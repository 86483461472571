import React, { useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import BasicTable from '../../../../components/BasicTable/BasicTable'
import { ColorsPalette, ColorsPalette2, ColorsPaletteLoop } from '../../Dashboard'
// import { changePriceAlertsQueryObject, changeTestsQueryObject, clearPriceAlerts, getPriceAlerts, getTests, openAlert } from '../../../../store/actions'
// import PriceAlertsTableColumns from '../../../Offers/PriceAlerts/PriceAlertsTableColumns'
import LoopReChart from '../../LoopReChart/LoopReChart'
import TopFiltersShoppingTableColumns from '../MarketplaceAndShoppingStatsTab/TopFiltersShoppingTableColumns'
import TopSearchedPhrasesTableColumns from '../MarketplaceAndShoppingStatsTab/TopSearchedPhrasesTableColumns'
import TopCouponsDisplayedTableColumns from './TopCouponsDisplayedTableColumns'
import TopProductsClicksTableColumns from './TopProductsClicksTableColumns'
import TopProductsViewsTableColumns from './TopProductsViewsTableColumns'

interface Props {
    t: (text: string) => string;
}

const MarketingStatsTab = ({ t }: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [initialDate] = useState(new Date())
    const [wishlistDateFilter, setWishlistDateFilter] = useState(initialDate)
    const [priceAlertDateFilter, setPriceAlertDateFilter] = useState(initialDate)
    const [searchedPhrasesDateFilter, setSearchedPhrasesDateFilter] = useState(initialDate)
    // const { items, totalItems, page, perPage, isLoading } = useSelector((state: any) => state.PriceAlerts);
    // const QUERY_OBJECT_INIT = useSelector((state: any) => state.PriceAlerts.initialQueryObject)
    // const queryObject = useSelector((state: any) => state.PriceAlerts.queryObject)
    // const setQueryObject = (queryObject: any) => dispatch(changePriceAlertsQueryObject(queryObject))
    // const onOpenAlert = (text: string, status: string) => dispatch(openAlert({ text, status }));
  
    // const handleSetQueryObject = (value: any, forceReload?: boolean) => {
    //   if ((JSON.stringify(queryObject) !== JSON.stringify(value)) || forceReload) {
    //      dispatch(getPriceAlerts({ page: 1, queryObject: { value, per_page: 10 } }))
    //   }
    //   setQueryObject(value)
    // }

    // useEffect(() => {
    //     if(items?.length !== 10){
    //         // dispatch(getPriceAlerts({ page: 1, queryObject: { ...queryObject, per_page: 10 } }))
    //     }
    //     console.log("RENDER ERRORS")
    // }, [])

    const feedsProductsCount = [
        {date: '2023-01-01', products: 60 },
        {date: '2023-01-02', products: 60 },
        {date: '2023-01-03', products: 59 },
        {date: '2023-01-04', products: 59 },
        {date: '2023-01-05', products: 60 },
        {date: '2023-01-06', products: 60 },
        {date: '2023-01-07', products: 60 },
    ]

    const productFeedClicks = [
        {date: '2023-01-01', clicks: 171 },
        {date: '2023-01-02', clicks: 155 },
        {date: '2023-01-03', clicks: 165 },
        {date: '2023-01-04', clicks: 121 },
        {date: '2023-01-05', clicks: 148 },
        {date: '2023-01-06', clicks: 150 },
        {date: '2023-01-07', clicks: 163 },
    ]
    
    const displayedCoupons = {
        "info": {
            "current_page": 1,
            "total_pages": 10,
            "total_items": 150,
            "page_count": 15
        },
        items: [
            { coupon_name: 'FLASHSALE15', coupon_description: "Get 15% off your purchase during our 24-hour flash sale!", displayed: 497 },
            { coupon_name: 'NEW10OFF', coupon_description: "Enjoy 10% off your first order as a new customer.", displayed: 466 },
            { coupon_name: 'FREESHIP50', coupon_description: "Free shipping on orders over $50.", displayed: 421 },
            { coupon_name: 'BUY2GET1FREE', coupon_description: "Buy 2 items, get 1 free on select products.", displayed: 379 },
            { coupon_name: 'SUMMER20', coupon_description: "Save 20% on all summer collection items.", displayed: 311 },
            { coupon_name: 'LOYALTY25', coupon_description: "Exclusive 25% discount for our loyal customers.", displayed: 205 },
            { coupon_name: 'BUNDLEDEAL', coupon_description: "Build your own bundle and save 15% on the total.", displayed: 142 },
            { coupon_name: 'BACKTOSCHOOL', coupon_description: "Get ready for school with 10% off on essential supplies.", displayed: 87 },
            { coupon_name: 'CLEARANCE50', coupon_description: "Up to 50% off on clearance items while supplies last.", displayed: 88 },
            { coupon_name: 'SOCIALFOLLOW', coupon_description: "Follow us on social media and use this code for a special surprise discount!", displayed: 61 },
        ]
    };

    const topFiltersShopping = {
        "info": {
            "current_page": 1,
            "total_pages": 10,
            "total_items": 150,
            "page_count": 15
        },
        items: [
            { filters: { brand: "Boohoo", color: "red", category: "skirt" }, searched: 301, results: 53 },
            { filters: { brand: "Boohoo", color: "black", category: "jeans" }, searched: 277, results: 12 },
            { filters: { brand: "Boohoo", color: "blue", category: "t-shirts" }, searched: 198, results: 34 },
            { filters: { brand: "Boohoo", color: "multi", category: "swimsuits" }, searched: 155, results: 21 },
            { filters: { brand: "Boohoo", size: "XS", category: "skirt" }, searched: 134, results: 5 },
            { filters: { brand: "Boohoo", color: "black", category: "joggers" }, searched: 127, results: 3 },
            { filters: { brand: "Boohoo", color: "khaki", category: "trousers" }, searched: 111, results: 11 },
            { filters: { brand: "Boohoo", category: "Coats & Jackets", size: "XL" }, searched: 110, results: 14 },
            { filters: { brand: "Boohoo", color: "red", category: "skirt" }, searched: 98, results: 43 },
            { filters: { brand: "Boohoo", color: "orange", category: "dress" }, searched: 93, results: 17 },
            { filters: { brand: "Boohoo", color: "blue", category: "skirt" }, searched: 77, results: 27 },
        ]
    };

    const topFiltersMarketplace = {
        "info": {
            "current_page": 1,
            "total_pages": 10,
            "total_items": 150,
            "page_count": 15
        },
        items: [
            { filters: { brand: "Boohoo", color: "red", category: "skirt" }, searched: 345, results: 45 },
            { filters: { brand: "Boohoo", color: "black", category: "jeans" }, searched: 287, results: 40 },
            { filters: { brand: "Boohoo", color: "blue", category: "t-shirts" }, searched: 168, results: 34 },
            { filters: { brand: "Boohoo", color: "multi", category: "swimsuits" }, searched: 145, results: 21 },
            { filters: { brand: "Boohoo", size: "XS", category: "skirt" }, searched: 134, results: 15 },
            { filters: { brand: "Boohoo", color: "black", category: "joggers" }, searched: 112, results: 7 },
            { filters: { brand: "Boohoo", color: "khaki", category: "trousers" }, searched: 111, results: 11 },
            { filters: { brand: "Boohoo", category: "Coats & Jackets", size: "XL" }, searched: 110, results: 27 },
            { filters: { brand: "Boohoo", color: "red", category: "skirt" }, searched: 67, results: 17 },
            { filters: { brand: "Boohoo", color: "orange", category: "dress" }, searched: 42, results: 43 },
            { filters: { brand: "Boohoo", color: "blue", category: "skirt" }, searched: 21, results: 27 },
        ]
    };

    return (
        <div className="add-to-stats-tab">
            {/* ROW 1 */}
            <Row>
                <Col sm={12} md={12} lg={12} xl={6}>
                    <Card>
                        <CardBody className="p-3">
                            <h4 className="card-title" style={{marginTop: 15, marginBottom: 16}}>{t("Number of products from google & FB feeds")}</h4>
                            <LoopReChart
                                height={300}
                                data={feedsProductsCount}
                                showLoaderOnEmptyData={true}
                                xAxisDataKey="date"
                                dataMaxKey="products"
                                lines={[
                                    // { type: "monotone", dataKey: "new", name: "New", stroke: "#4d9de0", activeDotSize: 5 },
                                    { type: "linear", dataKey: "products", name: "Products", stroke: ColorsPaletteLoop.green, activeDotSize: 5, label: { dataKey: "products" } },
                                    // { type: "monotone", dataKey: "total", name: "Total", stroke: "#e15554", activeDotSize: 8 },
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={12} md={12} lg={12} xl={6}>
                    <Card>
                        <CardBody className="p-3">
                            <h4 className="card-title" style={{marginTop: 15, marginBottom: 16}}>{t("Product feed clicks")}</h4>
                            <LoopReChart
                                height={300}
                                data={productFeedClicks}
                                showLoaderOnEmptyData={true}
                                xAxisDataKey="date"
                                dataMaxKey="clicks"
                                lines={[
                                    // { type: "monotone", dataKey: "new", name: "New", stroke: "#4d9de0", activeDotSize: 5 },
                                    { type: "linear", dataKey: "clicks", name: "Clicks", stroke: ColorsPaletteLoop.green, activeDotSize: 5, label: { dataKey: "clicks" } },
                                    // { type: "monotone", dataKey: "total", name: "Total", stroke: "#e15554", activeDotSize: 8 },
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {/* ROW 2 */}
            <Row>
                <Col sm={12} md={12} lg={12} xl={6}>
                    <Card>
                        <CardBody className="p-3">
                            <div className="d-flex justify-content-between align-items-center mb-0 pe-4">
                                <h4 className="card-title" style={{marginTop: 15, marginBottom: 16}}>{t("Most viewed product")}</h4>
                                <div className="d-flex gap-2 align-items-center">
                                    <span>Date:</span>
                                    <div style={{ width: 100 }}>
                                        <ReactDatePicker
                                            selected={wishlistDateFilter}
                                            onChange={(date: any) => setWishlistDateFilter(new Date(date))}
                                            className="form-control input-small clickable"
                                            showTimeSelect={false}
                                            calendarClassName="react-datepicker"
                                            dateFormat="dd.MM.yyyy"
                                            maxDate={new Date()}
                                        />
                                    </div>
                                    <Button size="sm" color="dark">
                                        <span>Show More</span>
                                        <i className="fas fa-list ms-1" />
                                    </Button>
                                </div>
                            </div>
                            <BasicTable
                                items={topProductsViews?.items}
                                columns={TopProductsViewsTableColumns(
                                    (topProductsViews?.info?.current_page - 1) * topProductsViews?.info?.page_count,
                                    (text: string) => text,
                                    {},
                                    () => {},
                                    {},
                                    topProductsViews?.items?.[0]?.views
                                )}
                                searchbar={false}
                                // getAction={({ page }: any) => getPriceAlerts({ page, queryObject: {...queryObject, per_page: 10} })}
                                totalSize={topProductsViews?.info?.total_items}
                                sizePerPage={topProductsViews?.info?.page_count}
                                // defaultSort={{}}
                                //@ts-ignore
                                currentPage={topProductsViews?.info?.current_page}
                                tableCustomClassName="table-small"
                                simple={true}
                                // isLoading={isLoading}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={12} md={12} lg={12} xl={6}>
                    <Card>
                        <CardBody className="p-3">
                            <div className="d-flex justify-content-between align-items-center mb-0 pe-4">
                                <h4 className="card-title" style={{marginTop: 15, marginBottom: 16}}>{t("Most clicked product")}</h4>
                                <div className="d-flex gap-2 align-items-center">
                                    <span>Date:</span>
                                    <div style={{ width: 100 }}>
                                        <ReactDatePicker
                                            selected={priceAlertDateFilter}
                                            onChange={(date: any) => setPriceAlertDateFilter(new Date(date))}
                                            className="form-control input-small clickable"
                                            showTimeSelect={false}
                                            calendarClassName="react-datepicker"
                                            dateFormat="dd.MM.yyyy"
                                            maxDate={new Date()}
                                        />
                                    </div>
                                    <Button size="sm" color="dark">
                                        <span>Show More</span>
                                        <i className="fas fa-list ms-1" />
                                    </Button>
                                </div>
                            </div>
                            <BasicTable
                                items={topProductsClicks?.items}
                                columns={TopProductsClicksTableColumns(
                                    (topProductsClicks?.info?.current_page - 1) * topProductsClicks?.info?.page_count,
                                    (text: string) => text,
                                    {},
                                    () => {},
                                    {},
                                    topProductsClicks?.items?.[0]?.clicks
                                )}
                                searchbar={false}
                                // getAction={({ page }: any) => getPriceAlerts({ page, queryObject: {...queryObject, per_page: 10} })}
                                totalSize={topProductsClicks?.info?.total_items}
                                sizePerPage={topProductsClicks?.info?.page_count}
                                // defaultSort={{}}
                                //@ts-ignore
                                currentPage={topProductsClicks?.info?.current_page}
                                tableCustomClassName="table-small"
                                simple={true}
                                // isLoading={isLoading}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {/* ROW 3 */}
            <Row>
                <Col sm={12} md={12} lg={12} xl={6}>
                    <Card>
                        <CardBody className="p-3">
                            <div className="d-flex justify-content-between align-items-center mb-0 pe-4">
                                <h4 className="card-title" style={{marginTop: 15, marginBottom: 16}}>{t("Number of coupons displayed")}</h4>
                                <div className="d-flex gap-2 align-items-center">
                                    <span>Date:</span>
                                    <div style={{ width: 100 }}>
                                        <ReactDatePicker
                                            selected={searchedPhrasesDateFilter}
                                            onChange={(date: any) => setSearchedPhrasesDateFilter(new Date(date))}
                                            className="form-control input-small clickable"
                                            showTimeSelect={false}
                                            calendarClassName="react-datepicker"
                                            dateFormat="dd.MM.yyyy"
                                            maxDate={new Date()}
                                        />
                                    </div>
                                    <Button size="sm" color="dark">
                                        <span>Show More</span>
                                        <i className="fas fa-list ms-1" />
                                    </Button>
                                </div>
                            </div>
                            <BasicTable
                                items={displayedCoupons?.items}
                                columns={TopCouponsDisplayedTableColumns(
                                    (displayedCoupons?.info?.current_page - 1) * displayedCoupons?.info?.page_count,
                                    (text: string) => text,
                                    {},
                                    () => {},
                                    {},
                                    displayedCoupons?.items?.[0]?.displayed
                                )}
                                searchbar={false}
                                // getAction={({ page }: any) => getPriceAlerts({ page, queryObject: {...queryObject, per_page: 10} })}
                                totalSize={displayedCoupons?.info?.total_items}
                                sizePerPage={displayedCoupons?.info?.page_count}
                                // defaultSort={{}}
                                //@ts-ignore
                                currentPage={displayedCoupons?.info?.current_page}
                                tableCustomClassName="table-small"
                                simple={true}
                                // isLoading={isLoading}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={12} md={12} lg={12} xl={6}>
                </Col>
            </Row>

        </div>
    )
}

export default MarketingStatsTab


const topProductsClicks = {
    "items": [
        {
            "id": 46095947,
            "sku": "c3fa48fb",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 627,
            "link": "https://www.boohoo.com/bonded-scuba-oversized-hoodie-/BMM25945.html?color=105",
            "name": "Bonded Scuba Oversized Hoodie",
            "status": 1,
            "version": "202302020000",
            "gender": "MEN",
            "category_id": 1567,
            "category": null,
            "clicks": 45,
            "external_image": "https://media.boohoo.com/i/boohoo/bmm25945_black_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "30.00",
            "sale_price": "30.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/_HgmMdx5vJe1ZzKx6DB4jU/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/_HgmMdx5vJe1ZzKx6DB4jU/original.jpg",
            "tags": {
                "color": "black",
                "category": "sweatshirt",
                "attributes": [
                    {
                        "type": "sweatshirt_type",
                        "values": [
                            {
                                "score": 0.96924,
                                "attribute": "everyday-sweatshirt"
                            }
                        ]
                    },
                    {
                        "type": "neckline_type",
                        "values": [
                            {
                                "score": 0.45056,
                                "attribute": "cowl-neck"
                            }
                        ]
                    },
                    {
                        "type": "tops_fit",
                        "values": [
                            {
                                "score": 0.94045,
                                "attribute": "oversized-tops"
                            }
                        ]
                    },
                    {
                        "type": "tops_length",
                        "values": [
                            {
                                "score": 0.79665,
                                "attribute": "thigh-length"
                            }
                        ]
                    },
                    {
                        "type": "sleeve_length",
                        "values": [
                            {
                                "score": 0.99988,
                                "attribute": "long-sleeve"
                            }
                        ]
                    },
                    {
                        "type": "sleeve_type",
                        "values": [
                            {
                                "score": 0.90798,
                                "attribute": "straight-sleeve"
                            }
                        ]
                    },
                    {
                        "type": "colors",
                        "values": [
                            {
                                "score": 0.81073,
                                "attribute": "black"
                            }
                        ]
                    },
                    {
                        "type": "pattern",
                        "values": [
                            {
                                "score": 0.7822,
                                "attribute": "plain"
                            }
                        ]
                    },
                    {
                        "type": "style",
                        "values": [
                            {
                                "score": 0.45166,
                                "attribute": "modest"
                            }
                        ]
                    },
                    {
                        "type": "occasion",
                        "values": [
                            {
                                "score": 0.65136,
                                "attribute": "casual"
                            }
                        ]
                    }
                ]
            },
            "ximilar_tags": {
                "Age": "adult",
                "Belt": "no belt",
                "Hood": "hood",
                "Color": "black",
                "Style": "casual",
                "Design": "plain",
                "Gender": "men",
                "Closure": "no closure",
                "Sleeves": "long sleeves",
                "Category": "Clothing/Upper",
                "Material": "silk",
                "Neckline": "hooded",
                "Subcategory": "sweatshirts",
                "Top Category": "Clothing"
            },
            "ximilar_category": [],
            "created_at": "2023-08-04T02:30:40.000000Z",
            "updated_at": "2023-08-04T02:30:40.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 46390027,
            "sku": "a688464f",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 627,
            "link": "https://www.boohoo.com/tall-dragon-graphic-hoodie/BMM32096.html?color=173",
            "name": "Tall Dragon Graphic Hoodie",
            "status": 1,
            "version": "202302020000",
            "gender": "MEN",
            "category_id": 1568,
            "category": null,
            "clicks": 42,
            "external_image": "https://media.boohoo.com/i/boohoo/bmm32096_white_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "30.00",
            "sale_price": "30.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/_4bW1TTk-qxZ-7jaHU9OMf/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/_4bW1TTk-qxZ-7jaHU9OMf/original.jpg",
            "tags": {
                "color": "purple",
                "category": "headwear",
                "attributes": [
                    {
                        "type": "headwear_type",
                        "values": [
                            {
                                "score": 0.99755,
                                "attribute": "beanie"
                            }
                        ]
                    },
                    {
                        "type": "colors",
                        "values": [
                            {
                                "score": 0.7412,
                                "attribute": "purple"
                            }
                        ]
                    },
                    {
                        "type": "pattern",
                        "values": [
                            {
                                "score": 0.992,
                                "attribute": "plain"
                            }
                        ]
                    },
                    {
                        "type": "material",
                        "values": [
                            {
                                "score": 1,
                                "attribute": "knitted"
                            }
                        ]
                    },
                    {
                        "type": "style",
                        "values": [
                            {
                                "score": 0.8972,
                                "attribute": "casual"
                            }
                        ]
                    },
                    {
                        "type": "occasion",
                        "values": [
                            {
                                "score": 0.8972,
                                "attribute": "winter"
                            }
                        ]
                    }
                ]
            },
            "ximilar_tags": {
                "Age": "adult",
                "Belt": "no belt",
                "Hood": "hood",
                "Color": "white",
                "Style": "casual",
                "Design": "picture",
                "Gender": "unisex",
                "Length": "short",
                "Closure": "no closure",
                "Category": "Clothing/Jackets and Coats",
                "Material": "synthetic",
                "Subcategory": "casual jackets",
                "Top Category": "Clothing"
            },
            "ximilar_category": [],
            "created_at": "2023-07-17T15:17:38.000000Z",
            "updated_at": "2023-07-17T15:17:38.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 46440969,
            "sku": "25fa4842",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 627,
            "link": "https://www.boohoo.com/oversized-homme-print-hoodie/BMM31330.html?color=105",
            "name": "Oversized Homme Print Hoodie",
            "status": 1,
            "version": "202302020000",
            "gender": "MEN",
            "category_id": 1568,
            "category": null,
            "clicks": 40,
            "external_image": "https://media.boohoo.com/i/boohoo/bmm31330_black_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "20.00",
            "sale_price": "20.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/_OmUDOLVkb1UMKq3lG_08V/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/_OmUDOLVkb1UMKq3lG_08V/original.jpg",
            "tags": {
                "color": "navy",
                "category": "hoodie",
                "attributes": [
                    {
                        "type": "tops_fit",
                        "values": [
                            {
                                "score": 0.96004,
                                "attribute": "oversized-tops"
                            }
                        ]
                    },
                    {
                        "type": "tops_length",
                        "values": [
                            {
                                "score": 0.54847,
                                "attribute": "thigh-length"
                            }
                        ]
                    },
                    {
                        "type": "sleeve_length",
                        "values": [
                            {
                                "score": 0.99986,
                                "attribute": "long-sleeve"
                            }
                        ]
                    },
                    {
                        "type": "sleeve_type",
                        "values": [
                            {
                                "score": 0.99625,
                                "attribute": "straight-sleeve"
                            }
                        ]
                    },
                    {
                        "type": "colors",
                        "values": [
                            {
                                "score": 0.96203,
                                "attribute": "navy"
                            }
                        ]
                    },
                    {
                        "type": "pattern",
                        "values": [
                            {
                                "score": 0.54668,
                                "attribute": "plain"
                            },
                            {
                                "score": 0.3178,
                                "attribute": "varsity-striped"
                            }
                        ]
                    },
                    {
                        "type": "more_attributes",
                        "values": [
                            {
                                "score": 1,
                                "attribute": "drawstring"
                            }
                        ]
                    },
                    {
                        "type": "style",
                        "values": [
                            {
                                "score": 0.5,
                                "attribute": "sporty"
                            }
                        ]
                    },
                    {
                        "type": "occasion",
                        "values": [
                            {
                                "score": 0.89877,
                                "attribute": "casual"
                            }
                        ]
                    }
                ]
            },
            "ximilar_tags": {
                "Age": "adult",
                "Belt": "no belt",
                "Hood": "hood",
                "Color": "black",
                "Style": "sport",
                "Design": "logo",
                "Gender": "men",
                "Closure": "no closure",
                "Sleeves": "long sleeves",
                "Category": "Clothing/Upper",
                "Material": "cotton",
                "Neckline": "hooded",
                "Subcategory": "sweatshirts",
                "Top Category": "Clothing"
            },
            "ximilar_category": [],
            "created_at": "2023-08-05T00:24:00.000000Z",
            "updated_at": "2023-08-05T00:24:00.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 50961964,
            "sku": "783d3ef5",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 627,
            "link": "https://www.boohoo.com/slim-fit-jeans/BMM41419.html?color=614",
            "name": "Slim Fit Jeans",
            "status": 1,
            "version": "202304040000",
            "gender": "MEN",
            "category_id": 1567,
            "category": null,
            "clicks": 40,
            "external_image": "https://media.boohoo.com/i/boohoo/bmm41419_dark%20grey_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "22.00",
            "sale_price": "22.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/MetUn89lLkubLfIT9vyh0/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/MetUn89lLkubLfIT9vyh0/original.jpg",
            "tags": {
                "color": "white",
                "category": "sneakers",
                "attributes": [
                    {
                        "type": "sneakers_type",
                        "values": [
                            {
                                "score": 1,
                                "attribute": "low-sneakers"
                            }
                        ]
                    },
                    {
                        "type": "heels_width",
                        "values": [
                            {
                                "score": 0.99744,
                                "attribute": "wedges"
                            }
                        ]
                    },
                    {
                        "type": "heels_height",
                        "values": [
                            {
                                "score": 0.93988,
                                "attribute": "low-heel"
                            }
                        ]
                    },
                    {
                        "type": "colors",
                        "values": [
                            {
                                "score": 0.50897,
                                "attribute": "white"
                            }
                        ]
                    },
                    {
                        "type": "pattern",
                        "values": [
                            {
                                "score": 0.38165,
                                "attribute": "abstract"
                            }
                        ]
                    },
                    {
                        "type": "shoe_material",
                        "values": [
                            {
                                "score": 1,
                                "attribute": "canvas"
                            }
                        ]
                    },
                    {
                        "type": "more_attributes",
                        "values": [
                            {
                                "score": 1,
                                "attribute": "shoelaces"
                            }
                        ]
                    },
                    {
                        "type": "style",
                        "values": [
                            {
                                "score": 0.82355,
                                "attribute": "sporty"
                            }
                        ]
                    },
                    {
                        "type": "occasion",
                        "values": [
                            {
                                "score": 0.82369,
                                "attribute": "sport"
                            }
                        ]
                    }
                ]
            },
            "ximilar_tags": {
                "Age": "adult",
                "Fit": "straight",
                "Rise": "mid rise",
                "Color": "grey",
                "Style": "casual",
                "Design": "plain",
                "Gender": "men",
                "Length": "long",
                "Category": "Clothing/Pants",
                "Material": "denim",
                "Subcategory": "casual trousers",
                "Top Category": "Clothing"
            },
            "ximilar_category": {
                "name": "Clothing/Pants",
                "subcategory": [
                    "casual trousers",
                    "casual leggings",
                    "sportswear shorts",
                    "culottes",
                    "formal trousers",
                    "hotpants",
                    "leather leggings",
                    "casual shorts",
                    "cargo",
                    "leather trousers",
                    "suit trousers",
                    "leather shorts",
                    "leggings",
                    "jeans",
                    "swim shorts",
                    "beach pants",
                    "sweat pants",
                    "denim shorts",
                    "sportswear leggings"
                ]
            },
            "created_at": "2023-07-12T00:16:16.000000Z",
            "updated_at": "2023-07-12T00:16:16.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 50962085,
            "sku": "fb8752ed",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 627,
            "link": "https://www.boohoo.com/oversized-extended-neck-graphic-sweatshirt/BMM41490.html?color=209",
            "name": "Oversized Extended Neck Graphic Sweatshirt",
            "status": 1,
            "version": "202304040000",
            "gender": "MEN",
            "category_id": 1568,
            "category": null,
            "clicks": 37,
            "external_image": "https://media.boohoo.com/i/boohoo/bmm41490_sage_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "25.00",
            "sale_price": "8.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/PK1WLrf0n89pL-rXTYZBO/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/PK1WLrf0n89pL-rXTYZBO/original.jpg",
            "tags": {
                "color": "mint",
                "category": "sweatshirt",
                "attributes": [
                    {
                        "type": "sweatshirt_type",
                        "values": [
                            {
                                "score": 0.9999,
                                "attribute": "everyday-sweatshirt"
                            }
                        ]
                    },
                    {
                        "type": "neckline_type",
                        "values": [
                            {
                                "score": 0.81101,
                                "attribute": "high-neck"
                            }
                        ]
                    },
                    {
                        "type": "tops_fit",
                        "values": [
                            {
                                "score": 0.59681,
                                "attribute": "regular-fit-tops"
                            }
                        ]
                    },
                    {
                        "type": "tops_length",
                        "values": [
                            {
                                "score": 0.83161,
                                "attribute": "thigh-length"
                            }
                        ]
                    },
                    {
                        "type": "sleeve_length",
                        "values": [
                            {
                                "score": 0.99856,
                                "attribute": "long-sleeve"
                            }
                        ]
                    },
                    {
                        "type": "sleeve_type",
                        "values": [
                            {
                                "score": 0.98107,
                                "attribute": "straight-sleeve"
                            }
                        ]
                    },
                    {
                        "type": "colors",
                        "values": [
                            {
                                "score": 0.87894,
                                "attribute": "mint"
                            }
                        ]
                    },
                    {
                        "type": "pattern",
                        "values": [
                            {
                                "score": 0.33719,
                                "attribute": "text-print"
                            },
                            {
                                "score": 0.33231,
                                "attribute": "plain"
                            }
                        ]
                    },
                    {
                        "type": "style",
                        "values": [
                            {
                                "score": 0.86056,
                                "attribute": "casual"
                            }
                        ]
                    },
                    {
                        "type": "occasion",
                        "values": [
                            {
                                "score": 0.84791,
                                "attribute": "casual"
                            }
                        ]
                    }
                ]
            },
            "ximilar_tags": {
                "Age": "adult",
                "Belt": "no belt",
                "Color": "white",
                "Style": "casual",
                "Design": "logo",
                "Gender": "men",
                "Closure": "no closure",
                "Sleeves": "long sleeves",
                "Category": "Clothing/Upper",
                "Material": "cotton",
                "Neckline": "back",
                "Subcategory": "sweatshirts",
                "Top Category": "Clothing"
            },
            "ximilar_category": {
                "name": "Clothing/Upper",
                "subcategory": [
                    "cardigans",
                    "crop tops",
                    "blouses",
                    "sweatshirts",
                    "tops",
                    "knitted tops",
                    "denim shirt",
                    "polo-shirts",
                    "t-shirts",
                    "wrap tops",
                    "knitted vests",
                    "sweaters",
                    "shell tops",
                    "cropped blouse",
                    "tunics",
                    "vests",
                    "tank tops",
                    "shirts",
                    "transparent tops",
                    "hoodies",
                    "bardot tops"
                ]
            },
            "created_at": "2023-07-22T23:01:13.000000Z",
            "updated_at": "2023-07-22T23:01:13.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 50962087,
            "sku": "fc6547c1",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 627,
            "link": "https://www.boohoo.com/plus-yu-gi-oh-license-t-shirt/BMM45653.html?color=173",
            "name": "Plus Yu Gi Oh License T-shirt",
            "status": 1,
            "version": "202304040000",
            "gender": "MEN",
            "category_id": null,
            "category": null,
            "clicks": 34,
            "external_image": "https://media.boohoo.com/i/boohoo/bmm45653_white_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "18.00",
            "sale_price": "18.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/7nF3GvVlZwKZzH7jKoAZv/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/7nF3GvVlZwKZzH7jKoAZv/original.jpg",
            "tags": {
                "color": "white",
                "category": "t-shirt",
                "attributes": [
                    {
                        "type": "neckline_type",
                        "values": [
                            {
                                "score": 0.67361,
                                "attribute": "crew-neck"
                            }
                        ]
                    },
                    {
                        "type": "tops_fit",
                        "values": [
                            {
                                "score": 0.97945,
                                "attribute": "oversized-tops"
                            }
                        ]
                    },
                    {
                        "type": "tops_length",
                        "values": [
                            {
                                "score": 0.68347,
                                "attribute": "short"
                            }
                        ]
                    },
                    {
                        "type": "colors",
                        "values": [
                            {
                                "score": 0.60635,
                                "attribute": "white"
                            }
                        ]
                    },
                    {
                        "type": "pattern",
                        "values": [
                            {
                                "score": 0.60303,
                                "attribute": "abstract"
                            }
                        ]
                    },
                    {
                        "type": "material",
                        "values": [
                            {
                                "score": 1,
                                "attribute": "cotton"
                            }
                        ]
                    },
                    {
                        "type": "style",
                        "values": [
                            {
                                "score": 0.99998,
                                "attribute": "casual"
                            }
                        ]
                    },
                    {
                        "type": "occasion",
                        "values": [
                            {
                                "score": 0.98296,
                                "attribute": "casual"
                            }
                        ]
                    }
                ]
            },
            "ximilar_tags": {
                "Age": "adult",
                "Belt": "no belt",
                "Color": "white",
                "Style": "casual",
                "Design": "picture",
                "Gender": "men",
                "Closure": "no closure",
                "Sleeves": "short sleeves",
                "Category": "Clothing/Upper",
                "Material": "cotton",
                "Neckline": "crew",
                "Subcategory": "t-shirts",
                "Top Category": "Clothing"
            },
            "ximilar_category": {
                "name": "Clothing/Upper",
                "subcategory": [
                    "cardigans",
                    "crop tops",
                    "blouses",
                    "sweatshirts",
                    "tops",
                    "knitted tops",
                    "denim shirt",
                    "polo-shirts",
                    "t-shirts",
                    "wrap tops",
                    "knitted vests",
                    "sweaters",
                    "shell tops",
                    "cropped blouse",
                    "tunics",
                    "vests",
                    "tank tops",
                    "shirts",
                    "transparent tops",
                    "hoodies",
                    "bardot tops"
                ]
            },
            "created_at": "2023-07-19T00:12:42.000000Z",
            "updated_at": "2023-07-19T00:12:42.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 50962168,
            "sku": "72c74d5d",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 627,
            "link": "https://www.boohoo.com/tall-mid-length-plain-swim-shorts-/BMM40336.html?color=324",
            "name": "Tall Mid Length Plain Swim Shorts",
            "status": 1,
            "version": "202304040000",
            "gender": "MEN",
            "category_id": 1410,
            "category": null,
            "clicks": 32,
            "external_image": "https://media.boohoo.com/i/boohoo/bmm40336_neon-green_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "10.00",
            "sale_price": "10.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/g8I2FQytVr10ZLU5hbGoF/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/g8I2FQytVr10ZLU5hbGoF/original.jpg",
            "tags": {
                "color": "black",
                "category": "slippers-shoe",
                "attributes": [
                    {
                        "type": "slippers-shoe_type",
                        "values": [
                            {
                                "score": 0.9918,
                                "attribute": "open-front-slipper"
                            }
                        ]
                    },
                    {
                        "type": "heels_height",
                        "values": [
                            {
                                "score": 0.99711,
                                "attribute": "flats"
                            }
                        ]
                    },
                    {
                        "type": "colors",
                        "values": [
                            {
                                "score": 0.99869,
                                "attribute": "black"
                            }
                        ]
                    },
                    {
                        "type": "pattern",
                        "values": [
                            {
                                "score": 0.99086,
                                "attribute": "plain"
                            }
                        ]
                    },
                    {
                        "type": "shoe_material",
                        "values": [
                            {
                                "score": 1,
                                "attribute": "leather"
                            }
                        ]
                    },
                    {
                        "type": "style",
                        "values": [
                            {
                                "score": 0.90304,
                                "attribute": "casual"
                            }
                        ]
                    },
                    {
                        "type": "occasion",
                        "values": [
                            {
                                "score": 0.32709,
                                "attribute": "semi-formal"
                            },
                            {
                                "score": 0.32709,
                                "attribute": "home-lounge"
                            },
                            {
                                "score": 0.32709,
                                "attribute": "summer"
                            }
                        ]
                    }
                ]
            },
            "ximilar_tags": {
                "Age": "adult",
                "Rise": "mid rise",
                "Color": "green",
                "Style": "sport",
                "Design": "plain",
                "Gender": "men",
                "Length": "short",
                "Category": "Clothing/Pants",
                "Material": "cotton",
                "Subcategory": "sportswear shorts",
                "Top Category": "Clothing"
            },
            "ximilar_category": {
                "name": "Clothing/Jackets and Coats",
                "subcategory": [
                    "biker jacket",
                    "windstoppers & softshells",
                    "wool coats",
                    "knitted ponchos",
                    "suit jackets and tuxedos",
                    "raincoats and ponchos",
                    "sportswear shorts",
                    "trench coats",
                    "puffer jackets",
                    "parka coat",
                    "harrington jacket",
                    "bomber jacket",
                    "pilot jacket",
                    "peacoat",
                    "casual jackets",
                    "racer jacket",
                    "kimono coat",
                    "baseball jacket",
                    "duffle coat",
                    "winter jackets",
                    "winter coats"
                ]
            },
            "created_at": "2023-07-12T00:12:22.000000Z",
            "updated_at": "2023-07-12T00:12:22.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 50962175,
            "sku": "a94b51ef",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 627,
            "link": "https://www.boohoo.com/skinny-stretch-rip-repair-denim-shorts/BMM47599.html?color=391",
            "name": "Skinny Stretch Rip & Repair Denim Shorts",
            "status": 1,
            "version": "202304040000",
            "gender": "MEN",
            "category_id": 1382,
            "category": null,
            "clicks": 32,
            "external_image": "https://media.boohoo.com/i/boohoo/bmm47599_dark%20blue_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "18.00",
            "sale_price": "18.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/ASSoBVqUjWo0gumo0NmhC/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/ASSoBVqUjWo0gumo0NmhC/original.jpg",
            "tags": {
                "color": "white",
                "category": "sneakers",
                "attributes": [
                    {
                        "type": "sneakers_type",
                        "values": [
                            {
                                "score": 1,
                                "attribute": "low-sneakers"
                            }
                        ]
                    },
                    {
                        "type": "heels_height",
                        "values": [
                            {
                                "score": 0.99987,
                                "attribute": "flats"
                            }
                        ]
                    },
                    {
                        "type": "colors",
                        "values": [
                            {
                                "score": 0.78043,
                                "attribute": "white"
                            }
                        ]
                    },
                    {
                        "type": "pattern",
                        "values": [
                            {
                                "score": 0.88157,
                                "attribute": "zebra"
                            }
                        ]
                    },
                    {
                        "type": "shoe_material",
                        "values": [
                            {
                                "score": 1,
                                "attribute": "canvas"
                            }
                        ]
                    },
                    {
                        "type": "more_attributes",
                        "values": [
                            {
                                "score": 1,
                                "attribute": "shoelaces"
                            }
                        ]
                    },
                    {
                        "type": "style",
                        "values": [
                            {
                                "score": 0.86096,
                                "attribute": "sporty"
                            }
                        ]
                    },
                    {
                        "type": "occasion",
                        "values": [
                            {
                                "score": 0.86096,
                                "attribute": "sport"
                            }
                        ]
                    }
                ]
            },
            "ximilar_tags": {
                "Age": "adult",
                "Rise": "can't tell",
                "Color": "light blue",
                "Style": "casual",
                "Design": "plain",
                "Gender": "men",
                "Length": "short",
                "Category": "Clothing/Pants",
                "Material": "denim",
                "Subcategory": "denim shorts",
                "Top Category": "Clothing"
            },
            "ximilar_category": [],
            "created_at": "2023-08-01T05:44:41.000000Z",
            "updated_at": "2023-08-01T05:44:41.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 50962180,
            "sku": "991e538c",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 627,
            "link": "https://www.boohoo.com/oversized-paint-splatter-worldwide-t-shirt-/BMM46408.html?color=105",
            "name": "Oversized Paint Splatter Worldwide T-shirt",
            "status": 1,
            "version": "202304040000",
            "gender": "MEN",
            "category_id": 1573,
            "category": null,
            "clicks": 28,
            "external_image": "https://media.boohoo.com/i/boohoo/bmm46408_black_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "14.00",
            "sale_price": "14.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/UO45sZWbtLDMj40Wlex1X/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/UO45sZWbtLDMj40Wlex1X/original.jpg",
            "tags": {
                "color": "multicolor",
                "category": "t-shirt",
                "attributes": [
                    {
                        "type": "neckline_type",
                        "values": [
                            {
                                "score": 0.68228,
                                "attribute": "round-neck"
                            }
                        ]
                    },
                    {
                        "type": "tops_fit",
                        "values": [
                            {
                                "score": 0.68342,
                                "attribute": "regular-fit-tops"
                            }
                        ]
                    },
                    {
                        "type": "tops_length",
                        "values": [
                            {
                                "score": 0.58709,
                                "attribute": "thigh-length"
                            }
                        ]
                    },
                    {
                        "type": "colors",
                        "values": [
                            {
                                "score": 0.47856,
                                "attribute": "multicolor"
                            }
                        ]
                    },
                    {
                        "type": "pattern",
                        "values": [
                            {
                                "score": 0.63491,
                                "attribute": "abstract"
                            }
                        ]
                    },
                    {
                        "type": "material",
                        "values": [
                            {
                                "score": 1,
                                "attribute": "cotton"
                            }
                        ]
                    },
                    {
                        "type": "style",
                        "values": [
                            {
                                "score": 0.99998,
                                "attribute": "casual"
                            }
                        ]
                    },
                    {
                        "type": "occasion",
                        "values": [
                            {
                                "score": 0.98296,
                                "attribute": "casual"
                            }
                        ]
                    }
                ]
            },
            "ximilar_tags": {
                "Age": "adult",
                "Belt": "no belt",
                "Color": "black",
                "Style": "casual",
                "Design": "picture",
                "Gender": "men",
                "Closure": "no closure",
                "Sleeves": "short sleeves",
                "Category": "Clothing/Upper",
                "Material": "cotton",
                "Neckline": "crew",
                "Subcategory": "t-shirts",
                "Top Category": "Clothing"
            },
            "ximilar_category": [],
            "created_at": "2023-07-12T00:12:22.000000Z",
            "updated_at": "2023-07-12T00:12:22.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 50962186,
            "sku": "921c533e",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 627,
            "link": "https://www.boohoo.com/oversized-pour-homme-gothic-graphic-t-shirt/BMM46414.html?color=105",
            "name": "Oversized Pour Homme Gothic Graphic T-shirt",
            "status": 1,
            "version": "202304040000",
            "gender": "MEN",
            "category_id": 1573,
            "category": null,
            "clicks": 21,
            "external_image": "https://media.boohoo.com/i/boohoo/bmm46414_black_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "15.00",
            "sale_price": "15.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/pHiw5tgQFHsBGwgM0rQo-/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/pHiw5tgQFHsBGwgM0rQo-/original.jpg",
            "tags": {
                "color": "multicolor, black",
                "category": "t-shirt",
                "attributes": [
                    {
                        "type": "neckline_type",
                        "values": [
                            {
                                "score": 0.66424,
                                "attribute": "round-neck"
                            }
                        ]
                    },
                    {
                        "type": "tops_fit",
                        "values": [
                            {
                                "score": 0.90678,
                                "attribute": "regular-fit-tops"
                            }
                        ]
                    },
                    {
                        "type": "tops_length",
                        "values": [
                            {
                                "score": 0.54403,
                                "attribute": "short"
                            }
                        ]
                    },
                    {
                        "type": "colors",
                        "values": [
                            {
                                "score": 0.52209,
                                "attribute": "multicolor"
                            },
                            {
                                "score": 0.34798,
                                "attribute": "black"
                            }
                        ]
                    },
                    {
                        "type": "pattern",
                        "values": [
                            {
                                "score": 0.4949,
                                "attribute": "abstract"
                            }
                        ]
                    },
                    {
                        "type": "material",
                        "values": [
                            {
                                "score": 1,
                                "attribute": "cotton"
                            }
                        ]
                    },
                    {
                        "type": "style",
                        "values": [
                            {
                                "score": 0.98296,
                                "attribute": "casual"
                            }
                        ]
                    },
                    {
                        "type": "occasion",
                        "values": [
                            {
                                "score": 0.98296,
                                "attribute": "casual"
                            }
                        ]
                    }
                ]
            },
            "ximilar_tags": {
                "Age": "adult",
                "Belt": "no belt",
                "Color": "dark blue and navy",
                "Style": "casual",
                "Design": "picture",
                "Gender": "men",
                "Closure": "no closure",
                "Sleeves": "short sleeves",
                "Category": "Clothing/Upper",
                "Material": "cotton",
                "Neckline": "crew",
                "Subcategory": "t-shirts",
                "Top Category": "Clothing"
            },
            "ximilar_category": [],
            "created_at": "2023-07-15T00:15:40.000000Z",
            "updated_at": "2023-07-15T00:15:40.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 50962191,
            "sku": "e2585047",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 627,
            "link": "https://www.boohoo.com/oversized-black-panther-license-t-shirt/BMM45524.html?color=105",
            "name": "Oversized Black Panther License T-shirt",
            "status": 1,
            "version": "202304040000",
            "gender": "MEN",
            "category_id": null,
            "category": null,
            "clicks": 12,
            "external_image": "https://media.boohoo.com/i/boohoo/bmm45524_black_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "16.00",
            "sale_price": "16.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/RR-RyuFmh1iOQ7OQYcrIt/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/RR-RyuFmh1iOQ7OQYcrIt/original.jpg",
            "tags": {
                "color": "navy, multicolor",
                "category": "t-shirt",
                "attributes": [
                    {
                        "type": "neckline_type",
                        "values": [
                            {
                                "score": 0.50358,
                                "attribute": "round-neck"
                            }
                        ]
                    },
                    {
                        "type": "tops_fit",
                        "values": [
                            {
                                "score": 0.99105,
                                "attribute": "oversized-tops"
                            }
                        ]
                    },
                    {
                        "type": "tops_length",
                        "values": [
                            {
                                "score": 0.80424,
                                "attribute": "thigh-length"
                            }
                        ]
                    },
                    {
                        "type": "colors",
                        "values": [
                            {
                                "score": 0.5571,
                                "attribute": "navy"
                            },
                            {
                                "score": 0.3181,
                                "attribute": "multicolor"
                            }
                        ]
                    },
                    {
                        "type": "pattern",
                        "values": [
                            {
                                "score": 0.69083,
                                "attribute": "text-print"
                            }
                        ]
                    },
                    {
                        "type": "material",
                        "values": [
                            {
                                "score": 1,
                                "attribute": "cotton"
                            }
                        ]
                    },
                    {
                        "type": "style",
                        "values": [
                            {
                                "score": 0.99998,
                                "attribute": "casual"
                            }
                        ]
                    },
                    {
                        "type": "occasion",
                        "values": [
                            {
                                "score": 0.98296,
                                "attribute": "casual"
                            }
                        ]
                    }
                ]
            },
            "ximilar_tags": {
                "Age": "adult",
                "Belt": "no belt",
                "Color": "dark blue and navy",
                "Style": "casual",
                "Design": "picture",
                "Gender": "men",
                "Closure": "no closure",
                "Sleeves": "short sleeves",
                "Category": "Clothing/Upper",
                "Material": "cotton",
                "Neckline": "crew",
                "Subcategory": "t-shirts",
                "Top Category": "Clothing"
            },
            "ximilar_category": {
                "name": "Clothing/Upper",
                "subcategory": [
                    "cardigans",
                    "crop tops",
                    "blouses",
                    "sweatshirts",
                    "tops",
                    "knitted tops",
                    "denim shirt",
                    "polo-shirts",
                    "t-shirts",
                    "wrap tops",
                    "knitted vests",
                    "sweaters",
                    "shell tops",
                    "cropped blouse",
                    "tunics",
                    "vests",
                    "tank tops",
                    "shirts",
                    "transparent tops",
                    "hoodies",
                    "bardot tops"
                ]
            },
            "created_at": "2023-07-12T00:12:22.000000Z",
            "updated_at": "2023-07-12T00:12:22.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 50962272,
            "sku": "d1e54b66",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 627,
            "link": "https://www.boohoo.com/plus-mid-length-plain-swim-shorts/BMM35501.html?color=105",
            "name": "Plus Mid Length Plain Swim Shorts",
            "status": 1,
            "version": "202304040000",
            "gender": "MEN",
            "category_id": 1410,
            "category": null,
            "clicks": 10,
            "external_image": "https://media.boohoo.com/i/boohoo/bmm35501_black_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "14.00",
            "sale_price": "14.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/0aIJkT0E_X9t4sZ_85-Mb/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/0aIJkT0E_X9t4sZ_85-Mb/original.jpg",
            "tags": {
                "color": "black",
                "category": "slippers-shoe",
                "attributes": [
                    {
                        "type": "slippers-shoe_type",
                        "values": [
                            {
                                "score": 0.73437,
                                "attribute": "open-front-slipper"
                            }
                        ]
                    },
                    {
                        "type": "heels_height",
                        "values": [
                            {
                                "score": 0.99905,
                                "attribute": "flats"
                            }
                        ]
                    },
                    {
                        "type": "colors",
                        "values": [
                            {
                                "score": 0.99219,
                                "attribute": "black"
                            }
                        ]
                    },
                    {
                        "type": "pattern",
                        "values": [
                            {
                                "score": 0.94415,
                                "attribute": "plain"
                            }
                        ]
                    },
                    {
                        "type": "style",
                        "values": [
                            {
                                "score": 0.94669,
                                "attribute": "casual"
                            }
                        ]
                    },
                    {
                        "type": "occasion",
                        "values": [
                            {
                                "score": 0.85476,
                                "attribute": "home-lounge"
                            }
                        ]
                    }
                ]
            },
            "ximilar_tags": {
                "Age": "adult",
                "Rise": "low rise",
                "Color": "black",
                "Style": "sport",
                "Design": "plain",
                "Gender": "men",
                "Length": "short",
                "Category": "Clothing/Pants",
                "Material": "cotton",
                "Subcategory": "sportswear shorts",
                "Top Category": "Clothing"
            },
            "ximilar_category": {
                "name": "Clothing/Jackets and Coats",
                "subcategory": [
                    "biker jacket",
                    "windstoppers & softshells",
                    "wool coats",
                    "knitted ponchos",
                    "suit jackets and tuxedos",
                    "raincoats and ponchos",
                    "sportswear shorts",
                    "trench coats",
                    "puffer jackets",
                    "parka coat",
                    "harrington jacket",
                    "bomber jacket",
                    "pilot jacket",
                    "peacoat",
                    "casual jackets",
                    "racer jacket",
                    "kimono coat",
                    "baseball jacket",
                    "duffle coat",
                    "winter jackets",
                    "winter coats"
                ]
            },
            "created_at": "2023-07-19T00:12:42.000000Z",
            "updated_at": "2023-07-19T00:12:42.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 50962275,
            "sku": "883d491c",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 627,
            "link": "https://www.boohoo.com/oversized-heavyweight-hoodie/BMM40919.html?color=173",
            "name": "Oversized Heavyweight Hoodie",
            "status": 1,
            "version": "202304040000",
            "gender": "MEN",
            "category_id": null,
            "category": null,
            "clicks": 9,
            "external_image": "https://media.boohoo.com/i/boohoo/bmm40919_white_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "22.00",
            "sale_price": "22.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/yhCchA1-4Mh1c_AClGDUB/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/yhCchA1-4Mh1c_AClGDUB/original.jpg",
            "tags": {
                "color": "black",
                "category": "sweatpants",
                "attributes": [
                    {
                        "type": "waist_type",
                        "values": [
                            {
                                "score": 0.53769,
                                "attribute": "low-waist"
                            }
                        ]
                    },
                    {
                        "type": "bottoms_fit",
                        "values": [
                            {
                                "score": 0.72014,
                                "attribute": "oversized-bottoms"
                            }
                        ]
                    },
                    {
                        "type": "bottoms_length",
                        "values": [
                            {
                                "score": 0.99183,
                                "attribute": "above-knee"
                            }
                        ]
                    },
                    {
                        "type": "colors",
                        "values": [
                            {
                                "score": 0.99127,
                                "attribute": "black"
                            }
                        ]
                    },
                    {
                        "type": "pattern",
                        "values": [
                            {
                                "score": 0.9926,
                                "attribute": "plain"
                            }
                        ]
                    },
                    {
                        "type": "style",
                        "values": [
                            {
                                "score": 0.5,
                                "attribute": "sporty"
                            }
                        ]
                    },
                    {
                        "type": "occasion",
                        "values": [
                            {
                                "score": 0.5,
                                "attribute": "sport"
                            }
                        ]
                    }
                ]
            },
            "ximilar_tags": {
                "Age": "adult",
                "Cut": "shorted",
                "Belt": "no belt",
                "Hood": "hood",
                "Color": "white",
                "Style": "casual",
                "Design": "plain",
                "Gender": "men",
                "Closure": "no closure",
                "Sleeves": "long sleeves",
                "Category": "Clothing/Upper",
                "Material": "cotton",
                "Neckline": "hooded",
                "Subcategory": "sweatshirts",
                "Top Category": "Clothing"
            },
            "ximilar_category": [],
            "created_at": "2023-07-19T00:12:42.000000Z",
            "updated_at": "2023-07-19T00:12:42.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 50962278,
            "sku": "cc0a506c",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 627,
            "link": "https://www.boohoo.com/man-signature-mid-length-swim-shorts/BMM34927.html?color=365",
            "name": "Man Signature Mid Length Swim Shorts",
            "status": 1,
            "version": "202304040000",
            "gender": "MEN",
            "category_id": null,
            "category": null,
            "clicks": 8,
            "external_image": "https://media.boohoo.com/i/boohoo/bmm34927_neon-yellow_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "12.00",
            "sale_price": "12.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/qL-07R9Y7lty7INfWYaD2/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/qL-07R9Y7lty7INfWYaD2/original.jpg",
            "tags": {
                "color": "black",
                "category": "slippers-shoe",
                "attributes": [
                    {
                        "type": "slippers-shoe_type",
                        "values": [
                            {
                                "score": 0.99257,
                                "attribute": "open-front-slipper"
                            }
                        ]
                    },
                    {
                        "type": "heels_height",
                        "values": [
                            {
                                "score": 0.98713,
                                "attribute": "flats"
                            }
                        ]
                    },
                    {
                        "type": "colors",
                        "values": [
                            {
                                "score": 0.99758,
                                "attribute": "black"
                            }
                        ]
                    },
                    {
                        "type": "pattern",
                        "values": [
                            {
                                "score": 0.96214,
                                "attribute": "plain"
                            }
                        ]
                    },
                    {
                        "type": "style",
                        "values": [
                            {
                                "score": 0.94669,
                                "attribute": "casual"
                            }
                        ]
                    },
                    {
                        "type": "occasion",
                        "values": [
                            {
                                "score": 0.85476,
                                "attribute": "home-lounge"
                            }
                        ]
                    }
                ]
            },
            "ximilar_tags": {
                "Age": "adult",
                "Rise": "low rise",
                "Color": "green",
                "Style": "sport",
                "Design": "logo",
                "Gender": "men",
                "Length": "short",
                "region": "generic",
                "Category": "Clothing/Pants",
                "Material": "cotton",
                "Subcategory": "sportswear shorts",
                "Top Category": "Clothing"
            },
            "ximilar_category": {
                "name": "Clothing/Jackets and Coats",
                "subcategory": [
                    "biker jacket",
                    "windstoppers & softshells",
                    "wool coats",
                    "knitted ponchos",
                    "suit jackets and tuxedos",
                    "raincoats and ponchos",
                    "sportswear shorts",
                    "trench coats",
                    "puffer jackets",
                    "parka coat",
                    "harrington jacket",
                    "bomber jacket",
                    "pilot jacket",
                    "peacoat",
                    "casual jackets",
                    "racer jacket",
                    "kimono coat",
                    "baseball jacket",
                    "duffle coat",
                    "winter jackets",
                    "winter coats"
                ]
            },
            "created_at": "2023-07-19T00:12:42.000000Z",
            "updated_at": "2023-07-19T00:12:42.000000Z",
            "blocks": 0,
            "brand": {
                "id": 16273,
                "name": "MAN Branded"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 50962280,
            "sku": "12a0518f",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 627,
            "link": "https://www.boohoo.com/plus-man-signature-mid-length-swim-shorts/BMM35505.html?color=105",
            "name": "Plus Man Signature Mid Length Swim Shorts",
            "status": 1,
            "version": "202304040000",
            "gender": "MEN",
            "category_id": 1410,
            "category": null,
            "clicks": 8,
            "external_image": "https://media.boohoo.com/i/boohoo/bmm35505_black_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "12.00",
            "sale_price": "12.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/Sa-MtWr5sfmZ5NUykC50z/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/Sa-MtWr5sfmZ5NUykC50z/original.jpg",
            "tags": {
                "color": "black",
                "category": "slippers-shoe",
                "attributes": [
                    {
                        "type": "slippers-shoe_type",
                        "values": [
                            {
                                "score": 0.98485,
                                "attribute": "open-front-slipper"
                            }
                        ]
                    },
                    {
                        "type": "heels_height",
                        "values": [
                            {
                                "score": 0.93099,
                                "attribute": "flats"
                            }
                        ]
                    },
                    {
                        "type": "colors",
                        "values": [
                            {
                                "score": 0.98981,
                                "attribute": "black"
                            }
                        ]
                    },
                    {
                        "type": "pattern",
                        "values": [
                            {
                                "score": 0.71833,
                                "attribute": "plain"
                            }
                        ]
                    },
                    {
                        "type": "shoe_material",
                        "values": [
                            {
                                "score": 1,
                                "attribute": "leather"
                            }
                        ]
                    },
                    {
                        "type": "style",
                        "values": [
                            {
                                "score": 0.90304,
                                "attribute": "casual"
                            }
                        ]
                    },
                    {
                        "type": "occasion",
                        "values": [
                            {
                                "score": 0.32709,
                                "attribute": "semi-formal"
                            },
                            {
                                "score": 0.32709,
                                "attribute": "home-lounge"
                            },
                            {
                                "score": 0.32709,
                                "attribute": "summer"
                            }
                        ]
                    }
                ]
            },
            "ximilar_tags": {
                "Age": "adult",
                "Rise": "can't tell",
                "Color": "black",
                "Style": "sport",
                "Design": "logo",
                "Gender": "men",
                "Length": "short",
                "Category": "Clothing/Pants",
                "Material": "cotton",
                "Subcategory": "sportswear shorts",
                "Top Category": "Clothing"
            },
            "ximilar_category": {
                "name": "Clothing/Jackets and Coats",
                "subcategory": [
                    "biker jacket",
                    "windstoppers & softshells",
                    "wool coats",
                    "knitted ponchos",
                    "suit jackets and tuxedos",
                    "raincoats and ponchos",
                    "sportswear shorts",
                    "trench coats",
                    "puffer jackets",
                    "parka coat",
                    "harrington jacket",
                    "bomber jacket",
                    "pilot jacket",
                    "peacoat",
                    "casual jackets",
                    "racer jacket",
                    "kimono coat",
                    "baseball jacket",
                    "duffle coat",
                    "winter jackets",
                    "winter coats"
                ]
            },
            "created_at": "2023-07-19T00:18:23.000000Z",
            "updated_at": "2023-07-19T00:18:23.000000Z",
            "blocks": 0,
            "brand": {
                "id": 16273,
                "name": "MAN Branded"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        }
    ],
    "info": {
        "current_page": 1,
        "total_pages": 30,
        "total_items": 444,
        "page_count": 15
    }
}
const topProductsViews = {
    "items": [
        {
            "id": 52478197,
            "sku": "ae2c49ff",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 625,
            "link": "https://www.boohoo.com/rib-wide-square-neck-maxi-dress/GZZ53211.html?color=119",
            "name": "Rib Wide Square Neck Maxi Dress",
            "status": 1,
            "version": "202304280000",
            "gender": "WOMEN",
            "category_id": 1350,
            "category": null,
            "clicks": 45,
            "views": 195,
            "external_image": "https://media.boohoo.com/i/boohoo/gzz53211_cobalt_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "18.00",
            "sale_price": "18.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/cY_7FTjEgKk1cqVe8GKuu/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/cY_7FTjEgKk1cqVe8GKuu/original.jpg",
            "tags": [
                {
                    "color": "blue",
                    "category": "dress"
                },
                {
                    "color": "black",
                    "category": "sandals-shoe"
                }
            ],
            "ximilar_tags": {
                "Age": "adult",
                "Cut": "shoulder straps",
                "Fit": "flared",
                "Belt": "no belt",
                "Color": "dark blue and navy",
                "Design": "plain",
                "Gender": "women",
                "Length": "long",
                "Closure": "no closure",
                "Sleeves": "sleeveless",
                "Category": "Clothing/Overalls and Dungarees",
                "Material": "cotton",
                "Neckline": "off shoulder",
                "Subcategory": "casual overalls",
                "Top Category": "Clothing"
            },
            "ximilar_category": [],
            "created_at": "2023-08-06T03:47:39.000000Z",
            "updated_at": "2023-08-06T03:47:39.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 53969165,
            "sku": "b1585be3",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 625,
            "link": "https://www.boohoo.com/petite-reverse-waistband-tailored-wide-leg-trousers/GZZ54506.html?color=265",
            "name": "Petite Reverse Waistband Tailored Wide Leg Trousers",
            "status": 1,
            "version": "202305170000",
            "gender": "WOMEN",
            "category_id": 1291,
            "category": null,
            "clicks": 42,
            "views": 187,
            "external_image": "https://media.boohoo.com/i/boohoo/gzz54506_grey%20marl_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "28.00",
            "sale_price": "25.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/RDIFC5SfHSdwnesfyxZzE/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/RDIFC5SfHSdwnesfyxZzE/original.jpg",
            "tags": [
                {
                    "color": "gray",
                    "category": "culottes"
                },
                {
                    "color": "white",
                    "category": "tops"
                },
                {
                    "color": "white",
                    "category": "belt"
                },
                {
                    "color": "white",
                    "category": "sneakers"
                }
            ],
            "ximilar_tags": {
                "Age": "adult",
                "Cut": "shoulder straps",
                "Fit": "wide leg",
                "Belt": "belt",
                "Color": "grey",
                "Design": "plain",
                "Gender": "women",
                "Length": "long",
                "Closure": "no closure",
                "Sleeves": "sleeveless",
                "Category": "Clothing/Overalls and Dungarees",
                "Material": "silk",
                "Neckline": "round",
                "Subcategory": "elegant overalls",
                "Top Category": "Clothing"
            },
            "ximilar_category": [],
            "created_at": "2023-08-06T03:48:31.000000Z",
            "updated_at": "2023-08-06T03:48:31.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 59983206,
            "sku": "2f34489f",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 625,
            "link": "https://www.boohoo.com/neon-tailored-city-shorts/GZZ49691.html?color=366",
            "name": "Neon Tailored City Shorts",
            "status": 1,
            "version": "202306210000",
            "gender": "WOMEN",
            "category_id": 1401,
            "category": null,
            "clicks": 33,
            "views": 140,
            "external_image": "https://media.boohoo.com/i/boohoo/gzz49691_neon-orange_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "18.00",
            "sale_price": "12.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/200eG6oNo-rm1ZQV8g792/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/200eG6oNo-rm1ZQV8g792/original.jpg",
            "tags": null,
            "ximilar_tags": {
                "Age": "adult",
                "Belt": "belt",
                "Color": "yellow",
                "Style": "elegant",
                "Design": "plain",
                "Gender": "women",
                "Length": "middle",
                "Lining": "double-breasted",
                "Closure": "full closure",
                "Category": "Clothing/Jackets and Coats",
                "Material": "wool",
                "Fastening": "fastening",
                "Subcategory": "wool coats",
                "Top Category": "Clothing",
                "Fastening type": "buttons"
            },
            "ximilar_category": [],
            "created_at": "2023-08-06T03:45:13.000000Z",
            "updated_at": "2023-08-06T03:45:13.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 61243983,
            "sku": "a7b45745",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 625,
            "link": "https://www.boohoo.com/plus-high-waisted-side-split-denim-midaxi-skirt/GZZ56371.html?color=340",
            "name": "Plus High Waisted Side Split Denim Midaxi Skirt",
            "status": 1,
            "version": "202307020000",
            "gender": "WOMEN",
            "category_id": 1291,
            "category": null,
            "clicks": 32,
            "views": 136,
            "external_image": "https://media.boohoo.com/i/boohoo/gzz56371_mid%20blue_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "30.00",
            "sale_price": "20.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/Z3R1JaW-ccz_jH03-6FkN/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/Z3R1JaW-ccz_jH03-6FkN/original.jpg",
            "tags": null,
            "ximilar_tags": {
                "Age": "adult",
                "Cut": "shorted",
                "Belt": "no belt",
                "Type": "buttons",
                "Color": "beige",
                "Style": "smart casual",
                "Design": "plain",
                "Gender": "women",
                "Lining": "single-breasted",
                "Closure": "full closure",
                "Sleeves": "long sleeves",
                "Category": "Clothing/Upper",
                "Material": "denim",
                "Neckline": "square",
                "Fastening": "fastening",
                "Top Category": "Clothing"
            },
            "ximilar_category": [],
            "created_at": "2023-08-06T03:45:13.000000Z",
            "updated_at": "2023-08-06T03:45:13.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 61566118,
            "sku": "907c4b66",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 625,
            "link": "https://www.boohoo.com/checkerboard-racer-neck-bodysuit/GZZ59520.html?color=105",
            "name": "Checkerboard Racer Neck Bodysuit",
            "status": 1,
            "version": "202307050000",
            "gender": "WOMEN",
            "category_id": 1291,
            "category": null,
            "clicks": 27,
            "views": 111,
            "external_image": "https://media.boohoo.com/i/boohoo/gzz59520_black_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "15.00",
            "sale_price": "11.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/O3r_gwTdxUq6XvhBcygq1/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/O3r_gwTdxUq6XvhBcygq1/original.jpg",
            "tags": null,
            "ximilar_tags": {
                "Age": "adult",
                "Cut": "shoulder straps",
                "Belt": "no belt",
                "Color": "multicolor",
                "Skirt": "a-line skirts",
                "Style": "casual",
                "Design": "patterned",
                "Gender": "women",
                "Length": "mini",
                "Closure": "no closure",
                "Pattern": "checked",
                "Sleeves": "sleeveless",
                "Category": "Clothing/Dresses",
                "Neckline": "back",
                "Subcategory": "fit & flare dresses",
                "Top Category": "Clothing"
            },
            "ximilar_category": [],
            "created_at": "2023-08-06T03:43:36.000000Z",
            "updated_at": "2023-08-06T03:43:36.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 61566120,
            "sku": "3a7851ec",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 625,
            "link": "https://www.boohoo.com/pinstripe-oversized-beach-shirt-dress/GZZ59719.html?color=564",
            "name": "Pinstripe Oversized Beach Shirt Dress",
            "status": 1,
            "version": "202307050000",
            "gender": "WOMEN",
            "category_id": 1291,
            "category": null,
            "clicks": 21,
            "views": 105,
            "external_image": "https://media.boohoo.com/i/boohoo/gzz59719_light%20blue_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "22.00",
            "sale_price": "15.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/pesgXVJAI4X65nzo0g-rU/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/pesgXVJAI4X65nzo0g-rU/original.jpg",
            "tags": null,
            "ximilar_tags": {
                "Age": "adult",
                "Belt": "no belt",
                "Type": "buttons",
                "Color": "multicolor",
                "Style": "smart casual",
                "Design": "patterned",
                "Gender": "women",
                "Lining": "single-breasted",
                "Closure": "full closure",
                "Pattern": "stripe",
                "Sleeves": "long sleeves",
                "Category": "Clothing/Upper",
                "Material": "knitwear",
                "Neckline": "bow",
                "Fastening": "fastening",
                "Subcategory": "shirts",
                "Top Category": "Clothing"
            },
            "ximilar_category": [],
            "created_at": "2023-08-06T03:46:44.000000Z",
            "updated_at": "2023-08-06T03:46:44.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 61684058,
            "sku": "dd55492d",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 625,
            "link": "https://www.boohoo.com/abstract-scoop-neck-maxi-dress/GZZ64880.html?color=155",
            "name": "Abstract Scoop Neck Maxi Dress",
            "status": 1,
            "version": "202307060000",
            "gender": "WOMEN",
            "category_id": 1291,
            "category": null,
            "clicks": 20,
            "views": 87,
            "external_image": "https://media.boohoo.com/i/boohoo/gzz64880_pink_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "18.00",
            "sale_price": "9.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/DEbft-oZlr1rBBvoYNJLb/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/DEbft-oZlr1rBBvoYNJLb/original.jpg",
            "tags": null,
            "ximilar_tags": {
                "Age": "adult",
                "Cut": "spaghetti straps",
                "Belt": "no belt",
                "Color": "pink",
                "Skirt": "mermaid skirts",
                "Style": "evening dress",
                "Design": "shiny",
                "Gender": "women",
                "Length": "mini",
                "Closure": "no closure",
                "Sleeves": "sleeveless",
                "Category": "Clothing/Dresses",
                "Neckline": "square",
                "Subcategory": "blouse dresses",
                "Top Category": "Clothing"
            },
            "ximilar_category": [],
            "created_at": "2023-08-06T03:45:13.000000Z",
            "updated_at": "2023-08-06T03:45:13.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 61894764,
            "sku": "69274d52",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 625,
            "link": "https://www.boohoo.com/textured-scoop-back-beach-maxi-dress/GZZ63081.html?color=155",
            "name": "Textured Scoop Back Beach Maxi Dress",
            "status": 1,
            "version": "202307080000",
            "gender": "WOMEN",
            "category_id": 1291,
            "category": null,
            "clicks": 20,
            "views": 89,
            "external_image": "https://media.boohoo.com/i/boohoo/gzz63081_pink_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "18.00",
            "sale_price": "12.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/-aLXhwL5DQs73ywLbjbou/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/-aLXhwL5DQs73ywLbjbou/original.jpg",
            "tags": null,
            "ximilar_tags": {
                "Age": "adult",
                "Cut": "spaghetti straps",
                "Belt": "no belt",
                "Color": "pink",
                "Skirt": "mermaid skirts",
                "Style": "evening dress",
                "Design": "plain",
                "Gender": "women",
                "Length": "maxi",
                "Closure": "no closure",
                "Sleeves": "sleeveless",
                "Category": "Clothing/Dresses",
                "Material": "silk",
                "Neckline": "square",
                "Subcategory": "sheath dresses",
                "Top Category": "Clothing"
            },
            "ximilar_category": [],
            "created_at": "2023-08-06T03:43:36.000000Z",
            "updated_at": "2023-08-06T03:43:36.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 61895773,
            "sku": "27e84cc6",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 625,
            "link": "https://www.boohoo.com/wave-print-racer-neck-maxi-dress/GZZ59722.html?color=741",
            "name": "Wave Print Racer Neck Maxi Dress",
            "status": 1,
            "version": "202307080000",
            "gender": "WOMEN",
            "category_id": 1291,
            "category": null,
            "clicks": `7`,
            "views": 60,
            "external_image": "https://media.boohoo.com/i/boohoo/gzz59722_ocean%20blue_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "25.00",
            "sale_price": "17.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/raoZ4p8j2AsHZRftja8Vi/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/raoZ4p8j2AsHZRftja8Vi/original.jpg",
            "tags": null,
            "ximilar_tags": {
                "Age": "adult",
                "Cut": "shoulder straps",
                "Fit": "skinny",
                "Belt": "no belt",
                "Color": "turquoise",
                "Design": "lace",
                "Gender": "women",
                "Length": "long",
                "Closure": "no closure",
                "Sleeves": "sleeveless",
                "Category": "Clothing/Overalls and Dungarees",
                "Material": "mesh/transparent",
                "Subcategory": "casual overalls",
                "Top Category": "Clothing"
            },
            "ximilar_category": [],
            "created_at": "2023-08-02T01:06:08.000000Z",
            "updated_at": "2023-08-02T01:06:08.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 62297508,
            "sku": "a0a33e23",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 625,
            "link": "https://www.boohoo.com/jersey-crop-top/GZZ61585.html?color=106",
            "name": "Jersey Crop Top",
            "status": 1,
            "version": "202307110000",
            "gender": "WOMEN",
            "category_id": 1291,
            "category": null,
            "clicks": 15,
            "views": 55,
            "external_image": "https://media.boohoo.com/i/boohoo/gzz61585_blue_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "10.00",
            "sale_price": "7.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/YOXSQjkCgiJOjExKnAuF0/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/YOXSQjkCgiJOjExKnAuF0/original.jpg",
            "tags": null,
            "ximilar_tags": {
                "Age": "adult",
                "Cut": "shorted",
                "Belt": "no belt",
                "Color": "light blue",
                "Style": "sport",
                "Design": "plain",
                "Gender": "women",
                "Closure": "no closure",
                "Sleeves": "short sleeves",
                "Category": "Clothing/Upper",
                "Material": "cotton",
                "Neckline": "crew",
                "Subcategory": "t-shirts",
                "Top Category": "Clothing"
            },
            "ximilar_category": [],
            "created_at": "2023-08-06T03:43:36.000000Z",
            "updated_at": "2023-08-06T03:43:36.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 62411501,
            "sku": "4449508d",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 625,
            "link": "https://www.boohoo.com/knitted-keyhole-cut-out-maxi-beach-dress/GZZ60675.html?color=105",
            "name": "Knitted Keyhole Cut Out Maxi Beach Dress",
            "status": 1,
            "version": "202307120000",
            "gender": "WOMEN",
            "category_id": 1291,
            "category": null,
            "clicks": 15,
            "views": 52,
            "external_image": "https://media.boohoo.com/i/boohoo/gzz60675_black_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "18.00",
            "sale_price": "12.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/LON8wio2fl8KcmW7HfzP4/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/LON8wio2fl8KcmW7HfzP4/original.jpg",
            "tags": null,
            "ximilar_tags": {
                "Rise": "low rise",
                "Color": "black",
                "Style": "elegant",
                "Design": "patterned",
                "Gender": "women",
                "Length": "midi",
                "Pattern": "polka dot",
                "Category": "Clothing/Skirts",
                "Subcategory": "mermaid skirts",
                "Top Category": "Clothing"
            },
            "ximilar_category": [],
            "created_at": "2023-08-06T03:46:44.000000Z",
            "updated_at": "2023-08-06T03:46:44.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 62411625,
            "sku": "892d4b24",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 625,
            "link": "https://www.boohoo.com/mesh-ruffle-high-split-maxi-skirt/GZZ60072.html?color=155",
            "name": "Mesh Ruffle High Split Maxi Skirt",
            "status": 1,
            "version": "202307120000",
            "gender": "WOMEN",
            "category_id": 1291,
            "category": null,
            "clicks": 12,
            "views": 44,
            "external_image": "https://media.boohoo.com/i/boohoo/gzz60072_pink_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "20.00",
            "sale_price": "13.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/R3jaJfu7cl3lrs_hiReFl/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/R3jaJfu7cl3lrs_hiReFl/original.jpg",
            "tags": null,
            "ximilar_tags": {
                "Rise": "low rise",
                "Color": "pink",
                "Style": "elegant",
                "Design": "text",
                "Gender": "women",
                "Length": "maxi",
                "Category": "Clothing/Skirts",
                "Material": "silk",
                "Subcategory": "formal skirts",
                "Top Category": "Clothing"
            },
            "ximilar_category": [],
            "created_at": "2023-08-06T03:43:36.000000Z",
            "updated_at": "2023-08-06T03:43:36.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 62411928,
            "sku": "896044d6",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 625,
            "link": "https://www.boohoo.com/panel-detail-moto-jacket/GZZ55562.html?color=105",
            "name": "Panel Detail Moto Jacket",
            "status": 1,
            "version": "202307120000",
            "gender": "WOMEN",
            "category_id": 1496,
            "category": null,
            "clicks": 10,
            "views": 42,
            "external_image": "https://media.boohoo.com/i/boohoo/gzz55562_black_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "40.00",
            "sale_price": "32.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/WO77CTOkkm-tF8QZfQCeJ/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/WO77CTOkkm-tF8QZfQCeJ/original.jpg",
            "tags": null,
            "ximilar_tags": {
                "Age": "adult",
                "Belt": "belt",
                "Color": "multicolor",
                "Style": "casual",
                "Design": "plain",
                "Gender": "women",
                "Length": "short",
                "Closure": "full closure",
                "Category": "Clothing/Jackets and Coats",
                "Material": "textile",
                "Fastening": "fastening",
                "Top Category": "Clothing",
                "Fastening type": "zipper"
            },
            "ximilar_category": [],
            "created_at": "2023-08-06T03:47:39.000000Z",
            "updated_at": "2023-08-06T03:47:39.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 62411930,
            "sku": "a66147c0",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 625,
            "link": "https://www.boohoo.com/oversized-tonal-moto-jacket/GZZ55561.html?color=105",
            "name": "Oversized Tonal Moto Jacket",
            "status": 1,
            "version": "202307120000",
            "gender": "WOMEN",
            "category_id": 1496,
            "category": null,
            "clicks": 7,
            "views": 37,
            "external_image": "https://media.boohoo.com/i/boohoo/gzz55561_black_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "45.00",
            "sale_price": "39.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/WTF_1xEnqUtv2YMb9RWXF/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/WTF_1xEnqUtv2YMb9RWXF/original.jpg",
            "tags": null,
            "ximilar_tags": {
                "Age": "adult",
                "Belt": "no belt",
                "Color": "black",
                "Style": "casual",
                "Design": "plain",
                "Gender": "women",
                "Length": "short",
                "Closure": "full closure",
                "Category": "Clothing/Jackets and Coats",
                "Material": "leather & suede",
                "Fastening": "fastening",
                "Subcategory": "biker jacket",
                "Top Category": "Clothing",
                "Fastening type": "zipper"
            },
            "ximilar_category": [],
            "created_at": "2023-08-06T03:46:44.000000Z",
            "updated_at": "2023-08-06T03:46:44.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        },
        {
            "id": 62411933,
            "sku": "451341bc",
            "excluded": 0,
            "exclude_discovery": 0,
            "retailer_feed_id": 625,
            "link": "https://www.boohoo.com/slim-fit-moto-jacket/GZZ55560.html?color=105",
            "name": "Slim Fit Moto Jacket",
            "status": 1,
            "version": "202307120000",
            "gender": "WOMEN",
            "category_id": 1496,
            "category": null,
            "clicks": 6,
            "views": 29,
            "external_image": "https://media.boohoo.com/i/boohoo/gzz55560_black_xl?w=900&qlt=default&fmt.jp2.qlt=70&fmt=auto&sm=fit",
            "price": "40.00",
            "sale_price": "30.00",
            "currency": "GBP",
            "image": "https://d2sl3ai4g6turs.cloudfront.net/feed/CYDMe_tfejg5WAhq48fQv/original.jpg",
            "img": "https://d2sl3ai4g6turs.cloudfront.net/feed/CYDMe_tfejg5WAhq48fQv/original.jpg",
            "tags": null,
            "ximilar_tags": {
                "Age": "adult",
                "Belt": "no belt",
                "Color": "black",
                "Style": "casual",
                "Design": "plain",
                "Gender": "women",
                "Length": "short",
                "Closure": "full closure",
                "Category": "Clothing/Jackets and Coats",
                "Material": "leather & suede",
                "Fastening": "fastening",
                "Subcategory": "biker jacket",
                "Top Category": "Clothing",
                "Fastening type": "zipper"
            },
            "ximilar_category": [],
            "created_at": "2023-08-06T03:43:36.000000Z",
            "updated_at": "2023-08-06T03:43:36.000000Z",
            "blocks": 0,
            "brand": {
                "id": 870,
                "name": "Boohoo"
            },
            "feeder_brand": {
                "id": 870,
                "name": "Boohoo"
            }
        }
    ],
    "info": {
        "current_page": 1,
        "total_pages": 33,
        "total_items": 490,
        "page_count": 15
    }
}