import {
  GET_TRANSLATIONS_SUCCESS,
  GET_TRANSLATIONS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  translations: []
}

const Translations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        translations: action?.payload,
      }
      break
    case GET_TRANSLATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break
    default:
      return state
      break
  }
  return state;
}

export default Translations
