import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {
  Button,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Magento from './../../../assets/images/magento-logo-2.png';
import PrestaShop from './../../../assets/images/prestashop-logo.png';
import SendEmail from './../../../assets/images/send-email.png';
import Shopify from './../../../assets/images/shopify-logo.png';
import Woocommerce from './../../../assets/images/woocommerce-logo.png';
import './Integrations.scss';
import CardProject from './CardProject';
//@ts-ignore
import {AvForm, AvField} from 'availity-reactstrap-validation';
import FormInput from '../../../components/Common/FormInput';

import {get, post} from '../../../helpers/api_helper';
import {openAlert} from '../../../store/actions';
import Websites from '../Websites';
import {useHistory} from 'react-router-dom';

interface Props extends RouteComponentProps {
  returnTranslation: (text: string) => string;
}

const DownloadPlugin = ({returnTranslation}: Props) => {
  const history = useHistory();

  const [integrations] = useState([
    {
      id: 1,
      img: Woocommerce,
      name: 'Woocommerce',
      description: 'Download Woocommerce plugin',
      // "configureLink": "/plugin/integrations/woocommerce",
      downloadUrl: '/assets/loop-woocommerce.zip',
    },
    {
      id: 2,
      img: PrestaShop,
      name: 'Prestashop',
      description: 'Download Prestashop plugin',
      // "configureLink": "/plugin/integrations/prestashop",
      downloadUrl: '/assets/loop-prestashop.zip',
    },
    {
      id: 3,
      img: Shopify,
      name: 'Shopify',
      description: 'Download Shopify plugin',
      // "configureLink": "/plugin/integrations/shopify",
      downloadUrl: '',
      customBtnText: 'Read Manual',
      btnOnClick: () => history.push(`/plugin/manual/shopify/{uuid}`),
    },
    {
      id: 4,
      img: Magento,
      name: 'Magento',
      description: 'Download Magento plugin',
      // "configureLink": "/plugin/integrations/magento",
      downloadUrl: '',
    },
    // {
    //     "id": 5,
    //     "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/480px-Unofficial_JavaScript_logo_2.svg.png",
    //     "name": "Manual Integration",
    //     // "description": "Add new webiste and configure plugin",
    //     "description": "Add new wesbite and configure plugin with Javascript",
    //     "configureLink": "/plugin/integrations/javascript",
    //     // "downloadUrl": ""
    // },
    {
      id: 6,
      img: SendEmail,
      name: 'Is your platform missing?',
      // "description": "Add new webiste and configure plugin",
      description: 'Contact us via this simple form',
      customBtnText: 'Contact Us',
      btnOnClick: () => setIsModalOpen(true),
    },
  ]);

  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phone, setPhone] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const user = useSelector((state: any) => state?.Account?.user);

  useEffect(() => {
    setPhone(user?.contact_details?.phone);
  }, [user?.contact_details?.phone]);

  const handleSubmit = async (e: any, values: any) => {
    try {
      e?.preventDefault();

      setIsSending(true);
      // console.log(['values', values]);
      const response = await post('/supports', {
        ...values,
        phone,
        subject_id: 2,
      });

      dispatch(openAlert({text: 'Request has been sent.', status: 'success'}));
      setIsModalOpen(!isModalOpen);
    } catch (e) {
      dispatch(openAlert({text: 'Error. Try again later.', status: 'danger'}));
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={returnTranslation('Plugin')}
            breadcrumbItem={returnTranslation('Integrations')}
          />

          <h4 className="card-title mb-2">
            {returnTranslation('Download Plugin')}
          </h4>
          <span>
            {returnTranslation(
              'Download .zip file with LOOP plugin for Your platform.',
            )}
          </span>
          {/* <div className="d-flex flex-wrap gap-3"> */}
          <CardProject projects={integrations} />

          {/* <h4 className="card-title mt-4 mb-2">{returnTranslation("My Integrations")}</h4>
                    <Websites returnTranslation={returnTranslation} /> */}
        </Container>
      </div>

      <Modal
        isOpen={!!isModalOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        toggle={(ev: any) => setIsModalOpen(!isModalOpen)}>
        <div className="modal-content">
          <ModalHeader>
            {returnTranslation(`Send integration request to our support team`)}
          </ModalHeader>
          <ModalBody>
            <AvForm id="modal-form" onValidSubmit={handleSubmit}>
              <AvField
                name="name"
                label={'Contact Name'}
                className="form-control"
                placeholder={returnTranslation('Name')}
                type="text"
                resize={false}
                defaultValue={
                  (user?.contact_details?.first_name
                    ? `${user?.contact_details?.first_name}`
                    : '') +
                  (user?.contact_details?.last_name
                    ? ` ${user?.contact_details?.last_name}`
                    : '')
                }
                required={true}
              />
              <AvField
                name="email"
                label={'Contact Email'}
                className="form-control"
                placeholder={returnTranslation('Email')}
                type="email"
                resize={false}
                defaultValue={user?.company_details?.email}
                required={true}
              />
              <div className="mb-2">
                <div className="form-group">
                  <label>Contact Phone</label>
                  <FormInput
                    className=""
                    name="phone"
                    type="phone"
                    placeholder="+44 7987 654321"
                    value={phone ? phone : ''}
                    onChange={phone => setPhone(phone)}
                    formErrors={formErrors}
                    showErrors={true}
                  />
                </div>
              </div>
              <AvField
                name="platform"
                label={'Ecommerce platform/engine'}
                className="form-control"
                placeholder={returnTranslation('Platform or engine name')}
                type="text"
                resize={false}
                required={true}
              />
              <AvField
                name="description"
                label={'Description'}
                className="form-control"
                placeholder={returnTranslation('Description')}
                type="textarea"
                rows={3}
                resize={false}
              />
            </AvForm>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={e => {
                // handleSubmit(e, true)
                e?.preventDefault();
                setIsModalOpen(!isModalOpen);
              }}
              color="secondary"
              type="button">
              {returnTranslation('Cancel')}
            </Button>
            <Button
              type="submit"
              color="success"
              form="modal-form"
              disabled={isSending}>
              {returnTranslation('Send')}
              {isSending ? <Spinner color="dark" className="ms-2" /> : null}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};

export default DownloadPlugin;
