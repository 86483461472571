import {
  GET_LOCAL_STORES,
  GET_LOCAL_STORES_SUCCESS,
  GET_LOCAL_STORES_FAIL,
  DELETE_LOCAL_STORE,
  CLEAR_LOCAL_STORES,
  SET_LOCAL_STORES_QUERY_OBJECT
} from "./actionTypes"

export const getLocalStores = (payload) => ({
  type: GET_LOCAL_STORES,
  payload: {
    takebackId: payload?.takebackId,
    page: payload?.page ? payload?.page : 1,
    queryObject: payload?.queryObject ? payload?.queryObject : {},
    preventLoader: payload?.preventLoader
  }
})

export const getLocalStoresSuccess = data => ({
  type: GET_LOCAL_STORES_SUCCESS,
  payload: data,
})

export const getLocalStoresFail = error => ({
  type: GET_LOCAL_STORES_FAIL,
  payload: error,
})

// export const changePostStatus = (id, status) => {
//   return {
//     type: CHANGE_POST_STATUS,
//     payload: { id, status },
//   }
// }

export const deleteLocalStore = (id) => {
  return {
    type: DELETE_LOCAL_STORE,
    payload: { id },
  }
}

export const clearLocalStores = (remainFilters) => {
  return {
    type: CLEAR_LOCAL_STORES,
    payload: { remainFilters }
  }
}

export const changeLocalStoresQueryObject = (queryObject) => {
  return {
    type: SET_LOCAL_STORES_QUERY_OBJECT,
    payload: { queryObject },
  }
}