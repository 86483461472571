import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CouponCodesTableColumns from './CouponCodesTableColumns'
import * as url from "../../../../helpers/url_helper"
import BasicTable from '../../../../components/BasicTable/BasicTable';
import { openAlert, requestConfirmation } from '../../../../store/actions';
import { activeCode, deleteCode } from '../../../../helpers/backend_helper';
import { RouteComponentProps } from 'react-router-dom';

interface Props {
    returnTranslation: (text: string) => string;
    triggerRefresh: boolean; //triggerRefresh - pass false on init to prevent fetching codes from api
    couponId: any;
}

function CouponCodesTable({ returnTranslation, triggerRefresh, couponId }: Props) {
    const dispatch = useDispatch();
    const [allowRefresh, setAllowRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const dashboardID = useSelector((state: any) => state?.Account?.dashbord_id)
    const userToken = useSelector((state: any) => state?.Account?.user?.access_token)
    const [couponCodesData, setCouponCodesData] = useState({
        items: [],
        info: {
            total_items: 0,
            page_count: 15,
            current_page: 1
        }
    })

    useEffect(() => {
        if (allowRefresh) {
            getCodes(1)
        } else if (triggerRefresh) {
            setAllowRefresh(true)
            getCodes(1)
        }
    }, [triggerRefresh])

    const getCodes = (page: number = 1) => {
        // console.log(['getCodes', page]);
        return new Promise(async (resolve, reject) => {
            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                    "Client-UUID": dashboardID,
                    Authorization: `Bearer ${userToken}`,
                    "Custom-User-Agent": window.navigator.userAgent
                },
            };
            setIsLoading(true)
            axios
                .get(`${url.API_BASE}retailers/coupons/${location.pathname.split('/').pop()}/codes?page=${page}`, config)
                .then(response => {
                    if (response.data) {
                        const { info: { page_count, current_page } } = response.data;
                        setCouponCodesData(response.data);
                    }

                    return resolve(response)
                })
                .catch(error => {
                    if (error?.response?.data) {
                        // const { message, errors } = error?.response?.data
                        // this.props.onOpenAlert({
                        //   text: message,
                        //   status: "danger",
                        // })
                    }
                    return reject(error)
                }).finally(() => setIsLoading(false))
        })
    }

    const handleDelete = (uuid: string, name: string) => {
        dispatch(
            requestConfirmation(`${returnTranslation("Delete")} ${name}?`, () => confirmDelete(uuid))
        )
    }

    const confirmDelete = async (id: string) => {
        try {
            await deleteCode({ coupon_id: couponId, code_id: id })
            getCodes(1)
            dispatch(openAlert({ text: returnTranslation("Code deleted"), status: "success" }))
        } catch (e) {
            dispatch(openAlert({ text: returnTranslation("An error occured"), status: "danger" }))
        }
    }

    const handleSetActive = async (id: string, active: number) => {
        try {
            await activeCode({ coupon_id: couponId, code_id: id, active })
            getCodes(1)
            if (active) {
                dispatch(openAlert({ text: returnTranslation("Code activated"), status: "success" }))
            } else {
                dispatch(openAlert({ text: returnTranslation("Code deactivated"), status: "success" }))
            }
        } catch (e) {
            dispatch(openAlert({ text: returnTranslation("An error occured"), status: "danger" }))
        }
    }

    const handleSetItemActive = async (id: string) => {
        handleSetActive(id, 1)
    }

    const handleSetItemInactive = async (id: string) => {
        handleSetActive(id, 0)
    }

    return (
        <>
            <h4 className="mb-0 font-size-18">
                {returnTranslation("Codes")}
            </h4>
            <BasicTable
                items={couponCodesData.items}
                columns={CouponCodesTableColumns({ returnTranslation, handleDelete, handleSetActive })}
                searchbar={false}
                getFunction={(page: number) => getCodes(page)}
                totalSize={couponCodesData.info?.total_items}
                sizePerPage={couponCodesData.info?.page_count}
                currentPage={couponCodesData.info?.current_page}
                isLoading={isLoading}
                displaySelectMultiple={true}
                selectMultipleOptions={[
                    {
                        'name': 'Set Active',
                        action: (id: string) => handleSetItemActive(id)
                    },
                    {
                        'name': 'Set Inactive',
                        action: (id: string) => handleSetItemInactive(id)
                    },
                    {
                        'name': 'Delete',
                        action: (id: string) => confirmDelete(id),
                        requestConfirmation: (successCb: any) => dispatch(requestConfirmation(returnTranslation('Delete selected rows?'), successCb)),
                    }
                ]}
            />
        </>
    )
}

export default CouponCodesTable
