// @ts-ignore
import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
// @ts-ignore
import MetaTags from 'react-meta-tags';
import 'react-phone-input-2/lib/high-res.css';
// @ts-ignore
import { connect } from "react-redux";
// @ts-ignore
import { RouteComponentProps } from "react-router";
// @ts-ignore
import { withRouter } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Row
} from "reactstrap";
import { apiError, registerUserSuccessful } from "../../store/actions";
import Breadcrumb from "./../../components/Common/Breadcrumb";
import { post } from "./../../helpers/api_helper";
import * as url from "./../../helpers/url_helper";
import { openAlert } from "./../../store/alert/actions";
import { editProfile } from "./../../store/auth/profile/actions";
import RegisterContactForm from "./Register/RegisterContact/RegisterContactForm";
import RegisterDetailsForm, { RegisterSubmitData } from "./Register/RegisterDetails/RegisterDetailsForm";
import RegisterSocialsForm from "./Register/RegisterSocials/RegisterSocialsForm";
import moment from "moment-timezone";
import FormInput from "../../components/Common/FormInput";

export interface UserChangePasswordPropsInterface extends RouteComponentProps {
    dashbord_id: string,
    apiError: (err: string) => {},
    history: any,
    location: any,
    error: string,
    onGetCountries: any,
    onProfileUpdate: any,
    user: any,
    onOpenAlert: any,
    countries: any[],
}

const UserChangePassword = ({
    onGetCountries,
    countries,
    onProfileUpdate,
    user,
    onOpenAlert,
    history,
    location,
    error
}: UserChangePasswordPropsInterface) => {
    const [formErrors, setFormErrors] = useState<any>({})

    const handleValidProfileSubmit = (data: RegisterSubmitData | any) => {
        setFormErrors({});
        let userData = {
            id: user?.id,
            ...data
        }

        onProfileUpdate(userData, setFormErrors)
    }

    const handlePasswordChange = async (event: any) => {
        event.preventDefault()
        try {
            const formData = new FormData(event?.target);
            const values: any = {};
            Array.from(formData.entries())?.map(([key, value]) => {
                values[key] = value
            });
            const {
                previous_password,
                password,
                password_confirmation
            } = values;

            const response = await post(`${url.API_BASE}users/change_password`, {
                previous_password,
                password,
                password_confirmation
            })
            setFormErrors({})
            onOpenAlert({
                text: "Password changed successfuly.",
                status: "success",
            })
        } catch (error) {
            //@ts-ignore
            if (error?.response?.data) {
                //@ts-ignore
                const { message, errors } = error?.response?.data
                setFormErrors(errors)
                onOpenAlert({
                    text: message,
                    status: "danger",
                })
            }
        }
    }

    return (
        <>
            <MetaTags>
                <title>LOOP | Change Password</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb title="LOOP" breadcrumbItem="Change Password" />

                    <Row>
                        <Col sm={12} md={6} lg={6} xl={6}>


                            <Card>
                                <CardBody>
                                    <h3 className="fw-bold">Change Password</h3>
                                    <Form
                                        className="form-horizontal"
                                        onSubmit={handlePasswordChange}
                                    >
                                        <div className="mb-3">
                                            <FormInput
                                                name="previous_password"
                                                label="Old password"
                                                className="form-control"
                                                placeholder="Enter old password"
                                                type="password"
                                                required
                                                showErrors={true}
                                                formErrors={formErrors}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <FormInput
                                                name="password"
                                                label="New password"
                                                className="form-control"
                                                placeholder="Enter new password"
                                                type="password"
                                                required
                                                showErrors={true}
                                                formErrors={formErrors}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <FormInput
                                                name="password_confirmation"
                                                label="New password confirmation"
                                                className="form-control"
                                                placeholder="Enter new password confirmation"
                                                type="password"
                                                required
                                                showErrors={true}
                                                formErrors={formErrors}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Button type="submit" className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light">
                                                Update
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>

                </Container>
            </div>
        </>
    )
}

const mapStateToProps = (state: any) => {
    const { error } = state.Login
    const { dashbord_id, user } = state.Account
    const { countries } = state.Countries
    return { error, dashbord_id, user, countries }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        onGetCountries: () => dispatch({ type: "GET_COUNTRIES" }),
        onApiError: () => apiError(),
        onOpenAlert: ({ text, status }: any) =>
            dispatch(openAlert({ text: text, status: status })),
        onRegisterUserSuccessful: (user: any) => dispatch(registerUserSuccessful(user)),
        onProfileUpdate: (user: any, errorCb: any) =>
            dispatch(
                editProfile({
                    user,
                    errorCb,
                    successCb: () => dispatch(openAlert({ text: "Details updated.", status: "success" }))
                })
            ),
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UserChangePassword)
)
