import {
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  countries: []
}

const Countries = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action ?.payload,
      }
      break
    case GET_COUNTRIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break
    default:
      return state
      break
  }
  return state;
}

export default Countries
