import React, {useEffect, useState} from 'react';
// @ts-ignore
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  Button,
  Label,
  Row,
  Col,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from 'reactstrap';
//@ts-ignore
import {CopyToClipboard} from 'react-copy-to-clipboard';
import 'photoswipe/dist/photoswipe.css';
import {Gallery, Item} from 'react-photoswipe-gallery';

interface CompleteSetupInterface {
  returnTranslation: (name: string) => string;
  selectedPlatform: string;
  generatedToken: string;
  displayCopiedAlert: any;
}

const CompleteSetup = ({
  returnTranslation,
  selectedPlatform,
  generatedToken,
  displayCopiedAlert,
}: CompleteSetupInterface) => {
  const history = useHistory();

  const shopifyCode = `
    <script id="loop_plugin_configuration_url" loop-configuration-uuid="${generatedToken}" src="https://app.loopdigitalwardrobe.com/js/plugin/loop-widget.js?v=1"></script>
    <script type="text/javascript">
      var loopCustomerItems = [];

      var loopOrderCreatedAt = Shopify.checkout.created_at;
      var loopOrderCurrency = Shopify.checkout.currency;
      var loopOrderNumber = Shopify.checkout.order_id;

      {% for line in checkout.line_items %}
        var loopProductImages = [];
        var loopCategoryPath = '';
        var loopGender = '';
        var loopSize = '';
        var loopColor = '';

        {% for image in line.product.images %}
          loopProductImages.push('https:' + '{{ image | image_url: width: 300 }}');
        {% endfor %}

        {% for collection in line.product.collections %}
          loopCategoryPath = '/' + '{{ collection.title }}';
        {% endfor %}

        {% for product_option in line.product.options_with_values %}
            {% if product_option.name == 'Color' %}
            {% for value in product_option.values %}
                {% if product_option.selected_value == value %}
                  var loopColor = '{{ value }}';
                {% endif %}
              {% endfor %}
          {% endif %}
            {% if product_option.name == 'Size' %}
            {% for value in product_option.values %}
                {% if product_option.selected_value == value %}
                  var loopSize = '{{ value }}';
                {% endif %}
              {% endfor %}
          {% endif %}
            {% if product_option.name == 'Gender' %}
            {% for value in product_option.values %}
                {% if product_option.selected_value == value %}
                  var loopGender = '{{ value }}';
                {% endif %}
              {% endfor %}
          {% endif %}
        {% endfor %}

        var loopCustomerItem = {
          name: '{{ line.product.title }}', 
          size: loopSize,
          color: loopColor,
          ean: '{{ line.sku }}',
          sku: '{{ line.sku }}', 
          brand: '{{ line.product.vendor }}',
          order_number: loopOrderNumber ? loopOrderNumber?.toString() : '',
          price: parseFloat({{ line.original_price | money_without_currency }}),
          final_price: parseFloat({{ line.final_price | money_without_currency }}),
          currency: loopOrderCurrency,
          buy_at: loopOrderCreatedAt,
          images: loopProductImages,
          img: loopProductImages ? loopProductImages[0] : '',
          category: loopCategoryPath,
          gender: loopGender
        };

        loopCustomerItems.push(loopCustomerItem);
      {% endfor %}

      window.LoopCustomerItems = loopCustomerItems;
    </script>
    <script>
      Shopify.Checkout.OrderStatus.addContentBox(
        '<div id="loop-digital-wardrobe"></div>'
      );
    </script>
    `;

  return (
    <Row>
      <Col xl="12" sm="12">
        {selectedPlatform === 'shopify' ? (
          <>
            <div className="padding-bottom-custom">
              <div className="iamge-gallery">
                <Gallery>
                  <Item
                    original="https://dashboard.loopdigitalwardrobe.com/assets/images/shopify/shopify-1.png"
                    thumbnail="https://dashboard.loopdigitalwardrobe.com/assets/images/shopify/shopify-1.png"
                    width="1463"
                    height="836">
                    {({ref, open}) => (
                      <img
                        ref={ref}
                        onClick={open}
                        src="https://dashboard.loopdigitalwardrobe.com/assets/images/shopify/shopify-1.png"
                        // height={200}
                      />
                    )}
                  </Item>
                  <Item
                    original="https://dashboard.loopdigitalwardrobe.com/assets/images/shopify/shopify-2.png"
                    thumbnail="https://dashboard.loopdigitalwardrobe.com/assets/images/shopify/shopify-2.png"
                    width="1463"
                    height="836">
                    {({ref, open}) => (
                      <img
                        ref={ref}
                        onClick={open}
                        src="https://dashboard.loopdigitalwardrobe.com/assets/images/shopify/shopify-2.png"
                        // height={200}
                      />
                    )}
                  </Item>
                  <Item
                    original="https://dashboard.loopdigitalwardrobe.com/assets/images/shopify/shopify-3.png"
                    thumbnail="https://dashboard.loopdigitalwardrobe.com/assets/images/shopify/shopify-3.png"
                    width="1463"
                    height="836">
                    {({ref, open}) => (
                      <img
                        ref={ref}
                        onClick={open}
                        src="https://dashboard.loopdigitalwardrobe.com/assets/images/shopify/shopify-3.png"
                        // height={200}
                      />
                    )}
                  </Item>
                </Gallery>
              </div>

              <h4 className="mt-4 font-weight-semibold">
                1. {returnTranslation('Plugin initialization')}
              </h4>
              <p className="text-muted mt-3">
                {returnTranslation(
                  'After logging in to the administration panel in the Shopify store, go to the ‘Settings’ tab, then to the ‘Checkout’ tab and paste the below code at the beginning of the ‘Additional scripts’ text field.',
                )}
              </p>
              <div className="alert alert-secondary fade show" role="alert">
                <p className="codeFormat">{shopifyCode && shopifyCode}</p>
              </div>
            </div>

            <div className=" mt-4">
              <Button
                onClick={() => history.push('/plugin/integrations')}
                color="success">
                {/* {params?.uuid ? returnTranslation("Update") : returnTranslation("Add website")} */}
                {returnTranslation('Done')}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="mb-3">
              <h6>1. Download and upload plugin on your platform</h6>
              <a
                href={
                  selectedPlatform === 'woocommerce'
                    ? '/assets/loop-woocommerce.zip'
                    : '/assets/loop-prestashop.zip'
                }
                //  target="_blank"
              >
                <button type="button" className="btn btn-success">
                  {returnTranslation('Download')}
                </button>
              </a>
            </div>

            <div>
              <h6>2. Your integration key is</h6>
              <div className="input-group mb-3">
                <Input
                  type="text"
                  className="form-control"
                  value={generatedToken}
                  id="inputGroupFile01"
                />
                <CopyToClipboard text={generatedToken}>
                  <Label
                    onClick={displayCopiedAlert}
                    className="input-group-text"
                    htmlFor={`inputGroupFile-${generatedToken}`}>
                    Copy
                  </Label>
                </CopyToClipboard>
              </div>
            </div>

            <div className=" mt-4">
              <Button
                onClick={() => history.push('/plugin/integrations')}
                color="success">
                {/* {params?.uuid ? returnTranslation("Update") : returnTranslation("Add website")} */}
                {returnTranslation('Save')}
              </Button>
            </div>
          </>
        )}
      </Col>
    </Row>
  );
};

export default CompleteSetup;
