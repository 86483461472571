import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//confirmPanel
import confirmPanel from "./confirmPanel/reducer"

//alert
import alert from "./alert/reducer"

//websites
import websites from "./websites/reducer"

//countries
import Countries from "./countries/reducer"

//Dictionaries
import Dictionaries from "./dictionaries/reducer"

//websites
import Codes from "./coupons/reducer"

//Currencies
import Currencies from "./currencies/reducer"

//Charity Shops
import CharityShops from "./charityShops/reducer"

//Transation
import Translations from "./translations/reducer"

//Payment Info
import PaymentInfo from "./paymentInfo/reducer"

//billings
import Billings from "./billings/reducer"

//invoices
import Invoices from "./invoices/reducer"

//FeedAnalytics
import FeedAnalytics from "./FeedAnalytics/reducer"

import TakeBacks from "./takeBacks/reducer"

import LocalStores from "./localStores/reducer"

import { RESET_APP_STATE } from "./actions"

const allReducers = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  confirmPanel,
  alert,
  websites,
  Countries,
  Dictionaries,
  Codes,
  Currencies,
  CharityShops,
  Translations,
  PaymentInfo,
  Billings,
  Invoices,
  FeedAnalytics,
  TakeBacks,
  LocalStores
})

//root reducer wrapper
const rootReducer = (state, action) => {
  if (action.type === RESET_APP_STATE) {
    // exclude reducers from reset
    const { Layout } = state;
    state = { Layout };
  }

  return allReducers(state, action);
};

export default rootReducer;
