import React, { useEffect, useState } from "react";
import { Progress } from "reactstrap";

const TopCouponsDisplayedTableColumns = (
    offsetIndex: number,
    t: (text: string) => string,
    queryObject: any,
    setQueryObject: (newQueryObject: any, forceReload?: boolean) => void,
    defaultQueryObject: any,
    maxProgressValue: number | undefined
    // handleDelete: (id: number) => void,
    // brandsCategories: SelectSearchOption[],
    // priceRanges: any[],
    // handleSynchronizeNow: (feederId: number) => void
) => {
  const [isFilterActive, setIsFilterActive] = useState(false)
  const [filters, setFilters] = useState<any>({})

  useEffect(() => {
    const queryObjectCopy = { ...queryObject };
    delete queryObjectCopy.sort
    delete queryObjectCopy.direction
    setFilters(queryObjectCopy);
  }, [])

  useEffect(() => {
    handleSetIsFilterActive();
  }, [queryObject])

  const handleSetFilters = (columnName?: string, value?: string | number | boolean) => {
    if(value !== undefined && value !== ""){
      //@ts-ignore
      setFilters({ ...filters, [columnName] : value})
    } else {
      const filtersCopy = {...filters};
      //@ts-ignore
      delete filtersCopy[columnName]
      setFilters(filtersCopy)
    }
  }

  const handleSetIsFilterActive = () => {
    setIsFilterActive(Object.keys(queryObject)?.length > Object.keys(defaultQueryObject)?.length)
  }

  const handleSubmitFilters = (event?: any) => {
    if(event){
      event.preventDefault()
    }
    // get already existing default query keys from query obejct
    // and copy to new queryObject with additional filters
    const defaultQueryKeys = {}
    Object.keys(defaultQueryObject).forEach((key: string) => {
      //@ts-ignore
      defaultQueryKeys[key] = queryObject[key];
    })

    setQueryObject({ ...defaultQueryKeys, ...filters}, true);
  }

  const handleResetFilters = () => {
    const { sort, direction } = queryObject;
    setQueryObject({ sort, direction });
    setFilters({})
    setIsFilterActive(false)

    // setSelectedBrandCategory({})
  }

  const globalColumnProps = {
    onSort: (column: any, order: any) => {
      setQueryObject({
        ...queryObject,
        sort: column,
        direction: order
      })
    },
  }

  const getFilterRenderer = (columnName: string, columnType: string, placeholder = "", description = "") => {
    if(['string', 'number'].includes(columnType)){
      return (
        <form onSubmit={handleSubmitFilters}>
          <input
            name={columnName}
            className={"form-control table-filter-input"}
            //@ts-ignore
            value={filters[columnName] ? filters[columnName] : ""}
            onChange={e => handleSetFilters(columnName, e.target.value)}
            placeholder={placeholder}
          />
          <small className="text-muted">{description}</small>
        </form>
      )
    }else if(columnType === 'boolean'){
      return (
        <form onSubmit={handleSubmitFilters}>
          {/* <input
            style={{
              height: 17,
              width: 17
            }}
            type="checkbox"
            name={columnName}
            className={"form-check-input"}
            checked={filters[columnName] ? filters[columnName] : false}
            onChange={e => handleSetFilters(columnName, filters[columnName] ? false : true)}
          /> */}
          <select
            style={{
              width: 41
            }}
            className="form-control text-center table-filter-select"
            value={filters[columnName] ? filters[columnName] : ""}
            onChange={e => handleSetFilters(columnName, e.target.value)}
          >
            {["", 1, 0].map(value => <option key={value} value={value}>{value === "" ? "All" : value}</option>)}
          </select>
        </form>
      )
    }
  }

  return [
    {
      dataField: "i",
      isDummyField: true,
      text: "#",
      formatter: (cellContent: any, row: any, rowIndex: number) => {
        return <div>#{rowIndex + 1 + offsetIndex}</div>
      },
    //   //@ts-ignore
    //   filter: customFilter(),
    //   filterRenderer: () => !isFilterActive
    //     ? <i className="mdi mdi-filter"/>
    //     : <i className="mdi mdi-filter-off" onClick={handleResetFilters}/>
    },
    // {
    //   dataField: "logo",
    //   text: t("Logo"),
    //   formatter: (cellContent: any, row: any, rowIndex: number) =>
    //       <img className="feeder-logo-miniature" height={45} src={row?.image} />
    // },
    {
      dataField: "coupon_name",
      text: t("Coupon")
    },
    {
      dataField: "coupon_description",
      text: t("Description"),
      formatter: (cellContent: any, row: any, rowIndex: number) => (
        <div className="text-truncate" style={{ maxWidth: 250}}>{row?.coupon_description}</div>
      )

    },
    // {
    //   dataField: "searched",
    //   text: t("Searched"),
    //   formatter: (cellContent: any, row: any, rowIndex: number) =>
    //     <div>{row?.searched} times</div>
    // },
    {
      dataField: "displayed",
      text: t("Displayed"),
      formatter: (cellContent: any, row: any, rowIndex: number) => {
        return (
          <div className="position-relative">
              <div className="position-absolute text-center w-100"
                style={{ top: "-3px" }}
              >{row?.displayed} times</div>
              <Progress
                style={{
                  height: "1rem",
                }}
                color={"#AEE8B3"}
                value={maxProgressValue ? ((100/maxProgressValue) * row.displayed) : 0}
              />
          </div>
        )
      }
    },
    // {
    //     dataField: "name",
    //     text: t("Name"),
    //     formatter: (cellContent: any, row: any, rowIndex: number) =>
    //         <div className="table-url-expandable--wrapper">
    //             <div className="text-truncate table-url-expandable--url-container">
    //                 <a href={row?.link} target="_blank" rel="noreferer noopener">{row?.name}</a>
    //             </div>
    //         </div>,
    // },
    // {
    //     dataField: "wishlist_count",
    //     text: t("Wishlisted"),
    //     formatter: (cellContent: any, row: any, rowIndex: number) =>
    //     <div>{row?.wishlist_count} times</div>
    // },
    // {
    //     dataField: "price",
    //     text: t("Price"),
    //     formatter: (cellContent: any, row: any, rowIndex: number) =>
    //       <div>{row?.price} {row?.currency}</div>
    // },
    // {
    //     dataField: "sale_price",
    //     text: t("Sale Price"),
    //     formatter: (cellContent: any, row: any, rowIndex: number) =>
    //         <div>
    //             {row?.sale_price && row?.sale_price != row?.price ? <div>{row?.sale_price} {row?.currency}</div> : "-"}
    //         </div>
    // },
    // {
    //     dataField: "gender",
    //     text: t("Gender"),
    // },
    // {
    //   dataField: "time",
    //   text: t("Execution Time"),
    //   formatter: (cellContent: any, row: any, rowIndex: number) =>
    //   <div>
    //     {row?.time}s
    //   </div>,
    //   // sort: true
    // },
    // {
    //   dataField: "Status",
    //   text: t("Status"),
    //   formatter: (cellContent: any, row: any, rowIndex: number) =>
    //     row?.status === 1 ? (
    //       <span className="bg-danger badge badge-danger btn-rounded"><div style={{width: 70}}>{t("PRICE ALERT")}</div></span>
    //       ) : (
    //         <span className="bg-danger badge badge-danger btn-rounded"><div style={{width: 70}}>{t("WISHLIST")}</div></span>
    //     ),
    // },
    // {70
    //   dataField: "feed_status",
    //   text: t("Apify"),
    //   sort: true,
    //   formatter: (cellContent: any, row: any, rowIndex: number) =>
    //     row?.feed?.status === 1 ? (
    //       <span className="bg-success badge badge-success btn-rounded">{t("Active")}</span>
    //     ) : (
    //         <span className="bg-danger badge badge-danger btn-rounded">{t("Inactive")}</span>
    //       ),
    // },
    // {
    //   dataField: "actions",
    //   isDummyField: true,
    //   text: "Actions",
    //   formatter: (cellContent: any, row: any, rowIndex: number) =>
    //     <div className="d-flex gap-4">
    //       {/* <Link to={`/apify/feeders/${row.id}`} className="text-secondary">
    //         {t("Edit")}
    //       </Link>
    //       <span
    //         className="text-danger clickable"
    //         // onClick={() => handleDelete(row?.id)}
    //       >
    //         {t("Delete")}
    //       </span> */}
    //     </div>,
    //   //@ts-ignore
    //   filter: customFilter(),
    //   filterRenderer: (onFilter: any, column: any) =>
    //     <Button
    //       type="button"
    //       color="success"
    //       className="btn-rounded waves-effect waves-light me-2"
    //       onClick={handleSubmitFilters}
    //     >
    //       {t("Search")}
    //     </Button>
    // },
  ].map(col => ({ ...col, ...globalColumnProps }))
}

export default TopCouponsDisplayedTableColumns

