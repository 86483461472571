// @ts-ignore
import {AvField, AvForm} from 'availity-reactstrap-validation';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
// @ts-ignore
import MetaTags from 'react-meta-tags';
// @ts-ignore
import {connect} from 'react-redux';
// @ts-ignore
import {RouteComponentProps} from 'react-router';
// @ts-ignore
import {Link} from 'react-router-dom';
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  // @ts-ignore
} from 'reactstrap';
import {
  apiError,
  changePreloader,
  registerUserFailed,
  registerUserSuccessful,
} from '../../../store/actions';
import {post} from './../../../helpers/api_helper';
import * as url from './../../../helpers/url_helper';
import {openAlert} from './../../../store/alert/actions';
import './Register.scss';

export interface RegisterEmailPropsInterface extends RouteComponentProps {
  dashbord_id: string;
  registerUserSuccessful: (userObj: any) => {};
  apiError: (err: string) => {};
  history: any;
  location: any;
  error: string;
  onOpenAlert: ({}) => {};
  registerUserFailed: (status: string) => {};
  t: any;
  onGetCountries: any;
  countries: [{code: string; name: string}];
  user: any;
  registrationError: string;
  onChangePreloader: (value: boolean) => {};
}

export interface RegisterEmailStateInterface {
  isCharity: boolean;
  countries: any[];
  selectedCountryCode: string;
  formErrors: any;
  role: string;
}

class RegisterEmail extends Component<
  RegisterEmailPropsInterface,
  RegisterEmailStateInterface
> {
  constructor(props: RegisterEmailPropsInterface) {
    super(props);
    this.state = {
      isCharity: false,
      countries: [],
      selectedCountryCode: '',
      formErrors: {},
      role: 'retailer',
    };
  }

  async componentDidMount() {
    await this.props.apiError('');
    await this.props.registerUserFailed('');
    await this.props.onGetCountries();
  }

  handleCharityRadioChecked = (status: boolean) => {
    const {isCharity} = this.state;

    this.setState({isCharity: status});
  };

  handleSetRole = (value: string) => {
    this.setState({role: value});
  };

  handleValidSubmit = async (event: any, values: any) => {
    event.preventDefault();

    return new Promise(async (resolve, reject) => {
      const {selectedCountryCode, isCharity, role} = this.state;

      const {email} = values;

      this.props.onChangePreloader(true);
      post(`${url.API_BASE}dashboard/register/1`, {
        email,
        // role: this.props.location?.state?.role
      })
        .then(response => {
          if (response?.status) {
            this.props.onOpenAlert({
              text: response?.message,
              status: 'success',
            });
            this.props.history.push('/register-code', {
              // type: response?.type,
              email: email,
            });
          } else if (response?.step) {
            this.props.onOpenAlert({
              text: `Email exists. Please sign in.`,
              status: 'success',
            });
            this.props.history.push('/login');
          }

          this.props.onChangePreloader(false);
          return resolve(response);
        })
        .catch(error => {
          if (error?.response?.data) {
            const {message, errors} = error?.response?.data;
            this.setState({formErrors: errors});
            this.props.onOpenAlert({
              text: message,
              status: 'danger',
            });
          }
          this.props.onChangePreloader(false);
          return reject(error);
        });
    });
  };

  handleChangeCountryCode = (event: any) => {
    this.setState({selectedCountryCode: event.target.value});
  };

  render() {
    const {countries, formErrors, selectedCountryCode, isCharity, role} =
      this.state;
    const {t} = this.props;

    return (
      <>
        <MetaTags>
          <title>LOOP | Register</title>
        </MetaTags>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-2 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={7} xl={6}>
                <Card className="overflow-hidden">
                  <Row>
                    <Col className="col-7">
                      <div className="p-4">
                        <h1>Email</h1>
                      </div>
                    </Col>
                  </Row>
                  <CardBody className="pt-0">
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}>
                        {this.props.user && this.props.user ? (
                          <Alert color="success">
                            Register User Successfully
                          </Alert>
                        ) : null}
                        {this.props.registrationError &&
                        this.props.registrationError ? (
                          <Alert color="danger">
                            {this.props.registrationError}
                          </Alert>
                        ) : null}

                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email Address"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>

                        <div className="mt-4 d-grid">
                          <button
                            className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
                            type="submit">
                            Next
                          </button>
                        </div>

                        <div className="mt-3 text-center">
                          <p>
                            <span className="auth-agreement-text">
                              Already have an account?
                            </span>{' '}
                            <Link
                              to="/login"
                              className="fw-medium auth-agreement-link">
                              {' '}
                              Log in
                            </Link>{' '}
                          </p>
                        </div>

                        <div className="mt-4 text-center">
                          <p className="mb-0 auth-agreement-text">
                            By continuing you agree to our{' '}
                            <a
                              href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/"
                              target="_blank"
                              className="text-primary">
                              Terms & Conditions
                            </a>
                            . LOOP services are subject to our{' '}
                            <a
                              href="https://loopdigitalwardrobe.com/privacy-policy/"
                              target="_blank"
                              className="text-primary">
                              Privacy Policy
                            </a>
                            .
                          </p>
                        </div>

                        <div className="mt-4 text-center">
                          <p className="mb-0 auth-agreement-text">
                            Need our help?{' '}
                            <a
                              href="mailto:help@loopdigitalwardrobe.com"
                              className="fw-medium contact-us-text">
                              Contact us.
                            </a>
                          </p>
                        </div>

                        <div className="mt-4 text-center our-integrations">
                          <p className="mb-0 auth-agreement-text">
                            <div className="fw-medium contact-us-text">
                              Our Integrations
                            </div>
                            <img
                              src="/assets/images/platforms.png"
                              width="220"
                            />
                          </p>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-5 text-center">
                  <p className="auth-agreement-text">
                    ©{new Date().getFullYear()} All Rights Reserved. LOOP
                    DIGITAL WARDROBE® is a registered trademark of LOOP DIGITAL
                    WARDROBE®.{' '}
                    <a
                      href="https://loopdigitalwardrobe.com/privacy-policy/"
                      target="_blank"
                      className="fw-medium text-primary">
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                      href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/"
                      target="_blank"
                      className="fw-medium text-primary">
                      Terms & Conditions
                    </a>
                    .
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  const {user, registrationError, loading, dashbord_id} = state.Account;
  const {countries} = state.Countries;
  return {user, registrationError, loading, dashbord_id, countries};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    apiError: (message: string) => dispatch(apiError(message)),
    registerUserFailed: (message: string) =>
      dispatch(registerUserFailed(message)),
    registerUserSuccessful: (user: any) =>
      dispatch(registerUserSuccessful(user)),
    onGetCountries: () => dispatch({type: 'GET_COUNTRIES'}),
    onOpenAlert: ({text, status}: any) =>
      dispatch(openAlert({text: text, status: status})),
    onChangePreloader: (value: boolean) => dispatch(changePreloader(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(RegisterEmail));
