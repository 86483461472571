import React, { useEffect, useState } from "react"
import LightboxComponent from "../../../components/LightboxComponent/LightboxComponent";
import { customFilter } from 'react-bootstrap-table2-filter';
import "./Analytics.scss"
import { Button } from "reactstrap";
import { useHistory } from "react-router";
import comment from "../../../assets/images/comment.svg";
import addToBoardPin from "../../../assets/images/add-to-board-pin.svg";
import heart from "../../../assets/images/heart.svg";
import wishlist from "../../../assets/images/wishlist.svg";
import watch from "../../../assets/images/watch.svg";
import moment from "moment";

const CustomerItemsTableColumns = (offsetIndex: number, t: any, queryObject: any, setQueryObject: any, defaultQueryObject: any, banItem?: any, showOwner?: boolean) => {
  const [isFilterActive, setIsFilterActive] = useState(false)
  const [filters, setFilters] = useState<any>({})
  const history = useHistory();

  useEffect(() => {
    const queryObjectCopy = { ...queryObject };
    delete queryObjectCopy.sort
    delete queryObjectCopy.direction
    setFilters(queryObjectCopy);
  }, [])

  useEffect(() => {
    handleSetIsFilterActive();
  }, [queryObject])

  const handleSetFilters = (columnName?: string, value?: string | number | boolean) => {
    if(value !== undefined && value !== ""){
      //@ts-ignore
      setFilters({ ...filters, [columnName] : value})
    } else {
      const filtersCopy = {...filters};
      //@ts-ignore
      delete filtersCopy[columnName]
      setFilters(filtersCopy)
    }
  }

  const handleSetIsFilterActive = () => {
    setIsFilterActive(Object.keys(queryObject)?.length > Object.keys(defaultQueryObject)?.length)
  }

  const handleSubmitFilters = (event?: any) => {
    if(event){
      event.preventDefault()
    }
    // get already existing default query keys from query obejct
    // and copy to new queryObject with additional filters
    const defaultQueryKeys = {}
    Object.keys(defaultQueryObject).forEach((key: string) => {
      //@ts-ignore
      defaultQueryKeys[key] = queryObject[key];
    })

    let filtersCopy = {...filters};
    if(filtersCopy?.statuses === "none"){
      filtersCopy.statuses = "";
    }

    setQueryObject({ ...defaultQueryKeys, ...filtersCopy}, true);
  }

  const handleResetFilters = () => {
    const { sort, direction } = queryObject;
    setQueryObject({ sort, direction });
    setFilters({})
    setIsFilterActive(false)
  }

  const globalColumnProps = {
    onSort: (column: any, order: any) => {
      setQueryObject({
        ...queryObject,
        sort: column,
        direction: order
      })
    },
  }

  // adding_to_boards: 0
  // adding_to_wishlist: 0
  // clicks: 0
  // comments: 0
  // data: {change: {likes: null, comments: null}}
  // date: "{new Date().getFullYear()}-03-14T00:00:00.000000Z"
  // id: 801
  // item: {type: "FEED_ITEM", id: 2285271, name: "Vintage Style Beaded Lapel Pin",…}
    // comments: 0
    // id: 2285271
    // image: "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/feed/2285271_cuVQIwjZkHCb4dTuDAx8B.jpg"
    // likes: 1
    // name: "Vintage Style Beaded Lapel Pin"
    // type: "FEED_ITEM"
    // user: {id: 30033, type: "USER", nanoId: "rk58JIXzpo6xG1dMG0Ni0",…}
  // likes: 1
  // out_of_date: 0
  // reach: 0
  // type: "daily"

  return [
    {
      dataField: "i",
      isDummyField: true,
      text: "#",
      formatter: (cellContent: any, row: any, rowIndex: any) => <div>{rowIndex + 1 + offsetIndex}</div>,
      // //@ts-ignore
      // filter: customFilter(),
      // filterRenderer: () => !isFilterActive
      //   ? <i className="mdi mdi-filter"/>
      //   : <i className="mdi mdi-filter-off" onClick={handleResetFilters}/>
    },
    // {
    //   dataField: "User",
    //   text: t("User"),
    //   formatter: (cellContent: any, row: any, rowIndex: any) => 
    //     <div className="d-flex">
    //       <img className="rounded-circle" 
    //         src={row?.item?.user?.avatar}
    //         style={{
    //           height: 40,
    //           width: 40
    //         }} 
    //       />
    //       <div className="ms-2 d-flex flex-column justify-content-center">
    //         {row?.item?.user?.company_name ? row?.item?.user?.company_name : row?.item?.user?.username}
    //       </div>
    //     </div>
    // },
    {
      dataField: "images",
      text: t("Images"),
      formatter: (cellContent: any, row: any, rowIndex: any) => {
        return <LightboxComponent images={[row?.item?.image]} />
      }
    },
    {
      dataField: "item.name",
      text: t("Name"),
    },
    {
      dataField: "likes",
      text: t("Likes"),
      formatter: (cellContent: any, row: any, rowIndex: any) => {
        return (
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <img className="table-icon" src={heart}/>
              <span className="ms-2">{row?.likes}</span>
            </div>
          </div>
        )
      },
      sort: true
    },
    {
      dataField: "data->change->likes",
      text: t("Likes Gain"),
      formatter: (cellContent: any, row: any, rowIndex: any) => {
        return (
          row?.data?.change?.likes !== null ? (
            <div className="w-50 text-center">
              {row?.data?.change?.likes < 0 ? <div className="font-size-10 text-danger stats-text-up">{parseFloat((row?.data?.change?.likes).toFixed(2))}%</div> : null}
              <i className={`table-icon bx ${
                row?.data?.change?.likes === 0 
                  ? "mdi mdi-drag-horizontal-variant text-warning" 
                  : row?.data?.change?.likes > 0 
                    ? "bx-up-arrow text-success" 
                    : "bx-down-arrow text-danger"
                }`}
              />
              {row?.data?.change?.likes > 0 ? <div className="font-size-10 text-success stats-text-down">{parseFloat((row?.data?.change?.likes).toFixed(2))}%</div> : null}
            </div>
          ) : null
        )
      },
      sort: true
    },
    {
      dataField: "comments",
      text: t("Comments"),
      formatter: (cellContent: any, row: any, rowIndex: any) => {
        return (
          <div className="d-flex align-items-center">
            <span>
              <img className="table-icon" src={comment}/>
              <span className="ms-2">{row?.comments}</span>
            </span>
          </div>
        )
      },
      sort: true
    },
    {
      dataField: "data->change->comments",
      text: t("Comments Gain"),
      formatter: (cellContent: any, row: any, rowIndex: any) => {
        return (
          row?.data?.change?.comments !== null ? (
            <div className="w-50 text-center">
              {row?.data?.change?.comments < 0 ? <div className="font-size-10 text-danger stats-text-up">{parseFloat((row?.data?.change?.comments).toFixed(2))}%</div> : null}
              <i className={`table-icon bx ${
                row?.data?.change?.comments === 0 
                  ? "mdi mdi-drag-horizontal-variant text-warning" 
                  : row?.data?.change?.comments > 0 
                    ? "bx-up-arrow text-success" 
                    : "bx-down-arrow text-danger"
                }`}
              />
              {row?.data?.change?.comments > 0 ? <div className="font-size-10 text-success stats-text-down">{parseFloat((row?.data?.change?.comments).toFixed(2))}%</div> : null}
            </div>
          ) : null
        )
      },
      sort: true
    },
    {
      dataField: "adding_to_boards",
      text: t("Boards"),
      formatter: (cellContent: any, row: any, rowIndex: any) => {
        return (
          <div className="d-flex align-items-center">
            <img className="table-icon" src={addToBoardPin}/>
            <span className="ms-2">{row?.adding_to_boards}</span>
          </div>
        )
      },
      sort: true
    },
    {
      dataField: "adding_to_wishlist",
      text: t("Wishlist"),
      formatter: (cellContent: any, row: any, rowIndex: any) => {
        return (
          <div className="d-flex align-items-center">
            <img className="table-icon" src={wishlist}/>
            <span className="ms-2">{row?.adding_to_wishlist}</span>
          </div>
        )
      },
      sort: true
    },
    {
      dataField: "clicks",
      text: t("Clicks"),
      formatter: (cellContent: any, row: any, rowIndex: any) => {
        return (
          <span>
            <i className="table-icon mdi mdi-cursor-default-click-outline" />
            <span className="ms-2">{row?.clicks}</span>
          </span>
        )
      },
      sort: true
    },
    {
      dataField: "reach",
      text: t("Views"),
      formatter: (cellContent: any, row: any, rowIndex: any) => {
        return (
          <span>
            <img className="table-icon" src={watch}/>
            <span className="ms-2">{row?.reach}</span>
          </span>
        )
      },
      sort: true
    },
    {
      dataField: "date",
      text: t("Date"),
      sort: true,
      formatter: (cellContent: any, row: any, rowIndex: number) =>
        <span>{moment(row?.date).subtract(1, 'hours').format('YYYY-MM-DD')}</span>,
    },
    // banItem && {
    //   dataField: "ban",
    //   text: "Item Banned",
    //   sort: true,
    //   formatter: (cellContent: any, row: any, rowIndex: number) =>
    //     <div className="d-flex gap-3">
    //       {!row.ban ? (
    //         <span className="bg-success badge badge-success d-flex align-items-center">{t("No")}</span>
    //       ) : (
    //         <span className="bg-danger badge badge-danger d-flex align-items-center">{t("Banned")}</span>
    //       )}
    //       <span
    //         className="text-danger clickable"
    //         onClick={() => banItem(row?.id, row?.ban)}
    //       >
    //         {t(!row.ban ? "Ban Item" : "Unban Item")}
    //       </span>
    //     </div>,
    // },
    // {
    //   dataField: "filter",
    //   isDummyField: true,
    //   text: "Filter",
    //   //@ts-ignore
    //   filter: customFilter(),
    //   filterRenderer: (onFilter: any, column: any) =>
    //     <Button
    //       type="button"
    //       color="success"
    //       className="btn-rounded waves-effect waves-light me-2"
    //       onClick={handleSubmitFilters}
    //     >
    //       {t("Search")}
    //     </Button>
    // },
    // {
    //   dataField: "ban",
    //   isDummyField: true,
    //   text: "Change Status",
    //   formatter: (cellContent: any, row: any, rowIndex: any) => (
    //     <div className="d-flex gap-3">
    //       <Link
    //         to="#"
    //         className="text-danger"
    //         onClick={() => banItem(row.id, row.status)}
    //       >
    //         {t("Change")}
    //       </Link>
    //     </div>
    //   ),
    // },
  //   {
  //     dataField: "Edit",
  //     text: "Edit",
  //     formatter: (cellContent: any, row: any, rowIndex: any) => (
  //       <div className="d-flex gap-3">
  //         <Link to={`/customers/${row.id}`} className="text-danger">
  //           {t("Edit")}
  //         </Link>
  //       </div>
  //     ),
  //   },
  ].map(col => ({ ...col, ...globalColumnProps }))
}

export default CustomerItemsTableColumns
