import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_COUNTRIES
} from "./actionTypes"
import {
  getCountriesFail,
  getCountriesSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCountries
} from "./../../helpers/backend_helper"

function* getCountriesAsync() {
  try {
    const response = yield call(getCountries)
    yield put(getCountriesSuccess(response))
  } catch (error) {
    yield put(getCountriesFail(error))
  }
}

function* countriesSaga() {
  yield takeEvery(GET_COUNTRIES, getCountriesAsync)
}

export default countriesSaga
