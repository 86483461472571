import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import MetaTags from 'react-meta-tags';
// @ts-ignore
import {useDispatch, useSelector} from 'react-redux';
import {Card, CardBody, Col, Container, Form, Row} from 'reactstrap';
import Breadcrumbs from './../../components/Common/Breadcrumb';
import {
  getEmailTemplates,
  getWebsiteDetailApi,
} from './../../helpers/backend_helper';
import {API_BASE} from './../../helpers/url_helper';
import {openAlert} from './../../store/alert/actions';
import {getDictionaries} from './../../store/dictionaries/actions';
import CompleteSetupEcommerce from './WebsitesFormSteps/CompleteSetupEcommerce';
import VerifyDomain from './WebsitesFormSteps/VerifyDomain';
import ViewSettings from './WebsitesFormSteps/ViewSettings';
import {capitalize} from 'lodash';

interface WebsiteFormInterface {
  history: any;
  match: any;
  returnTranslation: (name: string) => string;
}

interface IPluginConfigObject {
  plugin: {
    allow_actions: string[];
    color_1: string;
    color_2: string;
    default_lang: string;
    yOffset: string;
    xOffset: string;
    zIndex: string;
    direction: string;
    position: string;
    website_container_class: string;
    description?: string;
    show_widget_btn: boolean;
    xOffset_widget_btn: string;
    yOffset_widget_btn: string;
    zIndex_widget_btn: string;
    position_widget_btn: string;
    direction_widget_btn: string;
    default_widget_style: string | boolean;
  };
}

const EcommerceWebsiteForm = ({
  history,
  match: {params},
  returnTranslation,
}: WebsiteFormInterface) => {
  const dispatch = useDispatch();

  const [steps, setSteps] = useState([
    'Choose the platform',
    'Verify your domain',
    'View settings',
    'Complete set-up',
  ]);
  // const [activeStep, setActiveStep] = useState("Choose the platform")

  // const [steps, setSteps] = useState(["Verify your domain", "View settings", "Complete set-up"])
  const [activeStep, setActiveStep] = useState('Verify your domain');
  // const [activeStep, setActiveStep] = useState("View settings")
  const [selectedPlatform, setSelectedPlatform] = useState(params?.platform);
  const [generatedToken, setGeneratedToken] = useState('');
  const [generatedSiteId, setGeneratedSiteId] = useState('');
  const [selectedWidgetStyle, setSelectedWidgetStyle] = useState('fullwidth');
  const [customizeModalIsOpen, setCustomizeModalIsOpen] = useState(false);

  const [widgetStatus, setWidgetStatus] = useState('active');
  const [widgetStatusList, setWidgetStatusList] = useState([
    'active',
    'inactive',
  ]);
  const [url, setUrl] = useState('');
  const [name, setName] = useState('');
  const [logo, setLogo] = useState<any>('');
  const [backgroundImage, setBackgroundImage] = useState<any>('');
  const [formErrors, setFormErrors] = useState({});
  const [mainColour, setMainColour] = useState('#000');
  const [subColour, setSubColour] = useState('#000');
  const [selectedActions, setSelectedActions] = useState([]);
  const [selectedDefaultLanguageID, setSelectedDefaultLanguageID] =
    useState('');
  const [selectedFilesLogo, setSelectedFilesLogo] = useState([]);
  const [selectedFilesBackground, setSelectedFilesBackground] = useState([]);
  const [lastUploadedLogoId, setLastUploadedLogoId] = useState('');
  const [lastUploadedLogoPath, setLastUploadedLogoPath] = useState('');
  const [lastUploadedBackgroundImageId, setLastUploadedBackgroundImageId] =
    useState('');
  const [lastUploadedBackgroundImagePath, setLastUploadedBackgroundImagePath] =
    useState('');

  const [pluginSelectorName, setPluginSelectorName] = useState(
    '#loop-digital-wardrobe',
  );
  const [brandDescription, setBrandDescription] = useState('');
  const [emailTemplates, setEmailTemplates] = useState([]);

  const [pluginDirectionOptions, setPluginDirectionOptions] = useState([
    'topLeft',
    'topRight',
    'downLeft',
    'downRight',
  ]);
  const [pluginPositionOptions, setPluginPositionOptions] = useState([
    'fixed',
    'relative',
    'absolute',
  ]);
  const [pluginYOffset, setPluginYOffset] = useState('1');
  const [pluginXOffset, setPluginXOffset] = useState('1');
  const [pluginZIndex, setPluginZIndex] = useState('1');
  const [selectedPluginDirectionOption, setSelectedPluginDirectionOption] =
    useState('downRight');
  const [selectedPluginPositionOption, setSelectedPluginPositionOption] =
    useState('absolute');

  const [showWidgetBtn, setShowWidgetBtn] = useState(false);
  const [widgetBtnDirectionOptions, setWidgetBtnDirectionOptions] = useState([
    'topLeft',
    'topRight',
    'downLeft',
    'downRight',
  ]);
  const [widgetBtnPositionOptions, setWidgetBtnOptions] = useState([
    'fixed',
    'relative',
    'absolute',
  ]);
  const [widgetBtnYOffset, setWidgetBtnYOffset] = useState('1');
  const [widgetBtnXOffset, setWidgetBtnXOffset] = useState('1');
  const [widgetBtnZIndex, setWidgetBtnZIndex] = useState('1');
  const [
    selectedWidgetBtnDirectionOption,
    setSelectedWidgetBtnDirectionOption,
  ] = useState('downRight');
  const [selectedWidgetBtnPositionOption, setSelectedWidgetBtnPositionOption] =
    useState('absolute');

  const [pluginTestMode, setPluginTestMode] = useState('0');
  const [pluginFullscreenModalMode, setPluginFullscreenModalMode] =
    useState('1');

  const languages = useSelector(
    (state: any) => state.Dictionaries.dictionaries.languages,
  );
  const dashboardID = useSelector((state: any) => state?.Account?.dashbord_id);
  const userToken = useSelector(
    (state: any) => state?.Account?.user?.access_token,
  );
  const companyName = useSelector(
    (state: any) => state?.Account?.user?.company_name,
  );

  const donateCheckbox = useRef(null);
  const recycleCheckbox = useRef(null);
  const resellCheckbox = useRef(null);
  const swapCheckbox = useRef(null);
  const returnCheckbox = useRef(null);

  const showOpenBtnCheckbox = useRef(null);

  useEffect(() => {
    if (params?.uuid) {
      handleGetWebsite();
    }
  }, [params?.uuid]);

  useEffect(() => {
    dispatch(getDictionaries());
  }, []);

  useEffect(() => {
    handleSetInitLanguage();
  }, [languages]);

  const onAddWardrobeProductPhoto = (photo: any, type: string) => {
    return new Promise((resolve, reject) => {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Client-UUID': dashboardID,
          Authorization: `Bearer ${userToken}`,
        },
      };

      const formData = new FormData();

      formData.append('file', photo);
      formData.append('name', `${companyName}-${Date.now()}`);

      axios
        .post(`${API_BASE}files`, formData, config)
        .then(response => {
          if (type === 'logo') {
            setLastUploadedLogoId(response?.data?.id);
            setLastUploadedLogoPath(response?.data?.path);
          } else if (type === 'background') {
            setLastUploadedBackgroundImageId(response?.data?.id);
            setLastUploadedBackgroundImagePath(response?.data?.path);
          }
        })
        .catch(error => {
          if (error?.response?.data) {
            const {message, errors} = error?.response?.data;

            if (message === 'Unauthenticated.') {
              localStorage.setItem('authUser', '');
            }

            openAlert({text: message, status: 'danger'});
          }
          return reject(error);
        });
    });
  };

  const handleSetInitLanguage = () => {
    setSelectedDefaultLanguageID(languages?.[0]?.id);
  };

  const handleChangeLanguage = async (event: any) => {
    //after every language change download email templates for that language
    const emailResponse = await getEmailTemplates({
      uuid: params?.uuid,
      language_id: event.target.value,
    });
    setEmailTemplates(emailResponse);
    setSelectedDefaultLanguageID(event.target.value);
  };

  const handleChangeWidgetStatus = (event: any) => {
    setWidgetStatus(event.target.value);
  };

  const handleChangePluginPosition = (event: any) => {
    setSelectedPluginPositionOption(event.target.value);
  };

  const handleChangePluginDirection = (event: any) => {
    setSelectedPluginDirectionOption(event.target.value);
  };

  const handleChangeWidgetBtnPosition = (event: any) => {
    setSelectedWidgetBtnPositionOption(event.target.value);
  };

  const handleChangeWidgetBtnDirection = (event: any) => {
    setSelectedWidgetBtnDirectionOption(event.target.value);
  };

  //jak w GET będziemy mieli zwracany config to trzeba obsłużyć stwozenie tablicy do selectedAction
  const handleInitActionsLoad = (
    url: string,
    name: string,
    logo: string,
    image: string,
    description: string,
    config: IPluginConfigObject,
    widgetStatus: string,
    test_mode: string,
  ) => {
    const {plugin} = config;
    setPluginTestMode(test_mode);
    setPluginFullscreenModalMode(
      plugin?.default_widget_style && plugin?.default_widget_style === true
        ? '1'
        : '0',
    );
    // console.log(["test", plugin?.default_widget_style && plugin?.default_widget_style === true ? "1" : "0"])

    setWidgetStatus(widgetStatus ? widgetStatus : 'active');
    setUrl(url ? url.replace('https://', '') : '');
    setName(name ? name : '');
    setLogo(logo ? logo : '');
    setBackgroundImage(image ? image : '');
    setMainColour(plugin?.color_1 ? plugin.color_1 : '#000');
    setSubColour(plugin?.color_2 ? plugin.color_2 : '#000');
    setSelectedDefaultLanguageID(
      plugin?.default_lang ? plugin.default_lang : languages?.[0]?.id,
    );

    setPluginYOffset(plugin?.yOffset ? plugin.yOffset : '');
    setPluginXOffset(plugin?.xOffset ? plugin.xOffset : '');
    setPluginZIndex(plugin?.zIndex ? plugin.zIndex : '');
    setSelectedPluginDirectionOption(
      plugin?.direction ? plugin.direction : pluginDirectionOptions?.[0],
    );
    setSelectedPluginPositionOption(
      plugin?.position ? plugin.position : pluginPositionOptions?.[0],
    );

    // setPluginSelectorName(plugin?.website_container_class ? plugin.website_container_class : "")
    setPluginSelectorName('#loop-digital-wardrobe');
    setBrandDescription(description ? description : '');
    setShowWidgetBtn(plugin?.show_widget_btn ? plugin?.show_widget_btn : false);

    setWidgetBtnYOffset(
      plugin?.yOffset_widget_btn ? plugin.yOffset_widget_btn : '',
    );
    setWidgetBtnXOffset(
      plugin?.xOffset_widget_btn ? plugin.xOffset_widget_btn : '',
    );
    setWidgetBtnZIndex(
      plugin?.zIndex_widget_btn ? plugin.zIndex_widget_btn : '',
    );
    setSelectedWidgetBtnDirectionOption(
      plugin?.direction_widget_btn
        ? plugin.direction_widget_btn
        : widgetBtnDirectionOptions?.[0],
    );
    setSelectedWidgetBtnPositionOption(
      plugin?.position_widget_btn
        ? plugin.position_widget_btn
        : widgetBtnPositionOptions?.[0],
    );

    // @ts-ignore
    showOpenBtnCheckbox.current.checked = plugin?.show_widget_btn
      ? plugin?.show_widget_btn
      : false;
  };

  const handleCheckboxActionsChange = (name: string) => {
    // @ts-ignore
    let valueInArray = selectedActions.includes(name);

    if (valueInArray) {
      let newSelectedActions = selectedActions.filter(value => value !== name);
      setSelectedActions(newSelectedActions);
    } else {
      let newSelectedActions = selectedActions;
      // @ts-ignore
      newSelectedActions.push(name);
      setSelectedActions(newSelectedActions);
    }
  };

  const handleGetWebsite = async () => {
    try {
      const response = await getWebsiteDetailApi(params?.uuid);

      const {
        widget_status,
        url,
        name,
        logo,
        image,
        description,
        config,
        config: {plugin},
        test_mode,
      } = response;

      handleInitActionsLoad(
        url,
        name,
        logo,
        image,
        description,
        config,
        widget_status,
        test_mode,
      );

      const emailResponse = await getEmailTemplates({
        uuid: params?.uuid,
        language_id: selectedDefaultLanguageID
          ? selectedDefaultLanguageID
          : plugin?.default_lang
          ? plugin.default_lang
          : languages?.[0]?.id,
      });
      setEmailTemplates(emailResponse);
    } catch (e) {
      // console.log(["e", e])
      dispatch(
        openAlert({
          text: returnTranslation('An error occured'),
          status: 'danger',
        }),
      );
    }
  };

  const handleUpdateWebsite = async (
    widgetStatus: string,
    name: string,
    url: string,
    logo: string,
    backgroundImage: string,
    description: string,
    config: IPluginConfigObject,
    testMode: number | string,
  ) => {
    // console.log(['config', config]);
    return new Promise(async (resolve, reject) => {
      let configAxios = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Client-UUID': dashboardID,
          Authorization: `Bearer ${userToken}`,
          'Custom-User-Agent': window.navigator.userAgent,
        },
      };

      // console.log(["widgetStatus", widgetStatus, name, url])

      if (widgetStatus && url) {
        axios
          .put(
            `${API_BASE}sites/${
              params?.uuid
                ? params?.uuid
                : generatedSiteId
                ? generatedSiteId
                : ''
            }`,
            {
              widget_status: widgetStatus,
              name: name ? name : url ? url : '',
              url,
              logo,
              image: backgroundImage,
              description,
              test_mode: testMode,
              config,
            },
            configAxios,
          )
          .then(response => {
            if (response.data) {
              // const { site: { id, token } } = response.data;
              // setGeneratedToken(token)
              // setActiveStep("View settings")

              params?.uuid &&
                history.push(`/plugin/integrations/configure/${params?.uuid}`);
              dispatch(
                openAlert({
                  text: returnTranslation('Integration updated'),
                  status: 'success',
                }),
              );
            }

            return resolve(response);
          })
          .catch(error => {
            if (error?.response?.data) {
              const {message} = error?.response?.data;
              dispatch(openAlert({text: message, status: 'danger'}));
            }
            return reject(error);
          });
      } else {
        dispatch(
          openAlert({
            text: returnTranslation('All fields required'),
            status: 'danger',
          }),
        );
      }
    });
  };

  const handleCreateWebsite = async (
    widgetStatus: string,
    name: string,
    url: string,
    logo: string,
    backgroundImage: string,
    description: string,
    config: IPluginConfigObject,
    testMode: number | string,
  ) => {
    return new Promise(async (resolve, reject) => {
      let configAxios = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Client-UUID': dashboardID,
          Authorization: `Bearer ${userToken}`,
          'Custom-User-Agent': window.navigator.userAgent,
        },
      };

      if (
        // name &&
        url
      ) {
        axios
          .post(
            `${API_BASE}sites`,
            {
              widget_status: widgetStatus,
              name: url,
              url,
              logo,
              image: backgroundImage,
              description,
              test_mode: testMode,
              config,
            },
            configAxios,
          )
          .then(response => {
            if (response.data) {
              // console.log(["response.data", response.data])
              const {
                site: {id, token},
              } = response.data;
              setGeneratedSiteId(id);
              setGeneratedToken(token);
              setActiveStep('View settings');

              dispatch(
                openAlert({
                  text: returnTranslation('Website added'),
                  status: 'success',
                }),
              );
            }

            return resolve(response);
          })
          .catch(error => {
            if (error?.response?.data) {
              const {message, errors} = error?.response?.data;
              // console.log(["errors", errors])
              setFormErrors(errors);
              dispatch(openAlert({text: message, status: 'danger'}));
            }
            return reject(error);
          });
      } else {
        dispatch(
          openAlert({
            text: returnTranslation('All fields required'),
            status: 'danger',
          }),
        );
      }
    });
  };

  const handleSubmit = (event: any, isEditSend?: boolean) => {
    event.preventDefault();
    setFormErrors({});

    let config: IPluginConfigObject = {
      plugin: {
        default_lang: selectedDefaultLanguageID,
        color_1: mainColour,
        color_2: subColour,
        allow_actions: selectedActions,
        yOffset: pluginYOffset,
        xOffset: pluginXOffset,
        zIndex: pluginZIndex,
        show_widget_btn: showWidgetBtn,
        direction: selectedPluginDirectionOption,
        position: selectedPluginPositionOption,
        website_container_class: pluginSelectorName,
        xOffset_widget_btn: widgetBtnXOffset,
        yOffset_widget_btn: widgetBtnYOffset,
        zIndex_widget_btn: widgetBtnZIndex,
        position_widget_btn: selectedWidgetBtnPositionOption,
        direction_widget_btn: selectedWidgetBtnDirectionOption,
        //@ts-ignore
        default_widget_style: !params?.uuid
          ? selectedWidgetStyle === 'fullwidth'
            ? true
            : false
          : //@ts-ignore
          pluginFullscreenModalMode == 1
          ? true
          : false,
      },
    };

    const isEdit = params?.uuid;
    if (isEdit || isEditSend) {
      handleUpdateWebsite(
        widgetStatus,
        name,
        `https://${url}`,
        lastUploadedLogoId ? lastUploadedLogoId : logo?.id ? logo?.id : '',
        lastUploadedBackgroundImageId
          ? lastUploadedBackgroundImageId
          : backgroundImage?.id
          ? backgroundImage?.id
          : '',
        brandDescription,
        config,
        pluginTestMode,
      );
    } else {
      handleCreateWebsite(
        widgetStatus,
        name,
        `https://${url}`,
        lastUploadedLogoId ? lastUploadedLogoId : '',
        lastUploadedBackgroundImageId ? lastUploadedBackgroundImageId : '',
        brandDescription,
        config,
        pluginTestMode,
      );
    }
  };

  const handleMainColourChange = ({hex}: {hex: string}) => {
    setMainColour(hex);
  };

  const handleSubColourChange = ({hex}: {hex: string}) => {
    setSubColour(hex);
  };

  const handleAcceptedFiles = (files: any, type: string) => {
    if (type === 'logo') {
      setSelectedFilesLogo([]);
    } else if (type === 'background') {
      setSelectedFilesBackground([]);
    }

    let addFiles: any = [];

    files.map(async (file: any) => {
      const reader = new FileReader();

      if (file.type.match('image.*')) {
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          const binaryStr = reader.result;
          file = new Blob([file], {type: 'image/png'});

          try {
            onAddWardrobeProductPhoto(file, type);

            Object.assign(file, {
              preview: URL.createObjectURL(file),
              formattedSize: formatBytes(file.size),
            });

            addFiles.push(file);
          } catch (err) {
            dispatch(openAlert({text: err, status: 'danger'}));
          }
        };
        reader.readAsDataURL(file);
      } else {
        dispatch(
          openAlert({
            text: returnTranslation('Import image type'),
            status: 'danger',
          }),
        );
      }
    });

    if (type === 'logo') {
      setSelectedFilesLogo(addFiles);
    } else if (type === 'background') {
      setSelectedFilesBackground(addFiles);
    }
  };

  const formatBytes = (bytes: any, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const displayCopiedAlert = () => {
    return dispatch(
      openAlert({text: returnTranslation('Copied'), status: 'success'}),
    );
  };

  const toggleCustomizeModal = () => {
    setCustomizeModalIsOpen(!customizeModalIsOpen);
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>LOOP | Websites</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          title={returnTranslation('Pages')}
          breadcrumbItems={[
            returnTranslation('Integrations'),
            returnTranslation('Ecommerce Integration'),
          ]}
        />
        <Row>
          <Col sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                <Form
                  className="form-horizontal"
                  // onSubmit={handleSubmit}
                >
                  {/* page edit */}
                  {params?.uuid && <></>}

                  {steps && (
                    <div className="steps__container">
                      {steps?.map((step, i) => {
                        return (
                          <div
                            className={`steps__wrapper ${
                              activeStep === step && 'steps__wrapper--active'
                            } ${
                              i < steps?.findIndex(x => x === activeStep) &&
                              'steps__wrapper--done'
                            }`}>
                            <div className="steps__count">{i + 1}</div>
                            <p>
                              {step}{' '}
                              {i === 0
                                ? `(${capitalize(selectedPlatform)})`
                                : ''}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {/* {activeStep === "Choose the platform" &&
                                        <ChoosePlatform
                                            returnTranslation={returnTranslation}
                                            setSelectedPlatform={setSelectedPlatform}
                                            setActiveStep={setActiveStep}
                                        />
                                    } */}

                  {activeStep === 'Verify your domain' && (
                    <VerifyDomain
                      returnTranslation={returnTranslation}
                      setActiveStep={setActiveStep}
                      url={url}
                      formErrors={formErrors}
                      setUrl={setUrl}
                      handleSubmit={handleSubmit}
                      showBackBtn={false}
                    />
                  )}

                  {activeStep === 'View settings' && (
                    <ViewSettings
                      returnTranslation={returnTranslation}
                      setActiveStep={setActiveStep}
                      formErrors={formErrors}
                      selectedWidgetStyle={selectedWidgetStyle}
                      setSelectedWidgetStyle={setSelectedWidgetStyle}
                      setCustomizeModalIsOpen={setCustomizeModalIsOpen}
                      customizeModalIsOpen={customizeModalIsOpen}
                      toggleCustomizeModal={toggleCustomizeModal}
                      handleChangePluginPosition={handleChangePluginPosition}
                      pluginPositionOptions={pluginPositionOptions}
                      selectedPluginPositionOption={
                        selectedPluginPositionOption
                      }
                      pluginYOffset={pluginYOffset}
                      setPluginYOffset={setPluginYOffset}
                      pluginXOffset={pluginXOffset}
                      setPluginXOffset={setPluginXOffset}
                      pluginZIndex={pluginZIndex}
                      setPluginZIndex={setPluginZIndex}
                      handleChangePluginDirection={handleChangePluginDirection}
                      pluginDirectionOptions={pluginDirectionOptions}
                      selectedPluginDirectionOption={
                        selectedPluginDirectionOption
                      }
                      handleSubmit={handleSubmit}
                    />
                  )}

                  {activeStep === 'Complete set-up' && (
                    <CompleteSetupEcommerce
                      returnTranslation={returnTranslation}
                      selectedPlatform={selectedPlatform}
                      generatedToken={generatedToken}
                      displayCopiedAlert={displayCopiedAlert}
                    />
                  )}

                  {/* <div className="form-group"> */}
                  {/* @ts-ignore */}
                  {/* <FormInput disabled={false}
                                            label={returnTranslation("Name")}
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            placeholder={returnTranslation("Enter name")}
                                            value={name}
                                            showErrors
                                            formErrors={formErrors}
                                            onChange={(e: any) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <Label>Website url</Label> */}
                  {/* @ts-ignore */}
                  {/* <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>https://</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                disabled={false}
                                                label={returnTranslation("Website url")}
                                                type="text"
                                                name="url"
                                                className="form-control"
                                                //@ts-ignore
                                                placeholder={returnTranslation("Enter website url")}
                                                value={url}
                                                showErrors
                                                formErrors={formErrors}
                                                onChange={(e: any) => setUrl(e.target.value)}
                                            />
                                        </InputGroup>
                                    </div> */}
                  {/* <div className="form-group">
                                        <FormGroup className="mt-3 mb-0">
                                            <Label>{returnTranslation("Fullscreen Modal Mode")}</Label>
                                            <select
                                                className="form-control select2-search-disable"
                                                onChange={(e) => setPluginFullscreenModalMode(e?.target?.value)}
                                            > */}
                  {/* @ts-ignore */}
                  {/* <option key={'pluginFullscreenModalModeOff'} value={0} selected={!pluginFullscreenModalMode == 1 ? "1" : "0"}>
                                                    Off
                                                </option> */}
                  {/* @ts-ignore */}
                  {/* <option key={'pluginFullscreenModalModeOn'} value={1} selected={pluginFullscreenModalMode == 1 ? "1" : "0"}>
                                                    On
                                                </option>
                                            </select>
                                        </FormGroup>
                                    </div> */}
                  {/* {pluginFullscreenModalMode == "0" &&
                                        <>
                                            <div className="form-group">
                                                <FormGroup className="mt-3 mb-0">
                                                    <Label>{returnTranslation("Plugin Position")}</Label>
                                                    <select
                                                        className="form-control select2-search-disable"
                                                        onChange={handleChangePluginPosition}
                                                    >
                                                        {pluginPositionOptions?.map((position, i) => {
                                                            if (selectedPluginPositionOption == position) {
                                                                return (
                                                                    // @ts-ignore
                                                                    <option key={position} value={position} selected>
                                                                        {position}
                                                                    </option>
                                                                )
                                                            } else {
                                                                return (
                                                                    // @ts-ignore
                                                                    <option key={position} value={position}>
                                                                        {position}
                                                                    </option>
                                                                )
                                                            }
                                                        })}
                                                    </select>
                                                </FormGroup>
                                            </div> */}
                  {/* <div className="form-group"> */}
                  {/* @ts-ignore */}
                  {/* <FormInput disabled={false}
                                                    label={returnTranslation("Plugin position Vertical(yOffset)")}
                                                    type="text"
                                                    name="yOffset"
                                                    className="form-control"
                                                    placeholder={returnTranslation("Enter value")}
                                                    value={pluginYOffset}
                                                    showErrors
                                                    formErrors={formErrors}
                                                    onChange={(e: any) => setPluginYOffset(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group"> */}
                  {/* @ts-ignore */}
                  {/* <FormInput disabled={false}
                                                    label={returnTranslation("Plugin position Horizontal(xOffset)")}
                                                    type="text"
                                                    name="xOffset"
                                                    className="form-control"
                                                    placeholder={returnTranslation("Enter value")}
                                                    value={pluginXOffset}
                                                    showErrors
                                                    formErrors={formErrors}
                                                    onChange={(e: any) => setPluginXOffset(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group"> */}
                  {/* @ts-ignore */}
                  {/* <FormInput disabled={false}
                                                    label={returnTranslation("Plugin position (zIndex)")}
                                                    type="number"
                                                    name="zIndex"
                                                    className="form-control"
                                                    placeholder={returnTranslation("Enter value")}
                                                    value={pluginZIndex}
                                                    showErrors
                                                    formErrors={formErrors}
                                                    onChange={(e: any) => setPluginZIndex(e.target.value)}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <FormGroup className="mt-3 mb-0">
                                                    <Label>{returnTranslation("Plugin Direction")}</Label>
                                                    <select
                                                        className="form-control select2-search-disable"
                                                        onChange={handleChangePluginDirection}
                                                    >
                                                        {pluginDirectionOptions?.map((direction, i) => {
                                                            if (selectedPluginDirectionOption == direction) {
                                                                return (
                                                                    // @ts-ignore
                                                                    <option key={direction} value={direction} selected>
                                                                        {direction}
                                                                    </option>
                                                                )
                                                            } else {
                                                                return (
                                                                    // @ts-ignore
                                                                    <option key={direction} value={direction}>
                                                                        {direction}
                                                                    </option>
                                                                )
                                                            }
                                                        })}
                                                    </select>
                                                </FormGroup>
                                            </div>
                                        </>
                                    } */}

                  {/* <div className="form-group">
                                        <label className="">{returnTranslation("Show Btn Open Modal")}</label>
                                        <div className="form-check mb-3">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value=""
                                                ref={showOpenBtnCheckbox}
                                                id="checkboxShowOpenBtn"
                                                onChange={() => setShowWidgetBtn(!showWidgetBtn)}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkboxShowOpenBtn"
                                            >
                                                {returnTranslation("Show")}
                                            </label>
                                        </div>
                                    </div> */}

                  {/* {showWidgetBtn &&
                                        <>
                                            <div className="form-group"> */}
                  {/* @ts-ignore */}
                  {/* <FormInput disabled={false} widget
                                                    label={returnTranslation("Widget Button Position Vertical(yOffset) in pixels")}
                                                    type="number"
                                                    name="widgetBtnYOffset"
                                                    className="form-control"
                                                    placeholder={returnTranslation("Enter value")}
                                                    value={widgetBtnYOffset}
                                                    showErrors
                                                    formErrors={formErrors}
                                                    onChange={(e: any) => setWidgetBtnYOffset(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group"> */}
                  {/* @ts-ignore */}
                  {/* <FormInput disabled={false}
                                                    label={returnTranslation("Widget Button Position Horizontal(xOffset) in pixels")}
                                                    type="number"
                                                    name="widgetBtnXOffset"
                                                    className="form-control"
                                                    placeholder={returnTranslation("Enter value")}
                                                    value={widgetBtnXOffset}
                                                    showErrors
                                                    formErrors={formErrors}
                                                    onChange={(e: any) => setWidgetBtnXOffset(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group"> */}
                  {/* @ts-ignore */}
                  {/* <FormInput disabled={false}
                                                    label={returnTranslation("Widget Button Position (zIndex)")}
                                                    type="number"
                                                    name="widgetBtnZIndex"
                                                    className="form-control"
                                                    placeholder={returnTranslation("Enter value")}
                                                    value={widgetBtnZIndex}
                                                    showErrors
                                                    formErrors={formErrors}
                                                    onChange={(e: any) => setWidgetBtnZIndex(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <FormGroup className="mt-3 mb-0">
                                                    <Label>{returnTranslation("Widget Button Position")}</Label>
                                                    <select
                                                        className="form-control select2-search-disable"
                                                        onChange={handleChangeWidgetBtnPosition}
                                                    >
                                                        {widgetBtnPositionOptions?.map((position, i) => {
                                                            if (selectedWidgetBtnPositionOption == position) {
                                                                return (
                                                                    // @ts-ignore
                                                                    <option key={position} value={position} selected>
                                                                        {position}
                                                                    </option>
                                                                )
                                                            } else {
                                                                return (
                                                                    // @ts-ignore
                                                                    <option key={position} value={position}>
                                                                        {position}
                                                                    </option>
                                                                )
                                                            }
                                                        })}
                                                    </select>
                                                </FormGroup>
                                            </div> */}
                  {/* <div className="form-group">
                                                <FormGroup className="mt-3 mb-0">
                                                    <Label>{returnTranslation("Plugin Direction")}</Label>
                                                    <select
                                                        className="form-control select2-search-disable"
                                                        onChange={handleChangeWidgetBtnDirection}
                                                    >
                                                        {widgetBtnDirectionOptions?.map((direction, i) => {
                                                            if (selectedWidgetBtnDirectionOption == direction) {
                                                                return (
                                                                    // @ts-ignore
                                                                    <option key={direction} value={direction} selected>
                                                                        {direction}
                                                                    </option>
                                                                )
                                                            } else {
                                                                return (
                                                                    // @ts-ignore
                                                                    <option key={direction} value={direction}>
                                                                        {direction}
                                                                    </option>
                                                                )
                                                            }
                                                        })}
                                                    </select>
                                                </FormGroup>
                                            </div>
                                        </>
                                    } */}

                  {/* <div className="text-center mt-4">
                                        <Button type="submit" color="success">
                                            {params?.uuid ? returnTranslation("Update") : returnTranslation("Add website")}
                                        </Button>
                                    </div> */}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EcommerceWebsiteForm;
