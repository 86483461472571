import {
  REQUEST_CONFIRMATION,
  CANCEL_CONFIRMATION,
  CONFIRM,
} from "./actionTypes"

export const requestConfirmation = (msg, confirmCb, cancelCb, confirmBtnText, confirmBtnStyle) => ({
  type: REQUEST_CONFIRMATION,
  payload: { msg, confirmCb, cancelCb, confirmBtnText, confirmBtnStyle },
})

export const cancelConfirmation = () => ({
  type: CANCEL_CONFIRMATION,
})

export const confirm = () => ({
  type: CONFIRM,
})

// export const createConfirmAction = (pendingConfirmationAction, msg) => {
//   return {
//     type: REQUEST_CONFIRMATION,
//     pendingConfirmationAction,
//     msg,
//   }
// }

// export const cancelConfirmation = () => {
//   return {
//     type: CANCEL_CONFIRMATION,
//   }
// }

// export const confirmPendingAction = () => {
//   return (dispatch, getState) => {
//     const cancelConfirmationAction = cancelConfirmation()
//     if (getState().pendingConfirmation) {
//       const pendingConfirmAction = getState().pendingConfirmation
//         .pendingConfirmationAction
//       dispatch(pendingConfirmAction)
//       dispatch(cancelConfirmationAction)
//     } else {
//       dispatch(cancelConfirmationAction)
//     }
//   }
// }
