import { capitalize } from 'lodash'
import React, { useEffect, useState } from "react"
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Row,
  Spinner
} from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import FormInput from "../../../../components/Common/FormInput"
import CustomGoogleMaps, { LatLng } from "../../../../components/CustomGoogleMaps/CustomGoogleMaps"
import CustomTabs from "../../../../components/CustomTabs/CustomTabs"
import { post, put, get } from "../../../../helpers/api_helper"
import { generateCoordinates, getPoint, getPointsTags, getCity, getSchoolTypes, getSchoolStages } from "../../../../helpers/backend_helper"
import * as url from "../../../../helpers/url_helper"
import { getCharityShops, openAlert } from "../../../../store/actions"
import { getCountries } from "../../../../store/countries/actions"
import AddTags, { CharityShopTag } from './AddTags/AddTags'

interface AddCharityShopInterface {
  history: any,
  match: any,
  returnTranslation: (text: string) => string;
  google: any;
}

interface OpeningHour {
  "dayOfWeek": string;
  "day": number;
  "from": string;
  "until": string;
  "active": boolean;
}

const AddCharityShop = ({ history, match: { params }, returnTranslation, google }: AddCharityShopInterface) => {
  const dispatch = useDispatch()
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  // const [not_accept, setNot_accept] = useState("")
  // const [accept, setAccept] = useState("")
  const [street, setStreet] = useState("")
  const [city, setCity] = useState("")
  const [postcode, setPostcode] = useState("")
  const [country_id, setCountry_id] = useState("1")
  const [lat, setLat] = useState<number>()
  const [lng, setLng] = useState<number>()
  const [active, setActive] = useState(1)
  const [formErrors, setFormErrors] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [foundAddress, setFoundAddress] = useState('')
  const [opening_hours, setOpening_hours] = useState<OpeningHour[]>([
    { "dayOfWeek": "Monday", "day": 1, "from": "10:00", "until": "20:00", "active": true },
    { "dayOfWeek": "Tuesday", "day": 2, "from": "10:00", "until": "20:00", "active": true },
    { "dayOfWeek": "Wednesday", "day": 3, "from": "10:00", "until": "20:00", "active": true },
    { "dayOfWeek": "Thursday", "day": 4, "from": "10:00", "until": "20:00", "active": true },
    { "dayOfWeek": "Friday", "day": 5, "from": "10:00", "until": "20:00", "active": true },
    { "dayOfWeek": "Saturday", "day": 6, "from": "10:00", "until": "20:00", "active": true },
    { "dayOfWeek": "Sunday", "day": 7, "from": "10:00", "until": "20:00", "active": false }
  ]);
  const countries = useSelector((state: any) => state?.Countries?.countries)
  const user = useSelector((state: any) => state?.Account?.user)

  const [labels] = useState(["General", "Location", "Opening Hours"])
  const [sizes] = useState([{ lg: 8, xl: 8 }, { md: 12, lg: 12, xl: 8 }, { lg: 6, xl: 6 }])
  const [currentTab, setCurrentTab] = useState(0)
  const [loaded, setLoaded] = useState(false);
  const [countryCode, setCountryCode] = useState("");

  const [tags, setTags] = useState<CharityShopTag[]>([]);
  const [selectedIdsAllowed, setSelectedIdsAllowed] = useState<number[]>([]);
  const [selectedIdsDisallowed, setSelectedIdsDisallowed] = useState<number[]>([]);

  const [schoolTypeId, setSchoolTypeId] = useState(0)
  const [schoolStageId, setSchoolStageId] = useState(0)

  const [schoolTypes, setSchoolTypes] = useState([]);
  const [schoolStages, setSchoolStages] = useState([]);

  const onOpenAlert = ({ text, status }: any) => dispatch(openAlert({ text: text, status: status }));

  useEffect(() => {
    init()
  }, [params?.uuid, countries?.length])

  const init = () => {
    if (!countries?.length) {
      return;
    }

    if (!params?.uuid) {
      handleChangeCountry("GB")
    } else {
      setIsEdit(true)
      handleGetPoint(params?.uuid);
    }

    if (user?.role === "CHARITY") {
      getTags(() => setLoaded(true));
    } else if (user?.role === "SCHOOL") {
      setLoaded(true)
      handleGetSchoolTypes()
      handleGetSchoolStages()
    }
  }

  useEffect(() => {
    dispatch(getCountries())
  }, [])

  useEffect(() => {
    if (tags?.length && !params?.uuid) {
      getAndSetUserPreferences();
    }
  }, [tags?.length])

  const handleGetPoint = async (id: number) => {
    try {
      await getPoint(user?.role, id)
        .then(res => {
          const { accept, city, country: countryName, description, lat, lng, name, not_accept, open_in_weekend, postcode, street, opening_hours, tags, type_id, stage_id } = res;
          // console.log(["res", res])
          // setAccept(accept ? accept : "")
          setCity(city ? city : "")
          const country = handleChangeCountry(countryName, false)
          setFoundAddress(getFullAddress({ city, street, postcode, country_id: country?.id }))
          setDescription(description ? description : "")
          setLat(lat ? parseFloat(lat) : undefined)
          setLng(lng ? parseFloat(lng) : undefined)
          setName(name ? name : "")
          // setNot_accept(not_accept ? not_accept : "")
          setPostcode(postcode ? postcode : "")
          setSelectedIdsAllowed(tags?.allowed ? tags?.allowed : [])
          setSelectedIdsDisallowed(tags?.disallowed ? tags?.disallowed : [])
          setStreet(street ? street : "")
          setOpening_hours((prev) => {
            return prev.map(singleOpeningHour => {
              const singleOpeningHourApi = opening_hours?.find((singleOpeningHourApi: any) => singleOpeningHour?.day === singleOpeningHourApi?.day)
              if (singleOpeningHourApi) {
                singleOpeningHourApi.active = true;
                return singleOpeningHourApi
              } else {
                singleOpeningHour.active = false;
                return singleOpeningHour
              }
            })
          })
          setSchoolTypeId(type_id ? parseInt(type_id?.id) : 0)
          setSchoolStageId(stage_id ? parseInt(stage_id?.id) : 0)
        })
    } catch (e) {

    }
  }

  const getAndSetUserPreferences = async () => {
    try {
      const user_preferences = await get(`${url.API_BASE}charities/points/create`)
      // const { opening_hours, tags, open_in_weekend } = user_preferences;
      if (user_preferences?.tags?.disallowed?.length || user_preferences?.tags?.allowed?.length) {
        // console.log("INSIDE", user_preferences)
        const selectedIdsAllowed = tags?.filter(item => user_preferences?.tags?.allowed?.includes(item?.id))?.map(item => item?.id);
        const selectedIdsDisallowed = tags?.filter(item => user_preferences?.tags?.disallowed?.includes(item?.id))?.map(item => item?.id);
        setSelectedIdsAllowed(selectedIdsAllowed)
        setSelectedIdsDisallowed(selectedIdsDisallowed)
      }

      if (user_preferences?.opening_hours?.length) {
        const mapped = opening_hours?.map(stateOpeningHour => {
          const foundInPreferences = user_preferences?.opening_hours?.find((userPrefOpeningHour: OpeningHour) => userPrefOpeningHour?.day === stateOpeningHour?.day)
          if (foundInPreferences) {
            return {
              ...foundInPreferences,
              active: true
            }
          }
          return {
            ...stateOpeningHour,
            active: false
          };
        })

        setOpening_hours(mapped)
      }

      setLoaded(true);
    } catch (e) {
      console.log(["err", e])
    }

  }

  const handleRemoveDaysWith0000OrInactive = () => {
    return opening_hours.filter(singleDayObject =>
      singleDayObject?.active && (singleDayObject.from != "00:00" || singleDayObject.until != "00:00")
    )
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const openingDaysFormatted = handleRemoveDaysWith0000OrInactive();

      const apiUrl = `${url.API_BASE}${user?.role === "CHARITY" ? "charities" : "schools"}/points${isEdit ? `/${params?.uuid}` : ""}`
      const data = {
        name,
        description,
        street,
        city,
        postcode,
        country_id,
        opening_hours: openingDaysFormatted,
        lat,
        lng,
        ...(user?.role === "CHARITY" ? {
          tags: {
            allowed: selectedIdsAllowed,
            disallowed: selectedIdsDisallowed
          },
          active: 1,
        } : {}),
        ...(user?.role === "SCHOOL" ? {
          type_id: schoolTypeId,
          stage_id: schoolStageId,
          status: 1
        } : {}),
      }

      let response: any = null;
      console.log(data, apiUrl);
      if (!isEdit) {
        response = await post(apiUrl, data);
      } else {
        response = await put(apiUrl, data);
      }

      dispatch(getCharityShops({ page: 1 }))
      dispatch(openAlert({ text: returnTranslation("Point successfuly added."), status: response?.status }))
      // history.push(`/charity-shops-list`)
    } catch (error) {
      //@ts-ignore
      if (error?.response?.data) {
        //@ts-ignore
        const { message, errors } = error?.response?.data
        setFormErrors(errors ?? {})
        dispatch(openAlert({ text: message, status: "danger" }))
      }
    }
  }

  const handleChangeCountry = (value: string | number, allowToUpdateCity = true) => {
    const country = countries?.find((country: any) => country?.code === value || country?.id === value || country?.name === value)
    setCountryCode(country?.code)
    setCountry_id(country?.id)

    if (country?.code && postcode && allowToUpdateCity)
      handleUpdateCity(postcode, country?.code)

    return country
  }

  const handleTimeChange = (time: string, day: number, type: string) => {
    const openingHoursCopy = opening_hours;
    const dayIndex = opening_hours.findIndex(singleDay => singleDay.day === day);
    const newDayObject = openingHoursCopy[dayIndex];

    if (type === "from") {
      newDayObject.from = time
    } else if (type === "until") {
      newDayObject.until = time
    }

    setOpening_hours(openingHoursCopy)
  }

  const getFullAddress = (customData?: { city?: string, street?: string, postcode?: string, country_id?: string }): string => {
    const finalStreet = customData?.street ? (customData?.street ? customData?.street : "") : (street ? street : "");
    const finalPostcode = customData?.postcode ? (customData?.postcode ? customData?.postcode : "") : (postcode ? postcode : "");
    const finalCity = customData?.city ? (customData?.city ? customData?.city : "") : (city ? city : "");
    const finalCountryId = customData?.country_id ? (customData?.country_id ? customData?.country_id : "") : (country_id ? country_id : "");

    if (!((finalStreet || finalPostcode || finalCity || finalCountryId) && countries?.length))
      return '';

    const country = countries?.find((country: any) => country.id === finalCountryId);
    const fullAddress = [finalStreet, `${finalPostcode} ${finalCity}`, country?.name]
      ?.filter(value => !!value?.trim())
      ?.join(", ")

    return fullAddress ? fullAddress : '';
  }

  const generateCoords = async (customData?: { city?: string, street?: string, postcode?: string, country_id?: string }) => {
    const fullAddress = getFullAddress(customData);
    if (!fullAddress) return;

    try {
      const response = await generateCoordinates(fullAddress);

      const { address, location: { lat, lng } } = response;

      setFoundAddress(address)
      setLat(lat)
      setLng(lng)
    } catch (error) {
      // console.log(error)
      dispatch(openAlert({ text: returnTranslation("Problem with address"), status: 'danger' }))
    }
  }

  const handleUpdateCity = async (postcode: string, countryCode: any) => {
    if (postcode && countryCode) {
      try {
        const cityObject = await handleGetCity(postcode, countryCode)
        if (cityObject?.name) {
          setCity(cityObject?.name)
          generateCoords({ city: cityObject?.name })
        }
      } catch (e) {
        // console.log(e)
      }
    } else {
      generateCoords()
    }
  }

  const handleGetCity = async (postcode: string, countryCode: any): Promise<{ id: number, name: string }> => {
    return new Promise(async (resolve, reject) => {
      // setIsLoadingCity(true)
      try {
        const cityObject = await getCity(postcode, countryCode)
        onOpenAlert({
          text: returnTranslation(`Found`) + ` ${cityObject.name}.`,
          status: "success",
        })
        resolve(cityObject)
      } catch (error) {
        // console.log(['error', error]);
        //@ts-ignore
        const { message } = error?.response?.data;
        onOpenAlert({
          text: message,
          status: "danger",
        })
        reject(error)
      } finally {
        // setIsLoadingCity(false)
      }
    })
  }

  const handleSetActiveDay = (dayName: string, value: boolean) => {
    setOpening_hours((prev) => {
      return prev?.map(item => {
        if (item.dayOfWeek === dayName) {
          item.active = value;
        }
        return item;
      })

    })
  }

  const getTags = async (cb?: any) => {
    try {
      const response = await getPointsTags(user?.role)
      if (response?.items) {
        const items: CharityShopTag[] = response?.items;
        setTags(items)
      }
      cb && cb();
    } catch (e) {
      console.log(e)
    }
  }

  const handleGetSchoolTypes = async () => {
    try {
      const response = await getSchoolTypes();
      setSchoolTypes(response)
    } catch (e) {

    }
  }

  const handleGetSchoolStages = async () => {
    try {
      const response = await getSchoolStages();
      setSchoolStages(response)
    } catch (e) {

    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"LOOP"}
            breadcrumbItems={[
              "Charity Shops",
              isEdit
                ? `Edit Charity Shop (${name})`
                : "Add New Charity Shop",
            ]}
          />

          <Row>
            <Col sm="12" md="12" lg="12" xl="7">
              <Card>
                <CardBody style={{
                  overflowX: 'auto'
                }}>
                  {!loaded ? (
                    <div style={{ height: 500 }} className="w-100 d-flex justify-content-center align-items-center">
                      <Spinner className="ms-2" color="primary" />
                    </div>
                  ) : (
                    <Form onSubmit={e => handleSubmit(e)}>
                      <CustomTabs
                        labels={labels}
                        onChange={(newValue: any) => {
                          setCurrentTab(newValue)
                        }}
                        currentTab={labels[currentTab]}
                        className={"tabs-wide"}
                        minTabHeight={400}
                      >
                        {/* TAB 1 */}
                        <Row>
                          <Col
                          // {...sizes[currentTab]}
                          >
                            <div className="mb-3">
                              <FormInput
                                disabled={false}
                                label={returnTranslation("Name")}
                                type="name"
                                name="name"
                                value={name}
                                className="form-control"
                                placeholder={returnTranslation("Enter value")}
                                showErrors
                                formErrors={formErrors}
                                onChange={(e: any) => { setName(e.target.value) }}
                              />
                            </div>

                            <div className="mb-3">
                              <FormInput
                                disabled={false}
                                label={returnTranslation("Description")}
                                type="textarea"
                                rows={3}
                                name="description"
                                value={description}
                                className="form-control"
                                placeholder={returnTranslation("Enter value")}
                                showErrors
                                formErrors={formErrors}
                                onChange={(e: any) => { setDescription(e.target.value) }}
                              />
                            </div>

                            {user?.role === "CHARITY" ? (
                              <AddTags
                                type="allowed"
                                tags={tags}
                                setTags={setTags}
                                returnTranslation={returnTranslation}
                                setAllowedIds={setSelectedIdsAllowed}
                                allowedIds={selectedIdsAllowed}
                                setDisallowedIds={setSelectedIdsDisallowed}
                                disallowedIds={selectedIdsDisallowed}
                              />
                            ) : null}
                            {user?.role === "SCHOOL" ? (
                              <>
                                <div className="mb-3">
                                  {/* @ts-ignore */}
                                  <FormInput disabled={false}
                                    label={"Type of school"}
                                    type="select"
                                    name="school_type_id"
                                    className="form-control"
                                    placeholder={"Choose"}
                                    showErrors
                                    formErrors={formErrors}
                                    onChange={(e) => setSchoolTypeId(e?.target?.value)}
                                    value={schoolTypeId}
                                    options={schoolTypes?.map((type: any) => (
                                      <option key={type?.id} value={type?.id}>{type?.name}</option>
                                    ))}
                                  />
                                </div>

                                <div className="mb-3">
                                  <FormInput
                                    label={"Educational stage"}
                                    type="select"
                                    name="school_stage_id"
                                    className="form-control"
                                    placeholder={"Choose"}
                                    showErrors
                                    formErrors={formErrors}
                                    onChange={(e) => setSchoolStageId(e?.target?.value)}
                                    value={schoolStageId}
                                    options={schoolStages?.map((type: any) => (
                                      <option key={type?.id} value={type?.id}>{type?.name}</option>
                                    ))}
                                  />
                                </div>
                              </>
                            ) : null}

                            {/* <AddTags
                              type="not_allowed"
                              tags={tags}
                              setTags={setTags}
                              returnTranslation={returnTranslation}
                              setAllowedIds={setSelectedIdsAllowed}
                              allowedIds={selectedIdsAllowed}
                              setDisallowedIds={setSelectedIdsDisallowed}
                              disallowedIds={selectedIdsDisallowed}
                            /> */}
                          </Col>
                        </Row>
                        {/* TAB 2 */}
                        <Row>
                          <Col
                          // {...sizes[currentTab]}
                          >
                            <Row>
                              <Col sm={12} md={6} lg={6} xl={6}>
                                <div className="mb-3 mt-2">
                                  <FormInput
                                    label={returnTranslation("Country")}
                                    type="country"
                                    name="country_code"
                                    className="form-control"
                                    placeholder={returnTranslation("Select country")}
                                    showErrors
                                    formErrors={formErrors}
                                    onChange={handleChangeCountry}
                                    value={countryCode}
                                    countries={countries?.map((country: any) => country?.code)}
                                    onBlur={() => generateCoords()}
                                  />
                                </div>

                                <div className="mb-3">
                                  <FormInput disabled={false}
                                    label={returnTranslation("Street and number")}
                                    type="street"
                                    name="street"
                                    value={street}
                                    className="form-control"
                                    placeholder={returnTranslation("Enter value")}
                                    showErrors
                                    formErrors={formErrors}
                                    onChange={(e: any) => { setStreet(e.target.value) }}
                                    onBlur={() => generateCoords()}
                                  />
                                </div>

                                <div className="mb-3">
                                  <FormInput
                                    disabled={false}
                                    label={returnTranslation("Postcode")}
                                    type="text"
                                    name="postcode"
                                    value={postcode}
                                    className="form-control"
                                    placeholder={returnTranslation("Enter postcode")}
                                    showErrors
                                    formErrors={formErrors}
                                    onChange={(e: any) => setPostcode(e.target.value)}
                                    onBlur={() => {
                                      handleUpdateCity(postcode, countryCode)
                                    }}
                                  />
                                </div>

                                <div className="mb-3">
                                  <FormInput disabled={false}
                                    label={returnTranslation("City")}
                                    type="city"
                                    name="city"
                                    value={city}
                                    className="form-control"
                                    placeholder={returnTranslation("Enter value")}
                                    showErrors
                                    formErrors={formErrors}
                                    onChange={(e: any) => { setCity(e.target.value) }}
                                    onBlur={generateCoords}
                                  />
                                </div>

                                <div>
                                  <small className="text-muted">
                                    {foundAddress ?
                                      <span>
                                        <span>Full address:</span>
                                        {" "}
                                        <b>{foundAddress}</b>
                                      </span>
                                      : null
                                    }
                                  </small>
                                </div>
                              </Col>

                              <Col sm={12} md={6} lg={6} xl={6} className={`add-charity-shop-google-maps-container`}>
                                {/* @ts-ignore */}
                                {formErrors?.lat || formErrors?.lng ? <small className="text-danger">{returnTranslation("Coordinates are used.")}</small> : null}
                                {/* @ts-ignore */}
                                <div className={`${formErrors?.lat || formErrors?.lng ? "google-maps-error" : ""}`}>
                                  <CustomGoogleMaps
                                    // @ts-ignore
                                    returnTranslation={returnTranslation}
                                    defaultCenter={{ lat: 51.507290, lng: -0.127751 }}
                                    markerPosition={{ lat, lng }}
                                    defaultZoom={18}
                                    center={{ lat: lat ? lat : 51.507290, lng: lng ? lng : -0.127751 }}
                                    markerDraggable={true}
                                    onMarkerDragEnd={(coords: LatLng) => {
                                      setFormErrors({
                                        ...formErrors,
                                        lat: false,
                                        lng: false
                                      })
                                      setLat(coords?.lat)
                                      setLng(coords?.lng)
                                    }}
                                    setCurrentPosition={(coords: LatLng) => {
                                      setLat(coords?.lat)
                                      setLng(coords?.lng)
                                    }}
                                    //@ts-ignore
                                    error={formErrors?.lat || formErrors?.lng}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {/* TAB 3 */}
                        <Row>
                          <Col
                          // {...sizes[currentTab]}
                          >
                            {opening_hours?.map((singleDayOpening, i) => {
                              return (
                                <div className="single-day-time-inline" key={`${singleDayOpening?.day}-${singleDayOpening?.from}-${singleDayOpening?.until}`}>
                                  <div className="clickable d-flex align-items-center"
                                    onClick={(e: any) => handleSetActiveDay(singleDayOpening?.dayOfWeek, !singleDayOpening?.active)}
                                  >
                                    <FormInput
                                      label={""}
                                      type="checkbox"
                                      name="active"
                                      className="form-check-input mt-2 me-1"
                                      placeholder={""}
                                      showErrors
                                      formErrors={formErrors}
                                      // onChange={(e: any) => handleSetActiveDay(singleDayOpening?.name, !singleDayOpening?.active)}
                                      checked={singleDayOpening?.active}
                                      id={`apt-${i}-checkbox`}
                                    />
                                    <label className="clickable" htmlFor={`apt-${i}-checkbox`} >
                                      {`${capitalize(singleDayOpening?.dayOfWeek)}`}
                                    </label>
                                  </div>

                                  <div>
                                    {singleDayOpening?.active ? (
                                      <>
                                        {/* <div className="input--floting-label"> */}

                                        <FormInput
                                          type="time"
                                          id={`apt-${i}-open`}
                                          name={`apt-${i}-open`}
                                          min="00:00"
                                          max="23:59"
                                          defaultValue={singleDayOpening?.from}
                                          onChange={(e: any) => handleTimeChange(e.target.value, singleDayOpening?.day, "from")}
                                          required={true}
                                          className="form-control"
                                          floatingLabel={returnTranslation("Open from")}
                                          wrapperClass={"mb-0"}
                                        />
                                        {/* </div> */}

                                        {/* <TextField id="outlined-basic" label="Open From" variant="outlined" /> */}
                                        <FormInput
                                          type="time"
                                          id={`apt-${i}-close`}
                                          name={`apt-${i}-close`}
                                          min="00:00"
                                          max="23:59"
                                          defaultValue={singleDayOpening?.until}
                                          onChange={(e) => handleTimeChange(e.target.value, singleDayOpening?.day, "until")}
                                          required={true}
                                          className="form-control"
                                          floatingLabel={returnTranslation("Open till")}
                                          wrapperClass={"mb-0"}
                                        />
                                      </>
                                    ) : (
                                      <div className="d-flex align-items-center" style={{ height: 40 }}>{returnTranslation("Closed")}</div>
                                    )}
                                  </div>
                                </div>
                              )
                            })}
                          </Col>
                        </Row>
                      </CustomTabs>

                      <div className="p-3 w-100">
                        {isEdit || currentTab == 2 ? (
                          <div className="d-flex">
                            <Button
                              color="success"
                              className={`me-2 ${isEdit ? "w-100" : ""}`}
                              type="submit"
                              style={{ width: 100 }}
                            >
                              {returnTranslation('Submit')}
                            </Button>

                            {!isEdit ? (
                              <Button
                                color="secondary"
                                type="button"
                                onClick={() => {
                                  setCurrentTab(currentTab - 1)
                                }}
                              >
                                {returnTranslation('Back')}
                              </Button>
                            ) : null}
                          </div>
                        ) : null}

                        {!isEdit && currentTab !== 2 ? (
                          <>
                            <Button
                              color="success"
                              className="me-2"
                              type="button"
                              style={{ width: 100 }}
                              onClick={() => {
                                setCurrentTab(currentTab + 1)
                              }}
                            >
                              {returnTranslation('Next')}
                            </Button>

                            {currentTab !== 0 ? (
                              <Button
                                color="secondary"
                                className="me-2"
                                type="button"
                                onClick={() => {
                                  setCurrentTab(currentTab - 1)
                                }}
                              >
                                {returnTranslation('Back')}
                              </Button>
                            ) : null}
                          </>
                        ) : null}
                        <div className="d-flex flex-column mt-2">
                          {Object.entries(formErrors)?.map(([key, value]) => {
                            return key !== "lat" ? <small className="text-danger">*{value}</small> : null;
                          })}
                        </div>
                      </div>

                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
// const LoadingContainer = () => <div>Loading...</div>
export default AddCharityShop;
// export default GoogleApiWrapper({
//   apiKey: "AIzaSyBk-jAVsR-Ut7gp1_4BmHbf5_SLuQzH9EI",
//   LoadingContainer,
//   //@ts-ignore
//   v: "3",
//   //@ts-ignore
// })(AddCharityShop)