import {
  GET_TAKE_BACKS,
  GET_TAKE_BACKS_FAIL,
  GET_TAKE_BACKS_SUCCESS,
  GET_TAKE_BACK_CARRIERS,
  GET_TAKE_BACK_CARRIERS_FAIL,
  GET_TAKE_BACK_CARRIERS_SUCCESS,
  CLEAR_TAKE_BACK_CARRIERS,
} from "./actionTypes"

export const getTakeBacks = (payload) => ({
  type: GET_TAKE_BACKS,
  payload: {
    page: payload?.page ? payload?.page : 1,
    queryObject: payload?.queryObject ? payload?.queryObject : {},
    successCb: payload?.successCb
  }
})

export const getTakeBacksSuccess = data => ({
  type: GET_TAKE_BACKS_SUCCESS,
  payload: data,
})

export const getTakeBacksFail = error => ({
  type: GET_TAKE_BACKS_FAIL,
  payload: error,
})

export const getTakeBackCarriers = (payload) => ({
  type: GET_TAKE_BACK_CARRIERS,
  payload: {
    id: payload?.id,
    page: payload?.page ? payload?.page : 1,
    queryObject: payload?.queryObject ? payload?.queryObject : {}
  }
})

export const getTakeBackCarriersSuccess = data => ({
  type: GET_TAKE_BACK_CARRIERS_SUCCESS,
  payload: data,
})

export const getTakeBackCarriersFail = error => ({
  type: GET_TAKE_BACK_CARRIERS_FAIL,
  payload: error,
})

export const clearTakeBackCarriers = () => ({
  type: CLEAR_TAKE_BACK_CARRIERS
})