import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG } from "./actionTypes"

const initialState = {
  error: "",
  success: "",
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state }
      break
    case PROFILE_SUCCESS:
      // console.log(['PROFILE_SUCCESS action.payload?.user', action.payload?.user]);
      updateUserLocalStorage(action.payload?.user)
      state = { ...state, success: action.payload }
      break
    case PROFILE_ERROR:
      // console.log(['PROFILE_ERROR', action.payload]);
      state = { ...state, error: action.payload }
      break
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

const updateUserLocalStorage = (user) => {
  let currentUserObj = JSON.parse(localStorage.getItem("authUser"))
  localStorage.setItem("authUser", JSON.stringify({ ...currentUserObj, ...user, ...user?.address }))
}

export default profile
