// @ts-ignore
import React, { FormEvent, useEffect, useState } from "react";
// @ts-ignore
import MetaTags from 'react-meta-tags';
// @ts-ignore
import { connect, useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { RouteComponentProps, useParams } from "react-router";
// @ts-ignore
import { withRouter } from "react-router-dom";
import {
	Card,
	CardBody,
	Col,
	Container, Row,
	Button,
	Spinner,
	Form
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { apiError, checkAccount } from "../../../store/actions";
import { openAlert } from "../../../store/alert/actions";
import SingleConfigurationOption from "../../Dashboard/WelcomePanels/SingleConfigurationOption/SingleConfigurationOption";
//@ts-ignore
import SchoolDonate from "../../../assets/images/school_donate.svg";
import FormInput from "../../../components/Common/FormInput";
import { saveMicroDonationsExternalUrl } from "../../../helpers/backend_helper";

export interface Props extends RouteComponentProps {
	dashbord_id: string,
	apiError: (err: string) => {},
	history: any,
	error: string,
	user: any,
	onOpenAlert: any,
	returnTranslation: any
}

const EnableMicroDonations = ({ returnTranslation, onOpenAlert, history }: Props) => {
	const dispatch = useDispatch();
	const MICRO_DONATIONS = "MICRO_DONATIONS"

	const onCheckAccount = () => dispatch(checkAccount());
	const accountInfo = useSelector((state: any) => state.Account?.info);
	const microDonations = useSelector((state: any) => state.Account?.info?.configuration?.entitlements[MICRO_DONATIONS]);
	const details = useSelector((state: any) => state.Account?.info?.configuration?.details)
	const paypal_connection = useSelector((state: any) => state.Account?.info?.configuration?.paypal_connection)
	const user = useSelector((state: any) => state?.Account?.user)

	const [isSaving, setIsSaving] = useState(false);
	const [formErrors, setFormErrors] = useState({});

	useEffect(() => {
		onCheckAccount();
	}, [])

	const handleSubmit = async (event: FormEvent) => {
		event?.preventDefault()
		setFormErrors({})

		try {
			//@ts-ignore
			const formData = new FormData(event?.target);
			// console.log(Array.from(formData.entries()))
			const response = await saveMicroDonationsExternalUrl({
				external_url: formData.get("external_url")
			})
			onOpenAlert({ status: 'success', text: returnTranslation("Saved successfully.") })
		} catch (e) {
			//@ts-ignore
			const { message, errors, status } = e?.response?.data;
			setFormErrors(errors)
			onOpenAlert({ status: 'danger', text: returnTranslation(message) })
		}finally{
			onCheckAccount();
			setIsSaving(false)
		}
	}

	return (
		<React.Fragment>
			<MetaTags>
				<title>
					{user?.role === 'CHARITY' ? returnTranslation("LOOP | Donations Setup - Micro-Donations") : null}
					{user?.role === 'SCHOOL' ? returnTranslation("LOOP | LOOP FOR GOOD - Micro-Donations") : null}
				</title>
			</MetaTags>
			<div className="page-content">
				<Container fluid>
					{user?.role === 'CHARITY' ? <Breadcrumb title="Donations Setup" breadcrumbItem="Micro Donations" /> : null}
					{user?.role === 'SCHOOL' ? <Breadcrumb title="LOOP FOR GOOD" breadcrumbItem="Micro Donations" /> : null}
				
					<Row>
						<Col sm={12} md={12} lg={12} xl={8}>
							<Card>
								<CardBody>
									<div className="welcome-panel__steps">
										<SingleConfigurationOption
											header={"Micro-Donations"}
											description={"Connect to the world of digital fashion and acquire new donors and supporters among highly engaging community of shoppers and influencers on LOOP Digital Wardrobe. Simply add your bank details to start receiving micro-donations from LOOP users."}
											descriptionClassName="m-0"
											filled={details && (paypal_connection || accountInfo?.micro_donations_url) && microDonations?.status === "ACTIVE"}
											tasksOpened={true}
											containerClassName="p-0"
											tasksOptions={[
												{
													name: returnTranslation("Details"),
													isActive: details,
													route: `/details`
												},
												// {
												// 	name: returnTranslation("Set up Virtual Charity"),
												// 	isActive: entitlements[VIRTUAL_CHARITY].status === "ACTIVE",
												// 	route: "/virtual-charity-shop"
												// },
												{
													name: returnTranslation("Payouts with Paypal"),
													isActive: paypal_connection,
													route: "/payouts-with-paypal"
												},
												{
													name: "External Link",
													isActive: !!accountInfo?.micro_donations_url,
													route: "/micro-donations"
												}
											]}
											entitlement={microDonations}
											entitlementType={MICRO_DONATIONS}
											verified={accountInfo?.verified}
											activateCondition={details && (paypal_connection || accountInfo?.micro_donations_url)}
											returnTranslation={returnTranslation}
											customFilledIconDone={user?.role === "CHARITY"
												? <img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donate-micro-donations.png" />
												: <img className="single-configuration-option--icon" src={SchoolDonate} />
											}
											customFilledIconUndone={user?.role === "CHARITY"
												? <img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donate-micro-donations.png" />
												: <img className="single-configuration-option--icon" src={SchoolDonate} />
											}
										/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col sm={12} md={12} lg={12} xl={8}>
							<Card>
								<CardBody>
									<Form
										className="form-horizontal"
										onSubmit={handleSubmit}
									>
										<div className="mt-3">
											<h4 className="card-title">{returnTranslation("External Link")}</h4>
											<span className="text-muted">{returnTranslation("Instead of using LOOP Micro-Donations solution you can just simply paste an external link to redirect LOOP users to your charity donations website!")}</span>
											<FormInput
												// disabled={!isCustomIntegration}
												label={returnTranslation("")}
												type="text"
												name={"external_url"}
												// required={field?.required}
												defaultValue={accountInfo?.micro_donations_url ? accountInfo?.micro_donations_url : ""}
												className="form-control"
												placeholder={returnTranslation("https://...")}
												showErrors={true}
												formErrors={formErrors}
												// onChange={(e: any) => {
												// 	setLength(e.target.value)
												// }}
											/>
										</div>
										<Button
											color="success"
											type="submit"
											className="btn-no-shadow btn-block waves-effect waves-light"
											// disabled={isFormDisabled}
										>
											{returnTranslation("Save")}
											{isSaving ? (
												<Spinner className="ms-2" color="dark" />
											): null}
										</Button>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	)
}

const mapStateToProps = (state: any) => {
	const { error } = state.Login
	const { dashbord_id, user } = state.Account
	const { countries } = state.Countries
	return { error, dashbord_id, user, countries }
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		onApiError: () => apiError(),
		onOpenAlert: ({ text, status }: any) =>
			dispatch(openAlert({ text: text, status: status })),
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EnableMicroDonations)
)
