import React, { useState, useEffect } from "react"
import FormInput from "./../../components/Common/FormInput"
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Row,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { apiError, openAlert } from "../../store/actions"
import * as url from "../../helpers/url_helper"
import { getCountries } from "../../store/countries/actions"
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
import { generateCoordinates, getReturnWarehouse } from "./../../helpers/backend_helper"
import { post } from "./../../helpers/api_helper"

interface CharityReturnWarehouseInterface {
    history: any,
    match: any,
    returnTranslation: (name: string) => string
}

const CharityReturnWarehouse = ({ history, match: { params }, returnTranslation }: CharityReturnWarehouseInterface) => {
    const dispatch = useDispatch()
    const [street, setStreet] = useState("")
    const [city, setCity] = useState("")
    const [country_id, setCountry_id] = useState("1")
    const [postcode, setPostcode] = useState("")
    const [lat, setLat] = useState('')
    const [lng, setLng] = useState('')
    const [foundAddress, setFoundAddress] = useState('')
    const [formErrors, setFormErrors] = useState([])

    const dashboardID = useSelector((state: any) => state?.Account?.dashbord_id)
    const userToken = useSelector((state: any) => state?.Account?.user?.access_token)
    const countries = useSelector((state: any) => state?.Countries?.countries)

    useEffect(() => {
        dispatch(getCountries())
        getReturnWarehouse().then(res => {
            setStreet(res?.street ? res?.street : "")
            setCity(res?.city ? res?.city : "")
            setCountry_id(res?.country_id ? res?.country_id : "1")
            setPostcode(res?.postcode ? res?.postcode : "")
            setLat(res?.lat ? res?.lat : '')
            setLng(res?.lng ? res?.lng : '')
        })

    }, [])

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (street && city && country_id && postcode && lat && lng) {

            return new Promise(async (resolve, reject) => {
                post(`${url.API_BASE}charities/return_address`, {
                    street,
                    city,
                    country_id,
                    postcode,
                    lat,
                    lng
                })
                    .then(response => {
                        dispatch(openAlert({ text: returnTranslation("Success"), status: status }))
                        history.push(`/`)

                        return resolve(response)
                    })
                    .catch(error => {
                        if (error?.response?.data) {
                            const { message, errors } = error?.response?.data
                            setFormErrors(errors)

                            dispatch(openAlert({ text: message, status: status }))
                        }
                        return reject(error)
                    })
            })
        } else {
            dispatch(openAlert({ text: returnTranslation("All fields required"), status: "danger" }))
        }
    }

    const handleCountryChange = (event: any) => {
        setCountry_id(event.target.value)
    }

    const generateCoords = async () => {
        if (street && postcode && city && country_id && countries?.length) {
            const country = countries?.find((country: any) => country.id === country_id);

            const fullAddress = `${street}, ${postcode} ${city}, ${country?.name}`
            try {
                const response = await generateCoordinates(fullAddress);

                const { address, location: { lat, lng } } = response;

                setFoundAddress(address)
                setLat(lat)
                setLng(lng)
            } catch (error) {
                // console.log(error)
                dispatch(openAlert({ text: returnTranslation("Problem with address"), status: 'danger' }))
            }

        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Donation" breadcrumbItem="Modify donation address" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Form onSubmit={e => handleSubmit(e)}>
                                        <div className="mb-3">
                                            {/* @ts-ignore */}
                                            <FormInput disabled={false}
                                                label={returnTranslation("Street")}
                                                type="street"
                                                name="street"
                                                value={street}
                                                className="form-control"
                                                placeholder={returnTranslation("Enter value")}
                                                showErrors
                                                formErrors={formErrors}
                                                onChange={(e: any) => { setStreet(e.target.value) }}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            {/* @ts-ignore */}
                                            <FormInput disabled={false}
                                                label={returnTranslation("City")}
                                                type="city"
                                                name="city"
                                                value={city}
                                                className="form-control"
                                                placeholder={returnTranslation("Enter value")}
                                                showErrors
                                                formErrors={formErrors}
                                                onChange={(e: any) => { setCity(e.target.value) }}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            {/* @ts-ignore */}
                                            <FormInput disabled={false}
                                                label={returnTranslation("Postcode")}
                                                type="postcode"
                                                name="postcode"
                                                value={postcode}
                                                className="form-control"
                                                placeholder={returnTranslation("Enter value")}
                                                showErrors
                                                formErrors={formErrors}
                                                onChange={(e: any) => { setPostcode(e.target.value) }}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            {/* @ts-ignore */}
                                            <FormInput disabled={false}
                                                label={returnTranslation("Country")}
                                                type="select"
                                                name="country_id"
                                                className="form-control"
                                                placeholder={returnTranslation("Choose")}
                                                showErrors
                                                formErrors={formErrors}
                                                onChange={handleCountryChange}
                                                value={country_id}
                                                options={
                                                    countries?.map((country: any, i: number) => {
                                                        return (
                                                            <option
                                                                key={country?.id}
                                                                value={country?.id}
                                                            >
                                                                {country?.name}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="mb-3 mt-2">
                                            <div className="row">

                                                <div className="col-sm-4 d-flex align-items-end">
                                                    <div className="form-group w-100">
                                                        <button
                                                            disabled={!(street && postcode && city && country_id)}
                                                            className="w-100 btn btn-primary btn-block waves-effect waves-light"
                                                            type="button"
                                                            onClick={generateCoords}
                                                        >
                                                            {returnTranslation('Generate coordinates')}
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="col-sm-4">
                                                    {/* @ts-ignore */}
                                                    <FormInput
                                                        disabled={true}
                                                        label={returnTranslation("Latitude")}
                                                        type="lat"
                                                        name="lat"
                                                        value={lat}
                                                        className="form-control"
                                                        placeholder={returnTranslation("Latitude")}
                                                        showErrors
                                                        formErrors={formErrors}
                                                        onChange={(e: any) => { setLat(e.target.value) }}
                                                    />
                                                </div>

                                                <div className="col-sm-4">
                                                    {/* @ts-ignore */}
                                                    <FormInput
                                                        disabled={true}
                                                        label={returnTranslation("Longitude")}
                                                        type="lng"
                                                        name="lng"
                                                        value={lng}
                                                        className="form-control"
                                                        placeholder={returnTranslation("Longitude")}
                                                        showErrors
                                                        formErrors={formErrors}
                                                        onChange={(e: any) => { setLng(e.target.value) }}
                                                    />
                                                </div>
                                            </div>

                                            <div style={{ height: '15px' }}>
                                                <small className="text-muted">
                                                    {foundAddress ?
                                                        <span>
                                                            <span>Coordinates found for address:</span>
                                                            {" "}
                                                            <b>{foundAddress}</b>
                                                        </span>
                                                        : null
                                                    }
                                                </small>
                                            </div>
                                        </div>

                                        {/* 
                                        <div className="mb-5 d-grid">
                                            <button
                                                disabled={!(street && postcode && city && country_id)}
                                                className="btn btn-primary btn-block waves-effect waves-light"
                                                type="button"
                                                onClick={generateCoords}
                                                >
                                                {returnTranslation('Generate coordinates')}
                                            </button>
                                        </div> */}


                                        <div className="mt-4 d-grid">
                                            <button
                                                className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
                                                type="submit"
                                            >
                                                {returnTranslation('Submit')}
                                            </button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CharityReturnWarehouse