import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import websiteSaga from "./websites/saga"
import countriesSaga from "./countries/saga"
import dictionariesSaga from "./dictionaries/saga"
import couponsSaga from "./coupons/saga"
import currenciesSaga from "./currencies/saga"
import charityShopsSaga from "./charityShops/saga"
import translationsSaga from "./translations/saga"
import paymentInfoSaga from "./paymentInfo/saga"
import billingsSaga from "./billings/saga"
import invoicesSaga from "./invoices/saga"
import feedAnalytics from "./FeedAnalytics/saga"
import takeBacksSaga from "./takeBacks/saga"
import localStoresSaga from "./localStores/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(websiteSaga),
    fork(countriesSaga),
    fork(dictionariesSaga),
    fork(currenciesSaga),
    fork(couponsSaga),
    fork(charityShopsSaga),
    fork(translationsSaga),
    fork(paymentInfoSaga),
    fork(billingsSaga),
    fork(invoicesSaga),
    fork(feedAnalytics),
    fork(takeBacksSaga),
    fork(localStoresSaga)
  ])
}
