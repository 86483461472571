import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { useDispatch } from "react-redux"
import { Button, Col, Row, Spinner } from "reactstrap"
import Toolbar from "../Toolbar/Toolbar"
import filterFactory from 'react-bootstrap-table2-filter';
import "./BasicTable.scss";
import { useHistory } from "react-router"

interface IBasicTableProps {
  columns: any[],
  items: any[],
  addItemBtnText?: string,
  addItemBtnAction?: any,
  addItemBtnColor?: string;
  addItemBtnIconClass?: string;

  addItemBtnText2?: string,
  addItemBtnAction2?: any,
  addItemBtnColor2?: string;
  addItemBtnIconClass2?: string;

  addItemBtnText3?: string,
  addItemBtnAction3?: any,
  addItemBtnColor3?: string;
  addItemBtnIconClass3?: string;

  searchbar: boolean,
  getAction?: (payload: { page: number, searchText?: string }) => void,
  getFunction?:  (page: number, searchText?: string) => void;
  totalSize: number,
  sizePerPage: number,
  currentPage?: number,
  displaySelectMultiple?: any,
  selectMultipleOptions?: any,
  defaultSort?: {
    sort: string,
    direction: string
  },
  currentSort?: {
    sort: string,
    direction: string
  },
  isLoading?: boolean,
  activeColumns?: any,
  onSetActiveColumns?: (columnName: string) => void,
  customFilter?: any;
  simple?: boolean;

  tableCustomClassName?: string;
}

const BasicTable = ({
  columns,
  items,
  addItemBtnText,
  addItemBtnAction,
  addItemBtnColor,
  addItemBtnIconClass,

  addItemBtnText2,
  addItemBtnAction2,
  addItemBtnColor2,
  addItemBtnIconClass2,

  addItemBtnText3,
  addItemBtnAction3,
  addItemBtnColor3,
  addItemBtnIconClass3,

  searchbar,
  getAction,
  getFunction,
  totalSize,
  sizePerPage,
  currentPage,
  displaySelectMultiple,
  selectMultipleOptions,
  defaultSort,
  isLoading,
  activeColumns,
  onSetActiveColumns,
  customFilter,
  simple,

  tableCustomClassName
}: IBasicTableProps) => {
  const { SearchBar } = Search
  const dispatch = useDispatch()
  const history = useHistory();

  const [selected, setSelected] = useState([])

  const handleClearSelected = () => {
    // console.log(["handleClearSelected"])
    setSelected([])
  }

  const handleOnSelect = (row: any, isSelect: any) => {
    // console.log(["row", row])
    if (isSelect) {
      //@ts-ignore
      setSelected([...selected, row.id])
    } else {
      setSelected(selected.filter(x => x !== row.id))
    }
  }

  const handleOnSelectAll = (isSelect: any, rows: any) => {
    const ids = rows.map((r: any) => r.id);
    if (isSelect) {
      setSelected(ids)
    } else {
      setSelected([])
    }
  }

  useEffect(() => {
    if (getAction && !items?.length) dispatch(getAction({ page: currentPage ? currentPage : 1 }))
    else if (getFunction) {
      getFunction(currentPage ? currentPage : 1)
    }
  }, [])

  const handleTableChange = (type: any, { page, searchText }: any) => {
    if (getAction)
      switch (type) {
        case "pagination":
          dispatch(getAction({ page }))
          break;
        case "search":
          dispatch(getAction({ page, searchText }))
          break;
      } else if (getFunction) {
        switch (type) {
          case "pagination":
            getFunction(page)
            break;
          case "search":
            getFunction(page, searchText)
            break;
        }
      }
  }

  const sortOption = {
    sortCaret: (order: string, column: any) => {
      if (!order) return <span className="sort-available-icon"><i className="mdi mdi-chevron-up" /><i className="mdi mdi-chevron-down" /></span>
      if (order === 'asc') return <i className="mdi mdi-chevron-up" />
      else if (order === 'desc') return <i className="mdi mdi-chevron-down" />
      return null;
    },
    dataField: defaultSort?.sort,
    order: defaultSort?.direction,
  }

  const getQueryParam = (paramName: string) => {
    return new URLSearchParams(location?.search).get(paramName)
  }

  useEffect(() => {
    !isLoading && !simple && triggerPageQueryString();
  }, [isLoading, simple])

  const triggerPageQueryString = () => {
    //fix no pagination bug on first enter
    //it forces state change at BasicTable pagination
    history.replace(`${location.pathname}?page=`);
    // console.log(currentPage)
    currentPage && setTimeout(() => {
      history.replace(`${location.pathname}?page=${currentPage}`);
    }, 50)
  }

  return (
    <>
      {!isLoading ?
        <Row>
          <Col xs="12">
            <PaginationProvider
              pagination={paginationFactory({
                sizePerPage: sizePerPage ? sizePerPage : 15,
                totalSize: totalSize ? totalSize : 0,
                custom: true,
                //@ts-ignore
                page: getQueryParam('page') ? parseInt(getQueryParam('page')) : currentPage
              })}
            >
              {({ paginationProps, paginationTableProps }: any) => (
                <ToolkitProvider
                  keyField="id"
                  data={items || []}
                  columns={columns}
                  bootstrap4
                  search
                >
                  {(toolkitProps: any) => (
                    <>
                      <Row className="mb-2">
                        <Col sm="6">
                          {searchbar && (
                            <Row>
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Row>
                          )}
                          {customFilter && customFilter}
                          {activeColumns && onSetActiveColumns ? (
                            <Row>
                              <div style={{
                                display: "grid",
                                gridTemplateColumns: "auto auto auto auto"
                              }}>
                                {Object.entries(activeColumns).map((columnEntry: any[]) => {
                                  const columnName = columnEntry[0]
                                  const isColumnActive = columnEntry[1]
                                  return (
                                    <div className="form-check">
                                      {/* <span> */}
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={isColumnActive}
                                        id={`checkbox-${columnName}`}
                                        onChange={() => onSetActiveColumns(columnName)}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`checkbox-${columnName}`}
                                      >
                                        {columnName}
                                      </label>
                                    </div>
                                  )
                                })}
                              </div>
                            </Row>
                          ) : null}
                          {selectMultipleOptions ?
                            <Row>
                              <Col>
                                <Toolbar
                                  selectMultipleOptions={selectMultipleOptions}
                                  selected={selected}
                                  handleClearSelected={handleClearSelected}
                                />
                              </Col>
                            </Row>
                          : null}
                        </Col>
                        <Col sm="6" className="justify-content-end d-flex">
                          {addItemBtnText && (
                            <div className="d-flex align-items-end">
                              <Button
                                type="button"
                                color={`${addItemBtnColor ? addItemBtnColor : "success"}`}
                                className="btn-rounded waves-effect waves-light me-2"
                                onClick={addItemBtnAction}
                              >
                                <i className={addItemBtnIconClass ? addItemBtnIconClass + " me-1" : "mdi mdi-plus "} />
                                {addItemBtnText}
                              </Button>
                            </div>
                          )}

                          {addItemBtnText2 && (
                            <div className="d-flex align-items-end">
                              <Button
                                type="button"
                                color={`${addItemBtnColor2 ? addItemBtnColor2 : "success"}`}
                                className="btn-rounded waves-effect waves-light me-2"
                                onClick={addItemBtnAction2}
                              >
                                <i className={addItemBtnIconClass2 ? addItemBtnIconClass2 + " me-1" : "mdi mdi-plus "} />
                                {addItemBtnText2}
                              </Button>
                            </div>
                          )}

                          {addItemBtnText3 && (
                            <div className="d-flex align-items-end">
                              <Button
                                type="button"
                                color={`${addItemBtnColor3 ? addItemBtnColor3 : "success"}`}
                                className="btn-rounded waves-effect waves-light me-2"
                                onClick={addItemBtnAction3}
                              >
                                <i className={addItemBtnIconClass3 ? addItemBtnIconClass3 + " me-1" : "mdi mdi-plus "} />
                                {addItemBtnText3}
                              </Button>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="d-flex align-items-center">
                            {getAction || getFunction ? (
                              <i className="me-2 clickable bx bx-revision breadcrumbs-refresh-spinner"
                                onClick={() => handleTableChange("pagination", { currentPage })}
                              />
                            ) : null}
                            {!simple ? <small className="text-muted font-size-15">{"Results -"} {totalSize ? totalSize : 0}</small> : null}
                          </div>
                          {/* <ToolbarMenu /> */}
                          <div className="table-responsive">
                            {selectMultipleOptions ?
                              <BootstrapTable
                                filter={filterFactory()}
                                filterPosition="top"
                                sort={sortOption}
                                defaultSorted={[{
                                  dataField: defaultSort?.sort ? defaultSort?.sort : "created_at",
                                  order: defaultSort?.direction ? defaultSort?.direction : "desc"
                                }]}
                                keyField="id"
                                responsive
                                remote
                                bordered={false}
                                striped={false}
                                classes={
                                  `table align-middle table-nowrap table-check project-list-table ${tableCustomClassName ?? ""}`
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange}
                                {...paginationTableProps}
                                selectRow={{
                                  mode: 'checkbox',
                                  clickToSelect: false,
                                  selected: selected,
                                  onSelect: handleOnSelect,
                                  onSelectAll: handleOnSelectAll
                                }}
                              />
                              :
                              <BootstrapTable
                                filter={filterFactory()}
                                filterPosition="top"
                                sort={sortOption}
                                defaultSorted={[{
                                  dataField: defaultSort?.sort ? defaultSort?.sort : "created_at",
                                  order: defaultSort?.direction ? defaultSort?.direction : "desc"
                                }]}
                                keyField="id"
                                responsive
                                remote
                                bordered={false}
                                striped={false}
                                classes={
                                  `table align-middle table-nowrap table-check project-list-table ${tableCustomClassName ?? ""}`
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange}
                                {...paginationTableProps}
                              />
                            }
                          </div>
                        </Col>
                      </Row>
                      {!simple ? (
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone {...paginationProps} />
                          </Col>
                        </Row>
                      ) : null}
                    </>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </Col>
        </Row>
        :
        <div className="w-100 d-flex justify-content-center align-items-center" style={{ height: 640 }}>
          <Spinner className="ms-2" color="primary" />
        </div>
      }
    </>
  )
}

export default BasicTable


// import React, { useEffect, useState } from "react"
// import BootstrapTable from "react-bootstrap-table-next"
// import paginationFactory, {
//   PaginationListStandalone,
//   PaginationProvider,
// } from "react-bootstrap-table2-paginator"
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// import { useDispatch } from "react-redux"
// import { Button, Col, Row, Spinner } from "reactstrap"
// import Toolbar from "../Toolbar/Toolbar"
// import filterFactory from 'react-bootstrap-table2-filter';
// import "./BasicTable.scss";
// import { useHistory } from "react-router"

// interface IBasicTableProps {
//   columns: any[],
//   items: any[],
//   addItemBtnAction?: any,
//   addItemBtnText?: string,
//   addItemBtnColor?: string;
//   addItemBtnAction2?: any,
//   addItemBtnText2?: string,
//   addItemBtnColor2?: string;
//   searchbar: boolean,
//   getAction?: any,
//   getFunction?: any,
//   totalSize: number,
//   sizePerPage: number,
//   currentPage?: number,
//   displaySelectMultiple?: any,
//   selectMultipleOptions?: any,
//   defaultSort?: {
//     sort: string,
//     direction: string
//   },
//   currentSort?: {
//     sort: string,
//     direction: string
//   },
//   isLoading?: boolean,
//   activeColumns?: any,
//   onSetActiveColumns?: (columnName: string) => void,
//   customFilter?: any;
// }

// const BasicTable = ({
//   columns,
//   items,
//   addItemBtnAction,
//   addItemBtnText,
//   addItemBtnColor,
//   addItemBtnAction2,
//   addItemBtnText2,
//   addItemBtnColor2,
//   searchbar,
//   getAction,
//   getFunction,
//   totalSize,
//   sizePerPage,
//   currentPage,
//   displaySelectMultiple,
//   selectMultipleOptions,
//   defaultSort,
//   isLoading,
//   activeColumns,
//   onSetActiveColumns,
//   customFilter
// }: IBasicTableProps) => {
//   const { SearchBar } = Search
//   const dispatch = useDispatch()
//   const history = useHistory();

//   const [selected, setSelected] = useState([])

//   const handleClearSelected = () => {
//     // console.log(["handleClearSelected"])
//     setSelected([])
//   }

//   const handleOnSelect = (row: any, isSelect: any) => {
//     // console.log(["row", row])
//     if (isSelect) {
//       //@ts-ignore
//       setSelected([...selected, row.id])
//     } else {
//       setSelected(selected.filter(x => x !== row.id))
//     }
//   }

//   const handleOnSelectAll = (isSelect: any, rows: any) => {
//     const ids = rows.map((r: any) => r.id);
//     if (isSelect) {
//       setSelected(ids)
//     } else {
//       setSelected([])
//     }
//   }

//   useEffect(() => {
//     if (getAction && !items?.length) dispatch(getAction({ page: currentPage ? currentPage : 1 }))
//     else if (getFunction){
//       getFunction(currentPage ? currentPage : 1)
//     }
//   }, [])

//   const handleTableChange = (type: any, { page, searchText }:any) => {
//     if (getAction)
//       switch (type) {
//         case "pagination":
//           dispatch(getAction({ page }))
//           break;
//         case "search":
//           dispatch(getAction({ page, searchText }))
//           break;
//       }else if(getFunction){
//         switch(type){
//           case "pagination":
//             getFunction(page)
//             break;
//           case "search":
//             getFunction(page, searchText)
//             break;
//         }
//       }
//   }

//   const sortOption = {
//     sortCaret: (order: string, column: any) => {
//       if(!order) return <span className="sort-available-icon"><i className="mdi mdi-chevron-up"/><i className="mdi mdi-chevron-down"/></span>
//       if (order === 'asc') return <i className="mdi mdi-chevron-up"/>
//       else if (order === 'desc') return <i className="mdi mdi-chevron-down"/>
//       return null;
//     },
//     dataField: defaultSort?.sort,
//     order: defaultSort?.direction,
//   }

//   const getQueryParam = (paramName: string) => {
//     return new URLSearchParams(location?.search).get(paramName)
//   }

//   useEffect(() => {
//     !isLoading && triggerPageQueryString();
//   }, [isLoading])

//   const triggerPageQueryString = () => {
//     //fix no pagination bug on first enter
//     //it forces state change at BasicTable pagination
//     history.replace(`${location.pathname}?page=`);
//     // console.log(currentPage)
//     currentPage && setTimeout(() => {
//       history.replace(`${location.pathname}?page=${currentPage}`);
//     }, 50)
//   }

//   return (
//     <>
//       <Row>
//         <Col xs="12">
//           <PaginationProvider
//             pagination={paginationFactory({
//               sizePerPage: sizePerPage ? sizePerPage : 15,
//               totalSize: totalSize ? totalSize : 0,
//               custom: true,
//               //@ts-ignore
//               page:   getQueryParam('page') ? parseInt(getQueryParam('page')) : currentPage
//             })}
//           >
//             {({ paginationProps, paginationTableProps }: any) => (
//               <ToolkitProvider
//                 keyField="id"
//                 data={items || []}
//                 columns={columns}
//                 bootstrap4
//                 search
//               >
//                 {(toolkitProps: any) => (
//                   <React.Fragment>
//                     <Row className="mb-2">
//                       <Col sm="6">
//                         {searchbar && (
//                             <Row>
//                               <div className="search-box me-2 mb-2 d-inline-block">
//                                 <div className="position-relative">
//                                   <SearchBar {...toolkitProps.searchProps} />
//                                   <i className="bx bx-search-alt search-icon" />
//                                 </div>
//                               </div>
//                             </Row>
//                         )}
//                         {customFilter && customFilter}
//                         {activeColumns && onSetActiveColumns ? (
//                           <div style={{
//                             display: "grid",
//                             gridTemplateColumns: "auto auto auto auto"
//                           }}>
//                             {Object.entries(activeColumns).map((columnEntry: any[]) => {
//                               const columnName = columnEntry[0]
//                               const isColumnActive = columnEntry[1]
//                               return (
//                                 <div className="form-check">
//                                 {/* <span> */}
//                                     <input
//                                         className="form-check-input"
//                                         type="checkbox"
//                                         checked={isColumnActive}
//                                         id={`checkbox-${columnName}`}
//                                         onChange={() => onSetActiveColumns(columnName)}
//                                     />
//                                     <label
//                                         className="form-check-label"
//                                         htmlFor={`checkbox-${columnName}`}
//                                     >
//                                         {columnName}
//                                     </label>
//                                 </div>
//                               )
//                             })}
//                           </div>
//                         ): null}
//                       </Col>
//                       <Col sm="6" className="justify-content-end d-flex">
//                         {addItemBtnText && (
//                           <div className="d-flex align-items-end">
//                             <Button
//                               type="button"
//                               color={`${addItemBtnColor ? addItemBtnColor : "success"}`}
//                               className="btn-rounded waves-effect waves-light me-2"
//                               onClick={addItemBtnAction}
//                             >
//                               <i className="mdi mdi-plus me-1" />
//                               {addItemBtnText}
//                             </Button>
//                           </div>
//                         )}

//                         {addItemBtnText2 && (
//                           <div className="d-flex align-items-end">
//                             <Button
//                               type="button"
//                               color={`${addItemBtnColor2 ? addItemBtnColor2 : "success"}`}
//                               className="btn-rounded waves-effect waves-light me-2"
//                               onClick={addItemBtnAction2}
//                             >
//                               <i className="mdi mdi-plus me-1" />
//                               {addItemBtnText2}
//                             </Button>
//                           </div>
//                         )}
//                       </Col>
//                     </Row>
//                     {!isLoading ? (
//                       <>
//                         <Row>
//                           <Col xl="12">
//                             {selectMultipleOptions &&
//                               <Toolbar
//                                 selectMultipleOptions={selectMultipleOptions}
//                                 selected={selected}
//                                 handleClearSelected={handleClearSelected}
//                               />
//                             }
//                             <div className="d-flex align-items-center mt-2">
//                               {getAction || getFunction ? (
//                                 <i className="me-2 clickable bx bx-revision refresh-spinner"
//                                   onClick={() => handleTableChange("pagination", { currentPage })}
//                                 />
//                               ): null}
//                               <small className="text-muted font-size-15">{"Results -"} {totalSize ? totalSize : 0}</small>
//                             </div>
//                             {/* <ToolbarMenu /> */}
//                             <div className="table-responsive">
//                               {selectMultipleOptions ?
//                                 <BootstrapTable
//                                   filter={filterFactory()}
//                                   filterPosition="top"
//                                   sort={sortOption}
//                                   defaultSorted={[{
//                                     dataField: defaultSort?.sort ? defaultSort?.sort : "created_at",
//                                     order:  defaultSort?.direction ? defaultSort?.direction : "desc"
//                                   }]}
//                                   keyField="id"
//                                   responsive
//                                   remote
//                                   bordered={false}
//                                   striped={false}
//                                   classes={
//                                     "table align-middle table-nowrap table-check project-list-table"
//                                   }
//                                   headerWrapperClasses={"table-light"}
//                                   {...toolkitProps.baseProps}
//                                   onTableChange={handleTableChange}
//                                   {...paginationTableProps}
//                                   selectRow={{
//                                     mode: 'checkbox',
//                                     clickToSelect: true,
//                                     selected: selected,
//                                     onSelect: handleOnSelect,
//                                     onSelectAll: handleOnSelectAll
//                                   }}
//                                 />
//                                 :
//                                 <BootstrapTable
//                                   filter={filterFactory()}
//                                   filterPosition="top"
//                                   sort={sortOption}
//                                   defaultSorted={[{
//                                     dataField: defaultSort?.sort ? defaultSort?.sort : "created_at",
//                                     order:  defaultSort?.direction ? defaultSort?.direction : "desc"
//                                   }]}
//                                   keyField="id"
//                                   responsive
//                                   remote
//                                   bordered={false}
//                                   striped={false}
//                                   classes={
//                                     "table align-middle table-nowrap table-check project-list-table"
//                                   }
//                                   headerWrapperClasses={"table-light"}
//                                   {...toolkitProps.baseProps}
//                                   onTableChange={handleTableChange}
//                                   {...paginationTableProps}
//                                 />
//                               }
//                             </div>
//                           </Col>
//                         </Row>
//                         <Row className="align-items-md-center mt-30">
//                           <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
//                             <PaginationListStandalone {...paginationProps} />
//                           </Col>
//                         </Row>
//                       </>
//                     ) : (
//                       <div className="w-100 h-100 d-flex justify-content-center align-items-center">
//                         <Spinner className="ms-2" color="secondary" />
//                       </div>
//                     )}
//                   </React.Fragment>
//                 )}
//               </ToolkitProvider>
//             )}
//           </PaginationProvider>
//         </Col>
//       </Row>
//     </>
//   )
// }

// export default BasicTable
