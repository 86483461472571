import {
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAIL,
  GET_INVOICES,
} from "./actionTypes"

const INIT_STATE = {
  info: [],
  isLoading: true
}

const Invoices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INVOICES:
      return {
        ...state,
        isLoading: true
      }

    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        info: action?.payload,
        isLoading: false
      }
      break
    case GET_INVOICES_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: true
      }
      break
    default:
      return state
      break
  }
  return state;
}

export default Invoices
