import React from 'react'
import { Card, CardBody } from "reactstrap"
import Visa from '../../../../assets/images/payment/visa.svg'
import Mastercard from '../../../../assets/images/payment/mastercard.svg'
import Amex from '../../../../assets/images/payment/amex.svg'
import Discover from '../../../../assets/images/payment/discover.svg'
import { useDispatch } from 'react-redux'
import { requestConfirmation } from '../../../../store/actions'

interface Props {
    cardInfo: {
        card: any;
    };
    setShowEditPaymentInfo: (bool: boolean) => void;
    handleDisconnectCard: () => void;
    returnTranslation: (text: string) => string;
}

function CreditCardTile({ cardInfo, setShowEditPaymentInfo, handleDisconnectCard, returnTranslation}: Props) {
    const dispatch = useDispatch();

    const disconnectCard = () => {
        dispatch(
            requestConfirmation(`${returnTranslation("Are you sure")}?`, () => handleDisconnectCard())
        )
    }

    return (
        <Card className="card-info-tile">
            <CardBody>
                <h4 className="card-title">Payment info</h4>
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <span> {cardInfo?.card?.brand} ending in </span>
                        <span className="card-info-tile--brand">*{cardInfo?.card?.last4}</span>
                    </div>
                    {/* {cardInfo?.card?.brand?.toLowerCase() === "visa"
                        ? <i className="fab fa-cc-visa"/>
                        : cardInfo?.card?.brand?.toLowerCase() === "mastercard"
                            ? <i className="fab fa-cc-mastercard"/>
                            : cardInfo?.card?.brand?.toLowerCase() === "american express"
                                ? <i className="fab fa-cc-amex"/>
                                : cardInfo?.card?.brand?.toLowerCase() === "discover"
                                    ? <i className="fab fab fa-cc-discover"/>
                                    : <i className="bx bx-credit-card"/>
                    } */}
                    {cardInfo?.card?.brand?.toLowerCase() === "visa"
                        ? <img className="credit-card-img" src={Visa} width={50} height={28} />
                        : cardInfo?.card?.brand?.toLowerCase() === "mastercard"
                            ? <img className="credit-card-img" src={Mastercard} width={50} height={28} />
                            : cardInfo?.card?.brand?.toLowerCase() === "american express"
                                ? <img className="credit-card-img" src={Amex} width={50} height={28} />
                                : cardInfo?.card?.brand?.toLowerCase() === "discover"
                                    ? <img className="credit-card-img" src={Discover} width={50} height={28} />
                                    : <i className="bx bx-credit-card"/>
                    }
                </div>
                <div>{cardInfo?.card?.name}</div>
                <small className="text-muted">Expires {cardInfo?.card?.exp_month?.toString()?.length === 1 ? `0${cardInfo?.card?.exp_month?.toString()}` : cardInfo?.card?.exp_month?.toString()}/{cardInfo?.card?.exp_year}</small>
                <hr className="mt-1 mb-1"/>
                <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-medium auth-agreement-link"
                        onClick={() => setShowEditPaymentInfo(true)}
                    >Edit payment info</span>
                    <span className="fw-medium auth-agreement-link"
                        onClick={disconnectCard}
                    >Disconnect</span>
                </div>
            </CardBody>
        </Card>
    )
}

export default CreditCardTile
