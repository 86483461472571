// @ts-ignore
import { AvForm } from "availity-reactstrap-validation"
import React, { Component } from "react"
import { withTranslation } from "react-i18next"
// @ts-ignore
import MetaTags from 'react-meta-tags'
// @ts-ignore
import { connect } from "react-redux"
// @ts-ignore
import { RouteComponentProps } from "react-router"
// @ts-ignore
import { Link } from "react-router-dom"
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, Modal, ModalBody,
    ModalFooter, ModalHeader, Row
}
    // @ts-ignore
    from "reactstrap"
import RadioCheck from "../../../components/Common/RadioCheck"
import {
    apiError, changePreloader, registerUserFailed,
    registerUserSuccessful
} from "../../../store/actions"
// import ConnectPaypal from "../../CharityConfiguration/PayoutsWithPaypal/ConnectPaypal/ConnectPaypal"
import ChangePlanDuration from "./../../../components/SubscriptionsPlans/ChangePlanDuration/ChangePlanDuration"
import SubscriptionsPlans from "./../../../components/SubscriptionsPlans/SubscriptionsPlans"
import { post } from "./../../../helpers/api_helper"
import * as url from "./../../../helpers/url_helper"
import { openAlert } from "./../../../store/alert/actions"
import Visa from '../../../assets/images/payment/visa.svg'
import Mastercard from '../../../assets/images/payment/mastercard.svg'
import Amex from '../../../assets/images/payment/amex.svg'
import Discover from '../../../assets/images/payment/discover.svg'
import PayPal from '../../../assets/images/payment/paypal.svg'


export interface RegisterPlansPropsInterface extends RouteComponentProps {
    dashbord_id: string,
    registerUserSuccessful: (userObj: any) => {},
    apiError: (err: string) => {},
    history: any,
    location: any,
    error: string,
    onOpenAlert: ({ }) => {},
    registerUserFailed: (status: string) => {},
    t: any,
    onGetCountries: any,
    countries: [{ code: string, name: string }],
    user: any,
    registrationError: string,
    onChangePreloader: (value: boolean) => {}
}

export interface RegisterPlansStateInterface {
    isCharity: boolean,
    countries: any[],
    selectedCountryCode: string,
    formErrors: any,
    billAnnulally: boolean,
    planType: string,
    paymentMethod: string | number,
    isOpenPaymentModal: boolean
}

class RegisterPlans extends Component<RegisterPlansPropsInterface, RegisterPlansStateInterface> {
    constructor(props: RegisterPlansPropsInterface) {
        super(props)
        this.state = {
            isCharity: false,
            countries: [],
            selectedCountryCode: "",
            formErrors: {},
            billAnnulally: false,
            planType: "",
            paymentMethod: "card",
            isOpenPaymentModal: false,
        }
    }

    async componentDidMount() {
        await this.props.apiError("")
        await this.props.registerUserFailed("")
        await this.props.onGetCountries()

        window.addEventListener("message", this.messageHandler)
        return () => {
            window.removeEventListener("message", this.messageHandler)
        }
    }

    setIsOpenPaymentModal = (status: boolean) => {
        this.setState({ isOpenPaymentModal: status })
    }

    handleLoginUser = async (email: string, password: string) => {
        return new Promise(async (resolve, reject) => {
            //   this.props.changePreloader(true)
            post(`${url.API_BASE}login`, { email, password })
                .then(response => {
                    if (response?.user && response?.access_token) {
                        let userObj = response?.user;
                        userObj.access_token = response?.access_token;
                        this.props.registerUserSuccessful(userObj)
                        localStorage.setItem("authUser", JSON.stringify(userObj))
                        this.props.history.push("/dashboard")
                    }

                    //   this.props.changePreloader(false)
                    return resolve(response);
                })
                .catch(error => {
                    if (error && error.response && error.response.data && error.response.data.message) {
                        this.props.apiError(error.response.data.message)
                    }
                    //   this.props.changePreloader(false)
                    return reject(error);
                });

        });
    }

    messageHandler = async (event: any) => {
        // console.log(["messageHandler", event])
        if(!event?.data?.source?.includes("devtools")){

        // if (event?.data?.error?.message) {
        // 	onOpenAlert("error", event?.data?.error?.message)
        // 	// //@ts-ignore
        // 	// message.error({
        // 	// 	content: event.data.error.message,
        // 	// })
        // }

        // if (event.data.status === "success") {
        // 	setLoaded(false)
        // 	getPaypalInfo()
        // 	onOpenAlert("success", returnTranslation("Payment method succesfuly connected"))
        // }

            this.handleLoginUser(this.props.location?.state?.email, this.props.location?.state?.password)
        }
    }


    handleConnectPaypal = async (url: string) => {
        if (!url) {
            return
        }

        const newwindow = window.open("", "connect", "height=500,width=350")
        //@ts-ignore
        newwindow.location.href = url
        //@ts-ignore
        if (window.focus) {
            // newwindow.focus();
            //@ts-ignore
            newwindow.blur()
            //@ts-ignore
            setTimeout(newwindow.focus, 0)
        }
        return false
    }

    handleSelectPlan = async (event: any, values: any) => {
        event.preventDefault();
        if(this.props.location?.state?.role === "CHARITY" || this.props.location?.state?.role === "SCHOOL"){
            this.handleSubmitPaymentMethod()
        } else {
            this.setIsOpenPaymentModal(true);
        }
    }

    handleSubmitPaymentMethod = async () => {
        // this.setIsOpenPaymentModal(false);
        return new Promise(async (resolve, reject) => {
            const { paymentMethod } = this.state
            
            let paymentPayload: any = {};
            if(this.props.location?.state?.role !== "CHARITY"){
                //temporary - check if role is school for free plan
                if(paymentMethod === "setup_payments_later" || this.props.location?.state?.role === "SCHOOL") {
                    paymentPayload = { required_card: 0, plan_type: "STRIPE" };
                } else if(paymentMethod === "card"){
                    paymentPayload = { required_card: 1, plan_type: "STRIPE" };
                } else if(paymentMethod === "paypal"){
                    paymentPayload = { required_card: 0, plan_type: "PAYPAL" };
                }
            }

            this.props.onChangePreloader(true)

            // console.log({
            //     plan: this.state.planType,
            //     interval: this.state.billAnnulally ? "year" : "month",
            //     code: this.props.location?.state?.code,
            //     ...paymentPayload
            // })

            post(`${url.API_BASE}dashboard/register/7`, {
                plan: this.state.planType,
                interval: this.state.billAnnulally ? "year" : "month",
                code: this.props.location?.state?.code,
                ...paymentPayload
            })
                .then(response => {
                    if (response?.status) {
                        // TODO maybe change this to not only check RETAILER bcs all roles can have paid plans
                        if (this.props.location?.state?.role === "RETAILER"){
                            //if url returned then open iframe and redirect to login
                            if (response?.url) {
                                return this.handleConnectPaypal(response?.url)
                            } else if(paymentPayload?.required_card) {
                                return this.props.history.push("/register-card", {
                                    code: this.props.location?.state?.code,
                                    role: this.props.location?.state?.role,
                                    email: this.props.location?.state?.email,
                                    password: this.props.location?.state?.password,
                                    // password_confirmation: this.props.location?.state?.password_confirmation,
                                    plan: this.state.planType,
                                    interval: this.state.billAnnulally ? "year" : "month",
                                })
                            }
                        }

                        this.props.onOpenAlert({
                            text: response?.message,
                            status: "success",
                        })

                        if(this.props.location?.state?.password){
                            this.handleLoginUser(this.props.location?.state?.email, this.props.location?.state?.password)
                        }else{
                            this.props.history.push("/login", { email: this.props.location?.state?.email })
                        }

                        // if (this.props.location?.state?.role === "CHARITY" || this.props.location?.state?.role === "SCHOOL") {
                        //     // this.props.history.push("/login")
                        //     if(this.props.location?.state?.password){
                        //         this.handleLoginUser(this.props.location?.state?.email, this.props.location?.state?.password)
                        //     }else{
                        //         this.props.history.push("/login")
                        //     }
                        // } else {
                        //     //if url returned then open iframe and redirect to login
                        //     if (response?.url) {
                        //         this.handleConnectPaypal(response?.url)
                        //     } else if(paymentPayload?.required_card) {
                        //         this.props.history.push("/register-card", {
                        //             code: this.props.location?.state?.code,
                        //             type: this.props.location?.state?.role,
                        //             email: this.props.location?.state?.email,
                        //             password: this.props.location?.state?.password,
                        //             // password_confirmation: this.props.location?.state?.password_confirmation,
                        //             plan: this.state.planType,
                        //             interval: this.state.billAnnulally ? "year" : "month",
                        //         })
                        //     } else {
                        //         this.handleLoginUser(this.props.location?.state?.email, this.props.location?.state?.password);
                        //     }
                        // }
                    }
                    this.props.onChangePreloader(false)
                    return resolve(response)
                })
                .catch(error => {
                    if (error?.response?.data) {
                        const { message, errors } = error?.response?.data;
                        this.setState({ formErrors: errors });
                        this.props.onOpenAlert({
                            text: message,
                            status: "danger",
                        })
                    }
                    return reject(error)
                }).finally(() => this.props.onChangePreloader(false))
        })
    }

    handleChangeCountryCode = (event: any) => {
        this.setState({ selectedCountryCode: event.target.value })
    }

    handleSwitchChange = () => {
        this.setState({ billAnnulally: !this.state.billAnnulally })
    }

    handleSetPlan = (value: string) => {
        this.setState({ planType: value })
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>LOOP | Register Plans</title>
                </MetaTags>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-dark">
                        <i className="bx bx-home h2" />
                    </Link>
                </div>
                <div className="account-pages my-2 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={12} lg={12} xl={12}>
                                <Card className="overflow-hidden">
                                    <CardHeader>
                                        <i className="fas fa-chevron-left font-size-20 text-black clickable pt-1 ps-1"
                                            onClick={() => this?.props?.history?.push("/register-socials", this?.props?.location?.state)}
                                        />
                                    </CardHeader>
                                
                                    {/* <div className="p-4 d-flex align-items-center gap-3">
                                        <h1 className="m-0">{t("Social Media")}</h1>
                                    </div> */}
                                    <ChangePlanDuration
                                        type={this.props.location?.state?.role}
                                        billAnnulally={this.state.billAnnulally}
                                        handleSwitchChange={this.handleSwitchChange}
                                    />
                                    <CardBody className="pt-0">
                                        <div className="p-2">
                                            <AvForm
                                                className="form-horizontal"
                                                onValidSubmit={this.handleSelectPlan}
                                            >
                                                {this.props.user && this.props.user ? (
                                                    <Alert color="success">
                                                        Register User Successfully
                                                    </Alert>
                                                ) : null}
                                                {this.props.registrationError &&
                                                    this.props.registrationError ? (
                                                    <Alert color="danger">
                                                        {this.props.registrationError}
                                                    </Alert>
                                                ) : null}

                                                <SubscriptionsPlans
                                                    type={this.props.location?.state?.role}
                                                    handleSetPlan={this.handleSetPlan}
                                                    displayTrialText={false}
                                                />

                                                <div className="mt-3 text-center">
                                                    <p>
                                                        <span className="auth-agreement-text">Already have an account?</span>{" "}
                                                        <Link to="/login" className="fw-medium auth-agreement-link">
                                                            {" "}
                                                            Log in
                                                        </Link>{" "}
                                                    </p>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <p className="mb-0 auth-agreement-text">
                                                        By continuing you agree to our <a href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/" target="_blank" className="text-primary">
                                                            Terms & Conditions
                                                        </a>. LOOP services are
                                                        subject to our <a href="https://loopdigitalwardrobe.com/privacy-policy/" target="_blank" className="text-primary">
                                                            Privacy Policy
                                                        </a>.
                                                    </p>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <p className="mb-0 auth-agreement-text">
                                                        Need our help? <a href="mailto:help@loopdigitalwardrobe.com" className="fw-medium contact-us-text">
                                                            Contact us.
                                                        </a>
                                                    </p>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="mt-5 text-center">
                                    <p className="auth-agreement-text">
                                        ©{new Date().getFullYear()} All Rights Reserved. LOOP DIGITAL WARDROBE® is a registered trademark of LOOP DIGITAL WARDROBE®.{" "}
                                        <a href="https://loopdigitalwardrobe.com/privacy-policy/" target="_blank" className="fw-medium text-primary">
                                            Privacy Policy
                                        </a> and{" "}
                                        <a href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/" target="_blank" className="fw-medium text-primary">
                                            Terms & Conditions
                                        </a>
                                        .
                                    </p>
                                </div>
                            </Col>
                        </Row>

                        <Modal
                            isOpen={this.state.isOpenPaymentModal}
                            toggle={() => this.setIsOpenPaymentModal(!this.state.isOpenPaymentModal)}
                            className={"prices-alerts--modal-dialog"}
                            centered={true}
                        >
                            <ModalHeader toggle={() => this.setIsOpenPaymentModal(!this.state.isOpenPaymentModal)}>
                                <span className="card-title">Choose payment method</span>
                            </ModalHeader>
                            <ModalBody className="p-3">
                                <RadioCheck 
                                    label="Credit Card"
                                    id="flexRadioDefault1"
                                    radioGroupName="flexRadioDefault"
                                    // labelIconElement={<i className="bx bx-credit-card"/>}
                                    labelIconElement={<div className="d-flex gap-1">
                                        <img className="credit-card-img" src={Visa} width={50} height={28} />
                                        <img className="credit-card-img" src={Mastercard} width={50} height={28} />
                                        <img className="credit-card-img" src={Amex} width={50} height={28} />
                                        <img className="credit-card-img" src={Discover} width={50} height={28} />
                                    </div>}
                                    defaultChecked={true}
                                    className={"mb-1"}
                                    inputClassName={"mt-8px"}
                                    value={"card"}
                                    onChange={(v) => this.setState({ paymentMethod: v })}
                                />
                                <RadioCheck 
                                    label="PayPal"
                                    id="flexRadioDefault2"
                                    radioGroupName="flexRadioDefault"
                                    // labelIconElement={<i className="bx bxl-paypal"/>}
                                    labelIconElement={<div style={{padding: "2px"}} className="credit-card-img">
                                        <img  src={PayPal} width={46} height={24} />
                                    </div>}
                                    value={"paypal"}
                                    className={"mb-1"}
                                    inputClassName={"mt-8px"}
                                    onChange={(v) => this.setState({ paymentMethod: v })}
                                />
                                <RadioCheck 
                                    label="Setup payments later"
                                    id="flexRadioDefault3"
                                    radioGroupName="flexRadioDefault"
                                    descriptionElement={<small>{"You can setup your payments later in "}<b>{"Settings->Payment Method"}</b>{" bookmark"}</small>}
                                    value={"setup_payments_later"}
                                    onChange={(v) => this.setState({ paymentMethod: v })}
                                    className={"mb-1"}
                                />
                            </ModalBody>
                            <ModalFooter className="pt-1 pb-1">
                                <button
                                    className="plan-row-bottom__btn btn btn-block waves-effect waves-light w-25 m-0"
                                    type="button"
                                    onClick={this.handleSubmitPaymentMethod}
                                >
                                    {"Next"}
                                </button>
                            </ModalFooter>
                        </Modal>
                    </Container>
                </div >
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state: any) => {
    const { user, registrationError, loading, dashbord_id } = state.Account
    const { countries } = state.Countries
    return { user, registrationError, loading, dashbord_id, countries }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        apiError: (message: string) => dispatch(apiError(message)),
        registerUserFailed: (message: string) => dispatch(registerUserFailed(message)),
        registerUserSuccessful: (user: any) => dispatch(registerUserSuccessful(user)),
        onGetCountries: () => dispatch({ type: "GET_COUNTRIES" }),
        onOpenAlert: ({ text, status }: any) =>
            dispatch(openAlert({ text: text, status: status })),
        onChangePreloader: (value: boolean) => dispatch(changePreloader(value))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(RegisterPlans))
