import BasicTable from "./../../../components/BasicTable/BasicTable"
import Breadcrumbs from "./../../../components/Common/Breadcrumb"
import { deleteWebsiteApi } from "./../../../helpers/backend_helper"
import React, { useEffect, useState } from "react"
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
// @ts-ignore
import { RouteComponentProps, withRouter } from "react-router-dom"
// @ts-ignore
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row, Container, UncontrolledTooltip } from "reactstrap"
import { getWebsites, openAlert, requestConfirmation, getPaymentInfo, getBillings, changePreloader, getInvoices, editProfile } from "./../../../store/actions"
// import WebsitesTableColumns from "./WebsitesTableColumns/WebsitesTableColumns"
import SubscriptionsPlans from "./../../../components/SubscriptionsPlans/SubscriptionsPlans"
import ChangePlanDuration from "./../../../components/SubscriptionsPlans/ChangePlanDuration/ChangePlanDuration"
import { put, post } from "./../../../helpers/api_helper"
import * as url from "./../../../helpers/url_helper"
// @ts-ignore
import { AvField, AvForm } from "availity-reactstrap-validation"
import ReactFlagsSelect from 'react-flags-select';

interface ChangeBillingOwnerInterface extends RouteComponentProps {
    history: any,
    returnTranslation: (name: string) => string
}

const ChangeBillingOwner = ({ history, returnTranslation }: ChangeBillingOwnerInterface) => {
    const dispatch = useDispatch()
    const user = useSelector((state: any) => state.Account.user)
    const billingsInfo = useSelector((state: any) => state.Billings.info)

    const [formErrors, setFormErrors] = useState('')

    useEffect(() => {
        dispatch(getPaymentInfo())
        dispatch(getBillings())
        dispatch(getInvoices())
    }, [])


    const handleValidSubmit = async (event: any, values: any) => {
        event.preventDefault()

        return new Promise(async (resolve, reject) => {
            const {
                first_name,
                last_name
            } = values;

            dispatch(changePreloader(true))

            put(`${url.API_BASE}users/me`, {
                first_name,
                last_name
            })
                .then(response => {
                    if (response?.status) {
                        dispatch(openAlert({ text: response?.status, status: "success" }))
                        history.push("/billings")
                    }

                    dispatch(changePreloader(false))
                    return resolve(response)
                })
                .catch(error => {
                    dispatch(changePreloader(false))
                    if (error?.response?.data) {
                        const { message, errors } = error?.response?.data;
                        setFormErrors(errors)
                        dispatch(openAlert({ text: message, status: "danger" }))
                    }
                    return reject(error)
                })
        })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>LOOP | Change billing owner</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs
                        title={returnTranslation("Billings")}
                        breadcrumbItem={returnTranslation("Change billing owner")}
                    />
                    <Row>
                        <Col sm={12} md={6} lg={6} xl={6}>
                            <Card className="overflow-hidden">
                                <CardBody className="pt-0">
                                    <div className="p-2">
                                        <AvForm
                                            className="form-horizontal"
                                            onValidSubmit={handleValidSubmit}
                                        >
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="first_name"
                                                            label="First Name"
                                                            className="form-control"
                                                            placeholder="Enter first name"
                                                            type="text"
                                                            defaultValue={user?.first_name ? user?.first_name : ""}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="last_name"
                                                            label="Last Name"
                                                            className="form-control"
                                                            placeholder="Enter last name"
                                                            type="text"
                                                            defaultValue={user?.last_name ? user?.last_name : ""}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-4 d-grid">
                                                <button
                                                    className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
                                                    type="submit"
                                                >
                                                    {returnTranslation("Submit")}
                                                </button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(ChangeBillingOwner)
