import React, { useEffect, useState } from "react"
// @ts-ignore
import { useDispatch } from "react-redux"
import {
    Button,
    Label,
    Row,
    Col,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Form,
    FormGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap"
import FormInput from "./../../../components/Common/FormInput"

interface ViewSettingsInterface {
    returnTranslation: (name: string) => string,
    setActiveStep: any,
    formErrors: any,
    selectedWidgetStyle: string,
    setSelectedWidgetStyle: any,
    setCustomizeModalIsOpen: any,
    customizeModalIsOpen: boolean,
    toggleCustomizeModal: any,
    handleChangePluginPosition: any,
    pluginPositionOptions: any,
    selectedPluginPositionOption: any,
    pluginYOffset: any,
    setPluginYOffset: any,
    pluginXOffset: any,
    setPluginXOffset: any,
    pluginZIndex: any,
    setPluginZIndex: any,
    handleChangePluginDirection: any,
    pluginDirectionOptions: any,
    selectedPluginDirectionOption: any,
    handleSubmit: any
}

const ViewSettings = ({
    returnTranslation,
    setActiveStep,
    formErrors,
    selectedWidgetStyle,
    setSelectedWidgetStyle,
    setCustomizeModalIsOpen,
    customizeModalIsOpen,
    toggleCustomizeModal,
    handleChangePluginPosition,
    pluginPositionOptions,
    selectedPluginPositionOption,
    pluginYOffset,
    setPluginYOffset,
    pluginXOffset,
    setPluginXOffset,
    pluginZIndex,
    setPluginZIndex,
    handleChangePluginDirection,
    pluginDirectionOptions,
    selectedPluginDirectionOption,
    handleSubmit
}: ViewSettingsInterface) => {
    const dispatch = useDispatch()

    return (
        <>
            <Row>
                <Col xl="12" sm="12">
                    <div className="mb-1" style={{ display: "flex" }}>
                        <div style={{ width: "58%", marginRight: "2%" }}>
                            <h5>Pop up window</h5>
                            <p>Get a default view of the widget embedded into your after-purchase page as a pop-up window. <br />Now your customers can automatically upload newly purchased items directly into their digital wardrobes.</p>
                        </div>

                        <Label className="card-radio-label mb-2" style={{ width: "40%" }}>
                            <Input
                                type="radio"
                                name="fullwidth"
                                id="fullwidth"
                                className="card-radio-input"
                                defaultChecked={selectedWidgetStyle === "fullwidth" && true}
                            />

                            <div className="card-radio" onClick={() => {
                                setSelectedWidgetStyle("fullwidth")
                            }}>
                                <div>
                                    <img style={{ width: "100%" }} src="/assets/images/fullwidthPreview.png" />
                                    {/* <i className="mdi mdi-ethereum font-size-24 text-primary align-middle me-2" />{" "} */}
                                    {/* <span>Prestashop</span> */}
                                </div>
                            </div>
                        </Label>
                    </div>
                </Col>

                <Col xl="12" sm="12">
                    <div className="mb-3" style={{ display: "flex" }}>
                        <div style={{ width: "58%", marginRight: "2%" }}>
                            <h5>Inline widget</h5>
                            <p>Create a custom view widget for your after-purchase page. The plugin is loaded in the place indicated in the website code. <br />Now your customers can automatically upload newly purchased items directly into their digital wardrobes.</p>

                            {selectedWidgetStyle !== "fullwidth" &&
                                <Button onClick={() => setCustomizeModalIsOpen(true)} color="success">
                                    {/* {params?.uuid ? returnTranslation("Update") : returnTranslation("Add website")} */}
                                    {returnTranslation("Customize")}
                                </Button>
                            }
                        </div>

                        <Modal
                            isOpen={customizeModalIsOpen}
                            role="dialog"
                            autoFocus={true}
                            centered={true}
                            className="exampleModal"
                            //@ts-ignore
                            tabIndex="-1"
                            toggle={toggleCustomizeModal}
                        >
                            <div className="modal-content">
                                <ModalHeader>{returnTranslation("Customize")}</ModalHeader>
                                <ModalBody>
                                    <>
                                        <div className="form-group">
                                            <FormGroup className="mt-3 mb-0">
                                                <Label>{returnTranslation("Plugin Position")}</Label>
                                                <select
                                                    className="form-control select2-search-disable"
                                                    onChange={handleChangePluginPosition}
                                                >
                                                    {pluginPositionOptions?.map((position: any, i: number) => {
                                                        if (selectedPluginPositionOption == position) {
                                                            return (
                                                                // @ts-ignore
                                                                <option key={position} value={position} selected>
                                                                    {position}
                                                                </option>
                                                            )
                                                        } else {
                                                            return (
                                                                // @ts-ignore
                                                                <option key={position} value={position}>
                                                                    {position}
                                                                </option>
                                                            )
                                                        }
                                                    })}
                                                </select>
                                            </FormGroup>
                                        </div>
                                        <div className="form-group">
                                            {/* @ts-ignore */}
                                            <FormInput disabled={false}
                                                label={returnTranslation("Plugin position Vertical(yOffset)")}
                                                type="text"
                                                name="yOffset"
                                                className="form-control"
                                                placeholder={returnTranslation("Enter value")}
                                                value={pluginYOffset}
                                                showErrors
                                                formErrors={formErrors}
                                                onChange={(e: any) => setPluginYOffset(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            {/* @ts-ignore */}
                                            <FormInput disabled={false}
                                                label={returnTranslation("Plugin position Horizontal(xOffset)")}
                                                type="text"
                                                name="xOffset"
                                                className="form-control"
                                                placeholder={returnTranslation("Enter value")}
                                                value={pluginXOffset}
                                                showErrors
                                                formErrors={formErrors}
                                                onChange={(e: any) => setPluginXOffset(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            {/* @ts-ignore */}
                                            <FormInput disabled={false}
                                                label={returnTranslation("Plugin position (zIndex)")}
                                                type="number"
                                                name="zIndex"
                                                className="form-control"
                                                placeholder={returnTranslation("Enter value")}
                                                value={pluginZIndex}
                                                showErrors
                                                formErrors={formErrors}
                                                onChange={(e: any) => setPluginZIndex(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <FormGroup className="mt-3 mb-0">
                                                <Label>{returnTranslation("Plugin Direction")}</Label>
                                                <select
                                                    className="form-control select2-search-disable"
                                                    onChange={handleChangePluginDirection}
                                                >
                                                    {pluginDirectionOptions?.map((direction: any, i: number) => {
                                                        if (selectedPluginDirectionOption == direction) {
                                                            return (
                                                                // @ts-ignore
                                                                <option key={direction} value={direction} selected>
                                                                    {direction}
                                                                </option>
                                                            )
                                                        } else {
                                                            return (
                                                                // @ts-ignore
                                                                <option key={direction} value={direction}>
                                                                    {direction}
                                                                </option>
                                                            )
                                                        }
                                                    })}
                                                </select>
                                            </FormGroup>
                                        </div>
                                    </>
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={(e) => {
                                        setCustomizeModalIsOpen(false)
                                        handleSubmit(e, true)
                                    }} color="success">
                                        {/* {params?.uuid ? returnTranslation("Update") : returnTranslation("Add website")} */}
                                        {returnTranslation("Submit")}
                                    </Button>
                                </ModalFooter>
                            </div>
                        </Modal>

                        <Label className="card-radio-label mb-2" style={{ width: "40%" }}>
                            <Input
                                type="radio"
                                name="fullwidth"
                                id="fullwidth"
                                className="card-radio-input"
                            />

                            <div className="card-radio" onClick={() => {
                                setSelectedWidgetStyle("inline")
                            }}>
                                <div>
                                    <img style={{ width: "100%" }} src="/assets/images/inlinePreview.png" />
                                    {/* <i className="mdi mdi-ethereum font-size-24 text-primary align-middle me-2" />{" "} */}
                                    {/* <span>Prestashop</span> */}
                                </div>
                            </div>
                        </Label>
                    </div>
                </Col>
            </Row>

            <div style={{
                display: "flex",
                justifyContent: "center",

            }}>
                <div className="text-center mt-4" style={{ marginRight: "5px" }}>
                    <Button onClick={() => setActiveStep("Verify your domain")} color="light">
                        {/* {params?.uuid ? returnTranslation("Update") : returnTranslation("Add website")} */}
                        {returnTranslation("Back")}
                    </Button>
                </div>
                <div className="text-center mt-4" style={{ marginLeft: "5px" }}>
                    <Button onClick={(e) => {
                        handleSubmit(e, true)
                        setActiveStep("Complete set-up")
                    }} color="success">
                        {/* {params?.uuid ? returnTranslation("Update") : returnTranslation("Add website")} */}
                        {returnTranslation("Submit")}
                    </Button>
                </div>
            </div>
        </>
    )
}

export default ViewSettings
