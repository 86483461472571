import React, { Component } from "react"
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap"

class EmailToolbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            folder_Menu: false,
            tag_Menu: false,
            more_Menu: false,
        }
        this.toggleFolder = this.toggleFolder.bind(this)
        this.toggleTag = this.toggleTag.bind(this)
        this.toggleMore = this.toggleMore.bind(this)
    }

    //Toggle Folder Menus
    toggleFolder() {
        this.setState(prevState => ({
            folder_Menu: !prevState.folder_Menu,
        }))
    }

    //Toggle Tag Menus
    toggleTag() {
        this.setState(prevState => ({
            tag_Menu: !prevState.tag_Menu,
        }))
    }

    //Toggle More Menus
    toggleMore() {
        this.setState(prevState => ({
            more_Menu: !prevState.more_Menu,
        }))
    }

    handleRunActionOnSelected = (action, requestConfirmation) => {
        const { selected } = this.props;

        if (selected && action) {
            if (requestConfirmation) {
                requestConfirmation(() => this.runActionOnSelected(action))
            } else {
                this.runActionOnSelected(action)
            }
        }
    }

    runActionOnSelected = (action) => {
        const { selected } = this.props;

        let selectedIndex = 0;

        selected.forEach((selectedID, index) => {
            // console.log(["selectedIndex", selectedIndex, index])
            action(selectedID)
            if (selectedIndex === index) {
                this.props.handleClearSelected();
            }
            selectedIndex++;
        });
    }

    render() {
        const { selectMultipleOptions } = this.props
        return (
            <>
                <Dropdown
                    isOpen={this.state.more_Menu}
                    toggle={this.toggleMore}
                    className="btn-no-shadow btn-group me-2 mb-sm-0"
                >
                    <DropdownToggle
                        className="btn-no-shadow btn btn-primary waves-light waves-effect dropdown-toggle"
                        tag="div"
                    >
                        Action <i className="mdi mdi-dots-vertical ms-2" />
                    </DropdownToggle>
                    <DropdownMenu>
                        {selectMultipleOptions?.map((selectOption, i) => {
                            return (
                                <DropdownItem onClick={() => this.handleRunActionOnSelected(selectOption?.action, selectOption?.requestConfirmation)}>{selectOption?.name}</DropdownItem>
                            )
                        })}
                    </DropdownMenu>
                </Dropdown>
            </>
        )
    }
}

export default EmailToolbar