import React from "react"
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Col,
  Container, Row
} from "reactstrap"
import BasicTable from "../../../components/BasicTable/BasicTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { toggleTakeBackCarrier } from "../../../helpers/backend_helper"
import { getTakeBackCarriers, openAlert } from "../../../store/actions"
import "./Carriers.scss"
import CarriersTableColumns from "./CarriersTableColumns"

interface Props {
  history: any,
  returnTranslation: (name: string) => string
}

const Carriers = ({ history, returnTranslation }: Props) => {
  const dispatch = useDispatch()
  const data = useSelector((state: any) => state.TakeBacks.carriers)
  const params: { takeback_id: string } = useParams();

  const handleEdit = (id: string) => {
    history.push(`/drop-offs-near-you/${params?.takeback_id}/carriers/${id}`)
  }

  const handleToggleTakeBackCarrier = async (id: string, isActive: boolean, isConfigured: boolean) => {
    if(!isConfigured && !isActive) {
      return dispatch(openAlert({ text: returnTranslation("Please, check Your configuration first."), status: "danger" }))
    }
    
    try {
      // await activeCoupon({ id, active, retailer_id: retailerId })
      const response = await toggleTakeBackCarrier(params?.takeback_id, id)
      if (!isActive) {
        dispatch(openAlert({ text: returnTranslation("Carrier activated"), status: "success" }))
      } else {
        dispatch(openAlert({ text: returnTranslation("Carrier deactivated"), status: "success" }))
      }
    } catch (e) {
      dispatch(openAlert({ text: returnTranslation("An error occured"), status: "danger" }))
    }finally{
      dispatch(getTakeBackCarriers({id: params?.takeback_id, page: data?.info?.current_page }))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content carriers">
        <Container fluid>
          <Breadcrumbs title={returnTranslation("Take Back")} breadcrumbItem={returnTranslation("Carriers")} />

          <Row>
            <Col lg="12">
              {/* <h4 className="card-title">{returnTranslation("Carriers List")}</h4> */}
              <BasicTable
                items={data?.items}
                columns={CarriersTableColumns({ returnTranslation, handleEdit, handleToggleTakeBackCarrier })}
                searchbar={false}
                getAction={({ page }) => getTakeBackCarriers({id: params?.takeback_id, page })}
                totalSize={data?.info?.total_items}
                sizePerPage={data?.info?.page_count}
                currentPage={data?.info?.current_page}
                isLoading={data?.isLoading}
                // displaySelectMultiple={true}
                // selectMultipleOptions={[
                //   {
                //     'name': 'Set Active',
                //     action: (id: string) => handleSetItemActive(id)
                //   },
                //   {
                //     'name': 'Set Inactive',
                //     action: (id: string) => handleSetItemInactive(id)
                //   }
                // ]}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Carriers
