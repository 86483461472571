import React, { Component, Fragment } from "react"
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Breadcrumbs from "../../components/Common/Breadcrumb"

class CharityPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            inputFields: [{ name: "", file: "" }],
        }
        this.startDateChange.bind(this)
        this.endDateChange.bind(this)
        this.handleAddFields.bind(this)
        this.handleRemoveFields.bind(this)
    }

    startDateChange = date => {
        this.setState({
            startDate: date,
        })
    }

    endDateChange = date => {
        this.setState({
            endDate: date,
        })
    }

    // Function for Create Input Fields
    handleAddFields = () => {
        const values = this.state.inputFields
        values.push({ name: "", file: "" })
        this.setState({ inputFields: values })
    }

    // Function for Remove Input Fields
    handleRemoveFields = index => {
        const values = this.state.inputFields
        values.splice(index, 1)
        this.setState({ inputFields: values })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Charity" breadcrumbItem="Edit Page" />

                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Edit Page</CardTitle>
                                        <form className="outer-repeater">
                                            <div data-repeater-list="outer-group" className="outer">
                                                <div data-repeater-item className="outer">
                                                    {/* <FormGroup className="mb-4" row>
                                                        <Label
                                                            htmlFor="taskname"
                                                            className="col-form-label col-lg-2"
                                                        >
                                                            Task Name
                                                        </Label>
                                                        <Col lg="10">
                                                            <Input
                                                                id="taskname"
                                                                name="taskname"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Task Name..."
                                                            />
                                                        </Col>
                                                    </FormGroup> */}
                                                    <FormGroup className="mb-4" row>
                                                        <Label className="col-form-label col-lg-2">
                                                            Page view
                                                        </Label>
                                                        <Col lg="10">
                                                            <Editor
                                                                toolbarClassName="toolbarClassName"
                                                                wrapperClassName="wrapperClassName"
                                                                editorClassName="editorClassName"
                                                                placeholder="Place Your Content Here..."
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </form>
                                        <Row className="justify-content-end">
                                            <Col lg="10">
                                                <Button type="submit" color="primary">
                                                    Update Charity Page
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default CharityPage
