// @ts-ignore
import axios from "axios";
import React, { FormEvent, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
// @ts-ignore
import MetaTags from 'react-meta-tags';
// @ts-ignore
import { connect, useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { RouteComponentProps, useHistory } from "react-router";
// @ts-ignore
import { withRouter } from "react-router-dom";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, Label, Modal, ModalBody, ModalHeader, Row
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ContactUsAlert from "../../../components/Common/ContactUsAlert";
import ImageCropper from "../../../components/ImageCropper/ImageCropper";
import { getDetailsConfig, updateDetailsConfig, updateDetailsWebsite } from "../../../helpers/backend_helper";
import * as url from "../../../helpers/url_helper";
import { apiError, checkAccount, loginSuccess, profileSuccess, registerUserSuccessful } from "../../../store/actions";
import { openAlert } from "../../../store/alert/actions";
import Picker, { EmojiClickData, EmojiStyle } from "emoji-picker-react";
// import SKIN_TONE_MEDIUM_DARK from "emoji-picker-react";
import emojiIcon from '../../../assets/images/emoji-face.svg';
import SingleConfigurationOption from "../../Dashboard/WelcomePanels/SingleConfigurationOption/SingleConfigurationOption";

const formErrorSvg = "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/%3e%3c/svg%3e" 

export interface Props extends RouteComponentProps {
	dashbord_id: string,
	apiError: (err: string) => {},
	error: string,
	user: any,
	onOpenAlert: any,
	returnTranslation: any
}

const Details = ({ returnTranslation, onOpenAlert, user }: Props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const dashboardID = useSelector((state: any) => state?.Account?.dashbord_id)
	const userToken = useSelector((state: any) => state?.Account?.user?.access_token)

	const APP_PROFILE = `APP_PROFILE`
	const accountInfo = useSelector((state: any) => state.Account?.info);
	// const user = useSelector((state: any) => state.Account?.user?.background);
	const appProfileEntitlement = useSelector((state: any) => state.Account?.info?.configuration?.entitlements?.[APP_PROFILE]);
	const details = useSelector((state: any) => state.Account?.info?.configuration?.details)
	// const hasShops = useSelector((state: any) => state.Account?.info?.points)

	const [config, setConfig] = useState<any>(null)
	const [aboutUs, setAboutUs] = useState("")
	const [logo, setLogo] = useState<any>({})
	const [background, setBackground] = useState<any>({})
	const [shortName, setShortName] = useState("");
	const [username, setUsername] = useState("")
	const [websiteUrl, setWebsiteUrl] = useState("")
	const [formErrors, setFormErrors] = useState<any>({})
	const [filesToResize, setFilesToResize] = useState<any>([]);
	const [isFormDisabled, setIsFormDisabled] = useState(false);

	const [profileBio, setProfileBio] = useState("");
	const [isEmojiOpen, setIsEmojiOpen] = useState(false);

	const onCheckAccount = () => dispatch(checkAccount());

	const VIRTUAL_SHOP = `VIRTUAL_${user?.role?.toUpperCase()}`
	const TAKE_BACKS = "TAKE_BACKS"
	const APP_PROFIE = "APP_PROFIE"
	const SEND_BY_POST = "SEND_BY_POST"

	useEffect(() => {
		onCheckAccount();
		handleGetDetailsConfig()
		// console.log(history)
	}, [])

	useEffect(() => {
		setBackground({ url: user?.background })
	}, [user])

	const handleGetDetailsConfig = async () => {
		try {
			const response = await getDetailsConfig(user?.role);
			const {
				description,
				enabled,
				website,
				logo,
				username,
				short_name,
				entitlements,
				verified,
				bio
			} = response;

			// setReason(entitlements[VIRTUAL_CHARITY]?.reason)
			// setStatus(entitlements[VIRTUAL_CHARITY]?.status)

			// setDonationReason(entitlements[SEND_BY_POST]?.reason)
			// setDonationStatus(entitlements[SEND_BY_POST]?.status)

			setConfig(response)
			setAboutUs(description)
			// setSwitchEnabled(entitlements[VIRTUAL_CHARITY]?.status === "ACTIVE" && verified)
			// setSwitchEnabledDonation(entitlements[SEND_BY_POST]?.status === "ACTIVE" && verified)
			setWebsiteUrl(website)
			setLogo(logo)
			setUsername(username)
			setShortName(short_name)
			setProfileBio(bio)

			return response
		} catch (e) {
			// console.log(e);
			return {}
		}
	}

	const handleValidProfileSubmit = async (event: FormEvent) => {
		console.log(event)
		event?.preventDefault()
		try {
			// const charityStatusActive = config?.entitlements?.[VIRTUAL_SHOP]?.status === "ACTIVE"
			// const takeBacksStatusActive = config?.entitlements?.[TAKE_BACKS]?.status === "ACTIVE"
			// if(
			// 	(!(charityStatusActive && takeBacksStatusActive) && username) ||
			// 	//if charity status is active, additionaly check other fields, bcs its required when virtual charity is active
			// 	((charityStatusActive || takeBacksStatusActive) && username && aboutUs && websiteUrl && logo?.id)
			// ){
			const appProfileStatusActive = config?.entitlements?.[APP_PROFIE]?.status === "ACTIVE"
			const virtualShopStatusActive = config?.entitlements?.[VIRTUAL_SHOP]?.status === "ACTIVE"
			// console.log(['appProfileStatusActive', appProfileStatusActive]);
			// console.log(['virtualShopStatusActive', virtualShopStatusActive]);
			// if((virtualShopStatusActive || appProfileStatusActive) && !(username && aboutUs && websiteUrl && logo?.id)){
			if((virtualShopStatusActive || appProfileStatusActive) && !(shortName && username && profileBio && aboutUs && websiteUrl && logo?.id)){
				onOpenAlert({ status: 'danger', text: returnTranslation("Please fill required fields.") })
				setFormErrors({
					short_name: !shortName,
					username: !username,
					description: !aboutUs,
					logo: !logo?.id,
					website: !websiteUrl,
					bio: !profileBio
				})
				return false;
			}
			const newDetailsConfig = {
				"description": aboutUs,
				"logo": logo?.id ? logo?.id : null,
				"background": background?.id ? background?.id : null,
				"username": username,
				"short_name": shortName,
				"bio": profileBio
			}
			// await Promise.all([
			// 	updateDetailsConfig(newDetailsConfig),
			// 	updateDetailsWebsite(user?.role, websiteUrl)
			// ])
			await updateDetailsConfig(newDetailsConfig)
				.then(res => {
					// console.log(res?.user)
					dispatch(registerUserSuccessful({
						...res?.user,
						access_token: user?.access_token
					}))
				})

			await updateDetailsWebsite(user?.role, websiteUrl)


			const responseConfig = await handleGetDetailsConfig();
			setConfig(responseConfig)
			setFormErrors({})
			onOpenAlert({ status: 'success', text: returnTranslation("Configuration saved.") })
			onCheckAccount();
			// } else {
			// 	onOpenAlert({ status: 'danger', text: returnTranslation("Please fill required fields.") })
			// 	setFormErrors({
			// 		username: !username,
			// 		//if charity status is active, additionaly check other fields, bcs its required when virtual charity is active
			// 		...(virtualShopStatusActive ? {
			// 			description: !aboutUs,
			// 			logo: !logo?.id,
			// 			website: !websiteUrl
			// 		}: {})
			// 	})
			// }
		} catch (e) {
			console.log(e)
			//@ts-ignore
			const { message, errors } = e?.response?.data;
			setFormErrors(errors)
			onOpenAlert({ status: 'danger', text: returnTranslation(message) })
			// console.log(e)
		}
	}

	const handleAcceptedFiles = (files: any, type: string) => {
		files.map(async (file: any) => {
			const reader = new FileReader()

			if (file.type.match("image.*")) {
				reader.onabort = () => console.log("file reading was aborted")
				reader.onerror = () => console.log("file reading has failed")
				reader.onload = async () => {
					//   const binaryStr = reader.result
					file = new Blob([file], { type: "image/png" })

					try {
						const response: any = await handleUploadPhoto(file)
						// console.log(['response', response]);
						Object.assign(file, {
							id: response?.id,
							url: URL.createObjectURL(file),
							uploadType: type
						})

						// setLogo(file)
						setFilesToResize([file]);
					} catch (err) {
						onOpenAlert({ text: 'error', status: "danger" })
					}
				}
				reader.readAsDataURL(file)
			} else {
				onOpenAlert({ text: returnTranslation("Import image type"), status: "danger" })
			}
		})
	}

	const handleUploadPhoto = async (photo: any) => {
		return new Promise((resolve, reject) => {
			let config = {
				headers: {
					'Content-Type': 'multipart/form-data',
					"Client-UUID": dashboardID,
					Authorization: `Bearer ${userToken}`,
				},
			}

			const formData = new FormData()

			formData.append("file", photo)

			axios
				.post(`${url.API_BASE}files`, formData, config)
				.then(response => {
					resolve(response?.data)
				})
				.catch(error => {
					if (error?.response?.data) {
						const { message, errors } = error?.response?.data

						if (message === "Unauthenticated.") {
							localStorage.setItem("authUser", "")
						}

						openAlert({ text: message, status: "danger" })
					}
					return reject(error)
				})
		})
	}

	const handleSaveCroppedImage = async (croppedImageBlob: any, uploadType: "logo" | "background") => {
		try {
			const response: any = await handleUploadPhoto(croppedImageBlob);

			Object.assign(croppedImageBlob, {
				id: response?.id,
				url: URL.createObjectURL(croppedImageBlob),
			})

			let filesToResizeCopy = [...filesToResize];
			filesToResizeCopy.shift();
			setFilesToResize(filesToResizeCopy)
			if(uploadType === "logo"){
				setLogo(croppedImageBlob)
			}else{
				setBackground(croppedImageBlob)
			}
		} catch (e) {
			// console.log(e);
		}
	}

	// const getMissingInfoMessageText = () => {
	// 	if(!config) return false;
	// 	let text = returnTranslation("The following information is missing: ")
	// 	let missingArr = []
	// 	if(!config?.logo?.id){
	// 		missingArr.push("Logo")
	// 	}
	// 	if(!config?.username){
	// 		missingArr.push("Short name")
	// 	}
	// 	if(!config?.description){
	// 		missingArr.push("About us")
	// 	}
	// 	if(!config?.website){
	// 		missingArr.push("Website URL")
	// 	}
	// 	if(!config?.bio){
	// 		missingArr.push("Bio")
	// 	}

	// 	if(!missingArr?.length){
	// 		return false
	// 	}

	// 	return text + missingArr?.join(", ");
	// }

	const onEmojiClick = (emojiData: EmojiClickData, event: MouseEvent) => {
		event?.preventDefault();
        setProfileBio(`${profileBio} ${emojiData?.emoji}`)
    };

	return (
		<React.Fragment>
			<MetaTags>
				{returnTranslation("LOOP | Mobile App Profile - Details")}
			</MetaTags>
			<div className="page-content">
				<Container fluid>
					<Breadcrumb title="Mobile App Profile" breadcrumbItem="Details" />

					<Row>
						<Col sm={12} md={12} lg={12} xl={8}>
							<Card>
								<CardBody>
									<div className="welcome-panel__steps">
										<SingleConfigurationOption
											header={"Mobile App Profile"}
											description={`Add details to display Your ${user?.role === "RETAILER" ? "brand" : user?.role?.toLowerCase()} profile in LOOP APP.`}
											filled={appProfileEntitlement?.status === "ACTIVE" && details}
											descriptionClassName="m-0"
											containerClassName="p-0"
											tasksOpened={true}
											tasksOptions={[
												{
													name: "Details",
													isActive: details,
													route: `/details`
												}
											]}
											entitlement={appProfileEntitlement}
											entitlementType={APP_PROFILE}
											verified={accountInfo?.verified}
											activateCondition={details}
											returnTranslation={returnTranslation}
											customFilledIconDone={<img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donates.png" />}
											customFilledIconUndone={<img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donates.png" />}
										/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col sm={12} md={12} lg={12} xl={8}>
							<Card>
								<CardBody>
									<h4 className="card-title mb-4">{returnTranslation("Details")}</h4>
									<ContactUsAlert
										display={isFormDisabled}
										returnTranslation={returnTranslation}
										onOpenAlert={onOpenAlert}
									/>
									{/* <ContactUsAlert
										//@ts-ignore
										customText={getMissingInfoMessageText()}
										display={getMissingInfoMessageText() ? true : false}
										returnTranslation={returnTranslation}
										onOpenAlert={onOpenAlert}
									/> */}
									<Form
										className="form-horizontal"
										onSubmit={handleValidProfileSubmit}
									>
										<div className="mb-3">
											<FormGroup className="mb-4" row>
												<div className="d-flex align-items-center">
													<Label className={`col-form-label pt-1 ${formErrors?.logo ? "text-danger" : ""}`}>
														<span>{returnTranslation("Logo")}</span>
														{formErrors?.logo ? 
															<span className="ms-1">
																<img height="16" src={formErrorSvg} />
															</span>
														: null}
													</Label>
												</div>

												<div className="d-flex align-items-center">
													<img src={logo?.url} style={{
														maxHeight: '100px',
														borderRadius: '100%'
													}} />
													<Dropzone
														onDrop={acceptedFiles =>
															handleAcceptedFiles(acceptedFiles, "logo")
														}
														disabled={isFormDisabled}
													>
														{({ getRootProps, getInputProps }) => (
															<div {...getRootProps()}>
																<input {...getInputProps()} />
																<Button
																	type="button"
																	className="m-2 btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
																	disabled={isFormDisabled}
																>
																	{returnTranslation("Edit")}
																</Button>
															</div>
														)}
													</Dropzone>
													{/* {logo?.id ?
														<Button onClick={() => setLogo(null)} type="button" className="m-2 btn-no-shadow btn btn-danger btn-block waves-effect waves-light">
															X
														</Button>
													: null} */}
												</div>

												<Modal isOpen={filesToResize?.length && filesToResize?.[0]?.uploadType === "logo"}
													centered={true}
												>
													<ModalHeader>
														{returnTranslation("Crop image")}
													</ModalHeader>
													<ModalBody>
														<ImageCropper
															image={filesToResize?.[0]}
															onSave={(blob: any) => handleSaveCroppedImage(blob, "logo")}
															cropRadius={true}
															returnTranslation={returnTranslation}
															setImage={() => setFilesToResize([])}
															showZoomSlider={true}
														/>
													</ModalBody>
												</Modal>
											</FormGroup>
										</div>

										<div className="mb-3">
											<FormGroup className="mb-4" row>
												<div className="d-flex align-items-center">
													<Label className={`col-form-label pt-1 ${formErrors?.background ? "text-danger" : ""}`}>
														<span>{returnTranslation("Background Image")}</span>
														{formErrors?.background ? 
															<span className="ms-1">
																<img height="16" src={formErrorSvg} />
															</span>
														: null}
													</Label>
												</div>

												<div className="d-flex align-items-center">
													<img src={background?.url} style={{
														maxHeight: '100px',
													}} />
													<Dropzone
														onDrop={acceptedFiles =>
															handleAcceptedFiles(acceptedFiles, "background")
														}
														disabled={isFormDisabled}
													>
														{({ getRootProps, getInputProps }) => (
															<div {...getRootProps()}>
																<input {...getInputProps()} />
																<Button
																	type="button"
																	className="m-2 btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
																	disabled={isFormDisabled}
																>
																	{returnTranslation("Edit")}
																</Button>
															</div>
														)}
													</Dropzone>
													{/* {logo?.id ?
														<Button onClick={() => setLogo(null)} type="button" className="m-2 btn-no-shadow btn btn-danger btn-block waves-effect waves-light">
															X
														</Button>
													: null} */}
												</div>

												<Modal isOpen={filesToResize?.length && filesToResize?.[0]?.uploadType === "background"}
													centered={true}
													size="lg"
												>
													<ModalHeader>
														{returnTranslation("Crop image")}
													</ModalHeader>
													<ModalBody>
														<ImageCropper
															image={filesToResize?.[0]}
															onSave={(blob: any) => handleSaveCroppedImage(blob, "background")}
															aspectRatio={415/200}
															cropRadius={false}
															returnTranslation={returnTranslation}
															setImage={() => setFilesToResize([])}
															showZoomSlider={true}
														/>
													</ModalBody>
												</Modal>
											</FormGroup>
										</div>

										<div className="form-group">
											<Label>{returnTranslation("Short Name (max. 20 characters)")}</Label>
											<InputGroup>
												<Input
													name="short_name"
													label="Short Name"
													value={shortName}
													className="form-control"
													placeholder={returnTranslation("Enter short name")}
													type="text"
													onChange={(e: any) => setShortName(e.target.value)}
													invalid={formErrors?.short_name}
													disabled={isFormDisabled}
													maxLength={20}
												/>
											</InputGroup>
										</div>

										<div className="form-group">
											<Label>{returnTranslation("Username (profile URL)")}</Label>
											<InputGroup>
												<Input
													name="username"
													label="Username"
													value={username}
													className="form-control"
													placeholder={returnTranslation("Enter username")}
													type="text"
													onChange={(e: any) => setUsername(e.target.value)}
													invalid={formErrors?.username}
													disabled={isFormDisabled}
												/>
											</InputGroup>
										</div>

										<div className="form-group">
											<Label>{returnTranslation("Website url")}</Label>
											<InputGroup className={formErrors?.website ? "input-invalid" : ""}>
												<InputGroupAddon addonType="prepend">
													https://
												</InputGroupAddon>
												<Input
													label={returnTranslation("Website url")}
													type="text"
													name="url"
													className="form-control"
													//@ts-ignore
													placeholder={returnTranslation("Enter website url")}
													value={websiteUrl?.replace("https://", "")}
													onChange={(e: any) => setWebsiteUrl(e.target.value ? "https://" + e.target.value : "")}
													disabled={isFormDisabled}
												/>
											</InputGroup>
											<div className="text-danger">{formErrors?.website ? formErrors?.website[0] : ""}</div>
										</div>

										<div className="form-group">
											<Label className="d-flex align-items-center">
												{returnTranslation("Profile Bio")}
												<div className="emoij-open-icon" onClick={() => setIsEmojiOpen(!isEmojiOpen)}>
													<img src={emojiIcon}/>
													<span>{isEmojiOpen ? returnTranslation("Close Emoji") : returnTranslation("Open Emoji")}</span>
												</div>
											</Label>
											
											<InputGroup>
												<Input
													name="bio"
													label="Profile Bio"
													value={profileBio}
													className="form-control"
													placeholder={returnTranslation("Enter profile bio")}
													type="textarea"
													rows={3}
													resize={false}
													onChange={(e: any) => setProfileBio(e.target.value)}
													invalid={formErrors?.bio}
													disabled={isFormDisabled}
													maxLength={150}
												/>
											</InputGroup>
											{isEmojiOpen ?
												<div className="emoji-picker--wrapper"
													// style={{ display: isEmojiOpen ? "block" : "none" }}
												>
													<div className="emoiji-picker--close"
														onClick={(e) => {
															e.stopPropagation()
															setIsEmojiOpen(false)
														}}
													/>
												
													<Picker
														height={320}
														width={280}
														onEmojiClick={(emojiData: EmojiClickData, event: MouseEvent) => onEmojiClick(emojiData, event)}
														lazyLoadEmojis={true}
														autoFocusSearch={false}
														emojiStyle={EmojiStyle?.NATIVE}
													/>
												</div>
											: null}
										</div>

										<div className="form-group">
											<Label>{returnTranslation("About us")}</Label>
											<InputGroup>
												<Input
													name="about_us"
													label="About us"
													value={aboutUs}
													className="form-control"
													placeholder={returnTranslation("Enter text")}
													type="textarea"
													rows={12}
													resize={false}
													onChange={(e: any) => setAboutUs(e.target.value)}
													invalid={formErrors?.description}
													disabled={isFormDisabled}
												/>
											</InputGroup>
										</div>

										<div className="mb-3 d-flex gap-2">
											<Button
												color="success"
												type="submit"
												className="btn-no-shadow btn-block waves-effect waves-light"
												disabled={isFormDisabled}
											>
												{returnTranslation("Save")}
											</Button>
											{/* @ts-ignore */}
											{history?.location?.state?.from
												? (
													<Button
														onClick={() => history?.goBack()}
														type="button"
														className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
													>
														{returnTranslation("Back")}
													</Button>	
												) : null
											}
										</div>
										
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	)
}

const mapStateToProps = (state: any) => {
	const { error } = state.Login
	const { dashbord_id, user } = state.Account
	const { countries } = state.Countries
	return { error, dashbord_id, user, countries }
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		onApiError: () => apiError(),
		onOpenAlert: ({ text, status }: any) =>
			dispatch(openAlert({ text: text, status: status })),
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Details)
)