// @ts-ignore
import React, { FormEvent, useEffect, useState } from "react";
import ReactFlagsSelect from 'react-flags-select';
// @ts-ignore
import MetaTags from 'react-meta-tags';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/high-res.css'
// @ts-ignore
import { connect, useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { RouteComponentProps, useParams } from "react-router";
// @ts-ignore
import { withRouter } from "react-router-dom";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container, Form, Input, InputGroup, Label, Row, Spinner
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ContactUsAlert from "../../../components/Common/ContactUsAlert";
import FormInput from "../../../components/Common/FormInput";
import { get } from "../../../helpers/api_helper";
import * as url from "../../../helpers/url_helper";
import { createShippingAddress, getShippingAddress, getCity } from "../../../helpers/backend_helper";
import { apiError, checkAccount } from "../../../store/actions";
import { openAlert } from "../../../store/alert/actions";
import SingleConfigurationOption from "../../Dashboard/WelcomePanels/SingleConfigurationOption/SingleConfigurationOption";
//@ts-ignore
import SchoolShipByPost from "../../../assets/images/school_free_post_donation.svg";

export interface Props extends RouteComponentProps {
	dashbord_id: string,
	apiError: (err: string) => {},
	history: any,
	error: string,
	user: any,
	onOpenAlert: any,
	returnTranslation: any
}

const EnableShipByPost = ({ returnTranslation, onOpenAlert, history }: Props) => {
	const dispatch = useDispatch();
	// const [config, setConfig] = useState<any>({})
	const SEND_BY_POST = "SEND_BY_POST"

	const onGetCountries = () => dispatch({ type: "GET_COUNTRIES" });
	const { countries } = useSelector((state: any) => state.Countries)

	const onCheckAccount = () => dispatch(checkAccount());
	const accountInfo = useSelector((state: any) => state.Account?.info);
	const detailsFormFilled = useSelector((state: any) => state.Account?.info?.configuration?.details)
	const sendByPost = useSelector((state: any) => state.Account?.info?.configuration?.entitlements?.[SEND_BY_POST])
	const user = useSelector((state: any) => state.Account?.user);


	const [shippingAddress, setShippingAddress] = useState({
		city_name: "",
		name: "",
		house_no: "",
		flat_no: "",
		postcode: "",
		street: "",
		phone: "",
		country: ""
	})
	const [formErrors, setFormErrors] = useState<any>({});
	const [selectedCountry, setSelectedCountry] = useState("");
	const [phone, setPhone] = useState("");
	const [postcode, setPostcode] = useState("");
	const [isLoadingCity, setIsLoadingCity] = useState(false);
	const [city, setCity] = useState<any>({});
	const [cities, setCities] = useState<any[]>([]);

	const [shippingDetailsFilled, setShippingDetailsFilled] = useState(false)
	const [isFormDisabled, setIsFormDisabled] = useState(false);

	useEffect(() => {
		//if entitlemets.SEND_BY_POST was activated anytime && shippingDetailsFilled -> dont allow to change form data
		setIsFormDisabled(!!(sendByPost?.status && shippingDetailsFilled))
	}, [sendByPost, shippingDetailsFilled])

	useEffect(() => {
		// const { city_name, house_no, postcode, street, flat_no } = shippingAddress;
		// setShippingDetailsFilled(!!(city_name && house_no && postcode && street))
		setShippingDetailsFilled(!!(shippingAddress?.city_name && shippingAddress?.house_no && shippingAddress?.postcode && shippingAddress?.street))
	}, [shippingAddress])
	
	useEffect(() => {
		onCheckAccount();
		onGetCountries();
		handleGetShippingAddress();
	}, [])

	useEffect(() => {
        selectedCountry && getCities();
    }, [selectedCountry])
	
	const handleGetShippingAddress = async () => {
		try {
			const response: any = await getShippingAddress();
			setPhone(response?.phone);
			setSelectedCountry(response?.country?.code)
			setPostcode(response?.postcode)
			setCity(response?.city)
			setShippingAddress(response)
		} catch (e) {
		}
	}

	const handleSubmit = async (ev: FormEvent) => {
		ev?.preventDefault();
		if(isFormDisabled) return;
		
		try {
			
			//@ts-ignore
			const formData = new FormData(ev?.target)
			const body = {
				name: formData.get("name"),
				city_name: formData.get("city_name"),
				house_no: formData.get("house_no"),
				flat_no: formData.get("flat_no"),
				postcode: formData.get("postcode"),
				street: formData.get("street"),
				phone: formData.get("phone"),
				country: formData.get("country"),
				shipping: 1
			}

			// const errors = {}
			// Object.entries(body).forEach(entry => {
			// 	const key = entry[0]
			// 	const value = entry[1]
			// 	if(!value){
			// 		Object.assign(errors, {[key] : true})
			// 	}
			// })
			
			// if(Object.keys(errors)?.length > 0){
			// 	reject({ response: {data: { message: returnTranslation("The given data was invalid."), errors }} })
			// }

			const response = await createShippingAddress(body);
			onOpenAlert({text: returnTranslation("Shipping address updated successfuly."), status: 'success'})
			setFormErrors({})
			handleGetShippingAddress();
		} catch(error) {
			// console.log(["error", error])
			//@ts-ignore
			const { message, errors } = error?.response?.data
			onOpenAlert({text: message, status: 'danger'})
            setFormErrors(errors)
		}
	}

	const handleUpdateCity = async (postcode: string, countryCode: any) => {
		try{
			const cityObject = await handleGetCity(postcode, countryCode)
			if (cityObject?.name) {
				setCity(cityObject)
			}
		}catch(e){
			// console.log(e)
		}
	}

	const handleGetCity = async (postcode: string, countryCode: any): Promise<{id: number, name: string}> => {
		return new Promise(async (resolve, reject) => {
			setIsLoadingCity(true)
			try {
				const cityObject = await getCity(postcode, countryCode)
				onOpenAlert({
					text: returnTranslation(`Found`) +  ` ${cityObject.name}.`,
					status: "success",
				})
				resolve(cityObject)
			} catch (error) {
				// console.log(['error', error]);
				//@ts-ignore
				const { message } = error?.response?.data;
				onOpenAlert({
					text: message,
					status: "danger",
				})
				reject(error)
			} finally {
				setIsLoadingCity(false)
			}
		})
	}

    const getCities = () => {
        return new Promise(async (resolve, reject) => {
            try {
                get(`${url.API_BASE}countries/${selectedCountry}/cities`)
                .then(response => {
                    if (response) {
                        setCities(response.map((city: any) => ({ id: city?.id, value: city?.name, name: city?.name })))
                    }
                }).catch(error => {
                    if (error?.response?.data) {
                        const { message, errors } = error?.response?.data;
                        setFormErrors(errors)
                        onOpenAlert({
                            text: message,
                            status: "danger",
                        })
                        reject(error)
                    }
                })
            } catch (e) {
                // console.log(e)
            }
        })
    }

	return (
		<React.Fragment>
			<MetaTags>
				<title>
					{user?.role === 'CHARITY' ? returnTranslation("LOOP | Donations Setup - Ship by Post") : null}
					{user?.role === 'SCHOOL' ? returnTranslation("LOOP | LOOP FOR GOOD - Ship by Post") : null}
				</title>
			</MetaTags>
			<div className="page-content">
				<Container fluid>
					{user?.role === 'CHARITY' ? <Breadcrumb title="Donations Setup" breadcrumbItem="Ship by Post" /> : null}
					{user?.role === 'SCHOOL' ? <Breadcrumb title="LOOP FOR GOOD" breadcrumbItem="Ship by Post" /> : null}

					<Row>
						<Col sm={12} md={12} lg={12} xl={8}>
							<Card>
								<CardBody>
									<div className="welcome-panel__steps">
										<SingleConfigurationOption
											header={returnTranslation("Ship by Post")}
											description={"Add the address to allow Loop users to ship their donations directly to your distribution centre."}
											descriptionClassName="m-0"
											// showFilledIcon={false}
											filled={shippingDetailsFilled && detailsFormFilled && sendByPost?.status === "ACTIVE"}
											tasksOpened={true}
											containerClassName="p-0"
											tasksOptions={[
												{
													name: "Details",
													isActive: detailsFormFilled,
													route: `/details`
												},
												{
													name: returnTranslation("Shipping details"),
													isActive: shippingDetailsFilled,
													onClick: () => document.querySelector("#form")?.scrollIntoView({ behavior: "smooth" })
												}
											]}
											entitlement={sendByPost}
											entitlementType={SEND_BY_POST}
											verified={accountInfo?.verified}
											activateCondition={detailsFormFilled && shippingDetailsFilled}
											returnTranslation={returnTranslation}
											customFilledIconDone={user?.role === "CHARITY"
												? <img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donate-free-shipping.png" />
												: <img className="single-configuration-option--icon" src={SchoolShipByPost} />
											}
											customFilledIconUndone={user?.role === "CHARITY"
												? <img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donate-free-shipping.png" />
												: <img className="single-configuration-option--icon" src={SchoolShipByPost} />
											}
										/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Row id="form">
						<Col sm={12} md={12} lg={12} xl={8}>
							<Card>
								<CardBody>
									<ContactUsAlert display={isFormDisabled} returnTranslation={returnTranslation} onOpenAlert={onOpenAlert}/>
									<h4 className="card-title mb-4">{returnTranslation("Shipping Address")}</h4>
									<Form onSubmit={handleSubmit}>
										<div className="form-group">
											<Label>{returnTranslation("Name")}</Label>
											<InputGroup>
												<Input
													name="name"
													className="form-control"
													placeholder={returnTranslation("Enter name")}
													type="text"
													defaultValue={shippingAddress?.name}
													invalid={formErrors.name}
													disabled={isFormDisabled}
												/>
											</InputGroup>
										</div>

										<div className="form-group">
											<Label>{returnTranslation("Street Name")}</Label>
											<InputGroup>
												<Input
													name="street"
													className="form-control"
													placeholder={returnTranslation("Enter street name")}
													type="text"
													defaultValue={shippingAddress?.street}
													invalid={formErrors.street}
													disabled={isFormDisabled}
												/>
											</InputGroup>
										</div>

										<div className="form-group">
											<Label>{returnTranslation("House Number")}</Label>
											<InputGroup>
												<Input
													name="house_no"
													className="form-control"
													placeholder={returnTranslation("Enter house number")}
													type="text"
													defaultValue={shippingAddress?.house_no}
													invalid={formErrors.house_no}
													disabled={isFormDisabled}
												/>
											</InputGroup>
										</div>

										<div className="form-group">
											<Label>{returnTranslation("Flat Number")}</Label>
											<InputGroup>
												<Input
													name="flat_no"
													className="form-control"
													placeholder={returnTranslation("Enter flat number")}
													type="text"
													defaultValue={shippingAddress?.flat_no}
													invalid={formErrors.flat_no}
													disabled={isFormDisabled}
												/>
											</InputGroup>
										</div>

										<FormInput
											label={"Country"}
											type="country"
											name="country"
											className="form-control"
											placeholder={"Select country"}
											showErrors={false}
											formErrors={formErrors}
											onChange={code => {
												setCity({ id: 0, name: "" })
												setPostcode("")
												setSelectedCountry(code)
											}}
											value={selectedCountry}
											countries={countries?.map((country: any) => country?.code)}
											disabled={isFormDisabled}
										/>

										<Row>
											<Col sm={8}>
												<div className="form-group">
													<Label>{returnTranslation("Postcode")}</Label>
													<InputGroup>
														<Input
															name="postcode"
															className="form-control"
															placeholder={returnTranslation("Enter postcode")}
															type="text"
															value={postcode}
															onChange={ev => setPostcode(ev?.target?.value)}
															invalid={formErrors.postcode}
															disabled={isFormDisabled}
														/>
													</InputGroup>
												</div>
											</Col>
											<Col sm={4} className="mt-4">
												<Button
													type="button"
													onClick={() => handleUpdateCity(postcode, selectedCountry)}
													className="btn-no-shadow btn btn-success btn-block waves-effect waves-light w-100"
													disabled={!postcode || !selectedCountry || isFormDisabled}
												>
													{isLoadingCity ? <Spinner style={{height: 16, width: 16}} /> : returnTranslation("Update city")}
												</Button>
											</Col>
										</Row>

										<div className="form-group">
											<FormInput
												label={"City"}
												type="city"
												name="city_name"
												className="form-control"
												placeholder={"Select city"}
												showErrors
												formErrors={formErrors}
												onChange={cityObj => setCity(cityObj)}
												value={city?.name}
												cities={cities}
												disabled={isFormDisabled}
											/>
										</div>

										<div className="mb-3">
											<div className="form-group">
												<label>Contact Phone</label>
												<Input
													style={{ display: "none" }}
													name="phone"
													className="form-control"
													placeholder={returnTranslation("Enter city")}
													type="text"
													value={phone}
													invalid={formErrors.phone}
													disabled={isFormDisabled}
												/>
												<PhoneInput
													country="gb"
													value={shippingAddress?.phone ? shippingAddress?.phone : ""}
													onChange={phone => setPhone(phone)}
													disableDropdown={true}
													containerClass={formErrors?.phone ? "is-invalid" : ""}
													disabled={isFormDisabled}
													placeholder="+44 7987 654321"
												/>
											</div>
										</div>
										
										<div className="mb-3 mt-3">
											<Button
												type="submit"
												className="btn-no-shadow btn btn-success btn-block waves-effect waves-light"
												disabled={isFormDisabled}
											>
												{returnTranslation("Save")}
											</Button>
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	)
}

const mapStateToProps = (state: any) => {
	const { error } = state.Login
	const { dashbord_id, user } = state.Account
	const { countries } = state.Countries
	return { error, dashbord_id, user, countries }
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		onApiError: () => apiError(),
		onOpenAlert: ({ text, status }: any) =>
			dispatch(openAlert({ text: text, status: status })),
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EnableShipByPost)
)
