//@ts-ignore
import { AvField, AvForm } from "availity-reactstrap-validation";
import { capitalize } from "lodash";
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button, Modal, ModalBody,
    ModalFooter, ModalHeader
} from "reactstrap";
import { changeCharityEntitlementStatus } from '../../helpers/backend_helper';
import { checkAccount, openAlert } from '../../store/actions';
import SwitchCustom from '../SwitchCustom/SwitchCustom';


export interface Entitlement {
    reason: string;
    status: string;
}

interface Props {
    entitlement?: Entitlement;
    entitlementType: string;
    verified: boolean;
    activateCondition: boolean;
    returnTranslation: (text: string) => string;
    handleShowTasks?: () => void;
    customCheckAccount?: () => void;
}

export const entitlementStatusNames = {
    "INACTIVE": "INACTIVE",
    "ACTIVE": "ACTIVE",
    "REVIEW": "IN REVIEW",
    "REJECTED": "REJECTED",
    "BLOCKED": "BLOCKED"
}

function EntitlementActivation({
    entitlement = {
        status: "INACTIVE",
        reason: ""
    },
    entitlementType,
    verified,
    activateCondition,
    returnTranslation,
    handleShowTasks,
    customCheckAccount
}: Props) {
    const dispatch = useDispatch();
    const [switchEnabled, setSwitchEnabled] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalNote, setModalNote] = useState("")
    const [entitlementName, setEntitlementName] = useState("");

    const onOpenAlert = ({ text, status }: any) => dispatch(openAlert({ text: text, status: status }));
	const onCheckAccount = () => {
        customCheckAccount && customCheckAccount()
        dispatch(checkAccount())
    }

    useEffect(() => {
        setSwitchEnabled(entitlement?.status === "ACTIVE" && verified)
        setEntitlementName(getEntitlementNameByType(entitlementType))
    }, [entitlement, entitlement?.status, entitlementType, verified])

    const getEntitlementNameByType = (entitlementType: string): string => {
        if(entitlementType === "SEND_BY_POST"){
            return "Ship by Post"
        }
        
        if(entitlementType === "VIRTUAL_CHARITY"){
            return "Virtual Charity Shop"
        }

        if(entitlementType === "CHARITY_SHOPS"){
            return "Charity Shops"
        }

        if(entitlementType === "MICRO_DONATIONS"){
            return "Micro-Donations"
        }

        if(entitlementType === "TAKE_BACKS"){
            return "Take Back"
        }

        if(entitlementType === "APP_PROFILE"){
            return "Mobile App Profile"
        }

        return capitalize(entitlementType?.split("_")?.join(" "))
    }

    const handleSwitchChange = async () => {
		try {
			const allow = handleVerify();
			if (allow) {
				const response: any = await changeCharityEntitlementStatus({
					name: entitlementType,
					status: entitlement?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
					request: modalNote
				})

				if (response?.status) {
                    onCheckAccount()
					onOpenAlert({ text: returnTranslation(`${entitlementName} has been ${switchEnabled ? "disabled" : "enabled"}.`), status: 'success' })
					setModalNote("")
				}
			}
		} catch (e) {
			// console.log(e)
            onOpenAlert({ text: returnTranslation(`An error ocurred.`), status: 'success' })
		}
	}

	const toggleModal = () => {
		const allow = handleVerify();
		if (allow) {
			setIsModalOpen(!isModalOpen);
		}
	}

    const handleVerify = () => {
        handleShowTasks && handleShowTasks()
		if ((!switchEnabled && activateCondition) || switchEnabled) {
			return true
		} else {
            onOpenAlert({ text: returnTranslation("Please complete required tasks first."), status: 'danger' })
			return false
		}
	}

    const getEntitlementStatusName = (status: string) => {
        //@ts-ignore
        return entitlementStatusNames?.[status] ?  entitlementStatusNames?.[status] : status;
    }
    
    return (
        <>
            {/* <div>
                <span>Status:</span>
                <span className="ms-1">
                    {entitlement?.status === "ACTIVE" ? <span className="text-success">{entitlement?.status}</span>: null}
                    {entitlement?.status === "REVIEW" ? <span className="text-warning">{entitlement?.status}</span>: null}
                    {entitlement?.status === "INACTIVE" ? <span className="text-secondary">{entitlement?.status}</span> : null}
                    {["REJECTED", "BLOCKED"]?.includes(entitlement?.status) ? <span className="text-danger">{entitlement?.status}</span> : null}
                    {!entitlement?.status ? <span className="text-secondary">INACTIVE</span> : null}
                </span>
                {["REJECTED", "BLOCKED"]?.includes(entitlement?.status) && entitlement?.reason ?
                ( 
                    <div>
                        <span>Reason:</span>
                        <span className="ms-1 text-muted">{entitlement?.reason}</span>
                    </div>
                ) : null}
            </div> */}
            <div style={{ width: '160px' }} className="d-flex gap-1 align-items-center mt-3">
                <SwitchCustom
                    handleSwitchChange={
                        entitlement?.status !== "ACTIVE" && !switchEnabled
                            ? () => toggleModal()
                            : () => handleSwitchChange()
                    }
                    value={switchEnabled}
                    // offText={"Disabled"}
                    // onText={"Enabled"}
                    size="lg"
                    disabled={["REVIEW"]?.includes(entitlement?.status)}
                />
                <span className="ms-1">
                    {entitlement?.status === "ACTIVE" ? <span className="text-success">{getEntitlementStatusName(entitlement?.status)}</span>: null}
                    {entitlement?.status === "REVIEW" ? <span className="text-warning">{getEntitlementStatusName(entitlement?.status)}</span>: null}
                    {entitlement?.status === "INACTIVE" ? <span className="text-secondary">{getEntitlementStatusName(entitlement?.status)}</span> : null}
                    {["REJECTED", "BLOCKED"]?.includes(entitlement?.status) ? <span className="text-danger">{getEntitlementStatusName(entitlement?.status)}</span> : null}
                    {!entitlement?.status ? <span className="text-secondary">{getEntitlementStatusName("INACTIVE")}</span> : null}
                </span>
            </div>

            <div>
                {["REJECTED", "BLOCKED"]?.includes(entitlement?.status) && entitlement?.reason ?
                ( 
                    <div>
                        <span>Reason:</span>
                        <span className="ms-1 text-muted">{entitlement?.reason}</span>
                    </div>
                ) : null}
            </div>

            <Modal
                isOpen={!!isModalOpen}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                //@ts-ignore
                tabIndex="-1"
                toggle={(ev: any) => toggleModal()}
            >
                <div className="modal-content">
                    <ModalHeader>{returnTranslation(`Send verification request for ${entitlementName}`)}</ModalHeader>
                    <ModalBody>
                        <AvForm id="modal-form">
                            <AvField
                                name="modal-note"
                                label={""}
                                value={modalNote}
                                className="form-control"
                                placeholder={returnTranslation("You can leave a short note for our team...")}
                                type="textarea"
                                rows={3}
                                resize={false}
                                onChange={(e: any) => setModalNote(e.target.value)}
                            />
                        </AvForm>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={(e) => {
                                // handleSubmit(e, true)
                                e?.preventDefault()
                                handleSwitchChange()
                                toggleModal()
                            }}
                            color="success"
                            form="modal-form"
                        >
                            {returnTranslation("Send")}
                        </Button>
                    </ModalFooter>
                </div>
            </Modal>
        </>
    )
}

export default EntitlementActivation
