import { del, get, post, put } from "./api_helper"
import {
  UPDATE_WEBSITE,
  GET_WEBSITE_DETAIL,
  ADD_WEBSITE,
  DELETE_WEBSITE,
  GET_COUNTRIES,
  UPDATE_PROFILE,
  GET_WEBSITES,
  LOGIN_PATH,
  GET_DICTIONARIES,
  GET_REGIONS,
  GET_COUPONS,
  GET_CURRENCIES,
  DELETE_CODE,
  GET_COUPON,
  ACTIVE_CODE,
  RETURN_ADDRESS,
  GET_VIRTUAL_SHOP_CONFIG,
  GET_TRANSLATIONS,
  RESET_PASSWORD,
  CHECK_EMAIL,
  CHARITIES_EXPORT,
  GET_PAYMENT_INFO,
  GET_BILLINGS,
  GET_INVOICES,
  UPLOAD_FILE,
  STRIPE_CONNECT,
  STRIPE,
  RECOMMENDATIONS_STATS,

  PAYPAL_CONNECT,
  PAYPAL,
  PAYPAL_DISCONNECT,
  GET_SHIPPING_ADDRESS,
  STRIPE_CARDS
} from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Login
export const postLogin = data => {
  // console.log(["data", data, LOGIN_PATH])

  if (data && data.email && data.password) {
    post(LOGIN_PATH, data)
  }
}

// reset password
export const resetPassword = ({
  email,
  code,
  password,
  password_confirmation
}) => post(`${RESET_PASSWORD}`, {
  email,
  code,
  new_password: password,
  new_password_confirmation: password_confirmation
})

export const checkEmail = ({
  email
}) => post(`${CHECK_EMAIL}`, {
  email
})


// websites
export const getWebsitesApi = ({ page: { page } }) => get(`${GET_WEBSITES}?page=${page ? page : 1}`)
export const getWebsiteDetailApi = uuid => get(`${GET_WEBSITE_DETAIL}/${uuid}`)
export const deleteWebsiteApi = ({ uuid }) => del(`${DELETE_WEBSITE}/${uuid}`)
export const getEmailTemplates = ({ uuid, language_id }) => get(`sites/${uuid}/mail_templates?language_id=${language_id}`)

//countries
export const getCountries = () => get(GET_COUNTRIES)

//translations
export const getTranslations = () => get(GET_TRANSLATIONS)

//profile
export const updateProfile = (user) => post(UPDATE_PROFILE, user)
// //profile
// export const updateProfile = async ({ user }) => {
//   return new Promise(async (resolve, reject) => {
//     const response = await put(UPDATE_PROFILE, {
//       company_name: user.company_name,
//       charity_number: user.charity_number,
//       // name: user.name,
//       vatin: user.vatin,
//       address: user.address,
//       postcode: user.postcode,
//       city: user.city,
//       country: user.country,
//     })
//     resolve(response);
//   })
// }

//dictionaries
export const getDictionaries = () => {
  return new Promise((resolve, reject) => {
    try {
      let response = get(GET_DICTIONARIES)
      resolve(response)
    } catch (err) {
      reject(err)
      // console.log(["err", err, err?.response?.data?.errors])
    }
  })
}

export const getRegions = data => get(GET_REGIONS);


export const getCity = (postcode, countryCode) => post('/cities', { postcode, country: countryCode })

//coupons
export const getCoupons = async ({ page: { page } }) => {
  return await get(`${GET_COUPONS}?page=${page ? page : 1}`)
}
export const deleteCoupon = async ({ id }) => {
  return await del(`${DELETE_CODE}/${id}`)
}
export const getCoupon = async ({ id }) => {
  return await get(`${GET_COUPON}/${id}`)
}
export const activeCoupon = async ({ id, active, retailer_id }) => {
  return await post(`${ACTIVE_CODE}/${id}/active`, { active })
}

export const deleteCode = async ({ coupon_id, code_id }) => {
  return await del(`/retailers/coupons/${coupon_id}/codes/${code_id}`)
}
export const activeCode = async ({ coupon_id, code_id, active }) => {
  return await post(`/retailers/coupons/${coupon_id}/codes/${code_id}/active`, { active })
}

export const getCouponCodesImportStats = async (coupon_id) => get(`/retailers/coupons/${coupon_id}/codes/imports`)

//currencies
export const getCurrencies = () => {
  return new Promise((resolve, reject) => {
    try {
      let response = get(GET_CURRENCIES)
      resolve(response)
    } catch (err) {
      reject(err)
      // console.log(["err", err, err?.response?.data?.errors])
    }
  })
}

//return warehouse
export const getReturnWarehouse = () => get(`${RETURN_ADDRESS}`)

//charity shop
// export const getDetailsConfig = (role) => get(`${role?.toLowerCase()}_shop/config`)
export const getDetailsConfig = (role) => get(`${GET_VIRTUAL_SHOP_CONFIG}`)

export const changeCharityEntitlementStatus = (body) => post(`/entitlements/status`, body)
export const updateDetailsConfig = (body) => put(`${UPDATE_PROFILE}`, body)
export const updateDetailsWebsite = (role, website) => post(`/virtual_shop/website`, { website })
export const getEntitlements = () => get(`/entitlements`)

export const saveMicroDonationsExternalUrl = (body) => post(`/micro_donations/external_url`, body)

export const getShippingAddress = () => get(`${GET_SHIPPING_ADDRESS}?shipping=1`)
export const createShippingAddress = (body) => post(GET_SHIPPING_ADDRESS, body)
// export const updateCharityShippingAddress = (id, body) => put(`${GET_CHARITY_SHIPPING_ADDRESS}/${id}`, body)

//charity shops
export const getPoints = (role, page, params) => get(role?.toUpperCase() === "CHARITY" ? `/charities/points` : `/schools/points` + `?page=${page ? page : 1}${params ? `&${params}` : ""}`)

export const deletePoint = (role, id) => del(`${role?.toUpperCase() === "CHARITY" ? `/charities/points/${id}` : `/schools/points/${id}`}`)
export const getPoint = (role, id) => get(`${role?.toUpperCase() === "CHARITY" ? `/charities/points/${id}` : `/schools/points/${id}`}`);
export const activePoint = (role, ids, active) => post(`${role?.toUpperCase() === "CHARITY" ? `/charities/points/active` : `/schools/points/active`}`, { ids, active })
export const charitiesExport = () => {
  return new Promise((resolve, reject) => {
    try {
      let response = get(CHARITIES_EXPORT)
      resolve(response)
    } catch (err) {
      reject(err)
    }
  })
}
export const generateCoordinates = (address) => post('/coordination', { address })

export const getPointsTags = (role) => get(role?.toUpperCase() === "CHARITY" ? `/charities/points/tags` : `/schools/points/tags`)
export const addPointsTag = (role, name) => post(role?.toUpperCase() === "CHARITY" ? `/charities/points/tags` : `/schools/points/tags`, { name })
export const delPointsTag = (role, id) => get(role?.toUpperCase() === "CHARITY" ? `/charities/points/tags/${id}` : `/schools/points/tags/${id}`)
// export const addPointsTag = (role, name) => post(`/charities/points/tags`, { name })
// export const delPointsTag = (role, id) => del(`/charities/points/tags/${id}`)

//payment info
export const getPaymentInfo = () => get(GET_PAYMENT_INFO)

//billings
export const getBillings = () => get(GET_BILLINGS)

//invoices
export const getInvoices = () => get(GET_INVOICES)

//invoices
export const uploadFile = (body, config) => post(UPLOAD_FILE, body, config)

//stripe
export const stripe = () => get(STRIPE)
export const stripeConnect = () => get(STRIPE_CONNECT)
export const stripeDisonnect = () => del(STRIPE_CONNECT)
export const createStripeCard = (id, data) => post(`${STRIPE_CARDS}`, data)
export const updateStripeCard = (id, data) => put(`${STRIPE_CARDS}/${id}`, data)
export const stripeDisonnectCard = (id) => del(`${STRIPE_CARDS}/${id}`)

//paypal
export const paypal = () => get(PAYPAL)
export const paypalConnect = () => get(PAYPAL_CONNECT)
export const paypalDisonnect = () => post(PAYPAL_DISCONNECT)

//feed analytics
export const getFeedAnalytics = (page, params) => get(`${RECOMMENDATIONS_STATS}?page=${page ? page : 1}&${params ? params : ""}`);

export const checkAccount = () => get(`/dashboard/check_account`)

//donations-report
export const getReports = (role, page, params) => get(`/${role?.toLowerCase()}/donations-monthly-report?page=${page ? page : 1}&${params ? params : ""}`)


//take backs
export const getTakeBacks = (page, params) => get(`/dashboard/takes_backs?page=${page ? page : 1}${params ? `&${params}` : ""}`)

export const getCheckTakeBack = (takeBackId) => get(`/dashboard/takes_backs/${takeBackId}/check`)
export const addTakeBack = (data) => post(`/dashboard/takes_backs`, data)
export const getTakeBack = (takeBackId) => get(`/dashboard/takes_backs/${takeBackId}`)
export const updateTakeBack = (takeBackId, data) => post(`/dashboard/takes_backs/${takeBackId}`, { ...data, _method: "PUT" })
export const deleteTakeBack = (takeBackId) => del(`/dashboard/takes_backs/${takeBackId}`)

//carriers
export const getTakeBackCarriers = (takeBackId, page, params) => get(`/dashboard/takes_backs/${takeBackId}/carriers?page=${page ? page : 1}${params ? `&${params}` : ""}`)
export const getTakeBackCarrier = (takeBackId, carrierId) => get(`/dashboard/takes_backs/${takeBackId}/carriers/${carrierId}`)
export const getTakeBackCarrierRequirements = (carrierId) => get(`/dashboard/takes_backs/carriers/${carrierId}/integration/requirements`)
export const addTakeBackCarrier = (takeBackId, data) => post(`/dashboard/takes_backs/${takeBackId}/carriers`, data)
export const updateTakeBackCarrier = (takeBackId, data) => post(`/dashboard/takes_backs/${takeBackId}/carriers`, { ...data, _method: "PUT" })
export const toggleTakeBackCarrier = (takeBackId, carrierId, status) => post(`/dashboard/takes_backs/${takeBackId}/carriers/${carrierId}/toggle`, { status })

//points
export const getTakeBackPoints = (takeBackId, page, params) => get(`/dashboard/takes_backs/${takeBackId}/points?page=${page ? page : 1}${params ? `&${params}` : ""}`)
export const getTakeBackPoint = (takeBackId, pointId) => get(`/dashboard/takes_backs/${takeBackId}/points/${pointId}`)
export const addTakeBackPoint = (takeBackId, data) => post(`/dashboard/takes_backs/${takeBackId}/points`, data)
export const updateTakeBackPoint = (takeBackId, pointId, data) => put(`/dashboard/takes_backs/${takeBackId}/points/${pointId}`, data)
export const toggleTakeBackPoint = (takeBackId, pointId, status) => post(`/dashboard/takes_backs/${takeBackId}/points/${pointId}/toggle`, { status })
export const deleteTakeBackPoint = (takeBackId, pointId) => del(`/dashboard/takes_backs/${takeBackId}/points/${pointId}`)

//return address
export const getTakeBackReturnAddresses = () => get(`/dashboard/return_addresses`);
export const deleteTakeBackReturnAddress = (return_address_id) => del(`/dashboard/return_addresses/${return_address_id}`);
export const addTakeBackReturnAddress = (
  data = {
    "title": "",
    "city": "",
    "houseNo": "",
    "postcode": "",
    "street": "",
    "country_id": 0
  }) => post(`/dashboard/return_addresses`, data)

// export const updateTakeBackReturnAddress = (takeBackId, data = { "title": "", "city": "", "houseNo": "", "postcode": "", "street_name": "", "country_id": 0 }) =>
//   post(`/dashboard/takes_backs/${takeBackId}/return_address`, { ...data, _method: "PUT" })

//tags
export const addTakeBackTags = (takeBackId, data = { accepts_tags: [0], not_accepts_tags: [0] }) => post(`/dashboard/takes_backs/${takeBackId}/tags/add`, data)

export const getSchoolTypes = () => get(`/schools/types`)
export const getSchoolStages = () => get(`/schools/stages`)
