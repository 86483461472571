import {
  GET_TRANSLATIONS,
  GET_TRANSLATIONS_SUCCESS,
  GET_TRANSLATIONS_FAIL,
} from "./actionTypes"

export const getTranslations = () => ({
  type: GET_TRANSLATIONS,
})

export const getTranslationsSuccess = translations => ({
  type: GET_TRANSLATIONS_SUCCESS,
  payload: translations,
})

export const getTranslationsFail = error => ({
  type: GET_TRANSLATIONS_FAIL,
  payload: error,
})