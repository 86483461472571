import React, { useState, useEffect } from 'react'
import ReactCropper from 'react-cropper'
import "cropperjs/dist/cropper.css";
import './ImageCropper.scss'
import { Button } from 'reactstrap';
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"

interface Props {
    returnTranslation: any;
    image: any;
    onSave: any;
    cropRadius?: boolean;
    aspectRatio?: number;
    resizeFile?: any;
    setImage?: any;
    showZoomSlider?: boolean;
}

const ImageCropper = ({ image, returnTranslation, onSave, cropRadius, aspectRatio, resizeFile, setImage, showZoomSlider }: Props) => {
    const [base64Img, setBase64Img] = useState<any>(null)
    const [cropper, setCropper] = useState<any>();
    const [rangeValue, setRangeValue] = useState(0.4);

    const drawImageScaled = (imgProp: any) => {
        // console.log(["drawImageScaled", imgProp])
        const canvasElem = document.createElement('canvas');
        canvasElem.width = 1000;
        canvasElem.height = 1000;
        const ctx = canvasElem?.getContext('2d')

        if (ctx) {
            // console.log(ctx);
            const img = new Image();
            img.src = imgProp?.url;
            img.onload = function () {
                var canvas = ctx.canvas;
                var hRatio = canvas.width / img.width;
                var vRatio = canvas.height / img.height;
                var ratio = Math.min(hRatio, vRatio);

                let destinationImageWidth = img.width * ratio
                let destinationImageHeight = img.height * ratio
                //Add some margin around image to maximize zoom
                destinationImageWidth = destinationImageWidth * 0.7;
                destinationImageHeight = destinationImageHeight * 0.7;

                var centerShift_x = (canvas.width - destinationImageWidth) / 2;
                var centerShift_y = (canvas.height - destinationImageHeight) / 2;
                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                ctx.drawImage(img, 0, 0, img.width, img.height, centerShift_x, centerShift_y, destinationImageWidth, destinationImageHeight);

                setBase64Img(canvas?.toDataURL())
            }
        }
    }

    const getCropData = () => {
        if (typeof cropper !== "undefined") {

            cropper.getCroppedCanvas().toBlob(async (blob: any) => onSave(blob, await getBase64Img(blob)))
        }
    };

    useEffect(() => {
        // image && handleSetBase64Img(image, resizeFile)
        handleDrawImage();
    }, [image])

    const handleDrawImage = async () => {
        let img = image;
        if (resizeFile) {
            img = await resizeFile(image)
        }
        drawImageScaled(img)
    }

    // const handleSetBase64Img = async (image: any, resizeFile?: any) => {
    //     const base64 = await getBase64Img(image, resizeFile);
    //     // console.log(base64)
    //     // setBase64Img(base64)
    // }

    const getBase64Img = async (image: any, resizeFile?: any) => {
        if (resizeFile) {
            const resized = await resizeFile(image)
            const base64 = await toBase64(resized)
            return base64
        }
        // const blobUrl = URL.createObjectURL(image)
        const base64 = await toBase64(image)
        return base64
    }

    const toBase64 = (file: any) => new Promise((resolve, reject) => {
        // file = new Blob([file]);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })

    return (
        <div className={`crop loop__widget--options__container loop__widget--scroll__list-container ${cropRadius ? 'crop-radius' : ''}`}>
            <ReactCropper
                style={{ height: '90%', width: '100%' }}
                aspectRatio={!cropRadius ? aspectRatio ? aspectRatio : (3 / 4) : 1}
                // preview=".img-preview"
                guides={false}
                src={base64Img}
                viewMode={1}
                dragMode="move"
                cropBoxMovable={!cropRadius}
                cropBoxResizable={!cropRadius}
                background={false}
                minCropBoxHeight={350}
                // minCropBoxWidth={200}
                onInitialized={(instance) => {
                    setCropper(instance);
                }}
                zoomTo={rangeValue}
            />
            {showZoomSlider ? (
                <>
                    <div className="p-1 pt-2 w-100 d-flex align-items-center justify-content-center">
                        {/* <span className="mx-1">0</span> */}
                        <Slider
                            className="w-100"
                            value={rangeValue}
                            orientation="horizontal"
                            onChange={(value: number) => {
                                setRangeValue(value)
                            }}
                            step={0.0001}
                            min={0.0}
                            max={5}
                            format={(value) => `${parseInt(((value * 100) / 5)?.toString())}%`}
                        />
                        {/* <span className="mx-1">100</span> */}
                    </div>
                </>
            ) : null}
            <div className="d-flex m-2 w-100">
                <Button
                    className="me-1"
                    onClick={getCropData}
                    size={"big"}
                    type="button"
                    color="success"
                >{returnTranslation('Save')}
                </Button>
                {setImage ?
                    <Button
                        size={"big"}
                        onClick={() => setImage(null)}
                        type="button"
                        color="danger"
                        className="ms-1"
                    >
                        {returnTranslation("Cancel")}
                    </Button>
                    : null}
            </div>

            {/* <canvas
                //@ts-ignore
                ref={(ref: HTMLCanvasElement) => canvasRef = ref}
                width="500"
                height="500"
            /> */}
        </div>
    )
}

export default ImageCropper
