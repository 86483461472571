import { takeEvery, put, call } from "redux-saga/effects"

import {
  getFeedAnalyticsSuccess,
  getFeedAnalyticsFail,
} from "./actions"

import { GET_FEED_ANALYTICS } from "./actionTypes"

import {
  // deleteWardrobeItem,
  getFeedAnalytics,
} from "../../helpers/backend_helper"

function* getFeedAnalyticsAsync({ payload }) {
  try {
    const params = new URLSearchParams(payload?.queryObject)
    const response = yield call(() => getFeedAnalytics(payload?.page, params?.toString()))

    yield put(getFeedAnalyticsSuccess(response))
  } catch (error) {
    yield put(getFeedAnalyticsFail(error))
  }
}

// function* deleteWardrobeItemAsync({ payload }) {
//   try {
//     const { id } = payload;

//     const response = yield call(deleteWardrobeItem, id)

//     const responseFeedAnalytics = yield call(getFeedAnalytics)
//     yield put(getFeedAnalyticsSuccess(responseFeedAnalytics))
//   } catch (error) {
//     yield put(getFeedAnalyticsFail(error))
//   }
// }

function* FeedAnalyticsSaga() {
  yield takeEvery(GET_FEED_ANALYTICS, getFeedAnalyticsAsync)
  // yield takeEvery(DELETE_WARDROBE_ITEM, deleteWardrobeItemAsync)
}

export default FeedAnalyticsSaga
