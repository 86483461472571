// @ts-ignore
import React, { useEffect, useState } from "react"
// @ts-ignore
import MetaTags from 'react-meta-tags'
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
// @ts-ignore
import { RouteComponentProps, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import RadioCheck from "../../../components/Common/RadioCheck"
import { stripe, stripeDisonnect, stripeDisonnectCard } from "../../../helpers/backend_helper"
import ChangePaymentInfo from "../ChangePaymentInfo/ChangePaymentInfo"
// import ConnectPaypal from "../PayoutsWithPaypal/ConnectPaypal/ConnectPaypal"
import PayoutsWithPaypal from "../PayoutsWithPaypal/PayoutsWithPaypal"
import Breadcrumbs from "./../../../components/Common/Breadcrumb"
import { changePreloader, checkAccount, getPaymentInfo, openAlert } from "./../../../store/actions"
import CreditCardTile from "./CreditCardTile/CreditCardTile"
import Visa from '../../../assets/images/payment/visa.svg'
import Mastercard from '../../../assets/images/payment/mastercard.svg'
import Amex from '../../../assets/images/payment/amex.svg'
import Discover from '../../../assets/images/payment/discover.svg'
import PayPal from '../../../assets/images/payment/paypal.svg'

interface ChangePaymentInfoInterface extends RouteComponentProps {
    history: any,
    returnTranslation: (name: string) => string
}

const PaymentMethod = ({ history, returnTranslation }: ChangePaymentInfoInterface) => {
    const dispatch = useDispatch()
    const paypalInfo = useSelector((state: any) => state.Account?.info);
    const cardInfo = useSelector((state: any) => state.PaymentInfo?.info);
    const isPaypalConnected = useSelector((state: any) => state?.PaymentInfo?.info?.paypal_connection);
    const isCardConnected = useSelector((state: any) => state?.PaymentInfo?.info?.card) !== null;
    const [paymentMethod, setPaymentMethod] = useState<string | number>(isPaypalConnected ? 'paypal' : 'card')
    const [showEditPaymentInfo, setShowEditPaymentInfo] = useState(false);
    // const [stripeInfo, setStripeInfo] = useState<any>({});
    // const [billAnnulally, setBillAnnulally] = useState(false)
    // const [formErrors, setFormErrors] = useState('')

    useEffect(() => {
        dispatch(getPaymentInfo())
        dispatch(checkAccount())
    }, [])

    useEffect(() => {
        setPaymentMethod(isPaypalConnected ? 'paypal' : 'card')
    }, [isPaypalConnected])

    const handleDisconnectCard = async () => {
        try {
            const response = await stripeDisonnectCard(cardInfo?.card?.id)
            dispatch(openAlert({ text: response?.status, status: "success" }))
            dispatch(getPaymentInfo())
            dispatch(checkAccount())
        } catch(e) {
            //@ts-ignore
            dispatch(openAlert({ text: e?.message, status: "danger" }))
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>LOOP | Payment Method</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs
                        title={returnTranslation("Billing")}
                        breadcrumbItem={returnTranslation("Payment Method")}
                    />
                    <Row>
                        <Col sm={12} md={6} lg={6} xl={6}>
                            <Card className="overflow-hidden">
                                <CardBody className="p-3">
                                    <h4 className="card-title">Choose payment method</h4>

                                    <RadioCheck
                                        label="Credit Card"
                                        id="flexRadioDefault1"
                                        radioGroupName="flexRadioDefault"
                                        // labelIconElement={<i className="bx bx-credit-card"/>}
                                        labelIconElement={<div className="d-flex gap-1">
                                            <img className="credit-card-img" src={Visa} width={50} height={28} />
                                            <img className="credit-card-img" src={Mastercard} width={50} height={28} />
                                            <img className="credit-card-img" src={Amex} width={50} height={28} />
                                            <img className="credit-card-img" src={Discover} width={50} height={28} />
                                        </div>}
                                        value={"card"}
                                        onChange={setPaymentMethod}
                                        checked={paymentMethod === "card"}
                                        disabled={isPaypalConnected}
                                        inputClassName={"mt-8px"}
                                        className={"mb-1"}
                                    />
                                    <RadioCheck 
                                        label="PayPal"
                                        id="flexRadioDefault2"
                                        radioGroupName="flexRadioDefault"
                                        // labelIconElement={<i className="bx bxl-paypal"/>}
                                        labelIconElement={<div style={{padding: "2px"}} className="credit-card-img">
                                            <img  src={PayPal} width={46} height={24} />
                                        </div>}
                                        value={"paypal"}
                                        onChange={setPaymentMethod}
                                        checked={paymentMethod === "paypal"}
                                        disabled={isCardConnected}
                                        inputClassName={"mt-8px"}
                                        className={"mb-1"}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <div style={{ display: paymentMethod === "paypal" ? "block" : "none" }}>
                        <PayoutsWithPaypal 
                            returnTranslation={returnTranslation}
                        />
                    </div>
                    <div style={{ display: paymentMethod === "card" ? "block" : "none" }}>
                        {cardInfo?.card && !showEditPaymentInfo ? (
                            <CreditCardTile
                                cardInfo={cardInfo}
                                setShowEditPaymentInfo={setShowEditPaymentInfo}
                                handleDisconnectCard={handleDisconnectCard}
                                returnTranslation={returnTranslation}
                            />
                        ) : (
                            <ChangePaymentInfo
                                returnTranslation={returnTranslation}
                                handleClose={isCardConnected ? () => setShowEditPaymentInfo(false) : undefined}
                            />
                        )}
                        
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(PaymentMethod)
