import React, { useEffect, useState } from "react"
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Col,
  Container, Row
} from "reactstrap"
import BasicTable from "../../../components/BasicTable/BasicTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { get } from "../../../helpers/api_helper"
import { deleteTakeBackPoint, getTakeBackPoints, toggleTakeBackPoint } from "../../../helpers/backend_helper"
import { openAlert, requestConfirmation } from "../../../store/actions"
import { changeLocalStoresQueryObject, getLocalStores } from "../../../store/localStores/actions"
import "./DropoffPoints.scss"
import DropoffPointsTableColumns from "./DropoffPointsTableColumns"

interface Props {
  history: any,
  returnTranslation: (name: string) => string
}

const DropoffPoints = ({ history, returnTranslation }: Props) => {
  const dispatch = useDispatch()
  const params: { takeback_id: string } = useParams();
  const {
    items,
    isLoading,
    current_page,
    total_items,
    page_count,
    total_pages,
    queryObject,
    initialQueryObject: QUERY_OBJECT_INIT
  } = useSelector((state: any) => state.LocalStores)

  const setQueryObject = (queryObject: any) => dispatch(changeLocalStoresQueryObject(queryObject))

  const handleSetQueryObject = (value: any, forceReload?: boolean) => {
    if ((JSON.stringify(queryObject) !== JSON.stringify(value)) || forceReload) {
      dispatch(getLocalStores({ takebackId: params?.takeback_id, page: 1, queryObject: value }))
    }
    setQueryObject(value)
  }

  // const handleGetTakeBackPoints = async(page: number) => {
  //   try{
  //     setIsLoading(true)
  //     const reponse = await getTakeBackPoints(params?.takeback_id, page)
  //     setTakeBackPoints(reponse)
  //   } catch(e) {
  //     console.log(e)
  //   }finally{
  //     setIsLoading(false)
  //   }
  // }

  const handleEdit = (id: number) => {
    history.push(`/take-back/${params?.takeback_id}/local-stores/${id}`)
  }

  const handleToggleTakeBackPoint = async (pointId: number, isActive: boolean) => {
    try{
      const response = await toggleTakeBackPoint(params?.takeback_id, pointId, !isActive);
      dispatch(openAlert({ text: returnTranslation("Success"), status: "success" }));
    }catch(e){
      dispatch(openAlert({ text: returnTranslation("An error occured"), status: "success" }));
    }finally{
      // handleGetTakeBackPoints(takeBackPoints?.info?.current_page)
      dispatch(getLocalStores({ takebackId: params?.takeback_id, page: current_page, queryObject, preventLoader: true }))
    }
  }

  const handleDeletePoint = async (pointId: number, name: string) => {
    dispatch(
      requestConfirmation(`${returnTranslation("Delete")} ${name}?`, () => deletePoint(pointId))
    )
  }

  const deletePoint = async (pointId: number) => {
    try{
      const response = await deleteTakeBackPoint(params?.takeback_id, pointId);
      dispatch(openAlert({ text: returnTranslation("Success"), status: "success" }));
    }catch(e){
      dispatch(openAlert({ text: returnTranslation("Error"), status: "success" }));
    }finally{
      dispatch(getLocalStores({ takebackId: params?.takeback_id, page: current_page, queryObject, preventLoader: true }))
    }
  }

  const handleSetItemActive = async (id: number) => {
    // handleSetActive(id, 1)
    handleToggleTakeBackPoint(id, false)
  }

  const handleSetItemInactive = async (id: number) => {
    // handleSetActive(id, 0)
    handleToggleTakeBackPoint(id, true)
  }

  const handleExportLocalStores = async () => {
    try{
      get(`/dashboard/takes_backs/${params?.takeback_id}/points/export`)
        .then(response => { 
          // console.log(response?.toBlob())
          const blob = new Blob([response], {type: 'text/csv;charset=utf-8'});
          // console.log(['blob', blob]);
          const a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          const dateTimeFormatted = new Date()?.toLocaleString()?.split(", ")?.join("-")?.split(".")?.join("_")?.split(":")?.join("_");
          a.setAttribute("download", `LOOP Take Back Stores ${dateTimeFormatted}.csv`);
          a.click();
        })
      // console.log(response)
    }catch(e){
      console.log(e)
    }
  }

  // function download(url, filename) {
  //   fetch(url).then(function(t) {
  //       return t.blob().then((b)=>{
  //           var a = document.createElement("a");
  //           a.href = URL.createObjectURL(b);
  //           a.setAttribute("download", filename);
  //           a.click();
  //       }
  //       );
  //   });
  //   }

  return (
    <React.Fragment>
      <div className="page-content carriers">
        <Container fluid>
          <Breadcrumbs title={returnTranslation("Take Back")} breadcrumbItem={returnTranslation("Local Stores")} />

          <Row>
            <Col lg="12">
              {/* <h4 className="card-title">{returnTranslation("Carriers List")}</h4> */}
              <BasicTable
                items={items}
                columns={DropoffPointsTableColumns({
                  offsetIndex: (current_page - 1) * page_count,
                  returnTranslation,
                  handleEdit,
                  handleDelete: handleDeletePoint,
                  handleToggleTakeBackPoint,
                  queryObject,
                  setQueryObject: handleSetQueryObject,
                  defaultQueryObject: QUERY_OBJECT_INIT,
                })}
                addItemBtnText3={returnTranslation("Add Store")}
                addItemBtnAction3={() => history.push(`/take-back/${params?.takeback_id}/local-stores/add`)}

                addItemBtnText2={returnTranslation("Import Stores")}
                addItemBtnAction2={() => history.push(`/take-back/${params?.takeback_id}/local-stores/import`)}
                addItemBtnIconClass2={"far fa-file-excel"}

                addItemBtnText={returnTranslation("Export Stores")}
                addItemBtnAction={handleExportLocalStores}
                addItemBtnIconClass={"far fa-file-excel"}

                searchbar={false}
                getAction={({ page }) => dispatch(getLocalStores({ takebackId: params?.takeback_id, page, queryObject }))}
                // getFunction={page => handleGetTakeBackPoints(page)}
                totalSize={total_items}
                sizePerPage={page_count}
                currentPage={current_page}
                isLoading={isLoading}
                displaySelectMultiple={true}
                selectMultipleOptions={[
                  {
                    'name': 'Set Active',
                    action: (id: number) => handleSetItemActive(id)
                  },
                  {
                    'name': 'Set Inactive',
                    action: (id: number) => handleSetItemInactive(id)
                  },
                  {
                    'name': 'Delete',
                    action:(id: number) => deletePoint(id),
                    requestConfirmation: (cb: any) => dispatch(requestConfirmation("Do you want to delete selected stores?", cb))
                  }
                ]}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DropoffPoints
