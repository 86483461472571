import React from 'react'

interface Props {
    radioGroupName: string;
    id: string;
    label: string;
    descriptionElement?: any;
    labelIconElement?: any;
    defaultChecked?: boolean;
    checked?: boolean;
    disabled?: boolean;
    value: string | number;
    onChange: (value: string | number) => void;
    inputClassName?: string;
    className?: string;
    customClassName?: string;
    reversed?: boolean;
}

function RadioCheck({
    radioGroupName,
    id,
    label,
    descriptionElement,
    labelIconElement,
    defaultChecked,
    checked,
    disabled,
    onChange,
    value,
    inputClassName,
    className,
    customClassName,
    reversed
}: Props) {

    return (
        reversed ? (
            <div className={`form-check radio-check radio-check-reversed d-flex ${className ?? ""}`}>
                <div>
                    <label className="form-check-label" htmlFor={id}>
                        <span>{label}</span>
                        {/* <span className="ms-1">{labelIconElement ?? null}</span> */}
                        <div className="fw-normal">{descriptionElement ?? null}</div>
                    </label>
                </div>
                <div className="h-100 d-flex align-items-center ms-1">
                    <input disabled={disabled}
                        onChange={v => onChange(value)}
                        className={`form-check-input ${inputClassName ?? ""}`}
                        type="radio"
                        name={radioGroupName}
                        id={id}
                        defaultChecked={defaultChecked}
                        checked={checked}
                    />
                </div>
            </div>
        ): (
            <div className={customClassName ? customClassName: `form-check radio-check ${className ?? ""}`}>
                <input disabled={disabled}
                    onClick={v => onChange(value)}
                    className={`form-check-input ${inputClassName ?? ""}`}
                    type="radio"
                    name={radioGroupName}
                    id={id}
                    defaultChecked={defaultChecked}
                    checked={checked}
                />
                <label className="form-check-label d-flex align-items-center" htmlFor={id}>
                    <span>{label}</span>
                    <span className="ms-1">{labelIconElement ?? null}</span>
                </label>
                <div className="radio-check--desc">{descriptionElement ?? null}</div>
            </div>
        )
    )
}

export default RadioCheck
