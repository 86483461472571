// @ts-ignore
import React, { useEffect } from "react";
// @ts-ignore
import MetaTags from 'react-meta-tags';
import 'react-phone-input-2/lib/high-res.css';
// @ts-ignore
import { connect, useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { RouteComponentProps, useParams } from "react-router";
// @ts-ignore
import { withRouter } from "react-router-dom";
import {
	Card,
	CardBody,
	Col,
	Container, Row
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { apiError, checkAccount } from "../../../store/actions";
import { openAlert } from "../../../store/alert/actions";
import SingleConfigurationOption from "../../Dashboard/WelcomePanels/SingleConfigurationOption/SingleConfigurationOption";
//@ts-ignore
import SchoolSwapIcon from "../../../assets/images/school_swap.svg"

export interface Props extends RouteComponentProps {
	dashbord_id: string,
	apiError: (err: string) => {},
	history: any,
	error: string,
	user: any,
	onOpenAlert: any,
	returnTranslation: any
}

const EnableSwap = ({ returnTranslation, onOpenAlert, history }: Props) => {
	const dispatch = useDispatch();
	// const [config, setConfig] = useState<any>({})
	const SWAP = "SWAP"

	const onCheckAccount = () => dispatch(checkAccount());
	const accountInfo = useSelector((state: any) => state.Account?.info);
	const detailsFormFilled = useSelector((state: any) => state.Account?.info?.configuration?.details);
	const swap = useSelector((state: any) => state.Account?.info?.configuration?.entitlements?.[SWAP])
	const user = useSelector((state: any) => state.Account?.user);
	
	useEffect(() => {
		onCheckAccount();
	}, [])

	return (
		<React.Fragment>
			<MetaTags>
				<title>
					{user?.role === 'CHARITY' ? returnTranslation("LOOP | Donations Setup - Swap") : null}
					{user?.role === 'SCHOOL' ? returnTranslation("LOOP | LOOP FOR GOOD - Swap") : null}
				</title>
			</MetaTags>
			<div className="page-content">
				<Container fluid>
					{user?.role === 'CHARITY' ? <Breadcrumb title="Donations Setup" breadcrumbItem="Swap" /> : null}
					{user?.role === 'SCHOOL' ? <Breadcrumb title="LOOP FOR GOOD" breadcrumbItem="Swap" /> : null}

					<Row>
						<Col sm={12} md={12} lg={12} xl={8}>
							<Card>
								<CardBody>
									<div className="welcome-panel__steps">
										<SingleConfigurationOption
											header={returnTranslation("Swap")}
											description={user?.role === "SCHOOL" ? "Enable Swap for items donated to your school’s virtual shop." : "Lorem Ipsum"}
											descriptionClassName="m-0"
											// showFilledIcon={false}
											filled={detailsFormFilled && swap?.status === "ACTIVE"}
											tasksOpened={true}
											containerClassName="p-0"
											tasksOptions={[
												{
													name: "Details",
													isActive: detailsFormFilled,
													route: `/details`
												}
											]}
											entitlement={swap}
											entitlementType={SWAP}
											verified={accountInfo?.verified}
											activateCondition={detailsFormFilled}
											returnTranslation={returnTranslation}
											customFilledIconDone={<img className="single-configuration-option--icon" src={SchoolSwapIcon} />}
											customFilledIconUndone={<img className="single-configuration-option--icon" src={SchoolSwapIcon} />}
										/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	)
}

const mapStateToProps = (state: any) => {
	const { error } = state.Login
	const { dashbord_id, user } = state.Account
	const { countries } = state.Countries
	return { error, dashbord_id, user, countries }
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		onApiError: () => apiError(),
		onOpenAlert: ({ text, status }: any) =>
			dispatch(openAlert({ text: text, status: status })),
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EnableSwap)
)
