import React from "react";
import { useHistory } from "react-router-dom";
import {
    Button, Card,
    CardBody, Col, Row
} from "reactstrap";

interface CompleteSetupDevelopersInterface {
    returnTranslation: (name: string) => string,
    headCode: string,
    alignTextLeft?: boolean,
}

const CompleteSetupDevelopers = ({
    returnTranslation,
    headCode,
    alignTextLeft,
}: CompleteSetupDevelopersInterface) => {
    const history = useHistory();
    return (
        <Card>
            <CardBody>
                <div className={alignTextLeft ? "" : "text-center"}>
                    <Row className="justify-content-center">
                        <Col lg={alignTextLeft ? "12" : "10"}>
                            <div className="padding-bottom-custom">
                                <h4 className="mt-4 font-weight-semibold">
                                    1. {returnTranslation("Plugin initialization")}
                                </h4>
                                <p className="text-muted mt-3">
                                    {returnTranslation("Copy below code and paste in the <head> section of HTML document")}
                                </p>
                                <div className="alert alert-secondary fade show" role="alert">
                                    <p className="codeFormat">{headCode && headCode}</p>
                                </div>
                            </div>

                            <div className="padding-bottom-custom">
                                <h4 className="mt-4 font-weight-semibold">
                                    2. {returnTranslation("Add HTML where widget should be generated")}
                                </h4>
                                <p className="text-muted mt-3">
                                    {returnTranslation("Copy below code")}
                                </p>
                                <div className="alert alert-secondary fade show" role="alert">
                                    <p>{`< div id="loop-digital-wardrobe"></div>`}</p>
                                </div>
                            </div>


                            <h4 className="mt-4 font-weight-semibold">
                                3. {returnTranslation("Add product list")}
                            </h4>
                            <p className="text-muted mt-3">
                                {returnTranslation("Send data after purchase")}
                            </p>
                            <div className="alert alert-secondary fade show" role="alert">
                                <p className="codeFormat">{`window.LoopCustomerItems = [
                                {
                                    'name': 'Product Name',
                                    'size': 'Product Size',
                                    'color': 'Product Color',
                                    'brand': 'Product Brand',
                                    'order_number': 'Order Number',
                                    'price': 'Product Price',
                                    'final_price': 'Product Price with discount',
                                    'currency': 'Product Currency e.g. GBP',
                                    'sku': 'Product SKU',
                                    'ean': 'Product EAN',
                                    'buy_at': 'date, e.g. 2021-12-03T12:34:45.026Z',
                                    'category': 'Product Category Tree',
                                    'gender': 'Product gender - Men/Women/Kids',
                                    'type': 'SIMPLE',
                                    'images': ['absolute path to image from store', 'absolute path to image from store', 'absolute path to image from store']
                                }, 
                                {...}, 
                                {...}, ...
                            ];`}
                                </p>
                            </div>

                            <div className=" mt-4">
                                <Button onClick={() => history.push("/plugin/integrations")} color="success">
                                    {/* {params?.uuid ? returnTranslation("Update") : returnTranslation("Add website")} */}
                                    {returnTranslation("Done")}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        </Card>
    )
}

export default CompleteSetupDevelopers
