import React, { useEffect } from "react"
import {
  Col,
  Container,
  Row
} from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { getCharityShops } from "../../../../store/charityShops/actions"
import { openAlert } from "../../../../store/alert/actions"
import BasicTable from "../../../../components/BasicTable/BasicTable"
import CodesTableColumns from "./CodesTableColumns/CodesTableColumns"
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
import { requestConfirmation } from "../../../../store/actions"
import { activePoint, charitiesExport, deletePoint } from "../../../../helpers/backend_helper"

interface DashboardCharityShopsInterface {
  history: any,
  returnTranslation: (name: string) => string
}

const DashboardCharityShops = ({ history, returnTranslation }: DashboardCharityShopsInterface) => {
  const dispatch = useDispatch()
  const data = useSelector((state: any) => state.CharityShops)
  const user = useSelector((state: any) => state.Account?.user)

  const handleDelete = (uuid: string, name: string) => {
    dispatch(
      requestConfirmation(`${returnTranslation("Delete")} ${name}?`, () => confirmDelete(uuid))
    )
  }

  const confirmDelete = async (id: string) => {
    try {
      await deletePoint(user?.role, id)
      dispatch(getCharityShops({page: 1}))
      dispatch(openAlert({ text: returnTranslation("Point deleted."), status: "success" }))
    } catch (e) {
      dispatch(openAlert({ text: returnTranslation("An error occured"), status: "danger" }))
    }
  }

  const handleEdit = (id: string) => {
    history.push(`/charity-shop/edit/${id}`)
  }

  const handleSetActive = async (id: string, active: number) => {
    try {
      await activePoint(user?.role, [id], active)
      dispatch(getCharityShops({ page: 1 }))
      dispatch(openAlert({ text: returnTranslation(`Point ${active ? "activated" : "deactivated"}.`), status: "success" }))
    } catch (e) {
      dispatch(openAlert({ text: returnTranslation("An error occured"), status: "danger" }))
    }
  }
  const handleSetItemActive = async (id: string) => {
    handleSetActive(id, 1)
  }

  const handleSetItemInactive = async (id: string) => {
    handleSetActive(id, 0)
  }

  const downloadFile = (fileName: string, urlData: string) => {
    let aLink = document.createElement('a');
    aLink.download = fileName;
    aLink.href = urlData;
    let event = new MouseEvent('click');
    aLink.dispatchEvent(event);
  }

  const handleExportData = async () => {
    const response = await charitiesExport();
    downloadFile('charity-points-loop.csv', 'data:text/csv;charset=UTF-8,' + encodeURIComponent(response));
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={returnTranslation("Charity Shops")} breadcrumbItem={returnTranslation("List of Charity Shops")} />

          <Row>
            <Col lg="12">
              <BasicTable
                items={data?.charityShops}
                // @ts-ignore
                columns={CodesTableColumns({ returnTranslation, handleDelete, handleEdit, handleSetActive })}
                //@ts-ignore
                addItemBtnText={returnTranslation("Add Shop")}
                addItemBtnAction={() => history.push("/add-charity-shop")}
                addItemBtnText2={returnTranslation("Export data")}
                addItemBtnAction2={() => handleExportData()}
                searchbar={false}
                getAction={getCharityShops}
                totalSize={data?.info?.total_items}
                sizePerPage={data?.info?.page_count}
                currentPage={data?.info?.current_page}
                isLoading={data?.isLoading}
                displaySelectMultiple={true}
                selectMultipleOptions={[
                  {
                    'name': 'Set Active',
                    action: (id: string) => handleSetItemActive(id)
                  },
                  {
                    'name': 'Set Inactive',
                    action: (id: string) => handleSetItemInactive(id)
                  }
                ]}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default DashboardCharityShops
