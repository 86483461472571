import {
  GET_BILLINGS_SUCCESS,
  GET_BILLINGS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  info: []
}

const PaymentInfo = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BILLINGS_SUCCESS:
      return {
        ...state,
        info: action?.payload,
      }
      break
    case GET_BILLINGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      break
    default:
      return state
      break
  }
  return state;
}

export default PaymentInfo
