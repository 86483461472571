import axios from "axios"
import React, { useState } from "react"
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
// @ts-ignore
import { useHistory } from "react-router-dom"
import BasicTable from "./../../components/BasicTable/BasicTable"
import { put } from "./../../helpers/api_helper"
import { deleteWebsiteApi } from "./../../helpers/backend_helper"
import * as url from "./../../helpers/url_helper"
import { openAlert, requestConfirmation } from "./../../store/actions"
import WebsitesTableColumns from "./WebsitesTableColumns/WebsitesTableColumns"

interface WebsitesInterface {
  returnTranslation: (name: string) => string;
}

const Websites = ({returnTranslation }: WebsitesInterface) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const data = useSelector((state: any) => state.websites.websites)
  // const totalItems = useSelector((state: any) => state.websites.totalItems)
  // const perPage = useSelector((state: any) => state.websites.perPage)
  // const page = useSelector((state: any) => state.websites.page)
  const [isLoading, setIsLoading] = useState(true);
  const dashboardID = useSelector((state: any) => state?.Account?.dashbord_id)
  const userToken = useSelector((state: any) => state?.Account?.user?.access_token)
  const [sitesData, setSitesData] = useState({
    items: [],
    info: {
      total_items: 0,
      page_count: 15,
      current_page: 1
    }
  })

  const getWebsites = (page: number = 1, preventLoader = false) => {
    return new Promise(async (resolve, reject) => {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "Client-UUID": dashboardID,
          Authorization: `Bearer ${userToken}`,
          "Custom-User-Agent": window.navigator.userAgent
        },
      };
      if(!preventLoader){
        setIsLoading(true)
      }
      axios
        .get(`${url.API_BASE}sites?page=${page}`, config)
        .then(response => {
          if (response.data) {
            // const { info: { page_count, current_page } } = response.data;
            setSitesData(response.data);
          }

          return resolve(response)
        })
        .catch(error => {
          if (error?.response?.data) {
            // const { message, errors } = error?.response?.data
            // this.props.onOpenAlert({
            //   text: message,
            //   status: "danger",
            // })
          }
          return reject(error)
        }).finally(() => setIsLoading(false))
    })
  }

  const handleDelete = (uuid: string, name: string) => {
    dispatch(
      requestConfirmation(`${returnTranslation("Delete")} ${name} LOOP widget?`, () => confirmDelete(uuid))
    )
  }

  const confirmDelete = async (uuid: string) => {
    try {
      await deleteWebsiteApi({ uuid })
      getWebsites(sitesData?.info?.current_page, true)
      dispatch(openAlert({ text: returnTranslation("Website deleted"), status: "success" }))
    } catch (e) {
      console.log(e)
      dispatch(openAlert({ text: returnTranslation("An error occured"), status: "danger" }))
    }
  }

  const handleEdit = (id: string) => {
    history.push(`/plugin/integrations/edit/${id}`)
  }

  const handlePreview = (id: string) => {
    history.push(`/plugin/integrations/preview/${id}`)
    window.location.reload();
  }

  const handleConfigurationPageRedirect = (id: string) => {
    history.push(`/plugin/integrations/configure/${id}`)
  }

  const displayCopiedAlert = () => {
    return dispatch(openAlert({ text: returnTranslation("Copied"), status: "success" }))
  }

  const handleUpdateWebsite = async (testMode: boolean, active: boolean, siteId: number, siteName: string, siteUrl: string) => {
    return new Promise(async (resolve, reject) => {
      //   const { selectedCountryCode, isCharity, role } = this.state

      //   const {
      //     email,
      //   } = values;

      put(`${url.API_BASE}sites/${siteId}`, {
        test_mode: testMode,
        active: active,
        name: siteName,
        url: siteUrl
      })
        .then(response => {
          if (response?.status) {
            getWebsites(sitesData.info?.current_page, true)
            dispatch(openAlert({ text: response?.status, status: "success" }))
          }

          return resolve(response)
        })
        .catch(error => {
          if (error?.response?.data) {
            const { message, errors } = error?.response?.data;
            // this.setState({ formErrors: errors });
            dispatch(openAlert({ text: message, status: "danger" }))
          }
          return reject(error)
        })
    })
  }

  return (
    // <React.Fragment>
    //   <div className="page-content">
    //     <MetaTags>
    //       <title>LOOP | Websites</title>
    //     </MetaTags>
    //     <Container fluid>
    //       <Breadcrumbs
    //         title={returnTranslation("Pages")}
    //         breadcrumbItem={returnTranslation("Websites")}
    //       />
          <BasicTable
            items={sitesData.items}
            columns={WebsitesTableColumns({
              offsetIndex: ((sitesData?.info?.current_page - 1) * sitesData?.info?.page_count),
              handleDelete,
              handleEdit,
              handleConfigurationPageRedirect,
              handlePreview,
              returnTranslation,
              displayCopiedAlert,
              handleUpdateWebsite
            })}
            searchbar={false}
            // getAction={getWebsites}
            getFunction={(page: number) => getWebsites(page)}
            totalSize={sitesData.info?.total_items}
            sizePerPage={sitesData.info?.page_count}
            currentPage={sitesData.info?.current_page}
            isLoading={isLoading}
          />


    //     </Container>
    //   </div>
    // </React.Fragment>
  )
}

export default Websites
