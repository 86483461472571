import BasicTable from "./../../components/BasicTable/BasicTable"
import Breadcrumbs from "./../../components/Common/Breadcrumb"
import { deleteWebsiteApi } from "./../../helpers/backend_helper"
import React, { useEffect, useState } from "react"
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
// @ts-ignore
import { RouteComponentProps, withRouter } from "react-router-dom"
// @ts-ignore
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row, Container, UncontrolledTooltip } from "reactstrap"
import { getWebsites, openAlert, requestConfirmation, getPaymentInfo, getBillings, getInvoices, changePreloader } from "./../../store/actions"
// import WebsitesTableColumns from "./WebsitesTableColumns/WebsitesTableColumns"
import { post } from "./../../helpers/api_helper"
import * as url from "./../../helpers/url_helper"

interface BillingsInterface extends RouteComponentProps {
    history: any,
    returnTranslation: (name: string) => string
}

const Billings = ({ history, returnTranslation }: BillingsInterface) => {
    const dispatch = useDispatch()

    const billingsInfo = useSelector((state: any) => state.Billings.info)
    const invoices = useSelector((state: any) => state.Invoices.info)

    const [endDate, setEndDate] = useState("")

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        let date = new Date(`${billingsInfo?.plan_details?.current_period_end ? billingsInfo?.plan_details?.current_period_end : billingsInfo?.plan_details?.trial_ends_at ? billingsInfo?.plan_details?.trial_ends_at : ""}`)
        setEndDate(date.toDateString())
    }, [billingsInfo])

    const init = () => {
        dispatch(getPaymentInfo())
        dispatch(getBillings())
        dispatch(getInvoices())
    }

    const handleCancelPlan = (id: number) => {
        return new Promise(async (resolve, reject) => {
            dispatch(changePreloader(true))
            post(`${url.API_BASE}subscribes/${id}/cancel`, {
                "now": true
            })
                .then(response => {
                    if (response?.status) {
                        dispatch(openAlert({ text: response?.status, status: "success" }))
                        init()
                    }

                    dispatch(changePreloader(false))
                    return resolve(response)
                })
                .catch(error => {
                    dispatch(changePreloader(false))
                    if (error?.response?.data) {
                        const { message, errors } = error?.response?.data;
                        dispatch(openAlert({ text: message, status: "danger" }))
                    }
                    return reject(error)
                })
        })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>LOOP | Billings</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs
                        title={returnTranslation("LOOP")}
                        breadcrumbItem={returnTranslation("Billing")}
                    />
                    <Row>
                        <React.Fragment>
                            <Col xl="6" sm="6" className="billing">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg="11" sm="10" xs="10">
                                                <h4 className="mb-3 fw-bold">{returnTranslation("Plan details")}</h4>
                                                <h6 className="fw-bold">{billingsInfo?.plan_details?.name} ({billingsInfo?.plan_details?.active ? billingsInfo?.plan_details?.active : "No plan choosed"})</h6>
                                                <p>{returnTranslation("Ends on")} {endDate && endDate !== "Invalid Date" ? endDate : "-"}</p>
                                            </Col>

                                            <Col lg="1" sm="2" xs="2">
                                                <i className="bx bx-shopping-bag h2 text-primary" />
                                            </Col>
                                        </Row>
                                        <Row className="billing__bottom pt-3">
                                            <Col lg="3">
                                                <Link to="/change-plan" className="fw-medium auth-agreement-link">
                                                    {returnTranslation("Change Plan")}
                                                </Link>
                                            </Col>
                                            {/* <Col lg="4">
                                                <Link to="#" className="fw-medium auth-agreement-link">
                                                    {returnTranslation("Switch to annual plan")}
                                                </Link>
                                            </Col> */}
                                            <Col lg="3">
                                                <Link to="#" onClick={() => handleCancelPlan(billingsInfo?.plan_details?.id)} className="fw-medium auth-agreement-link">
                                                    {returnTranslation("Cancel plan")}
                                                </Link>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </React.Fragment>

                        {/* <React.Fragment>
                            <Col xl="6" sm="6" className="billing">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg="11" sm="10" xs="10">
                                                <h4 className="mb-3 fw-bold">{returnTranslation("Payment Details")}</h4>
                                                <h6 className="fw-bold">{billingsInfo?.payment_info?.card?.brand} {returnTranslation("ending in")} {billingsInfo?.payment_info?.card?.last4}</h6>
                                                <p>{returnTranslation("Your VAT number is")} {billingsInfo?.vatin}</p>
                                            </Col>

                                            <Col lg="1" sm="2" xs="2">
                                                <i className="bx bx-credit-card h2 text-primary" />
                                            </Col>
                                        </Row>
                                        <Row className="billing__bottom pt-3">
                                            <Col lg="3">
                                                <Link to="/change-payment-info" className="fw-medium auth-agreement-link">
                                                    {returnTranslation("Edit")}
                                                </Link>
                                            </Col>
                                            <Col lg="5">
                                                <Link to="/change-vat-id" className="fw-medium auth-agreement-link">
                                                    {returnTranslation("Change VAT number")}
                                                </Link>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </React.Fragment> */}

                        {/* <React.Fragment>
                            <Col xl="6" sm="6" className="billing">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg="11" sm="10" xs="10">
                                                <h4 className="mb-3 fw-bold">{returnTranslation("Billing owner")}</h4>
                                                <h6 className="fw-bold">{billingsInfo?.user?.first_name} {billingsInfo?.user?.last_name}</h6>
                                                <p>{billingsInfo?.user?.email}</p>
                                            </Col>

                                            <Col lg="1" sm="2" xs="2">
                                                <i className="bx bx-user-circle h2 text-primary" />
                                            </Col>
                                        </Row>
                                        <Row className="billing__bottom pt-3">
                                            <Col lg="12">
                                                <Link to="/change-billing-owner" className="fw-medium auth-agreement-link">
                                                    {returnTranslation("Change billing owner")}
                                                </Link>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </React.Fragment> */}

                        <React.Fragment>
                            <Col xl="6" sm="6" className="billing">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg="11" sm="10" xs="10">
                                                <h4 className="mb-3 fw-bold">{returnTranslation("Invoices")}</h4>
                                                <h6 className="fw-bold">Amount: {invoices?.length}</h6>
                                                <p></p>
                                            </Col>

                                            <Col lg="1" sm="2" xs="2">
                                                <i className="bx bx-file-blank h2 text-primary" />
                                            </Col>
                                        </Row>
                                        <Row className="billing__bottom pt-3">
                                            <Col lg="4">
                                                <Link to="/invoices" className="fw-medium auth-agreement-link">
                                                    {returnTranslation("View latest")}
                                                </Link>
                                            </Col>
                                            <Col lg="4">
                                                <Link to="/invoices" className="fw-medium auth-agreement-link">
                                                    {returnTranslation("Invoice history")}
                                                </Link>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </React.Fragment>
                    </Row>

                    <Row>
                        <React.Fragment>
                            <Col xl="6" sm="6" className="billing">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg="11" sm="10" xs="10">
                                                <h4 className="mb-3 fw-bold">{returnTranslation("Contact support")}</h4>
                                                <p>{returnTranslation("For help and support with your billing questions, contact our support team and they'll be happy to help")}</p>
                                            </Col>

                                            <Col lg="1" sm="2" xs="2">
                                                <i className="bx bx-dice-5 h2 text-primary" />
                                            </Col>
                                        </Row>
                                        <Row className="billing__bottom pt-3">
                                            <Col lg="12">
                                                <Link to="#" className="fw-medium auth-agreement-link">
                                                    {returnTranslation("Contact Support")}
                                                </Link>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </React.Fragment>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(Billings)
