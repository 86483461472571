import {
  GET_PAYMENT_INFO,
  GET_PAYMENT_INFO_SUCCESS,
  GET_PAYMENT_INFO_FAIL
} from "./actionTypes"

export const getPaymentInfo = () => ({
  type: GET_PAYMENT_INFO,
})

export const getPaymentInfoSuccess = info => ({
  type: GET_PAYMENT_INFO_SUCCESS,
  payload: info,
})

export const getPaymentInfoFail = error => ({
  type: GET_PAYMENT_INFO_FAIL,
  payload: error,
})