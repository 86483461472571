import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"
import { API_BASE } from "./url_helper"

//pass new generated access token here
const token = accessToken

//apply base url for axios
// const API_URL = ""

const axiosApi = axios.create({
  baseURL: API_BASE,
})

const getDefaultConfig = () => {
  return {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Client-UUID": "9fde47f3-c8f8-46a8-8bed-a587032d9aa0",
      "Authorization": `Bearer ${localStorage.authUser
        ? JSON.parse(localStorage.authUser).access_token
        : ""
        }`,
      "Custom-User-Agent": window.navigator.userAgent
    },
  }
}

// console.log(["getDefaultConfig", getDefaultConfig()])

const logoutUser = (err) => {
  if (err?.response?.data?.message === "Unauthenticated.") {
    localStorage.setItem("authUser", "")
  }
}

// axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = getDefaultConfig()) {
  // console.log("get", url, config)
  return new Promise(async (resolve, reject) => {
    await axiosApi
      .get(url, { ...config })
      .then(response => resolve(response.data))
      .catch(e => {
        logoutUser(e)
        reject(e)
      })
  })
}

export async function post(url, data, config = getDefaultConfig()) {
  // console.log(["post", url, data, config])
  return new Promise(async (resolve, reject) => {
    axiosApi
      .post(url, { ...data }, { ...config })
      .then(response => resolve(response.data))
      .catch(e => {
        logoutUser(e)
        reject(e)
      })
  })
}

export async function put(url, data, config = getDefaultConfig()) {
  // console.log("put", url, data, config)
  return new Promise(async (resolve, reject) => {
    axiosApi
      .put(url, { ...data }, { ...config })
      .then(response => resolve(response.data))
      .catch(e => {
        logoutUser(e)
        reject(e)
      })
  })
}

export async function del(url, config = getDefaultConfig()) {
  // console.log("del", url, config)
  return new Promise(async (resolve, reject) => {
    await axiosApi
      .delete(url, { ...config })
      .then(response => resolve(response.data))
      .catch(e => {
        logoutUser(e)
        reject(e)
      })
  })
}
