//Include Both Helper File with needed methods
import { getPoints } from "./../../helpers/backend_helper"
import { call, put, takeEvery, select } from "redux-saga/effects"
import { getCharityShopsFail, getCharityShopsSuccess } from "./actions"
// Ecommerce Redux States
import { GET_CHARITY_SHOPS } from "./actionTypes"

function* getCharityShopsAsync({ payload }) {
  try {
    const role = yield select(state => state?.Account?.user?.role)
    const response = yield call(() => getPoints(role, payload?.page))
    yield put(getCharityShopsSuccess(response))
  } catch (error) {
    yield put(getCharityShopsFail(error))
  }
}

function* charityShopsSaga() {
  yield takeEvery(GET_CHARITY_SHOPS, getCharityShopsAsync)
}

export default charityShopsSaga
