import {
  GET_CHARITY_SHOPS,
  GET_CHARITY_SHOPS_FAIL,
  GET_CHARITY_SHOPS_SUCCESS,
} from "./actionTypes"

export const getCharityShops = (payload) => ({
  type: GET_CHARITY_SHOPS,
  payload
})

export const getCharityShopsSuccess = codes => ({
  type: GET_CHARITY_SHOPS_SUCCESS,
  payload: codes,
})

export const getCharityShopsFail = error => ({
  type: GET_CHARITY_SHOPS_FAIL,
  payload: error,
})
