import React, { useEffect, useState } from 'react'
import Switch from 'react-switch'
import { Col, Label, Row } from 'reactstrap'
import OffSymbol from '../../../../../components/Common/OffSymbol';
import OnSymbol from '../../../../../components/Common/OnSymbol';

interface Props {
    item: any;
    handleExcludeFromDiscovery: (itemId: number, exclude: boolean, showMessage?: boolean) => Promise<boolean | undefined>;
    handleExclude: (itemId: number, exclude: boolean) => Promise<boolean | undefined>;
    setFoundErrors: any;
}

function FeederGridItem({ item, handleExcludeFromDiscovery, handleExclude, setFoundErrors }: Props) {
    const [discoveryActive, setDiscoveryActive] = useState(false);
    const [active, setActive] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        setDiscoveryActive(!item?.exclude_discovery)
        setActive(!item?.excluded)
    }, [item?.excluded, item?.status])

    const isValueCorrect = (value: any) => {
        if(value === null || value === undefined || value === ""){
            return false
        }
        return true
    }
    
    useEffect(() => {
        if(item?.item){
            const { id, name, slug, image, resell } = item?.item;
            if(!isValueCorrect(id) || !isValueCorrect(name) || !isValueCorrect(slug) || !isValueCorrect(image) || !isValueCorrect(resell)){
                setError(true);
                setFoundErrors(true)
            }
        }
    }, [item?.item])

    return (
        <div className="feeder-grid--item">
            <div className={`feeder-grid--item__content-wrapper ${active ? 'active' : ''} ${error ? 'border-error' : ''}`}>
                <img src={item?.image} />
                <div className={`feeder-grid--item__footer`}>
                    <Row className="mb-2 w-100">
                        <Col sm={8} className="d-flex align-items-center">
                            <div>Discovery</div>
                        </Col>
                        <Col sm={4}>
                            <Switch
                                uncheckedIcon={<OffSymbol />}
                                checkedIcon={<OnSymbol />}
                                onColor="#AEE8B3"
                                onChange={async () => {
                                    const prevActiveDiscovery = discoveryActive;
                                    setDiscoveryActive(!discoveryActive)

                                    const isSuccesDiscovery = await handleExcludeFromDiscovery(item?.id, discoveryActive)
                                    !isSuccesDiscovery && setDiscoveryActive(prevActiveDiscovery)
                                }}
                                checked={discoveryActive}
                            />
                        </Col>
                        
                    </Row>
                    <Row className="w-100">
                        <Col sm={8} className="d-flex align-items-center">
                            <div>Active</div>
                        </Col>
                        <Col sm={4}>
                            <Switch
                                uncheckedIcon={<OffSymbol />}
                                checkedIcon={<OnSymbol />}
                                onColor="#AEE8B3"
                                onChange={async () => {
                                    const prevActive = active;
                                    const prevActiveDiscovery = discoveryActive;

                                    if(active && discoveryActive){
                                        setDiscoveryActive(false)
                                    }
                                    setActive(!active)

                                    const isSuccess = await handleExclude(item?.id, prevActive)
                                    if(isSuccess && prevActive && prevActiveDiscovery){
                                        const isSuccesDiscovery = await handleExcludeFromDiscovery(item?.id, true, false)
                                        //if not isSuccess -> set state back to previous values
                                        !isSuccesDiscovery && setDiscoveryActive(prevActiveDiscovery)
                                    }
                                    //if not isSuccess -> set state back to previous values
                                    !isSuccess && setActive(prevActive)
                                    !isSuccess && setDiscoveryActive(prevActiveDiscovery)
                                }}
                                checked={active}
                            />
                        </Col>
                    </Row>

                </div>
            </div>
        </div>
    )
}

export default FeederGridItem
