import axios from "axios";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
//@ts-ignore
import { connect } from "react-redux";
// @ts-ignore
import { RouteComponentProps } from "react-router";
import {
  Button,
  Card, Col, Form,
  FormGroup,
  Label,
  Row,
  Spinner
} from "reactstrap";
import * as url from "../../../helpers/url_helper";
import { apiError, openAlert } from "../../../store/actions";
import LiVerticalTimeline from "../../../components/LiVerticalTimeline/LiVerticalTimeline";
import plusGreenBg from "../../../assets/images/plus-green-bg.svg"

export interface ImportCodesPropsInterface {
  history: any,
  dashbord_id?: string,
  userToken?: string,
  onOpenAlert?: any,
  noDropzoneColumnsLayout?: boolean,
  couponId?: string;
  returnTranslation: (name: string) => string,
  callbackAfterSubmitCodes?: any;
}

export interface ImportCodesStateInterface {
  isLoading: boolean,
  selectedFiles: {
    name: string,
    lastModified: number,
    lastModifiedDate: any,
    path: string,
    size: number,
    type: string,
    webkitRelativePath: string,
  },
  steps: any[]
}

class ImportCodes extends Component<ImportCodesPropsInterface, ImportCodesStateInterface> {
  constructor(props: ImportCodesPropsInterface) {
    super(props)
    this.state = {
      isLoading: false,
      selectedFiles: {
        name: "",
        lastModified: 0,
        lastModifiedDate: "",
        path: "",
        size: 0,
        type: "",
        webkitRelativePath: "",
      },
      steps: [
        {
          id: 1,
          stausTitle: this.props.returnTranslation("Download template"),
          iconClass: "bx-package",
          description: this.props.returnTranslation(`Download`),
        },
        {
          id: 2,
          stausTitle: this.props.returnTranslation("Fill data"),
          iconClass: "bx-copy-alt",
          description: this.props.returnTranslation("Add your codes to file"),
        },
        {
          id: 3,
          stausTitle: this.props.returnTranslation("Upload"),
          iconClass: "bx-badge-check",
          description: this.props.returnTranslation("Upload file in the form below"),
        },
      ]
    }
  }

  handleAcceptedFiles = (files: any) => {
    this.setState({ selectedFiles: files[0] })
  }

  formatBytes = (bytes: any, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  handleSubmit = () => {
    return new Promise(async (resolve, reject) => {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "Client-UUID": this.props?.dashbord_id,
          Authorization: `Bearer ${this.props.userToken}`,
          "Custom-User-Agent": window.navigator.userAgent
        },
      }

      const { selectedFiles } = this.state;
      const formData = new FormData()
      // console.log(['selected', selectedFiles])

      // @ts-ignore
      formData.append("csv_file", selectedFiles)
      formData.append("delimiter", ',')

      let apiUrl = 'retailers/coupons/csv';
      if (this.props.couponId) {
        apiUrl = `retailers/coupons/${this.props.couponId}/codes/csv`
      }
      this.setState({ isLoading: true })

      axios
        .post(`${url.API_BASE}${apiUrl}`, formData, config)
        .then(response => {
          this.props.onOpenAlert({ text: this.props.returnTranslation("Success"), status: "success" })
          // this.props.history.push(`/coupons`)
          if (this.props.callbackAfterSubmitCodes) {
            this.props.callbackAfterSubmitCodes()
          }

          return resolve(response)
        })
        .catch(error => {
          // console.log(['error', error]);
          if (error?.response?.data) {
            const { message, errors } = error?.response?.data
            this.props.onOpenAlert({
              text: message,
              status: "danger",
            })
          }
          return reject(error)
        }).finally(() => {
          this.setState({ isLoading: false })
        })
    })
  }

  render() {
    const { returnTranslation, noDropzoneColumnsLayout } = this.props;

    return (
      <>
        {/* <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={returnTranslation("Codes")} breadcrumbItem={returnTranslation("Add new codes")} />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody> */}
        {this.state.steps.map((status, key) => (
          <LiVerticalTimeline
            status={status}
            key={"_status_" + key}
            returnTranslation={returnTranslation}
            fileUrl="https://dashboard-dev.loopdigitalwardrobe.com/assets/coupons.csv"
          />
        ))}

        <FormGroup className="mb-4" row>
          <Label className={!noDropzoneColumnsLayout ? "col-form-label col-lg-2" : ""}>
            {returnTranslation("Attached Files")}
          </Label>
          <Col lg={!noDropzoneColumnsLayout ? "10" : "12"}>
            <Form>
              <Dropzone
                onDrop={acceptedFiles =>
                  this.handleAcceptedFiles(acceptedFiles)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div
                      className="dz-message needsclick"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="dz-message needsclick">
                        {/* <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div> */}
                        <img src={plusGreenBg} />
                        <h4>{returnTranslation("Drop files here or click to upload.")}</h4>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
              <div
                className="dropzone-previews mt-3"
                id="file-previews"
              >
                {this.state?.selectedFiles &&
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={`${this.state?.selectedFiles?.name}`}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">

                        <Col>
                          {this.state?.selectedFiles?.name}
                        </Col>
                      </Row>
                    </div>
                  </Card>
                }
              </div>
            </Form>
          </Col>
        </FormGroup>
        <Row className={!noDropzoneColumnsLayout ? "justify-content-end" : ""}>
          <Col lg="10">
            <Button onClick={this.handleSubmit} color="primary" disabled={this.state.isLoading}>
              {this.state.isLoading ? returnTranslation("Uploading CSV") : returnTranslation("Add Codes")}
              {this.state.isLoading ? <Spinner className="ms-1" style={{height: 14, width: 14}} /> : null}
            </Button>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const { error } = state.Login
  const { dashbord_id, user: { access_token } } = state.Account
  return { error, dashbord_id, userToken: access_token }
}

const mapDispatchToProps = (dispatch: any) => ({
  // @ts-ignore
  onGetCodes: (page: number) => dispatch(getCodes(page)),
  onOpenAlert: (msg: string, status: string) =>
    dispatch(openAlert({ text: msg, status: status })),
  onApiError: (error: string) => dispatch(apiError({ error })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(ImportCodes)
