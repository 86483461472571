import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";

interface IBreadcrumbsProps extends RouteComponentProps {
  history: any;
  match: any;
  t: any;
  breadcrumbItem?: string;
  breadcrumbItems?: string[];
  title: string;
  totalItems?: string | number;
  refreshAction?: any;
}

const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({
  breadcrumbItem,
  breadcrumbItems,
  title,
  history,
  t,
  match,
  totalItems,
  refreshAction
}) => {
  const [parentUrl, setParentUrl] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    const { url } = match;
    const parentUrl = "/" + url.split("/")[1];
    setParentUrl(parentUrl);
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              {/* {refreshAction ? (
                <i className="me-2 clickable bx bx-revision breadcrumbs-refresh-spinner"
                  onClick={() => dispatch(refreshAction())}
                />
              ): null} */}
              <h4 className="mb-0 mt-1 font-size-18">
                <span>{breadcrumbItem
                  ? t(breadcrumbItem)
                  : breadcrumbItems &&
                    t(breadcrumbItems[breadcrumbItems?.length - 1])
                }</span>
                {/* {totalItems ? <small className="ms-2 text-muted">{t("Results -")} {totalItems}</small> : null} */}
              </h4>
            </div>
            <div className="page-title-right">
              <Breadcrumb listClassName="m-0">
                <BreadcrumbItem>
                  {breadcrumbItem ? (
                    <Link to={parentUrl}>{t(title)}</Link>
                  ) : (
                    <span>{t(title)}</span>
                  )}
                </BreadcrumbItem>

                {breadcrumbItem && (
                  <BreadcrumbItem active>
                    <span>{t(breadcrumbItem)}</span>
                  </BreadcrumbItem>
                )}

                {breadcrumbItems?.map((item, index) => (
                  <BreadcrumbItem
                    key={index}
                    active={index + 1 === breadcrumbItems.length}
                  >
                    {index === 0 ? (
                      <Link to="#" onClick={index === 0 ? () => history.go(-2) : undefined}>
                        {t(item)}
                      </Link>
                    ) : (
                      <span>{t(item)}</span>
                    )}
                  </BreadcrumbItem>
                ))}
              </Breadcrumb>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(Breadcrumbs));
