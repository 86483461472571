// @ts-ignore
import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import 'react-phone-input-2/lib/high-res.css'
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router"
import FormInput from "../../../../components/Common/FormInput"

export interface RegisterDetailsPropsInterface {
    t: any;
    handleSubmit: (data: any) => void;
    stepData?: any;
    submitBtnText: string;
    formErrors: any;
    checkStepDataAsync?: boolean;
}

const RegisterContactForm = ({
    handleSubmit,
    stepData,
    submitBtnText,
    formErrors,
    checkStepDataAsync
}: RegisterDetailsPropsInterface) => {
    const location: any = useLocation();
    const dispatch = useDispatch();
    const onGetCountries = () => dispatch({ type: "GET_COUNTRIES" })
    // const { user, registrationError, loading, dashbord_id } = useSelector((state: any) => state?.Account)
    const { countries } = useSelector((state: any) => state?.Countries)

    const [phone, setPhone] = useState("");

    useEffect(() => {
        if(!countries?.length){
            onGetCountries()
        }
    }, [])

    const handleValidSubmit = async (event: any, values: any) => {
        event.preventDefault()

        return new Promise(async (resolve, reject) => {

            const {
                firstname,
                lastname,
                // email,
            } = values;

            const data = {
                firstname,
                lastname,
                phone,
                // email,
                code: location?.state?.code,
            };

            handleSubmit(data)
        })
    }

    useEffect(() => {
        if(stepData){
            setPhone(stepData?.phone ? stepData?.phone : "")
        }
    }, checkStepDataAsync ? [stepData] : [])

    return (
        <>
            <AvForm
                className="form-horizontal"
                onValidSubmit={handleValidSubmit}
                // onSubmit={handleValidSubmit}
            >
                <div className="row">
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <AvField
                                name="firstname"
                                label="First Name"
                                className="form-control"
                                placeholder="Enter first name"
                                type="text"
                                required
                                value={stepData?.firstname}
                            />
                        </div>

                    </div>
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <AvField
                                name="lastname"
                                label="Last Name"
                                className="form-control"
                                placeholder="Enter last name"
                                type="text"
                                required
                                value={stepData?.lastname}
                            />
                        </div>
                    </div>
                </div>

                <div className="mb-3">
                    <div className="form-group">
                        <label>Phone</label>
                        <FormInput
                            className=""
                            name="phone"
                            type="phone"
                            placeholder="+44 7987 654321"
                            value={phone ? phone : ""}
                            onChange={phone => setPhone(phone)}
                            formErrors={formErrors}
                            showErrors={true}
                        />
                    </div>
                </div>

                {/* <div className="mb-3">
                    <AvField
                        name="email"
                        label="Email"
                        className="form-control"
                        placeholder="Enter email"
                        type="text"
                        required
                    />
                </div> */}

                <div className="mt-5 d-grid">
                    <button
                        className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
                        type="submit"
                    >
                        {submitBtnText}
                    </button>
                </div>
            </AvForm>
        </ >
    )
}

export default withTranslation()(RegisterContactForm);
