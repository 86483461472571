import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_PAYMENT_INFO
} from "./actionTypes"
import {
  getPaymentInfoFail,
  getPaymentInfoSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getPaymentInfo
} from "./../../helpers/backend_helper"

function* getPaymentInfoAsync() {
  try {
    const response = yield call(getPaymentInfo)
    // console.log(["getPaymentInfoAsync", response])
    yield put(getPaymentInfoSuccess(response))
  } catch (error) {
    yield put(getPaymentInfoFail(error))
  }
}

function* paymentInfoSaga() {
  yield takeEvery(GET_PAYMENT_INFO, getPaymentInfoAsync)
}

export default paymentInfoSaga
