import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_BILLINGS
} from "./actionTypes"
import {
  getBillingsFail,
  getBillingsSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getBillings
} from "./../../helpers/backend_helper"

function* getBillingsAsync() {
  try {
    const response = yield call(getBillings)
    // console.log(["getBillingsAsync", response])
    yield put(getBillingsSuccess(response))
  } catch (error) {
    yield put(getBillingsFail(error))
  }
}

function* billingsSaga() {
  yield takeEvery(GET_BILLINGS, getBillingsAsync)
}

export default billingsSaga
