import { CLEAR_LOCAL_STORES, GET_LOCAL_STORES, GET_LOCAL_STORES_FAIL, GET_LOCAL_STORES_SUCCESS, SET_LOCAL_STORES_QUERY_OBJECT } from "./actionTypes"

const INIT_QUERY_OBJECT = { sort: 'created_at', direction: 'desc' }
const INIT_STATE = {
  items: [],
  total_items: 0,
  isLoading: true,
  initialQueryObject: INIT_QUERY_OBJECT,
  queryObject: INIT_QUERY_OBJECT,
  takebackId: undefined
}

const LocalStores = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOCAL_STORES:
      return {
        ...state,
        isLoading: action?.payload?.preventLoader ? false : true,
        takebackId: action?.payload?.takebackId
      }

    case GET_LOCAL_STORES_SUCCESS:
      return {
        ...state,
        items: action?.payload?.items,
        ...action?.payload?.info,
        // total: action ?.payload ?.info ?.total_pages,
        // page: action ?.payload ?.info ?.current_page,
        // perPage: action ?.payload ?.info ?.page_count,
        // totalItems: action ?.payload ?.info ?.total_items,
        isLoading: false
        // current_page: 1
        // page_count: 15
        // total_items: 15
        // total_pages: 1
      }

    case GET_LOCAL_STORES_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      }

    case CLEAR_LOCAL_STORES:
      return {
        ...INIT_STATE,
        ...(action?.payload?.remainFilters ? {
          ...state,
          items: []
        } : INIT_STATE)
      }

    case SET_LOCAL_STORES_QUERY_OBJECT:
      return {
        ...state,
        queryObject: action.payload.queryObject
      }

    default:
      return state
  }
}

export default LocalStores
