import React from "react"
import "./ConnectPaypal.scss"

interface Props {
	paymentMethods?: any[],
	onClick: (type: string) => void,
	returnTranslation: (text: string) => string,
	disconnect?: boolean
}

function ConnectPaypal({
	paymentMethods,
	onClick,
	returnTranslation,
	disconnect
}: Props) {
	return (
		<div className="loop__widget__connect-stripe">
			{/* <ReactSVG className="loop__widget__connect-stripe--image" src={InstagramIconGray} /> */}
			<div className="loop__widget__connect-stripe--title">
				{disconnect ? returnTranslation("Disconnect your PayPal Account") : returnTranslation("Connect your PayPal Account")}
			</div>
			<div className="loop__widget__connect-stripe--subtitle">
				{returnTranslation("Loop Digital Wardrobe partners with PayPal to charge your company for the subscription plan you chose")}
			</div>

			{/* {paymentMethods?.length && paymentMethods.filter((item) => item.name === "stripe") && ( */}
			<button
				className="loop__widget__connect-stripe--btn--paypal clickable"
				onClick={() => onClick("connect")}
			>
				{disconnect ? returnTranslation("DISCONNECT") : returnTranslation("CONNECT")}
			</button>
			{/* )} */}

			<div className="loop__widget__connect-stripe--btn-caption">
				{disconnect ? null : returnTranslation("You will be redirected to PayPal to complete the onboarding process")}
			</div>
		</div>
	)
}

export default ConnectPaypal
