// @ts-ignore
import { AvForm } from "availity-reactstrap-validation"
import React, { Component, useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
// @ts-ignore
import MetaTags from 'react-meta-tags'
import { connect, useDispatch, useSelector } from "react-redux"
import { RouteComponentProps } from "react-router"
import { Link } from "react-router-dom"
import {
    Alert,
    Card,
    CardBody,
    Col,
    Container, Row
} from "reactstrap"
import RadioCheck from "../../../components/Common/RadioCheck"
import { get, post } from "../../../helpers/api_helper"
import {
    apiError, changePreloader, getRegions, registerUserFailed,
    registerUserSuccessful
} from "../../../store/actions"
import { openAlert } from "../../../store/alert/actions"
import * as url from "../../../helpers/url_helper"


export interface RegisterRegionPropsInterface extends RouteComponentProps {
    history: any,
    error: string,
    t: any,
    location: any,
}

export interface RegisterRegionStateInterface {
    formErrors: any,
    role: string
}

const RegisterRegion = ({ 
    history,
    error,
    t,
    location,
 }: RegisterRegionPropsInterface) => {
     const dispatch = useDispatch();
    const [formErrors, setFormErrors] = useState({});
    const [selectedRegion, setSelectedRegion] = useState<any>({});
    const { user, registrationError, loading, dashbord_id } = useSelector((state: any) => state.Account)
    const { countries } = useSelector((state: any) => state.Countries)
    const regions = useSelector((state: any) => state.Dictionaries?.regions?.items)

    const onApiError = (message: string) => dispatch(apiError(message));
    const onRegisterUserFailed = (message: string) => dispatch(registerUserFailed(message));
    const onRegisterUserSuccessful = (user: any) => dispatch(registerUserSuccessful(user));
    const onGetCountries = () => dispatch({ type: "GET_COUNTRIES" });
    const onOpenAlert = ({ text, status }: any) => dispatch(openAlert({ text: text, status: status }));
    const onChangePreloader = (value: boolean) => dispatch(changePreloader(value))
    const onGetRegions = () => dispatch(getRegions())

    useEffect(() => {
        onApiError("")
        onRegisterUserFailed("")
        onGetRegions()
        // onGetCountries()
        // setSelectedRegion(regions?.[0]);
    }, [])

    useEffect(() => {
        if(regions?.length){
            if(location?.state?.region_id){
                setSelectedRegion(regions?.find((region: any) => region?.id === location?.state?.region_id))
            }else{
                getStepData(3)
            }
        }
    }, [regions])

    const handleValidSubmit = async (event: any) => {
        event.preventDefault()
        setFormErrors({})

        if(selectedRegion){
            history.push("/register-role", {
                code: location?.state?.code,
                email: location?.state?.email,
                region_id: selectedRegion?.id,
                // role: role?.toUpperCase(),
                password: location?.state?.password
            })
        }else{

        }

        // const { role } = state;
        // if(!role){
        //     return onOpenAlert({
        //         text: t("Choose role to proceed."),
        //         status: "danger",
        //     })
        // }

        // return new Promise(async (resolve, reject) => {
        //     onChangePreloader(true)

        //     post(`${url.API_BASE}dashboard/register/3`, {
        //         code: location?.state?.code,
        //         // role: role?.toUpperCase()
        //     })
        //     .then(response => {
        //         if (response?.status) {
        //             // onOpenAlert({
        //             //     text: response?.message,
        //             //     status: "success",
        //             // })
        //             history.push("/register-contact", {
        //                 code: location?.state?.code,
        //                 email: location?.state?.email,
        //                 // role: role?.toUpperCase(),
        //                 password: location?.state?.password
        //             })
        //         }

        //         onChangePreloader(false)
        //         return resolve(response)
        //     })
        //     .catch(error => {
        //         if (error?.response?.data) {
        //             const { message, errors } = error?.response?.data;
        //             setFormErrors(errors)
        //             onOpenAlert({
        //                 text: message,
        //                 status: "danger",
        //             })
        //         }
        //         onChangePreloader(false)
        //         return reject(error)
        //     })
        // })
    }

    const getStepData = async (step: number) => {
        if(!location?.state?.code){
            return;
        }
        try{
            onChangePreloader(true)

            const response = await get(`${url.API_BASE}dashboard/register/step?code=${location?.state?.code}&step=${step}`)
            if(!!response?.data?.region_id){
                setSelectedRegion(regions?.find((region: any) => region?.id === response?.data?.region_id));
            }
        }catch(e){

        }finally{
            onChangePreloader(false)

        }
    }

    return (
        <>
            <MetaTags>
                <title>{t("LOOP | Register")}</title>
            </MetaTags>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="bx bx-home h2" />
                </Link>
            </div>
            <div className="account-pages my-2 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={7} xl={6}>
                            <Card className="overflow-hidden">
                                <Row>
                                    <Col className="col-7">
                                        <div className="p-4 pe-0">
                                            <h1>{t("Sign up")}</h1>
                                            {/* <h1>{t("I am the person representing")}</h1> */}
                                        </div>
                                    </Col>
                                </Row>
                                <CardBody className="p-2 pt-0">
                                    <label className="form-check-label d-flex justify-content-center align-items-center mb-3">
                                            {t("Select Region")}
                                    </label>
                                    <div className="d-flex justify-content-center align-items-center gap-5 mb-4">
                                        {regions?.map((region: any) => (
                                            <div key={region?.id}
                                                className={`register-region clickable ${selectedRegion?.id === region?.id ? "register-region--selected" : ""}`}
                                                onClick={() => setSelectedRegion(region)}
                                            >
                                                <img src={region?.logo} height={80} width={80} />
                                                <div className="register-region--name">{region?.name}</div>
                                            </div>
                                        ))}
                                        
                                    </div>
                                    {/* <div className="role-description p-2" style={{ minHeight: 110 }}>
                                        {role === "retailer" ? t("Attract, convert, and retain customers through social media style ecosystem and performance-driven marketing. Boost your business with Sustainability Credentials & Post Purchase Marketing with LOOP.") : null}
                                        {role === "charity" ? t("Connect to the world of digital fashion and acquire new donors & supporters among highly engaging community of shoppers and influencers on LOOP Digital Wardrobe.") : null}
                                        {role === "school" ? t("Businesses of all sizes - from startups to large enterprises - are welcome to join LOOP to create a bigger impact together.") : null}
                                    </div> */}
                                    <div className="p-2">
                                        <AvForm
                                            className="form-horizontal"
                                            onValidSubmit={handleValidSubmit}
                                        >
                                            {user && user ? (
                                                <Alert color="success">
                                                    Register User Successfully
                                                </Alert>
                                            ) : null}
                                            {registrationError &&
                                                registrationError ? (
                                                <Alert color="danger">
                                                    {registrationError}
                                                </Alert>
                                            ) : null}

                                            <div className="d-grid">
                                                <button
                                                    className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
                                                    onClick={handleValidSubmit}
                                                >
                                                    {t("Next")}
                                                </button>
                                            </div>

                                            <div className="mt-3 text-center">
                                                <p>
                                                    <span className="auth-agreement-text">Already have an account?</span>{" "}
                                                    <Link to="/login" className="fw-medium auth-agreement-link">
                                                        {" "}
                                                        {t("Log in")}
                                                    </Link>{" "}
                                                </p>
                                            </div>

                                            <div className="mt-4 text-center">
                                                <p className="mb-0 auth-agreement-text">
                                                    By continuing you agree to our <a href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/" target="_blank" className="text-primary">
                                                        Terms & Conditions
                                                    </a>. LOOP services are
                                                    subject to our <a href="https://loopdigitalwardrobe.com/privacy-policy/" target="_blank" className="text-primary">
                                                        Privacy Policy
                                                    </a>.
                                                </p>
                                            </div>

                                            <div className="mt-4 text-center">
                                                <p className="mb-0 auth-agreement-text">
                                                    Need our help? <a href="mailto:help@loopdigitalwardrobe.com" className="fw-medium contact-us-text">
                                                        Contact us.
                                                    </a>
                                                </p>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>

                            <div className="mt-5 text-center">
                                <p className="auth-agreement-text">
                                    ©{new Date().getFullYear()} All Rights Reserved. LOOP DIGITAL WARDROBE® is a registered trademark of LOOP DIGITAL WARDROBE®.{" "}
                                    <a href="https://loopdigitalwardrobe.com/privacy-policy/" target="_blank" className="fw-medium text-primary">
                                        Privacy Policy
                                    </a> and{" "}
                                    <a href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/" target="_blank" className="fw-medium text-primary">
                                        Terms & Conditions
                                    </a>
                                    .
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        </>
    )
}

// class RegisterRegion extends Component<RegisterRegionPropsInterface, RegisterRegionStateInterface> {
//     constructor(props: RegisterRegionPropsInterface) {
//         super(props)
//         state = {
//             formErrors: {},
//             role: "school"
//         }
//     }

//     async componentDidMount() {
//         await getStepData(3)
//         await apiError("")
//         await registerUserFailed("")
//         await onGetCountries()
//     }

//     handleSetRole = (value: string) => {
//         setState({ role: value })
//     }

//     handleValidSubmit = async (event: any, values: any) => {
//         event.preventDefault()

//         const { role } = state;
//         if(!role){
//             return onOpenAlert({
//                 text: t("Choose role to proceed."),
//                 status: "danger",
//             })
//         }

//         return new Promise(async (resolve, reject) => {
//             onChangePreloader(true)

//             post(`${url.API_BASE}dashboard/register/3`, {
//                 code: location?.state?.code,
//                 role: role?.toUpperCase()
//             })
//             .then(response => {
//                 if (response?.status) {
//                     // onOpenAlert({
//                     //     text: response?.message,
//                     //     status: "success",
//                     // })
//                     history.push("/register-contact", {
//                         code: location?.state?.code,
//                         email: location?.state?.email,
//                         role: role?.toUpperCase(),
//                         password: location?.state?.password
//                     })
//                 }

//                 onChangePreloader(false)
//                 return resolve(response)
//             })
//             .catch(error => {
//                 if (error?.response?.data) {
//                     const { message, errors } = error?.response?.data;
//                     setState({ formErrors: errors });
//                     onOpenAlert({
//                         text: message,
//                         status: "danger",
//                     })
//                 }
//                 onChangePreloader(false)
//                 return reject(error)
//             })
//         })
//     }

//     getStepData = async (step: number) => {
//         if(!this?.props?.location?.state?.code){
//             return;
//         }
//         try{
//             onChangePreloader(true)

//             const response = await get(`${url.API_BASE}dashboard/register/step?code=${this?.props?.location?.state?.code}&step=${step}`)
//             if(response?.data?.role?.toLowerCase() !== "undefined"){
//                 handleSetRole(response?.data?.role?.toLowerCase());
//             }
//         }catch(e){

//         }finally{
//             onChangePreloader(false)

//         }
//     }

//     render() {
//         const { formErrors, role } = state
//         const { t } = props

//         return (
//             <React.Fragment>
//                 <MetaTags>
//                     <title>{t("LOOP | Register")}</title>
//                 </MetaTags>
//                 <div className="home-btn d-none d-sm-block">
//                     <Link to="/" className="text-dark">
//                         <i className="bx bx-home h2" />
//                     </Link>
//                 </div>
//                 <div className="account-pages my-2 pt-sm-5">
//                     <Container>
//                         <Row className="justify-content-center">
//                             <Col md={8} lg={7} xl={6}>
//                                 <Card className="overflow-hidden">
//                                     <Row>
//                                         <Col className="col-7">
//                                             <div className="p-4 pe-0">
//                                                 <h1>{t("Sign up")}</h1>
//                                                 {/* <h1>{t("I am the person representing")}</h1> */}
//                                             </div>
//                                         </Col>
//                                     </Row>
//                                     <CardBody className="pt-0">
//                                         <div className="d-grid p-2">
//                                             <label className="form-check-label">
//                                                 {t("Select Region")}
//                                             </label>
                                           
//                                         </div>
//                                         {/* <div className="role-description p-2" style={{ minHeight: 110 }}>
//                                             {role === "retailer" ? t("Attract, convert, and retain customers through social media style ecosystem and performance-driven marketing. Boost your business with Sustainability Credentials & Post Purchase Marketing with LOOP.") : null}
//                                             {role === "charity" ? t("Connect to the world of digital fashion and acquire new donors & supporters among highly engaging community of shoppers and influencers on LOOP Digital Wardrobe.") : null}
//                                             {role === "school" ? t("Businesses of all sizes - from startups to large enterprises - are welcome to join LOOP to create a bigger impact together.") : null}
//                                         </div> */}
//                                         <div className="p-2">
//                                             <AvForm
//                                                 className="form-horizontal"
//                                                 onValidSubmit={handleValidSubmit}
//                                             >
//                                                 {user && user ? (
//                                                     <Alert color="success">
//                                                         Register User Successfully
//                                                     </Alert>
//                                                 ) : null}
//                                                 {registrationError &&
//                                                     registrationError ? (
//                                                     <Alert color="danger">
//                                                         {registrationError}
//                                                     </Alert>
//                                                 ) : null}

//                                                 <div className="d-grid">
//                                                     <button
//                                                         className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
//                                                         type="submit"
//                                                     >
//                                                         {t("Next")}
//                                                     </button>
//                                                 </div>

//                                                 <div className="mt-3 text-center">
//                                                     <p>
//                                                         <span className="auth-agreement-text">Already have an account?</span>{" "}
//                                                         <Link to="/login" className="fw-medium auth-agreement-link">
//                                                             {" "}
//                                                             {t("Log in")}
//                                                         </Link>{" "}
//                                                     </p>
//                                                 </div>

//                                                 <div className="mt-4 text-center">
//                                                     <p className="mb-0 auth-agreement-text">
//                                                         By continuing you agree to our <a href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/" target="_blank" className="text-primary">
//                                                             Terms & Conditions
//                                                         </a>. LOOP services are
//                                                         subject to our <a href="https://loopdigitalwardrobe.com/privacy-policy/" target="_blank" className="text-primary">
//                                                             Privacy Policy
//                                                         </a>.
//                                                     </p>
//                                                 </div>

//                                                 <div className="mt-4 text-center">
//                                                     <p className="mb-0 auth-agreement-text">
//                                                         Need our help? <a href="mailto:help@loopdigitalwardrobe.com" className="fw-medium contact-us-text">
//                                                             Contact us.
//                                                         </a>
//                                                     </p>
//                                                 </div>
//                                             </AvForm>
//                                         </div>
//                                     </CardBody>
//                                 </Card>

//                                 <div className="mt-5 text-center">
//                                     <p className="auth-agreement-text">
//                                         ©{new Date().getFullYear()} All Rights Reserved. LOOP DIGITAL WARDROBE® is a registered trademark of LOOP DIGITAL WARDROBE®.{" "}
//                                         <a href="https://loopdigitalwardrobe.com/privacy-policy/" target="_blank" className="fw-medium text-primary">
//                                             Privacy Policy
//                                         </a> and{" "}
//                                         <a href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/" target="_blank" className="fw-medium text-primary">
//                                             Terms & Conditions
//                                         </a>
//                                         .
//                                     </p>
//                                 </div>
//                             </Col>
//                         </Row>
//                     </Container>
//                 </div >
//             </React.Fragment >
//         )
//     }
// }

// const mapStateToProps = (state: any) => {
//     const { user, registrationError, loading, dashbord_id } = state.Account
//     const { countries } = state.Countries
//     return { user, registrationError, loading, dashbord_id, countries }
// }

// const mapDispatchToProps = (dispatch: any) => {
//     return {
//         apiError: (message: string) => dispatch(apiError(message)),
//         registerUserFailed: (message: string) => dispatch(registerUserFailed(message)),
//         registerUserSuccessful: (user: any) => dispatch(registerUserSuccessful(user)),
//         onGetCountries: () => dispatch({ type: "GET_COUNTRIES" }),
//         onOpenAlert: ({ text, status }: any) =>
//             dispatch(openAlert({ text: text, status: status })),
//         onChangePreloader: (value: boolean) => dispatch(changePreloader(value))
//     }
// }

export default withTranslation()(RegisterRegion)
