// @ts-ignore
import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import FormInput from "../../../components/Common/FormInput"
import { getTakeBackReturnAddresses, addTakeBackReturnAddress, deleteTakeBackReturnAddress, updateTakeBack } from "../../../helpers/backend_helper"
import { openAlert, requestConfirmation } from "../../../store/actions"
import { getCountries } from "../../../store/countries/actions"
import "./ReturnAddress.scss"
//@ts-ignore
import plusGreenBg from "../../../assets/images/plus-green-bg.svg"


interface Props {
  history: any,
  returnTranslation: (name: string) => string
}

const ReturnAddress = ({ history, returnTranslation }: Props) => {
  const dispatch = useDispatch()
  const params: { takeback_id: string } = useParams();
  const countries = useSelector((state: any) => state.Countries?.countries);
  const [selectedCountry, setSelectedCountry] = useState<any>({
    id: 53,
    name: "United Kingdom",
    code: "GB"
  })

  const [formErrors, setFormErrors] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [addresses, setAddresses] = useState<any>([]);
  const [isEdit, setIsEdit] = useState<any>(false);

  const [isOpenAddressModal, setIsOpenAddressModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);

  useEffect(() => {
    handleGetTakeBackReturnAddresses();
    if(!countries?.length){
      dispatch(getCountries())
    }
  }, [])

  // useEffect(() => {
  //   if(address?.country){
  //     setSelectedCountry(countries?.find((c: any) => c?.id === address?.country?.id))
  //   } else if(countries?.length){
  //     setSelectedCountry(countries?.find((c: any) => c?.code === "GB"))
  //   }
  // }, [countries, address?.country])

  const handleGetTakeBackReturnAddresses = async (preventLoader?: boolean) => {
    try{
      !preventLoader && setIsLoading(true)
      const response = await getTakeBackReturnAddresses();
      setAddresses(response)
    }catch(e){
      console.log(e);
      dispatch(openAlert({ text: returnTranslation("An error occured"), status: "danger" }));
    }finally{
      setIsLoading(false)
    }
  }

  const handleSubmit = async (e: any, values: any) => {
    e?.preventDefault();
    try{
      setIsSubmitting(true)
      // console.log(e, values)

      const response = await addTakeBackReturnAddress({
        ...values,
        country_id: selectedCountry?.id
      })
      dispatch(openAlert({ text: returnTranslation("Return address saved successfuly."), status: "success" }));
    }catch(e){
      console.log(e);
      dispatch(openAlert({ text: returnTranslation("An error occured"), status: "danger" }));
    }finally{
      handleGetTakeBackReturnAddresses();
      setIsOpenAddressModal(false);
      setSelectedAddress(null);
      setIsSubmitting(false)
    }
  }

  const handleValidate = () => {
    if(!selectedCountry){
      setFormErrors({
        country: ["Country is required"]
      })
    }
  }

  const handleOpenAddressModal = async (point: any) => {
    setIsOpenAddressModal(true);
    setSelectedAddress(point ? point : null)
    setIsEdit(!!point)
  }

  const handleToggleTakeBackAddress = async (addressId: number, isActive: boolean) => {
    try{
      const response = await updateTakeBack(params?.takeback_id, {
        return_address_id: isActive ? null : addressId
      })
      // const response = await toggleTakeBackAddress(params?.takeback_id, pointId, !isActive);
      dispatch(openAlert({ text: returnTranslation("Changes saved."), status: "success" }));
    }catch(e){
      dispatch(openAlert({ text: returnTranslation("An error occured"), status: "danger" }));
    }finally{
      handleGetTakeBackReturnAddresses(true)
    }
  }

  const handleDeleteAddress = async (addressId: number, name: string) => {
    dispatch(
      requestConfirmation(`${returnTranslation("Delete")} ${name}?`, async () => {
        try{
          const response = await deleteTakeBackReturnAddress(addressId);
          dispatch(openAlert({ text: returnTranslation("Success"), status: "success" }));
        }catch(e){
          dispatch(openAlert({ text: returnTranslation("Error"), status: "success" }));
        }finally{
          handleGetTakeBackReturnAddresses(true)
        }
      })
    )
  }

  return (
    <React.Fragment>
      <div className="page-content return-address">
        <Container fluid>
          <Breadcrumbs title="Take Back" breadcrumbItem="Return Address" />
          {isLoading ? (
              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <Spinner className="ms-2" color="primary" />
              </div>
          ) : (
            <>
              <Row className="return-address--grid">
                {addresses?.map((address: any) => (
                    <Card className="lh-sm card-point font-size-15 p-0">
                      <CardBody className="text-muted d-flex align-items-center">
                        {address?.logo
                          ? <img className="return-address--logo-img" src={address?.logo} />
                          : null}
                        <div className="w-100">
                          <div className="fw-medium text-dark">{address?.title}</div>
                          <div>{address?.street}</div>
                          <div><span>{address?.city}</span>, <span>{address?.postcode}</span></div>
                          <div>{address?.country?.name}</div>
                          {address?.editable ? (
                            <div className="d-flex gap-2 mt-1">
                              <div onClick={() => handleOpenAddressModal(address)} className="fw-medium auth-agreement-link">
                                {returnTranslation("Edit")}
                              </div>
                              <div onClick={() => handleDeleteAddress(address?.id, address?.title)} className="fw-medium auth-agreement-link auth-agreement-link--inactive">
                                {returnTranslation("Delete")}
                              </div>
                            </div>
                          ): null}
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                          <Input
                            type="radio"
                            className="radio-input clickable"
                            checked={!!address?.take_back}
                            onClick={() => handleToggleTakeBackAddress(address?.id, !!address?.take_back)}
                          />
                        </div>
                      </CardBody>
                    </Card>
                ))}
                {!addresses?.some((address: any) => address?.type === "CUSTOM") ? (
                  <Card className="card-placeholder card-point clickable" onClick={() => handleOpenAddressModal(null)}>
                    <img src={plusGreenBg}  height={25} className="mt-2"/>
                    <h5>{returnTranslation("Add address")}</h5>
                  </Card>
                ) : null}
              </Row>
            </>
          )}
          <Modal size="lg" centered={true} isOpen={isOpenAddressModal} toggle={() => setIsOpenAddressModal(!isOpenAddressModal)}>
            <ModalHeader>
              {returnTranslation(`Return Address${isEdit ? ` (${selectedAddress?.title})` : ""}`)}
            </ModalHeader>
            <ModalBody>
              <AvForm
                className="form-horizontal"
                onValidSubmit={handleSubmit}
                // onSubmit={handleValidate}
              >
                <Row>
                  <Col>
                    <AvField
                      name="title"
                      label="Title"
                      className="form-control"
                      placeholder="Enter title"
                      type="text"
                      value={selectedAddress?.title ? selectedAddress?.title : ""}
                      // defaultValue={address?.title ? address?.title : ""}
                      required
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={8} md={8}>
                    <AvField
                      name="street"
                      label="Street"
                      className="form-control"
                      placeholder="Enter street name"
                      type="text"
                      value={selectedAddress?.street ? selectedAddress?.street : ""}
                      required
                    />
                  </Col>
                  <Col lg={4} md={4}>
                    <AvField
                      name="houseNo"
                      label="House No."
                      className="form-control"
                      placeholder="Enter House No."
                      type="text"
                      value={selectedAddress?.houseNo ? selectedAddress?.houseNo : ""}
                      required
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={4} md={4}>
                    <AvField
                      name="postcode"
                      label="Postcode"
                      className="form-control"
                      placeholder="Enter postcode"
                      type="text"
                      value={selectedAddress?.postcode ? selectedAddress?.postcode : ""}
                      required
                    />
                  </Col>
                  <Col lg={8} md={8}>
                    <AvField
                      name="city"
                      label="City"
                      className="form-control"
                      placeholder="Enter city name"
                      type="text"
                      value={selectedAddress?.city ? selectedAddress?.city : ""}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormInput
                      label={"Country"}
                      type="country"
                      name="country"
                      className="form-control"
                      placeholder={"Select country"}
                      showErrors={true}
                      formErrors={formErrors}
                      onChange={code => {
                        setSelectedCountry(countries?.find((c: any) => c.code === code))
                      }}
                      value={selectedCountry?.code}
                      // countries={countries?.map((country: any) => country?.code)}
                      countries={["GB", "US"]}
                      disabled={false}
                    />
                  </Col>
                </Row>

                <div className="mt-4 d-flex">
                  <Button
                    color="success"
                    type="submit"
                    className="btn-no-shadow btn-block waves-effect waves-light"
                    style={{ width: 100 }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <Spinner color="dark" />
                    ): returnTranslation(isEdit ? "Update" : "Add")}
                  </Button>
                </div>
              </AvForm>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ReturnAddress
