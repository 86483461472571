import {
  REQUEST_CONFIRMATION,
  CANCEL_CONFIRMATION,
  CONFIRM,
} from "./actionTypes"

const INIT_STATE = {
  confirmCb: null,
  cancelCb: null,
  msg: null,
  confirmBtnText: null,
  confirmBtnStyle: null,
}

const pendingConfirmationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case REQUEST_CONFIRMATION:
      const { msg, confirmCb, cancelCb, confirmBtnText, confirmBtnStyle } = action?.payload
      return {
        msg,
        confirmCb,
        cancelCb,
        confirmBtnText,
        confirmBtnStyle,
      }
    case CANCEL_CONFIRMATION:
      return {
        msg: null,
        confirmCb: null,
        cancelCb: null,
        confirmBtnText: null,
        confirmBtnStyle: null,
      }
    case CONFIRM:
      return {
        msg: null,
        confirmCb: null,
        cancelCb: null,
        confirmBtnText: null,
        confirmBtnStyle: null,
      }
    default:
      return state
  }
}

export default pendingConfirmationReducer
