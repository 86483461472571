import React from "react"
import  moment from 'moment-timezone';

interface ReportsTableColumnsInterface {
    offsetIndex: number;
    returnTranslation: (name: string) => string;
}

const ReportsTableColumns = ({ offsetIndex, returnTranslation }: ReportsTableColumnsInterface) => {
    return (
        [
            {
                dataField: "i",
                isDummyField: true,
                text: "#",
                formatter: (cellContent: any, row: any, rowIndex: number) => {
                    return <div>{rowIndex + 1 + offsetIndex}</div>
                },
            },
            // {
            //     dataField: "date",
            //     text: returnTranslation("Date"),
            //     // formatter: (cellContent: any, row: any, rowIndex: any) =>
            //     // (
            //     //     <p>{row?.data[0]?.description}</p>
            //     // ),
            // },
            {
                dataField: "name",
                text: returnTranslation("Item"),
                // formatter: (cellContent: any, row: any, rowIndex: any) =>
                // (
                //     <p>{row?.data[0]?.description}</p>
                // ),
            },
            {
                dataField: "actual_price",
                text: returnTranslation("Selling Price"),
                formatter: (cellContent: any, row: any, rowIndex: any) =>
                (
                    <span>{row?.actual_currency} {row?.actual_price}</span>
                ),
            },
            {
                dataField: "order.created_at",
                text: returnTranslation("Sold at"),
                formatter: (cellContent: any, row: any, rowIndex: any) =>
                (
                    <span>{moment.tz(row?.created_at, 'UTC').tz('Europe/Warsaw').format('DD-MM-YYYY HH:mm:ss')}</span>
                ),
            },
            {
                dataField: "order.status_name",
                text: returnTranslation("Status"),
                // formatter: (cellContent: any, row: any, rowIndex: any) =>
                // (
                //     <span>{moment.tz(row?.created_at, 'UTC').tz('Europe/Warsaw').format('DD-MM-YYYY HH:mm:ss')}</span>
                // ),
            },
            // {
            //     dataField: "configuration",
            //     isDummyField: true,
            //     formatter: (cellContent: any, row: any, rowIndex: any) =>
            //     (
            //         <a href={row.pdf} target="_blank">
            //             <Button color="success">
            //                 {returnTranslation("Download PDF")}
            //             </Button>
            //         </a>
            //     ),
            //     text: returnTranslation("PDF"),
            // },
        ]
    )
}

export default ReportsTableColumns
