import BasicTable from "./../../../components/BasicTable/BasicTable"
import Breadcrumbs from "./../../../components/Common/Breadcrumb"
import { deleteWebsiteApi } from "./../../../helpers/backend_helper"
import React, { useEffect, useState } from "react"
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
// @ts-ignore
import { RouteComponentProps, withRouter } from "react-router-dom"
// @ts-ignore
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row, Container, UncontrolledTooltip } from "reactstrap"
import { getWebsites, openAlert, requestConfirmation, getPaymentInfo, getBillings, changePreloader, getInvoices } from "./../../../store/actions"
// import WebsitesTableColumns from "./WebsitesTableColumns/WebsitesTableColumns"
import SubscriptionsPlans from "./../../../components/SubscriptionsPlans/SubscriptionsPlans"
import ChangePlanDuration from "./../../../components/SubscriptionsPlans/ChangePlanDuration/ChangePlanDuration"
import { put, post } from "./../../../helpers/api_helper"
import * as url from "./../../../helpers/url_helper"
// @ts-ignore
import { AvField, AvForm } from "availity-reactstrap-validation"
import ReactFlagsSelect from 'react-flags-select';

interface ChangeVatInterface extends RouteComponentProps {
    history: any,
    returnTranslation: (name: string) => string
}

const ChangeVat = ({ history, returnTranslation }: ChangeVatInterface) => {
    const dispatch = useDispatch()
    const user = useSelector((state: any) => state.Account.user)
    const billingsInfo = useSelector((state: any) => state.Billings.info)

    const [formErrors, setFormErrors] = useState('')

    useEffect(() => {
        dispatch(getPaymentInfo())
        dispatch(getBillings())
        dispatch(getInvoices())
    }, [])

    const handleValidSubmit = async (event: any, values: any) => {
        event.preventDefault()

        return new Promise(async (resolve, reject) => {
            const {
                vat_id
            } = values;

            dispatch(changePreloader(true))

            put(`${url.API_BASE}billings/payment_info/vatin`, {
                vatin: vat_id
            })
                .then(response => {
                    if (response?.status) {
                        dispatch(openAlert({ text: response?.status, status: "success" }))
                        history.push("/billings")
                    }
                    dispatch(changePreloader(false))
                    return resolve(response)
                })
                .catch(error => {
                    dispatch(changePreloader(false))
                    if (error?.response?.data) {
                        const { message, errors } = error?.response?.data;
                        setFormErrors(errors)
                        dispatch(openAlert({ text: message, status: "danger" }))
                    }
                    return reject(error)
                })
        })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>LOOP | Edit VAT ID</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs
                        title={returnTranslation("Billings")}
                        breadcrumbItem={returnTranslation("Edit VAT ID")}
                    />
                    <Row >
                        <Col sm={12} md={6} lg={6} xl={6}>
                            <Card className="overflow-hidden">
                                <CardBody className="pt-0">
                                    <div className="p-2">
                                        <AvForm
                                            className="form-horizontal"
                                            onValidSubmit={handleValidSubmit}
                                        >
                                            <div className="mb-3">
                                                <AvField
                                                    name="vat_id"
                                                    label="VAT ID"
                                                    className="form-control"
                                                    placeholder="Enter VAT ID"
                                                    type="text"
                                                    defaultValue={billingsInfo?.vatin ? billingsInfo?.vatin : ""}
                                                    validate={{
                                                        pattern: {
                                                            value: new RegExp(/((^GB[ ]?)([0-9][ ]{0,1}){9}$)|(^([0-9][ ]{0,1}){9}$)/),
                                                            errorMessage: 'VAT ID must be 9 digits / GB followed by 9 digits.'
                                                        }
                                                    }}
                                                    required
                                                />
                                            </div>

                                            <div className="mt-4 d-grid">
                                                <button
                                                    className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
                                                    type="submit"
                                                >
                                                    {returnTranslation("Submit")}
                                                </button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(ChangeVat)
