import React from "react"
import { Link } from "react-router-dom"

interface CodesTableColumnsInterface {
  handleDelete: (id: string, name: string) => {},
  handleEdit: (id: string) => {},
  handleSetActive: (id: string, active: number) => {},
  returnTranslation: (name: string) => string
}

const WebsitesTableColumns = ({ handleDelete, handleEdit, handleSetActive, returnTranslation }: CodesTableColumnsInterface) => {
  return (
    [
      {
        dataField: "i",
        isDummyField: true,
        text: "#",
        formatter: (cellContent: any, row: any, rowIndex: any) => <div>{rowIndex + 1}</div>,
      },
      {
        dataField: "name",
        text: returnTranslation("Name"),
      },
      {
        dataField: "short_description",
        text: returnTranslation("Short Description"),
      },
      {
        dataField: "start_at",
        text: returnTranslation("Start At"),
      },
      {
        dataField: "expire_at",
        text: returnTranslation("Expire At"),
      },
      {
        dataField: "type",
        text: returnTranslation("Type"),
      },
      {
        dataField: "value",
        text: returnTranslation("Value"),
      },
      {
        dataField: "status",
        text: returnTranslation("LOOP Status"),
        formatter: (cellContent: any, row: any, rowIndex: any) =>
          row.is_active ? (
            <span className="bg-success badge badge-success">{returnTranslation("Visible")}</span>
          ) : (
            <span className="bg-danger badge badge-danger">{returnTranslation("Inactive")}</span>
          ),
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "",
        formatter: (cellContent: any, row: any, rowIndex: any) => (
          <div className="d-flex gap-3">
            <span
              className="text-warning clickable"
              onClick={() => handleEdit(row.id)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
            </span>
            <span
              className="text-danger clickable"
              onClick={() => handleDelete(row.id, row.code)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
            </span>
            <span
              className={"clickable " + (row.active == 1 ? "text-danger" : "text-success")}
              onClick={() => handleSetActive(row.id, row.active == 1 ? 0 : 1)}
            >
              <p className="active-coupon-btn">{row.active == 1 ? returnTranslation("Set inactive") : returnTranslation("Set active")}</p>
            </span>
          </div>
        ),
      },
    ]
  )
}

export default WebsitesTableColumns
