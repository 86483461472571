import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  CHECK_ACCOUNT,
  CHECK_ACCOUNT_FAILED,
  CHECK_ACCOUNT_SUCCESSFUL,
} from "./actionTypes"

export const registerUser = user => {
  return {
    type: REGISTER_USER,
    payload: { user },
  }
}

export const registerUserSuccessful = user => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  }
}

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}

export const checkAccount = () => {
  return {
    type: CHECK_ACCOUNT,
  }
}

export const checkAccountSuccessful = accountInfo => {
  return {
    type: CHECK_ACCOUNT_SUCCESSFUL,
    payload: accountInfo,
  }
}

export const checkAccountFailed = error => {
  return {
    type: CHECK_ACCOUNT_FAILED,
    payload: error,
  }
}
