import React from 'react'
import { Alert } from 'reactstrap'

interface Props {
    returnTranslation: (text: string) => any;
    onOpenAlert: (params: {text: string, status: string}) => any;
    display?: boolean;
    customText?: string;
    color?: string;
    CustomTextElement?: any;
}

function ContactUsAlert({
    returnTranslation,
    onOpenAlert,
    display = true,
    customText,
    color="secondary",
    CustomTextElement
}: Props) {

    const copyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text);
		onOpenAlert({ text: "Copied to clipboard.", status: "success" })
	}

    return (
        display ? (
            <Alert className="text-center border-0" color={color}>
                {customText || CustomTextElement
                    ? <span>{customText && customText}{CustomTextElement && CustomTextElement}</span>
                    : (
                        <>
                            <span className="me-1">{returnTranslation("Contact us at")}</span>
                            <a onClick={() => copyToClipboard("admin@loopdigitalwardrobe.com")}>
                                <b>admin@loopdigitalwardrobe.com</b>
                            </a>
                            <span className="ms-1">{returnTranslation("to change your account details.")}</span>
                        </>
                    )
                }
                
            </Alert>
        ) : null
    )
}

export default ContactUsAlert
