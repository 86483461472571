import React, { useEffect, useState } from "react"
import {
  Col,
  Container,
  Row
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { getCodes } from "../../../store/coupons/actions"
import { openAlert } from "../../../store/alert/actions"
import BasicTable from "../../../components/BasicTable/BasicTable"
import CodesTableColumns from "./CodesTableColumns/CodesTableColumns"
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
import { requestConfirmation } from "./../../../store/actions"
import { deleteCode, activeCoupon, deleteCoupon } from "./../../../helpers/backend_helper"

interface DashboardCodesInterface {
  history: any,
  returnTranslation: (name: string) => string
}

const DashboardCoupons = ({ history, returnTranslation }: DashboardCodesInterface) => {
  const dispatch = useDispatch()
  const data = useSelector((state: any) => state.Codes)
  const retailerId = useSelector((state: any) => state.Account.user.id)

  const handleDelete = (uuid: string, name: string) => {
    dispatch(
      requestConfirmation(`${returnTranslation("Delete")} ${name}?`, () => confirmDelete(uuid))
    )
  }

  const confirmDelete = async (id: string) => {
    try {
      await deleteCoupon({ id })
      dispatch(getCodes(1))
      dispatch(openAlert({ text: returnTranslation("Code deleted"), status: "success" }))
    } catch (e) {
      dispatch(openAlert({ text: returnTranslation("An error occured"), status: "danger" }))
    }
  }

  const handleEdit = (id: string) => {
    history.push(`/coupon/edit/${id}`)
  }

  const handleSetActive = async (id: string, active: number) => {
    try {
      await activeCoupon({ id, active, retailer_id: retailerId })
      dispatch(getCodes(1))
      if (active) {
        dispatch(openAlert({ text: returnTranslation("Coupon activated"), status: "success" }))
      } else {
        dispatch(openAlert({ text: returnTranslation("Coupon deactivated"), status: "success" }))
      }
    } catch (e) {
      dispatch(openAlert({ text: returnTranslation("An error occured"), status: "danger" }))
    }
  }

  const handleSetItemActive = async (id: string) => {
    handleSetActive(id, 1)
  }

  const handleSetItemInactive = async (id: string) => {
    handleSetActive(id, 0)
  }

  return (
    <React.Fragment>
      <div className="page-content coupons-list">
        <Container fluid>
          <Breadcrumbs title={returnTranslation("Coupons")} breadcrumbItem={returnTranslation("Coupons")} />

          <Row>
            <Col lg="12">
              <BasicTable
                items={data?.codes}
                // @ts-ignore
                columns={CodesTableColumns({ returnTranslation, handleDelete, handleEdit, handleSetActive })}
                // @ts-ignore
                addItemBtnText={returnTranslation("Add Coupon")}
                addItemBtnAction={() => history.push("/add-coupon")}
                searchbar={false}
                getAction={getCodes}
                totalSize={data?.info?.total_items}
                sizePerPage={data?.info?.page_count}
                currentPage={data?.info?.current_page}
                isLoading={data?.isLoading}
                displaySelectMultiple={true}
                selectMultipleOptions={[
                  {
                    'name': 'Set Active',
                    action: (id: string) => handleSetItemActive(id)
                  },
                  {
                    'name': 'Set Inactive',
                    action: (id: string) => handleSetItemInactive(id)
                  }
                ]}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DashboardCoupons
