import {
  GET_DICTIONARIES,
  GET_DICTIONARIES_FAIL,
  GET_DICTIONARIES_SUCCESS,
  GET_REGIONS,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_FAIL,
} from "./actionTypes"

export const getDictionaries = () => ({
  type: GET_DICTIONARIES,
})

export const getDictionariesSuccess = dictionaries => ({
  type: GET_DICTIONARIES_SUCCESS,
  payload: dictionaries,
})

export const getDictionariesFail = error => ({
  type: GET_DICTIONARIES_FAIL,
  payload: error,
})

export const getRegions = () => ({
  type: GET_REGIONS,
})

export const getRegionsSuccess = regions => ({
  type: GET_REGIONS_SUCCESS,
  payload: regions,
})

export const getRegionsFail = error => ({
  type: GET_REGIONS_FAIL,
  payload: error,
})