import { OPEN_ALERT, CLOSE_ALERT } from "./actionTypes"

export const openAlert = ({ text, status }) => ({
  type: OPEN_ALERT,
  payload: { text, status },
})

export const closeAlert = () => ({
  type: CLOSE_ALERT,
})
