import React, { useEffect } from "react"
// @ts-ignore
import MetaTags from 'react-meta-tags';
import Breadcrumbs from "./../../../components/Common/Breadcrumb"
import { Container } from "reactstrap"

interface PreviewInterface {
    history: any,
    match: any,
    returnTranslation: (name: string) => string
}

const Preview = ({ history, match: { params }, returnTranslation }: PreviewInterface) => {
    useEffect(() => {
        const getWidgetData = async () => {
            const url = `https://api.loopdigitalwardrobe.com/api/plugins/${params?.uuid}/init`;
            const response = await fetch(url);
            const data = await response.json();

            const { widget_status,
                config: {
                    position,
                    xOffset,
                    yOffset,
                    zIndex,
                    website_container_class,
                    show_widget_btn,
                    xOffset_widget_btn,
                    yOffset_widget_btn,
                    zIndex_widget_btn,
                    direction_widget_btn,
                    position_widget_btn
                }
            } = data;

            const _loopPlugin__containerNode = document.querySelector('.loop-plugin-container');
            const _loopPlugin__iframeNode = document.createElement('iframe');
            if (typeof (_loopPlugin__containerNode) != 'undefined' && _loopPlugin__containerNode != null) {
                _loopPlugin__iframeNode.setAttribute("src", `https://app.loopdigitalwardrobe.com/plugin?loopPluginUUID=${params?.uuid}`);
                _loopPlugin__iframeNode.setAttribute("id", "loop-widget-iframe");
                _loopPlugin__iframeNode.setAttribute("style", `width: 100%;
                                                                height: 700px;
                                                                border: 0;
                                                                position: ${position ? position : "relative"};
                                                                top: ${xOffset ? xOffset : "unset"};
                                                                left:  ${yOffset ? yOffset : "unset"};
                                                                z-index: ${zIndex};
                                                                margin: 20px auto;
                                                                max-height: 85vh;
                                                                overflow: hidden;
                                                                display: block;
                                                                max-width: 400px;
                                                                box-shadow: 0px 0px 9px 5px #f0f0f0;
                                                                -webkit-box-shadow: 0px 0px 9px 5px #f0f0f0;
                                                                `);
                _loopPlugin__iframeNode.setAttribute("scrolling", "no");
                _loopPlugin__containerNode.appendChild(_loopPlugin__iframeNode);
            }
        }
        getWidgetData()
    }, [])

    useEffect(() => () => {
        //unmount
        window.location.reload();
    }, []);

    return (
        <div className="page-content">
            <MetaTags>
                <title>LOOP | Plugin preview</title>
            </MetaTags>
            <Container fluid>
                <Breadcrumbs
                    title={returnTranslation("Pages")}
                    breadcrumbItems={[returnTranslation("Websites"), returnTranslation("Preview")]}
                />
                <div className="loop-plugin-container"></div>
            </Container>
        </div>
    )
}

export default Preview
