import React, { useEffect } from "react";
// @ts-ignore
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import {
	Card,
	CardBody,
	Col,
	Container, Row
} from "reactstrap";
import SchoolTakebackIcon from "../../../assets/images/school_takeback.svg";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { checkAccount, getTakeBacks } from "../../../store/actions";
import { openAlert } from "../../../store/alert/actions";
import SingleConfigurationOption from "../../Dashboard/WelcomePanels/SingleConfigurationOption/SingleConfigurationOption";
import "./TakebackOverview.scss";

export interface Props extends RouteComponentProps {
	history: any,
	returnTranslation: any
}

const EnableCharityShop = ({ returnTranslation, history }: Props) => {
	const dispatch = useDispatch();
	const TAKE_BACKS = "TAKE_BACKS"

	const entitlements = useSelector((state: any) => state?.Account?.info?.configuration?.entitlements)
	const takebackTasks = useSelector((state: any) => state?.Account?.info?.take_back_tasks)

	const onOpenAlert = ({ text, status }: any) => dispatch(openAlert({ text: text, status: status }))
	const onCheckAccount = () => dispatch(checkAccount());

	const data = useSelector((state: any) => state.TakeBacks)
	const user = useSelector((state: any) => state?.Account?.user)
	const accountInfo = useSelector((state: any) => state?.Account?.info)

	useEffect(() => {
		dispatch(getTakeBacks({ page: data?.info?.current_page }))
		onCheckAccount()
	}, [])

	return (
		<>
			<MetaTags>
				<title>Take Back Overview</title>
			</MetaTags>
			<div className="page-content">
				<Container fluid>
					<Breadcrumb title="Take Back" breadcrumbItem="Overview" />

					<Row>
						<Col sm={12} md={12} lg={12} xl={8}>
							<Card>
								<CardBody>
									<div className="welcome-panel__steps">
										<SingleConfigurationOption
											header={"Take Back Configuration"}
											description={user?.role === "SCHOOL" 
											    ? "Organize clothing collections. Easily resell or upcycle collected products and raise funds for your school or local communities or charity organizations you support"
												: "Create your own take back program! Simply specify the place of return for your company or we will do it for you. It’s super easy, just add your own stores to take back or choose courier shipping based on your own contract or use Loop. You can then create a voucher to reward your customers for the completed Take Back. Add a simple link to your Social media or transactional communications and direct shoppers to your very own Take Back program."
											}
											descriptionClassName="m-0"
											filled={takebackTasks?.details &&
												// takebackTasks?.return_address &&
												// takebackTasks?.accepted_items &&
												((takebackTasks?.carriers) || takebackTasks?.points)
											}
											tasksOpened={true}
											containerClassName="p-0"
											tasksOptions={[
												{
													name: data?.items?.[0]?.id ? returnTranslation("Details") : returnTranslation("Add Details"),
													isActive: takebackTasks?.details ?? false,
													route: data?.items?.[0]?.id
														? `/take-back/${data?.items?.[0]?.id}/details`
														: `/take-back/details/add`,
												},
												// {
												// 	name: returnTranslation("Return Address"),
												// 	isActive: takebackTasks?.return_address,
												// 	route: `/take-back/${data?.items?.[0]?.id}/return-address`,
												// 	isLocked: !data?.items?.[0]?.id
												// },
												{
													name: returnTranslation("Accepted Items"),
													isActive: takebackTasks?.accepted_items ?? false,
													route: `/take-back/${data?.items?.[0]?.id}/accepted-items`,
													isLocked: !data?.items?.[0]?.id,
													optional: true
												},
												(user?.role === "RETAILER" || user?.role === "CHARITY"  ? {
													name: "Redeem Coupon",
													isActive: (takebackTasks?.coupon || data?.items?.[0]?.external_coupon_text) ?? false,
													route: `/take-back/${data?.items?.[0]?.id}/redeem-coupon`,
													isLocked: !data?.items?.[0]?.id,
													optional: true
												} : null),
												{
													name: returnTranslation("Local Stores"),
													isActive: takebackTasks?.points  ?? false,
													route: `/take-back/${data?.items?.[0]?.id}/local-stores`,
													isLocked: !data?.items?.[0]?.id
												},
												{
													name: returnTranslation("Delivery"),
													isActive: (takebackTasks?.carriers) ?? false,
													// route: `/take-back/${data?.items?.[0]?.id}/carriers`,
													isLocked: !data?.items?.[0]?.id,
													subtasksOpened: true,
													subtasks: [
														{
															name: returnTranslation("Return Address"),
															isActive: takebackTasks?.return_address  ?? false,
															route: `/take-back/${data?.items?.[0]?.id}/return-address`,
															isLocked: !data?.items?.[0]?.id,
															optional: true
														},
														{
															name: returnTranslation("Carriers"),
															isActive: takebackTasks?.carriers  ?? false,
															route: `/take-back/${data?.items?.[0]?.id}/carriers`,
															isLocked: !data?.items?.[0]?.id
														}
													]
												},
											]}
											entitlement={entitlements?.[TAKE_BACKS]}
											entitlementType={TAKE_BACKS}
											// verified={data?.items?.[0]?.confirmed}
											verified={accountInfo?.verified}
											activateCondition={
												takebackTasks?.details &&
												// takebackTasks?.return_address &&
												// takebackTasks?.accepted_items &&
												(takebackTasks?.carriers || takebackTasks?.points)
											}
											returnTranslation={returnTranslation}
											// customCheckAccount={checkEntitlements}
											customFilledIconDone={<img className="single-configuration-option--icon" src={SchoolTakebackIcon} />}
                      						customFilledIconUndone={<img className="single-configuration-option--icon" src={SchoolTakebackIcon} />}
										/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	)
}

export default withRouter(EnableCharityShop)
