import React from "react"
import { Label } from "reactstrap"

interface Props {
  returnTranslation: (name: string) => string,
}

const CsvPreviewTableColumns = ({ returnTranslation }: Props) => {
  return (
    [
      {
        dataField: "i",
        isDummyField: true,
        text:"1",
        formatter: (cellContent: any, row: any, rowIndex: any) => <div>{rowIndex + 2}</div>,
      },
      {
        dataField: "name",
        text: "name",
       // text:returnTranslation("Name"),
      },
      {
        dataField: "description",
        text: "description",
       // text:returnTranslation("Desc."),
      },
      {
        dataField: "street",
        text: "street",
       // text:returnTranslation("Street"),
      },
      {
        dataField: "city",
        text: "city",
       // text:returnTranslation("City"),
      },
      {
        dataField: "postcode",
        text: "postcode",
       // text:returnTranslation("Postode"),
      },
      {
        dataField: "country",
        text: "country",
       // text:returnTranslation("Country"),
      },
      {
        dataField: "lat",
        text: "lat",
       // text:returnTranslation("Latidtude"),
      },
      {
        dataField: "lng",
        text:"lng",
        // text:returnTranslation("Longitude"),
      },
      {
        dataField: "open_monday",
        text: "open_monday",
       // text:returnTranslation("Open Mon."),
      }, {
        dataField: "open_tuesday",
        text: "open_tuesday",
       // text:returnTranslation("Open Tue."),
      },
      {
        dataField: "open_wednesday",
        text: "open_wednesday",
       // text:returnTranslation("Open Wed."),
      },
        {
        dataField: "open_thursday",
        text: "open_thursday",
       // text:returnTranslation("Open Thu."),
      },
      {
        dataField: "open_friday",
        text: "open_friday",
       // text:returnTranslation("Open Fri."),
      },
      {
        dataField: "open_saturday",
        text: "open_saturday",
       // text:returnTranslation("Open Sat."),
      },
      {
        dataField: "open_sunday",
        text: "open_sunday",
       // text:returnTranslation("Open Sun."),
      }, 
      {
        dataField: "active",
        text: "active",
       // text:returnTranslation("Active"),
      },
    ]
  )
}

export default CsvPreviewTableColumns
