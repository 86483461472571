import React, { Component, useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

interface Props {
    btnClassName?: string;
    imgClassName?: string;
    CustomButtonComponent?: any;
    images: string[];
}

const LightboxComponent = ({ btnClassName, imgClassName, CustomButtonComponent, images }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [innerImages, setInnerImages] = useState<string[]>([]);

    useEffect(() => {
        setInnerImages(images)
    }, [images, images?.length])

    return (
        <div>
            {CustomButtonComponent ? (
                <CustomButtonComponent 
                    type="button"
                    onClick={(index = 0) => {
                        setPhotoIndex(typeof index === 'number' ? index : 0)
                        setIsOpen(true)
                    }}
                />
            ) : (
                <button
                    type="button"
                    className={`openProductPhotos ${btnClassName ? btnClassName : ""}`}
                    onClick={() => setIsOpen(true)}
                >
                    <img key={`lightbox-photo`}
                        className={`main-lightbox-photo  ${imgClassName ? imgClassName : ""}`}
                        src={innerImages[0]}
                    />
                    {/* {images && images.map((photo, i) => {
                        //on btn list image only 3 images
                        if (i < 3) {
                            if (i === 0) {
                                return (
                                    <img key={`lightbox-photo-${i}`} className="main-lightbox-photo__main" src={photo} />
                                )
                            } else {
                                return (
                                    <img key={`lightbox-photo-${i}`} className="main-lightbox-photo" src={photo} />
                                )
                            }
                        }

                    })} */}
                </button>
            )}

            {isOpen && (
                <Lightbox
                    mainSrc={innerImages[photoIndex]}
                    nextSrc={innerImages[(photoIndex + 1) % innerImages.length]}
                    prevSrc={innerImages[(photoIndex + innerImages.length - 1) % innerImages.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + innerImages.length - 1) % innerImages.length,)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % innerImages.length,)
                    }
                />
            )}
      </div>
    );
}
export default LightboxComponent