import React from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { connect, useDispatch, useSelector } from "react-redux"
import { cancelConfirmation, confirm } from "./../../store/actions"

const ConfirmPanel = ({ returnTranslation }) => {
  const dispatch = useDispatch()
  const msg = useSelector(state => state.confirmPanel.msg)
  const confirmCb = useSelector(state => state.confirmPanel.confirmCb)
  const cancelCb = useSelector(state => state.confirmPanel.cancelCb)
  const confirmBtnText = useSelector(state => state.confirmPanel.confirmBtnText);
  const confirmBtnStyle = useSelector(state => state.confirmPanel.confirmBtnStyle);

  const success = () => {
    confirmCb && confirmCb()
    dispatch(confirm())
  }
  const cancel = () => {
    cancelCb && cancelCb()
    dispatch(cancelConfirmation())
  }
  return (
    <SweetAlert
      warning
      showCancel
      confirmBtnText={confirmBtnText ? confirmBtnText : returnTranslation('Yes')}
      confirmBtnBsStyle={confirmBtnStyle ? confirmBtnStyle : 'success'}
      cancelBtnBsStyle={"danger"}
      // cancelBtnStyle={"danger"}
      title={returnTranslation("Are you sure?")}
      onConfirm={success}
      onCancel={cancel}
      focusCancelBtn
      cancelBtnText={returnTranslation("Cancel")}
    >
      {msg}
    </SweetAlert>
  )
}

export default ConfirmPanel
