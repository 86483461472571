const OffSymbol = ({ text }: any) => {
    return (
        <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
        }}
        >
        {" "}
        {"No"}
        </div>
    )
}

export default OffSymbol;