import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Row, Card } from "reactstrap"
import SmallTile, { SmallTileProps } from "./SmallTile/SmallTile"
// import { getRetailers } from "../../store/retailers/actions"
// import { getCharities as getCharitiesAPI, getCustomers as getCustomersAPI, getSchools as getSchoolsAPI } from '../../helpers/backend_helper'
// import { getAdmins } from "../../store/admins/actions"
import { useHistory } from "react-router"
import CustomTabs2 from "../../components/CustomTabs2/CustomTabs2"
import "./Dashboard.scss"
import SecondLifeOptionsStatsTab from "./StatsTabs/SecondLifeOptionsStatsTab/SecondLifeOptionsStatsTab"
import MarketplaceAndShoppingStatsTab from "./StatsTabs/MarketplaceAndShoppingStatsTab/MarketplaceAndShoppingStatsTab"
import WardrobesStatsTab from "./StatsTabs/WardrobesStatsTab/WardrobesStatsTab"
import MarketingStatsTab from "./StatsTabs/MarketingStatsTab/MarketingStatsTab"
// import graduationCap from '../../assets/images/graduation-cap-2.png'
// import { getCharities, getSchools } from "../../store/actions"

export const ColorsPaletteLoop = {
  green: "#aee8b3",
  lightGreen: "#bee6c2",
  purple: "#9da3eb",
  lightPurple: "#d6d8f1",
  pink: "#e69ab3",
  lightPink: "#f1d6df",
  blue: "#99d1e0",
  black: "#000",
  darkGray: "#424242",

  loopLighPurple: "#EAE6FA",
  loopLightPink: "#FEE2F1",
  loopPink: "#f46a9b",
  loopLightBlue: "#9DD1EA",
  loopBlue: "#7BB4CF",
}

export const ColorsPalette = {
  orange: "#F47A1F",
  yellow: "#FDBB2F",
  darkGreen: "#377B2B",
  appleGreen: "#7AC142",
  bottleGreen: "#00C49F",
  blue: "#007CC3" ,
  darkBlue: "#00529B",
}

export const ColorsPalette2 = {
  bananaYellow: "#ffec21",
  blue: "#378AFF",
  orange: "#FFA32F",
  redOrange: "#F54F52",
  lightGreen: "#93F03B",
  lavender : "#9552EA"
}

//Retro Metro
export const ColorsPaletteArray = ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"];
//Dutch Field
export const ColorsPaletteArray2 = ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"];
//River Nights
export const ColorsPaletteArray3 = ["#b30000", "#7c1158", "#4421af", "#1a53ff", "#0d88e6", "#00b7c7", "#5ad45a", "#8be04e", "#ebdc78"];
// Spring Pastels
export const ColorsPaletteArray4 = ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"];
interface Props {
  t: (text: string) => string;
}

const Dashboard = ({ t }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory();
  const user = useSelector((state: any) => state.Account.user)
  const retailers = useSelector((state: any) => state.Retailers)
  const charities = useSelector((state: any) => state.Charities)
  const schools = useSelector((state: any) => state.Schools)
  const admins = useSelector((state: any) => state.admins)
  // const [charities, setCharities] = useState<any>({ items: [], info: {} });
  const [isLoadingCharities, setIsLoadingCharities] = useState(true);
  const [activeTab, setActiveTab] = useState("1");

  const [customers, setCustomers] = useState<any>({ items: [], info: {} });
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(true);

  // const [schools, setSchools] = useState<any>({ items: [], info: {} });
  const [isLoadingSchools, setIsLoadingSchools] = useState(true);

  const [reports, setReports] = useState<any>([])

  useEffect(() => {
    setReports(
      [
        {
          title: t("Number of products in users' wardrobes"),
          bgClass: "bg-primary",
          // iconClass: "bx bx-store",
          imageSrc: "https://d352xm44aq5j2v.cloudfront.net/icons/menu/NewGuideItemsIOwn.png",
          description: "-",
          // list: retailers ? retailers : [],
          customLength: 825,
          isLoading: false,
          // alert: retailers?.TO_REVIEW ? `TO REVIEW (${retailers?.TO_REVIEW})` : "",
          showDetailsOnClick: () => {},
          showDetailsText: "Click to view the daily chart"
        },
        {
          title: t("Number of products in shopping"),
          bgClass: "bg-primary",
          imageSrc: "https://d352xm44aq5j2v.cloudfront.net/icons/menu/NewGuideWantToBuyShopping.png",
          description: "-",
          // list: charities ? charities : [],
          //@ts-ignore
          customLength: 3526,
          isLoading: false,
          // alert: charities?.TO_REVIEW ? `TO REVIEW (${charities?.TO_REVIEW})` : "",
          showDetailsOnClick: () => {},
          showDetailsText: "Click to view the daily chart"
        },
        {
          title: t("Number of products in marketplace"),
          bgClass: "bg-primary",
          imageSrc: "https://d352xm44aq5j2v.cloudfront.net/icons/menu/NewGuideWantToBuyMarketplace.png",
          // iconSrc: "https://d352xm44aq5j2v.cloudfront.net/icons/menu/NewGuideItemsIOwn.png",
          description: "-",
          // list: charities ? charities : [],
          //@ts-ignore
          customLength: 1723,
          alert: schools?.TO_REVIEW ? `TO REVIEW (${schools?.TO_REVIEW})` : "",
          isLoading: false,
          showDetailsOnClick: () => {},
          showDetailsText: "Click to view the daily chart"
        },
        {
          title: t("Number of generated package returns"),
          bgClass: "bg-primary",
          // iconClass: "bx bx-return",
          imageSrc: "https://d352xm44aq5j2v.cloudfront.net/icons/menu/NewGuideItemsIDontWantTakebackNew.png",
          description: "-",
          // list: customers ? customers : [],
          //@ts-ignore
          customLength: 728,
          isLoading: false,
          showDetailsOnClick: () => {},
          showDetailsText: "Click to view the daily chart"
        },
        {
          title: t("Number of profile views"),
          bgClass: "bg-primary",
          // iconClass: "bx bx-user",
          imageSrc: "https://d352xm44aq5j2v.cloudfront.net/icons/menu/NewGuideOthersWardrobesFindFriends.png",
          description: "-",
          // list: admins ? admins : [],
          customLength: 2456,
          isLoading: false,
          showDetailsOnClick: () => {},
          showDetailsText: "Click to view the daily chart"
        },
      ]
    )
  }, [customers, retailers, charities, schools, admins, isLoadingCharities])

  const handleGetCustomers = async () => {
    try {
      setIsLoadingCustomers(true)
      // const response = await getCustomersAPI(1)
      // setCustomers(response)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoadingCustomers(false)
    }
  }

  useEffect(() => {
    // handleGetSchools();
    // handleGetCharities()
    
    // dispatch(getSchools())
    // dispatch(getCharities())
    // dispatch(getRetailers())
    // handleGetCustomers();
    // dispatch(getAdmins())
  }, [])

  return (
    <div className="page-content">
      <Container fluid>
        <h3>{t("Wardrobe Analytics")}</h3>
        <Row>
          {/* <Col sm="5">
            <WelcomePanel
              user={user}
            />
          </Col> */}
          <Col sm="12">
            <Row>
              {reports.map(
                ({ title, description, bgClass, iconClass, iconSrc, imageSrc, customLength, onRedirect, isLoading, alert, showDetailsText, showDetailsOnClick }: SmallTileProps, index: number) => (
                  <SmallTile
                    col={3}
                    key={"_col_" + index}
                    bgClass={bgClass}
                    title={title}
                    description={description}
                    iconClass={iconClass}
                    iconSrc={iconSrc}
                    imageSrc={imageSrc}
                    list={undefined}
                    customLength={customLength}
                    isLoading={isLoading}
                    onRedirect={onRedirect}
                    alert={alert}
                    showDetailsOnClick={showDetailsOnClick}
                    showDetailsText={showDetailsText}
                  />
                )
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <CustomTabs2
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            labels={[
              // "ERRORS",
              "Wardrobes",
              "Shopping & Marketplace",
              "Marketing",
              "Second Life Options",
            ]}
            className="dashboard-tabs"
            lazyLoadTabs={true}
          >
            <WardrobesStatsTab t={t} />
            <MarketplaceAndShoppingStatsTab t={t} />
            <MarketingStatsTab t={t} />
            <SecondLifeOptionsStatsTab t={t} />
          </CustomTabs2>
        </Row>
      </Container>
    </div>
  )
}

export default withTranslation()(Dashboard)
