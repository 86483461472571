import React from "react"

interface ISwitchCustom {
    value: boolean;
    handleSwitchChange: () => void;
    offText?: string;
    onText?: string;
    disabled?: boolean;
    size?: string;
    onOffText?: string;
}

const SwitchCustom: React.FC<ISwitchCustom> = ({
    handleSwitchChange,
    value,
    offText,
    onText,
    onOffText,
    disabled,
    size
}) => {
    return (
        <div
            className="form-check form-switch form-switch-md custom-switch"
        >
            {offText ? <p className={!value ? "custom-switch__active" : ""}>{offText}</p> : null}
            <input
                type="checkbox"
                className="form-check-input mt-0"
                id="customSwitchsizemd"
                checked={value}
                onChange={(e) => handleSwitchChange()}
                disabled={disabled}
                style={{
                    ...(size === "lg" ? { height: 30, width: 60 } : {})
                }}
            />
            {onText ? <p className={value ? "custom-switch__active" : ""}>{onText}</p> : null}
            {onOffText ? <p onClick={(e) => handleSwitchChange()} className={`ms-1 ${value ? "custom-switch__active" : ""}`}>{onOffText}</p> : null}
        </div>
    )
}

export default SwitchCustom;