import React, { useEffect, useState } from "react"
// @ts-ignore
import { useDispatch } from "react-redux"
import {
    Button,
    Label,
    Row,
    Col,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap"
import FormInput from "../../../components/Common/FormInput"

interface VerifyDomainInterface {
    returnTranslation: (name: string) => string,
    setActiveStep: any,
    url: string,
    formErrors: any,
    setUrl: any,
    handleSubmit: any,
    showBackBtn: boolean
}

const VerifyDomain = ({
    returnTranslation,
    setActiveStep,
    url,
    formErrors,
    setUrl,
    handleSubmit,
    showBackBtn
}: VerifyDomainInterface) => {
    const dispatch = useDispatch()

    return (
        <>
            {/* <InputGroup> */}
                {/* <InputGroupAddon addonType="prepend">
                    <InputGroupText>https://</InputGroupText>
                </InputGroupAddon> */}
                <FormInput
                    addonType="prepend"
                    addonText="https://"
                    disabled={false}
                    label={returnTranslation("Enter your website to verify")}
                    type="text"
                    name="url"
                    className="form-control"
                    //@ts-ignore
                    placeholder={returnTranslation("Enter website url")}
                    value={url}
                    showErrors
                    formErrors={formErrors}
                    onChange={(e: any) => setUrl(e.target.value)}
                />
            {/* </InputGroup> */}

            <div style={{
                display: "flex",
                justifyContent: "center",

            }}>
                {showBackBtn &&
                    <div className="text-center mt-4" style={{ marginRight: "5px" }}>
                        <Button onClick={() => setActiveStep("Choose the platform")} color="light">
                            {/* {params?.uuid ? returnTranslation("Update") : returnTranslation("Add website")} */}
                            {returnTranslation("Back")}
                        </Button>
                    </div>
                }
                <div className="text-center mt-4" style={{ marginLeft: "5px" }}>
                    <Button onClick={(e) => {
                        handleSubmit(e)
                    }} color="success">
                        {/* {params?.uuid ? returnTranslation("Update") : returnTranslation("Add website")} */}
                        {returnTranslation("Next")}
                    </Button>
                </div>
            </div>
        </>
    )
}

export default VerifyDomain
