import React, { useState, useEffect } from "react"
import FormInput from "../../../components/Common/FormInput"
import plusGreenBg from "../../../assets/images/plus-green-bg.svg"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Row,
  FormGroup,
  Label,
  Progress,
} from "reactstrap"
//@ts-ignore
import { Link } from "react-router-dom"
import Dropzone, { useDropzone } from "react-dropzone"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { post, put } from "../../../helpers/api_helper"
import { apiError, openAlert, getWebsites } from "../../../store/actions"
import * as url from "../../../helpers/url_helper"
import { getCurrencies } from "../../../store/currencies/actions"
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
import { getCoupon, getCouponCodesImportStats } from "../../../helpers/backend_helper"
// @ts-ignore
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import axios from "axios"
import ImportCodes from "../ImportCodes"
import CouponCodesTable from "./CouponCodesTable/CouponCodesTable"

interface AddCodeInterface {
  history: any,
  match: any,
  returnTranslation: (name: string) => string
}

const AddCoupon = ({
  history,
  match: { params },
  returnTranslation,
}: AddCodeInterface) => {
  const dispatch = useDispatch()
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [shortDescription, setShortDescription] = useState("")
  const [selectedSiteId, setSelectedSiteId] = useState(0)
  const [type, setType] = useState("")
  const [value, setValue] = useState("")
  const [currency_id, setCurrency_id] = useState("1")
  const [site_id, setSite_id] = useState("")
  const [start_at, setStart_at] = useState("")
  const [expires_at, setExpires_at] = useState("")
  const [formErrors, setFormErrors] = useState([])
  const [edittingCode, setEdittingCode] = useState(false)
  const [editedCouponId, setEditedCouponId] = useState(0)
  const [editedCouponThumbnailPreview, setEditedCouponThumbnailPreview] =
    useState("")
  const [editedCouponHeaderPreview, setEditedCouponHeaderPreview] = useState("")
  const [editedCouponFooterPreview, setEditedCouponFooterPreview] = useState("")
  const [selectedThumbnail, setSelectedThumbnail] = useState([])
  const [selectedHeader, setSelectedHeader] = useState([])
  const [selectedFooter, setSelectedFooter] = useState([])
  const [triggerRefreshCodesTable, setTriggerRefreshCodesTable] = useState(false)
  const [couponCodesImportStats, setCouponCodesImportStats] = useState<any>(null);
  const [intervalId, setIntervalId] = useState<any>(0);

  const dashboardID = useSelector((state: any) => state?.Account?.dashbord_id)
  // const websites = useSelector((state: any) => state?.websites?.websites)
  const [websites, setWebsites] = useState([])
  const userToken = useSelector(
    (state: any) => state?.Account?.user?.access_token
  )
  const currencies = useSelector((state: any) => state?.Currencies?.currencies)

  useEffect(() => {
    if (params?.uuid) {
      getCoupon({ id: params?.uuid }).then(res => {
        let selectedCurrency = currencies.find(
          (singleCurrency: any) => singleCurrency.name === res?.currency
        )
        setCurrency_id(selectedCurrency?.id)
        setExpires_at(res?.expires_at)
        setDescription(res?.description)
        setShortDescription(res?.short_description)
        setStart_at(res?.start_at)
        setType(res?.type)
        setValue(res?.value)
        setEditedCouponId(res?.id)
        setEditedCouponThumbnailPreview(res?.thumbnail)
        setEditedCouponHeaderPreview(res?.header)
        setEditedCouponFooterPreview(res?.footer)
        setName(res?.name)
        setEdittingCode(true)
      })
    }
  }, [params?.uuid, currencies])

  useEffect(() => {
    getWebsites()
    dispatch(getCurrencies())
    handleGetCouponCodesImportStats();
    setInitialTodayDate()
  }, [])

  useEffect(() => {
    if (websites.length) {
      //@ts-ignore
      setSite_id(websites[0].id)
    }
  }, [websites])

  useEffect(() => {
    if (couponCodesImportStats?.status === 0) {
      const intervalId = setInterval(() => {
        handleGetCouponCodesImportStats()
      }, 5000)
      setIntervalId(intervalId);
    }else{
      clearInterval(intervalId);
      //refresh table to show imported codes
      setTriggerRefreshCodesTable(!triggerRefreshCodesTable)
    }
    return () => clearInterval(intervalId);
  }, [couponCodesImportStats])

  const handleGetCouponCodesImportStats = async () => {
    try{
      const response = await getCouponCodesImportStats(params?.uuid);
      setCouponCodesImportStats(response?.items[0]);
    }catch(e){
      console.log(e)
    }
  }

  const getWebsites = (page: number = 1) => {
    return new Promise(async (resolve, reject) => {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "Client-UUID": dashboardID,
          Authorization: `Bearer ${userToken}`,
          "Custom-User-Agent": window.navigator.userAgent
        },
      };

      axios
        .get(`${url.API_BASE}sites?page=${page}&per_page=100`, config)
        .then(response => {
          if (response.data) {
            const { info: { page_count, current_page }, items } = response.data;
            if (items?.length) {
              setWebsites(items);
            }

          }

          return resolve(response)
        })
        .catch(error => {
          if (error?.response?.data) {
            // const { message, errors } = error?.response?.data
            // this.props.onOpenAlert({
            //   text: message,
            //   status: "danger",
            // })
          }
          return reject(error)
        })
    })
  }

  const setInitialTodayDate = () => {
    let today = new Date()
    setExpires_at(today?.toISOString()?.split("T")[0])
    setStart_at(today?.toISOString()?.split("T")[0])
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        "Client-UUID": "9fde47f3-c8f8-46a8-8bed-a587032d9aa0",
        Authorization: `Bearer ${userToken}`,
        "Custom-User-Agent": window.navigator.userAgent,
      },
    }

    if (type === "CASH" && !currency_id) {
      dispatch(
        openAlert({
          text: returnTranslation("All fields required"),
          status: "danger",
        })
      )
    } else {
      return new Promise(async (resolve, reject) => {
        if (!edittingCode) {
          // console.log([
          //   "post",
          //   type,
          //   value,
          //   currency_id ? currency_id : 1,
          //   expires_at,
          // ])

          if (
            name &&
            description &&
            shortDescription &&
            expires_at &&
            start_at &&
            type &&
            value &&
            site_id &&
            selectedThumbnail[0] &&
            selectedHeader[0] &&
            selectedFooter[0]
          ) {
            return new Promise(async (resolve, reject) => {

              const formData = new FormData()
              formData.append("name", name)
              formData.append("description", description)
              formData.append("short_description", shortDescription)
              formData.append("expire_at", expires_at)
              formData.append("start_at", start_at)
              formData.append("type", type)
              formData.append("currency_id", currency_id)
              formData.append("value", value)
              formData.append("sites_ids[0]", site_id)
              formData.append("thumbnail", selectedThumbnail[0])
              formData.append("header", selectedHeader[0])
              formData.append("footer", selectedFooter[0])
              formData.append("retailer_id", selectedFooter[0])
              formData.append("active", "0")

              axios
                .post(`${url.API_BASE}retailers/coupons`, formData, config)
                .then(response => {
                  dispatch(
                    openAlert({
                      text: returnTranslation("Success"),
                      status: response?.status,
                    })
                  )
                  history.push(`/coupons`)

                  return resolve(response.data)
                })
                .catch(error => {
                  if (error?.response?.data) {
                    const { message, errors } = error?.response?.data
                    setFormErrors(errors)

                    dispatch(openAlert({ text: message, status: status }))
                  }
                  return reject(error)
                })
            })
          } else {
            dispatch(
              openAlert({ text: "All fields required", status: "danger" })
            )
          }
        } else {
          put(`${url.API_BASE}retailers/coupons/${params?.uuid}`, {
            name,
            description,
            short_description: shortDescription,
            expire_at: expires_at,
            start_at,
            type,
            currency_id: currency_id ? currency_id : 1,
            value,
          })
            .then(response => {
              dispatch(
                openAlert({
                  text: returnTranslation("Success"),
                  status: response?.status,
                })
              )
              history.push(`/coupons`)

              return resolve(response)
            })
            .catch(error => {
              if (error?.response?.data) {
                const { message, errors } = error?.response?.data
                setFormErrors(errors)

                dispatch(openAlert({ text: message, status: status }))
              }
              return reject(error)
            })
        }
      })
    }
  }

  const convertBlobToBinary = (blob: any) => {
    var reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onloadend = (event) => {
      // The contents of the BLOB are in reader.result:
      return reader.result;
      // console.log(["convertBlobToBinary", reader.result]);
    }
  }

  const handleChangeType = (event: any) => {
    setType(event.target.value)
  }

  const handleChangeCurrency = (event: any) => {
    setCurrency_id(event.target.value)
  }

  const handleChangeSites = (event: any) => {
    setSite_id(event.target.value)
  }

  const handleChangeDate = (date: any, type: string) => {
    let formattedDate = new Date(date)
    if (type === "expired_at") {
      setExpires_at(formattedDate?.toISOString()?.split("T")[0])
    } else if (type === "start_at") {
      setStart_at(formattedDate?.toISOString()?.split("T")[0])
    }
  }

  const adjustTimezone = (date: any) => {
    return new Date(
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
    )
  }

  const returnUTCString = (date: string) => {
    let formattedTimestamp = new Date(date)
    return formattedTimestamp
  }

  const handleAcceptedFiles = async (files: any, type: string) => {
    const promises = files.map(async (file: any) => {
      return new Promise(async (resolve, reject) => {
        const reader = new FileReader()

        if (file.type.match("image.*")) {
          reader.onabort = () => console.log("file reading was aborted")
          reader.onerror = () => console.log("file reading has failed")

          reader.onload = async () => {
            file = new Blob([file], { type: "image/png" })

            try {
              Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
              })
              resolve(file)
            } catch (err) {
              // console.log(["error msg", err])
            }
          }
          reader.readAsDataURL(file)
        } else {
          // console.log("not image")
        }
      })
    })

    const newFiles = await Promise.all(promises)

    if (type === "thumbnail") {
      // console.log(["addFiles", newFiles])
      //@ts-ignore
      setSelectedThumbnail(newFiles)
    } else if (type === "header") {
      //@ts-ignore
      setSelectedHeader(newFiles)
    } else if (type === "footer") {
      //@ts-ignore
      setSelectedFooter(newFiles)
    }
  }

  const {
    acceptedFiles,
    fileRejections,
    isDragActive,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: "image/jpeg, image/png",
    maxFiles: 1,
  })

  const formatBytes = (bytes: any, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const initialDate = new Date()

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Coupons"
            breadcrumbItem={!edittingCode ? "Add new coupon" : "Edit coupon"}
          />

          <Form onSubmit={e => handleSubmit(e)}>
            <Card>
              <CardBody>
                <Row>
                  <Col lg="6" sm="12">

                    <div className="mb-3">
                      {/* @ts-ignore */}
                      <FormInput
                        disabled={false}
                        label={returnTranslation("Select Website")}
                        type="select"
                        name="site_id"
                        className="form-control"
                        placeholder={returnTranslation("Choose")}
                        showErrors
                        formErrors={formErrors}
                        onChange={handleChangeSites}
                        value={site_id}
                        options={websites?.length && websites?.map((website: any, i: number) => {
                          return (
                            <option key={website?.id} value={website?.id}>
                              {website?.name}
                            </option>
                          )
                        })}
                      />
                    </div>
                    <div className="mb-3">
                      {/* @ts-ignore */}
                      <FormInput
                        disabled={false}
                        label={returnTranslation("Name")}
                        type="text"
                        name="name"
                        value={name}
                        className="form-control"
                        placeholder={returnTranslation("Enter name")}
                        showErrors
                        formErrors={formErrors}
                        onChange={(e: any) => {
                          setName(e.target.value)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      {/* @ts-ignore */}
                      <FormInput
                        disabled={false}
                        label={returnTranslation("Description")}
                        type="textarea"
                        name="description"
                        value={description}
                        className="form-control"
                        placeholder={returnTranslation("Enter description")}
                        showErrors
                        formErrors={formErrors}
                        onChange={(e: any) => {
                          setDescription(e.target.value)
                        }}
                        rows={4}
                      />
                    </div>
                    <div className="mb-3">
                      {/* @ts-ignore */}
                      <FormInput
                        disabled={false}
                        label={returnTranslation("Short Description")}
                        type="text"
                        name="short_description"
                        value={shortDescription}
                        className="form-control"
                        placeholder={returnTranslation(
                          "Enter short description"
                        )}
                        showErrors
                        formErrors={formErrors}
                        onChange={(e: any) => {
                          setShortDescription(e.target.value)
                        }}
                      />
                    </div>



                    <div className="mb-3">
                      {/* @ts-ignore */}
                      <FormInput
                        disabled={false}
                        label={returnTranslation("Type")}
                        type="select"
                        name="type"
                        className="form-control"
                        placeholder={returnTranslation("Choose")}
                        showErrors
                        formErrors={formErrors}
                        onChange={handleChangeType}
                        value={type}
                        options={
                          <>
                            <option key={"CASH"} value={"CASH"}>
                              {/* {returnTranslation("Cash")} */}
                              {returnTranslation("£ off orders")}
                            </option>
                            <option key={"PERCENT"} value={"PERCENT"}>
                              {/* {returnTranslation("Percent")} */}
                              {returnTranslation("% off orders")}
                            </option>
                          </>
                        }
                      />
                    </div>

                    {type === "CASH" && (
                      <div className="mb-3">
                        {/* @ts-ignore */}
                        <FormInput
                          disabled={false}
                          label={returnTranslation("Currency")}
                          type="select"
                          name="currency_id"
                          className="form-control"
                          placeholder={returnTranslation("Choose")}
                          showErrors
                          formErrors={formErrors}
                          onChange={handleChangeCurrency}
                          value={currency_id}
                          options={currencies?.map(
                            (currency: any, i: number) => {
                              return (
                                <option key={currency?.id} value={currency?.id}>
                                  {currency?.name}
                                </option>
                              )
                            }
                          )}
                        />
                      </div>
                    )}



                    <div className="mb-3">
                      {/* @ts-ignore */}
                      <FormInput
                        disabled={false}
                        label={returnTranslation("Value")}
                        type="value"
                        name="value"
                        value={value}
                        className="form-control"
                        placeholder={returnTranslation("Enter value")}
                        showErrors
                        formErrors={formErrors}
                        onChange={(e: any) => {
                          setValue(e.target.value)
                        }}
                      />
                    </div>

                    <Row className="mb-3">
                      <Col md={6} className="mb-3">
                        <label
                          htmlFor="example-datetime-local-input"
                          className="col-form-label"
                        >
                          {returnTranslation("Start Date")}
                        </label>
                        <DatePicker
                          selected={
                            start_at ? returnUTCString(start_at) : initialDate
                          }
                          onChange={(date: any) =>
                            handleChangeDate(adjustTimezone(date), "start_at")
                          }
                          className="form-control"
                        />
                      </Col>
                      <Col md={6} className="mb-3">
                        <label
                          htmlFor="example-datetime-local-input"
                          className="col-form-label"
                        >
                          {returnTranslation("Expiry Date")}
                        </label>
                        <DatePicker
                          selected={
                            expires_at
                              ? returnUTCString(expires_at)
                              : initialDate
                          }
                          onChange={(date: any) =>
                            handleChangeDate(adjustTimezone(date), "expired_at")
                          }
                          className="form-control"
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col lg="6" sm="12">
                    <div className="mb-3">
                      <FormGroup row>
                        <Label>
                          {returnTranslation("Upload Logo")}
                        </Label>
                        <Col lg="12">
                          <Dropzone
                            onDrop={acceptedFiles =>
                              handleAcceptedFiles(acceptedFiles, "thumbnail")
                            }
                            maxFiles={1}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <img src={plusGreenBg} />
                                    <h4>
                                      {returnTranslation(
                                        "Drop files here or click to upload"
                                      )}
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {selectedThumbnail.map((
                              f: {
                                name: string,
                                preview: string,
                                formattedSize: string
                              },
                              i
                            ) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            }
                            )}
                            {editedCouponThumbnailPreview && (
                              <img
                                src={editedCouponThumbnailPreview}
                                height="300px"
                              />
                            )}
                          </div>
                        </Col>
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup row>
                        <Label>
                          {returnTranslation("Upload Lifestyle Image 1")}
                        </Label>
                        <Col lg="12">
                          <Dropzone
                            onDrop={acceptedFiles =>
                              handleAcceptedFiles(acceptedFiles, "header")
                            }
                            maxFiles={1}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <img src={plusGreenBg} />
                                    <h4>
                                      {returnTranslation(
                                        "Drop files here or click to upload"
                                      )}
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {selectedHeader.map((
                              f: {
                                name: string,
                                preview: string,
                                formattedSize: string
                              },
                              i
                            ) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            }
                            )}
                            {editedCouponHeaderPreview && (
                              <img
                                src={editedCouponHeaderPreview}
                                height="300px"
                              />
                            )}
                          </div>
                        </Col>
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup row>
                        <Label>
                          {returnTranslation("Upload Lifestyle Image 1")}
                        </Label>
                        <Col lg="12">
                          <Dropzone
                            onDrop={acceptedFiles =>
                              handleAcceptedFiles(acceptedFiles, "footer")
                            }
                            maxFiles={1}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <img src={plusGreenBg} />
                                    <h4>
                                      {returnTranslation(
                                        "Drop files here or click to upload"
                                      )}
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {selectedFooter.map((
                              f: {
                                name: string,
                                preview: string,
                                formattedSize: string
                              },
                              i
                            ) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            }
                            )}
                            {editedCouponFooterPreview && (
                              <img
                                src={editedCouponFooterPreview}
                                height="300px"
                              />
                            )}
                          </div>
                        </Col>
                      </FormGroup>
                    </div>
                    {/* {edittingCode && (
                      <>
                        <h4 className="mb-3 font-size-18">
                          {returnTranslation("Add new codes")}
                        </h4>
                        <ImportCodes
                          history={history}
                          returnTranslation={returnTranslation}
                          couponId={params?.uuid}
                          callbackAfterSubmitCodes={() =>
                            setTriggerRefreshCodesTable(
                              !triggerRefreshCodesTable
                            )
                          }
                        />
                      </>
                    )}

                    {edittingCode && (
                      <CouponCodesTable
                        returnTranslation={returnTranslation}
                        triggerRefresh={triggerRefreshCodesTable}
                        couponId={params?.uuid}
                      />
                    )} */}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {edittingCode && (
                      <>
                        <h4 className="mb-3 mt-5 font-size-18">
                          {returnTranslation("Add new codes")}
                        </h4>
                        {couponCodesImportStats?.status === 0 ? (
                          <div>
                            <div className="text-center">Import in progress... {`${Math.ceil(((100/couponCodesImportStats?.count) * couponCodesImportStats?.added))}%`}</div>
                            <Progress
                              striped
                              animated={true}
                              color={"#AEE8B3"}
                              value={((100/couponCodesImportStats?.count) * couponCodesImportStats?.added)}
                            />
                          </div>
                        ): (
                          <ImportCodes
                            noDropzoneColumnsLayout={true}
                            history={history}
                            returnTranslation={returnTranslation}
                            couponId={params?.uuid}
                            callbackAfterSubmitCodes={() => {
                              setTimeout(() => handleGetCouponCodesImportStats(), 2000);
                              setTimeout(() => handleGetCouponCodesImportStats(), 5000);
                              setTriggerRefreshCodesTable(!triggerRefreshCodesTable)
                            }}
                          />
                        )}
                        
                      </>
                    )}
                  </Col>
                </Row>

                <Row>
                  <div className="mt-4">
                    <button
                      className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                    >
                      {returnTranslation("Submit")}
                    </button>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Form>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  {edittingCode && (
                    <CouponCodesTable
                      returnTranslation={returnTranslation}
                      triggerRefresh={triggerRefreshCodesTable}
                      couponId={params?.uuid}
                    />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddCoupon
