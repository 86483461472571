import React from "react"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { openAlert } from "../../../../store/actions"
import {
  Button,
  Label,
  Input,
} from "reactstrap"
//@ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface CouponCodesTableColumnsInterface {
  handleDelete: (id: string, name: string) => void;
  handleSetActive: (id: string, active: number) => void;
  returnTranslation: (name: string) => string;
}

const CouponCodesTableColumns = ({ returnTranslation, handleDelete, handleSetActive }: CouponCodesTableColumnsInterface) => {
  const dispatch = useDispatch()

  // const handleCodeCopy = (code: any) => {
  //   //code from api
  //   // let code = "test code"

  //   const el = document.createElement("textarea")
  //   el.value = code
  //   document.body.appendChild(el)
  //   el.select()
  //   document.execCommand("copy")
  //   document.body.removeChild(el)

  //   dispatch(openAlert({ text: returnTranslation("Copied"), status: "success" }))
  // }

  return [
    // {
    //   dataField: "i",
    //   isDummyField: true,
    //   text: "#",
    //   formatter: (cellContent, row, rowIndex) => <div>{pageOffset + rowIndex + 1}</div>,
    // },
    {
      dataField: "code",
      text: returnTranslation("Code"),
      formatter: (cellContent: any, row: any, rowIndex: any) => (
        <div style={{ marginTop: "15px" }}>
          <div className="input-group mb-3">
            <Input type="text" className="form-control" style={{ maxWidth: 100 }} value={row?.code} id={`inputGroupFile-${row?.code}`} />
            <CopyToClipboard text={row?.code}>
              <Label onClick={() => dispatch(openAlert({ text: returnTranslation("Copied"), status: "success" }))}
                className="input-group-text clickable"
                htmlFor={`inputGroupFile-${row?.code}`}
              >
                Copy
              </Label>
            </CopyToClipboard>
          </div>
        </div>
      )
    },
    // {
    //   dataField: "",
    //   formatter: (cellContent: any, row: any, rowIndex: any) => (
    //     <Button onClick={() => handleCodeCopy(row.code)} color="success">
    //       {returnTranslation("Copy")}
    //     </Button>
    //   ),
    //   text: returnTranslation("Copy code"),
    // },
    {
      dataField: "issued",
      isDummyField: true,
      text: returnTranslation("Status"),
      formatter: (cellContent: any, row: any, rowIndex: any) => (
        <div className="d-flex gap-3">
          <b className={row.issued ? "text-danger" : "text-success"}>
            {row.issued ? (
              <span className="bg-danger badge badge-success">{returnTranslation("Used")}</span>
            ) : (
              <span className="bg-success badge badge-success">
                {returnTranslation("Unused")}
              </span>
            )}
          </b>
        </div>
      ),
    },
    {
      dataField: "expire_at",
      text: returnTranslation("Expires At"),
      formatter: (cellContent: any, row: any, rowIndex: any) => (
        <div>
          {new Date(row?.expire_at) > new Date() 
            ? row?.expire_at 
            : <div className="text-danger">{row?.expire_at} (Expired)</div>}
        </div>
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "",
      formatter: (cellContent: any, row: any, rowIndex: any) => (
        <div className="d-flex gap-3">
          <span
            className="text-danger clickable"
            onClick={() => handleDelete(row.id, row.code)}
          >
            <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
          </span>
          <span
            className={"clickable " + (row.active == 1 ? "text-danger" : "text-success")}
            onClick={() => handleSetActive(row.id, row.active == 1 ? 0 : 1)}
          >
            <p className="active-coupon-btn">{row.active == 1 ? returnTranslation("Set inactive") : returnTranslation("Set active")}</p>
          </span>
        </div>
      ),
    },
  ]
}

export default CouponCodesTableColumns
