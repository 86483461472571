import React, { useEffect, useState } from 'react'
import FeederGridItem from './FeederGridItem/FeederGridItem'
import './FeederGrid.scss'
import { get, post } from '../../../../helpers/api_helper'
import * as url from '../../../../helpers/url_helper';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { Button, Spinner } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { openAlert, requestConfirmation } from '../../../../store/actions';

interface Props {
    feederId: number;
    setFeedItemsCount?: any;
    t: (text: string) => string;
}

function FeederGrid({ feederId, t, setFeedItemsCount}: Props) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingExcludeAll, setIsLoadingExcludeAll] = useState(false)
    const [isLoadingIncludeAll, setIsLoadingIncludeAll] = useState(false)
    const [foundErrors, setFoundErrors] = useState(false)

    const [feedItems, setFeedItems] = useState<any>({
        info: {
            current_page: 0,
            page_count: 0,
            total_items: 0,
            total_pages: 0
        },
        items: []
    })

    const scrollRef = useBottomScrollListener(
        () => {
            const { current_page, total_pages } = feedItems?.info;
            if (current_page + 1 <= total_pages) {
                getFeederItems(feederId, current_page + 1);
            }
        },
        {
            triggerOnNoScroll: false,
            offset: 300,
            debounce: 500,
        }
    )

    const onOpenAlert = (text: string, status: string) => dispatch(openAlert({ text, status }));

    useEffect(() => {
        getFeederItems(feederId);
    }, [])

    const getFeederItems = async (id: number, page = 1) => {
        try {
            setIsLoading(true)
            const response = await get(`${url.API_BASE}retailers/feed_items?page=${page}&feed_id=${id}`)
            setFeedItemsCount(response?.info?.total_items)
            if (page > 1) {
                setFeedItems({
                    items: [...feedItems?.items, ...response?.items],
                    info: response?.info
                })
            } else {
                setFeedItems(response)
            }
        } catch (e) {
            // console.log(e)
        } finally {
            setIsLoading(false)
        }
    }

    const handleExclude = async (itemId: number, exclude: boolean) => {
        // console.log(["handleExclude", exclude])
        try {
            const response = await post(`${url.API_BASE}retailers/feed_items/${itemId}/exclude`, {
                excluded: exclude ? 1 : 0
            })
            if (response?.status === "success") {
                onOpenAlert(`Item ${exclude ? 'deactivated' : 'activated'}.`, "success")
                return true
            }
            return false;
        } catch (e) {
            // console.log(e)
            onOpenAlert("Error.", "danger")
            return false
        }
    }

    const handleExcludeAll = async (exclude: boolean) => {
        dispatch(requestConfirmation(`${t(`${!exclude ? "Activate" : "Deactivate"} all items`)}?`, async () => {
            try {
                exclude && setIsLoadingExcludeAll(true)
                !exclude && setIsLoadingIncludeAll(true)

                const response = await post(`${url.API_BASE}retailers/feed_items/exclude`, {
                    excluded: exclude ? 1 : 0,
                    exclude_discovery: exclude ? 1 : 0,
                    feed_id: feederId
                    // ...(exclude ? { exclude_discovery: 1 } : {})
                })
                if (response?.status === "success") {
                    getFeederItems(feederId)
                    onOpenAlert(`All items has been ${exclude ? 'deactivated' : 'activated'}.`, "success")
                    return true
                }
                return false;
            } catch (e) {
                // console.log(e)
                onOpenAlert("Error.", "danger")
                return false
            } finally {
                exclude && setIsLoadingExcludeAll(false)
                !exclude && setIsLoadingIncludeAll(false)
            }
        },
            undefined,
            !exclude ? 'Yes, Activate!' : 'Yes, Deactivate!',
        ))
    }

    const handleExcludeFromDiscovery = async (itemId: number, exclude: boolean, showSuccessAlert = true): Promise<boolean | undefined> => {
        // console.log(["handleExcludeFromDiscovery", exclude])

        try {
            const response = await post(`${url.API_BASE}retailers/feed_items/${itemId}/exclude`, {
                exclude_discovery: exclude ? 1 : 0
            })
            if (response?.status === "success") {
                showSuccessAlert && onOpenAlert(`Item ${exclude ? 'excluded from discovery' : 'included in discovery'}.`, "success")
                return true
            }
            return false;
        } catch (e) {
            // console.log(e)
            onOpenAlert("Error.", "danger")
            return false
        }
    }

    const formatNumber = (num: number) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h4 className="card-title mb-2">{t('Items')} {`(${formatNumber(feedItems?.info?.total_items)})`}</h4>
                    {foundErrors ? <span className="text-danger">Errors has been found, items with red border won't be displayed in marketplace.</span> : null}
                </div>
                {feedItems?.items?.length ? (
                    <div>
                        <Button type="button"
                            color="primary"
                            className="m-1"
                            onClick={() => handleExcludeAll(false)}
                            style={{
                                width: 140
                            }}
                        >
                            {isLoadingIncludeAll ? (
                                <Spinner
                                    className="me-2"
                                    color="#fff"
                                    style={{
                                        width: ' 0.8rem',
                                        height: '0.8rem',
                                        borderWidth: '0.2em'
                                    }}
                                />
                            ) : null}
                            {t('Activate all')}
                        </Button>
                        <Button type="button"
                            color="primary"
                            className="m-1"
                            onClick={() => handleExcludeAll(true)}
                            style={{
                                width: 140
                            }}
                        >
                            {isLoadingExcludeAll ? (
                                <Spinner
                                    className="me-2"
                                    color="#fff"
                                    style={{
                                        width: ' 0.8rem',
                                        height: '0.8rem',
                                        borderWidth: '0.2em'
                                    }}
                                />
                            ) : null}
                            {t('Deactivate all')}
                        </Button>
                    </div>
                ) : null}
            </div>

            <div className="feeder-grid">
                {feedItems?.items?.map((item: any) =>
                    <FeederGridItem
                        key={item?.id}
                        item={item}
                        handleExcludeFromDiscovery={handleExcludeFromDiscovery}
                        handleExclude={handleExclude}
                        setFoundErrors={setFoundErrors}
                    />
                )}
                {isLoading ? (
                    <div className="w-100 d-flex justify-content-center align-items-center">
                        <Spinner className="ms-2" color="primary" />
                    </div>
                ) : null}
            </div>
        </>
    )
}

export default FeederGrid
