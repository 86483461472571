import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import { Route, Redirect, useHistory } from "react-router-dom"

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  returnTranslation,
  ...rest
}) => {
  // const history = useHistory();
  
  // useEffect(() => {
  //   console.log([history?.location?.pathname, history])
  // }, [history?.location])

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !localStorage.getItem("authUser")) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }

        return (
          <Layout returnTranslation={returnTranslation}>
            <Component {...props} returnTranslation={returnTranslation} />
          </Layout>
        )
      }}
    />
  )
}

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any
}

export default AppRoute
