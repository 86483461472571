import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_INVOICES
} from "./actionTypes"
import {
  getInvoicesFail,
  getInvoicesSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getInvoices
} from "./../../helpers/backend_helper"

function* getInvoicesAsync() {
  try {
    const response = yield call(getInvoices)
    // console.log(["getInvoicesAsync", response])
    yield put(getInvoicesSuccess(response))
  } catch (error) {
    yield put(getInvoicesFail(error))
  }
}

function* invoicesSaga() {
  yield takeEvery(GET_INVOICES, getInvoicesAsync)
}

export default invoicesSaga
