import { random } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FormInput from '../../../../components/Common/FormInput';
import { addPointsTag, delPointsTag } from '../../../../helpers/backend_helper';
import { openAlert } from '../../../../store/actions';
import "./AddTags.scss";
// import Tshirt from "../../../../assets/images/icons8-t-shirt-96.png";

export interface CharityShopTag {
    id: number;
    name: string;
    path?: string;
    lastused?: string;
    is_user_tag?: boolean;
    category_id?: number;
    logo?: {
        id: string | null;
        path: string;
    }
}

interface Props {
    tags: CharityShopTag[],
    setTags: (tags: CharityShopTag[]) => void;
    allowedIds: number[];
    setAllowedIds: (ids: number[]) => void;
    disallowedIds: number[];
    setDisallowedIds: (ids: number[]) => void;
    returnTranslation: (text: string) => string;
    manageTags?: boolean;
}

const AddTags = ({
    tags,
    setTags,
    setAllowedIds,
    allowedIds,
    setDisallowedIds,
    disallowedIds,
    returnTranslation,
    manageTags = true
}: Props) => {
    const dispatch = useDispatch();
    const [innerTags, setInnerTags] = useState<CharityShopTag[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [customTag, setCustomTag] = useState("");
    const user = useSelector((state: any) => state?.Account?.user);
    const onOpenAlert = ({ text, status }: any) => dispatch(openAlert({ text: text, status: status }));


    useEffect(() => {
        const filtered = tags
            // ?.sort((a, b) => (a?.name?.length > b?.name?.length) || a?.custom ? 1 : -1)
        setInnerTags(filtered)

    }, [tags?.length, allowedIds])
    
    const handleTagClick = (id: number) => {
        // console.log(['handleTagClick', id]);

        const filteredAllowed = allowedIds?.filter(selectedId => selectedId !== id);
        const filteredDisallowed = disallowedIds?.filter(selectedId => selectedId !== id);

        const isDefault = filteredAllowed?.length === allowedIds?.length;
        const isAllowed = filteredAllowed?.length < allowedIds?.length;
        const isDisallowed = filteredDisallowed?.length < disallowedIds?.length;
        // console.log({ isDefault, isAllowed, isDisallowed })

        if(isDisallowed){
            setAllowedIds(filteredAllowed)
            setDisallowedIds(filteredDisallowed)
            return;
        }

        if(isDefault){
            setAllowedIds([...allowedIds, id])
            setDisallowedIds(filteredDisallowed)
            return;
        }
        
        if(isAllowed){
            setDisallowedIds([...disallowedIds, id])
            setAllowedIds(filteredAllowed)
            return;
        }
        // return setAllowedIds(filteredAllowed);
    }

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    const handleAddNewTag = async () => {
        if(customTag === ""){
            return;
        }
        if(!!tags?.find(tag => tag?.name === customTag)){
            onOpenAlert({ text: returnTranslation(`Tag with name '${customTag}' already exists.`), status: "danger"})
            return;
        }

        try {
            const response = await addPointsTag(user?.role, customTag);
            // console.log(['handleAddNewTag response', response]);

            setTags([...tags, response?.tag])

            setCustomTag("")
        } catch(e) {
            onOpenAlert({ text: returnTranslation("Error."), status: "danger" })
            console.log(["error", e])
        }
    }

    const handleDeleteTag = async (selectedId: number) => {
        try {
            const response = await delPointsTag(user?.role, selectedId);
            // console.log(['handleDeleteTag', response]);

            const newTags = tags?.filter(tag => tag?.id !== selectedId)
            // console.log(['newTags', newTags]);
            const filteredAllowed = allowedIds?.filter(id => id !== selectedId);
            const filteredDisallowed = disallowedIds?.filter(id => id !== selectedId);
            setTags(newTags)
            setAllowedIds(filteredAllowed)
            setDisallowedIds(filteredDisallowed)
        } catch(e) {
            onOpenAlert({ text: returnTranslation("Error."), status: "danger" })
            console.log(["error", e])
        }
    }

    return (
        <>
            <div className="mb-2">
                {/* <div>allowed: {JSON.stringify(allowedIds)}</div>
                <div>disallowed: {JSON.stringify(disallowedIds)}</div> */}
                <Label>{returnTranslation("Tags")}</Label>
                <div className="tags-list--allowed">
                    {innerTags
                        // ?.filter(tag => ![...allowedIds, ...disallowedIds]?.includes(tag?.id))
                        ?.map(tag => (
                        <div key={tag?.id}
                            onClick={() => handleTagClick(tag?.id)}
                            className={`tags-list--allowed--single-tag ${allowedIds?.includes(tag?.id) ? 'allowed' : ""} ${disallowedIds?.includes(tag?.id) ? 'not-allowed' : ""}`}
                        >
                            {/* <i className={`fas ${[...allowedIds, ...disallowedIds]?.includes(tag?.id) ? 'fa-minus' : "fa-plus"}`} /> */}
                            {allowedIds?.includes(tag?.id) ? <i className="far fa-check-circle" /> : null}
                            {disallowedIds?.includes(tag?.id) ? <i className="far fa-times-circle" /> : null}
                            {![...allowedIds, ...disallowedIds]?.includes(tag?.id) ? <i className="fas fa-plus" /> : null}
                            <span>{tag?.name}</span>
                            {/* <img src={Tshirt} className="ms-2" height={24} /> */}
                            {tag?.logo?.id ? (
                                <img src={tag?.logo?.path} className="ms-2" height={24}  />
                            ): null}
                            
                        </div>
                    ))}
                    {manageTags ? (
                        <div className="tags-list--allowed--single-tag tags-list--allowed--single-tag--custom btn-success" onClick={toggleModal}>
                            <i className="fas fa-pen" />
                            <span>{returnTranslation("Manage tags...")}</span>
                        </div>
                    ): null}
                </div>
            </div>
            {/* {type === "allowed" ? ( */}
                <div className="mb-2">
                    <Label>{returnTranslation("Allowed")}</Label>
                    <div className="tags-list--allowed">
                        {innerTags?.filter(tag => allowedIds?.includes(tag?.id))?.map(tag => (
                            <div key={tag?.id}
                                onClick={() => handleTagClick(tag?.id)}
                                className="tags-list--allowed--single-tag selected"
                            >   
                                <i className="fas fa-minus" />
                                <span>{tag?.name}</span>
                                {/* <img src={Tshirt} className="ms-2" height={24} /> */}
                                {tag?.logo?.id ? (
                                    <img src={tag?.logo?.path} className="ms-2" height={24} />
                                ): null}
                            </div>
                        ))}
                        {!innerTags?.filter(tag => allowedIds?.includes(tag?.id))?.length ? (
                            <small className="ms-1 d-flex align-items-center" style={{color: "#a7a7a7", height: 37 }}>{returnTranslation("No tags")}</small>
                        ) : null}
                    </div>
                </div>
            {/* ) : null} */}
            
            {/* {type === "not_allowed" ? ( */}
                <div className="mb-2">
                    <Label>{returnTranslation("Not allowed")}</Label>
                    <div className="tags-list--not-allowed">
                        {innerTags?.filter(tag => disallowedIds?.includes(tag?.id))?.map(tag => (
                            <div key={tag?.id}
                                onClick={() => handleTagClick(tag?.id)}
                                className="tags-list--not-allowed--single-tag selected"
                            >
                                <i className="fas fa-minus" />
                                <span>{tag?.name}</span>
                                {/* <img src={Tshirt} className="ms-2" height={24} /> */}
                                {tag?.logo?.id ? (
                                    <img src={tag?.logo?.path} className="ms-2" height={24} />
                                ): null}
                            </div>
                        ))}
                        {!innerTags?.filter(tag => disallowedIds?.includes(tag?.id))?.length ? (
                            <small className="ms-1 d-flex align-items-center" style={{color: "#a7a7a7", height: 37 }}>{returnTranslation("No tags")}</small>
                        ) : null}
                    </div>
                </div>
            {/* ): null} */}

            <Modal
                isOpen={!!isModalOpen}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                //@ts-ignore
                tabIndex="-1"
                toggle={(ev: any) => toggleModal()}
            >
                <div className="modal-content">
                    <ModalHeader>{returnTranslation(`Manage tags`)}</ModalHeader>
                    <ModalBody>
                        <Label>Custom tag</Label>
                        <div className={`mb-3 d-flex`}> 
                            <FormInput 
                                label={""}
                                type="text"
                                name="custom-tag"
                                value={customTag}
                                wrapperClass="w-100"
                                className="form-control w-100"
                                placeholder={returnTranslation("Add tag") + "..."}
                                showErrors
                                formErrors={{}}
                                onChange={(e: any) => setCustomTag(e.target.value)}
                            />
                            <Button
                                onClick={(e) => {
                                    e?.preventDefault()
                                    handleAddNewTag()
                                }}
                                className="ms-2 mb-3"
                                color="success"
                                form="modal-form"
                            >
                                {returnTranslation("Add")}
                            </Button>
                            
                        </div>

                        <div className="mb-2">
                            <Label>{returnTranslation("Custom tags")}</Label>
                            <div className="tags-list--allowed">
                                {innerTags?.filter(tag => tag?.is_user_tag)?.map(tag => (
                                    <div key={tag?.id}
                                        onClick={() => handleDeleteTag(tag?.id)}
                                        className="tags-list--allowed--single-tag tags-list--allowed--single-tag--custom-tag selected"
                                    >
                                        <div>
                                            <i className="fa fa-plus" />
                                        </div>
                                        <span>{tag?.name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={(e) => {
                                e?.preventDefault()
                                toggleModal()
                            }}
                            color="success"
                            form="modal-form"
                        >
                            {returnTranslation("Close")}
                        </Button>
                    </ModalFooter>
                </div>
            </Modal>
        </>
    )
}

export default AddTags
