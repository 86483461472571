import {
    GoogleMap,
    Marker, withGoogleMap, withScriptjs
} from "react-google-maps";
import { useDispatch } from 'react-redux';
import { Button, Spinner } from 'reactstrap';
import { compose, withProps } from "recompose";
import { openAlert } from '../../store/actions';
import "./CustomGoogleMaps.scss";

export interface LatLng {
    lat: number;
    lng: number
}

interface Props {
    // googleMapURL: any;
    loadingElement: any;
    containerElement: any;
    mapElement: any;
    defaultZoom?: number;
    defaultCenter?: LatLng;
    markerPosition?: LatLng;
    center?: LatLng;
    markerDraggable?: boolean;
    onMarkerDragEnd?: (coords: LatLng) => void;
    setCurrentPosition?: (coords: LatLng) => void;
    returnTranslation: (text: string) => string;
    error?: boolean;
}

const CustomGoogleMaps = compose(
    withProps({
        googleMapURL: process.env.REACT_APP_ENV === "development"
            ? "https://maps.googleapis.com/maps/api/js?key=AIzaSyBk-jAVsR-Ut7gp1_4BmHbf5_SLuQzH9EI&v=3.exp&libraries=geometry,drawing,places"
            : "https://maps.googleapis.com/maps/api/js?key=AIzaSyAjWxpbVx8ZIEWNyPJN5tp_tbQCWAXkTCU&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: (
        <div style={{ height: `350px`, width: "400px" }}>
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <Spinner color="primary" />
            </div>
        </div>
        ),
        containerElement: <div className="custom-google-maps" style={{ height: `350px`, width: "100%", position: "relative" }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
    //@ts-ignore
)(({
    defaultZoom,
    defaultCenter,
    markerPosition,
    center,
    markerDraggable,
    onMarkerDragEnd,
    setCurrentPosition,
    returnTranslation,
    error
}: Props) => {
    const dispatch = useDispatch()

    const handleGetCurrentLocation = (e: any) => {
        if (navigator?.geolocation) {
            navigator?.geolocation?.getCurrentPosition(
              (position: GeolocationPosition) => {
                const pos: LatLng = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                };
                setCurrentPosition && setCurrentPosition(pos)
              },
              () => {
                dispatch(openAlert({text: returnTranslation("Couldn't get current location."), status: "danger"}))
                // handleLocationError(true, infoWindow, map.getCenter()!);
              }
            );
          } else {
            // Browser doesn't support Geolocation
            dispatch(openAlert({text: returnTranslation("Couldn't get current location."), status: "danger"}))
            console.log("Navigator not found.")
            // handleLocationError(false, infoWindow, map.getCenter()!);
          }
    }

    const handleMarkerDragEnd = (e: any) => {
        const lng = e.latLng.lng()
        const lat = e.latLng.lat()
        onMarkerDragEnd && onMarkerDragEnd({ lat, lng })
    }

    return (
        <>
            <div className="drag-a-pin-text">{markerPosition?.lat && markerPosition?.lng ? returnTranslation("Drag a pin to change location.") : ""}</div>
            <GoogleMap
                defaultZoom={defaultZoom ? defaultZoom : 14}
                defaultCenter={defaultCenter ? defaultCenter : { lat: -34.397, lng: 150.644 }}
                center={center}
                streetView={false}
                // options={{
                //     streetView: false,
                //     panControl: true
                // }}
            >
                <div className={`overlay-msg ${!markerPosition?.lat && !markerPosition?.lng ? "" : "fade-out"}`}>{returnTranslation("Enter an address to display location on map.")}</div>
                <Button className="geolocation-button" onClick={e => handleGetCurrentLocation(e)}>
                    <i className="fas fa-location-arrow text-secondary" />
                </Button>
                {markerPosition?.lat && markerPosition?.lng ? (
                <Marker draggable={markerDraggable} onDragEnd={handleMarkerDragEnd} position={markerPosition}
                />) : null}
            </GoogleMap>
        </>
    )
})

export default CustomGoogleMaps