import React, { useEffect, useState } from "react"
import { Button, Label } from "reactstrap"
import { customFilter } from 'react-bootstrap-table2-filter';
import SwitchCustom from '../../../components/SwitchCustom/SwitchCustom';


interface Props {
  offsetIndex: number,
  returnTranslation: (name: string) => string,
  handleDelete: (id: number, name: string) => void,
  handleEdit: (id: number) => void,
  handleToggleTakeBackPoint: (id: number, isActive: boolean) => void,
  // handleClickManageCarriers: (id: string) => void,
  queryObject: any,
  setQueryObject: (newQueryObject: any, forceReload?: boolean) => void,
  defaultQueryObject: any,
}

const DropoffPointsTableColumns = ({
  offsetIndex,
  handleEdit,
  handleDelete,
  handleToggleTakeBackPoint,
  returnTranslation,
  queryObject,
  setQueryObject,
  defaultQueryObject,
}: Props) => {
  const [isFilterActive, setIsFilterActive] = useState(false)
  const [filters, setFilters] = useState<any>({})

  useEffect(() => {
    const queryObjectCopy = { ...queryObject };
    delete queryObjectCopy.sort
    delete queryObjectCopy.direction
    setFilters(queryObjectCopy);
  }, [])

  useEffect(() => {
    handleSetIsFilterActive();
  }, [queryObject])

  const handleSetFilters = (columnName?: string, value?: string | number | boolean) => {
    if(value !== undefined && value !== ""){
      //@ts-ignore
      setFilters({ ...filters, [columnName] : value})
    } else {
      const filtersCopy = {...filters};
      //@ts-ignore
      delete filtersCopy[columnName]
      setFilters(filtersCopy)
    }
  }

  const handleSetIsFilterActive = () => {
    setIsFilterActive(Object.keys(queryObject)?.length > Object.keys(defaultQueryObject)?.length)
  }

  const handleSubmitFilters = (event?: any) => {
    if(event){
      event.preventDefault()
    }
    // get already existing default query keys from query obejct
    // and copy to new queryObject with additional filters
    const defaultQueryKeys = {}
    Object.keys(defaultQueryObject).forEach((key: string) => {
      //@ts-ignore
      defaultQueryKeys[key] = queryObject[key];
    })

    setQueryObject({ ...defaultQueryKeys, ...filters}, true);
  }

  const handleResetFilters = () => {
    const { sort, direction } = queryObject;
    setQueryObject({ sort, direction });
    setFilters({})
    setIsFilterActive(false)

    //reset additional filters not included in queryObject
    // setSelectedBrandCategory({})
  }

  const globalColumnProps = {
    onSort: (column: any, order: any) => {
      setQueryObject({
        ...queryObject,
        sort: column,
        direction: order
      })
    },
  }

  const getFilterRenderer = (columnName: string, columnType: string, placeholder = "", description = "") => {
    if(['string', 'number'].includes(columnType)){
      return (
        <form onSubmit={handleSubmitFilters}>
          <input
            name={columnName}
            className={"form-control table-filter-input"}
            //@ts-ignore
            value={filters[columnName] ? filters[columnName] : ""}
            onChange={e => handleSetFilters(columnName, e.target.value)}
            placeholder={placeholder}
          />
          <small className="text-muted">{description}</small>
        </form>
      )
    }else if(columnType === 'boolean'){
      return (
        <form onSubmit={handleSubmitFilters}>
          {/* <input
            style={{
              height: 17,
              width: 17
            }}
            type="checkbox"
            name={columnName}
            className={"form-check-input"}
            checked={filters[columnName] ? filters[columnName] : false}
            onChange={e => handleSetFilters(columnName, filters[columnName] ? false : true)}
          /> */}
          <select
            style={{
              width: 41
            }}
            className="form-control text-center table-filter-select"
            value={filters[columnName] ? filters[columnName] : ""}
            onChange={e => handleSetFilters(columnName, e.target.value)}
          >
            {["", 1, 0].map(value => <option key={value} value={value}>{value === "" ? "All" : value}</option>)}
          </select>
        </form>
      )
    }
  }

  return (
    [
      {
        dataField: "i",
        isDummyField: true,
        text: "#",
        formatter: (cellContent: any, row: any, rowIndex: any) => <div>{rowIndex + 1 + offsetIndex}</div>,
        //@ts-ignore
        filter: customFilter(),
        filterRenderer: () => !isFilterActive
          ? <i className="mdi mdi-filter"/>
          : <i className="mdi mdi-filter-off" onClick={handleResetFilters}/>
      },
      {
        dataField: "status",
        text: returnTranslation("Is Active"),
        formatter: (cellContent: any, row: any, rowIndex: any) => (
          <div className={`d-flex align-items-center gap-1 clickable auth-agreement-link ${row.status == 1 ? "auth-agreement-link--inactive" : ""}`}>
            {/* <input id={`checkbox-active-${row?.id}`}
              name={`checkbox-active-${row?.id}`}
              type="checkbox"
              checked={row?.status}
              onClick={((e) => {
                e.stopPropagation()
              })}
            /> */}

            <div style={{ width: '160px' }} className="d-flex gap-1 align-items-center mt-3">
              <SwitchCustom
                  handleSwitchChange={() => handleToggleTakeBackPoint(row?.id, !!row?.status)}
                  value={row?.status}
                  onOffText={returnTranslation(row?.status ? "Active" : "Inactive")}
                  size="md"
                  // disabled={["REVIEW"]?.includes(entitlement?.status)}
              />
            </div>
            {/* <Label htmlFor={`checkbox-active-${row?.id}`}
              className="mb-0 clickable" 
              onClick={(e) => {
                e.stopPropagation()
                handleToggleTakeBackPoint(row?.id, !!row?.status)
              }}
            >
              {row.status == 1 ? returnTranslation("Set inactive") : returnTranslation("Set active")}
            </Label> */}
          </div>
        ),
        //@ts-ignore
        filter: customFilter(),
        filterRenderer: (onFilter: any, column: any) => getFilterRenderer("status", "boolean", "Active")
      },
      {
        dataField: "name",
        text: returnTranslation("Name"),
        //@ts-ignore
        filter: customFilter(),
        filterRenderer: (onFilter: any, column: any) => getFilterRenderer("name", "string", "Name")
      },
      {
        dataField: "street",
        text: returnTranslation("Street"),
        //@ts-ignore
        filter: customFilter(),
        filterRenderer: (onFilter: any, column: any) => getFilterRenderer("street", "string", "Steet")
      },
      {
        dataField: "city",
        text: returnTranslation("City"),
        //@ts-ignore
        filter: customFilter(),
        filterRenderer: (onFilter: any, column: any) => getFilterRenderer("city", "string", "City")
      },
      {
        dataField: "postcode",
        text: returnTranslation("Postode"),
        //@ts-ignore
        filter: customFilter(),
        filterRenderer: (onFilter: any, column: any) => getFilterRenderer("postcode", "string", "Postcode")
      },
      {
        dataField: "country.name",
        text: returnTranslation("Country"),
        //@ts-ignore
        filter: customFilter(),
        filterRenderer: (onFilter: any, column: any) => getFilterRenderer("country", "string", "Country")
      },
      // {
      //   dataField: "accept",
      //   text: returnTranslation("Allowed"),
      //   formatter: (cellContent: any, row: any, rowIndex: any) => (
      //     <div className="d-flex align-items-center gap-2">
      //       {row?.accept?.map((x: any) => x.name)?.join(", ")}
      //       <span className="fw-medium auth-agreement-link">{row?.accept?.length ? returnTranslation("Manage") :  returnTranslation("Add allowed items")}</span>

      //       {/* <Button
      //         size="sm"
      //         type="button"
      //         className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
      //       >
      //         {returnTranslation("Manage")}
      //       </Button> */}
      //     </div>
      //   )
      // },
      // {
      //   dataField: "not_accept",
      //   text: returnTranslation("Not Allowed"),
      //   formatter: (cellContent: any, row: any, rowIndex: any) => (
      //     <div className="d-flex align-items-center gap-2">
      //       {row?.not_accept?.map((x: any) => x.name)?.join(", ")}
      //       <span className="fw-medium auth-agreement-link">{row?.not_accept?.length ? returnTranslation("Manage") :  returnTranslation("Add not allowed items")}</span>
      //     </div>
      //   )
      // },
      // {
      {
        dataField: "action",
        isDummyField: true,
        text: "",
        formatter: (cellContent: any, row: any, rowIndex: any) => (
          <div className="d-flex align-items-center gap-3">
            <span
              className="text-warning clickable"
              onClick={() => handleEdit(row?.id)}
            >
              {returnTranslation("Edit")}
              {/* <i className="mdi mdi-pencil font-size-18" id="edittooltip" /> */}
            </span>
            <span
              className="text-danger clickable"
              onClick={() => handleDelete(row.id, row.name)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
            </span>
            {/* <Link
              to="#"
              className={row.active == 1 ? "text-danger" : "text-success"}
              onClick={() => handleSetActive(row.id, row.active == 1 ? 0 : 1)}
            >
              <p className="active-coupon-btn">{row.active == 1 ? returnTranslation("Set inactive") : returnTranslation("Set active")}</p>
            </Link> */}
          </div>
        ),
         //@ts-ignore
        filter: customFilter(),
        filterRenderer: (onFilter: any, column: any) =>
          <Button
            type="button"
            color="success"
            className="btn-rounded waves-effect waves-light me-2"
            onClick={handleSubmitFilters}
          >
            {returnTranslation("Search")}
          </Button>
      },
    ]
  )
}

export default DropoffPointsTableColumns
