import React, { PureComponent, ReactNode } from 'react'

interface Props {
    tick: any,
    data: any[]
}
// interface State {}

// class LogoXAxisTick extends PureComponent<Props, State> {
//     constructor(props: Props) {
//         super(props)

//         this.state = {
            
//         }
//     }

//     render(): ReactNode {
//         const { logoSrc, text } = this.props;
//         console.log(["LogoXAxisTick", text])

//         return (
//             <g transform={`translate(${0},${0})`}>
//                 <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
//                     {text}
//                 </text>
//             </g>

//             // <g className="d-flex flex-column align-items-center">
//             //     <text>{text}</text>
//             //     {/* <img className="circle-rounded" src={logoSrc} height={40} width={40}/> */}
//             // </g>
//         );
//     }
// }

// export default LogoXAxisTick
class LogoXAxisTick extends PureComponent<Props> {
    render() {
        //@ts-ignore
        const { x, y, stroke, payload } = this?.props?.tick;
        //@ts-ignore
        const { data } = this?.props;
        // console.log(data[payload?.index])
  
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                    {payload.value}
                    {/* <img className="circle-rounded" src={data[payload?.index]?.logoSrc} height={40} width={40} />
                    <i className="fas fa-list" /> */}
                </text>
                <image xlinkHref={data[payload?.index]?.logoSrc} x={-19} y={20} height="40px" width="40px" textAnchor="middle" fill="#666"
                    clip-path="inset(0% round 100%)"
                />
            </g>
        );
    }
  }

export default LogoXAxisTick
