import {
  GET_FEED_ANALYTICS,
  GET_FEED_ANALYTICS_SUCCESS,
  GET_FEED_ANALYTICS_FAIL,
  CLEAR_FEED_ANALYTICS,
  SET_FEED_ANALYTICS_QUERY_OBJECT
} from "./actionTypes"

export const getFeedAnalytics = (payload) => ({
  type: GET_FEED_ANALYTICS,
  payload: {
    page: payload?.page ? payload?.page : 1,
    queryObject: payload?.queryObject ? payload?.queryObject : {},
  }
})

export const getFeedAnalyticsSuccess = FEED_ANALYTICS => ({
  type: GET_FEED_ANALYTICS_SUCCESS,
  payload: FEED_ANALYTICS,
})

export const getFeedAnalyticsFail = error => ({
  type: GET_FEED_ANALYTICS_FAIL,
  payload: error,
})

// export const changePostStatus = (id, status) => {
//   return {
//     type: CHANGE_POST_STATUS,
//     payload: { id, status },
//   }
// }

// export const deleteWardrobeItem = (id) => {
//   return {
//     type: DELETE_WARDROBE_ITEM,
//     payload: { id },
//   }
// }

export const clearFeedAnalytics = () => {
  return {
    type: CLEAR_FEED_ANALYTICS,
    payload: {}
  }
}

export const changeFeedAnalyticsQueryObject = (queryObject) => {
  return {
    type: SET_FEED_ANALYTICS_QUERY_OBJECT,
    payload: { queryObject },
  }
}