//Include Both Helper File with needed methods
import { getTakeBackCarriers, getTakeBacks } from "../../helpers/backend_helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { getTakeBackCarriersFail, getTakeBackCarriersSuccess, getTakeBacksFail, getTakeBacksSuccess } from "./actions"
// Ecommerce Redux States
import { GET_TAKE_BACKS, GET_TAKE_BACK_CARRIERS } from "./actionTypes"
import { checkAccount } from "../actions"

function* getTakeBacksAsync({ payload }) {
  try {
    const params = new URLSearchParams(payload?.queryObject)?.toString()
    const response = yield call(() => getTakeBacks(payload?.page, params))
    yield put(getTakeBacksSuccess(response))

    //check account after setting takebacks lists to check takeback tasks
    yield put(checkAccount())

    if(payload?.successCb){
      successCb();
    }
  } catch (error) {
    yield put(getTakeBacksFail(error))
  }
}

function* getTakeBackCarriersAsync({ payload }) {
  try {
    const params = new URLSearchParams(payload?.queryObject)?.toString()
    const response = yield call(() => getTakeBackCarriers(payload?.id, payload?.page, params))
    yield put(getTakeBackCarriersSuccess(response))
  } catch (error) {
    yield put(getTakeBackCarriersFail(error))
  }
}

function* takeBacksSaga() {
  yield takeEvery(GET_TAKE_BACKS, getTakeBacksAsync)
  yield takeEvery(GET_TAKE_BACK_CARRIERS, getTakeBackCarriersAsync)
}

export default takeBacksSaga
