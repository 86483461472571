// @ts-ignore
import { AvForm } from "availity-reactstrap-validation"
import React, { Component } from "react"
import { withTranslation } from "react-i18next"
// @ts-ignore
import MetaTags from 'react-meta-tags'
import { connect } from "react-redux"
import { RouteComponentProps } from "react-router"
import { Link } from "react-router-dom"
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, Row
} from "reactstrap"
import RadioCheck from "../../../components/Common/RadioCheck"
import { get, post } from "../../../helpers/api_helper"
import {
    apiError, changePreloader, registerUserFailed,
    registerUserSuccessful
} from "../../../store/actions"
import { openAlert } from "./../../../store/alert/actions"
import * as url from "./../../../helpers/url_helper"


export interface RegisterRolePropsInterface extends RouteComponentProps {
    dashbord_id: string,
    registerUserSuccessful: (userObj: any) => {},
    apiError: (err: string) => {},
    history: any,
    error: string,
    onOpenAlert: ({ }) => {},
    registerUserFailed: (status: string) => {},
    t: any,
    onGetCountries: any,
    countries: [{ code: string, name: string }],
    user: any,
    registrationError: string,
    onChangePreloader: (value: boolean) => {},
    location: any,
}

export interface RegisterRoleStateInterface {
    formErrors: any,
    role: string
}

class RegisterRole extends Component<RegisterRolePropsInterface, RegisterRoleStateInterface> {
    constructor(props: RegisterRolePropsInterface) {
        super(props)
        this.state = {
            formErrors: {},
            role: "school"
        }
    }

    async componentDidMount() {
        await this.getStepData(3)
        await this.props.apiError("")
        await this.props.registerUserFailed("")
        await this.props.onGetCountries()
    }

    handleSetRole = (value: string) => {
        this.setState({ role: value })
    }

    handleValidSubmit = async (event: any, values: any) => {
        event.preventDefault()

        const { role } = this.state;
        if(!role){
            return this.props.onOpenAlert({
                text: this.props.t("Choose role to proceed."),
                status: "danger",
            })
        }

        return new Promise(async (resolve, reject) => {
            this.props.onChangePreloader(true)

            post(`${url.API_BASE}dashboard/register/3`, {
                code: this.props.location?.state?.code,
                role: role?.toUpperCase(),
                region_id: this.props.location?.state?.region_id,
            })
            .then(response => {
                if (response?.status) {
                    // this.props.onOpenAlert({
                    //     text: response?.message,
                    //     status: "success",
                    // })
                    this.props.history.push("/register-contact", {
                        code: this.props.location?.state?.code,
                        email: this.props.location?.state?.email,
                        role: role?.toUpperCase(),
                        password: this.props.location?.state?.password,
                        region_id: this.props.location?.state?.region_id,
                    })
                }

                this.props.onChangePreloader(false)
                return resolve(response)
            })
            .catch(error => {
                if (error?.response?.data) {
                    const { message, errors } = error?.response?.data;
                    this.setState({ formErrors: errors });
                    this.props.onOpenAlert({
                        text: message,
                        status: "danger",
                    })
                }
                this.props.onChangePreloader(false)
                return reject(error)
            })
        })
    }

    getStepData = async (step: number) => {
        if(!this?.props?.location?.state?.code){
            return;
        }
        try{
            this.props.onChangePreloader(true)

            const response = await get(`${url.API_BASE}dashboard/register/step?code=${this?.props?.location?.state?.code}&step=${step}`)
            if(response?.data?.role?.toLowerCase() !== "undefined"){
                this.handleSetRole(response?.data?.role?.toLowerCase());
            }
        }catch(e){

        }finally{
            this.props.onChangePreloader(false)

        }
    }

    render() {
        const { formErrors, role } = this.state
        const { t } = this.props

        return (
            <React.Fragment>
                <MetaTags>
                    <title>{t("LOOP | Register")}</title>
                </MetaTags>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-dark">
                        <i className="bx bx-home h2" />
                    </Link>
                </div>
                <div className="account-pages my-2 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={7} xl={6}>
                                <Card className="overflow-hidden">
                                    <CardHeader>
                                        <i className="fas fa-chevron-left font-size-20 text-black clickable pt-1 ps-1"
                                            onClick={() => this.props.history?.push("/register-region", this.props.location?.state)}
                                        />
                                    </CardHeader>
                                    <Row>
                                        <Col className="col-7">
                                            <div className="p-4 pe-0">
                                                <h1>{t("Sign up")}</h1>
                                                {/* <h1>{t("I am the person representing")}</h1> */}
                                            </div>
                                        </Col>
                                    </Row>
                                    <CardBody className="pt-0">
                                        <div className="d-grid p-2">
                                            <label className="form-check-label">
                                                {t("I am the person representing")}
                                            </label>
                                            {/* <div className="sign-up-role-container">
                                                <button
                                                    className={`${role === "retailer" ? "btn-no-shadow btn btn-primary btn-block waves-effect waves-light" : "btn-no-shadow btn btn-light"}`}
                                                    onClick={() => this.handleSetRole("retailer")}
                                                >
                                                    Retailer
                                                </button>
                                                <button
                                                    className={`${role === "charity" ? "btn-no-shadow partner-btn btn btn-primary btn-block waves-effect waves-light" : "btn-no-shadow partner-btn btn btn-light"}`}
                                                    onClick={() => this.handleSetRole("charity")}
                                                >
                                                    Charity
                                                </button>
                                                <button
                                                    className={`${role === "partner" ? "btn-no-shadow btn btn-primary btn-block waves-effect waves-light" : "btn-no-shadow btn btn-light"}`}
                                                    onClick={() => this.handleSetRole("partner")}
                                                >
                                                    Partner
                                                </button>
                                            </div> */}
                                            <RadioCheck
                                                reversed={true}
                                                label={t("School")}
                                                id="school"
                                                radioGroupName="role-group"
                                                checked={this.state.role === "school"}
                                                className={"mb-2 p-2 border-light-gray"}
                                                value={"school"}
                                                onChange={(v: any) => this.handleSetRole(v)}
                                                descriptionElement={
                                                    <div className="font-size-14">
                                                        {t("Register on Loop to easily organise clothing collections, resell or upcycle collected products and raise funds to support local communities or charity organizations")}
                                                    </div>
                                                }
                                            />
                                            <RadioCheck 
                                                reversed={true}
                                                label={t("Charity")}
                                                id="charity"
                                                radioGroupName="role-group"
                                                checked={this.state.role === "charity"}
                                                value={"charity"}
                                                className={"mb-2 p-2 border-light-gray"}
                                                onChange={(v: any) => this.handleSetRole(v)}
                                                descriptionElement={
                                                    <div className="font-size-14">
                                                        {t("Connect to the world of digital fashion and acquire new donors & supporters among highly engaging community of shoppers and influencers on LOOP Digital Wardrobe.")}
                                                    </div>
                                                }
                                            />
                                            <RadioCheck
                                                reversed={true}
                                                label={t("Retailer")}
                                                id="retailer"
                                                radioGroupName="role-group"
                                                checked={this.state.role === "retailer"}
                                                className={"mb-2 p-2 border-light-gray"}
                                                value={"retailer"}
                                                onChange={(v: any) => this.handleSetRole(v)}
                                                descriptionElement={
                                                    <div className="font-size-14">
                                                        {t("Attract, convert, and retain customers through social media style ecosystem and performance-driven marketing. Boost your business with Sustainability Credentials & Post Purchase Marketing with LOOP.")}
                                                    </div>
                                                }
                                            />
                                        </div>
                                        {/* <div className="role-description p-2" style={{ minHeight: 110 }}>
                                            {role === "retailer" ? t("Attract, convert, and retain customers through social media style ecosystem and performance-driven marketing. Boost your business with Sustainability Credentials & Post Purchase Marketing with LOOP.") : null}
                                            {role === "charity" ? t("Connect to the world of digital fashion and acquire new donors & supporters among highly engaging community of shoppers and influencers on LOOP Digital Wardrobe.") : null}
                                            {role === "school" ? t("Businesses of all sizes - from startups to large enterprises - are welcome to join LOOP to create a bigger impact together.") : null}
                                        </div> */}
                                        <div className="p-2">
                                            <AvForm
                                                className="form-horizontal"
                                                onValidSubmit={this.handleValidSubmit}
                                            >
                                                {this.props.user && this.props.user ? (
                                                    <Alert color="success">
                                                        Register User Successfully
                                                    </Alert>
                                                ) : null}
                                                {this.props.registrationError &&
                                                    this.props.registrationError ? (
                                                    <Alert color="danger">
                                                        {this.props.registrationError}
                                                    </Alert>
                                                ) : null}

                                                <div className="d-grid">
                                                    <button
                                                        className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
                                                        type="submit"
                                                    >
                                                        {t("Next")}
                                                    </button>
                                                </div>

                                                <div className="mt-3 text-center">
                                                    <p>
                                                        <span className="auth-agreement-text">Already have an account?</span>{" "}
                                                        <Link to="/login" className="fw-medium auth-agreement-link">
                                                            {" "}
                                                            {t("Log in")}
                                                        </Link>{" "}
                                                    </p>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <p className="mb-0 auth-agreement-text">
                                                        By continuing you agree to our <a href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/" target="_blank" className="text-primary">
                                                            Terms & Conditions
                                                        </a>. LOOP services are
                                                        subject to our <a href="https://loopdigitalwardrobe.com/privacy-policy/" target="_blank" className="text-primary">
                                                            Privacy Policy
                                                        </a>.
                                                    </p>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <p className="mb-0 auth-agreement-text">
                                                        Need our help? <a href="mailto:help@loopdigitalwardrobe.com" className="fw-medium contact-us-text">
                                                            Contact us.
                                                        </a>
                                                    </p>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="mt-5 text-center">
                                    <p className="auth-agreement-text">
                                        ©{new Date().getFullYear()} All Rights Reserved. LOOP DIGITAL WARDROBE® is a registered trademark of LOOP DIGITAL WARDROBE®.{" "}
                                        <a href="https://loopdigitalwardrobe.com/privacy-policy/" target="_blank" className="fw-medium text-primary">
                                            Privacy Policy
                                        </a> and{" "}
                                        <a href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/" target="_blank" className="fw-medium text-primary">
                                            Terms & Conditions
                                        </a>
                                        .
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div >
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state: any) => {
    const { user, registrationError, loading, dashbord_id } = state.Account
    const { countries } = state.Countries
    return { user, registrationError, loading, dashbord_id, countries }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        apiError: (message: string) => dispatch(apiError(message)),
        registerUserFailed: (message: string) => dispatch(registerUserFailed(message)),
        registerUserSuccessful: (user: any) => dispatch(registerUserSuccessful(user)),
        onGetCountries: () => dispatch({ type: "GET_COUNTRIES" }),
        onOpenAlert: ({ text, status }: any) =>
            dispatch(openAlert({ text: text, status: status })),
        onChangePreloader: (value: boolean) => dispatch(changePreloader(value))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(RegisterRole))
