// @ts-ignore
import { capitalize } from "lodash"
import React, { FormEvent, useEffect, useState } from "react"
import { Collapse } from 'react-collapse'
import { withTranslation } from "react-i18next"
// @ts-ignore
import MetaTags from 'react-meta-tags'
import 'react-phone-input-2/lib/high-res.css'
// @ts-ignore
import { connect, useDispatch } from "react-redux"
// @ts-ignore
import { RouteComponentProps } from "react-router"
// @ts-ignore
import { Link } from "react-router-dom"
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, Form, Row
} from "reactstrap"
import CustomGoogleMaps, { LatLng } from "../../../../components/CustomGoogleMaps/CustomGoogleMaps"
import { generateCoordinates, getCity, getSchoolStages, getSchoolTypes } from "../../../../helpers/backend_helper"
import {
    apiError, changePreloader, registerUserFailed,
    registerUserSuccessful
} from "../../../../store/actions"
import FormInput from "../../../../components/Common/FormInput"
import { get, post } from "../../../../helpers/api_helper"
import * as url from "../../../../helpers/url_helper"
import { openAlert } from "../../../../store/alert/actions"
import RegisterDetailsForm, { RegisterSubmitData } from "./RegisterDetailsForm"

export interface RegisterDetailsPropsInterface extends RouteComponentProps {
    dashbord_id: string,
    registerUserSuccessful: (userObj: any) => {},
    apiError: (err: string) => {},
    history: any,
    location: any,
    // error: string,
    onOpenAlert: ({ }) => {},
    registerUserFailed: (status: string) => {},
    t: any,
    onGetCountries: any,
    countries: any,
    user: any,
    registrationError: string,
    onChangePreloader: (value: boolean) => {}
}

export interface RegisterDetailsStateInterface {
    isCharity: boolean,
    // countries: any[],
    selectedCountryCode: string,
    formErrors: any,
    selectedCountry: string,
    countryCodes: any,
    cities: any,
    selectedCityId: any,
    city: string,
    phone: string;
    lat: string;
    lng: string;
}

interface OpeningHour {
    "dayOfWeek": string;
    "day": number;
    "from": string;
    "until": string;
    "active": boolean;
}

const RegisterDetails = ({
    apiError,
    registerUserFailed,
    onGetCountries,
    onOpenAlert,
    location,
    history,
    onChangePreloader,
    registerUserSuccessful,
    registrationError,
    user,
    countries,
    t
}: RegisterDetailsPropsInterface) => {
    const dispatch = useDispatch();
    const [formErrors, setFormErrors] = useState<any>({});
    const [phone, setPhone] = useState("");
    // const [selectedCountry, setSelectedCountry] = useState("UK");

    const [foundAddress, setFoundAddress] = useState('')

    const [country_id, setCountry_id] = useState("1")
    const [countryCode, setCountryCode] = useState("GB");
    const [street, setStreet] = useState("")
    const [city, setCity] = useState("")
    const [postcode, setPostcode] = useState("")

    const [schoolTypeId, setSchoolTypeId] = useState("")
    const [schoolStageId, setSchoolStageId] = useState("")

    const [lat, setLat] = useState<number>()
    const [lng, setLng] = useState<number>()

    const [isOpenOpeningHours, setIsOpenOpeningHours] = useState(false);

    const [schoolTypes, setSchoolTypes] = useState([]);
    const [schoolStages, setSchoolStages] = useState([]);

    const [opening_hours, setOpening_hours] = useState<OpeningHour[]>([
        { "dayOfWeek": "Monday", "day": 1, "from": "10:00", "until": "20:00", "active": true },
        { "dayOfWeek": "Tuesday", "day": 2, "from": "10:00", "until": "20:00", "active": true },
        { "dayOfWeek": "Wednesday", "day": 3, "from": "10:00", "until": "20:00", "active": true },
        { "dayOfWeek": "Thursday", "day": 4, "from": "10:00", "until": "20:00", "active": true },
        { "dayOfWeek": "Friday", "day": 5, "from": "10:00", "until": "20:00", "active": true },
        { "dayOfWeek": "Saturday", "day": 6, "from": "10:00", "until": "20:00", "active": true },
        { "dayOfWeek": "Sunday", "day": 7, "from": "10:00", "until": "20:00", "active": false }
    ]);

    const [stepData, setStepData] = useState<any>({});

    useEffect(() => {
        getStepData(5)
        apiError("")
        registerUserFailed("")
        // if(!countries?.length){
        //     onGetCountries()
        // }

        // if(location?.state?.role?.toLowerCase() === "school"){
        //     handleGetSchoolTypes()
        //     handleGetSchoolStages();
        // }
    }, [])

    // const handleGetSchoolTypes = async() => {
    //     try{
    //         const response = await getSchoolTypes();
    //         setSchoolTypes(response)
    //     }catch(e){

    //     }
    // }

    // const handleGetSchoolStages = async() => {
    //     try{
    //         const response = await getSchoolStages();
    //         setSchoolStages(response)
    //     }catch(e){

    //     }
    // }

    const handleValidSubmit = async (data: RegisterSubmitData) => {
        try{
            onChangePreloader(true)

            const response = await post(`${url.API_BASE}dashboard/register/5`, data)
            if (response?.status) {
                // registerUserSuccessful(response?.user)
                // onOpenAlert({
                //     text: response?.message,
                //     status: "success",
                // })
                history.push("/register-socials", {
                    code: location?.state?.code,
                    email: location?.state?.email,
                    role: location?.state?.role,
                    password: location?.state?.password
                })
            }
        }catch(error){
            //@ts-ignore
            if (error?.response?.data) {
                //@ts-ignore
                const { message, errors } = error?.response?.data;
                setFormErrors(errors ?? {});
                onOpenAlert({
                    text: message,
                    status: "danger",
                })
            }
        }finally{
            onChangePreloader(false)

        }
    }

    // const handleUpdateCity = async (postcode: string, countryCode: any) => {
    //     if(postcode && countryCode){
    //       try{
    //         const cityObject = await handleGetCity(postcode, countryCode)
    //         if (cityObject?.name) {
    //           setCity(cityObject?.name)
    //           generateCoords({city: cityObject?.name})
    //         }
    //       }catch(e){
    //         // console.log(e)
    //       }
    //     } else {
    //       generateCoords()
    //     }
    // }

    // const handleGetCity = async (postcode: string, countryCode: any): Promise<{id: number, name: string}> => {
    //     return new Promise(async (resolve, reject) => {
    //         // setIsLoadingCity(true)
    //         try {
    //             const cityObject = await getCity(postcode, countryCode)
    //             onOpenAlert({
    //                 text: t(`Found`) +  ` ${cityObject.name}.`,
    //                 status: "success",
    //             })
    //             resolve(cityObject)
    //         } catch (error) {
    //             // console.log(['error', error]);
    //             //@ts-ignore
    //             const { message } = error?.response?.data;
    //             // onOpenAlert({
    //             //     text: message,
    //             //     status: "danger",
    //             // })
    //             reject(error)
    //         } finally {
    //             // setIsLoadingCity(false)
    //         }
    //     })
    // }

    // const getFullAddress = (customData?: { city?: string, street?: string, postcode?: string, country_id?: string }): string => {
    //     const finalStreet = customData?.street ? (customData?.street ? customData?.street : "") : (street ? street : ""); 
    //     const finalPostcode = customData?.postcode ? (customData?.postcode ? customData?.postcode : "") : (postcode ? postcode : ""); 
    //     const finalCity = customData?.city ? (customData?.city ? customData?.city : "") : (city ? city : ""); 
    //     const finalCountryId = customData?.country_id ? (customData?.country_id ? customData?.country_id : "") : (country_id ? country_id : ""); 
    
    //     if (!((finalStreet || finalPostcode || finalCity || finalCountryId) && countries?.length))
    //       return '';
    
    //     const country = countries?.find((country: any) => country.id === finalCountryId);
    //     const fullAddress = [finalStreet, `${finalPostcode} ${finalCity}`, country?.name]
    //       ?.filter(value => !!value?.trim())
    //       ?.join(", ")
    
    //     return fullAddress ? fullAddress : '';
    // }
    
    // const generateCoords = async (customData?: { city?: string, street?: string, postcode?: string, country_id?: string }) => {
    //     const fullAddress = getFullAddress(customData);
    //     if(!fullAddress) return;

    //     try {
    //         const response = await generateCoordinates(fullAddress);

    //         const { address, location: { lat, lng } } = response;

    //         setFoundAddress(address)
    //         setLat(lat)
    //         setLng(lng)
    //         if(!city) {
    //             setCity(response?.city)
    //         }
    //     } catch (error) {
    //         // console.log(error)
    //         dispatch(openAlert({ text: t("Problem with address"), status: 'danger' }))
    //     }
    // }

    // const handleChangeCountry = (value: string | number, allowToUpdateCity = true) => {
    //     const country = countries?.find((country: any) => country?.code === value || country?.id === value || country?.name === value)
    //     setCountryCode(country?.code)
    //     setCountry_id(country?.id)
    
    //     if(country?.code && postcode && allowToUpdateCity)
    //       handleUpdateCity(postcode, country?.code)
    
    //     return country
    // }

    // const handleTimeChange = (time: string, day: number, type: string) => {
    //     const openingHoursCopy = opening_hours;
    //     const dayIndex = opening_hours.findIndex(singleDay => singleDay.day === day);
    //     const newDayObject = openingHoursCopy[dayIndex];
    
    //     if (type === "from") {
    //       newDayObject.from = time
    //     } else if (type === "until") {
    //       newDayObject.until = time
    //     }
    
    //     setOpening_hours(openingHoursCopy)
    // }
    
      
    // const  handleSetActiveDay = (dayName: string, value: boolean) => {
    //     setOpening_hours((prev) => {
    //       return prev?.map(item => {
    //         if(item.dayOfWeek === dayName){
    //           item.active = value;
    //         }
    //         return item;
    //       })
         
    //     })
    // }

    const getStepData = async (step: number) => {
        if(!location?.state?.code){
            return;
        }
        try{
            onChangePreloader(true)

            const response = await get(`${url.API_BASE}dashboard/register/step?code=${location?.state?.code}&step=${step}`)
            setStepData(response?.data)
            // setPhone(response?.data?.phone)
            // setPostcode(response?.data?.postcode)
            // setStreet(response?.data?.street)
            // setCity(response?.data?.city_name)
            // setLat(parseFloat(response?.data?.lat?.toString()))
            // setLng(parseFloat(response?.data?.lng?.toString()))
            // setIsOpenOpeningHours(!!response?.data?.opening_hours)
            // setSchoolTypeId(response?.data?.school_type_id)
            // setSchoolStageId(response?.data?.school_stage_id)
            // if(response?.data?.opening_hours){
            //     setOpening_hours(response?.data?.opening_hours)
            // }
        }catch(e){

        }finally{
            onChangePreloader(false)
        }
    }

    return (
        <>
            <MetaTags>
                <title>LOOP | Register</title>
            </MetaTags>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="bx bx-home h2" />
                </Link>
            </div>
            <div className="account-pages my-2 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                         <Col md={8} lg={7} xl={6}>
                            <Card className="overflow-hidden">
                                <CardHeader>
                                    <i className="fas fa-chevron-left font-size-20 text-black clickable pt-1 ps-1"
                                        onClick={() => history?.push("/register-contact", location?.state)}
                                    />
                                </CardHeader>
                              
                                <div className="p-4 d-flex align-items-center gap-3">
                                    <h1 className="m-0">{t("Company Details")}</h1>
                                </div>
                                <CardBody className="pt-0">
                                    <div className="p-2">
                                        {user && user ? (
                                            <Alert color="success">
                                                Register User Successfully
                                            </Alert>
                                        ) : null}
                                        {registrationError &&
                                            registrationError ? (
                                            <Alert color="danger">
                                                {registrationError}
                                            </Alert>
                                        ) : null}

                                        <RegisterDetailsForm
                                            stepData={stepData}
                                            handleSubmit={(data) => handleValidSubmit(data)}
                                            submitBtnText={"Next"}
                                            role={location?.state?.role}
                                            formErrors={formErrors}
                                            checkStepDataAsync={true}
                                        />

                                        <div className="mt-3 text-center">
                                            <p>
                                                <span className="auth-agreement-text">Already have an account?</span>{" "}
                                                <Link to="/login" className="fw-medium auth-agreement-link">
                                                    {" "}
                                                    Log in
                                                </Link>{" "}
                                            </p>
                                        </div>

                                        <div className="mt-4 text-center">
                                            <p className="mb-0 auth-agreement-text">
                                                By continuing you agree to our <a href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/" target="_blank" className="text-primary">
                                                    Terms & Conditions
                                                </a>. LOOP services are
                                                subject to our <a href="https://loopdigitalwardrobe.com/privacy-policy/" target="_blank" className="text-primary">
                                                    Privacy Policy
                                                </a>.
                                            </p>
                                        </div>

                                        <div className="mt-4 text-center">
                                            <p className="mb-0 auth-agreement-text">
                                                Need our help? <a href="mailto:help@loopdigitalwardrobe.com" className="fw-medium contact-us-text">
                                                    Contact us.
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>

                            <div className="mt-5 text-center">
                                <p className="auth-agreement-text">
                                    ©{new Date().getFullYear()} All Rights Reserved. LOOP DIGITAL WARDROBE® is a registered trademark of LOOP DIGITAL WARDROBE®.{" "}
                                    <a href="https://loopdigitalwardrobe.com/privacy-policy/" target="_blank" className="fw-medium text-primary">
                                        Privacy Policy
                                    </a> and{" "}
                                    <a href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/" target="_blank" className="fw-medium text-primary">
                                        Terms & Conditions
                                    </a>
                                    .
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        </ >
    )
}

const mapStateToProps = (state: any) => {
    const { user, registrationError, loading, dashbord_id } = state.Account
    const { countries } = state.Countries
    return { user, registrationError, loading, dashbord_id, countries }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        apiError: (message: string) => dispatch(apiError(message)),
        registerUserFailed: (message: string) => dispatch(registerUserFailed(message)),
        registerUserSuccessful: (user: any) => dispatch(registerUserSuccessful(user)),
        onGetCountries: () => dispatch({ type: "GET_COUNTRIES" }),
        onOpenAlert: ({ text, status }: any) =>
            dispatch(openAlert({ text: text, status: status })),
        onChangePreloader: (value: boolean) => dispatch(changePreloader(value))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(RegisterDetails))
