import { GET_TAKE_BACKS, GET_TAKE_BACKS_FAIL, GET_TAKE_BACKS_SUCCESS, GET_TAKE_BACK_CARRIERS, GET_TAKE_BACK_CARRIERS_FAIL, GET_TAKE_BACK_CARRIERS_SUCCESS, CLEAR_TAKE_BACK_CARRIERS} from "./actionTypes"

const INIT_STATE = {
  items: [],
  info: {},
  isLoading: false,
  carriers:{
    items: [],
    info: {
      current_page: 1,
      page_count: 10,
      total_items: 0,
      total_pages: 1
    },
    isLoading: false,
  }
}

const TakeBacks = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TAKE_BACKS:
      return {
        ...state,
        isLoading: true
      }


    case GET_TAKE_BACKS_SUCCESS:
      return {
        ...state,
        items: action?.payload?.items,
        info: action?.payload?.info,
        isLoading: false
      }

    case GET_TAKE_BACKS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      }

    case GET_TAKE_BACK_CARRIERS:
      return {
        ...state,
        carriers: {
          ...state?.carriers,
          isLoading: true
        }
      }

    case GET_TAKE_BACK_CARRIERS_SUCCESS:
      return {
        ...state,
        carriers: {
          ...state?.carriers,
          items: action?.payload,
          info: {
            ...state?.carriers?.info,
            total_items: action?.payload?.length
          },
          isLoading: false
        }
      }

    case GET_TAKE_BACK_CARRIERS_FAIL:
      return {
        ...state,
        carriers: {
          ...state?.carriers,
          error: action.payload,
          isLoading: false
        }
      }
      
    case CLEAR_TAKE_BACK_CARRIERS:
      return {
        ...state,
        carriers: {
          ...INIT_STATE.carriers
        }
      }
    default:
      return state
  }
}

export default TakeBacks
