export const RESET_APP_STATE = "RESET_APP_STATE"
export const resetAppState = layout => ({
  type: RESET_APP_STATE
})

export * from "./layout/actions"

// Authentication module
export * from "./auth/register/actions"
export * from "./auth/login/actions"
export * from "./auth/forgetpwd/actions"
export * from "./auth/profile/actions"

// contacts
export * from "./confirmPanel/actions"

export * from "./alert/actions"

// websites
export * from "./websites/actions"

// codes
export * from "./coupons/actions"

// dictionaries
export * from "./dictionaries/actions"

// currencies
export * from "./currencies/actions"

// charity shops
export * from "./charityShops/actions"

// translations
export * from "./translations/actions"

// paymentInfo
export * from "./paymentInfo/actions"

// billings
export * from "./billings/actions"

// invoices
export * from "./invoices/actions"

// FeedAnalytics
export * from "./FeedAnalytics/actions"

export * from "./takeBacks/actions"