import { takeEvery, put, call } from "redux-saga/effects"
import {
  GET_DICTIONARIES,
  GET_REGIONS
} from "./actionTypes"
import {
  getDictionariesSuccess,
  getDictionariesFail,
  getRegionsSuccess,
  getRegionsFail
} from "./actions"
import {
  getDictionaries,
  getRegions
} from "../../helpers/backend_helper"

function* getDictionariesAsync() {
  try {
    const response = yield call(getDictionaries)
    yield put(getDictionariesSuccess(response))
  } catch (error) {
    yield put(getDictionariesFail(error))
  }
}

function* getRegionsAsync() {
  try {
    const response = yield call(getRegions)
    yield put(getRegionsSuccess(response))
  } catch (error) {
    yield put(getRegionsFail(error))
  }
}

function* dictionariesSaga() {
  yield takeEvery(GET_DICTIONARIES, getDictionariesAsync)
  yield takeEvery(GET_REGIONS, getRegionsAsync)
}

export default dictionariesSaga