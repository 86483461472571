import {
  GET_WEBSITES,
  GET_WEBSITES_FAIL,
  GET_WEBSITES_SUCCESS,
} from "./actionTypes"

export const getWebsites = (page) => ({
  type: GET_WEBSITES,
  payload: { page }
})

export const getWebsitesSuccess = websites => ({
  type: GET_WEBSITES_SUCCESS,
  payload: websites,
})

export const getWebsitesFail = error => ({
  type: GET_WEBSITES_FAIL,
  payload: error,
})

export const clearWebsiteLogo = () => ({
  type: CLEAR_WEBSITE_LOGO,
  payload: {},
})

export const clearWebsiteLogoSuccess = () => ({
  type: CLEAR_WEBSITE_LOGO_SUCCESS,
  payload: {},
})

export const clearWebsiteLogoFail = error => ({
  type: CLEAR_WEBSITE_LOGO_FAIL,
  payload: error,
})

export const addWebsiteLogo = ({ photo, successCb, errorCb }) => ({
  type: ADD_WEBSITE_LOGO,
  payload: { photo },
  successCb,
  errorCb
})

export const addWebsiteLogoSuccess = photo => ({
  type: ADD_WEBSITE_LOGO,
  payload: photo,
})

export const addWebsiteLogoFail = error => ({
  type: ADD_WEBSITE_LOGO,
  payload: error,
})