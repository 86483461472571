import React, { Component, useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useDispatch, useSelector } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { Container, Label } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import BasicTable from "../../../components/BasicTable/BasicTable"
// import WardrobesTablesColumns from "./WardrobesTableColumns"
import AnalyticsTableColumns from "./AnalyticsTableColumns"
import './Analytics.scss'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { 
  requestConfirmation,
  getFeedAnalytics,
  changeFeedAnalyticsQueryObject,
  openAlert
} from "../../../store/actions"
import moment from "moment"

interface Props extends RouteComponentProps{
  t: (name: string) => string;  
  feedAnalytics: any[];
  onDelete: any;
  totalItems: number;
  perPage: number;
  page: number;
  isLoading: boolean;
}

const Analytics:React.FC<Props> = ({t, feedAnalytics, onDelete, totalItems, perPage, page, history, location, isLoading }) => {
  const dispatch = useDispatch();
  // const QUERY_OBJECT_INIT = { sort: 'created_at', direction: 'desc' };
  // const [queryObject, setQueryObject] = useState(QUERY_OBJECT_INIT);
  const QUERY_OBJECT_INIT = useSelector((state: any) => state.FeedAnalytics.initialQueryObject)
  const queryObject = useSelector((state: any) => state.FeedAnalytics.queryObject)
  const setQueryObject = (queryObject: any) => dispatch(changeFeedAnalyticsQueryObject(queryObject))

  const [date, setDate] = useState<Date | undefined>(undefined)

  const onOpenAlert = (status: string, text: string) => {
		dispatch(openAlert({ text, status }))
	}

  const triggerPageQueryString = () => {
    //fix no pagination bug on first enter
    //it forces state change at BasicTable pagination
    history.replace(`${location.pathname}?page=`);
    page && setTimeout(() => {
      history.replace(`${location.pathname}?page=${page}`);
    }, 50)
  }

  const handleSetQueryObject = (value: any, forceReload?: boolean) => {
    if((JSON.stringify(queryObject) !== JSON.stringify(value)) || forceReload){
      dispatch(getFeedAnalytics({ page: 1, queryObject: value }))
    }
    setQueryObject(value)
  }

  // const handleBanItem = (nanoId: string, isBanned: boolean) => {
  //   dispatch(requestConfirmation(
  //     `Do  you want to ${!isBanned ? 'ban' : 'unban'} this item?`,
  //     (data: any) => banItem(nanoId, isBanned, data),
  //     undefined,
  //     !isBanned ? 'Yes, Ban!' : 'Yes, Unban!',
  //     undefined,
  //     !isBanned,
  //     !isBanned
  //   ));
  // }

  // const banItem = async (nanoId: string, isBanned: boolean, data:any) => {
  //   try {
  //     let response: any = {};
  //     if(!isBanned){
  //       response = await ban('items', nanoId, {
  //         "comment" : data?.comment,
  //         "expired_at": data?.date
  //       })
  //     } else {
  //       response = await unban('items', nanoId)
  //     }
      
  //     if(response?.status === "success"){
  //       dispatch(getWardrobes({ page, queryObject }))
  //       triggerPageQueryString()
  //     }
  //   } catch (e) {
  //       console.log(e)
  //   }
  // }

  // const triggerShowFeedItems = () => {
  //   handleSetQueryObject({
  //     ...queryObject,
  //     show_feeder_items: queryObject.show_feeder_items ? 0 : 1
  //   }, true)
  // }

  const handleChangeDatepicker = (date: Date) => {
    const yesterday = moment().subtract(1, 'days');
    if(moment(date) > yesterday) {
      return onOpenAlert("danger", `Pick a date before ${yesterday.format("YYYY-MM-DD")}`)
    }
    handleSetQueryObject({...queryObject, period: date ? moment(date).format("YYYY-MM-DD"): "", type: "daily"})
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Ads Manager" breadcrumbItem={`Feed Items`} totalItems={totalItems} />

          <BasicTable
            items={feedAnalytics}
            columns={AnalyticsTableColumns(
              (page - 1) * perPage,
              t,
              queryObject,
              handleSetQueryObject,
              QUERY_OBJECT_INIT,
            )}
            searchbar={false}
            getAction={({ page }: any) => getFeedAnalytics({ page, queryObject })}
            totalSize={totalItems}
            sizePerPage={perPage}
            defaultSort={queryObject}
            //@ts-ignore
            currentPage={page}
            isLoading={isLoading}
            customFilter={
              <div className="w-50">
                <Label>Compare period</Label>
                <DatePicker
                  selected={queryObject?.period ? new Date(queryObject?.period) : undefined}
                  onChange={(date: Date) => handleChangeDatepicker(date)}
                  className="form-control"
                  showTimeSelect={false}
                  calendarClassName="react-datepicker"
                  dateFormat="yyyy-MM-dd"
                  placeholderText={"Choose a period"}
                  isClearable
                />
                <small className="text-muted text-nowrap">
                  Compare - DAILY
                  {queryObject?.period ? ` (compares with ${moment(queryObject?.period).subtract(1, 'days').format("YYYY-MM-DD")})` : ""}
                </small>
              </div>
            }
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state: any) => {
  const { dashbord_id, user } = state.Account
  const { feedAnalytics, totalItems, page, perPage, isLoading } = state.FeedAnalytics
  return { dashbord_id, user, feedAnalytics, totalItems, page, perPage, isLoading }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    // onDeleteFeeder: (id: any) => dispatch(deleteFeeder(id))
  }
}

export default withRouter(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Analytics))
)
