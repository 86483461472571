import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { UncontrolledAlert } from "reactstrap"
import { closeAlert } from "./../../store/actions"

const UiAlert = () => {
  const dispatch = useDispatch()
  const [allowRender, setAllowRender] = useState(false)
  const icons = {
    primary: "mdi-bullseye-arrow",
    secondary: "mdi-grease-pencil",
    success: "mdi-check-all",
    danger: "mdi-block-helper",
    warning: "mdi-alert-outline",
    info: "mdi-alert-circle-outline",
  }
  const text = useSelector(state => state ?.alert ?.text)
  const status = useSelector(state => state ?.alert ?.status)

  useEffect(() => {
    if (Object.keys(icons).includes(status) && text) {
      setAllowRender(true)
    } else {
      setAllowRender(false)
    }
  }, [status, text])

  useEffect(() => {
    if (allowRender) {
      setTimeout(() => {
        dispatch(closeAlert())
      }, 3000)
    }
  }, [allowRender])

  return (
    allowRender && (
      <UncontrolledAlert
        color={status ? status : "info"}
        className="fade show custom-alert border-0"
        role="alert"
      >
        <i className={`mdi ${icons[status]} me-2`} />
        {text}
      </UncontrolledAlert>
    )
  )
}

export default UiAlert
