import React, { FormEvent, useEffect, useState } from "react";
// @ts-ignore
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useHistory, useParams } from "react-router";
import { Link, withRouter } from "react-router-dom";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container, Form, Input, Label, Row, Spinner
} from "reactstrap";
import Breadcrumb from "../../../../components/Common/Breadcrumb";
import ContactUsAlert from "../../../../components/Common/ContactUsAlert";
import FormInput from "../../../../components/Common/FormInput";
import { addTakeBackCarrier, getTakeBackCarrier, getTakeBackCarrierRequirements } from "../../../../helpers/backend_helper";
import { checkAccount, getCurrencies, getTakeBackCarriers, openAlert } from "../../../../store/actions";
import "../Carriers.scss";

interface Props extends RouteComponentProps {
	returnTranslation: any
}

interface Carrier {
	"id": number;
	"status": boolean;
	"name": string;
	"logo": string | null;
	"default": boolean;
	"custom": boolean;
	"external_url": boolean;
	"price": string | null;
	"currency": string | null;
	"type": string | null;
	"types": string[];
	"created_at": string | null;
	"updated_at": string | null;
	"default_integration": any;
	"fields": Field[];
}

interface Field {
	"type": string;
	"title": string;
	"name": string;
	"required": boolean;
}

const CarrierForm = ({ returnTranslation }: Props) => {
	const history = useHistory();
	const params: { takeback_id: string, carrier_id: string } = useParams();
	const dispatch = useDispatch();

	const [details, setDetails] = useState<any>(null)
	const [description, setDescription] = useState("")
	const [logo, setLogo] = useState<any>({})
	const [price, setPrice] = useState<any>(null)
	const [isDefaultIntegration, setIsDefaultIntegration] = useState(true)
	const [isCustomIntegration, setIsCustomIntegration] = useState(false)
	const [isExternalURLIntegration, setIsExternalURLIntegration] = useState(false)
	const [formErrors, setFormErrors] = useState<any>({})
	const [isFormDisabled, setIsFormDisabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const [integrationRequirements, setIntegrationRequirements] = useState([]);

	const [height, setHeight] = useState("");
	const [width, setWidth] = useState("");
	const [length, setLength] = useState("");
	const [weight, setWeight] = useState("");
	const [isFree, setIsFree] = useState(false);

	const [qrCode, setQrCode] = useState("");
	const takebackTasks = useSelector((state: any) => state?.Account?.info?.take_back_tasks)
	const onCheckAccount = () => dispatch(checkAccount());

	const [currencyId, setCurrencyId] = useState(2);
	const { currencies } = useSelector((state: any) => state?.Currencies)

	const onOpenAlert = ({ text, status }: any) => dispatch(openAlert({ text: text, status: status }));

	useEffect(() => {
		if(params?.carrier_id){
			getDetails();
			handleGetTakeBackCarrierRequirements();
			setIsEdit(true)
		}
	}, [params?.carrier_id])

	useEffect(() => {
		// checkTakeBack(params?.takeback_id);
		// console.log(["details", details])
		onCheckAccount()
		if(details){
			if(isDefaultIntegration){
				setDefaultInterationValues()
			} else if(isCustomIntegration || isExternalURLIntegration) {
				setCustomInterationSavedValues()
			}
		}

	}, [
		details,
		isDefaultIntegration,
		isCustomIntegration,
		isExternalURLIntegration
	])

	useEffect(() => {
		if(!currencies?.length){
			dispatch(getCurrencies())
		}
	}, [])

	const setDefaultInterationValues = () => {
		// setLength(details?.default_integration?.["length"] ?? "")
		// setWidth(details?.default_integration?.width ?? "")
		// setHeight(details?.default_integration?.height ?? "")
		// setWeight(details?.default_integration?.weight ?? "")
		// setPrice(details?.default_integration?.price ?? "")
		// setCurrencyId(details?.default_integration?.currency?.id ?? 2)
		// setIsFree(parseInt(details?.default_integration?.price?.toString()) === 0)
		setIsFree(details?.default_integration?.price === null);
	}

	const setCustomInterationSavedValues = () => {
		// console.log(["setCustomInterationSavedValues", details])
		setLength(length ? length : details?.["length"] ?? "")
		setWidth(width ? width : details?.width ?? "")
		setHeight(height ? height : details?.height ?? "")
		setWeight(weight ? weight : details?.weight ?? "")
		setPrice(price ? price : details?.price ?? "0.00")
		setCurrencyId(currencyId ? currencyId : details?.currency?.id ?? 2)
		// setIsFree(parseInt(details?.price?.toString()) === 0)
		setIsFree((price && price?.toString()?.length > 0)
			? isFree || parseInt(price?.toString()) === 0
			: details?.price === null || parseInt(details?.price?.toString()) === 0
		)
	}

	const getDetails = async (preventLoader = false) => {
		try {
			!preventLoader && setIsLoading(true)
			const response: Carrier = await getTakeBackCarrier(params?.takeback_id, params?.carrier_id);

			setIsDefaultIntegration(response?.type === "LOOP")
			setIsCustomIntegration(response?.type === "CUSTOM")
			setIsExternalURLIntegration(response?.type === "EXTERNAL_URL")
			
			//set 1st integration from types as selected
			if(!response?.type){
				if(response?.types?.[0] === "LOOP"){
					setIsDefaultIntegration(true)
				}else if(response?.types?.[0] === "CUSTOM"){
					setIsCustomIntegration(true)
				}else if(response?.types?.[0] === "EXTERNAL_URL"){
					setIsExternalURLIntegration(true)
				}
			}

			setDetails(response)
			return response
		} catch (e) {
			// console.log(e);
			onOpenAlert({text: returnTranslation("Error. Try again later."), status: "danger"});
			return {}
		}finally{
			setIsLoading(false);
		}
	}
	
	const handleGetTakeBackCarrierRequirements = async () => {
		try {
			const response = await getTakeBackCarrierRequirements(params?.carrier_id);
			setIntegrationRequirements(response)
			return response
		} catch (e) {
			// console.log(e);
			onOpenAlert({text: returnTranslation("Error. Try again later."), status: "danger"});
			return {}
		}
	}

	const handleSubmit = async (event: FormEvent) => {
		event?.preventDefault()
		setFormErrors({})
		setQrCode("")

		const integrationRequirementsKeys = integrationRequirements?.map((x: any) => x?.name);
		try {
			if(!isDefaultIntegration && !isCustomIntegration && !isExternalURLIntegration){
				onOpenAlert({ status: 'danger', text: returnTranslation("Please, select an integration.") })
				return;
			}

			//@ts-ignore
			const formData = new FormData(event?.target);
			// console.log(Array.from(formData.entries()))
			// const integrationRequirementsKeys = integrationRequirements?.map((x: any) => x?.name);
			const data: any = { integration_data : {} };
			Array.from(formData.entries())?.map(([key, value]) => {
				if(isCustomIntegration && integrationRequirementsKeys?.includes(key)){
					data.integration_data[key] = value
				}else{
					data[key] = value;
				}
			})

			if(!isFree){
				data.currency = currencyId;
			}else{
				data.price = "0.00";
			}

			// console.log(data)
			if(isEdit){
				setIsSaving(true)
				const response = await addTakeBackCarrier(params?.takeback_id, {
					carrier_id: params?.carrier_id,
					// default: isDefaultIntegration ? 1 : 0,
					type: isDefaultIntegration ? "LOOP" : isCustomIntegration ? "CUSTOM" : isExternalURLIntegration ? "EXTERNAL_URL" : null,
					...data,
				})
				if(response?.status === "success"){
					setQrCode(response?.label)
				}
				

				onOpenAlert({ status: 'success', text: returnTranslation("Changes saved successfully.") })
			} else {
				// const response = await addTakeBackCarrier({
				// 	name,
				// 	description,
				// 	logo: logo.id,
				// 	status: 0
				// })
				// onOpenAlert({ status: 'success', text: returnTranslation("Added successfully.") })
			}
			// TODO - dodać carriers do saga
			// dispatch(getTakeBackCarriers());

			// history?.push(`/drop-offs-near-you/${params?.takeback_id}/carriers`)
		} catch (e) {
			//@ts-ignore
			const { message, errors, status } = e?.response?.data;
			setFormErrors(errors)
			if(errors){
				onOpenAlert({ status: 'danger', text: returnTranslation(message) })
			}else if(status === "failed"){
				const customFormErrors: any = {};
				integrationRequirementsKeys?.map((key: string) => {
					customFormErrors[key] = [returnTranslation("Please make sure that the field information is correct.")];
				})
				setFormErrors(customFormErrors)
				onOpenAlert({ status: 'danger', text: returnTranslation(message) })
				// onOpenAlert({ status: 'danger', text: returnTranslation("There is a problem with Your configuration, check informations and try again.") })
			}
			// console.log(e)
		}finally{
			getDetails(true);
			setIsSaving(false)
			dispatch(getTakeBackCarriers({id: params?.takeback_id, page: 1 }))
		}
	}

	return (
		<React.Fragment>
			<MetaTags>
				<title>Take Back Form</title>
			</MetaTags>
			<div className="page-content">
				<Container fluid>
					<Breadcrumb title="Take Back" breadcrumbItem={`Configure Carrier Integration (${details?.name})`} />

					<Row>
						<Col sm={12} md={12} lg={12} xl={7}>
							<Card>
								<CardBody>
									{isLoading ? (
										<div className="w-100 h-100 d-flex justify-content-center align-items-center">
											<Spinner className="ms-2" color="secondary" />
										</div>
									): (
										<Form
											className="form-horizontal"
											onSubmit={handleSubmit}
										>
											<ContactUsAlert
												//@ts-ignore
												customText={returnTranslation("There is a problem with Your configuration, check informations and try again.")}
												display={!details?.default && !details?.configured}
												returnTranslation={returnTranslation}
												onOpenAlert={onOpenAlert}
												color={"danger"}
											/>

											<ContactUsAlert
												//@ts-ignore
												customText={returnTranslation("Return Address is required for carrier configuration.")}
												display={!takebackTasks?.return_address && !isExternalURLIntegration}
												returnTranslation={returnTranslation}
												onOpenAlert={onOpenAlert}
												color={"danger"}
												CustomTextElement={<Link className="ms-2 text-danger text-decoration-underline" to={`/take-back/${params?.takeback_id}/return-address`}>Go to return address form.</Link>}
											/>

											<div className="mb-3 d-flex align-items-center gap-2">
												<img src={details?.logo}
													style={{ width: 80, borderRadius: '100%', boxShadow: "0 0 8px -5px gray" }}
												/>
												<span className="font-size-20 fw-medium">{details?.name}</span>
											</div>

											<h4 className="card-title mt-3">{returnTranslation("Choose Integration")}</h4>
											<Row>
												{details?.types?.includes("LOOP") ? (
													<Col sm={4}>
														<div className="d-flex gap-2 align-items-center clickable">
															<Input
																type="radio"
																className="radio-input clickable mt-0"
																checked={isDefaultIntegration}
																id="radio-default-integration"
																onClick={() =>{
																	setIsDefaultIntegration(true);
																	setIsCustomIntegration(false);
																	setIsExternalURLIntegration(false);

																	// setDefaultInterationValues();
																}}
															/>
															<Label htmlFor="radio-default-integration" className={`mb-0 lh-1 ${!isDefaultIntegration ? "clickable" : "" }`}>
																<div>LOOP API</div>
																<div>
																	<small className="text-muted">
																		{returnTranslation("Pricing")}
																		{" "}{details?.default_integration?.price}
																		{" "}{details?.default_integration?.currency?.code}
																		{" "}{returnTranslation("per package")}
																	</small>
																</div>
															</Label>
														</div>
													</Col>
												) : null}
												{details?.types?.includes("CUSTOM") ? (
													<Col sm={4}>
														<div className="d-flex gap-2 align-items-center clickable">
															<Input
																type="radio"
																className="radio-input clickable mt-0"
																checked={isCustomIntegration}
																id="radio-custom-integration"
																onClick={() =>{
																	setIsDefaultIntegration(false);
																	setIsCustomIntegration(true);
																	setIsExternalURLIntegration(false);

																	// setCustomInterationSavedValues()
																}}
															/>
															<Label htmlFor="radio-custom-integration" className={`mb-0 ${!isCustomIntegration ? "clickable" : "" }`} >Custom API</Label>
														</div>
													</Col>
												): null}
												{details?.types?.includes("EXTERNAL_URL") ? (
													<Col sm={4}>
														<div className="d-flex gap-2 align-items-center clickable">
															<Input
																type="radio"
																className="radio-input clickable mt-0"
																checked={isExternalURLIntegration}
																id="radio-custom-integration-url"
																onClick={() =>{
																	setIsDefaultIntegration(false);
																	setIsCustomIntegration(false);
																	setIsExternalURLIntegration(true);
																}}
															/>
															<Label htmlFor="radio-custom-integration-url" className={`mb-0 ${!isExternalURLIntegration ? "clickable" : "" }`} >Return URL</Label>
														</div>
													</Col>
												): null}
											</Row>
											

											{isCustomIntegration && integrationRequirements?.length ? (
												<Row className="mt-3">
													{integrationRequirements?.map((field: Field) => {
														return (
															<FormInput
																// disabled={!isCustomIntegration}
																label={returnTranslation(field?.title)}
																type="text"
																name={field?.name}
																// required={field?.required}
																defaultValue={details?.integration_data?.[field?.name]}
																className="form-control"
																placeholder={returnTranslation("")}
																showErrors={true}
																formErrors={formErrors}
																// onChange={(e: any) => {
																// 	setLength(e.target.value)
																// }}
															/>
														)
													})}
													
												</Row>
											) : null}

											{isExternalURLIntegration ? (
												<Row className="mt-3">
													<FormInput
														// disabled={!isCustomIntegration}
														label={returnTranslation("Return URL")}
														type="text"
														name={"external_url"}
														// required={field?.required}
														defaultValue={details?.external_url}
														className="form-control"
														placeholder={returnTranslation("")}
														showErrors={true}
														formErrors={formErrors}
														// onChange={(e: any) => {
														// 	setLength(e.target.value)
														// }}
													/>
												</Row>
											): null}

											{/* {!isExternalURLIntegration ? (

											): null} */}
											<div className="mt-3">
												<span className="card-title">{returnTranslation("Package Details")}</span>
												{isExternalURLIntegration ? <span className="ms-1 text-muted">({returnTranslation("not required")})</span> : null}
											</div>
											<div className="mt-1">
												<div className="">{returnTranslation("Pricing")}</div>
												<Row>
													<FormInput
														disabled={isDefaultIntegration}
														label={returnTranslation("Free")}
														type="checkbox"
														// name="free-checkbox"
														name=""
														className="form-check-input mt-0 me-1"
														placeholder={""}
														showErrors
														formErrors={formErrors}
														checked={isFree}
														id={`free-checkbox`}
														onChange={() => setIsFree(!isFree)}
													/>
												</Row>
												<Row>
													<Col sm={2}>
														<FormInput
															disabled={isDefaultIntegration || isFree}
															label={returnTranslation("Currency")}
															type="select"
															name="currency"
															className="form-control"
															placeholder={returnTranslation("Select currency")}
															showErrors
															formErrors={formErrors}
															onChange={(ev: any) => { 
																// console.log(['currencyId', ev?.target?.value]);
																setCurrencyId(ev?.target?.value)
															}}
															// value={currencyId}
															value={isDefaultIntegration ? (details?.default_integration?.currency?.id ?? "") : currencyId}
															options={currencies?.map(
																(currency: any, i: number) => (
																	<option key={currency?.id} value={currency?.id}>
																		{currency?.code}
																	</option>
																)
															)}
														/>
													</Col>
													<Col sm={2}>
														<FormInput
															addonType={"prepend"}
															addonText={currencies?.find((curr: any) => curr?.id == currencyId)?.symbol}
															disabled={isDefaultIntegration || isFree}
															label={returnTranslation("Price")}
															type="text"
															name="price"
															value={isDefaultIntegration ? (details?.default_integration?.price ?? "0.00") : isFree ? "0.00" : price}
															className="form-control input-number--no-arrows pe-0"
															placeholder={returnTranslation()}
															showErrors
															formErrors={formErrors}
															onChange={(e: any) => {
																setPrice(e.target.value)
															}}
															step="0.01"
															min="0.01"
															onBlur={() => setIsFree(parseInt(price?.toString()) === 0)}
														/>
													</Col>
												</Row>
											</div>

											<div className="mt-1">
												<div className="">{returnTranslation("Max. dimensions")}</div>

												<Row className="d-flex align-items-center">
													<Col sm={2}>
														<FormInput
															disabled={isDefaultIntegration}
															label={returnTranslation("Length (cm)")}
															type="number"
															name="length"
															value={isDefaultIntegration ? (details?.default_integration?.["length"] ?? "") : length}
															// setLength(details?.default_integration?.["length"] ?? "")
															// setWidth(details?.default_integration?.width ?? "")
															// setHeight(details?.default_integration?.height ?? "")
															// setWeight(details?.default_integration?.weight ?? "")
															// setPrice(details?.default_integration?.price ?? "")
															// setCurrencyId(details?.default_integration?.currency?.id ?? 2)
															className="form-control"
															placeholder={returnTranslation("")}
															showErrors
															formErrors={formErrors}
															onChange={(e: any) => {
																setLength(e.target.value)
															}}
														/>
													</Col>

													<Col sm={2}>
														<FormInput
															disabled={isDefaultIntegration}
															label={returnTranslation("Width (cm)")}
															type="number"
															name="width"
															// value={width}
															value={isDefaultIntegration ? (details?.default_integration?.width ?? "") : width}
															className="form-control"
															placeholder={returnTranslation("")}
															showErrors
															formErrors={formErrors}
															onChange={(e: any) => {
																setWidth(e.target.value)
															}}
														/>
													</Col>

													<Col sm={2}>
														<FormInput
															disabled={isDefaultIntegration}
															label={returnTranslation("Height (cm)")}
															type="number"
															name="height"
															// value={height}
															value={isDefaultIntegration ? (details?.default_integration?.height ?? "") : height}
															className="form-control"
															placeholder={returnTranslation("")}
															showErrors
															formErrors={formErrors}
															onChange={(e: any) => {
																setHeight(e.target.value)
															}}
														/>
													</Col>
												</Row>
											</div>

											<div className="mt-1">
												<div className="">{returnTranslation("Max. weight")}</div>
												<Row>
													<Col sm={2}>
														<FormInput
															disabled={isDefaultIntegration}
															label={returnTranslation("Weight (kg)")}
															type="number"
															name="weight"
															// value={weight}
															value={isDefaultIntegration ? (details?.default_integration?.weight ?? "") : weight}
															className="form-control"
															placeholder={returnTranslation("")}
															showErrors
															formErrors={formErrors}
															onChange={(e: any) => {
																setWeight(e.target.value)
															}}
														/>
													</Col>
												</Row>
											</div>

											{/* <div className="form-group">
												<Label>{returnTranslation("Description")}</Label>
												<InputGroup>
													<Input
														name="description"
														label="Description"
														value={description}
														className="form-control"
														placeholder={returnTranslation("Enter text")}
														type="textarea"
														rows={12}
														resize={false}
														onChange={(e: any) => setDescription(e.target.value)}
														invalid={formErrors?.description}
														disabled={isFormDisabled}
													/>
												</InputGroup>
											</div> */}

											<div className="mb-3 mt-4 d-flex gap-2">
												<Button
													color="success"
													type="submit"
													className="btn-no-shadow btn-block waves-effect waves-light"
													disabled={isFormDisabled}
												>
													{returnTranslation("Save configuration")}
													{isSaving ? (
														<Spinner className="ms-2" color="dark" />
													): null}
												</Button>
												{/* @ts-ignore */}
												{history?.location?.state?.from
													? (
														<Button
															onClick={() => history?.goBack()}
															type="button"
															className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
														>
															{returnTranslation("Back")}
														</Button>	
													) : null
												}
												{qrCode ? (
													<a href={qrCode} target="_blank">
														<Button
															color="secondary"
															type="button"
															className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
														>
															{returnTranslation("Show test label")}
															<i className="mdi mdi-qrcode font-size-12 ms-2" />
														</Button>
													</a>
												): null}
											</div>
											
										</Form>
									)}
									{/* <h4 className="card-title mb-4">{returnTranslation("Charity Details")}</h4> */}
									{/* <ContactUsAlert
										display={isFormDisabled}
										returnTranslation={returnTranslation}
										onOpenAlert={onOpenAlert}
									/>
									<ContactUsAlert
										//@ts-ignore
										customText={getMissingInfoMessageText()}
										display={getMissingInfoMessageText() ? true : false}
										returnTranslation={returnTranslation}
										onOpenAlert={onOpenAlert}
									/> */}
									
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	)
}

export default withRouter(CarrierForm);
