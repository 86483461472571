import React, { useState } from "react"
import {
    Alert,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormFeedback,
    FormGroup,
    Label,
    Row,
    Input,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from "reactstrap"

interface ISubscriptionsPlans {
    type: string;
    handleSetPlan: (value: string) => void;
    displayTrialText: boolean;
    selectedPlanName?: string;
    changeBilling?: boolean;
}
const SubscriptionsPlans: React.FC<ISubscriptionsPlans> = ({
    type,
    handleSetPlan,
    displayTrialText,
    selectedPlanName,
    changeBilling
}) => {
    const [showBasic, setShowBasic] = useState(false)
    const [showStandard, setShowStandard] = useState(false)
    const [showEnterprice, setShowEnterprice] = useState(false)

    const handleOpenContactForm = () => {
        var win = window?.open('https://loopdigitalwardrobe.com/contact-us', '_blank');
        return win?.focus();
    }

    return (
        <>
            {type?.toLowerCase() === "retailer" ?
                <Row className="plan-row-container">
                    <Col className="col-lg-3 col-md-12 col-sm-12 plan-row">
                        {/* <div className="plan-row-top">
                            <p className="plan-row-top__from"></p>
                            <p className="plan-row-top__price">£49 <span>/ Month</span></p>
                            <p className="plan-row-top__title">BASIC</p>
                            <p className="plan-row-top__description">Sustainability Credentials</p>
                            <button
                                className={`plan-row-bottom__btn btn btn-block waves-effect waves-light ${selectedPlanName === 'BASIC' && !changeBilling ? 'btn-success text-light disable-pointer-events' : ''}`}
                                type="submit"
                                onClick={() => handleSetPlan("basic")}
                            >
                                {selectedPlanName === 'BASIC'
                                    ? changeBilling ? "Change Billing" : "Current Plan"
                                    : displayTrialText ? "Choose 30 days free trial" : "Choose Plan"}
                            </button>
                        </div>
                        <div className="plan-row__trial"><p>Get a 30 day free trial</p></div> */}
                        <div className="plan-row-top">
                            <p className="plan-row-top__title">BASIC</p>
                            <div className="plan-row-top__description mb-1">
                                <div>Sustainability Credentials</div>
                                <div className="empty"></div>

                                <div className="plan-row-top__description--text">In effort to promote Circular Fashion LOOP offers RETAIL BASIC version for free.
                                    No more excuses for not giving customers hassle free sustainability options.</div>
                                {/* <div className="empty"></div> */}
                                {/* <div className="empty"></div> */}
                            </div>
                        </div>
                        <div className="plan-row__trial"><p>Get a 30 day free trial</p></div>
                        <div className="d-flex justify-content-center align-items-center gap-2">
                            <div>
                                <span className="plan-row-top__price">Free <span className="line-through">£49</span></span>
                                <div className="plan-row-top__from">/a month</div>
                            </div>
                        </div>
                        <div className="plan-row-top">
                            <button
                                className={`plan-row-bottom__btn mt-0 btn btn-block waves-effect waves-light ${selectedPlanName === 'BASIC' && !changeBilling ? 'btn-success text-light disable-pointer-events' : ''}`}
                                type="submit"
                                onClick={() => handleSetPlan("basic")}
                            >
                                {selectedPlanName === 'BASIC'
                                    ? changeBilling ? "Change Billing" : "Current Plan"
                                    : displayTrialText ? "Choose 30 days free trial" : "Choose Plan"}
                            </button>
                        </div>
                        <div className="plan-row-bottom">
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="1 domain" title="1 domain" />
                                <h6>1 domain</h6>
                            </div>
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Widget API integration" title="Widget API integration" />
                                <h6>Widget API integration</h6>
                            </div>
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Mobile shop integration" title="Mobile shop integration" />
                                <h6>Mobile shop integration</h6>
                            </div>
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Ecommerce Integration" title="Ecommerce Integration" />
                                <div>
                                    <h6>Ecommerce Integration</h6>
                                    <p>
                                        (Shopify, Magento 1.x,
                                        Magento 2.x, BigCommerce,
                                        PrestaShop, OpenCart,
                                        Shopware)
                                    </p>
                                </div>
                            </div>
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Loop Trustmark" title="Loop Trustmark" />
                                <h6>Loop Trustmark</h6>
                            </div>
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Access to the Upcycling" title="Access to the Upcycling" />
                                <h6>Access to the Upcycling</h6>
                            </div><div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Access to the Donation" title="Access to the Donation" />
                                <h6>Access to the Donation</h6>
                            </div>
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="AI-powered" title="AI-powered" />
                                <h6>AI-powered</h6>
                            </div>
                            <button
                                className="plan-row-bottom__btn btn btn-block waves-effect waves-light mb-4 mt-0"
                                type="submit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowBasic(!showBasic)
                                }}
                            >
                                {`Show ${showBasic ? "Less" : "More"}`}
                            </button>
                            <div style={showBasic ? { display: "block" } : { display: "none" }}>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Customer Support" title="Customer Support" />
                                    <h6>Customer Support</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Widget API integration" title="Widget API integration" />
                                    <h6>Widget API integration</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Widget Customization" title="Widget Customization" />
                                    <h6>Widget Customization</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Advertisement Management System" title="Advertisement Management System" />
                                    <h6>Advertisement Management System</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Coupon codes manager" title="Coupon codes manager" />
                                    <h6>Coupon codes manager</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Analytics Dashboard" title="Analytics Dashboard" />
                                    <h6>Analytics Dashboard</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Reports" title="Reports" />
                                    <h6>Reports</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Import products feeds" title="Import products feeds" />
                                    <h6>Import products feeds</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Resell for business" title="Resell for business" />
                                    <h6>Resell for business</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Enterprise extra add ones" title="Enterprise extra add ones" />
                                    <h6>Enterprise extra add ones</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Custom integration" title="Custom integration" />
                                    <h6>Custom integration</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Returns module" title="Returns module" />
                                    <h6>Returns module</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Custom data analytics" title="Custom data analytics" />
                                    <h6>Custom data analytics</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="API integration" title="API integration" />
                                    <h6>API integration</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Dedicated Account manager" title="Dedicated Account manager" />
                                    <h6>Dedicated Account manager</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Marketing Automation" title="Marketing Automation" />
                                    <h6>Marketing Automation</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Custom newsletter" title="Custom newsletter" />
                                    <h6>Custom newsletter</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Web push notification" title="Web push notification" />
                                    <h6>Web push notification</h6>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-lg-3 col-md-12 col-sm-12 plan-row">
                        {/* <div className="plan-row-top">
                            <p className="plan-row-top__from"></p>
                            <p className="plan-row-top__price">£199 <span>/ Month</span></p>
                            <p className="plan-row-top__title">STANDARD</p>
                            <p className="plan-row-top__description">Sustainability Credentials <br />Marketing platform </p>
                            <button
                                className={`plan-row-bottom__btn btn btn-block waves-effect waves-light ${selectedPlanName === 'STANDARD' && !changeBilling ? 'btn-success text-light disable-pointer-events' : ''}`}
                                type="submit"
                                onClick={() => handleSetPlan("standard")}
                            >
                                {selectedPlanName === 'STANDARD'
                                    ? changeBilling ? "Change Billing" : "Current Plan"
                                    : displayTrialText ? "Choose 30 days free trial" : "Choose Plan"}
                            </button>
                        </div>
                        <div className="plan-row__trial"><p>Get a 30 day free trial</p></div> */}
                        <div className="plan-row-top">
                            <p className="plan-row-top__title">STANDARD</p>
                            <div className="plan-row-top__description mb-1">
                                <div>Sustainability Credentials</div>
                                <div>Marketing platform</div>
                                <div className="empty"></div>
                                <div className="empty"></div>
                                <div className="empty"></div>
                            </div>
                        </div>
                        <div className="plan-row__trial"><p>*FREE with checkout integration</p></div>
                        <div className="d-flex justify-content-center align-items-center gap-2">
                            <div>
                                <span className="plan-row-top__price">from £249</span>
                                {/* <span className="plan-row-top__price">from £99 <span className="line-through">£249</span></span> */}
                                <div className="plan-row-top__from">/a month</div>
                            </div>
                        </div>
                        <div className="plan-row-top">
                            <button
                                className={`plan-row-bottom__btn mt-0 btn btn-block waves-effect waves-light ${selectedPlanName === 'STANDARD' && !changeBilling ? 'btn-success text-light disable-pointer-events' : ''}`}
                                type="submit"
                                onClick={() => handleSetPlan("standard")}
                            >
                                {selectedPlanName === 'STANDARD'
                                    ? changeBilling ? "Change Billing" : "Current Plan"
                                    : displayTrialText ? "Choose 30 days free trial" : "Choose Plan"}
                            </button>
                        </div>

                        <div className="plan-row-bottom">
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Up to 3 domains" title="Up to 3 domains" />
                                <h6>Up to 3 domains</h6>
                            </div>
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Widget API integration" title="Widget API integration" />
                                <h6>Widget API integration</h6>
                            </div>
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Mobile shop integration" title="Mobile shop integration" />
                                <h6>Mobile shop integration</h6>
                            </div>
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Ecommerce Integration" title="Ecommerce Integration" />
                                <div>
                                    <h6>Ecommerce Integration</h6>
                                    <p>
                                        (Shopify, Magento 1.x,
                                        Magento 2.x, BigCommerce,
                                        PrestaShop, OpenCart,
                                        Shopware)
                                    </p>
                                </div>
                            </div>
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Loop Trustmark" title="Loop Trustmark" />
                                <h6>Loop Trustmark</h6>
                            </div>
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Access to the Upcycling" title="Access to the Upcycling" />
                                <h6>Access to the Upcycling</h6>
                            </div><div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Access to the Donation" title="Access to the Donation" />
                                <h6>Access to the Donation</h6>
                            </div>
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="AI-powered" title="AI-powered" />
                                <h6>AI-powered</h6>
                            </div>
                            <button
                                className="plan-row-bottom__btn btn btn-block waves-effect waves-light mb-4 mt-0"
                                type="submit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowStandard(!showStandard)
                                }}
                            >
                                {`Show ${showStandard ? "Less" : "More"}`}
                            </button>
                            <div style={showStandard ? { display: "block" } : { display: "none" }}>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Customer Support" title="Customer Support" />
                                    <h6>Customer Support</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Widget API integration" title="Widget API integration" />
                                    <h6>Widget API integration</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Widget Customization" title="Widget Customization" />
                                    <h6>Widget Customization</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Advertisement Management System" title="Advertisement Management System" />
                                    <h6>Advertisement Management System</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Coupon codes manager" title="Coupon codes manager" />
                                    <h6>Coupon codes manager</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Analytics Dashboard" title="Analytics Dashboard" />
                                    <h6>Analytics Dashboard</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Reports" title="Reports" />
                                    <h6>Reports</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Import products feeds" title="Import products feeds" />
                                    <h6>Import products feeds</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Resell for business" title="Resell for business" />
                                    <h6>Resell for business</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Enterprise extra add ones" title="Enterprise extra add ones" />
                                    <h6>Enterprise extra add ones</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Custom integration" title="Custom integration" />
                                    <h6>Custom integration</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Returns module" title="Returns module" />
                                    <h6>Returns module</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Custom data analytics" title="Custom data analytics" />
                                    <h6>Custom data analytics</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="API integration" title="API integration" />
                                    <h6>API integration</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Dedicated Account manager" title="Dedicated Account manager" />
                                    <h6>Dedicated Account manager</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Marketing Automation" title="Marketing Automation" />
                                    <h6>Marketing Automation</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Custom newsletter" title="Custom newsletter" />
                                    <h6>Custom newsletter</h6>
                                </div>
                                <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                    <img src="/assets/images/option-inactive.png" alt="Web push notification" title="Web push notification" />
                                    <h6>Web push notification</h6>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-lg-3 col-md-12 col-sm-12 plan-row">
                        <div className="plan-row-top">
                            <p className="plan-row-top__title">ENTERPRISE</p>
                            <div className="plan-row-top__description mb-1">
                                <div>Sustainability Credentials</div>
                                <div>ESG Dashboards</div>
                                <div>Marketing platform</div>
                                <div>Data analytics dashboards</div>
                                <div className="empty"></div>
                            </div>
                        </div>
                        <div className="plan-row-top__trial--empty"></div>
                        <div className="d-flex justify-content-center align-items-center gap-2">

                        </div>
                        <div className="plan-row-top">
                            {/* <a
                                className={`plan-row-bottom__btn mt-0 btn btn-block waves-effect waves-light ${selectedPlanName === 'ENTERPRISE' && !changeBilling ? 'btn-success text-light disable-pointer-events' : ''}`}
                                href="https://loopdigitalwardrobe.com/contact-us"
                                target="_blank"
                            >
                               
                            </a> */}
                            <div
                                className={`plan-row-bottom__btn--get-in-touch plan-row-bottom__btn mt-0 btn btn-block waves-effect waves-light ${selectedPlanName === 'ENTERPRISE' && !changeBilling ? 'btn-success text-light disable-pointer-events' : ''}`}
                                // type="submit"
                                onClick={handleOpenContactForm}
                            >
                                Get in Touch 
                                <img src="https://loopdigitalwardrobe.com/wp-content/uploads/2023/09/email.png"/>
                            </div>
                        </div>
                        <div className="plan-row-bottom">
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Unlimited domains" title="Unlimited domains" />
                                <h6>Unlimited domains</h6>
                            </div>
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Widget API integration" title="Widget API integration" />
                                <h6>Widget API integration</h6>
                            </div>
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Mobile shop integration" title="Mobile shop integration" />
                                <h6>Mobile shop integration</h6>
                            </div>
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Ecommerce Integration" title="Ecommerce Integration" />
                                <div>
                                    <h6>Ecommerce Integration</h6>
                                    <p>
                                        (Shopify, Magento 1.x,
                                        Magento 2.x, BigCommerce,
                                        PrestaShop, OpenCart,
                                        Shopware)
                                    </p>
                                </div>
                            </div>
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Loop Trustmark" title="Loop Trustmark" />
                                <h6>Loop Trustmark</h6>
                            </div>
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Access to the Upcycling" title="Access to the Upcycling" />
                                <h6>Access to the Upcycling</h6>
                            </div><div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="Access to the Donation" title="Access to the Donation" />
                                <h6>Access to the Donation</h6>
                            </div>
                            <div className="plan-row-bottom__option">
                                <img src="/assets/images/option-active.png" alt="AI-powered" title="AI-powered" />
                                <h6>AI-powered</h6>
                            </div>
                            <button
                                className="plan-row-bottom__btn btn btn-block waves-effect waves-light mb-4 mt-0"
                                type="submit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowEnterprice(!showEnterprice)
                                }}
                            >
                                {`Show ${showEnterprice ? "Less" : "More"}`}
                            </button>
                            <div style={showEnterprice ? { display: "block" } : { display: "none" }}>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Customer Support" title="Customer Support" />
                                    <h6>Customer Support</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Widget API integration" title="Widget API integration" />
                                    <h6>Widget API integration</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Widget Customization" title="Widget Customization" />
                                    <h6>Widget Customization</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Advertisement Management System" title="Advertisement Management System" />
                                    <h6>Advertisement Management System</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Coupon codes manager" title="Coupon codes manager" />
                                    <h6>Coupon codes manager</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Analytics Dashboard" title="Analytics Dashboard" />
                                    <h6>Analytics Dashboard</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Reports" title="Reports" />
                                    <h6>Reports</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Import products feeds" title="Import products feeds" />
                                    <h6>Import products feeds</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Resell for business" title="Resell for business" />
                                    <h6>Resell for business</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Enterprise extra add ones" title="Enterprise extra add ones" />
                                    <h6>Enterprise extra add ones</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Custom integration" title="Custom integration" />
                                    <h6>Custom integration</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Returns module" title="Returns module" />
                                    <h6>Returns module</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Custom data analytics" title="Custom data analytics" />
                                    <h6>Custom data analytics</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="API integration" title="API integration" />
                                    <h6>API integration</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Dedicated Account manager" title="Dedicated Account manager" />
                                    <h6>Dedicated Account manager</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Marketing Automation" title="Marketing Automation" />
                                    <h6>Marketing Automation</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Custom newsletter" title="Custom newsletter" />
                                    <h6>Custom newsletter</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Web push notification" title="Web push notification" />
                                    <h6>Web push notification</h6>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                : type?.toLowerCase() === "charity" ?
                    <Row className="plan-row-container">

                        <Col className="col-lg-3 col-md-12 col-sm-12 plan-row">
                            {/* <div className="plan-row-top">
                                <p className="plan-row-top__from"></p>
                                <p className="plan-row-top__price">Free</p>
                                <p className="plan-row-top__title">Charity Standard</p>
                                <p className="plan-row-top__description">Sustainability Credentials <br />Marketing platform </p>
                                <button
                                    className="plan-row-top__trial btn btn-block waves-effect waves-light"
                                    disabled
                                >
                                    30 days free trial
                                </button>
                                <button
                                    className="plan-row-bottom__btn btn btn-block waves-effect waves-light"
                                    type="submit"
                                    onClick={() => handleSetPlan("standard")}
                                >
                                    {displayTrialText ? "Choose 30 days free trial" : "Choose Plan"}
                                </button>
                            </div> */}
                            <div className="plan-row-top">
                                <p className="plan-row-top__title">STANDARD</p>
                                <div className="plan-row-top__description mb-1">
                                    <div className="light-blue">Sustainability Credentials</div>
                                    <div className="pink">Marketing platform</div>
                                    {/* <div className="empty"></div> */}
                                </div>
                            </div>
                            <div className="plan-row__trial"><p>Get a 30 day free trial</p></div>
                            <div className="d-flex justify-content-center align-items-center gap-2 mt-2">
                                <div>
                                    <span className="plan-row-top__price">FREE</span>
                                    {/* <div className="plan-row-top__from"></div> */}
                                </div>
                            </div>
                            <div className="plan-row-top">
                                <button
                                    className={`plan-row-bottom__btn mt-0 btn btn-block waves-effect waves-light ${selectedPlanName === 'STANDARD' && !changeBilling ? 'btn-success text-light disable-pointer-events' : ''}`}
                                    type="submit"
                                    onClick={() => handleSetPlan("standard")}
                                >
                                    {selectedPlanName === 'STANDARD'
                                        ? changeBilling ? "Change Billing" : "Current Plan"
                                        : displayTrialText ? "Choose 30 days free trial" : "Choose Plan"}
                                </button>
                            </div>

                            <div className="plan-row-bottom">
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Up to 3 domains" title="Up to 3 domains" />
                                    <h6>Unlimited domains</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Widget API integration" title="Widget API integration" />
                                    <h6>Widget API integration</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Widget Customization" title="Widget Customization" />
                                    <h6>Widget Customization</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Loop Virtual Charity Shop" title="Loop Virtual Charity Shop" />
                                    <h6>Loop Virtual Charity Shop</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Loop Micro-Donations" title="Loop Micro-Donations" />
                                    <h6>Loop Micro-Donations</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="By-Post Charity Donations" title="By-Post Charity Donations" />
                                    <h6>By-Post Charity Donations</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Clothing Donation at Charity Shops" title="Clothing Donation at Charity Shops" />
                                    <h6>Clothing Donation at Charity Shops</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Loop Trustmark" title="Loop Trustmark" />
                                    <h6>Loop Trustmark</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Online Marketing Materials" title="Online Marketing Materials" />
                                    <h6>Online Marketing Materials</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="In-Store Marketing Materials" title="In-Store Marketing Materials" />
                                    <h6>In-Store Marketing Materials</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Donation Rewarding System" title="Donation Rewarding System" />
                                    <h6>Donation Rewarding System</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Advertisement Management System" title="Advertisement Management System" />
                                    <h6>Advertisement Management System</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="AI-powered" title="AI-powered" />
                                    <h6>AI-powered</h6>
                                </div>
                                <div className="plan-row-bottom__option">
                                    <img src="/assets/images/option-active.png" alt="Customer Support" title="Customer Support" />
                                    <h6>Customer Support</h6>
                                </div>
                                <button
                                    className="plan-row-bottom__btn btn btn-block waves-effect waves-light mb-4 mt-0"
                                    type="submit"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowStandard(!showStandard)
                                    }}
                                >
                                    {`Show ${showStandard ? "Less" : "More"}`}
                                </button>
                                <div style={showStandard ? { display: "block" } : { display: "none" }}>
                                    <div className="plan-row-bottom__option">
                                        <img src="/assets/images/option-active.png" alt="Custom data analytics" title="Custom data analytics" />
                                        <h6>Custom data analytics</h6>
                                    </div>
                                    <div className="plan-row-bottom__option">
                                        <img src="/assets/images/option-active.png" alt="Analytics Dashboard" title="Analytics Dashboard" />
                                        <h6>Analytics Dashboard</h6>
                                    </div>
                                    <div className="plan-row-bottom__option">
                                        <img src="/assets/images/option-active.png" alt="Reports" title="Reports" />
                                        <h6>Reports</h6>
                                    </div>
                                    <div className="plan-row-bottom__option">
                                        <img src="/assets/images/option-active.png" alt="Advertisement Management System" title="Advertisement Management System" />
                                        <h6>Advertisement Management System</h6>
                                    </div>
                                    <div className="plan-row-bottom__option">
                                        <img src="/assets/images/option-active.png" alt="Custom newsletter" title="Custom newsletter" />
                                        <h6>Custom newsletter</h6>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    : type?.toLowerCase() === "school" ?
                        <Row className="plan-row-container">

                            <Col className="col-lg-3 col-md-12 col-sm-12 plan-row">
                                {/* <div className="plan-row-top">
                        <p className="plan-row-top__from"></p>
                        <p className="plan-row-top__price">Free</p>
                        <p className="plan-row-top__title">School Standard</p>
                        <p className="plan-row-top__description">Sustainability Credentials <br />Marketing platform </p>
                        <button
                                    className="plan-row-top__trial btn btn-block waves-effect waves-light"
                                    disabled
                                >
                                    30 days free trial
                                </button>
                        <button
                            className="plan-row-bottom__btn btn btn-block waves-effect waves-light"
                            type="submit"
                            onClick={() => handleSetPlan("standard")}
                        >
                            {displayTrialText ? "Choose 30 days free trial" : "Choose Plan"}
                        </button>
                    </div> */}

                                <div className="plan-row-top">
                                    <p className="plan-row-top__title">STANDARD</p>
                                    <div className="plan-row-top__description mb-1">
                                        <div className="light-blue">Sustainability Credentials</div>
                                        <div className="pink">Marketing platform</div>
                                        {/* <div className="empty"></div> */}
                                    </div>
                                </div>
                                <div className="plan-row__trial"><p>Get a 30 day free trial</p></div>
                                <div className="d-flex justify-content-center align-items-center gap-2 mt-2">
                                    <div>
                                        <span className="plan-row-top__price">FREE</span>
                                        {/* <div className="plan-row-top__from"></div> */}
                                    </div>
                                </div>
                                <div className="plan-row-top">
                                    <button
                                        className={`plan-row-bottom__btn mt-0 btn btn-block waves-effect waves-light ${selectedPlanName === 'STANDARD' && !changeBilling ? 'btn-success text-light disable-pointer-events' : ''}`}
                                        type="submit"
                                        onClick={() => handleSetPlan("standard")}
                                    >
                                        {selectedPlanName === 'STANDARD'
                                            ? changeBilling ? "Change Billing" : "Current Plan"
                                            : displayTrialText ? "Choose 30 days free trial" : "Choose Plan"}
                                    </button>
                                </div>


                                <div className="plan-row-bottom">
                                    <div className="plan-row-bottom__option">
                                        <img src="/assets/images/option-active.png" alt="Up to 3 domains" title="Up to 3 domains" />
                                        <h6>Unlimited domains</h6>
                                    </div>
                                    <div className="plan-row-bottom__option">
                                        <img src="/assets/images/option-active.png" alt="Widget API integration" title="Widget API integration" />
                                        <h6>Widget API integration</h6>
                                    </div>
                                    <div className="plan-row-bottom__option">
                                        <img src="/assets/images/option-active.png" alt="Widget Customization" title="Widget Customization" />
                                        <h6>Widget Customization</h6>
                                    </div>
                                    <div className="plan-row-bottom__option">
                                        <img src="/assets/images/option-active.png" alt="Loop Virtual School Shop" title="Loop Virtual School Shop" />
                                        <h6>Loop Virtual School Shop</h6>
                                    </div>
                                    <div className="plan-row-bottom__option">
                                        <img src="/assets/images/option-active.png" alt="Loop Micro-Donations" title="Loop Micro-Donations" />
                                        <h6>Loop Micro-Donations</h6>
                                    </div>
                                    <div className="plan-row-bottom__option">
                                        <img src="/assets/images/option-active.png" alt="By-Post School Donations" title="By-Post School Donations" />
                                        <h6>By-Post School Donations</h6>
                                    </div>
                                    <div className="plan-row-bottom__option">
                                        <img src="/assets/images/option-active.png" alt="Clothing Donation at School Shops" title="Clothing Donation at School Shops" />
                                        <h6>Clothing Donation at School Shops</h6>
                                    </div>
                                    <div className="plan-row-bottom__option">
                                        <img src="/assets/images/option-active.png" alt="Loop Trustmark" title="Loop Trustmark" />
                                        <h6>Loop Trustmark</h6>
                                    </div>
                                    <div className="plan-row-bottom__option">
                                        <img src="/assets/images/option-active.png" alt="Online Marketing Materials" title="Online Marketing Materials" />
                                        <h6>Online Marketing Materials</h6>
                                    </div>
                                    <div className="plan-row-bottom__option">
                                        <img src="/assets/images/option-active.png" alt="In-Store Marketing Materials" title="In-Store Marketing Materials" />
                                        <h6>In-Store Marketing Materials</h6>
                                    </div>
                                    <div className="plan-row-bottom__option">
                                        <img src="/assets/images/option-active.png" alt="Donation Rewarding System" title="Donation Rewarding System" />
                                        <h6>Donation Rewarding System</h6>
                                    </div>
                                    <div className="plan-row-bottom__option">
                                        <img src="/assets/images/option-active.png" alt="Advertisement Management System" title="Advertisement Management System" />
                                        <h6>Advertisement Management System</h6>
                                    </div>
                                    <div className="plan-row-bottom__option">
                                        <img src="/assets/images/option-active.png" alt="AI-powered" title="AI-powered" />
                                        <h6>AI-powered</h6>
                                    </div>
                                    <div className="plan-row-bottom__option">
                                        <img src="/assets/images/option-active.png" alt="Customer Support" title="Customer Support" />
                                        <h6>Customer Support</h6>
                                    </div>
                                    <button
                                        className="plan-row-bottom__btn btn btn-block waves-effect waves-light mb-4 mt-0"
                                        type="submit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowStandard(!showStandard)
                                        }}
                                    >
                                        {`Show ${showStandard ? "Less" : "More"}`}
                                    </button>
                                    <div style={showStandard ? { display: "block" } : { display: "none" }}>
                                        <div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="Custom data analytics" title="Custom data analytics" />
                                            <h6>Custom data analytics</h6>
                                        </div>
                                        <div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="Analytics Dashboard" title="Analytics Dashboard" />
                                            <h6>Analytics Dashboard</h6>
                                        </div>
                                        <div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="Reports" title="Reports" />
                                            <h6>Reports</h6>
                                        </div>
                                        <div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="Advertisement Management System" title="Advertisement Management System" />
                                            <h6>Advertisement Management System</h6>
                                        </div>
                                        <div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="Custom newsletter" title="Custom newsletter" />
                                            <h6>Custom newsletter</h6>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        : type?.toLowerCase() === "partner" ?
                            <Row className="plan-row-container">
                                <Col className="col-lg-3 col-md-12 col-sm-12 plan-row">
                                    <div className="plan-row-top">
                                        <p className="plan-row-top__from"></p>
                                        <p className="plan-row-top__price">£249 <span>/ Month</span></p>
                                        <p className="plan-row-top__title">Partner Standard</p>
                                        <p className="plan-row-top__description">Sustainability Credentials <br />Marketing platform </p>
                                        {/* <button
                                        className="plan-row-top__trial btn btn-block waves-effect waves-light"
                                        disabled
                                    >
                                        30 days free trial
                                    </button> */}
                                        <button
                                            className="plan-row-bottom__btn btn btn-block waves-effect waves-light"
                                            type="submit"
                                            onClick={() => handleSetPlan("standard")}
                                        >
                                            {displayTrialText ? "Choose 30 days free trial" : "Choose Plan"}
                                        </button>
                                    </div>
                                    <div className="plan-row__trial"><p>Get a 30 day free trial</p></div>
                                    <div className="plan-row-bottom">
                                        <div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="Up to 3 domains" title="Up to 3 domains" />
                                            <h6>Up to 3 domains</h6>
                                        </div>
                                        <div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="Widget API integration" title="Widget API integration" />
                                            <h6>Widget API integration</h6>
                                        </div>
                                        <div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="Mobile shop integration" title="Mobile shop integration" />
                                            <h6>Mobile shop integration</h6>
                                        </div>
                                        <div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="Ecommerce Integration" title="Ecommerce Integration" />
                                            <div>
                                                <h6>Ecommerce Integration</h6>
                                                <p>
                                                    (Shopify, Magento 1.x,
                                                    Magento 2.x, BigCommerce,
                                                    PrestaShop, OpenCart,
                                                    Shopware)
                                                </p>
                                            </div>
                                        </div>
                                        <div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="Loop Trustmark" title="Loop Trustmark" />
                                            <h6>Loop Trustmark</h6>
                                        </div>
                                        <div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="Access to the Upcycling" title="Access to the Upcycling" />
                                            <h6>Access to the Upcycling</h6>
                                        </div><div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="Access to the Donation" title="Access to the Donation" />
                                            <h6>Access to the Donation</h6>
                                        </div>
                                        <div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="AI-powered" title="AI-powered" />
                                            <h6>AI-powered</h6>
                                        </div>
                                        <button
                                            className="plan-row-bottom__btn btn btn-block waves-effect waves-light mb-4 mt-0"
                                            type="submit"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setShowBasic(!showBasic)
                                            }}
                                        >
                                            {`Show ${showBasic ? "Less" : "More"}`}
                                        </button>
                                        <div style={showBasic ? { display: "block" } : { display: "none" }}>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Customer Support" title="Customer Support" />
                                                <h6>Customer Support</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Widget API integration" title="Widget API integration" />
                                                <h6>Widget API integration</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Widget Customization" title="Widget Customization" />
                                                <h6>Widget Customization</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Advertisement Management System" title="Advertisement Management System" />
                                                <h6>Advertisement Management System</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Coupon codes manager" title="Coupon codes manager" />
                                                <h6>Coupon codes manager</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Analytics Dashboard" title="Analytics Dashboard" />
                                                <h6>Analytics Dashboard</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Reports" title="Reports" />
                                                <h6>Reports</h6>
                                            </div>
                                            <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                                <img src="/assets/images/option-inactive.png" alt="Import products feeds" title="Import products feeds" />
                                                <h6>Import products feeds</h6>
                                            </div>
                                            <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                                <img src="/assets/images/option-inactive.png" alt="Resell for business" title="Resell for business" />
                                                <h6>Resell for business</h6>
                                            </div>
                                            <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                                <img src="/assets/images/option-inactive.png" alt="Enterprise extra add ones" title="Enterprise extra add ones" />
                                                <h6>Enterprise extra add ones</h6>
                                            </div>
                                            <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                                <img src="/assets/images/option-inactive.png" alt="Custom integration" title="Custom integration" />
                                                <h6>Custom integration</h6>
                                            </div>
                                            <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                                <img src="/assets/images/option-inactive.png" alt="Returns module" title="Returns module" />
                                                <h6>Returns module</h6>
                                            </div>
                                            <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                                <img src="/assets/images/option-inactive.png" alt="Custom data analytics" title="Custom data analytics" />
                                                <h6>Custom data analytics</h6>
                                            </div>
                                            <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                                <img src="/assets/images/option-inactive.png" alt="API integration" title="API integration" />
                                                <h6>API integration</h6>
                                            </div>
                                            <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                                <img src="/assets/images/option-inactive.png" alt="Dedicated Account manager" title="Dedicated Account manager" />
                                                <h6>Dedicated Account manager</h6>
                                            </div>
                                            <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                                <img src="/assets/images/option-inactive.png" alt="Marketing Automation" title="Marketing Automation" />
                                                <h6>Marketing Automation</h6>
                                            </div>
                                            <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                                <img src="/assets/images/option-inactive.png" alt="Custom newsletter" title="Custom newsletter" />
                                                <h6>Custom newsletter</h6>
                                            </div>
                                            <div className="plan-row-bottom__option plan-row-bottom__option--disabled">
                                                <img src="/assets/images/option-inactive.png" alt="Web push notification" title="Web push notification" />
                                                <h6>Web push notification</h6>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="col-lg-3 col-md-12 col-sm-12 plan-row">
                                    <div className="plan-row-top">
                                        <p className="plan-row-top__from">From</p>
                                        <p className="plan-row-top__price">£2499 <span>/ Month</span></p>
                                        <p className="plan-row-top__title">Partner Enterprise</p>
                                        <p className="plan-row-top__description">Sustainability Credentials<br />Marketing platform<br />Data analytics dashboards</p>
                                        {/* <button
                                        className="plan-row-top__trial btn btn-block waves-effect waves-light"
                                        disabled
                                    >
                                        30 days free trial
                                    </button> */}
                                        <button
                                            className="plan-row-bottom__btn btn btn-block waves-effect waves-light"
                                            type="submit"
                                            onClick={() => handleSetPlan("enterprise")}
                                        >
                                            {displayTrialText ? "Choose 30 days free trial" : "Choose Plan"}
                                        </button>
                                    </div>
                                    <div className="plan-row__trial"><p>Get a 30 day free trial</p></div>
                                    <div className="plan-row-bottom">
                                        <div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="Unlimited domains" title="Unlimited domains" />
                                            <h6>Unlimited domains</h6>
                                        </div>
                                        <div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="Widget API integration" title="Widget API integration" />
                                            <h6>Widget API integration</h6>
                                        </div>
                                        <div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="Mobile shop integration" title="Mobile shop integration" />
                                            <h6>Mobile shop integration</h6>
                                        </div>
                                        <div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="Ecommerce Integration" title="Ecommerce Integration" />
                                            <div>
                                                <h6>Ecommerce Integration</h6>
                                                <p>
                                                    (Shopify, Magento 1.x,
                                                    Magento 2.x, BigCommerce,
                                                    PrestaShop, OpenCart,
                                                    Shopware)
                                                </p>
                                            </div>
                                        </div>
                                        <div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="Loop Trustmark" title="Loop Trustmark" />
                                            <h6>Loop Trustmark</h6>
                                        </div>
                                        <div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="Access to the Upcycling" title="Access to the Upcycling" />
                                            <h6>Access to the Upcycling</h6>
                                        </div><div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="Access to the Donation" title="Access to the Donation" />
                                            <h6>Access to the Donation</h6>
                                        </div>
                                        <div className="plan-row-bottom__option">
                                            <img src="/assets/images/option-active.png" alt="AI-powered" title="AI-powered" />
                                            <h6>AI-powered</h6>
                                        </div>
                                        <button
                                            className="plan-row-bottom__btn btn btn-block waves-effect waves-light mb-4 mt-0"
                                            type="submit"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setShowEnterprice(!showEnterprice)
                                            }}
                                        >
                                            {`Show ${showEnterprice ? "Less" : "More"}`}
                                        </button>
                                        <div style={showEnterprice ? { display: "block" } : { display: "none" }}>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Customer Support" title="Customer Support" />
                                                <h6>Customer Support</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Widget API integration" title="Widget API integration" />
                                                <h6>Widget API integration</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Widget Customization" title="Widget Customization" />
                                                <h6>Widget Customization</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Advertisement Management System" title="Advertisement Management System" />
                                                <h6>Advertisement Management System</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Coupon codes manager" title="Coupon codes manager" />
                                                <h6>Coupon codes manager</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Analytics Dashboard" title="Analytics Dashboard" />
                                                <h6>Analytics Dashboard</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Reports" title="Reports" />
                                                <h6>Reports</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Import products feeds" title="Import products feeds" />
                                                <h6>Import products feeds</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Resell for business" title="Resell for business" />
                                                <h6>Resell for business</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Enterprise extra add ones" title="Enterprise extra add ones" />
                                                <h6>Enterprise extra add ones</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Custom integration" title="Custom integration" />
                                                <h6>Custom integration</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Returns module" title="Returns module" />
                                                <h6>Returns module</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Custom data analytics" title="Custom data analytics" />
                                                <h6>Custom data analytics</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="API integration" title="API integration" />
                                                <h6>API integration</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Dedicated Account manager" title="Dedicated Account manager" />
                                                <h6>Dedicated Account manager</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Marketing Automation" title="Marketing Automation" />
                                                <h6>Marketing Automation</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Custom newsletter" title="Custom newsletter" />
                                                <h6>Custom newsletter</h6>
                                            </div>
                                            <div className="plan-row-bottom__option">
                                                <img src="/assets/images/option-active.png" alt="Web push notification" title="Web push notification" />
                                                <h6>Web push notification</h6>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            : null}
        </>
    )
}

export default SubscriptionsPlans;