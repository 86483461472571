// @ts-ignore
import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
// @ts-ignore
import MetaTags from 'react-meta-tags';
import 'react-phone-input-2/lib/high-res.css';
// @ts-ignore
import { connect } from "react-redux";
// @ts-ignore
import { RouteComponentProps } from "react-router";
// @ts-ignore
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Row
} from "reactstrap";
import { apiError, registerUserSuccessful } from "../../store/actions";
import Breadcrumb from "./../../components/Common/Breadcrumb";
import { post } from "./../../helpers/api_helper";
import * as url from "./../../helpers/url_helper";
import { openAlert } from "./../../store/alert/actions";
import { editProfile } from "./../../store/auth/profile/actions";
import RegisterContactForm from "./Register/RegisterContact/RegisterContactForm";
import RegisterDetailsForm, { RegisterSubmitData } from "./Register/RegisterDetails/RegisterDetailsForm";
import RegisterSocialsForm from "./Register/RegisterSocials/RegisterSocialsForm";
import moment from "moment-timezone";
import FormInput from "../../components/Common/FormInput";

export interface UserProfilePropsInterface extends RouteComponentProps {
  dashbord_id: string,
  apiError: (err: string) => {},
  history: any,
  location: any,
  error: string,
  onGetCountries: any,
  onProfileUpdate: any,
  user: any,
  onOpenAlert: any,
  countries: any[],
}

const UserProfile = ({
  onGetCountries,
  countries,
  onProfileUpdate,
  user,
  onOpenAlert,
  history,
  location,
  error
}: UserProfilePropsInterface) => {
  const [formErrors, setFormErrors] = useState<any>({})

  const handleValidProfileSubmit = (data: RegisterSubmitData | any) => {
    setFormErrors({});
    let userData = {
      id: user?.id,
      ...data
    }

    onProfileUpdate(userData, setFormErrors)
  }

  const handlePasswordChange = async (event: any) => {
    event.preventDefault()
    try {
      const formData = new FormData(event?.target);
      const values: any = {};
      Array.from(formData.entries())?.map(([key, value]) => {
        values[key] = value
      });
      const {
        previous_password,
        password,
        password_confirmation
      } = values;

      const response = await post(`${url.API_BASE}users/change_password`, {
        previous_password,
        password,
        password_confirmation
      })
      setFormErrors({})
      onOpenAlert({
        text: "Password changed successfuly.",
        status: "success",
      })
    } catch (error) {
      //@ts-ignore
      if (error?.response?.data) {
        //@ts-ignore
        const { message, errors } = error?.response?.data
        setFormErrors(errors)
        onOpenAlert({
          text: message,
          status: "danger",
        })
      }
    }
  }

  return (
    <>
      <MetaTags>
        <title>LOOP | Profile</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="LOOP" breadcrumbItem="Profile" />

          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>

              <Card>
                <CardBody>
                  <div className="text-muted">
                    <h5>{user?.company_details?.company_name}</h5>
                    <p className="mb-1">
                      {user?.company_details?.email}
                    </p>
                    {/* <p className="mb-1">
                      {user?.first_name}{" "}{user?.last_name}
                    </p> */}
                    <p className="mb-1">
                      {user?.company_details?.address}
                      {", "}
                      {user?.company_details?.city?.name}
                      {" "}
                      {user?.company_details?.postcode}
                      {", "}
                      {user?.company_details?.country?.name}
                    </p>
                    <p className="mb-1">{user?.role}</p>
                    {user?.plan?.name ? (
                      <p className="mt-2 mb-0">
                        Plan
                        <div>{user?.plan?.name} {user?.plan?.active ? `(${user?.plan?.active})` : ""}</div>
                        <div>Ends {moment.tz(user?.plan?.ends_at ? user?.plan?.ends_at : user?.plan?.trial_ends_at ? user?.plan?.trial_ends_at : "", 'UTC').tz('Europe/Warsaw').format('DD.MM.YYYY HH:mm')}</div>
                      </p>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Card>
                <CardBody>
                  <h3 className="fw-bold">Company Details</h3>
                  <RegisterDetailsForm
                    handleSubmit={(data) => handleValidProfileSubmit({
                      ...data,
                      contact_phone: user?.contact_details?.phone,

                      address: data?.street
                    })}
                    submitBtnText={"Save"}
                    stepData={{
                      ...user?.company_details,
                      // company_name: string,
                      // charity_number: string,
                      // vatin: string,
                      // phone: string,
                      // email: string,
                      city_name: user?.company_details?.city?.name,
                      country: user?.company_details?.country?.code,
                      school_type_id: user?.company_details?.school_type?.id,
                      school_stage_id: user?.company_details?.school_stage?.id,

                      street: user?.company_details?.address,
                    }}
                    role={user?.role}
                    formErrors={formErrors}
                  />
                </CardBody>
              </Card>


            </Col>

            <Col sm={12} md={6} lg={6} xl={6}>
              <Card>
                <CardBody>
                  <h3 className="fw-bold">Contact Details</h3>
                  <RegisterContactForm
                    handleSubmit={(data) => handleValidProfileSubmit({
                      contact_phone: data?.phone,
                      firstname: data?.firstname,
                      lastname: data?.lastname
                    })}
                    submitBtnText={"Save"}
                    stepData={{
                      // ...user,
                      ...user?.contact_details,
                      firstname: user?.contact_details?.first_name,
                      lastname: user?.contact_details?.last_name
                      // company_name: string,
                      // charity_number: string,
                      // vatin: string,
                      // phone: string,
                      // email: string,
                      // city_name: user?.company_details?.city?.name,
                      // country: user?.company_details?.country?.code,
                      // school_type_id: user?.company_details?.school_type?.id,
                      // school_stage_id: user?.company_details?.school_stage?.id,

                    }}
                    // role={user?.role}
                    formErrors={{
                      ...formErrors,
                      phone: formErrors?.contact_phone
                    }}
                  />
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <h3 className="fw-bold">Social Media</h3>
                  <RegisterSocialsForm
                    handleSubmit={(data) => handleValidProfileSubmit({ websites: data })}
                    submitBtnText={"Save"}
                    stepData={{
                      ...user?.websites
                    }}
                  // role={user?.role}
                  // formErrors={formErrors}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  const { error } = state.Login
  const { dashbord_id, user } = state.Account
  const { countries } = state.Countries
  return { error, dashbord_id, user, countries }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetCountries: () => dispatch({ type: "GET_COUNTRIES" }),
    onApiError: () => apiError(),
    onOpenAlert: ({ text, status }: any) =>
      dispatch(openAlert({ text: text, status: status })),
    onRegisterUserSuccessful: (user: any) => dispatch(registerUserSuccessful(user)),
    onProfileUpdate: (user: any, errorCb: any) =>
      dispatch(
        editProfile({
          user,
          errorCb,
          successCb: () => dispatch(openAlert({ text: "Details updated.", status: "success" }))
        })
      ),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserProfile)
)
