import React from "react"
//@ts-ignore
import { useDispatch } from "react-redux"
//@ts-ignore
import { Link } from "react-router-dom"
import {
    Button
} from "reactstrap"

interface InvoiceTableColumnsInterface {
    returnTranslation: (name: string) => string
}

const InvoiceTableColumns = ({ returnTranslation }: InvoiceTableColumnsInterface) => {
    return (
        [
            {
                dataField: "i",
                isDummyField: true,
                text: "#",
                formatter: (cellContent: any, row: any, rowIndex: any) => <div>{rowIndex + 1}</div>,
            },
            {
                dataField: "description",
                text: returnTranslation("Description"),
                formatter: (cellContent: any, row: any, rowIndex: any) =>
                (
                    <p>{row?.data[0]?.description}</p>
                ),
            },
            {
                dataField: "amount",
                text: returnTranslation("Amount"),
                formatter: (cellContent: any, row: any, rowIndex: any) =>
                (
                    <p>{row?.data[0]?.amount}</p>
                ),
            },
            {
                dataField: "currency",
                text: returnTranslation("Currency"),
                formatter: (cellContent: any, row: any, rowIndex: any) =>
                (
                    <p>{row?.data[0]?.currency}</p>
                ),
            },
            {
                dataField: "configuration",
                isDummyField: true,
                formatter: (cellContent: any, row: any, rowIndex: any) =>
                (
                    <a href={row.pdf} target="_blank">
                        <Button color="success">
                            {returnTranslation("Download PDF")}
                        </Button>
                    </a>
                ),
                text: returnTranslation("PDF"),
            },
        ]
    )
}

export default InvoiceTableColumns
