import React, { Component } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { withRouter, Link } from "react-router-dom"
import user1 from "../../../assets/images/users/avatar-1.jpg"
// @ts-ignore
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

class ProfileMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
      name: "",
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        this.setState({ name: obj.displayName })
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        this.setState({ name: obj.username })
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn-no-shadow btn header-item waves-effect"
            id="page-header-user-dropdown"
            tag="button"
          >
            <div className="avatar-placeholder d-none d-xl-inline-block ms-1">
              <p>{this.props.user?.username?.substring(0, 2)}</p>
            </div>
            {/* <img
              className="rounded-circle header-profile-user"
              src={user1}
              alt="Header Avatar"
            /> */}
            {" "}
            {/* <span className="d-none d-xl-inline-block ms-1">
              {this.props.user?.username}
            </span> */}
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu right>
            <Link to="/profile" className="dropdown-item">
              <i className="bx bx-user font-size-16 align-middle ms-1" />
              {"Profile"}
            </Link>
            <div className="dropdown-divider" />
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>{"Logout"}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { user, registrationError, loading, dashbord_id } = state.Account
  return { user }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ProfileMenu))
