import React, { Component } from "react"
import PropTypes from 'prop-types'

class LiVerticalTimeline extends Component {
    render() {
        const { returnTranslation } = this.props;

        return (
            <React.Fragment>
                <ul className="verti-timeline list-unstyled">
                    <li className="event-list">
                        <div className="event-timeline-dot">
                            <i
                                className={
                                    "bx bx-right-arrow-circle"
                                }
                            />
                        </div>
                        <div className="media">
                            <div className="me-3">
                                <i
                                    className={
                                        "bx " + this.props.status.iconClass + " h2 text-primary"
                                    }
                                />
                            </div>
                            <div className="media-body">
                                <div>
                                    <h5>{this.props.status.stausTitle}</h5>

                                    {this.props?.status?.id === 1 
                                        ? (
                                            <a href={this?.props?.fileUrl} download="Take Back Local Stores.csv">
                                                {returnTranslation("Download")}
                                            </a>
                                        ) : <p className="text-muted">{this.props.status.description}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}

LiVerticalTimeline.propTypes = {
    status: PropTypes.object
}

export default LiVerticalTimeline
