import React, { PureComponent } from 'react';
import { PieChart, Pie, Legend, Sector, Cell, ResponsiveContainer } from 'recharts';

// const data = [
//   { name: 'Group A', value: 400 },
//   { name: 'Group B', value: 300 },
//   { name: 'Group C', value: 300 },
//   { name: 'Group D', value: 200 },
// ];


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.55;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={'middle'} dominantBaseline="central">
      {/* {payload?.name} -  */}
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const LoopPieChart = ({ data, colors }: any) => {
    const COLORS = colors ? colors: ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    return (
        <div className="w-100 loop-line-chart"
            style={{ height: 300 }}
        >
            <ResponsiveContainer width="100%" height="100%">
                <PieChart width={400} height={400}>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {data?.map((entry: any, index: any) => (
                            <Cell key={`cell-${index}`} fill={entry?.color ? entry?.color : COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Legend 
                    // wrapperStyle={{
                    //     top: '50%',
                    //     right: 0,
                    //     transform: 'translate(0, -50%)',
                    //     lineHeight: '24px',
                    // }} 
                    />

                </PieChart>
            </ResponsiveContainer>
        </div>
    );
}

export default LoopPieChart
