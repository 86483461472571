import React, { useEffect, useState } from "react"
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Button, Card,
  CardBody, Container, Spinner
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { get } from "../../../helpers/api_helper"
import { addTakeBackTags } from "../../../helpers/backend_helper"
import { getTakeBacks, openAlert } from "../../../store/actions"
import AddTags, { CharityShopTag } from "./AddTags/AddTags"
import "./AllowedItems.scss"

interface Props {
  history: any,
  returnTranslation: (name: string) => string
}

const AllowedItems = ({ history, returnTranslation }: Props) => {
  const dispatch = useDispatch()
  const [tags, setTags] = useState<CharityShopTag[]>([]);
  const [selectedIdsAllowed, setSelectedIdsAllowed] = useState<number[]>([]);
  const [selectedIdsDisallowed, setSelectedIdsDisallowed] = useState<number[]>([]);

  const takeBack = useSelector((state: any) => state.TakeBacks?.items?.[0])
  const params: {takeback_id: string} = useParams();

  const onOpenAlert = ({ text, status }: any) => dispatch(openAlert({ text: text, status: status }));

  const getTags = async (cb?: any) => {
    try {
      const items = await get("accepts_tags")
      setTags(items)
      cb && cb();
    } catch(e) {
      console.log(e)
    }
  }

  useEffect(() => {
    dispatch(getTakeBacks())
    getTags();
  }, [])

  useEffect(() => {
    if(takeBack){
      setSelectedIdsAllowed(takeBack.accept?.map((tag: any) => tag?.id))
      setSelectedIdsDisallowed(takeBack.not_accept?.map((tag: any) => tag?.id))
    }
  }, [takeBack])

  const handleSave = async () => {
    try{
      const response = await addTakeBackTags(params?.takeback_id, {
        accepts_tags: selectedIdsAllowed,
        not_accepts_tags: selectedIdsDisallowed,
      });
      // setPoints(response)
      onOpenAlert({ text: "Tags updated.", status: "success" })
    }catch(e){
      console.log(e);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content return-address">
        <Container fluid>
          <Breadcrumbs title="Take Back" breadcrumbItem="Accepted Items" />
          <Card>
            <CardBody>
              {tags.length ? (
                <>
                  <AddTags
                    tags={tags}
                    setTags={setTags}
                    returnTranslation={returnTranslation}
                    setAllowedIds={setSelectedIdsAllowed}
                    allowedIds={selectedIdsAllowed}
                    setDisallowedIds={setSelectedIdsDisallowed}
                    disallowedIds={selectedIdsDisallowed}
                    manageTags={false}
                  />

                  <div className="mt-4 d-flex">
                    <Button
                      color="success"
                      type="button"
                      className="btn-no-shadow btn-block waves-effect waves-light"
                      onClick={handleSave}
                      style={{ width: 100 }}
                    >
                      {returnTranslation("Save")}
                    </Button>
                  </div>
                </>
              ) : (
                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                  <Spinner className="ms-2" color="primary" />
                </div>
              )}
              
            </CardBody>
          </Card>

          {/* isOpenPointModal - rerender to reset state for selectedPointId = null
          {isOpenPointModal ? (
            <AddPoint
              isOpen={isOpenPointModal}
              toggle={() => setIsOpenPointModal(!isOpenPointModal)}
              takeBackId={params?.takeback_id}
              pointId={selectedPointId}
              returnTranslation={returnTranslation}
              refreshPoints={handleGetTakeBackPoints}
            />
          ): null}
          
          <Row>
            <Col>
              <div className="d-flex flex-wrap w-100 gap-3">
                {points?.items?.map((point: TakeBackPoint) => (
                  <Card className="w-25 lh-sm card-point">
                    <CardBody className="text-muted d-flex">
                      <div className="w-100">
                        <div className="fw-medium text-dark">{point?.name}</div>
                        <div>{point?.street}</div>
                        <div><span>{point?.city}</span>, <span>{point?.postcode}</span></div>
                        <div>{point?.country?.name}</div>

                        <div className="d-flex gap-2 mt-1">
                          <div onClick={() => handleOpenPointModal(point?.id)} className="fw-medium auth-agreement-link">
                            {returnTranslation("Edit")}
                          </div>
                          <div onClick={() => handleDeletePoint(point?.id, point?.name)} className="fw-medium auth-agreement-link auth-agreement-link--inactive">
                            {returnTranslation("Delete")}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <Input
                          type="radio"
                          className="radio-input clickable"
                          checked={!!point?.status}
                          onClick={() => handleToggleTakeBackPoint(point?.id, !!point?.status)}
                        />
                      </div>
                    </CardBody>
                  </Card>
                ))}
                <Card className="w-25 card-placeholder card-point clickable" onClick={() => handleOpenPointModal()}>
                  <img src={plusGreenBg}  height={25} className="mt-2"/>
                  <h5>{returnTranslation("Add address")}</h5>
                </Card>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AllowedItems
