import React from "react";
import {
    Col, Row
} from "reactstrap";

interface IChangePlanDuration {
    type: string;
    billAnnulally: boolean;
    handleSwitchChange: any;
}
const ChangePlanDuration: React.FC<IChangePlanDuration> = ({
    type,
    billAnnulally,
    handleSwitchChange,
}) => {
    return (
        <Row>
            <Col className="col-12 p-4">
                <h1 className="text-center">Plans for {`${type?.toLowerCase()}`}</h1>

            </Col>
            <Col className="col-12 flex__justify-content--space-between">
                <p className="col-lg-6 col-md-6 col-sm-8 text-center margin-zero-auto font-size-14">
                    {type?.toLowerCase() !== "charity"
                        ? "Familiarize yourself with the payment plans below, see for yourself that the basic service for your business is not as expensive as it might seem"
                        : "Tap into a new source of funding and easily increase your revenue with LOOP Digital Wardrobe."
                    }
                </p>
            </Col>
            {type?.toLowerCase() !== "charity" ? (
                <Col>
                    <div className="plan-switch mb-3 mt-3">
                        <span className={`font-size-14 ${!billAnnulally ? "plan-switch__active" : ""}`}>Bill monthly</span>
                        <div className="form-check form-switch form-switch-md">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                onChange={(e) => handleSwitchChange()}
                                checked={billAnnulally}
                            />
                        </div>
                        <span className={`font-size-14 ${billAnnulally ? "plan-switch__active" : ""}`}>Bill annually</span>
                    </div>
                </Col>
            ) : null}
        </Row>
    )
}

export default ChangePlanDuration;