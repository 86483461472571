// @ts-ignore
import axios from "axios";
import React, { FormEvent, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
// @ts-ignore
import MetaTags from 'react-meta-tags';
// @ts-ignore
import { connect, useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { RouteComponentProps, useHistory, useParams } from "react-router";
// @ts-ignore
import { withRouter } from "react-router-dom";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container, Form, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Row, Spinner
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ContactUsAlert from "../../../components/Common/ContactUsAlert";
import FormInput from "../../../components/Common/FormInput";
import ImageCropper from "../../../components/ImageCropper/ImageCropper";
import { addTakeBack, getTakeBack, updateTakeBack } from "../../../helpers/backend_helper";
import * as url from "../../../helpers/url_helper";
import { apiError, getCodes, getTakeBacks } from "../../../store/actions";
import { openAlert } from "../../../store/alert/actions";

const formErrorSvg = "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/%3e%3c/svg%3e" 

export interface Props extends RouteComponentProps {
	dashbord_id: string,
	apiError: (err: string) => {},
	error: string,
	user: any,
	returnTranslation: any
}

const TakeBackRedeemCoupon = ({ returnTranslation }: Props) => {
	const history = useHistory();
	const params: { takeback_id: string } = useParams();
	const dispatch = useDispatch();
	const dashboardID = useSelector((state: any) => state?.Account?.dashbord_id)
	const userToken = useSelector((state: any) => state?.Account?.user?.access_token)
	const user = useSelector((state: any) => state?.Account?.user)
	const codes = useSelector((state: any) => state?.Codes)
	const onGetCodes = () => dispatch(getCodes(1))
	const onOpenAlert = ({text, status}: any) => dispatch(openAlert({text, status}));

	const [details, setDetails] = useState<any>(null)
	const [description, setDescription] = useState("")
	const [logo, setLogo] = useState<any>({})
	const [name, setName] = useState("")
	const [formErrors, setFormErrors] = useState<any>({})
	const [filesToResize, setFilesToResize] = useState<any>([]);
	const [isFormDisabled, setIsFormDisabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [couponId, setCouponId] = useState("");
	const [externalCouponText, setExternalCouponText] = useState("");
	const [useLoopCoupon, setUseLoopCoupon] = useState(false);
	const [useExtrenalCoupon, setUseExtrenalCoupon] = useState(false);

	// const onCheckAccount = () => dispatch(checkAccount());
	// const details = useSelector((state: any) => state.Account?.info?.configuration?.details)
	// const entitlements = useSelector((state: any) => state.Account?.info?.configuration?.entitlements)

	// const VIRTUAL_CHARITY = "VIRTUAL_CHARITY"
	// const SEND_BY_POST = "SEND_BY_POST"
	// const CHARITY_SHOPS = "CHARITY_SHOPS"

	// useEffect(() => {
	// 	const charityShops = entitlements?.[CHARITY_SHOPS]
	// 	const sendByPost = entitlements?.[SEND_BY_POST]
	// 	const virtualCharity = entitlements?.[VIRTUAL_CHARITY]
	// 	const anyEntitlementWasActive = sendByPost?.status || virtualCharity?.status || charityShops?.status;
	// 	//if any entitlement was activated anytime && details filled -> dont allow to change details
	// 	setIsFormDisabled(!!(anyEntitlementWasActive && details))
	// }, [entitlements, details])

	useEffect(() => {
		if(params?.takeback_id){
			getDetails();
			onGetCodes()
		}
	}, [params?.takeback_id])

	const getDetails = async (preventLoader = false) => {
		try {
			setIsLoading(!preventLoader)
			const response = await getTakeBack(params?.takeback_id);

			// setReason(entitlements[VIRTUAL_CHARITY]?.reason)
			// setStatus(entitlements[VIRTUAL_CHARITY]?.status)

			// setDonationReason(entitlements[SEND_BY_POST]?.reason)
			// setDonationStatus(entitlements[SEND_BY_POST]?.status)

			// setSwitchEnabled(entitlements[VIRTUAL_CHARITY]?.status === "ACTIVE" && verified)
			// setSwitchEnabledDonation(entitlements[SEND_BY_POST]?.status === "ACTIVE" && verified)
			// setLogo(response?.logo)
			// setName(response?.name)
			// setDescription(response?.description)
			if(response?.coupon?.id){
				setUseLoopCoupon(true)
				setUseExtrenalCoupon(false)
				setCouponId(response?.coupon?.id)
			}else if(!response?.coupon?.id){
				setUseLoopCoupon(false)
				setCouponId("")
			}

			if(response?.external_coupon_text){
				setUseExtrenalCoupon(true)
				setUseLoopCoupon(false)
				setExternalCouponText(response?.external_coupon_text)
			}else if(!response?.external_coupon_text){
				setUseExtrenalCoupon(false)
				setExternalCouponText("")
			}
			setDetails(response)
			return response
		} catch (e) {
			// console.log(e);
			onOpenAlert({ text: "Error. Try again later.", status: "danger" });
			return {}
		}finally{
			setIsLoading(false);
		}
	}

	const handleSubmit = async (event: FormEvent) => {
		// console.log(event)
		event?.preventDefault()
		setFormErrors({})
		try {
			const response = await updateTakeBack(params?.takeback_id, {
				coupon_id: useLoopCoupon ? (couponId ? couponId : null) : null,
				external_coupon_text: useExtrenalCoupon ? (externalCouponText ? externalCouponText : null) : null
			})
			onOpenAlert({ status: 'success', text: returnTranslation("Changes saved successfully.") })
			getDetails(true);
			// dispatch(getTakeBacks({ successCb: getDetails }));
			// history?.push("/take-back")
			
				// onCheckAccount();
			// } else {
			// 	onOpenAlert({ status: 'danger', text: returnTranslation("Please fill required fields.") })
			// 	setFormErrors({
			// 		username: !name,
			// 		//if charity status is active, additionaly check other fields, bcs its required when virtual charity is active
			// 		...(charityStatusActive ? {
			// 			description: !description,
			// 			logo: !logo?.id,
			// 			website: !websiteUrl
			// 		}: {})
			// 	})
			// }
		} catch (e) {
			//@ts-ignore
			const { message, errors } = e?.response?.data;
			setFormErrors(errors)
			onOpenAlert({ status: 'danger', text: returnTranslation(message) })
			// console.log(e)
		}
	}

	return (
		<React.Fragment>
			<MetaTags>
				<title>{returnTranslation("LOOP | Take Back - Redeem Coupon")}</title>
			</MetaTags>
			<div className="page-content">
				<Container fluid>
					<Breadcrumb title="Take Back" breadcrumbItem={`Redeem Coupon`} />
					{user?.role === "RETAILER" ? (
						<Row>
							<Col sm={12} md={12} lg={12} xl={7}>
								<Card>
									<CardBody>
										{isLoading ? (
											<div className="w-100 h-100 d-flex justify-content-center align-items-center">
												<Spinner className="ms-2" color="primary" />
											</div>
										): (
											<Form
												className="form-horizontal"
												onSubmit={handleSubmit}
											>
												<div className="d-flex gap-2 align-items-center mb-2">
													<Input
														id="radio1"
														type="radio"
														className="radio-input clickable mt-0"
														checked={useLoopCoupon}
														onClick={() => {
															setUseExtrenalCoupon(false);
															setUseLoopCoupon(true);
														}}
													/>
													<Label htmlFor="radio1" className="card-title mb-0 clickable">LOOP Coupon</Label>
												</div>

												{details?.coupon?.errors?.map((errText: string) =>
													<ContactUsAlert
														//@ts-ignore
														customText={errText}
														display={!!errText}
														returnTranslation={returnTranslation}
														onOpenAlert={onOpenAlert}
													/>
												)}
												
												<div className="form-group"
													onClick={() => {
														setUseExtrenalCoupon(false)
														setUseLoopCoupon(true)
													}}
												>
													<FormInput
														type="select"
														name="coupon_id"
														label="Select Coupon"
														value={couponId}
														className="form-control"
														placeholder={"Select Coupon"}
														onChange={(e: any) => setCouponId(e.target.value)}
														options={codes?.codes
															?.filter((item: any) => item?.active && item?.is_active && !item?.expired)
															?.map((item: any) => (
																<option value={item?.id}>{item?.name} | {item?.sites?.[0]?.name}</option>
															))
														}
														disabled={!codes?.codes?.length}
													/>
												</div>

												<div className="mb-3 d-flex gap-2">
													<Button
														color="success"
														type="submit"
														className="btn-no-shadow btn-block waves-effect waves-light"
														disabled={isFormDisabled || useExtrenalCoupon || !couponId}
													>
														{returnTranslation("Save")}
													</Button>
													<Button
														color="success"
														type="button"
														onClick={() => history?.push("/coupons")}
														className="btn-no-shadow btn-block waves-effect waves-light"
														disabled={isFormDisabled}
													>
														{returnTranslation("Manage Coupons")}
													</Button>
												</div>
												
											</Form>
										)}
										{/* <h4 className="card-title mb-4">{returnTranslation("Charity Details")}</h4> */}
										{/* <ContactUsAlert
											display={isFormDisabled}
											returnTranslation={returnTranslation}
											onOpenAlert={onOpenAlert}
										/>
										<ContactUsAlert
											//@ts-ignore
											customText={getMissingInfoMessageText()}
											display={getMissingInfoMessageText() ? true : false}
											returnTranslation={returnTranslation}
											onOpenAlert={onOpenAlert}
										/> */}
										
									</CardBody>
								</Card>
							</Col>
						</Row>
					): null}

					<Row>
						<Col sm={12} md={12} lg={12} xl={7}>
							<Card>
								<CardBody>
									{isLoading ? (
										<div className="w-100 h-100 d-flex justify-content-center align-items-center">
											<Spinner className="ms-2" color="primary" />
										</div>
									): (
										<Form
											className="form-horizontal"
											onSubmit={handleSubmit}
										>
											<div className="d-flex gap-2 align-items-center mb-2">
												<Input
													id="radio2"
													type="radio"
													className="radio-input clickable mt-0"
													checked={useExtrenalCoupon}
													onClick={() => {
														setUseExtrenalCoupon(true);
														setUseLoopCoupon(false);
													}}
												/>
												<Label htmlFor="radio2" className="card-title mb-0 clickable">Extrenal Coupon</Label>
											</div>
											{/* {details?.coupon?.errors?.map((errText: string) =>
												<ContactUsAlert
													//@ts-ignore
													customText={errText}
													display={!!errText}
													returnTranslation={returnTranslation}
													onOpenAlert={onOpenAlert}
												/>
											)} */}
											
											<div className="form-group">
												<FormInput
													type="textarea"
													rows={5}
													name="external_coupon_text"
													label="Coupon description"
													value={externalCouponText}
													className="form-control"
													placeholder={"About coupon"}
													onChange={(e: any) => setExternalCouponText(e.target.value)}
													disabled={isFormDisabled}
													onFocus={() => {
														setUseExtrenalCoupon(true)
														setUseLoopCoupon(false)
													}}
												/>
											</div>

											<div className="mb-3 d-flex gap-2">
												<Button
													color="success"
													type="submit"
													className="btn-no-shadow btn-block waves-effect waves-light"
													disabled={isFormDisabled || useLoopCoupon}
												>
													{returnTranslation("Save")}
												</Button>
											</div>
											
										</Form>
									)}
									{/* <h4 className="card-title mb-4">{returnTranslation("Charity Details")}</h4> */}
									{/* <ContactUsAlert
										display={isFormDisabled}
										returnTranslation={returnTranslation}
										onOpenAlert={onOpenAlert}
									/>
									<ContactUsAlert
										//@ts-ignore
										customText={getMissingInfoMessageText()}
										display={getMissingInfoMessageText() ? true : false}
										returnTranslation={returnTranslation}
										onOpenAlert={onOpenAlert}
									/> */}
									
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	)
}

export default withRouter((TakeBackRedeemCoupon))
