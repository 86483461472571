import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    let matchingMenuItem = null
    const ul = document.getElementById("navigation")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ isDashboard: !this.state.isDashboard })
                      }}
                      to="dashboard"
                    >
                      <i className="bx bx-home-circle me-2" />
                      {"Dashboard"} {this.props.menuOpen}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isDashboard,
                      })}
                    >
                      <Link to="dashboard" className="dropdown-item">
                        {"Default"}
                      </Link>
                      <Link to="/websites" className="dropdown-item">
                        {"Websites"}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {"Crypto"}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {"Blog"}
                      </Link>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ uiState: !this.state.uiState })
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-tone me-2" />
                      {"UI Elements"}{" "}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname(
                        "dropdown-menu mega-dropdown-menu dropdown-menu-left dropdown-mega-menu-xl",
                        { show: this.state.uiState }
                      )}
                    >
                      <Row>
                        <Col lg={4}>
                          <div>
                            <Link to="#" className="dropdown-item">
                              {"Alerts"}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {"Buttons"}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {"Cards"}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {"Carousel"}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {"Dropdowns"}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {"Grid"}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {"Images"}
                            </Link>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div>
                            <Link to="#" className="dropdown-item">
                              {"Lightbox"}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {"Modals"}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {"Range Slider"}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {"Session Timeout"}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {"Progress Bars"}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {"Sweet-Alert"}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {"Tabs & Accordions"}
                            </Link>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div>
                            <Link to="#" className="dropdown-item">
                              {"Typography"}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {"Video"}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {"General"}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {"Colors"}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {"Rating"}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {"Notifications"}
                            </Link>
                            <Link to="#" className="dropdown-item">
                              {"Image Cropper"}
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ appState: !this.state.appState })
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-customize me-2" />
                      {"Apps"} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.appState,
                      })}
                    >
                      <Link to="#" className="dropdown-item">
                        {"Calendar"}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {"Chat"}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {"File Manager"}
                      </Link>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({
                              emailState: !this.state.emailState,
                            })
                          }}
                        >
                          {"Email"} <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.emailState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {"Inbox"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Read Email"}
                          </Link>
                          <div className="dropdown">
                            <Link
                              className="dropdown-item dropdown-toggle arrow-none"
                              to="/#"
                              onClick={e => {
                                e.preventDefault()
                                this.setState({
                                  emailState: !this.state.emailState,
                                })
                              }}
                            >
                              <span key="#">Templates</span>{" "}
                              <div className="arrow-down"></div>
                            </Link>
                            <div
                              className={classname("dropdown-menu", {
                                show: this.state.emailState,
                              })}
                            >
                              <Link to="#" className="dropdown-item">
                                {"Basic Action"}
                              </Link>
                              <Link to="#" className="dropdown-item">
                                {"Alert Email"}
                              </Link>
                              <Link to="#" className="dropdown-item">
                                {"Billing Email"}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({
                              ecommerceState: !this.state.ecommerceState,
                            })
                          }}
                        >
                          {" Ecommerce"}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.ecommerceState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {"Products"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Product Detail"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Orders"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Customers"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Cart"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Checkout"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Shops"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Add Product"}
                          </Link>
                        </div>
                      </div>

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({ isCrypto: !this.state.isCrypto })
                          }}
                        >
                          {"Crypto"}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.isCrypto,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {"Wallet"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Buy/Sell"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Exchange"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Lending"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Orders"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"KYC Application"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"ICO Landing"}
                          </Link>
                        </div>
                      </div>

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({
                              projectState: !this.state.projectState,
                            })
                          }}
                        >
                          {"Projects"}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.projectState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {"Projects Grid"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Projects List"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Project Overview"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Create New"}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({ taskState: !this.state.taskState })
                          }}
                        >
                          {"Tasks"} <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.taskState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {"Task List"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Kanban Board"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Create Task"}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({
                              contactState: !this.state.contactState,
                            })
                          }}
                        >
                          {"Contacts"}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.contactState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {"User Grid"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"User List"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Profile"}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({
                              blogState: !this.state.blogState,
                            })
                          }}
                        >
                          {"Blog"} <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.blogState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {"Blog List"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Blog Grid"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Blog Details"}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({
                          componentState: !this.state.componentState,
                        })
                      }}
                    >
                      <i className="bx bx-collection me-2" />
                      {"Components"}{" "}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.componentState,
                      })}
                    >
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({ formState: !this.state.formState })
                          }}
                        >
                          {"Forms"} <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.formState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {"Form Elements"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Form Layouts"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Form Validation"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Form Advanced"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Form Editors"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Form File Upload"}{" "}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Form Xeditable"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Form Repeater"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Form Wizard"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Form Mask"}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({
                              tableState: !this.state.tableState,
                            })
                          }}
                        >
                          {"Tables"}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.tableState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {"Basic Tables"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Data Tables"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Responsive Table"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Editable Table"}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({
                              chartState: !this.state.chartState,
                            })
                          }}
                        >
                          {"Charts"}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.chartState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {" "}
                            {"Apex charts"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {" "}
                            {"Chartjs Chart"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {" "}
                            {"E Chart"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Toast UI Chart"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {" "}
                            {"Sparkline Chart"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Knob Chart"}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({ iconState: !this.state.iconState })
                          }}
                        >
                          {"Icons"} <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.iconState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {"Boxicons"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Material Design"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Dripicons"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Font awesome"}{" "}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({ mapState: !this.state.mapState })
                          }}
                        >
                          {"Maps"} <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.mapState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {"Google Maps"}{" "}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Vector Maps"}{" "}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Leaflet Maps"}{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="#"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ extraState: !this.state.extraState })
                      }}
                    >
                      <i className="bx bx-file me-2" />
                      {"Extra pages"}{" "}
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.extraState,
                      })}
                    >
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({
                              invoiceState: !this.state.invoiceState,
                            })
                          }}
                        >
                          {"Invoices"}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.invoiceState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {"Invoice List"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Invoice Detail"}
                          </Link>
                        </div>
                      </div>

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({ authState: !this.state.authState })
                          }}
                        >
                          {"Authentication"}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.authState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {"Login"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Login 2"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Register"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Register 2"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Recover Password"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Recover Password 2"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Lock Screen"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Lock Screen 2"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Confirm Mail"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Confirm Mail 2"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Email verification"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Email verification 2"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Two step verification"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Two step verification 2"}
                          </Link>
                        </div>
                      </div>

                      <div className="dropdown">
                        <Link
                          className="dropdown-item dropdown-toggle arrow-none"
                          to="#"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({
                              utilityState: !this.state.utilityState,
                            })
                          }}
                        >
                          {"Utility"}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.utilityState,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {"Starter Page"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Maintenance"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Coming Soon"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Timeline"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"FAQs"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Pricing"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Error 404"}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {"Error 500"}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
}

export default withRouter(Navbar)
