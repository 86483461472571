import axios from "axios"
import React, { Component } from "react"
import Dropzone from "react-dropzone"
// @ts-ignore
import MetaTags from 'react-meta-tags'
//@ts-ignore
import { connect } from "react-redux"
// @ts-ignore
import { RouteComponentProps } from "react-router"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row
} from "reactstrap"
import plusGreenBg from "../../../../assets/images/plus-green-bg.svg"
import Breadcrumb from "../../../../components/Common/Breadcrumb"
import LiVerticalTimeline from "../../../../components/LiVerticalTimeline/LiVerticalTimeline"
import * as url from "../../../../helpers/url_helper"
import { apiError, openAlert } from "../../../../store/actions"

export interface ImportCharityShopsPropsInterface extends RouteComponentProps {
  history: any,
  user: any,
  dashbord_id: string,
  userToken: string,
  onOpenAlert: any,
  returnTranslation: (name: string) => string
}

export interface ImportCharityShopsStateInterface {
  selectedFiles: {
    name: string,
    lastModified: number,
    lastModifiedDate: any,
    path: string,
    size: number,
    type: string,
    webkitRelativePath: string,
  },
  steps: any[]
}

class ImportCharityShops extends Component<ImportCharityShopsPropsInterface, ImportCharityShopsStateInterface> {
  constructor(props: ImportCharityShopsPropsInterface) {
    super(props)
    this.state = {
      selectedFiles: {
        name: "",
        lastModified: 0,
        lastModifiedDate: "",
        path: "",
        size: 0,
        type: "",
        webkitRelativePath: "",
      },
      steps: [
        {
          id: 1,
          stausTitle: this.props.returnTranslation("Download template"),
          iconClass: "bx-package",
          description: this.props.returnTranslation(`Download`),
        },
        {
          id: 2,
          stausTitle: this.props.returnTranslation("Fill in the form"),
          iconClass: "bx-copy-alt",
          description: this.props.returnTranslation("Add/edit information in the form"),
        },
        {
          id: 3,
          stausTitle: this.props.returnTranslation("Upload completed form"),
          iconClass: "bx-badge-check",
          description: this.props.returnTranslation("Use the below field to upload the form"),
        },
      ]
    }
  }

  handleAcceptedFiles = (files: any) => {
    this.setState({ selectedFiles: files[0] })
  }

  formatBytes = (bytes: any, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  handleSubmit = () => {
    return new Promise(async (resolve, reject) => {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "Client-UUID": this.props?.dashbord_id,
          Authorization: `Bearer ${this.props.userToken}`,
          "Custom-User-Agent": window.navigator.userAgent
        },
      }

      const { selectedFiles } = this.state;
      const formData = new FormData()
      // console.log(['selected', selectedFiles])

      // @ts-ignore
      formData.append("points", selectedFiles)

      let apiUrl = "";
      if(this?.props?.user?.role === "CHARITY"){
        apiUrl = `${url.API_BASE}charities/points/import`
      }else if(this?.props?.user?.role === "SCHOOL"){
        apiUrl = `${url.API_BASE}schools/points/import`
      }

      axios
        .post(apiUrl, formData, config)
        .then(response => {
          this.props.onOpenAlert({ text: this.props.returnTranslation("Success"), status: "success" })
          this.props.history.push(`/charity-shops-list`)

          return resolve(response)
        })
        .catch(error => {
          if (error?.response?.data) {
            const { message, errors } = error?.response?.data
            this.props.onOpenAlert({
              text: message,
              status: "danger",
            })
          }
          return reject(error)
        })
    })
  }

  onRemoveFile = () => {
    this.setState({
      selectedFiles: {
        name: "",
        lastModified: 0,
        lastModifiedDate: "",
        path: "",
        size: 0,
        type: "",
        webkitRelativePath: "",
      }
    })
  }

  render() {
    const { returnTranslation, user } = this.props;

    return (
      <React.Fragment>
        <MetaTags>
          <title>
            {user?.role === 'CHARITY' ? returnTranslation("LOOP | Donations Setup - Charity Shops") : null}
            {user?.role === 'SCHOOL' ? returnTranslation("LOOP | LOOP FOR GOOD - School Points") : null}
          </title>
        </MetaTags>
        <div className="page-content">
          <Container fluid>
            {user?.role === 'CHARITY' ? <Breadcrumb title="Donations Setup" breadcrumbItem="Import Charity Shops" /> : null}
					  {user?.role === 'SCHOOL' ? <Breadcrumb title="LOOP FOR GOOD" breadcrumbItem="Import School Points" /> : null}

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {this.state.steps.map((status, key) => (
                      <LiVerticalTimeline
                        status={status}
                        key={"_status_" + key}
                        returnTranslation={returnTranslation}
                        fileUrl={user?.role === 'CHARITY'
                          ? "https://dashboard-dev.loopdigitalwardrobe.com/assets/charities.csv"
                          : user?.role === 'SCHOOL'
                            ? "https://dashboard-dev.loopdigitalwardrobe.com/assets/school_points.csv"
                            : null
                        }
                        // fileUrl="https://dashboard-dev.loopdigitalwardrobe.com/assets/charities.csv"
                      />
                    ))}

                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-2">
                        {returnTranslation("Attached Files")}
                      </Label>
                      <Col lg="10">
                        <Form>
                          <Dropzone
                            onDrop={acceptedFiles =>
                              this.handleAcceptedFiles(acceptedFiles)
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    {/* <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div> */}
                                    <img src={plusGreenBg} />
                                    <h4>{returnTranslation("Drop files here or click to upload.")}</h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {this.state?.selectedFiles?.name &&
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={`${this.state?.selectedFiles?.name}`}
                              >
                                <div className="p-2">
                                  <div className="align-items-center d-flex">
                                    <a className="text-danger" href="#"><i className="mdi mdi-delete m-2" style={{ fontSize: 20 }} onClick={this.onRemoveFile} /></a>
                                    <span>{this.state?.selectedFiles?.name}</span>
                                  </div>
                                </div>
                              </Card>
                            }
                          </div>
                        </Form>
                      </Col>
                    </FormGroup>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        <Button onClick={this.handleSubmit} color="primary">
                          {returnTranslation("Add")}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: any) => {
  const { error } = state.Login
  const { dashbord_id, user: { access_token }, user} = state.Account
  return { error, dashbord_id, userToken: access_token, user }
}

const mapDispatchToProps = (dispatch: any) => ({
  // @ts-ignore
  onGetCodes: (page: number) => dispatch(getCodes(page)),
  onOpenAlert: (msg: string, status: string) =>
    dispatch(openAlert({ text: msg, status: status })),
  onApiError: (error: string) => dispatch(apiError({ error })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(ImportCharityShops)
