import CodePackDetails from './../pages/Codes/CodesStatistics/CodePackDetails/CodePackDetails';
import CodesStatistics from './../pages/Codes/CodesStatistics/CodesStatistics';
import WebsiteForm from './../pages/Websites/WebsiteForm';

import DevelopersWebsiteForm from './../pages/Websites/DevelopersWebsiteForm';
import EcommerceWebsiteForm from './../pages/Websites/EcommerceWebsiteForm';

import React from 'react';
import {Redirect} from 'react-router-dom';
import ForgetPwd from './../pages/Authentication/ForgetPassword';
// Authentication related pages
import Login from './../pages/Authentication/Login';
import Logout from './../pages/Authentication/Logout';

import RegisterRole from './../pages/Authentication/Register/RegisterRole';
import RegisterRegion from './../pages/Authentication/Register/RegisterRegion';
import RegisterEmail from './../pages/Authentication/Register/RegisterEmail';
import RegisterCode from './../pages/Authentication/Register/RegisterCode';
import RegisterPassword from './../pages/Authentication/Register/RegisterPassword';
import RegisterDetails from '../pages/Authentication/Register/RegisterDetails/RegisterDetails';
import RegisterPlans from './../pages/Authentication/Register/RegisterPlans';
import RegisterCard from './../pages/Authentication/Register/RegisterCard';

// User profile
import UserProfile from './../pages/Authentication/UserProfile';
import UserChangePassword from './../pages/Authentication/UserChangePassword';
import CharityLogo from './../pages/CharityLogo/index';
//charity
import CharityPage from './../pages/CharityPage/index';
import CharityReturns from './../pages/CharityReturns/index';
import CharityReturnWarehouse from './../pages/CharityReturnWarehouse/index';
//codes
import DashboardCodes from './../pages/Codes/DashboardCodes/index';
import ImportCodes from './../pages/Codes/ImportCodes/index';
import AddCoupon from '../pages/Codes/AddCode/index';
//dashboard
import Dashboard from './../pages/Dashboard/index';
// import Websites from "./../pages/Websites/Websites"
import DownloadPlugin from './../pages/Websites/Integrations/DownloadPlugin';
import Docs from './../pages/Integrations/Docs/index';
// import EmailTemplate from "./../pages/Websites/EmailTemplate/EmailTemplate"
import Configuration from './../pages/Websites/Configuration/Configuration';
import ShopifyManual from './../pages/Websites/ShopifyManual/ShopifyManual';
import Preview from './../pages/Websites/Preview/Preview';
//charity shops
import DashboardCharityShops from '../pages/EntitlementsConfiguration/CharityShops/DashboardCharityShops/index';
import AddCharityShop from '../pages/EntitlementsConfiguration/CharityShops/AddCharityShop/index';
import ImportCharityShops from '../pages/EntitlementsConfiguration/CharityShops/ImportCharityShops/index';

//billings
import Billings from './../pages/Billings/Billings';
import ChangePlan from './../pages/Billings/ChangePlan/ChangePlan';
// import ChangePaymentInfo from "./../pages/Billings/ChangePaymentInfo/ChangePaymentInfo"
import ChangeVat from './../pages/Billings/ChangeVat/ChangeVat';
import ChangeBillingOwner from './../pages/Billings/ChangeBillingOwner/ChangeBillingOwner';
import Invoices from './../pages/Billings/Invoices/Invoices';
import Details from '../pages/EntitlementsConfiguration/Details/Details';
// import PayoutsWithStripe from "../pages/CharityConfiguration/PayoutsWithStripe/PayoutsWithStripe"
import PayoutsWithPaypal from '../pages/EntitlementsConfiguration/PayoutsWithPaypal/PayoutsWithPaypal';
import EnableVirtualShop from '../pages/EntitlementsConfiguration/EnableVirtualShop/EnableVirtualShop';

//retailer feeds
import DashboardFeeds from './../pages/Feeds/DashboardFeeds/index';
import AddFeed from './../pages/Feeds/AddFeed/index';
import Analytics from '../pages/Feeds/Analytics/Analytics';

// import PayoutsWithPaypalRetailer from "./../pages/Billings/PayoutsWithPaypal/PayoutsWithPaypal"
import EnableShipByPost from '../pages/EntitlementsConfiguration/EnableShipByPost/EnableShipByPost';
import EnableCharityShops from '../pages/EntitlementsConfiguration/CharityShops/EnableCharityShops/EnableCharityShops';
import EnableMicroDonations from '../pages/EntitlementsConfiguration/EnableMicroDonations/EnableMicroDonations';
import PaymentMethod from '../pages/Billings/PaymentMethod/PaymentMethod';
// import TakeBacks from "../pages/TakeBack/TakeBacksList/TakeBacks"
import TakeBackForm from '../pages/TakeBack/TakeBackForm/TakeBackForm';
import Carriers from '../pages/TakeBack/Carriers/Carriers';
import CarrierForm from '../pages/TakeBack/Carriers/CarrierForm/CarrierForm';
import TakebackOverview from '../pages/TakeBack/TakebackOverview/TakebackOverview';
import ReturnAddress from '../pages/TakeBack/ReturnAddress/ReturnAddress';
import AllowedItems from '../pages/TakeBack/AllowedItems/AllowedItems';
import DropoffPoints from '../pages/TakeBack/DropoffPoints/DropoffPoints';
import DropoffPointForm from '../pages/TakeBack/DropoffPoints/DropoffPointForm/DropoffPointForm';
import ImportDropoffPoints from '../pages/TakeBack/DropoffPoints/ImportDropoffPoints/ImportDropoffPoints';
import RegisterContact from '../pages/Authentication/Register/RegisterContact/RegisterContact';
import RegisterSocials from '../pages/Authentication/Register/RegisterSocials/RegisterSocials';
import EnableSwap from '../pages/EntitlementsConfiguration/EnableSwap/EnableSwap';
import TakeBackRedeemCoupon from '../pages/TakeBack/TakeBackRedeemCoupon/TakeBackRedeemCoupon';
import Integrations from '../pages/Websites/Integrations/Integrations';
import AnalyticsDashboard from '../pages/Analytics/Dashboard';

const authProtectedRoutes = [
  {path: '/dashboard', component: Dashboard},

  {path: '/analytics/post-purchase', component: AnalyticsDashboard},

  //website
  // { path: "/websites", component: Websites },
  // { path: "/website/add", component: WebsiteForm },
  {path: '/plugin/integrations/edit/:uuid', component: WebsiteForm},

  // { path: "/website/ecommerce", component: EcommerceWebsiteForm },
  // { path: "/website/developers", component: DevelopersWebsiteForm },

  {path: '/plugin/integrations', component: Integrations},

  {path: '/plugin/integrations/javascript', component: DevelopersWebsiteForm},

  {path: '/plugin/integrations/download', component: DownloadPlugin},
  {path: '/plugin/integrations/documentation', component: Docs},

  {path: '/plugin/integrations/:platform', component: EcommerceWebsiteForm},
  {path: '/plugin/integrations/configure/:uuid', component: Configuration},
  {path: '/plugin/manual/shopify/:uuid', component: ShopifyManual},
  {path: '/plugin/integrations/preview/:uuid', component: Preview},

  // { path: "/website/email/customize", component: EmailTemplate },

  //profile
  {path: '/profile', component: UserProfile},
  {path: '/change-password', component: UserChangePassword},

  //codes
  {path: '/coupons', component: DashboardCodes},
  // { path: "/codes/statistics/:uuid", component: CodesStatistics },
  // { path: "/codes/details/:uuid", component: CodePackDetails },
  // { path: "/import-codes", component: ImportCodes },
  {path: '/add-coupon', component: AddCoupon},
  {path: '/coupon/edit/:uuid', component: AddCoupon},

  //charity
  {path: '/charity-page', component: CharityPage},
  {path: '/charity-logo', component: CharityLogo},
  {path: '/charity-returns', component: CharityReturns},
  {path: '/donation', component: CharityReturnWarehouse},

  // Donations Setup (Charity) / LOOP FOR GOOD (School)
  {path: '/details', component: Details},
  {path: '/virtual-shop', component: EnableVirtualShop},
  {path: '/ship-by-post', component: EnableShipByPost},
  {path: '/swap', component: EnableSwap},
  // { path: "/charity-shop-enable", component: EnableCharityShop },
  // { path: "/virtual-charity-stripe", component: PayoutsWithStripe },
  {path: '/payouts-with-paypal', component: PayoutsWithPaypal},
  {path: '/micro-donations', component: EnableMicroDonations},

  {path: '/charity-shops', component: EnableCharityShops},
  {path: '/charity-shops-list', component: DashboardCharityShops},
  {path: '/add-charity-shop', component: AddCharityShop},
  {path: '/import-charity-shop', component: ImportCharityShops},
  {path: '/charity-shop/edit/:uuid', component: AddCharityShop},

  //billings
  {path: '/billings', component: Billings},
  {path: '/change-plan', component: ChangePlan},
  // { path: "/change-payment-info", component: ChangePaymentInfo },
  {path: '/change-vat-id', component: ChangeVat},
  {path: '/change-billing-owner', component: ChangeBillingOwner},
  {path: '/invoices', component: Invoices},
  // { path: "/setup-paypal", component: PayoutsWithPaypalRetailer },
  {path: '/payment-method', component: PaymentMethod},

  //retailer feeds
  {path: '/feed', component: DashboardFeeds},
  {path: '/add-feed', component: AddFeed},
  {path: '/feed/edit/:uuid', component: AddFeed},
  {path: '/feed/analytics', component: Analytics},

  {path: '/take-back', component: TakebackOverview},
  {path: '/take-back/details/add', component: TakeBackForm},
  {path: '/take-back/:takeback_id/details', component: TakeBackForm},
  {path: '/take-back/:takeback_id/return-address', component: ReturnAddress},
  {path: '/take-back/:takeback_id/carriers', component: Carriers},
  {
    path: '/take-back/:takeback_id/carriers/:carrier_id',
    component: CarrierForm,
  },
  {path: '/take-back/:takeback_id/accepted-items', component: AllowedItems},
  {path: '/take-back/:takeback_id/local-stores', component: DropoffPoints},
  {
    path: '/take-back/:takeback_id/local-stores/add',
    component: DropoffPointForm,
  },
  {
    path: '/take-back/:takeback_id/local-stores/import',
    component: ImportDropoffPoints,
  },
  {
    path: '/take-back/:takeback_id/local-stores/:point_id',
    component: DropoffPointForm,
  },

  {
    path: '/take-back/:takeback_id/redeem-coupon',
    component: TakeBackRedeemCoupon,
  },

  // { path: "/drop-offs-near-you", component: TakeBacks },
  // { path: "/drop-offs-near-you/add", component: TakeBackForm },
  // { path: "/drop-offs-near-you/:id", component: TakeBackForm },

  {path: '/drop-offs-near-you/:id/carriers', component: Carriers},
  {
    path: '/drop-offs-near-you/:takeback_id/carriers/:carrier_id',
    component: CarrierForm,
  },

  // this route should be at the end of all other routes
  {path: '/*', exact: true, component: () => <Redirect to="/dashboard" />},
];

const publicRoutes = [
  {path: '/logout', component: Logout},
  {path: '/login', component: Login},
  {path: '/forgot-password', component: ForgetPwd},
  {path: '/register-email', component: RegisterEmail},
  {path: '/register-code', component: RegisterCode},
  {path: '/register-password', component: RegisterPassword},
  {path: '/register-region', component: RegisterRegion},
  {path: '/register-role', component: RegisterRole},
  {path: '/register-contact', component: RegisterContact},
  {path: '/register-details', component: RegisterDetails},
  {path: '/register-socials', component: RegisterSocials},
  {path: '/register-card', component: RegisterCard},
  {path: '/register-plans', component: RegisterPlans},
];

export {authProtectedRoutes, publicRoutes};
