import React, { useState } from "react"
// @ts-ignore
import { useDispatch } from "react-redux"
import {
    Col,
    Container,
    Row
} from "reactstrap"
import BasicTable from "../../../components/BasicTable/BasicTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { openAlert } from "../../../store/alert/actions"
import { del, get } from "./../../../helpers/api_helper"
import * as url from "./../../../helpers/url_helper"
import { changePreloader, requestConfirmation } from "./../../../store/actions"
import CodesTableColumns from "./CodesTableColumns/CodesTableColumns"


interface DashboardFeedsInterface {
    history: any,
    returnTranslation: (name: string) => string
}

const DashboardFeeds = ({ history, returnTranslation }: DashboardFeedsInterface) => {
    const dispatch = useDispatch()
    // const data = useSelector((state: any) => state.CharityShops)

    const [feedData, setFeedData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const handleDelete = (uuid: string, name: string) => {
        dispatch(
            requestConfirmation(`${returnTranslation("Delete")} ${name}?`, () => confirmDelete(uuid))
        )
    }

    const confirmDelete = async (id: string) => {
        try {
            return new Promise(async (resolve, reject) => {
                dispatch(changePreloader(true))

                del(`${url.API_BASE}retailers/feeds/${id}`.replace('undefined', ''))
                    .then(response => {
                        if (response?.status) {
                            dispatch(openAlert({ text: response?.status, status: "success" }))
                            getFeeds()
                        }

                        dispatch(changePreloader(false))
                        return resolve(response)
                    })
                    .catch(error => {
                        if (error?.response?.data) {
                            const { message, errors } = error?.response?.data;
                            dispatch(openAlert({ text: message, status: "danger" }))
                        }
                        dispatch(changePreloader(false))
                        return reject(error)
                    })
            })
        } catch (e) {
            dispatch(openAlert({ text: returnTranslation("An error occured"), status: "danger" }))
        }
    }

    const handleEdit = (id: string) => {
        history.push(`/feed/edit/${id}`)
    }

    const getFeeds = () => {
        return new Promise(async (resolve, reject) => {
            // dispatch(changePreloader(true))
            setIsLoading(true)
            get(`${url.API_BASE}retailers/feeds`)
                .then((response: any) => {
                    let responseData = response.map((obj: any) => ({ ...obj, country: 'UK' }))
                    setFeedData(responseData)

                    dispatch(changePreloader(false))
                    return resolve(responseData)
                })
                .catch((error: any) => {
                    if (error?.response?.data) {
                        const { message, errors } = error?.response?.data;
                        // this.setState({ formErrors: errors });
                        dispatch(openAlert({ text: message, status: "danger" }))
                    }
                    // dispatch(changePreloader(false))
                    return reject(error)
                })
                .finally(() => setIsLoading(false))
        })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={returnTranslation("Ads Manager")} breadcrumbItem={returnTranslation("My feeds")} />
                    <p>Ads Manager uses feeds to display your products on Loop. Use the feed to upload your product data to Ads Manager using XML (.xml) files.</p>

                    <Row>
                        <Col lg="12">
                            <BasicTable
                                items={feedData}
                                // @ts-ignore
                                columns={CodesTableColumns({ returnTranslation, handleDelete, handleEdit })}
                                //@ts-ignore
                                addItemBtnText={returnTranslation("Add Feed")}
                                addItemBtnAction={() => history.push("/add-feed")}
                                searchbar={false}
                                // getAction={getCharityShops}
                                getFunction={getFeeds}
                                totalSize={feedData?.length}
                                sizePerPage={999}
                                currentPage={1}
                                isLoading={isLoading}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default DashboardFeeds
