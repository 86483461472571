import { takeEvery, fork, put, call, all } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"

//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  updateProfile
} from "../../../helpers/backend_helper"
import { registerUserSuccessful } from "../register/actions"

// const fireBaseBackend = getFirebaseBackend()

function* editProfileAsync({ payload, errorCb, successCb }) {
  try {
    const { user } = payload
    const response = yield call(updateProfile, { ...user, _method: "PUT" })
    // console.log(["response", response])
    // updateUserLocalStorage(user)
    yield put(profileSuccess({ user: response?.user }))
    yield put(registerUserSuccessful(response?.user))
    
    if(successCb){
      yield put(successCb())
    }

  } catch (error) {
    // console.log(["error editProfileAsync", error?.response?.data]);
    if(errorCb){
      errorCb(error?.response?.data?.errors)
    }
    yield put(profileError(error))
  }
}

// const updateUserLocalStorage = (user) => {
//   let currentUserObj = JSON.parse(localStorage.getItem("authUser"))
//   const newUserObj = { ...currentUserObj, ...user };
//   console.log(["newUserObj", newUserObj])
//   localStorage.setItem("authUser", JSON.stringify(newUserObj))
// }

function* ProfileSaga() {
  yield takeEvery(EDIT_PROFILE, editProfileAsync)
}

export default ProfileSaga
