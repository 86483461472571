import MetisMenu from "metismenujs"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import SimpleBar from "simplebar-react"
import { checkAccount, openAlert } from "../../store/actions"

class SidebarContent extends Component {
  constructor(props) {
    super(props)
    this.refDiv = React.createRef()
  }

  componentDidMount() {
    this.initMenu()
    this.props?.onCheckAccount()

  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }

  initMenu() {
    new MetisMenu("#side-menu")

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    console.log(item)
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  renderTakeback = () => {
    const takebackId = this?.props?.TakeBacks?.items?.[0]?.id;
    return (
      <li>
        {/* <div onClick={() => this?.props?.history?.push("/take-back")}> */}
        <Link to="/#" className="has-arrow waves-effect">
          <i className="mdi mdi-keyboard-return" />
          <span>{"Take Back Configuration"}</span>
        </Link>
        {/* </div> */}
        <ul className="sub-menu" aria-expanded="false">
          <li>
            <Link to="/take-back">{"Overview"}</Link>
          </li>
          <li>
            {takebackId
              ? <Link to={`/take-back/${takebackId}/details`}>{"Details"}</Link>
              : <Link to={"/take-back/details/add"}>{"Details"}</Link>
            }
          </li>
          <li>
            {takebackId
              ? <Link to={`/take-back/${takebackId}/accepted-items`}>{"Accepted Items"}</Link>
              : <Link to="/take-back/details/add"
                onClick={() => this?.props?.onOpenAlert("You can unlock this option by adding Take Back Details.", "danger")}
                className="sidebar-option--disabled"
              >
                <span >{"Accepted Items"}</span>
                <i className="bx bx-lock-alt" />
              </Link>
            }
          </li>
          {this?.props?.userRole === "RETAILER" || this?.props?.userRole === "CHARITY" ? (
            <li>
              {takebackId
                ? <Link to={`/take-back/${takebackId}/redeem-coupon`}>{"Redeem Coupon"}</Link>
                : <Link to="/take-back/details/add"
                  onClick={() => this?.props?.onOpenAlert("You can unlock this option by adding Take Back Details.", "danger")}
                  className="sidebar-option--disabled"
                >
                  <span >{"Redeem Coupon"}</span>
                  <i className="bx bx-lock-alt" />
                </Link>
              }
            </li>
          ) : null}
          <li>
            {takebackId
              ? <Link to={`/take-back/${takebackId}/local-stores`}>{"Local Stores"}</Link>
              : <Link to="/take-back/details/add"
                onClick={() => this?.props?.onOpenAlert("You can unlock this option by adding Take Back Details.", "danger")}
                className="sidebar-option--disabled"
              >
                <span>{"Local Stores"}</span>
                <i className="bx bx-lock-alt" />
              </Link>
            }
          </li>
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-truck-outline" />
              <span>{"Delivery"}</span>
            </Link>
            {/* </div> */}
            <ul className="sub-menu" aria-expanded="false">
              <li>
                {takebackId
                  ? <Link to={`/take-back/${takebackId}/return-address`}>{"Return Address"}</Link>
                  : <Link to="/take-back/details/add"
                    onClick={() => this?.props?.onOpenAlert("You can unlock this option by adding Take Back Details.", "danger")}
                    className="sidebar-option--disabled"
                  >
                    <span >{"Return Address"}</span>
                    <i className="bx bx-lock-alt" />
                  </Link>
                }
              </li>
              <li>
                {takebackId
                  ? <Link to={`/take-back/${takebackId}/carriers`}>{"Carriers"}</Link>
                  : <Link to="/take-back/details/add"
                    onClick={() => this?.props?.onOpenAlert("You can unlock this option by adding Take Back Details.", "danger")}
                    className="sidebar-option--disabled"
                  >
                    <span >{"Carriers"}</span>
                    <i className="bx bx-lock-alt" />
                  </Link>
                }
              </li>
            </ul>
          </li>
        </ul>
      </li>
    )
  }

  render() {
    // const details = true;
    const details = this?.props?.info?.configuration?.details;
    // console.log(details)
    const entitlements = this?.props?.info?.configuration?.entitlements ? this?.props?.info?.configuration?.entitlements : this?.props?.info?.general_information?.entitlements;
    const takebackId = this?.props?.TakeBacks?.items?.[0]?.id;

    return (
      <React.Fragment>
        <SimpleBar style={{ maxHeight: "100%" }} ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <Link to="/dashboard" className="waves-effect">
                  <i className="bx bx-home" />
                  <span>{"Home"}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-message-rounded-x" />
                  <span>{"Plugin Configuration"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/plugin/integrations">{"Integrations"}</Link>
                  </li>
                  <li>
                    <Link to="/plugin/integrations/download">{"Download Plugins"}</Link>
                  </li>
                  <li>
                    <Link to="/plugin/integrations/documentation">{"Documentation"}</Link>
                  </li>
                </ul>
              </li>

              {this?.props?.userRole === "RETAILER" && (
                <>
                  {this?.props?.isDev ?
                    <li>
                      <Link to="/#" className="has-arrow waves-effect">
                        <i className="bx bx-cog" />
                        <span>{"Ads Manager"}</span>
                      </Link>
                      <ul className="sub-menu" aria-expanded="false">
                        <li>
                          <Link to="/feed">{"My feeds"}</Link>
                        </li>
                        <li>
                          <Link to="/feed/analytics">{"Stats"}</Link>
                        </li>
                      </ul>
                    </li>
                  : null}

                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="bx bx-purchase-tag-alt" />
                      <span>{"Coupons Manager"}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/coupons">{"Overview"}</Link>
                      </li>
                      {/* <li>
                        <Link to="/import-codes">
                          {"Import"}
                        </Link>
                      </li> */}
                      <li>
                        <Link to="/add-coupon">
                          {"Add Coupon"}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </>
              )}

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  {/* <i><img src={heartWhite} /></i> */}
                  {/* <span>{"Charity Configuration"}</span> */}
                  <i className="far fa-user-circle" />
                  <span>{"Mobile App Profile"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/details">{"Details"}</Link>
                  </li>
                </ul>
              </li>

              {this?.props?.userRole === "RETAILER" &&
                entitlements?.hasOwnProperty("TAKE_BACKS") ? (
                  <li>
                    {details
                      ? this.renderTakeback()
                      : (
                        <>
                          <Link to="/details"
                            onClick={() => this?.props?.onOpenAlert("You can unlock this option by adding Mobile App Profile details.", "danger")}
                            className="sidebar-option--disabled-normal"
                          >
                            <i className="mdi mdi-keyboard-return" />
                            <span>{"Take Back Configuration"}</span>
                            <i className="bx bx-lock-alt" />
                          </Link>
                        </>
                      )
                    }
                  </li>
                ) : null}

              {this?.props?.userRole === "CHARITY" && (
                <>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect"> 
                      {/* <i><img src={heartWhite} /></i> */}
                      {/* <span>{"Charity Configuration"}</span> */}
                      <i className="far fa-heart" />
                      <span>{"Donations Setup"}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">

                      {/* <li>
                        <Link to="/details">{"Charity Details"}</Link>
                      </li> */}

                      {entitlements?.hasOwnProperty("VIRTUAL_CHARITY") ? (
                        <li>
                          {details
                            ? <Link to="/virtual-shop">{"Virtual Charity Shop"}</Link>
                            : <Link to="/details"
                              onClick={() => this?.props?.onOpenAlert("You can unlock this option by adding Charity Details.", "danger")}
                              className="sidebar-option--disabled"
                            >
                              <span >{"Virtual Charity Shop"}</span>
                              <i className="bx bx-lock-alt" />
                            </Link>
                          }
                        </li>
                      ) : null}

                      {entitlements?.hasOwnProperty("SEND_BY_POST") ? (
                        <li>
                          {details
                            ? <Link to="/ship-by-post">{"Ship by Post"}</Link>
                            : <Link to="/details"
                              onClick={() => this?.props?.onOpenAlert("You can unlock this option by adding Charity Details.", "danger")}
                              className="sidebar-option--disabled"
                            >
                              <span>{"Ship by Post"}</span>
                              <i className="bx bx-lock-alt" />
                            </Link>
                          }
                        </li>
                      ) : null}

                      {entitlements?.hasOwnProperty("MICRO_DONATIONS") ? (
                        <li>
                          {details
                            ? <Link to="/micro-donations">{"Micro-Donations"}</Link>
                            : <Link to="/details"
                              onClick={() => this?.props?.onOpenAlert("You can unlock this option by adding Charity Details.", "danger")}
                              className="sidebar-option--disabled"
                            >
                              <span>{"Micro-Donations"}</span>
                              <i className="bx bx-lock-alt" />
                            </Link>
                          }
                        </li>
                      ) : null}

                      <li>
                        {details
                          ? <Link to="/payouts-with-paypal">{"Payouts with Paypal"}</Link>
                          : <Link to="/details"
                            onClick={() => this?.props?.onOpenAlert("You can unlock this option by adding Charity Details.", "danger")}
                            className="sidebar-option--disabled"
                          >
                            <span>{"Payouts with Paypal"}</span>
                            <i className="bx bx-lock-alt" />
                          </Link>
                        }
                      </li>

                      {entitlements?.hasOwnProperty("TAKE_BACKS") ? (
                        <li>
                          {details
                            ? this.renderTakeback()
                            : (
                              <Link to="/details"
                                onClick={() => this?.props?.onOpenAlert("You can unlock this option by adding Charity Details.", "danger")}
                                className="sidebar-option--disabled"
                              >
                                <span >{"Take Back Configuration"}</span>
                                <i className="bx bx-lock-alt" />
                              </Link>
                            )
                          }
                        </li>
                      ) : null}

                      {entitlements?.hasOwnProperty("CHARITY_SHOPS") ? (
                        <li>
                          {!details ? (
                            <Link to="/details"
                              onClick={() => this?.props?.onOpenAlert("You can unlock this option by adding Charity Details.", "danger")}
                              className="sidebar-option--disabled"
                            >
                              <span>{"Charity Shops"}</span>
                              <i className="bx bx-lock-alt" />
                            </Link>
                          ) : (
                            <>
                              <div onClick={() => this?.props?.history?.push("/charity-shops")}>
                                <Link to="/#" className="has-arrow waves-effect">
                                  <i className="bx bx-store" />
                                  <span>{"Charity Shops"}</span>
                                </Link>
                              </div>
                              <ul className="sub-menu" aria-expanded="false">
                                <li>
                                  <Link to="/charity-shops">{"Overview"}</Link>
                                </li>
                                <li>
                                  <Link to="/charity-shops-list">{"Shops"}</Link>
                                </li>
                                <li>
                                  <Link to="/import-charity-shop">
                                    {"Import"}
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/add-charity-shop">
                                    {"Add a New Shop"}
                                  </Link>
                                </li>
                              </ul>
                            </>
                          )}
                        </li>
                      ) : null}

                    </ul>
                  </li>
                </>
              )}

              {this?.props?.userRole === "SCHOOL" ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="bx bx-infinite" />
                    <span>{"LOOP FOR GOOD"}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {/* <li>
                      <Link to="/details">{"Details"}</Link>
                    </li> */}

                    {entitlements?.hasOwnProperty("VIRTUAL_SCHOOL") ? (
                      <li>
                        {details
                          ? <Link to="/virtual-shop">{"Resell For School"}</Link>
                          : <Link to="/details"
                            onClick={() => this?.props?.onOpenAlert("You can unlock this option by adding LOOP FOR GOOD Details.", "danger")}
                            className="sidebar-option--disabled"
                          >
                            <span >{"Resell For School"}</span>
                            <i className="bx bx-lock-alt" />
                          </Link>
                        }
                      </li>
                    ) : null}

                    {entitlements?.hasOwnProperty("SWAP") ? (
                      <li>
                        {details
                          ? <Link to="/swap">{"Swap"}</Link>
                          : <Link to="/details"
                            onClick={() => this?.props?.onOpenAlert("You can unlock this option by adding LOOP FOR GOOD Details.", "danger")}
                            className="sidebar-option--disabled"
                          >
                            <span >{"Swap"}</span>
                            <i className="bx bx-lock-alt" />
                          </Link>
                        }
                      </li>
                    ) : null}

                    {entitlements?.hasOwnProperty("SEND_BY_POST") ? (
                      <li>
                        {details
                          ? <Link to="/ship-by-post">{"Ship by Post"}</Link>
                          : <Link to="/details"
                            onClick={() => this?.props?.onOpenAlert("You can unlock this option by adding LOOP FOR GOOD Details.", "danger")}
                            className="sidebar-option--disabled"
                          >
                            <span>{"Ship by Post"}</span>
                            <i className="bx bx-lock-alt" />
                          </Link>
                        }
                      </li>
                    ) : null}

                    {entitlements?.hasOwnProperty("MICRO_DONATIONS") ? (
                      <li>
                        {details
                          ? <Link to="/micro-donations">{"Micro-Donations"}</Link>
                          : <Link to="/details"
                            onClick={() => this?.props?.onOpenAlert("You can unlock this option by adding LOOP FOR GOOD Details.", "danger")}
                            className="sidebar-option--disabled"
                          >
                            <span>{"Micro-Donations"}</span>
                            <i className="bx bx-lock-alt" />
                          </Link>
                        }
                      </li>
                    ) : null}

                    <li>
                      {details
                        ? <Link to="/payouts-with-paypal">{"Payouts with Paypal"}</Link>
                        : <Link to="/details"
                          onClick={() => this?.props?.onOpenAlert("You can unlock this option by adding LOOP FOR GOOD Details.", "danger")}
                          className="sidebar-option--disabled"
                        >
                          <span>{"Payouts with Paypal"}</span>
                          <i className="bx bx-lock-alt" />
                        </Link>
                      }
                    </li>

                    {entitlements?.hasOwnProperty("TAKE_BACKS") ? (
                      <li>
                        {details ? this.renderTakeback() : (
                          <Link to="/details"
                            onClick={() => this?.props?.onOpenAlert("You can unlock this option by adding LOOP FOR GOOD Details.", "danger")}
                            className="sidebar-option--disabled"
                          >
                            <span >{"Take Back Configuration"}</span>
                            <i className="bx bx-lock-alt" />
                          </Link>
                        )}
                      </li>
                    ) : null}

                    {entitlements?.hasOwnProperty("SCHOOL_SHOPS") ? (
                      <li>
                        {!details ? (
                          <Link to="/details"
                            onClick={() => this?.props?.onOpenAlert("You can unlock this option by adding LOOP FOR GOOD Details.", "danger")}
                            className="sidebar-option--disabled"
                          >
                            <span>{"School Points"}</span>
                            <i className="bx bx-lock-alt" />
                          </Link>
                        ) : (
                          <>
                            {/* <div onClick={() => this?.props?.history?.push("/charity-shops")}> */}
                            <Link to="/#" className="has-arrow waves-effect">
                              <i className="bx bx-store" />
                              <span>{"School Points"}</span>
                            </Link>
                            {/* </div> */}
                            <ul className="sub-menu" aria-expanded="false">
                              <li>
                                <Link to="/charity-shops">{"Overview"}</Link>
                              </li>
                              <li>
                                <Link to="/charity-shops-list">{"Shops"}</Link>
                              </li>
                              <li>
                                <Link to="/import-charity-shop">
                                  {"Import"}
                                </Link>
                              </li>
                              <li>
                                <Link to="/add-charity-shop">
                                  {"Add a New Shop"}
                                </Link>
                              </li>
                            </ul>
                          </>
                        )}
                      </li>
                    ) : null}
                  </ul>
                </li>
              ) : null}



              {this?.props?.userRole === "RETAILER" && this?.props?.userEmail === "demo@loop.com" && (
                <li>
                  {/* <Link to="/analytics" className="waves-effect">
                    <i className="bx bx-chart" />
                    <span>{"Post purchase analytics"}</span>
                  </Link> */}
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="bx bx-chart" />
                    <span>{"Wardrobe Analytics"}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/analytics/post-purchase">{"Details"}</Link>
                    </li>
                  </ul>
                </li>
              )}

              {this?.props?.userRole !== "CHARITY" && (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="bx bx-credit-card" />
                    <span>{"Billing"}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/billings">{"Overview"}</Link>
                    </li>
                    <li>
                      <Link to="/change-plan">
                        {"Change Plan"}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/change-payment-info">
                        {"Payment Details"}
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/change-vat-id">
                        {"Change VAT ID"}
                      </Link>
                    </li>
                    <li>
                      <Link to="/payment-method">{"Payment Method"}</Link>
                    </li>
                  </ul>
                </li>
              )}

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-cog" />
                  <span>{"Settings"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/profile">{"Profile"}</Link>
                  </li>
                  <li>
                    <Link to="/change-password">{"Change Password"}</Link>
                  </li>
                </ul>
              </li>

            </ul>
          </div>
        </SimpleBar >
      </React.Fragment >
    )
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  type: PropTypes.string,
}

const mapDispatchToProps = dispatch => {
  return {
    onCheckAccount: () => dispatch(checkAccount()),
    onOpenAlert: (text, status) => dispatch(openAlert({ text, status })),
    // onGetPaymentInfo: () => dispatch({ type: "GET_PAYMENT_INFO" }),
  }
}

const mapStateToProps = state => {
  const { user, info } = state.Account
  const TakeBacks = state.TakeBacks
  return { user, userRole: user?.role, userEmail: user?.email, isDev: user?.dev, info, TakeBacks }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidebarContent)
)
