import React from 'react';
//@ts-ignore
import {useDispatch} from 'react-redux';
//@ts-ignore
import {Link} from 'react-router-dom';
import {Button, Label, Input} from 'reactstrap';
//@ts-ignore
import {CopyToClipboard} from 'react-copy-to-clipboard';
import SwitchCustom from './../../../components/SwitchCustom/SwitchCustom';
import {useHistory} from 'react-router-dom';

interface WebsitesTableColumnsInterface {
  offsetIndex: number;
  handleDelete: (uuid: string, name: string) => void;
  handleEdit: (id: string) => void;
  handleConfigurationPageRedirect: (id: string) => void;
  handlePreview: (id: string) => void;
  returnTranslation: (name: string) => string;
  displayCopiedAlert: () => void;
  handleUpdateWebsite: (
    testMode: boolean,
    active: boolean,
    siteId: number,
    name: string,
    url: string,
  ) => void;
}

const WebsitesTableColumns = ({
  offsetIndex,
  handleDelete,
  handleEdit,
  handleConfigurationPageRedirect,
  handlePreview,
  returnTranslation,
  displayCopiedAlert,
  handleUpdateWebsite,
}: WebsitesTableColumnsInterface) => {
  const history = useHistory();

  return [
    {
      dataField: 'i',
      isDummyField: true,
      text: '#',
      formatter: (cellContent: any, row: any, rowIndex: any) => (
        <div>{offsetIndex + (rowIndex + 1)}</div>
      ),
    },
    {
      dataField: 'name',
      text: returnTranslation('Name'),
    },
    {
      dataField: 'url',
      text: returnTranslation('Domain URL'),
    },
    // {
    //   dataField: "corrected_pasted",
    //   text: returnTranslation("Correct pasted"),
    //   formatter: (cellContent: any, row: any, rowIndex: any) => (
    //     <p>{row?.corrected_pasted ? "true" : "false"}</p>
    //   )
    // },
    {
      dataField: 'SITE-UUID',
      text: returnTranslation('SITE-UUID'),
      formatter: (cellContent: any, row: any, rowIndex: any) => (
        <div style={{marginTop: '15px'}}>
          <div className="input-group mb-3">
            <Input
              type="text"
              className="form-control"
              value={row?.['SITE-UUID']}
              id="inputGroupFile01"
            />
            <CopyToClipboard text={row?.['SITE-UUID']}>
              <Label
                onClick={displayCopiedAlert}
                className="input-group-text clickable"
                htmlFor={`inputGroupFile-${row?.['SITE-UUID']}`}>
                Copy
              </Label>
            </CopyToClipboard>
          </div>
        </div>
      ),
    },
    {
      dataField: 'test_mode',
      text: returnTranslation('Plugin Mode'),
      formatter: (cellContent: any, row: any, rowIndex: any) => (
        // <p>{row?.test_mode == 1 ? <b>On</b> : "Off"}</p>
        // <SwitchCustom
        //   handleSwitchChange={() => handleUpdateWebsite(!row?.test_mode, row?.active, row?.id, row?.name, row?.url)}
        //   value={row?.test_mode == 1 ? true : false}
        //   offText={"Off"}
        //   onText={"On"}
        // />
        <div
          className={`d-flex align-items-center gap-1 clickable auth-agreement-link ${
            row.test_mode == 1 ? 'auth-agreement-link--inactive' : ''
          }`}>
          <div
            style={{width: '160px'}}
            className="d-flex gap-1 align-items-center mt-3">
            <SwitchCustom
              handleSwitchChange={() =>
                handleUpdateWebsite(
                  !row?.test_mode,
                  row?.active,
                  row?.id,
                  row?.name,
                  row?.url,
                )
              }
              value={!!row?.test_mode}
              // onOffText={returnTranslation(row?.test_mode ? "Active" : "Inactive")}
              onText={'DEV'}
              offText={'PROD'}
              size="md"
            />
          </div>
        </div>
      ),
    },
    {
      dataField: 'active',
      text: returnTranslation('Active'),
      formatter: (cellContent: any, row: any, rowIndex: any) => (
        // <SwitchCustom
        //   handleSwitchChange={() => handleUpdateWebsite(row?.test_mode, !row?.active, row?.id, row?.name, row?.url)}
        //   value={row?.active == 1 ? true : false}
        //   offText={"Off"}
        //   onText={"On"}
        // />
        <div
          className={`d-flex align-items-center gap-1 clickable auth-agreement-link ${
            row.active == 1 ? 'auth-agreement-link--inactive' : ''
          }`}>
          <div
            style={{width: '160px'}}
            className="d-flex gap-1 align-items-center mt-3">
            <SwitchCustom
              handleSwitchChange={() =>
                handleUpdateWebsite(
                  row?.test_mode,
                  !row?.active,
                  row?.id,
                  row?.name,
                  row?.url,
                )
              }
              value={!!row?.active}
              onOffText={returnTranslation(row?.active ? 'Active' : 'Inactive')}
              size="md"
            />
          </div>
        </div>
      ),
    },
    {
      dataField: 'configuration',
      isDummyField: true,
      formatter: (cellContent: any, row: any, rowIndex: any) => (
        <div className="plugin-configuration-btns">
          <Link
            to="#"
            className="text-warning"
            onClick={() => handleConfigurationPageRedirect(row.id)}>
            <Button color="success">
              {returnTranslation('MANUAL CONFIGURATION')}
            </Button>
          </Link>
          <Link
            to="#"
            className="text-warning"
            onClick={() =>
              history?.push(`/plugin/manual/shopify/${row?.['SITE-UUID']}`)
            }>
            <Button color="success">
              {returnTranslation('SHOPIFY CONFIGURATION')}
            </Button>
          </Link>
        </div>
      ),
      text: returnTranslation('Plugin Configuration'),
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: '',
      formatter: (cellContent: any, row: any, rowIndex: any) => (
        <div className="d-flex gap-3">
          <span
            className="text-warning clickable"
            onClick={() => handleEdit(row.id)}>
            <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
          </span>
          <span
            className="text-warning clickable"
            onClick={() => handlePreview(row['SITE-UUID'])}>
            <i className="mdi mdi-magnify font-size-18" id="previewtooltip" />
          </span>
          <span
            className="text-danger clickable"
            onClick={() => handleDelete(row.id, row.name)}>
            <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
          </span>
        </div>
      ),
    },
  ];
};

export default WebsitesTableColumns;
