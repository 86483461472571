import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_TRANSLATIONS
} from "./actionTypes"
import {
  getTranslationsFail,
  getTranslationsSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getTranslations
} from "./../../helpers/backend_helper"

function* getTranslationsAsync() {
  try {
    const response = yield call(getTranslations)
    yield put(getTranslationsSuccess(response))
  } catch (error) {
    yield put(getTranslationsFail(error))
  }
}

function* translationsSaga() {
  yield takeEvery(GET_TRANSLATIONS, getTranslationsAsync)
}

export default translationsSaga
