// @ts-ignore
import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { Component } from "react"
import { withTranslation } from "react-i18next"
// @ts-ignore
import MetaTags from 'react-meta-tags'
// @ts-ignore
import { connect } from "react-redux"
// @ts-ignore
import { RouteComponentProps } from "react-router"
// @ts-ignore
import { Link } from "react-router-dom"
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, Row, Spinner
}
    // @ts-ignore
    from "reactstrap"
import {
    apiError, changePreloader, registerUserFailed,
    registerUserSuccessful
} from "../../../store/actions"
import { post } from "./../../../helpers/api_helper"
import * as url from "./../../../helpers/url_helper"
import { openAlert } from "./../../../store/alert/actions"
import { range } from "lodash";

export interface RegisterCardPropsInterface extends RouteComponentProps {
    dashbord_id: string,
    registerUserSuccessful: (userObj: any) => {},
    apiError: (err: string) => {},
    history: any,
    location: any,
    error: string,
    onOpenAlert: ({ }) => {},
    registerUserFailed: (status: string) => {},
    t: any,
    onGetCountries: any,
    countries: [{ code: string, name: string }],
    user: any,
    registrationError: string,
    onChangePreloader: (value: boolean) => {}
}

export interface RegisterCardStateInterface {
    isCharity: boolean,
    countries: any[],
    selectedCountryCode: string,
    formErrors: any,
    isLoading: boolean,
}

class RegisterCard extends Component<RegisterCardPropsInterface, RegisterCardStateInterface> {
    constructor(props: RegisterCardPropsInterface) {
        super(props)
        this.state = {
            isCharity: false,
            countries: [],
            selectedCountryCode: "",
            formErrors: {},
            isLoading: false
        }
    }

    async componentDidMount() {
        await this.props.apiError("")
        await this.props.registerUserFailed("")
        await this.props.onGetCountries()
    }

    handleCharityRadioChecked = (status: boolean) => {
        const { isCharity } = this.state

        this.setState({ isCharity: status })
    }

    handleLoginUser = async (email: string, password: string) => {
        return new Promise(async (resolve, reject) => {
            //   this.props.changePreloader(true)
            post(`${url.API_BASE}login`, { email, password })
                .then(response => {
                    if (response?.user && response?.access_token) {
                        let userObj = response?.user;
                        userObj.access_token = response?.access_token;
                        this.props.registerUserSuccessful(userObj)
                        localStorage.setItem("authUser", JSON.stringify(userObj))
                        this.props.history.push("/dashboard")
                    }

                    //   this.props.changePreloader(false)
                    return resolve(response);
                })
                .catch(error => {
                    if (error && error.response && error.response.data && error.response.data.message) {
                        this.props.apiError(error.response.data.message)
                    }
                    //   this.props.changePreloader(false)
                    return reject(error);
                });

        });
    }

    handleValidSubmit = async (event: any, values: any) => {
        event.preventDefault()

        return new Promise(async (resolve, reject) => {
            const { selectedCountryCode, isCharity } = this.state

            const {
                name,
                number,
                exp_month,
                exp_year,
                cvc
            } = values;

            this.props.onChangePreloader(true)
            this.setIsLoading(true);
            post(`${url.API_BASE}dashboard/register/8`, {
                name,
                number,
                exp_month,
                exp_year,
                cvc,
                code: this.props.location?.state?.code,
                plan: this.props.location?.state?.plan,
                interval: this.props.location?.state?.interval,

            })
                .then(response => {
                    if (response?.status) {
                        this.props.onOpenAlert({
                            text: response?.message,
                            status: "success",
                        })
                        // this.props.history.push("/login")
                        this.handleLoginUser(this.props.location?.state?.email, this.props.location?.state?.password)
                    }
                    this.props.onChangePreloader(false)
                    return resolve(response)
                })
                .catch(error => {
                    if (error?.response?.data) {
                        const { message, errors } = error?.response?.data;
                        this.setState({ formErrors: errors });
                        this.props.onOpenAlert({
                            text: message,
                            status: "danger",
                        })
                    }
                    this.props.onChangePreloader(false)
                    return reject(error)
                }).finally(() => {
                    this.setIsLoading(false);
                })
        })
    }

    handleChangeCountryCode = (event: any) => {
        this.setState({ selectedCountryCode: event.target.value })
    }

    setIsLoading = (bool: boolean) => {
        this.setState({ isLoading: bool })
    }

    render() {
        // const { countries, formErrors, selectedCountryCode, isCharity } = this.state
        // const { t } = this.props

        return (
            <React.Fragment>
                <MetaTags>
                    <title>LOOP | Register</title>
                </MetaTags>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-dark">
                        <i className="bx bx-home h2" />
                    </Link>
                </div>
                <div className="account-pages my-2 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                             <Col md={8} lg={7} xl={6}>
                                <Card className="overflow-hidden">
                                    <CardHeader>
                                        <i className="fas fa-chevron-left font-size-20 text-black clickable pt-1 ps-1"
                                            onClick={() => this?.props?.history?.push("/register-plans", this?.props?.location?.state)}
                                        />
                                    </CardHeader>
                                    <Row>
                                        <Col className="col-7">
                                            <div className="p-4">
                                                <h1>Card details</h1>
                                            </div>
                                        </Col>
                                    </Row>
                                    <CardBody className="pt-0">
                                        <div className="p-2">
                                            <AvForm
                                                className="form-horizontal"
                                                onValidSubmit={this.handleValidSubmit}
                                            >
                                                {this.props.user && this.props.user ? (
                                                    <Alert color="success">
                                                        Register User Successfully
                                                    </Alert>
                                                ) : null}
                                                {this.props.registrationError &&
                                                    this.props.registrationError ? (
                                                    <Alert color="danger">
                                                        {this.props.registrationError}
                                                    </Alert>
                                                ) : null}

                                                <div className="mb-3">
                                                    <AvField
                                                        name="name"
                                                        label="Card Owner Name"
                                                        className="form-control"
                                                        placeholder="Enter card Owner name"
                                                        type="text"
                                                        required
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <AvField
                                                        name="number"
                                                        label="Card Number"
                                                        className="form-control"
                                                        placeholder="xxxxxxxxxxxxxxxx"
                                                        type="text"
                                                        maxLength={16}
                                                        required
                                                    />
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="mb-3">
                                                            {/* <AvField
                                                                name="exp_month"
                                                                label="Exp Month"
                                                                className="form-control"
                                                                placeholder="mm"
                                                                type="text"
                                                                required
                                                                maxLength={2}
                                                            /> */}
                                                            <AvField
                                                                name="exp_month"
                                                                label="Exp Month"
                                                                className="form-control"
                                                                placeholder="mm"
                                                                type="select"
                                                                required
                                                                defaultValue={undefined}
                                                                value={undefined}
                                                            >
                                                                <option className="d-none text-muted">mm</option>
                                                                {range(1, 12)?.map((month: number) => (
                                                                    <option key={month} value={month?.toString()?.length === 1
                                                                        ? `0${month?.toString()}`
                                                                        : month}
                                                                    >
                                                                        {month?.toString()?.length === 1 ? `0${month?.toString()}` : month}
                                                                    </option>
                                                                ))}
                                                            </AvField>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="mb-3">
                                                            {/* <AvField
                                                                name="exp_year"
                                                                label="Exp Year"
                                                                className="form-control"
                                                                placeholder="yyyy"
                                                                type="text"
                                                                required
                                                                maxLength={4}
                                                            /> */}
                                                            <AvField
                                                                name="exp_year"
                                                                label="Exp Year"
                                                                className="form-control"
                                                                placeholder="yyyy"
                                                                type="select"
                                                                required
                                                                defaultValue={undefined}
                                                                value={undefined}
                                                            >
                                                                <option className="d-none text-muted">yyyy</option>
                                                                {range(new Date().getFullYear(), new Date().getFullYear() + 20)?.map((year: number) => (
                                                                    <option key={year} value={year}>{year}</option>
                                                                ))}
                                                            </AvField>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="cvc"
                                                                label="CVV"
                                                                className="form-control"
                                                                placeholder="xxx / xxxx"
                                                                type="text"
                                                                required
                                                                maxLength={4}
                                                                minLength={3}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mt-4 d-grid">
                                                    <button
                                                        className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
                                                        type="submit"
                                                    >
                                                        Next
                                                        {this.state.isLoading ? (
                                                            <Spinner className="ms-2" color="light" />
                                                        ): null}
                                                    </button>
                                                </div>

                                                <div className="mt-3 text-center">
                                                    <p>
                                                        <span className="auth-agreement-text">Already have an account?</span>{" "}
                                                        <Link to="/login" className="fw-medium auth-agreement-link">
                                                            {" "}
                                                            Log in
                                                        </Link>{" "}
                                                    </p>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <p className="mb-0 auth-agreement-text">
                                                        By continuing you agree to our <a href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/" target="_blank" className="text-primary">
                                                            Terms & Conditions
                                                        </a>. LOOP services are
                                                        subject to our <a href="https://loopdigitalwardrobe.com/privacy-policy/" target="_blank" className="text-primary">
                                                            Privacy Policy
                                                        </a>.
                                                    </p>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <p className="mb-0 auth-agreement-text">
                                                        Need our help? <a href="mailto:help@loopdigitalwardrobe.com" className="fw-medium contact-us-text">
                                                            Contact us.
                                                        </a>
                                                    </p>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="mt-5 text-center">
                                    <p className="auth-agreement-text">
                                        ©{new Date().getFullYear()} All Rights Reserved. LOOP DIGITAL WARDROBE® is a registered trademark of LOOP DIGITAL WARDROBE®.{" "}
                                        <a href="https://loopdigitalwardrobe.com/privacy-policy/" target="_blank" className="fw-medium text-primary">
                                            Privacy Policy
                                        </a> and{" "}
                                        <a href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/" target="_blank" className="fw-medium text-primary">
                                            Terms & Conditions
                                        </a>
                                        .
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div >
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state: any) => {
    const { user, registrationError, loading, dashbord_id } = state.Account
    const { countries } = state.Countries
    return { user, registrationError, loading, dashbord_id, countries }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        apiError: (message: string) => dispatch(apiError(message)),
        registerUserFailed: (message: string) => dispatch(registerUserFailed(message)),
        registerUserSuccessful: (user: any) => dispatch(registerUserSuccessful(user)),
        onGetCountries: () => dispatch({ type: "GET_COUNTRIES" }),
        onOpenAlert: ({ text, status }: any) =>
            dispatch(openAlert({ text: text, status: status })),
        onChangePreloader: (value: boolean) => dispatch(changePreloader(value))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(RegisterCard))
