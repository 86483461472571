import React, { useEffect, useState } from 'react'

interface Props {
    childToRender: any;
    triggerRender: boolean;
}

const LazyTabWrapper = ({ childToRender, triggerRender }: Props) => {
    const [renderHasBeenTriggered, setRenderHasBeenTriggered] = useState(false);

    useEffect(() => {
        if(triggerRender && !renderHasBeenTriggered){
            setRenderHasBeenTriggered(true)
        }
    }, [triggerRender])

    return renderHasBeenTriggered && childToRender ? childToRender : null
}

export default LazyTabWrapper
