// @ts-ignore
import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { Component } from "react"
import { withTranslation } from "react-i18next"
// @ts-ignore
import MetaTags from 'react-meta-tags'
// @ts-ignore
import { connect } from "react-redux"
// @ts-ignore
import { RouteComponentProps } from "react-router"
// @ts-ignore
import { Link } from "react-router-dom"
import {
    Alert,
    Card,
    CardBody,
    Col,
    Container, Row
}
    // @ts-ignore
    from "reactstrap"
import {
    apiError, changePreloader, registerUserFailed,
    registerUserSuccessful
} from "../../../store/actions"
import { get, post } from "./../../../helpers/api_helper"
import * as url from "./../../../helpers/url_helper"
import { openAlert } from "./../../../store/alert/actions"

export interface RegisterCodePropsInterface extends RouteComponentProps {
    dashbord_id: string,
    registerUserSuccessful: (userObj: any) => void,
    apiError: (err: string) => void,
    history: any,
    location: any,
    error: string,
    onOpenAlert: ({ }) => void,
    registerUserFailed: (status: string) => void,
    t: any,
    onGetCountries: any,
    countries: { code: string, name: string }[],
    user: any,
    registrationError: string,
    onChangePreloader: (value: boolean) => void;
    returnTranslation: (text: string) => string;
}

export interface RegisterCodeStateInterface {
    isCharity: boolean,
    countries: any[],
    selectedCountryCode: string,
    formErrors: any,
}

class RegisterCode extends Component<RegisterCodePropsInterface, RegisterCodeStateInterface> {
    constructor(props: RegisterCodePropsInterface) {
        super(props)
        this.state = {
            isCharity: false,
            countries: [],
            selectedCountryCode: "",
            formErrors: {},
        }
    }

    async componentDidMount() {
        await this.props.apiError("")
        await this.props.registerUserFailed("")
        await this.props.onGetCountries()
    }

    handleCharityRadioChecked = (status: boolean) => {
        const { isCharity } = this.state

        this.setState({ isCharity: status })
    }

    handleContinueRegister = async  (code: string) => {
        try{
            const response = await get(`${url.API_BASE}dashboard/register/step?code=${code}`)
            console.log(response)
            if(response?.step === 2){
                this.props.history.push("/register-password", {
                    code,
                    email: this.props.location?.state?.email,
                })
            }else if(response?.step === 3){
                this.props.history.push("/register-role", {
                    code,
                    email: this.props.location?.state?.email,
                    role: response?.type,
                })
            }else if(response?.step === 4){
                this.props.history.push("/register-contact", {
                    code,
                    email: this.props.location?.state?.email,
                    role: response?.type,
                })
            }else if(response?.step === 5){
                this.props.history.push("/register-details", {
                    code,
                    email: this.props.location?.state?.email,
                    role: response?.type,
                })
            }else if(response?.step === 6){
                this.props.history.push("/register-socials", {
                    code,
                    email: this.props.location?.state?.email,
                    role: response?.type,
                })
            }else if(response?.step === 7){
                this.props.history.push("/register-plans", {
                    code,
                    email: this.props.location?.state?.email,
                    role: response?.type,
                })
            }else if(response?.step === 8){
                this.props.history.push("/register-card", {
                    code,
                    email: this.props.location?.state?.email,
                    role: response?.type,
                    // code: this.props.location?.state?.code,
                    // type: this.props.location?.state?.role,
                    // email: this.props.location?.state?.email,
                    // password: this.props.location?.state?.password,
                    // // password_confirmation: this.props.location?.state?.password_confirmation,
                    // plan: this.state.planType,
                    // interval: this.state.billAnnulally ? "year" : "month",
                })
            }
        }catch(e){

        }finally{
            
        }
    }

    handleValidSubmit = async (event: any, values: any) => {
        event.preventDefault()

        return new Promise(async (resolve, reject) => {
            // console.log(["event.target", values])
            const { selectedCountryCode, isCharity } = this.state

            const {
                code,
            } = values;

            this.props.onChangePreloader(true)

            get(`${url.API_BASE}dashboard/register/step?code=${code}`)
                .then(response => {
                    // console.log(["response", response, response])

                    if (response?.step) {
                        // this.props.registerUserSuccessful(response?.user)
                        this.props.onOpenAlert({
                            text: response?.message,
                            status: "success",
                        })
                        this.handleContinueRegister(code)
                        // this.props.history.push("/register-password", {
                        //     code,
                        //     email: this.props.location?.state?.email,
                        // })
                    }

                    this.props.onChangePreloader(false)
                    return resolve(response)
                })
                .catch(error => {
                    if (error?.response?.data) {
                        const { message, errors } = error?.response?.data;
                        this.setState({ formErrors: errors });
                        this.props.onOpenAlert({
                            text: message,
                            status: "danger",
                        })
                    }
                    this.props.onChangePreloader(false)
                    return reject(error)
                })
        })
    }

    handleChangeCountryCode = (event: any) => {
        this.setState({ selectedCountryCode: event.target.value })
    }

    resendEmail = async (event: any) => {
        event.preventDefault();

        return new Promise(async (resolve, reject) => {
            //   const { selectedCountryCode, isCharity, role } = this.state

            //   const {
            //     email,
            //   } = values;

            post(`${url.API_BASE}dashboard/register/1`, {
                email: this.props.location?.state?.email,
                // role: this.props.location?.state?.type
            })
                .then(response => {
                    if (response?.status) {
                        // this.props.registerUserSuccessful(response?.user)
                        this.props.onOpenAlert({
                            text: response?.message,
                            status: "success",
                        })
                        // this.props.history.push("/register-password", {
                        //   type: response?.type,
                        //   email: email
                        // })
                    }

                    return resolve(response)
                })
                .catch(error => {
                    if (error?.response?.data) {
                        const { message, errors } = error?.response?.data;
                        this.setState({ formErrors: errors });
                        this.props.onOpenAlert({
                            text: message,
                            status: "danger",
                        })
                    }
                    return reject(error)
                })
        })
    }

    render() {
        const { countries, formErrors, selectedCountryCode, isCharity } = this.state
        const { t } = this.props

        return (
            <React.Fragment>
                <MetaTags>
                    <title>LOOP | Register</title>
                </MetaTags>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-dark">
                        <i className="bx bx-home h2" />
                    </Link>
                </div>
                <div className="account-pages my-2 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                             <Col md={8} lg={7} xl={6}>
                                <Card className="overflow-hidden">
                                    <Row>
                                        <Col className="col-12">
                                            <div className="p-4">
                                                <h1>Confirm your email address</h1>
                                            </div>
                                        </Col>
                                    </Row>
                                    <CardBody className="pt-0">
                                        <div className="p-2">
                                            <AvForm
                                                className="form-horizontal"
                                                onValidSubmit={this.handleValidSubmit}
                                            >
                                                {this.props.user && this.props.user ? (
                                                    <Alert color="success">
                                                        Register User Successfully
                                                    </Alert>
                                                ) : null}
                                                {this.props.registrationError &&
                                                    this.props.registrationError ? (
                                                    <Alert color="danger">
                                                        {this.props.registrationError}
                                                    </Alert>
                                                ) : null}
                                                <div>
                                                    <p>Please verify your email address<br />Enter the code you received on your email - {this.props.location?.state?.email}</p>
                                                </div>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="code"
                                                        label="Code"
                                                        className="form-control"
                                                        placeholder="Enter code"
                                                        type="text"
                                                        required
                                                    />
                                                </div>

                                                <div className="mt-4 d-grid">
                                                    <button
                                                        className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
                                                        type="submit"
                                                    >
                                                        Next
                                                    </button>
                                                </div>

                                                <div className="mt-3 text-center">
                                                    <p>
                                                        <span className="auth-agreement-text">Didn't receive a code?</span>{" "}
                                                        <Link onClick={(e) => this.resendEmail(e)} to="#" className="fw-medium auth-agreement-link">
                                                            {" "}
                                                            Resend code.
                                                        </Link>{" "}
                                                    </p>
                                                </div>

                                                <div className="mt-3 text-center">
                                                    <p>
                                                        <span className="auth-agreement-text">Already have an account?</span>{" "}
                                                        <Link to="/login" className="fw-medium auth-agreement-link">
                                                            {" "}
                                                            Log in
                                                        </Link>{" "}
                                                    </p>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <p className="mb-0 auth-agreement-text">
                                                        By continuing you agree to our <a href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/" target="_blank" className="text-primary">
                                                            Terms & Conditions
                                                        </a>. LOOP services are
                                                        subject to our <a href="https://loopdigitalwardrobe.com/privacy-policy/" target="_blank" className="text-primary">
                                                            Privacy Policy
                                                        </a>.
                                                    </p>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <p className="mb-0 auth-agreement-text">
                                                        Need our help? <a href="mailto:help@loopdigitalwardrobe.com" className="fw-medium contact-us-text">
                                                            Contact us.
                                                        </a>
                                                    </p>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="mt-5 text-center">
                                    <p className="auth-agreement-text">
                                        ©{new Date().getFullYear()} All Rights Reserved. LOOP DIGITAL WARDROBE® is a registered trademark of LOOP DIGITAL WARDROBE®.{" "}
                                        <a href="https://loopdigitalwardrobe.com/privacy-policy/" target="_blank" className="fw-medium text-primary">
                                            Privacy Policy
                                        </a> and{" "}
                                        <a href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/" target="_blank" className="fw-medium text-primary">
                                            Terms & Conditions
                                        </a>
                                        .
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div >
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state: any) => {
    const { user, registrationError, loading, dashbord_id } = state.Account
    const { countries } = state.Countries
    return { user, registrationError, loading, dashbord_id, countries }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        apiError: (message: string) => dispatch(apiError(message)),
        registerUserFailed: (message: string) => dispatch(registerUserFailed(message)),
        registerUserSuccessful: (user: any) => dispatch(registerUserSuccessful(user)),
        onGetCountries: () => dispatch({ type: "GET_COUNTRIES" }),
        onOpenAlert: ({ text, status }: any) =>
            dispatch(openAlert({ text: text, status: status })),
        onChangePreloader: (value: boolean) => dispatch(changePreloader(value))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(RegisterCode))
