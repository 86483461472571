import BasicTable from "./../../../components/BasicTable/BasicTable"
import Breadcrumbs from "./../../../components/Common/Breadcrumb"
import { deleteWebsiteApi } from "./../../../helpers/backend_helper"
import React, { useEffect } from "react"
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
// @ts-ignore
import { RouteComponentProps, withRouter } from "react-router-dom"
// @ts-ignore
import MetaTags from 'react-meta-tags';
import { Container } from "reactstrap"
import { getWebsites, openAlert, requestConfirmation, getInvoices } from "./../../../store/actions"
import InvoiceTableColumns from "./InvoiceTableColumns/InvoiceTableColumns"

interface InvoicesInterface extends RouteComponentProps {
    history: any,
    returnTranslation: (name: string) => string
}

const Invoices = ({ history, returnTranslation }: InvoicesInterface) => {
    const dispatch = useDispatch()
    const invoices = useSelector((state: any) => state.Invoices.info)
    const isLoading = useSelector((state: any) => state.Invoices.isLoading)

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>LOOP | Invoices</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs
                        title={returnTranslation("Billings")}
                        breadcrumbItem={returnTranslation("Invoices")}
                    />
                    <BasicTable
                        items={invoices}
                        // @ts-ignore
                        columns={InvoiceTableColumns({ returnTranslation })}
                        //@ts-ignore
                        addItemBtnText={""}
                        addItemBtnAction={() => { }}
                        searchbar={false}
                        getAction={getInvoices}
                        totalSize={invoices?.length}
                        sizePerPage={15}
                        isLoading={isLoading}
                        currentPage={1}
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(Invoices)
