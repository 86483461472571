import axios from "axios"
// @ts-ignore
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation"
import Breadcrumbs from "./../../components/Common/Breadcrumb"
import FormInput from "./../../components/Common/FormInput"
import {
  getWebsiteDetailApi,
  getEmailTemplates
} from "./../../helpers/backend_helper"
import React, { useEffect, useState, useRef } from "react"
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
import Dropzone from "react-dropzone"
// @ts-ignore
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  CardTitle,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Input
} from "reactstrap"
import { openAlert } from "./../../store/alert/actions"
import { getDictionaries } from "./../../store/dictionaries/actions"
// @ts-ignore
import { SketchPicker } from 'react-color'
// @ts-ignore
import { addWebsiteLogo, clearWebsiteLogo } from "./../../store/alert/actions"
import { API_BASE } from "./../../helpers/url_helper"
// @ts-ignore
import MetaTags from 'react-meta-tags';
import SwitchCustom from "../../components/SwitchCustom/SwitchCustom"

interface WebsiteFormInterface {
  history: any,
  match: any,
  returnTranslation: (name: string) => string
}

interface IPluginConfigObject {
  plugin: {
    allow_actions: string[],
    color_1: string,
    color_2: string,
    default_lang: string,
    yOffset: string,
    xOffset: string,
    zIndex: string,
    direction: string,
    position: string,
    website_container_class: string,
    description?: string,
    show_widget_btn: boolean,
    xOffset_widget_btn: string,
    yOffset_widget_btn: string,
    zIndex_widget_btn: string,
    position_widget_btn: string,
    direction_widget_btn: string,
    default_widget_style: string | boolean,
  }
}

const WebsiteForm = ({ history, match: { params }, returnTranslation }: WebsiteFormInterface) => {
  const dispatch = useDispatch()

  const [widgetStatus, setWidgetStatus] = useState("active")
  const [widgetStatusList, setWidgetStatusList] = useState(["active", "inactive"])
  const [url, setUrl] = useState("")
  const [name, setName] = useState("")
  const [logo, setLogo] = useState<any>("")
  const [backgroundImage, setBackgroundImage] = useState<any>("")
  const [formErrors, setFormErrors] = useState({})
  const [mainColour, setMainColour] = useState("#000")
  const [subColour, setSubColour] = useState("#000")
  const [selectedActions, setSelectedActions] = useState([])
  const [selectedDefaultLanguageID, setSelectedDefaultLanguageID] = useState("")
  const [selectedFilesLogo, setSelectedFilesLogo] = useState([])
  const [selectedFilesBackground, setSelectedFilesBackground] = useState([])
  const [lastUploadedLogoId, setLastUploadedLogoId] = useState("")
  const [lastUploadedLogoPath, setLastUploadedLogoPath] = useState("")
  const [lastUploadedBackgroundImageId, setLastUploadedBackgroundImageId] = useState("")
  const [lastUploadedBackgroundImagePath, setLastUploadedBackgroundImagePath] = useState("")

  const [pluginSelectorName, setPluginSelectorName] = useState("#loop-digital-wardrobe")
  const [brandDescription, setBrandDescription] = useState("")
  const [emailTemplates, setEmailTemplates] = useState([])

  const [pluginDirectionOptions, setPluginDirectionOptions] = useState(["topLeft", "topRight", "downLeft", "downRight"])
  const [pluginPositionOptions, setPluginPositionOptions] = useState(["fixed", "relative", "absolute"])
  const [pluginYOffset, setPluginYOffset] = useState("1")
  const [pluginXOffset, setPluginXOffset] = useState("1")
  const [pluginZIndex, setPluginZIndex] = useState("1")
  const [selectedPluginDirectionOption, setSelectedPluginDirectionOption] = useState("downRight")
  const [selectedPluginPositionOption, setSelectedPluginPositionOption] = useState("absolute")

  const [showDescriptionBtn, setShowDescriptionBtn] = useState(true)

  const [showWidgetBtn, setShowWidgetBtn] = useState(false)
  const [widgetBtnDirectionOptions, setWidgetBtnDirectionOptions] = useState(["topLeft", "topRight", "downLeft", "downRight"])
  const [widgetBtnPositionOptions, setWidgetBtnOptions] = useState(["fixed", "relative", "absolute"])
  const [widgetBtnYOffset, setWidgetBtnYOffset] = useState("1")
  const [widgetBtnXOffset, setWidgetBtnXOffset] = useState("1")
  const [widgetBtnZIndex, setWidgetBtnZIndex] = useState("1")
  const [selectedWidgetBtnDirectionOption, setSelectedWidgetBtnDirectionOption] = useState("downRight")
  const [selectedWidgetBtnPositionOption, setSelectedWidgetBtnPositionOption] = useState("absolute")

  const [pluginTestMode, setPluginTestMode] = useState("0")
  const [pluginFullscreenModalMode, setPluginFullscreenModalMode] = useState("1")


  const languages = useSelector((state: any) => state.Dictionaries.dictionaries.languages)
  const dashboardID = useSelector((state: any) => state?.Account?.dashbord_id)
  const userToken = useSelector((state: any) => state?.Account?.user?.access_token)
  const companyName = useSelector((state: any) => state?.Account?.user?.company_name)

  // const donateCheckbox = useRef(null);
  // const recycleCheckbox = useRef(null);
  // const resellCheckbox = useRef(null);
  // const swapCheckbox = useRef(null);
  // const returnCheckbox = useRef(null);

  // const showOpenBtnCheckbox = useRef(null)

  useEffect(() => {
    if (params?.uuid) {
      handleGetWebsite()
    }
  }, [params?.uuid])

  useEffect(() => {
    dispatch(getDictionaries())
  }, [])

  useEffect(() => {
    handleSetInitLanguage();
  }, [languages])

  const onAddWardrobeProductPhoto = (photo: any, type: string) => {
    return new Promise((resolve, reject) => {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Client-UUID": dashboardID,
          Authorization: `Bearer ${userToken}`,
        },
      }

      const formData = new FormData()

      formData.append("file", photo)
      formData.append("name", `${companyName}-${Date.now()}`)

      axios
        .post(`${API_BASE}files`, formData, config)
        .then(response => {
          if (type === "logo") {
            setLastUploadedLogoId(response?.data?.id)
            setLastUploadedLogoPath(response?.data?.path)
          } else if (type === "background") {
            setLastUploadedBackgroundImageId(response?.data?.id)
            setLastUploadedBackgroundImagePath(response?.data?.path)
          }

        })
        .catch(error => {
          if (error?.response?.data) {
            const { message, errors } = error?.response?.data

            if (message === "Unauthenticated.") {
              localStorage.setItem("authUser", "")
            }

            openAlert({ text: message, status: "danger" })
          }
          return reject(error)
        })
    })
  }

  const handleSetInitLanguage = () => {
    setSelectedDefaultLanguageID(languages?.[0]?.id)
  }

  const handleChangeLanguage = async (event: any) => {
    //after every language change download email templates for that language
    const emailResponse = await getEmailTemplates({ uuid: params?.uuid, language_id: event.target.value })
    setEmailTemplates(emailResponse);
    setSelectedDefaultLanguageID(event.target.value)
  }

  const handleChangeWidgetStatus = (event: any) => {
    setWidgetStatus(event.target.value)
  }

  const handleChangePluginPosition = (event: any) => {
    setSelectedPluginPositionOption(event.target.value)
  }

  const handleChangePluginDirection = (event: any) => {
    setSelectedPluginDirectionOption(event.target.value)
  }

  const handleChangeWidgetBtnPosition = (event: any) => {
    setSelectedWidgetBtnPositionOption(event.target.value)
  }

  const handleChangeWidgetBtnDirection = (event: any) => {
    setSelectedWidgetBtnDirectionOption(event.target.value)
  }

  //jak w GET będziemy mieli zwracany config to trzeba obsłużyć stwozenie tablicy do selectedAction
  const handleInitActionsLoad = (
    url: string,
    name: string,
    logo: string,
    image: string,
    description: string,
    config: IPluginConfigObject,
    widgetStatus: string,
    test_mode: string,
    show_description_btn: boolean
  ) => {
    const { plugin } = config;
    setPluginTestMode(test_mode)
    setPluginFullscreenModalMode(plugin?.default_widget_style && plugin?.default_widget_style === true ? "1" : "0")
    // console.log(["test", plugin?.default_widget_style && plugin?.default_widget_style === true ? "1" : "0"])
    console.log(['show_description_btn', show_description_btn]);
    setShowDescriptionBtn(show_description_btn ? true : false)

    setWidgetStatus(widgetStatus ? widgetStatus : "active")
    setUrl(url ? url.replace('https://', '') : "")
    setName(name ? name : "")
    setLogo(logo ? logo : "")
    setBackgroundImage(image ? image : "")
    setMainColour(plugin?.color_1 ? plugin.color_1 : "#000");
    setSubColour(plugin?.color_2 ? plugin.color_2 : "#000");
    setSelectedDefaultLanguageID(plugin?.default_lang ? plugin.default_lang : languages?.[0]?.id)

    setPluginYOffset(plugin?.yOffset ? plugin.yOffset : "")
    setPluginXOffset(plugin?.xOffset ? plugin.xOffset : "")
    setPluginZIndex(plugin?.zIndex ? plugin.zIndex : "")
    setSelectedPluginDirectionOption(plugin?.direction ? plugin.direction : pluginDirectionOptions?.[0])
    setSelectedPluginPositionOption(plugin?.position ? plugin.position : pluginPositionOptions?.[0])

    // setPluginSelectorName(plugin?.website_container_class ? plugin.website_container_class : "")
    setPluginSelectorName("#loop-digital-wardrobe")
    setBrandDescription(description ? description : "")
    setShowWidgetBtn(plugin?.show_widget_btn ? plugin?.show_widget_btn : false)

    setWidgetBtnYOffset(plugin?.yOffset_widget_btn ? plugin.yOffset_widget_btn : "")
    setWidgetBtnXOffset(plugin?.xOffset_widget_btn ? plugin.xOffset_widget_btn : "")
    setWidgetBtnZIndex(plugin?.zIndex_widget_btn ? plugin.zIndex_widget_btn : "")
    setSelectedWidgetBtnDirectionOption(plugin?.direction_widget_btn ? plugin.direction_widget_btn : widgetBtnDirectionOptions?.[0])
    setSelectedWidgetBtnPositionOption(plugin?.position_widget_btn ? plugin.position_widget_btn : widgetBtnPositionOptions?.[0])


    // @ts-ignore
    // showOpenBtnCheckbox.current.checked = plugin?.show_widget_btn ? plugin?.show_widget_btn : false

    // @ts-ignore
    // donateCheckbox.current.checked = true;
    // // @ts-ignore
    // recycleCheckbox.current.checked = true;
    // // @ts-ignore
    // resellCheckbox.current.checked = true;
    // // @ts-ignore
    // swapCheckbox.current.checked = true;
    // // @ts-ignore
    // returnCheckbox.current.checked = true;

    // plugin?.allow_actions.forEach(action => {
    //   handleCheckboxActionsChange(action)

    //   switch (action) {
    //     case "donate":
    //       // @ts-ignore
    //       donateCheckbox.current.checked = true;
    //       break;

    //     case "recycle":
    //       // @ts-ignore
    //       recycleCheckbox.current.checked = true;
    //       break;

    //     case "resell":
    //       // @ts-ignore
    //       resellCheckbox.current.checked = true;
    //       break;

    //     case "swap":
    //       // @ts-ignore
    //       swapCheckbox.current.checked = true;
    //       break;

    //     case "return":
    //       // @ts-ignore
    //       returnCheckbox.current.checked = true;
    //       break;
    //   }
    // })
  }

  const handleCheckboxActionsChange = (name: string) => {
    // @ts-ignore
    let valueInArray = selectedActions.includes(name);

    if (valueInArray) {
      let newSelectedActions = selectedActions.filter(value => value !== name);
      setSelectedActions(newSelectedActions)
    } else {
      let newSelectedActions = selectedActions
      // @ts-ignore
      newSelectedActions.push(name)
      setSelectedActions(newSelectedActions)
    }
  }

  const handleGetWebsite = async () => {
    // console.log(['handleGetWebsite']);
    try {
      const response = await getWebsiteDetailApi(params?.uuid)

      const { widget_status, url, name, logo, image, description, config, config: { plugin }, test_mode, show_description_btn} = response

      handleInitActionsLoad(url, name, logo, image, description, config, widget_status, test_mode, show_description_btn)

      const emailResponse = await getEmailTemplates({ uuid: params?.uuid, language_id: selectedDefaultLanguageID ? selectedDefaultLanguageID : plugin?.default_lang ? plugin.default_lang : languages?.[0]?.id })
      setEmailTemplates(emailResponse);
    } catch (e) {
      console.log(["e", e])
      dispatch(openAlert({ text: returnTranslation("An error occured"), status: "danger" }))
    }
  }

  const handleUpdateWebsite = async (widgetStatus: string, name: string, url: string, logo: string, backgroundImage: string, description: string, config: IPluginConfigObject, testMode: number | string) => {
    // console.log(['config', config]);
    return new Promise(async (resolve, reject) => {
      let configAxios = {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Client-UUID": dashboardID,
          "Authorization": `Bearer ${userToken}`,
          "Custom-User-Agent": window.navigator.userAgent
        },
      }

      // console.log(["widgetStatus", widgetStatus, name, url])

      if (
        widgetStatus &&
        name &&
        url
      ) {
        axios
          .put(`${API_BASE}sites/${params?.uuid}`, {
            widget_status: widgetStatus,
            name, url, logo,
            image: backgroundImage,
            description,
            test_mode: testMode,
            show_description_btn: showDescriptionBtn ? 1 : 0,
            config
          }, configAxios)
          .then(response => {
            if (response.data) {
              // history.push(`/plugin/integrations/configure/${params?.uuid}`)
              dispatch(openAlert({ text: returnTranslation("Integration updated"), status: "success" }))
            }

            return resolve(response)
          })
          .catch(error => {
            if (error?.response?.data) {
              const { message } = error?.response?.data;
              dispatch(openAlert({ text: message, status: "danger" }))
            }
            return reject(error)
          })
      }
      else {
        dispatch(openAlert({ text: returnTranslation("All fields required"), status: "danger" }))
      }
    })
  }

  const handleCreateWebsite = async (widgetStatus: string, name: string, url: string, logo: string, backgroundImage: string, description: string, config: IPluginConfigObject, testMode: number | string) => {
    return new Promise(async (resolve, reject) => {
      let configAxios = {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Client-UUID": dashboardID,
          "Authorization": `Bearer ${userToken}`,
          "Custom-User-Agent": window.navigator.userAgent
        },
      }

      if (
        name &&
        url
      ) {
        axios
          .post(`${API_BASE}sites`, {
            widget_status: widgetStatus,
            name, url, logo,
            image: backgroundImage,
            description,
            show_description_btn: showDescriptionBtn ? 1 : 0,
            test_mode: testMode,
            config
          }, configAxios)
          .then(response => {
            if (response.data) {
              const { site: { id } } = response.data;
              history.push(`/plugin/integrations/configure/${id}`)
              dispatch(openAlert({ text: returnTranslation("Integration added"), status: "success" }))
            }

            return resolve(response)
          })
          .catch(error => {
            if (error?.response?.data) {
              const { message, errors } = error?.response?.data;
              // console.log(["errors", errors])
              setFormErrors(errors)
              dispatch(openAlert({ text: message, status: "danger" }))
            }
            return reject(error)
          })
      }
      else {
        dispatch(openAlert({ text: returnTranslation("All fields required"), status: "danger" }))
      }
    })
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()

    let config: IPluginConfigObject = {
      "plugin": {
        "default_lang": selectedDefaultLanguageID,
        "color_1": mainColour,
        "color_2": subColour,
        "allow_actions": selectedActions,
        "yOffset": pluginYOffset,
        "xOffset": pluginXOffset,
        "zIndex": pluginZIndex,
        "show_widget_btn": showWidgetBtn,
        "direction": selectedPluginDirectionOption,
        "position": selectedPluginPositionOption,
        "website_container_class": pluginSelectorName,
        "xOffset_widget_btn": widgetBtnXOffset,
        "yOffset_widget_btn": widgetBtnYOffset,
        "zIndex_widget_btn": widgetBtnZIndex,
        "position_widget_btn": selectedWidgetBtnPositionOption,
        "direction_widget_btn": selectedWidgetBtnDirectionOption,
        //@ts-ignore
        "default_widget_style": pluginFullscreenModalMode == 1 ? true : false
      }
    }

    const isEdit = params?.uuid
    if (isEdit) {
      handleUpdateWebsite(
        widgetStatus,
        name,
        `https://${url}`,
        lastUploadedLogoId ? lastUploadedLogoId : logo?.id ? logo?.id : "",
        lastUploadedBackgroundImageId ? lastUploadedBackgroundImageId : backgroundImage?.id ? backgroundImage?.id : "",
        brandDescription,
        config,
        pluginTestMode,

      )
    } else {
      handleCreateWebsite(
        widgetStatus,
        name,
        `https://${url}`,
        lastUploadedLogoId ? lastUploadedLogoId : "",
        lastUploadedBackgroundImageId ? lastUploadedBackgroundImageId : "",
        brandDescription,
        config,
        pluginTestMode
      )
    }
  }

  const handleMainColourChange = ({ hex }: { hex: string }) => {
    setMainColour(hex)
  }

  const handleSubColourChange = ({ hex }: { hex: string }) => {
    setSubColour(hex)
  }

  const handleAcceptedFiles = (files: any, type: string) => {
    if (type === "logo") {
      setSelectedFilesLogo([])
    } else if (type === "background") {
      setSelectedFilesBackground([])
    }

    let addFiles: any = []

    files.map(async (file: any) => {
      const reader = new FileReader()

      if (file.type.match("image.*")) {
        reader.onabort = () => console.log("file reading was aborted")
        reader.onerror = () => console.log("file reading has failed")
        reader.onload = () => {
          const binaryStr = reader.result
          file = new Blob([file], { type: "image/png" })

          try {
            onAddWardrobeProductPhoto(file, type)

            Object.assign(file, {
              preview: URL.createObjectURL(file),
              formattedSize: formatBytes(file.size),
            })

            addFiles.push(file)
          } catch (err) {
            dispatch(openAlert({ text: err, status: "danger" }))
          }
        }
        reader.readAsDataURL(file)
      } else {
        dispatch(openAlert({ text: returnTranslation("Import image type"), status: "danger" }))
      }
    })

    if (type === "logo") {
      setSelectedFilesLogo(addFiles)
    } else if (type === "background") {
      setSelectedFilesBackground(addFiles)
    }
  }

  const formatBytes = (bytes: any, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>LOOP | Websites</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          title={returnTranslation("Pages")}
          breadcrumbItems={[returnTranslation("Websites"), returnTranslation("Add website")]}
        />
        <Row>
          <Col sm={12} md={12} lg={8} xl={8}>
            <Card>
              <CardBody>
                <Form
                  className="form-horizontal"
                  onSubmit={handleSubmit}
                >
                  {/* page edit */}
                  {params?.uuid &&
                    <>

                      <div className="form-group">
                        <FormInput
                          disabled={false}
                          label={returnTranslation("Name")}
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder={returnTranslation("Enter name")}
                          value={name}
                          showErrors
                          formErrors={formErrors}
                          onChange={(e: any) => setName(e.target.value)}
                        />
                      </div>
                      
                      <div className="form-group">
                        <FormGroup className="mt-3 mb-0">
                          <Label className="">
                            <div className="lh-1">{returnTranslation("Plugin Mode")}</div>
                            <div className="text-muted font-size-10">{returnTranslation("DEVELOPMENT - Choose to allow rendering plugin on any domain.")}</div>
                            {/* <div className="text-muted font-size-10">{returnTranslation("If 'On' allows to display LOOP Plugin on top of your checkout page. (absolute/centered, floating open button + close button)")}</div> */}
                            <div className="text-muted font-size-10" style={{marginTop: "-5px"}}>{returnTranslation("PRODUCTION - Choose to allow rendering plugin only on specified domain url.")}</div>
                          </Label>
                          <select
                            className="form-control select2-search-disable"
                            onChange={(e) => setPluginTestMode(e?.target?.value)}
                            value={pluginTestMode}
                          >
                            <option key={'pluginTestModeOff'} value={0}>PRODUCTION</option>
                            <option key={'pluginTestModeOn'} value={1}>DEVELOPMENT</option>
                          </select>
                        </FormGroup>
                      </div>

                      {/* <div className="form-group">
                        <FormGroup className="mt-3 mb-0">
                          <Label>{returnTranslation("Widget Status")}</Label>
                          <select
                            className="form-control select2-search-disable"
                            onChange={handleChangeWidgetStatus}
                          >
                            {widgetStatusList?.map((status, i) => {
                              if (widgetStatus == status) {
                                return (
                                  <option key={status} value={status} selected>
                                    {status}
                                  </option>
                                )
                              } else {
                                return (
                                  <option key={status} value={status}>
                                    {status}
                                  </option>
                                )
                              }
                            })}
                          </select>
                        </FormGroup>
                      </div> */}
                    </>
                  }
                  <div className="form-group">
                    <Label className="">
                          <div className="lh-1">{returnTranslation("Domain URL")}</div>
                          <div className="text-muted font-size-10">{returnTranslation("Specify the domain on which the plugin will run.")}</div>
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>https://</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        disabled={false}
                        label={returnTranslation("Domain url")}
                        type="text"
                        name="url"
                        className="form-control"
                        //@ts-ignore
                        placeholder={returnTranslation("Enter domain url")}
                        value={url}
                        showErrors
                        formErrors={formErrors}
                        onChange={(e: any) => setUrl(e.target.value?.replace("https://", ""))}
                      />
                    </InputGroup>

                    {/* <div className="col-12">
                            <Label className="visually-hidden" htmlFor="inlineFormInputGroupUsername">Username</Label>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>@</InputGroupText>
                              </InputGroupAddon>
                              <Input type="text" className="form-control" placeholder="Username" />
                            </InputGroup>
                          </div> */}
                  </div>

                  <div className="form-group mt-4">
                    <Label className="">
                      <div className="lh-1">{returnTranslation("Show LOOP Description Banner")}</div>
                      <div className="text-muted font-size-10">{returnTranslation("Check this option to display a relative LOOP banner button that opens LOOP Plugin on your checkout page.")}</div>
                    </Label>
                    <div className="mb-3">
                      <SwitchCustom
                        handleSwitchChange={() => setShowDescriptionBtn(!showDescriptionBtn)}
                        value={!!showDescriptionBtn}
                        onOffText={returnTranslation(showDescriptionBtn ? "Show" : "No")}
                        size="md"
                      />
                      {/* <input
                        className="form-check-input"
                        type="checkbox"
                        checked={showDescriptionBtn}
                        id="checkboxShowDescription"
                        onChange={() => setShowDescriptionBtn(!showDescriptionBtn)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="checkboxShowDescription"
                      >
                        {returnTranslation("Show")}
                      </label> */}
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <FormGroup className="mt-4 mb-0">
                      <Label>
                        <div className="lh-1">{returnTranslation("Fullscreen Modal Mode")}</div>
                        <div className="text-muted font-size-10">{returnTranslation("Choose 'On' to display LOOP Plugin on top of your checkout page. (absolute/centered, floating open button + close button)")}</div>
                        <div className="text-muted font-size-10" style={{marginTop: "-5px"}}>{returnTranslation("Choose 'Off' to pick position of LOOP Plugin manually.")}</div>
                      </Label>
                      {/* <select
                        className="form-control select2-search-disable"
                        onChange={(e) => {
                          setPluginFullscreenModalMode(e?.target?.value)
                          setShowWidgetBtn(false)
                        }}
                        value={pluginFullscreenModalMode}
                      >
                        <option value={0}>Off</option>
                        <option value={1}>On</option>
                      </select> */}
                      <SwitchCustom
                        handleSwitchChange={() => {
                          setPluginFullscreenModalMode(!!parseInt(pluginFullscreenModalMode) ? "0" : "1")
                          setShowWidgetBtn(false)
                        }}
                        value={!!parseInt(pluginFullscreenModalMode)}
                        onOffText={returnTranslation(!!parseInt(pluginFullscreenModalMode) ? "On" : "Off")}
                        size="md"
                      />
                    </FormGroup>
                  </div>
                  {pluginFullscreenModalMode == "0" &&
                    <>
                      <div className="form-group">
                        <FormGroup className="mt-3 mb-0">
                          <Label>{returnTranslation("Plugin Position")}</Label>
                          <select
                            className="form-control select2-search-disable"
                            onChange={handleChangePluginPosition}
                          >
                            {pluginPositionOptions?.map((position, i) => {
                              if (selectedPluginPositionOption == position) {
                                return (
                                  // @ts-ignore
                                  <option key={position} value={position} selected>
                                    {position}
                                  </option>
                                )
                              } else {
                                return (
                                  // @ts-ignore
                                  <option key={position} value={position}>
                                    {position}
                                  </option>
                                )
                              }
                            })}
                          </select>
                        </FormGroup>
                      </div>
                      <div className="form-group">
                        {/* @ts-ignore */}
                        <FormInput disabled={false}
                          label={returnTranslation("Plugin position Vertical(yOffset)")}
                          type="text"
                          name="yOffset"
                          className="form-control"
                          placeholder={returnTranslation("Enter value")}
                          value={pluginYOffset}
                          showErrors
                          formErrors={formErrors}
                          onChange={(e: any) => setPluginYOffset(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        {/* @ts-ignore */}
                        <FormInput disabled={false}
                          label={returnTranslation("Plugin position Horizontal(xOffset)")}
                          type="text"
                          name="xOffset"
                          className="form-control"
                          placeholder={returnTranslation("Enter value")}
                          value={pluginXOffset}
                          showErrors
                          formErrors={formErrors}
                          onChange={(e: any) => setPluginXOffset(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        {/* @ts-ignore */}
                        <FormInput disabled={false}
                          label={returnTranslation("Plugin position (zIndex)")}
                          type="number"
                          name="zIndex"
                          className="form-control"
                          placeholder={returnTranslation("Enter value")}
                          value={pluginZIndex}
                          showErrors
                          formErrors={formErrors}
                          onChange={(e: any) => setPluginZIndex(e.target.value)}
                        />
                      </div>

                      <div className="form-group">
                        <FormGroup className="mt-3 mb-0">
                          <Label>{returnTranslation("Plugin Direction")}</Label>
                          <select
                            className="form-control select2-search-disable"
                            onChange={handleChangePluginDirection}
                          >
                            {pluginDirectionOptions?.map((direction, i) => {
                              if (selectedPluginDirectionOption == direction) {
                                return (
                                  // @ts-ignore
                                  <option key={direction} value={direction} selected>
                                    {direction}
                                  </option>
                                )
                              } else {
                                return (
                                  // @ts-ignore
                                  <option key={direction} value={direction}>
                                    {direction}
                                  </option>
                                )
                              }
                            })}
                          </select>
                        </FormGroup>
                      </div>
                    </>
                  }
                  {/* <div className="form-group">
                    <label className="">{returnTranslation("First Colour")}</label>
                    <div className="color-picker-row">
                      <SketchPicker
                        color={mainColour}
                        onChangeComplete={handleMainColourChange}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="">{returnTranslation("Sub Colour")}</label>
                    <div className="color-picker-row">
                      <SketchPicker
                        color={subColour}
                        onChangeComplete={handleSubColourChange}
                      />
                    </div>
                  </div> */}
                  {/* <div className="form-group">
                    <label className="">{returnTranslation("Allow actions")}</label>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        ref={donateCheckbox}
                        id="checkboxDonate"
                        onChange={() => handleCheckboxActionsChange("donate")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="checkboxDonate"
                      >
                        {returnTranslation("Donate")}
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        ref={recycleCheckbox}
                        id="checkboxRecycle"
                        onChange={() => handleCheckboxActionsChange("recycle")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="checkboxRecycle"
                      >
                        {returnTranslation("Recycle")}
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        ref={resellCheckbox}
                        id="checkboxResell"
                        onChange={() => handleCheckboxActionsChange("resell")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="checkboxResell"
                      >
                        {returnTranslation("Resell")}
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        ref={swapCheckbox}
                        id="checkboxSwap"
                        onChange={() => handleCheckboxActionsChange("swap")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="checkboxSwap"
                      >
                        {returnTranslation("Swap")}
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        ref={returnCheckbox}
                        id="checkboxReturn"
                        onChange={() => handleCheckboxActionsChange("return")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="checkboxReturn"
                      >
                        {returnTranslation("Return")}
                      </label>
                    </div>
                  </div> */}

                  {/* <div className="form-group">
                    <FormGroup className="mt-3 mb-0">
                      <Label>{returnTranslation("Default Language")}</Label>
                      <select
                        className="form-control select2-search-disable"
                        onChange={handleChangeLanguage}
                      >
                        {languages?.map((language: {
                          id: string,
                          key: number
                        }, i: number) => {
                          return (
                            // @ts-ignore
                            <option key={language?.id} value={language?.id} selected={`${selectedDefaultLanguageID == language?.id && 'selected'}`}>
                              {language?.key}
                            </option>
                          )
                        })}
                      </select>
                    </FormGroup>
                  </div> */}
                  {/* <div className="form-group">
                    <FormInput disabled={true}
                      label={returnTranslation("Plugin container selector id(e.g. #myWebsitePluginContainer)")}
                      type="text"
                      name="pluginSelectorName"
                      className="form-control"
                      placeholder={returnTranslation("Enter value")}
                      value={pluginSelectorName}
                      showErrors
                      formErrors={formErrors}
                      onChange={(e: any) => setPluginSelectorName(e.target.value)}
                    />
                  </div> */}
                  {/* <div className="form-group">
                    <label className="">{returnTranslation("Logo")}</label>
                    <div>
                      <img src={lastUploadedLogoPath ? lastUploadedLogoPath : logo?.path ? logo?.path : ""} width={100} />
                    </div>
                  </div>
                  <div className="mb-3">
                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-2">{returnTranslation("New Logo")}</Label>
                      <Col lg="10">
                        <Dropzone
                          onDrop={acceptedFiles =>
                            handleAcceptedFiles(acceptedFiles, "logo")
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>{returnTranslation("Drop files here or click to upload")}</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFilesLogo.map((f: {
                            name: string,
                            preview: string,
                            formattedSize: string
                          }, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </Col>
                    </FormGroup>
                  </div> */}
                  {/* <div className="form-group">
                    <FormInput disabled={false}
                      label={returnTranslation("Brand description")}
                      type="text"
                      name="brandDescription"
                      className="form-control"
                      placeholder={returnTranslation("Enter value")}
                      value={brandDescription}
                      showErrors
                      formErrors={formErrors}
                      onChange={(e: any) => setBrandDescription(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="">{returnTranslation("Background Image")}</label>
                    <div>
                      <img src={lastUploadedBackgroundImagePath ? lastUploadedBackgroundImagePath : backgroundImage?.path ? backgroundImage?.path : ""} />
                    </div>
                  </div>
                  <div className="mb-3">
                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-2">{returnTranslation("New Background Image")}</Label>
                      <Col lg="10">
                        <Dropzone
                          onDrop={acceptedFiles =>
                            handleAcceptedFiles(acceptedFiles, "background")
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>{returnTranslation("Drop files here or click to upload")}</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFilesBackground.map((f: {
                            name: string,
                            preview: string,
                            formattedSize: string
                          }, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </Col>
                    </FormGroup>
                  </div> */}
                  {pluginFullscreenModalMode == "0" ?
                    <>
                      <div className="form-group mt-4">
                        <Label className="">
                          <div className="lh-1">{returnTranslation("Show Floating Button (Open Modal)")}</div>
                          <div className="text-muted font-size-10">{returnTranslation("Check this option to display a floating button that opens LOOP Plugin")}</div>
                        </Label>
                        <div className="mb-3">
                          {/* <input
                            className="form-check-input"
                            type="checkbox"
                            checked={showWidgetBtn}
                            // ref={showOpenBtnCheckbox}
                            id="checkboxShowOpenBtn"
                            onChange={() => setShowWidgetBtn(!showWidgetBtn)}
                          /> */}
                          <SwitchCustom
                            handleSwitchChange={() => setShowWidgetBtn(!showWidgetBtn)}
                            value={!!showWidgetBtn}
                            onOffText={returnTranslation(showWidgetBtn ? "Show" : "No")}
                            size="md"
                          />
                          {/* <label
                            className="form-check-label"
                            htmlFor="checkboxShowOpenBtn"
                          >
                            {returnTranslation("Show")}
                          </label> */}
                        </div>
                      </div>

                      {showWidgetBtn &&
                        <>
                          <div className="form-group">
                            {/* @ts-ignore */}
                            <FormInput disabled={false} widget
                              label={returnTranslation("Floating Button Position Vertical(yOffset) in pixels")}
                              type="number"
                              name="widgetBtnYOffset"
                              className="form-control"
                              placeholder={returnTranslation("Enter value")}
                              value={widgetBtnYOffset}
                              showErrors
                              formErrors={formErrors}
                              onChange={(e: any) => setWidgetBtnYOffset(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            {/* @ts-ignore */}
                            <FormInput disabled={false}
                              label={returnTranslation("Floating Button Position Horizontal(xOffset) in pixels")}
                              type="number"
                              name="widgetBtnXOffset"
                              className="form-control"
                              placeholder={returnTranslation("Enter value")}
                              value={widgetBtnXOffset}
                              showErrors
                              formErrors={formErrors}
                              onChange={(e: any) => setWidgetBtnXOffset(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            {/* @ts-ignore */}
                            <FormInput disabled={false}
                              label={returnTranslation("Floating Button Position (zIndex)")}
                              type="number"
                              name="widgetBtnZIndex"
                              className="form-control"
                              placeholder={returnTranslation("Enter value")}
                              value={widgetBtnZIndex}
                              showErrors
                              formErrors={formErrors}
                              onChange={(e: any) => setWidgetBtnZIndex(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <FormGroup className="mt-3 mb-0">
                              <Label>{returnTranslation("Floating Button Position")}</Label>
                              <select
                                className="form-control select2-search-disable"
                                onChange={handleChangeWidgetBtnPosition}
                              >
                                {widgetBtnPositionOptions?.map((position, i) => {
                                  if (selectedWidgetBtnPositionOption == position) {
                                    return (
                                      // @ts-ignore
                                      <option key={position} value={position} selected>
                                        {position}
                                      </option>
                                    )
                                  } else {
                                    return (
                                      // @ts-ignore
                                      <option key={position} value={position}>
                                        {position}
                                      </option>
                                    )
                                  }
                                })}
                              </select>
                            </FormGroup>
                          </div>
                          <div className="form-group">
                            <FormGroup className="mt-3 mb-0">
                              <Label>{returnTranslation("Plugin Direction")}</Label>
                              <select
                                className="form-control select2-search-disable"
                                onChange={handleChangeWidgetBtnDirection}
                              >
                                {widgetBtnDirectionOptions?.map((direction, i) => {
                                  if (selectedWidgetBtnDirectionOption == direction) {
                                    return (
                                      // @ts-ignore
                                      <option key={direction} value={direction} selected>
                                        {direction}
                                      </option>
                                    )
                                  } else {
                                    return (
                                      // @ts-ignore
                                      <option key={direction} value={direction}>
                                        {direction}
                                      </option>
                                    )
                                  }
                                })}
                              </select>
                            </FormGroup>
                          </div>
                        </>
                      }
                    </>
                  :null}

                  <div className="text-center mt-4">
                    <Button type="submit" color="success">
                      {params?.uuid ? returnTranslation("Update") : returnTranslation("Add website")}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* {params?.uuid && emailTemplates?.map((emailTemplate: { type: string, name: string, is_customized: boolean, id: number }) => {
          return (
            <Row key={emailTemplate.name}>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">{`${returnTranslation("Customize")} ${emailTemplate?.name ? emailTemplate?.name : emailTemplate?.type ? emailTemplate?.type : ""}`}</CardTitle>

                    <div className="text-center mt-4">
                      <Button color="success" onClick={() => {
                        history.push('/website/email/customize', {
                          uuid: params?.uuid,
                          type: emailTemplate?.type ? emailTemplate?.type : "",
                          selectedDefaultLanguageID: selectedDefaultLanguageID,
                          id: emailTemplate?.id ? emailTemplate?.id : ""
                        })
                        window.location.reload();
                      }}>
                        {returnTranslation("Update")}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )
        })} */}
      </Container>
    </div >
  )
}

export default WebsiteForm
