import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import {
    Button,
    Col, Collapse, Row
} from "reactstrap"
import EntitlementActivation, { Entitlement } from "../../../../components/EntitlementActivation/EntitlementActivation"

interface TaskOption {
    isActive: boolean;
    name: string;
    route?: string;
    onClick?: any;
    isLocked?: boolean;
    optional?: boolean;
    subtasksOpened?: boolean;
    display?: boolean;
    subtasks?: {
        isActive: boolean;
        name: string;
        route?: string;
        onClick?: any;
        isLocked?: boolean;
        optional?: boolean;
    }[];
}

export interface Props {
    header?: string;
    description?: string;
    descriptionClassName?: string;
    taskContent?: any;
    tasksOptions?: (TaskOption | null)[];
    isSubtask?: boolean;
    subtaskTitle?: string;
    isSubtaskLocked?: boolean;
    filled?: boolean;
    showFilledIcon?: boolean;
    containerClassName?: string;
    tasksOpened?: boolean;

    //entitlement activation
    entitlement?: Entitlement;
    entitlementType?: string;
    verified?: boolean;
    activateCondition?: boolean;
    returnTranslation: (text: string) => string;
    customCheckAccount?: () => void;

    customFilledIconDone?: any;
    customFilledIconUndone?: any;
}

const SingleConfigurationOption = ({
    header,
    description,
    taskContent,
    tasksOptions,
    filled,
    showFilledIcon = true,
    containerClassName,
    tasksOpened,
    descriptionClassName,
    entitlement,
    entitlementType,
    verified,
    activateCondition,
    returnTranslation,
    isSubtask,
    subtaskTitle,
    isSubtaskLocked,

    customCheckAccount,
    customFilledIconDone,
    customFilledIconUndone
}: Props) => {
    const history = useHistory();
    const [showTasks, setShowTasks] = useState(false)

    const handleShowTask = () => {
        setShowTasks(!showTasks)
    }

    useEffect(() => {
        setShowTasks(!!tasksOpened)
    }, [tasksOpened])

    return (
        <div className={containerClassName ? containerClassName : "p-4"}>
            <Row>
                <div className="d-flex align-items-center">
                    {showFilledIcon
                        ? filled
                            ? customFilledIconDone ? customFilledIconDone : <img src="/assets/images/configuration-success.png" />
                            : customFilledIconUndone ? customFilledIconUndone : <img src="/assets/images/configuration-undone.png" />
                        : null
                    }
                    {header ? <h5 className="ms-2 mb-0 pb-0">{header}</h5> : null}
                </div>
            </Row>
            <div>
                <Row>
                    <Col>
                        <div className="">
                            {description ? <p className="m-0 pt-3">{description}</p> : null}
                        </div>
                    </Col>
                </Row>

                {entitlementType && returnTranslation ? (
                    <Row>
                        <Col>
                            {/* <p className="mt-3 mb-1" style={{ fontWeight: 'bold' }}>{returnTranslation("Status")}</p> */}
                            <EntitlementActivation
                                entitlement={entitlement}
                                entitlementType={entitlementType}
                                verified={verified ? true : false}
                                activateCondition={activateCondition ? true : false}
                                returnTranslation={returnTranslation}
                                handleShowTasks={!showTasks ? handleShowTask : undefined}
                                customCheckAccount={customCheckAccount}
                            />
                        </Col>
                    </Row>
                ) : null}

                <Row>
                    <Col sm="12" md="12" lg="12">
                        <ul className={`list-unstyled categories-list mb-0 ${isSubtask ? "p-0" : ""}`}>
                            <li>
                                <div className="custom-accordion">
                                    {isSubtask ? (
                                        <Button color={filled === false ? "light" : "success"}
                                            onClick={() => handleShowTask()}
                                            className="btn--subtask"
                                        >
                                            <div className="d-flex gap-1 align-items-center">
                                                <span className="me-auto">{subtaskTitle}</span>
                                                {/* {taskOption?.optional ? <small className="me-auto text-muted">(optional)</small> : null} */}
                                            </div>
                                            {isSubtaskLocked
                                                ? <i className="bx bx-lock-alt" />
                                                : filled === false
                                                    ? <i className="bx bx-circle" />
                                                    : <i className="bx bx-check-circle" />
                                            }
                                            {/* <i className={
                                                showTasks
                                                    ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                                                    : "mdi mdi-chevron-down accor-down-icon ms-auto"
                                                }
                                            /> */}
                                        </Button>
                                    ) : (
                                        <Link
                                            className="text-body fw-medium py-1 d-flex align-items-center"
                                            onClick={() => handleShowTask()}
                                            to="#"
                                        >
                                            {/* <i className="mdi mdi-folder font-size-16 text-warning me-2"></i>{" "} */}
                                            {returnTranslation("View Tasks")}{" "}
                                            {/* <i className="mdi mdi-chevron-up accor-down-icon ms-auto"></i> */}
                                            <i
                                                className={
                                                    showTasks
                                                        ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                                                        : "mdi mdi-chevron-down accor-down-icon ms-auto"
                                                }
                                            />
                                        </Link>
                                    )}

                                    <Collapse isOpen={showTasks} className={isSubtask ? "collapse--subtaks" : ""}>
                                        {taskContent && taskContent()}
                                        {tasksOptions ? (
                                            <div className="card border-0 shadow-none mb-0">
                                                <ul style={{ listStyleType: "none" }} className="p-0">
                                                    {tasksOptions?.filter(x => !!x && (x?.display !== false))?.map(taskOption => (
                                                        <li key={taskOption?.name} className="p-0">
                                                            {taskOption?.subtasks ? (
                                                                <SingleConfigurationOption
                                                                    returnTranslation={returnTranslation}
                                                                    subtaskTitle={taskOption?.name}
                                                                    filled={taskOption?.isActive
                                                                        // taskOption?.subtasks?.some(x => x?.isActive)
                                                                    }
                                                                    showFilledIcon={false}
                                                                    tasksOpened={taskOption?.subtasksOpened}
                                                                    containerClassName="p-0"
                                                                    tasksOptions={taskOption?.subtasks}
                                                                    isSubtask={true}
                                                                    isSubtaskLocked={taskOption?.subtasks?.some(x => x?.isLocked)}
                                                                />
                                                            ) : (
                                                                <Link
                                                                    to={taskOption?.route ? (
                                                                        {
                                                                            pathname: taskOption?.route,
                                                                            state: { from: history?.location?.pathname }
                                                                        }
                                                                    ) : "#"}
                                                                    onClick={taskOption?.onClick ? taskOption?.onClick : null}
                                                                    className={`d-flex align-items-center p-0 ${taskOption?.isLocked ? "unclickable disabled" : ""}`}
                                                                >
                                                                    <Button color={taskOption?.isActive === false ? "light" : "success"} className={isSubtask ? "btn--subtask__task" : ""}>
                                                                        <div className="d-flex gap-1 align-items-center">
                                                                            <span className="me-auto">{taskOption?.name}</span>
                                                                            {taskOption?.optional ? <small className="me-auto text-muted">(optional)</small> : null}
                                                                        </div>
                                                                        {taskOption?.isLocked
                                                                            ? <i className="bx bx-lock-alt" />
                                                                            : taskOption?.isActive === false
                                                                                ? <i className="bx bx-circle" />
                                                                                : <i className="bx bx-check-circle" />
                                                                        }
                                                                    </Button>
                                                                </Link>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ) : null}
                                    </Collapse>
                                </div>
                            </li>

                        </ul>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default SingleConfigurationOption;