import React, { useEffect, useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { withTranslation } from 'react-i18next';
import SelectSearch, { SelectedOption, SelectedOptionValue, SelectSearchOption, SelectSearchProps } from 'react-select-search';
import { FormFeedback, FormGroup, Input, Label, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';
import PhoneInput from 'react-phone-input-2'

export interface IFormInputProps {
    label?: string;
    name: string;
    type: string;
    className: string;
    placeholder?: string;
    value?: string | number;
    formErrors?: any;
    showErrors?: boolean;
    onChange?: (value: any) => void;
    onBlur?: (event: any) => void;
    options?: any;
    disabled?: boolean;
    rows?: number;
    min?: number | string;
    max?: number | string;
    checked?: boolean;
    id?: string;
    floatingLabel?: string;
    defaultValue?: any;
    required?: boolean;
    wrapperClass?: string;
    countries?: any[];
    cities?: any[];
    addonType?: "prepend" | "append";
    addonText?: string;
    step?: string;
    submitted?: boolean;
    onFocus?: (event: any) => void;
}

const FormInput: React.FC<IFormInputProps> = ({
    label,
    name,
    type,
    className,
    placeholder,
    value,
    formErrors,
    showErrors,
    onChange,
    options,
    disabled,
    rows,
    min,
    max,
    checked,
    id,
    floatingLabel,
    defaultValue,
    required,
    wrapperClass,
    countries,
    onBlur,
    cities,
    addonType,
    addonText,
    step,
    submitted,
    onFocus
}) => {
    const [allowRender, setAllowRender] = useState(true);

    useEffect(() => {
        setAllowRender(false)
    }, [defaultValue])

    useEffect(() => {
        if(!allowRender){
            setAllowRender(true)
        }
    }, [allowRender])

    const filterCities = (phrase: any): Promise<SelectSearchOption[]> => {
        //@ts-ignore
        return cities?.filter((city: any) => city?.value.toLowerCase().includes(phrase?.toLowerCase())).splice(0, 20)
    }

    return (
        allowRender ?
            <FormGroup className={wrapperClass ? wrapperClass : ""} >
                <div className={floatingLabel ? "position-relative" : ""}>
                    {label && type !== 'checkbox' ? <Label>{label}</Label> : null}
                    {floatingLabel ?  <span className="floating-label">{floatingLabel}</span> : null}

                    {type === 'select' ? (
                        <Input
                            onBlur={onBlur}
                            id={id ? id : `${name}-${type}`}
                            type={type}
                            name={name}
                            className={`${className} ${value === undefined || value === null ? 'text-muted' : ''}`}
                            placeholder={placeholder}
                            value={value && value}
                            invalid={!!formErrors?.[name]}
                            onChange={onChange && onChange}
                            defaultValue={defaultValue}
                            disabled={disabled}
                            required={required}
                        >
                            <option value="" className="text-muted">
                                {placeholder}
                            </option>
                            {options}
                        </Input>
                    ) : type === 'country' && countries ? (
                        <>
                            <Input
                                onBlur={onBlur}
                                style={{ display: "none" }}
                                name={name}
                                className="form-control"
                                placeholder={""}
                                type="text"
                                value={value}
                                // invalid={formErrors?.[name]}
                                disabled={disabled}
                            />
                            <div onClick={() => {
                                setTimeout(() => {
                                    const inputElem = document.querySelector(".ReactFlagsSelect-module_flagsSelect__2pfa2 ul input");
                                    //@ts-ignore
                                    inputElem?.focus()
                                }, 300)
                            }}>
                                <ReactFlagsSelect
                                    className={`single-setting-panel--option__input single-setting-panel--option__input--flag-select ${value ? "has-value": ""}`}
                                    selected={value ? value?.toString() : ""}
                                    onSelect={(code: string) => {
                                        onChange && onChange(code)
                                        // setCity({})
                                        // setPostcode("")
                                        // handleUpdateCity(postcode, code)
                                    }}
                                    searchable={true}
                                    countries={countries}
                                    selectButtonClassName="flag-dropdown-btn"
                                    disabled={disabled}
                                />
                            </div>
                        </>
                    ) : type === 'city' && cities ? (
                        <>
                            <Input
                                onBlur={onBlur}
                                style={{ display: "none" }}
                                name={name}
                                className="form-control"
                                placeholder={""}
                                type="text"
                                value={value}
                                // invalid={formErrors?.[name]}
                                disabled={disabled}
                            />
                            <div className="mb-3">
                                <div className={`w-100 ${value ? "value-selected" : ""} `}>
                                    <SelectSearch
                                        className={`select-search ${formErrors?.[name] ? "is-invalid" : ""}`}
                                        options={cities.splice(0, 20)}
                                        search
                                        debounce={600}
                                        getOptions={filterCities}
                                        emptyMessage={"No results found"}
                                        placeholder={value ? value?.toString() : "Select City"}
                                        onChange={(selectedValue: SelectedOptionValue | SelectedOptionValue[], selectedOption: SelectedOption | SelectedOption[], optionSnapshot: SelectSearchProps) => {
                                            //@ts-ignore
                                            onChange && onChange({ id: selectedOption?.id, name: selectedOption?.value })
                                        }}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </>
                    ) : type === 'checkbox' ? (
                        <div className={`d-flex align-items-center ${disabled ? "unclickable disabled" : "click-hover"}`}
                            onClick={(onChange && !disabled) ? onChange : undefined}
                        >
                            <Input
                                // disabled={disabled}
                                id={`${name}-${type}`}
                                // @ts-ignore
                                type={type}
                                name={name}
                                className={className}
                                value={value ? value : undefined}
                                invalid={!!formErrors?.[name]}
                                onChange={onChange && onChange}
                                checked={checked}
                                required={required}
                            />
                            {label ? (
                                <Label className="mb-0 click-hover" >
                                    {label}
                                </Label>
                            ): null}
                        </div>
                    ): type === 'phone' ? (
                        // <div className={`d-flex align-items-center ${disabled ? "unclickable disabled" : "click-hover"}`}
                        //     onClick={(onChange && !disabled) ? onChange : undefined}
                        // >
                        //     <Input
                        //         // disabled={disabled}
                        //         id={`${name}-${type}`}
                        //         // @ts-ignore
                        //         type={type}
                        //         name={name}
                        //         className={className}
                        //         value={value && value}
                        //         invalid={!!formErrors?.[name]}
                        //         onChange={onChange && onChange}
                        //         checked={checked}
                        //         required={required}
                        //     />
                        //     {label ? (
                        //         <Label className="mb-0 click-hover" >
                        //             {label}
                        //         </Label>
                        //     ): null}
                        // </div>
                        <PhoneInput
                            country={'gb'}
                            onlyCountries={['gb','us']}
                            countryCodeEditable={false}
                            //@ts-ignore
                            value={value ? value : undefined}
                            onChange={onChange && onChange}
                            containerClass={formErrors?.phone ? "is-invalid" : ""}
                            disableDropdown={false}
                            placeholder={placeholder}
                        />
                    ) : (
                        <InputGroup>
                            {addonType && addonText ? (
                                <InputGroupAddon addonType={addonType}>
                                    <InputGroupText>{addonText}</InputGroupText>
                                </InputGroupAddon>
                            ): null}
                            <Input
                                onBlur={onBlur}
                                onFocus={onFocus && onFocus}
                                disabled={disabled}
                                id={`${name}-${type}`}
                                // @ts-ignore
                                type={type}
                                name={name}
                                className={`${className} ${submitted ? " submitted" : ""}`}
                                placeholder={placeholder}
                                invalid={!!formErrors?.[name]}
                                value={value ? value : undefined}
                                onChange={onChange && onChange}
                                // value={innerValue}
                                // onChange={(e: any) => setInnerValue(e?.target?.value)}
                                rows={rows}
                                style={{ resize: 'none' }}
                                min={min}
                                max={max}
                                checked={checked}
                                defaultValue={defaultValue}
                                required={required}
                                step={step}
                            />
                            {required ? (
                                <div className="required-error">{label} is required.</div>
                            ): null}
                        </InputGroup>
                    )}

                    {showErrors &&
                        formErrors &&
                        formErrors[name] &&
                        formErrors[name].length &&
                        formErrors[name].map((error: string) => (
                            <FormFeedback key={error}>{error}</FormFeedback>
                        ))
                    }
                </div>
            </FormGroup>
        : null
    );
};

export default withTranslation()(FormInput);
