//Include Both Helper File with needed methods
import { getWebsitesApi } from "./../../helpers/backend_helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { getWebsitesFail, getWebsitesSuccess } from "./actions"
// Ecommerce Redux States
import { GET_WEBSITES } from "./actionTypes"

function* getWebsitesAsync({ payload }) {
  // console.log(["getWebsitesAsync", payload])
  try {
    const response = yield call(getWebsitesApi, payload)
    yield put(getWebsitesSuccess(response))
  } catch (error) {
    yield put(getWebsitesFail(error))
  }
}

function* websiteSaga() {
  yield takeEvery(GET_WEBSITES, getWebsitesAsync)
}

export default websiteSaga
