import {
  GET_CURRENCIES,
  GET_CURRENCIES_FAIL,
  GET_CURRENCIES_SUCCESS
} from "./actionTypes"

export const getCurrencies = () => ({
  type: GET_CURRENCIES,
})

export const getCurrenciesSuccess = currencies => ({
  type: GET_CURRENCIES_SUCCESS,
  payload: currencies,
})

export const getCurrenciesFail = error => ({
  type: GET_CURRENCIES_FAIL,
  payload: error,
})
