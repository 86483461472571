import { takeEvery, put, call } from "redux-saga/effects"
import {
  GET_CURRENCIES
} from "./actionTypes"
import {
  getCurrenciesSuccess,
  getCurrenciesFail
} from "./actions"
import {
  getCurrencies
} from "../../helpers/backend_helper"

function* getCurrenciesAsync() {
  try {
    const response = yield call(getCurrencies)
    yield put(getCurrenciesSuccess(response))
  } catch (error) {
    yield put(getCurrenciesFail(error))
  }
}

function* currenciesSaga() {
  yield takeEvery(GET_CURRENCIES, getCurrenciesAsync)
}

export default currenciesSaga