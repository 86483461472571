// @ts-ignore
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
// @ts-ignore
import MetaTags from 'react-meta-tags'
import 'react-phone-input-2/lib/high-res.css'
// @ts-ignore
import { connect, useDispatch } from "react-redux"
// @ts-ignore
import { RouteComponentProps } from "react-router"
// @ts-ignore
import { Link } from "react-router-dom"
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, Row
} from "reactstrap"
import { get, post } from "../../../../helpers/api_helper"
import * as url from "../../../../helpers/url_helper"
import {
    apiError, changePreloader, registerUserFailed,
    registerUserSuccessful
} from "../../../../store/actions"
import { openAlert } from "../../../../store/alert/actions"
import RegisterSocialsForm from "./RegisterSocialsForm"

export interface RegisterSocialsPropsInterface extends RouteComponentProps {
    dashbord_id: string,
    registerUserSuccessful: (userObj: any) => {},
    apiError: (err: string) => {},
    history: any,
    location: any,
    error: string,
    onOpenAlert: ({ }) => {},
    registerUserFailed: (status: string) => {},
    t: any,
    onGetCountries: any,
    countries: any,
    user: any,
    registrationError: string,
    onChangePreloader: (value: boolean) => {}
}

export interface RegisterSocialsStateInterface {
    isCharity: boolean,
    // countries: any[],
    selectedCountryCode: string,
    formErrors: any,
    selectedCountry: string,
    countryCodes: any,
    cities: any,
    selectedCityId: any,
    city: string,
    phone: string;
    lat: string;
    lng: string;
}

const RegisterSocials = ({
    apiError,
    registerUserFailed,
    onGetCountries,
    location,
    onOpenAlert,
    history,
    onChangePreloader,
    registerUserSuccessful,
    registrationError,
    user,
    countries,
    t
}: RegisterSocialsPropsInterface) => {
    const dispatch = useDispatch();
    const [formErrors, setFormErrors] = useState<any>({});
    const [stepData, setStepData] = useState<any>({});

    useEffect(() => {
        getStepData(6)
        apiError("")
        registerUserFailed("")
    }, [])

    const handleValidSubmit = async (data: any) => {
        console.log(["data", data]);
        try{
            onChangePreloader(true)

            const response = await post(`${url.API_BASE}dashboard/register/6`, data)
            if (response?.status) {
                // registerUserSuccessful(response?.user)
                // onOpenAlert({
                //     text: response?.message,
                //     status: "success",
                // })
                history.push("/register-plans", {
                    code: location?.state?.code,
                    email: location?.state?.email,
                    role: location?.state?.role,
                    password: location?.state?.password
                })
            }
        }catch(error){
            //@ts-ignore
            if (error?.response?.data) {
                //@ts-ignore
                const { message, errors } = error?.response?.data;
                setFormErrors(errors);
                onOpenAlert({
                    text: message,
                    status: "danger",
                })
            }
        }finally{
            onChangePreloader(false)
        }
    }

    const getStepData = async (step: number) => {
        if(!location?.state?.code){
            return;
        }
        try{
            onChangePreloader(true)

            const response = await get(`${url.API_BASE}dashboard/register/step?code=${location?.state?.code}&step=${step}`)
            setStepData(response?.data)
        }catch(e){

        }finally{
            onChangePreloader(false)
        }
    }

    return (
        <>
            <MetaTags>
                <title>LOOP | Register Social Media</title>
            </MetaTags>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="bx bx-home h2" />
                </Link>
            </div>
            <div className="account-pages my-2 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                         <Col md={8} lg={7} xl={6}>
                            <Card className="overflow-hidden">
                                <CardHeader>
                                    <i className="fas fa-chevron-left font-size-20 text-black clickable pt-1 ps-1"
                                        onClick={() => history?.push("/register-details", location?.state)}
                                    />
                                </CardHeader>
                              
                                <div className="p-4 d-flex align-items-center gap-3">
                                    <h1 className="m-0">{t("Social Media")}</h1>
                                </div>
                                <CardBody className="pt-0">
                                    <div className="p-2">
                                        {user && user ? (
                                            <Alert color="success">
                                                Register User Successfully
                                            </Alert>
                                        ) : null}
                                        {registrationError &&
                                            registrationError ? (
                                            <Alert color="danger">
                                                {registrationError}
                                            </Alert>
                                        ) : null}

                                        <RegisterSocialsForm 
                                            handleSubmit={(data: any) => handleValidSubmit(data)}
                                            stepData={stepData}
                                            submitBtnText={"Next"}
                                        />
                                       

                                        <div className="mt-3 text-center">
                                            <p>
                                                <span className="auth-agreement-text">Already have an account?</span>{" "}
                                                <Link to="/login" className="fw-medium auth-agreement-link">
                                                    {" "}
                                                    Log in
                                                </Link>{" "}
                                            </p>
                                        </div>

                                        <div className="mt-4 text-center">
                                            <p className="mb-0 auth-agreement-text">
                                                By continuing you agree to our <a href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/" target="_blank" className="text-primary">
                                                    Terms & Conditions
                                                </a>. LOOP services are
                                                subject to our <a href="https://loopdigitalwardrobe.com/privacy-policy/" target="_blank" className="text-primary">
                                                    Privacy Policy
                                                </a>.
                                            </p>
                                        </div>

                                        <div className="mt-4 text-center">
                                            <p className="mb-0 auth-agreement-text">
                                                Need our help? <a href="mailto:help@loopdigitalwardrobe.com" className="fw-medium contact-us-text">
                                                    Contact us.
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>

                            <div className="mt-5 text-center">
                                <p className="auth-agreement-text">
                                    ©{new Date().getFullYear()} All Rights Reserved. LOOP DIGITAL WARDROBE® is a registered trademark of LOOP DIGITAL WARDROBE®.{" "}
                                    <a href="https://loopdigitalwardrobe.com/privacy-policy/" target="_blank" className="fw-medium text-primary">
                                        Privacy Policy
                                    </a> and{" "}
                                    <a href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/" target="_blank" className="fw-medium text-primary">
                                        Terms & Conditions
                                    </a>
                                    .
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        </ >
    )
}

const mapStateToProps = (state: any) => {
    const { user, registrationError, loading, dashbord_id } = state.Account
    const { countries } = state.Countries
    return { user, registrationError, loading, dashbord_id, countries }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        apiError: (message: string) => dispatch(apiError(message)),
        registerUserFailed: (message: string) => dispatch(registerUserFailed(message)),
        registerUserSuccessful: (user: any) => dispatch(registerUserSuccessful(user)),
        onGetCountries: () => dispatch({ type: "GET_COUNTRIES" }),
        onOpenAlert: ({ text, status }: any) =>
            dispatch(openAlert({ text: text, status: status })),
        onChangePreloader: (value: boolean) => dispatch(changePreloader(value))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(RegisterSocials))
