// @ts-ignore
import { capitalize } from "lodash"
import React, { FormEvent, useEffect, useState } from "react"
import { Collapse } from 'react-collapse'
import { withTranslation } from "react-i18next"
import 'react-phone-input-2/lib/high-res.css'
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
// @ts-ignore
import { useLocation } from "react-router"
import { Form, Row } from "reactstrap"
import FormInput from "../../../../components/Common/FormInput"
import CustomGoogleMaps, { LatLng } from "../../../../components/CustomGoogleMaps/CustomGoogleMaps"
import { generateCoordinates, getCity, getSchoolStages, getSchoolTypes } from "../../../../helpers/backend_helper"
import { openAlert } from "../../../../store/alert/actions"

interface OpeningHour {
    "dayOfWeek": string;
    "day": number;
    "from": string;
    "until": string;
    "active": boolean;
}

export interface RegisterSubmitData {
    code?: string,
    company_name: string,
    charity_number: string,
    vatin: string,
    phone: string,
    email: string,
    country: string,
    street: string,
    postcode: string,
    city_name: string,
    lat: number | undefined,
    lng: number | undefined,
    opening_hours: OpeningHour[] | null,
    school_type_id: string | null,
    school_stage_id: string | null,
}

export interface RegisterDetailsPropsInterface {
    t: any,
    role: string,
    submitBtnText: string,
    handleSubmit: (data: RegisterSubmitData) => void,
    stepData?: RegisterSubmitData,
    formErrors: any,
    checkStepDataAsync?: boolean
}

const RegisterDetails = ({
    t,
    role,
    submitBtnText,
    handleSubmit,
    stepData,
    formErrors,
    checkStepDataAsync
}: RegisterDetailsPropsInterface) => {
    // const { user, registrationError, loading, dashbord_id } = useSelector((state: any) => state?.Account)
    const { countries } = useSelector((state: any) => state?.Countries)

    const onGetCountries = () => dispatch({ type: "GET_COUNTRIES" });
    const onOpenAlert = ({ text, status }: any) => dispatch(openAlert({ text: text, status: status }));

    const location: any = useLocation();
    const dispatch = useDispatch();
    const [phone, setPhone] = useState("");
    // const [selectedCountry, setSelectedCountry] = useState("UK");

    const [foundAddress, setFoundAddress] = useState('')

    const [country_id, setCountry_id] = useState("1")
    const [countryCode, setCountryCode] = useState("GB");
    const [street, setStreet] = useState("")
    const [city, setCity] = useState("")
    const [postcode, setPostcode] = useState("")

    const [schoolTypeId, setSchoolTypeId] = useState("")
    const [schoolStageId, setSchoolStageId] = useState("")

    const [lat, setLat] = useState<number>()
    const [lng, setLng] = useState<number>()

    const [isOpenOpeningHours, setIsOpenOpeningHours] = useState(false);

    const [schoolTypes, setSchoolTypes] = useState([]);
    const [schoolStages, setSchoolStages] = useState([]);

    const [formSubmitted, setFormSubmitted] = useState(false);

    const [opening_hours, setOpening_hours] = useState<OpeningHour[]>([
        { "dayOfWeek": "Monday", "day": 1, "from": "10:00", "until": "20:00", "active": true },
        { "dayOfWeek": "Tuesday", "day": 2, "from": "10:00", "until": "20:00", "active": true },
        { "dayOfWeek": "Wednesday", "day": 3, "from": "10:00", "until": "20:00", "active": true },
        { "dayOfWeek": "Thursday", "day": 4, "from": "10:00", "until": "20:00", "active": true },
        { "dayOfWeek": "Friday", "day": 5, "from": "10:00", "until": "20:00", "active": true },
        { "dayOfWeek": "Saturday", "day": 6, "from": "10:00", "until": "20:00", "active": true },
        { "dayOfWeek": "Sunday", "day": 7, "from": "10:00", "until": "20:00", "active": false }
    ]);


    useEffect(() => {
        if (!countries?.length) {
            onGetCountries()
        }

        if (role?.toLowerCase() === "school") {
            handleGetSchoolTypes()
            handleGetSchoolStages();
        }
    }, [])

    const handleGetSchoolTypes = async () => {
        try {
            const response = await getSchoolTypes();
            setSchoolTypes(response)
        } catch (e) {

        }
    }

    const handleGetSchoolStages = async () => {
        try {
            const response = await getSchoolStages();
            setSchoolStages(response)
        } catch (e) {

        }
    }

    const handleValidSubmit = async (event: FormEvent) => {
        event.preventDefault()

        return new Promise(async (resolve, reject) => {

            //@ts-ignore
            const formData = new FormData(event?.target);
            const values: any = {};
            Array.from(formData.entries())?.map(([key, value]) => {
                values[key] = value
            });

            const {
                // first_name,
                // last_name,
                company_name,
                vatin,
                charity_number,
                email,
                // address,
                // postcode,
                // city_name
            } = values;

            if (!lat && !lng) {
                return onOpenAlert({
                    text: t("Drop a pin on the map to set coordinates."),
                    status: "danger",
                })
            }

            const data: RegisterSubmitData = {
                code: location?.state?.code,
                company_name,
                charity_number,
                vatin: vatin,
                phone,
                email,
                country: countryCode,
                street,
                postcode,
                city_name: city,
                lat,
                lng,
                opening_hours: isOpenOpeningHours ? opening_hours : null,
                school_type_id: schoolTypeId ?? null,
                school_stage_id: schoolStageId ?? null,
            };

            handleSubmit(data)
        })
    }

    const handleUpdateCity = async (postcode: string, countryCode: any) => {
        if (postcode && countryCode) {
            try {
                const cityObject = await handleGetCity(postcode, countryCode)
                if (cityObject?.name) {
                    setCity(cityObject?.name)
                    generateCoords({ city: cityObject?.name })
                }
            } catch (e) {
                // console.log(e)
            }
        } else {
            generateCoords()
        }
    }

    const handleGetCity = async (postcode: string, countryCode: any): Promise<{ id: number, name: string }> => {
        return new Promise(async (resolve, reject) => {
            // setIsLoadingCity(true)
            try {
                const cityObject = await getCity(postcode, countryCode)
                onOpenAlert({
                    text: t(`Found`) + ` ${cityObject.name}.`,
                    status: "success",
                })
                resolve(cityObject)
            } catch (error) {
                // console.log(['error', error]);
                //@ts-ignore
                const { message } = error?.response?.data;
                // onOpenAlert({
                //     text: message,
                //     status: "danger",
                // })
                reject(error)
            } finally {
                // setIsLoadingCity(false)
            }
        })
    }

    const getFullAddress = (customData?: { city?: string, street?: string, postcode?: string, country_id?: string }): string => {
        const finalStreet = customData?.street ? (customData?.street ? customData?.street : "") : (street ? street : "");
        const finalPostcode = customData?.postcode ? (customData?.postcode ? customData?.postcode : "") : (postcode ? postcode : "");
        const finalCity = customData?.city ? (customData?.city ? customData?.city : "") : (city ? city : "");
        const finalCountryId = customData?.country_id ? (customData?.country_id ? customData?.country_id : "") : (country_id ? country_id : "");

        if (!((finalStreet || finalPostcode || finalCity || finalCountryId) && countries?.length))
            return '';

        const country = countries?.find((country: any) => country.id === finalCountryId);
        const fullAddress = [finalStreet, `${finalPostcode} ${finalCity}`, country?.name]
            ?.filter(value => !!value?.trim())
            ?.join(", ")

        return fullAddress ? fullAddress : '';
    }

    const generateCoords = async (customData?: { city?: string, street?: string, postcode?: string, country_id?: string }) => {
        const fullAddress = getFullAddress(customData);
        if (!fullAddress) return;

        try {
            const response = await generateCoordinates(fullAddress);

            const { address, location: { lat, lng } } = response;

            setFoundAddress(address)
            setLat(lat)
            setLng(lng)
            if (!city) {
                setCity(response?.city)
            }
        } catch (error) {
            // console.log(error)
            dispatch(openAlert({ text: t("Problem with address"), status: 'danger' }))
        }
    }

    const handleChangeCountry = (value: string | number, allowToUpdateCity = true) => {
        const country = countries?.find((country: any) => country?.code === value || country?.id === value || country?.name === value)
        setCountryCode(country?.code)
        setCountry_id(country?.id)

        if (country?.code && postcode && allowToUpdateCity)
            handleUpdateCity(postcode, country?.code)

        return country
    }

    const handleTimeChange = (time: string, day: number, type: string) => {
        const openingHoursCopy = opening_hours;
        const dayIndex = opening_hours.findIndex(singleDay => singleDay.day === day);
        const newDayObject = openingHoursCopy[dayIndex];

        if (type === "from") {
            newDayObject.from = time
        } else if (type === "until") {
            newDayObject.until = time
        }

        setOpening_hours(openingHoursCopy)
    }


    const handleSetActiveDay = (dayName: string, value: boolean) => {
        setOpening_hours((prev) => {
            return prev?.map(item => {
                if (item.dayOfWeek === dayName) {
                    item.active = value;
                }
                return item;
            })

        })
    }

    useEffect(() => {
        // console.log(["useEffect stepData", stepData])
        if (stepData) {
            setPhone(stepData?.phone)
            setPostcode(stepData?.postcode)
            setStreet(stepData?.street)
            setCity(stepData?.city_name)
            setLat(stepData?.lat ? parseFloat(stepData?.lat?.toString()) : undefined)
            setLng(stepData?.lng ? parseFloat(stepData?.lng?.toString()) : undefined)
            setSchoolTypeId(stepData?.school_type_id ?? "")
            setSchoolStageId(stepData?.school_stage_id ?? "")
            setCountryCode(stepData?.country)

            setIsOpenOpeningHours(!!stepData?.opening_hours)
            if (stepData?.opening_hours) {
                const mappedDayNames = stepData?.opening_hours?.map((x: any) => {
                    x.dayOfWeek = opening_hours?.find((y: OpeningHour) => y.day === x.day)?.dayOfWeek;
                    return x;
                })
                setOpening_hours(mappedDayNames)
            }
        }
    }, checkStepDataAsync ? [stepData] : [])

    return (
        <>

            <Form
                className="form-horizontal"
                // onValidSubmit={handleValidSubmit}
                onSubmit={handleValidSubmit}
            >
                <div className="mb-3">
                    <FormInput
                        name="company_name"
                        label="Company Name"
                        className="form-control"
                        placeholder="Enter company name"
                        type="text"
                        required
                        showErrors
                        formErrors={formErrors}
                        defaultValue={stepData?.["company_name"]}
                        submitted={formSubmitted}
                    />
                </div>

                {role?.toLowerCase() === "school" &&
                    <>
                        <div className="mb-3">
                            {/* @ts-ignore */}
                            <FormInput disabled={false}
                                label={t("Type of school")}
                                type="select"
                                name="school_type_id"
                                className="form-control"
                                placeholder={t("Choose")}
                                showErrors
                                formErrors={formErrors}
                                onChange={(e) => setSchoolTypeId(e?.target?.value)}
                                value={schoolTypeId}
                                options={schoolTypes?.map((type: any) => (
                                    <option key={type?.id} value={type?.id}>{type?.name}</option>
                                ))}
                            />
                        </div>

                        <div className="mb-3">
                            <FormInput
                                label={t("Educational stage")}
                                type="select"
                                name="school_stage_id"
                                className="form-control"
                                placeholder={t("Choose")}
                                showErrors
                                formErrors={formErrors}
                                onChange={(e) => setSchoolStageId(e?.target?.value)}
                                value={schoolStageId}
                                options={schoolStages?.map((type: any) => (
                                    <option key={type?.id} value={type?.id}>{type?.name}</option>
                                ))}
                            />
                        </div>
                    </>
                }

                <div className="mb-3">
                    <FormInput
                        name="vatin"
                        label="VAT ID"
                        className="form-control"
                        placeholder="Enter VAT ID"
                        type="text"
                        // required
                        showErrors
                        formErrors={formErrors}
                        defaultValue={stepData?.["vatin"]}
                    // validate={{
                    //     pattern: {
                    //         value: new RegExp(/((^GB[ ]?)([0-9][ ]{0,1}){9}$)|(^([0-9][ ]{0,1}){9}$)/),
                    //         errorMessage: 'VAT ID must be 9 digits / GB followed by 9 digits.'
                    //     },
                    //     // minLength: {value: 9, errorMessage: 'VAT ID must be exactly 9 digits.'},
                    //     // maxLength: {value: 9, errorMessage: 'VAT ID must be exactly 9 digits.'}
                    // }}
                    />
                </div>

                {role?.toLowerCase() === "charity" &&
                    <div className="mb-3">
                        <FormInput
                            name="charity_number"
                            label="Charity Number"
                            className="form-control"
                            placeholder="Enter charity number"
                            type="text"
                            // required
                            showErrors
                            formErrors={formErrors}
                            defaultValue={stepData?.["charity_number"]}
                        // validate={{
                        //     pattern: { value: '^[0-9]+$', errorMessage: 'Only digits are allowed.' },
                        //     minLength: { value: 6, errorMessage: 'Charity Number must be between 6 and 7 digits.' },
                        //     maxLength: { value: 7, errorMessage: 'Charity Number must be between 6 and 7 digits.' }
                        // }}
                        />
                    </div>
                }

                {/* <div className="row">
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <AvField
                                name="first_name"
                                label="First Name"
                                className="form-control"
                                placeholder="Enter first name"
                                type="text"
                                required
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <AvField
                                name="last_name"
                                label="Last Name"
                                className="form-control"
                                placeholder="Enter last name"
                                type="text"
                                required
                            />
                        </div>
                    </div>
                </div> */}

                <div className="mb-3">
                    <div className="form-group">
                        <label>Company Phone</label>
                        <FormInput
                            className=""
                            name="phone"
                            type="phone"
                            placeholder="+44 7987 654321"
                            value={phone ? phone : ""}
                            onChange={phone => setPhone(phone)}
                            formErrors={formErrors}
                            showErrors={true}
                        />
                    </div>
                </div>

                <div className="mb-3">
                    <FormInput
                        name="email"
                        label="Company Email"
                        className="form-control"
                        placeholder="Enter email"
                        type="text"
                        // required
                        showErrors
                        formErrors={formErrors}
                        defaultValue={stepData?.["email"]}
                    />
                </div>

                {/* <div className="mb-3">
                    <FormInput
                        label={"Country"}
                        type="country"
                        name="country_code"
                        className="form-control"
                        placeholder={"Select country"}
                        showErrors
                        formErrors={formErrors}
                        onChange={code => setSelectedCountry(code)}
                        value={selectedCountry}
                        countries={countries?.map((country: any) => country?.code)}
                    />
                </div>

                <div className="row">
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <AvField
                                name="city_name"
                                label="City"
                                className="form-control"
                                placeholder="Enter city name"
                                type="text"
                                required
                            />
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="mb-3">
                            <AvField
                                name="postcode"
                                label="Postcode"
                                className="form-control"
                                placeholder="Enter postcode"
                                type="text"
                                required
                            />
                        </div>
                    </div>
                </div> */}

                <h4 className="card-title mt-4">Register Address</h4>
                <Row>
                    <div className="mb-0">
                        <FormInput
                            label={t("Country")}
                            type="country"
                            name="country_code"
                            className="form-control"
                            placeholder={t("Select country")}
                            showErrors
                            formErrors={formErrors}
                            onChange={handleChangeCountry}
                            value={countryCode}
                            // countries={countries?.map((country: any) => country?.code)}
                            countries={["GB", "US"]}
                            onBlur={() => generateCoords()}
                            disabled={false}
                        />
                    </div>

                    <div className="mb-1">
                        <FormInput
                            disabled={false}
                            label={t("Street and number")}
                            type="text"
                            name="street"
                            // value={street ? street : stepData?.street ? stepData?.street : ""}
                            value={street}
                            className="form-control"
                            placeholder={t("Enter value")}
                            showErrors
                            formErrors={formErrors}
                            onChange={(e: any) => setStreet(e.target.value)}
                            // onBlur={() => generateCoords()}
                            required
                            submitted={formSubmitted}
                        />
                    </div>

                    <div className="mb-1">
                        <FormInput
                            disabled={false}
                            label={t("Postcode")}
                            type="text"
                            name="postcode"
                            // value={postcode ? postcode : stepData?.postcode ? stepData?.postcode : ""}                                                        className="form-control"
                            value={postcode}
                            className="form-control"
                            placeholder={t("Enter postcode")}
                            showErrors
                            formErrors={formErrors}
                            onChange={(e: any) => setPostcode(e.target.value)}
                            onBlur={() => {
                                handleUpdateCity(postcode, countryCode)
                            }}
                            required
                            submitted={formSubmitted}
                        />
                    </div>

                    <div className="mb-1">
                        <FormInput
                            disabled={false}
                            label={t("City")}
                            type="text"
                            name="city_name"
                            // value={city ? city : stepData?.city_name ? stepData?.city_name : ""}
                            value={city}
                            className="form-control"
                            placeholder={t("Enter value")}
                            showErrors
                            formErrors={formErrors}
                            onChange={(e: any) => setCity(e.target.value)}
                            onBlur={generateCoords}
                            required
                            submitted={formSubmitted}
                        />
                    </div>

                    <div>
                        <small className="text-muted">
                            {foundAddress ?
                                <span>
                                    <span>Full address:</span>
                                    {" "}
                                    <b>{foundAddress}</b>
                                </span>
                                : null
                            }
                        </small>
                    </div>
                </Row>
                <Row className={`add-charity-shop-google-maps-container pt-0`}>
                    {/* @ts-ignore */}
                    {formErrors?.lat || formErrors?.lng ? <small className="text-danger">{t("Coordinates are used.")}</small> : null}
                    {/* @ts-ignore */}
                    <div className={`${formErrors?.lat || formErrors?.lng ? "google-maps-error" : ""}`}>
                        <CustomGoogleMaps
                            // @ts-ignore
                            returnTranslation={t}
                            defaultCenter={{ lat: 51.507290, lng: -0.127751 }}
                            markerPosition={{ lat, lng }}
                            defaultZoom={18}
                            center={{ lat: lat ? lat : 51.507290, lng: lng ? lng : -0.127751 }}
                            markerDraggable={true}
                            onMarkerDragEnd={(coords: LatLng) => {
                                // setFormErrors({
                                //     ...formErrors,
                                //     lat: false,
                                //     lng: false
                                // })
                                setLat(coords?.lat)
                                setLng(coords?.lng)
                            }}
                            setCurrentPosition={(coords: LatLng) => {
                                setLat(coords?.lat)
                                setLng(coords?.lng)
                            }}
                            //@ts-ignore
                            error={formErrors?.lat || formErrors?.lng}
                        />
                    </div>
                </Row>


                <div>
                    <div className="d-flex align-items-center justify-content-between mt-4 border-light-gray p-2 clickable"
                        onClick={() => setIsOpenOpeningHours(!isOpenOpeningHours)}
                    >
                        <label htmlFor="opening_hours_active-checkbox" className="card-title m-0 p-0">Add Opening Hours</label>
                        <FormInput
                            label={""}
                            type="checkbox"
                            name="opening_hours_active"
                            id="opening_hours_active"
                            className="form-check-input mt-2 me-1"
                            placeholder={""}
                            showErrors
                            formErrors={formErrors}
                            // onChange={(e: any) => handleSetActiveDay(singleDayOpening?.name, !singleDayOpening?.active)}
                            checked={isOpenOpeningHours}
                            // id={`apt-${i}-checkbox`}
                            onChange={() => setIsOpenOpeningHours(!isOpenOpeningHours)}
                        />
                    </div>
                    <Collapse isOpened={isOpenOpeningHours}
                        theme={{ content: "ReactCollapse--content ps-2 overflow-auto", collapse: "ReactCollapse--collapse" }}
                    >
                        {/* <div>Random content</div> */}
                        {opening_hours?.map((singleDayOpening, i) => {
                            return (
                                <div className="single-day-time-inline" key={`${singleDayOpening?.day}-${singleDayOpening?.from}-${singleDayOpening?.until}`}>
                                    <div className="clickable d-flex align-items-center"
                                        onClick={(e: any) => handleSetActiveDay(singleDayOpening?.dayOfWeek, !singleDayOpening?.active)}
                                    >
                                        <FormInput
                                            label={""}
                                            type="checkbox"
                                            name="active"
                                            className="form-check-input mt-2 me-1"
                                            placeholder={""}
                                            showErrors
                                            formErrors={formErrors}
                                            // onChange={(e: any) => handleSetActiveDay(singleDayOpening?.name, !singleDayOpening?.active)}
                                            checked={singleDayOpening?.active}
                                            id={`apt-${i}-checkbox`}
                                        />
                                        <label className="clickable" htmlFor={`apt-${i}-checkbox`} >
                                            {`${capitalize(singleDayOpening?.dayOfWeek)}`}
                                        </label>
                                    </div>

                                    <div>
                                        {singleDayOpening?.active ? (
                                            <>
                                                {/* <div className="input--floting-label"> */}

                                                <FormInput
                                                    type="time"
                                                    id={`apt-${i}-open`}
                                                    name={`apt-${i}-open`}
                                                    min="00:00"
                                                    max="23:59"
                                                    defaultValue={singleDayOpening?.from}
                                                    onChange={(e: any) => handleTimeChange(e.target.value, singleDayOpening?.day, "from")}
                                                    required
                                                    className="form-control"
                                                    floatingLabel={t("Open from")}
                                                    wrapperClass={"mb-0"}
                                                />
                                                {/* </div> */}

                                                {/* <TextField id="outlined-basic" label="Open From" variant="outlined" /> */}
                                                <FormInput
                                                    type="time"
                                                    id={`apt-${i}-close`}
                                                    name={`apt-${i}-close`}
                                                    min="00:00"
                                                    max="23:59"
                                                    defaultValue={singleDayOpening?.until}
                                                    onChange={(e) => handleTimeChange(e.target.value, singleDayOpening?.day, "until")}
                                                    required
                                                    className="form-control"
                                                    floatingLabel={t("Open till")}
                                                    wrapperClass={"mb-0"}
                                                />
                                            </>
                                        ) : (
                                            <div className="d-flex align-items-center" style={{ height: 40 }}>{t("Closed")}</div>
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                    </Collapse>
                </div>

                <div className="mt-5 d-grid">
                    <button
                        className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
                        type="submit"
                        onClick={() => setFormSubmitted(true)}
                    >
                        {submitBtnText}
                    </button>
                </div>
            </Form>
        </ >
    )
}

export default withTranslation()(RegisterDetails);
