import { GET_COUPONS, GET_COUPONS_FAIL, GET_COUPONS_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  codesc: [],
  info: {},
}

const Codes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COUPONS:
      return {
        ...state,
        isLoading: true
      }


    case GET_COUPONS_SUCCESS:
      return {
        ...state,
        codes: action?.payload?.items,
        info: action?.payload?.info,
        isLoading: false
      }

    case GET_COUPONS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      }
    default:
      return state
  }
}

export default Codes
