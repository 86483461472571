import React, {useEffect, useState} from 'react';
// @ts-ignore
import {useDispatch} from 'react-redux';
// @ts-ignore
import MetaTags from 'react-meta-tags';
import Breadcrumbs from './../../../components/Common/Breadcrumb';
import {Container, Card, Row, CardBody, Col} from 'reactstrap';
import {getWebsiteDetailApi} from './../../../helpers/backend_helper';
import {openAlert} from './../../../store/alert/actions';
import CompleteSetupEcommerce from './../WebsitesFormSteps/CompleteSetupEcommerce';

interface ShopifyManualInterface {
  history: any;
  match: any;
  returnTranslation: (name: string) => string;
}

const ShopifyManual = ({
  history,
  match: {params},
  returnTranslation,
}: ShopifyManualInterface) => {
  const dispatch = useDispatch();

  const displayCopiedAlert = () => {
    return dispatch(
      openAlert({text: returnTranslation('Copied'), status: 'success'}),
    );
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>LOOP | Shopify Manual</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          title={returnTranslation('Pages')}
          breadcrumbItems={[
            returnTranslation('Websites'),
            returnTranslation('Shopify Manual'),
          ]}
        />

        <div className="mb-4">
          <CompleteSetupEcommerce
            returnTranslation={returnTranslation}
            selectedPlatform={'shopify'}
            generatedToken={params?.uuid ? params?.uuid : '{uuid}'}
            displayCopiedAlert={displayCopiedAlert}
          />
        </div>
      </Container>
    </div>
  );
};

export default ShopifyManual;
