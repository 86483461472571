import {
  GET_DICTIONARIES_SUCCESS,
  GET_DICTIONARIES_FAIL,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_FAIL
} from "./actionTypes"

const INIT_STATE = {
  dictionaries: [],
  regions: []
}

const Dictionaries = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DICTIONARIES_SUCCESS:
      return {
        ...state,
        dictionaries: action ?.payload
      }

    case GET_DICTIONARIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_REGIONS_SUCCESS:
      return {
        ...state,
        regions: action ?.payload
      }

    case GET_REGIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Dictionaries
