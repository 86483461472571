import React from "react"

interface Props {
  returnTranslation: (name: string) => string,

  // handleDelete: (id: string, name: string) => void,
  handleEdit: (id: string) => void,
  handleToggleTakeBackCarrier: (id: string, isActive: boolean, isConfigured: boolean) => void,
  // handleClickManageCarriers: (id: string) => void,
}

const CarriersTableColumns = ({
    handleEdit,
    handleToggleTakeBackCarrier,
    returnTranslation,
  }: Props) => {
  return (
    [
      {
        dataField: "i",
        isDummyField: true,
        text: "#",
        formatter: (cellContent: any, row: any, rowIndex: any) => <div>{rowIndex + 1}</div>,
      },
      {
        dataField: "status",
        text: returnTranslation("Is Active"),
        formatter: (cellContent: any, row: any, rowIndex: any) => (
          <div className="d-flex align-items-center gap-2">
            <input className="unclickable" type="checkbox" checked={row?.status} />
            <span className={`auth-agreement-link ${row.status == 1 ? "auth-agreement-link--inactive" : ""}`}
              onClick={() => handleToggleTakeBackCarrier(row?.id, !!row?.status, row?.configured)}
            >
              {row.status == 1 ? returnTranslation("Set inactive") : returnTranslation("Set active")}
            </span>
          </div>
        )
      },
      {
        dataField: "logo",
        text: returnTranslation("Logo"),
        formatter: (cellContent: any, row: any, rowIndex: any) => (
          <div className="d-flex align-items-center" style={{width: 65}}>
            <img src={row?.logo} height={65} style={{ borderRadius: 100 }} />
          </div>
        )
      },
      {
        dataField: "name",
        text: returnTranslation("Name"),
      },
      // {
      //   dataField: "description",
      //   text: returnTranslation("Short Description"),
      // },
      // {
      //   dataField: "carriers",
      //   text: returnTranslation("Carriers"),
      //   formatter: (cellContent: any, row: any, rowIndex: any) => (
      //     <div className="d-flex align-items-center gap-1">
      //       {row?.carriers?.map((carrier: any) => (
      //         <img key={carrier.id} src={carrier.logo} height={31} style={{ borderRadius: 3 }} />
      //         // <span key={carrier}>{carrier}</span>
      //       ))}
      //       <span className="fw-medium auth-agreement-link"
      //         onClick={() => handleClickManageCarriers(row?.id)}
      //       >
      //         {row?.carriers?.length ? returnTranslation("Manage") :  returnTranslation("Add carriers")}
      //       </span>
      //       {/* <Button
      //         size="sm"
      //         type="button"
      //         className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
      //       >
      //         {returnTranslation("Manage")}
      //       </Button> */}
      //     </div>
      //   )
      // },
      // {
      //   dataField: "accept",
      //   text: returnTranslation("Allowed"),
      //   formatter: (cellContent: any, row: any, rowIndex: any) => (
      //     <div className="d-flex align-items-center gap-2">
      //       {row?.accept?.map((x: any) => x.name)?.join(", ")}
      //       <span className="fw-medium auth-agreement-link">{row?.accept?.length ? returnTranslation("Manage") :  returnTranslation("Add allowed items")}</span>

      //       {/* <Button
      //         size="sm"
      //         type="button"
      //         className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
      //       >
      //         {returnTranslation("Manage")}
      //       </Button> */}
      //     </div>
      //   )
      // },
      // {
      //   dataField: "not_accept",
      //   text: returnTranslation("Not Allowed"),
      //   formatter: (cellContent: any, row: any, rowIndex: any) => (
      //     <div className="d-flex align-items-center gap-2">
      //       {row?.not_accept?.map((x: any) => x.name)?.join(", ")}
      //       <span className="fw-medium auth-agreement-link">{row?.not_accept?.length ? returnTranslation("Manage") :  returnTranslation("Add not allowed items")}</span>
      //     </div>
      //   )
      // },
      // {
      //   dataField: "type",
      //   text: returnTranslation("Type"),
      // },
      // {
      //   dataField: "value",
      //   text: returnTranslation("Value"),
      // },
      // {
      //   dataField: "status",
      //   text: returnTranslation("LOOP Status"),
      //   formatter: (cellContent: any, row: any, rowIndex: any) =>
      //     row.active ? (
      //       <span className="bg-success badge badge-success">{returnTranslation("Visible")}</span>
      //     ) : (
      //       <span className="bg-danger badge badge-danger">{returnTranslation("Inactive")}</span>
      //     ),
      // },
      {
        dataField: "action",
        isDummyField: true,
        text: "",
        formatter: (cellContent: any, row: any, rowIndex: any) => (
          <div className="d-flex gap-3">
            <span
              className="text-warning clickable"
              onClick={() => handleEdit(row.id)}
            >
               {!row?.configured ? (
                <i className="fas fa-exclamation-triangle text-danger me-1" />
              ): null}
              {returnTranslation("Configure")}
              {/* <i className="mdi mdi-pencil font-size-18" id="edittooltip" /> */}
            </span>
            {/* <span
              className="text-danger clickable"
              onClick={() => handleDelete(row.id, row.name)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
            </span> */}
            {/* <Link
              to="#"
              className={row.active == 1 ? "text-danger" : "text-success"}
              onClick={() => handleSetActive(row.id, row.active == 1 ? 0 : 1)}
            >
              <p className="active-coupon-btn">{row.active == 1 ? returnTranslation("Set inactive") : returnTranslation("Set active")}</p>
            </Link> */}
          </div>
        ),
      },
    ]
  )
}

export default CarriersTableColumns
