import React from 'react'
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap'
import LazyTabWrapper from './LazyTabWrapper'
import './CustomTabs2.scss'

interface Props {
    labels: string[];
    activeTab: string;
    setActiveTab: (number: string) => void;
    children: any;
    className?:string;
    lazyLoadTabs?: boolean;
}

const CustomTabs2 = ({ activeTab, setActiveTab, labels, children, className, lazyLoadTabs }: Props) => {

    return (
        <div className={`custom-tabs custom-tabs-2 ${className ? className : ''}`}>
            <Nav tabs className="nav-tabs-custom nav-justified">
                {labels.map((label, index) => (
                    <NavItem key={label}>
                        <NavLink
                            className={activeTab === `${index + 1}` ? 'active' : ''}
                            onClick={() => setActiveTab(`${index + 1}`)}
                        >
                        <span className="d-none d-sm-block">{label}</span>
                        </NavLink>
                    </NavItem> 
                ))}
            </Nav>

            <TabContent activeTab={activeTab} className="pt-3 text-muted">
                {children.length > 1
                    ? children?.filter((child: any) => !!child).map((child: any, index: number) => (
                        <TabPane key={index} tabId={`${index + 1}`}>
                            <Row>
                                <Col sm="12">
                                    {lazyLoadTabs ? <LazyTabWrapper triggerRender={activeTab == `${index + 1}`} childToRender={child}/> : child}
                                </Col>
                            </Row>
                        </TabPane>
                    )) : (
                        <TabPane tabId={`1`}>
                            <Row>
                                <Col sm="12">
                                    {children}
                                </Col>
                            </Row>
                        </TabPane>
                    )
                }
            </TabContent>
        </div>
    )
}

export default CustomTabs2
