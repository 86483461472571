import React, { useEffect, useState } from "react"
// @ts-ignore
import MetaTags from 'react-meta-tags'
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
// @ts-ignore
import { RouteComponentProps, withRouter } from "react-router-dom"
import { Container, Row } from "reactstrap"
import Breadcrumbs from "./../../../components/Common/Breadcrumb"
import ChangePlanDuration from "./../../../components/SubscriptionsPlans/ChangePlanDuration/ChangePlanDuration"
// import WebsitesTableColumns from "./WebsitesTableColumns/WebsitesTableColumns"
import SubscriptionsPlans from "./../../../components/SubscriptionsPlans/SubscriptionsPlans"
import { post } from "./../../../helpers/api_helper"
import * as url from "./../../../helpers/url_helper"
import { changePreloader, getBillings, getInvoices, getPaymentInfo, openAlert } from "./../../../store/actions"

interface ChangePlanInterface extends RouteComponentProps {
    history: any,
    returnTranslation: (name: string) => string
}

const ChangePlan = ({ history, returnTranslation }: ChangePlanInterface) => {
    const dispatch = useDispatch()
    const user = useSelector((state: any) => state.Account.user)
    const billingsInfo = useSelector((state: any) => state.Billings.info)
    const isPaypalConnected = useSelector((state: any) => state?.PaymentInfo?.info?.paypal_connection);
    // const isCardConnected = useSelector((state: any) => state.PaymentInfo?.info?.card) !== null;
    const [billAnnulally, setBillAnnulally] = useState(false)

    const init = () => {
        dispatch(getPaymentInfo())
        dispatch(getBillings())
        dispatch(getInvoices())
    }

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        setBillAnnulally(billingsInfo?.plan_details?.interval === "year");
    }, [billingsInfo?.plan_details?.interval])

    const handleSetPlan = (value: string) => {
        return new Promise(async (resolve, reject) => {
            dispatch(changePreloader(true))
            post(billingsInfo?.plan_details ? `${url.API_BASE}subscribes/switch` : `${url.API_BASE}plans/subscribe`, {
                plan: value,
                interval: billAnnulally ? "year" : "month",
                type: isPaypalConnected ? "PAYPAL" : "STRIPE"
            })
                .then(response => {
                    if (response?.status) {
                        // if (response?.url) {
                        //     handleConnectStripe(response?.url)
                        // }

                        dispatch(openAlert({ text: response?.status, status: "success" }))
                        // history.push("/billings")
                        init();
                    }

                    dispatch(changePreloader(false))
                    return resolve(response)
                })
                .catch(error => {
                    dispatch(changePreloader(false))
                    if (error?.response?.data) {
                        const { message, errors } = error?.response?.data;
                        dispatch(openAlert({ text: message, status: "danger" }))
                    }
                    return reject(error)
                })
        })
    }

    const messageHandler = async (event: any) => {
        if(!event?.data?.source?.includes("devtools")){
            if (event?.data?.error?.message) {
                dispatch(openAlert({ text: event?.data?.error?.message, status: "danger" }))
            }

            if (event.data.status === "success") {
                dispatch(openAlert({ text: "Payment method succesfuly connected", status: "success" }))
            }

            init();
        }
    }

    useEffect(() => {
        window.addEventListener("message", messageHandler)
        return () => {
            window.removeEventListener("message", messageHandler)
        }
    }, [])

    const handleConnectStripe = async (url: string) => {
        // if (!url) {
        //     return
        // }

        // const newwindow = window.open("", url, "height=500,width=500")

        const newwindow = window.open(url, "connect", "height=500,width=500");

        //@ts-ignore
        if (window?.focus) {
            // console.log("focus")
            newwindow?.focus();
        }

        return false;
    }



    // const messageHandler = async (event: any) => {
    //     // console.log(["messageHandler", event])
    //     if (event?.data?.error?.message) {
    //         dispatch(openAlert({ text: event?.data?.error?.message, status: "danger" }))
    //         // //@ts-ignore
    //         // message.error({
    //         // 	content: event.data.error.message,
    //         // })
    //     }

    //     if (event.data.status === "success") {
    //         dispatch(changePreloader(false))
    //         dispatch(openAlert({ text: "success", status: "success" }))
    //     }
    // }

    // useEffect(() => {
    //     window.addEventListener("message", messageHandler)
    //     return () => {
    //         window.removeEventListener("message", messageHandler)
    //     }
    // }, [])

    const openStripeUrl = async (url: string) => {
        // console.log(['openStripeUrl', url])
        if (!url) {
            return
        }

        const newwindow = window.open("", url, "height=500,width=500")
        //@ts-ignore
        newwindow.location.href = url

        //@ts-ignore
        if (window.focus) {
            //@ts-ignore
            newwindow.blur()
            //@ts-ignore
            setTimeout(newwindow.focus, 0)
        }
        return false
    }

    const getCurrentPlanName = () => {
        return billingsInfo?.plan_details?.name?.toLowerCase()?.includes("standard")
            ? "STANDARD"
            : billingsInfo?.plan_details?.name?.toLowerCase()?.includes("enterprise")
                ? "ENTERPRISE"
                : billingsInfo?.plan_details?.name?.toLowerCase()?.includes("basic")
                    ? "BASIC"
                    : undefined
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>LOOP | Change Plan</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs
                        title={returnTranslation("Billings")}
                        breadcrumbItem={returnTranslation("Change Plan")}
                    />
                    {user?.role &&
                        <>
                            <ChangePlanDuration
                                type={user?.role}
                                billAnnulally={billAnnulally}
                                handleSwitchChange={() => setBillAnnulally(!billAnnulally)}
                            />
                            <Row>
                                <SubscriptionsPlans
                                    type={user?.role}
                                    handleSetPlan={handleSetPlan}
                                    displayTrialText={false}
                                    selectedPlanName={getCurrentPlanName()}
                                    changeBilling={((billingsInfo?.plan_details?.interval === "month") && billAnnulally) ||
                                        ((billingsInfo?.plan_details?.interval === "year") && !billAnnulally)
                                    }
                                />
                            </Row>
                        </>
                    }
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(ChangePlan)
