import React, { Component, useEffect } from "react"
import PropTypes from "prop-types"
import { BrowserRouter as Router, Switch } from "react-router-dom"
import { connect } from "react-redux"
import { authProtectedRoutes, publicRoutes } from "./routes/"
import AppRoute from "./routes/route"
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import "./assets/scss/theme.scss"
// import fakeBackend from "./helpers/AuthType/fakeBackend"
import ConfirmPanel from "./components/Common/ConfirmPanel"
import UiAlert from "./components/Common/UiAlert"
import Protect from "react-app-protect"
import "react-app-protect/dist/index.css"
import { getRegions, getTakeBacks } from "./store/actions"

const App = (props) => {
  const getLayout = () => {
    let layoutCls = VerticalLayout

    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const returnTranslation = name => {
    const translationsList = props?.translations?.translations
    let language = localStorage.getItem("I18N_LANGUAGE")

    if (
      translationsList &&
      translationsList[name] &&
      translationsList[name][language]
    ) {
      return translationsList[name][language]
    } else {
      return name
    }
  }

  useEffect(() => {
    props?.onGetTranslations()
    props?.onGetRegions()
    // this.props.onGetPaymentInfo()
  }, [])

  useEffect(() => {
    if(props?.user?.access_token){
      // wait for localstorage.authUser - api_helper.js bearer
      // TODO - api_helper.js connect saga Account.user.acces_token
      setTimeout(() => {
        props?.onGetTakeBacks()
      }, 1000)
    }
    // this.props.onGetPaymentInfo()
  }, [props?.user?.access_token])

  return (
    <>
      {window?.location?.origin === "https://dashboard.loopdigitalwardrobe.com" ?
        <React.Fragment>
          <Router>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  returnTranslation={returnTranslation}
                />
              ))}

              {authProtectedRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={getLayout()}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                  returnTranslation={returnTranslation}
                />
              ))}
            </Switch>
          </Router>
          {props?.confirmPanel?.confirmCb && (
            <ConfirmPanel returnTranslation={returnTranslation} />
          )}
          <UiAlert returnTranslation={returnTranslation} />
        </React.Fragment>
        :
        <Protect sha512="4AEB2000B9DE5858F5E5E0B7EDA52F253CAF19582C67CBBB453BE6987ECC1BAF27D75670E39F78058FB1EBEE3D16B83D1CBDC8D3628636377B2458EA5BF12FF2">
          <React.Fragment>
            <Router>
              <Switch>
                {publicRoutes.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={NonAuthLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={false}
                    returnTranslation={returnTranslation}
                  />
                ))}

                {authProtectedRoutes.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={getLayout()}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    exact
                    returnTranslation={returnTranslation}
                  />
                ))}
              </Switch>
            </Router>
            {props.confirmPanel?.confirmCb && (
              <ConfirmPanel returnTranslation={returnTranslation} />
            )}
            <UiAlert returnTranslation={returnTranslation} />
          </React.Fragment>
        </Protect>
      }
    </>
  )
}

// class App extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {}
//     this.getLayout = this.getLayout.bind(this)
//   }

//   getLayout = () => {
//     let layoutCls = VerticalLayout

//     switch (this.props.layout.layoutType) {
//       case "horizontal":
//         layoutCls = HorizontalLayout
//         break
//       default:
//         layoutCls = VerticalLayout
//         break
//     }
//     return layoutCls
//   }

//   returnTranslation = name => {
//     const translationsList = this.props?.translations?.translations
//     let language = localStorage.getItem("I18N_LANGUAGE")

//     if (
//       translationsList &&
//       translationsList[name] &&
//       translationsList[name][language]
//     ) {
//       return translationsList[name][language]
//     } else {
//       return name
//     }
//   }

//   componentDidMount = () => {
//     this.props.onGetTranslations()
//     // this.props.onGetPaymentInfo()
//   }

//   render() {
//     const Layout = this.getLayout()

//     return (
//       <>
//         {window?.location?.origin === "https://dashboard.loopdigitalwardrobe.com" ?
//           <React.Fragment>
//             <Router>
//               <Switch>
//                 {publicRoutes.map((route, idx) => (
//                   <AppRoute
//                     path={route.path}
//                     layout={NonAuthLayout}
//                     component={route.component}
//                     key={idx}
//                     isAuthProtected={false}
//                     returnTranslation={this.returnTranslation}
//                   />
//                 ))}

//                 {authProtectedRoutes.map((route, idx) => (
//                   <AppRoute
//                     path={route.path}
//                     layout={Layout}
//                     component={route.component}
//                     key={idx}
//                     isAuthProtected={true}
//                     exact
//                     returnTranslation={this.returnTranslation}
//                   />
//                 ))}
//               </Switch>
//             </Router>
//             {this.props.confirmPanel?.confirmCb && (
//               <ConfirmPanel returnTranslation={this.returnTranslation} />
//             )}
//             <UiAlert returnTranslation={this.returnTranslation} />
//           </React.Fragment>
//           :
//           <Protect sha512="4AEB2000B9DE5858F5E5E0B7EDA52F253CAF19582C67CBBB453BE6987ECC1BAF27D75670E39F78058FB1EBEE3D16B83D1CBDC8D3628636377B2458EA5BF12FF2">
//             <React.Fragment>
//               <Router>
//                 <Switch>
//                   {publicRoutes.map((route, idx) => (
//                     <AppRoute
//                       path={route.path}
//                       layout={NonAuthLayout}
//                       component={route.component}
//                       key={idx}
//                       isAuthProtected={false}
//                       returnTranslation={this.returnTranslation}
//                     />
//                   ))}

//                   {authProtectedRoutes.map((route, idx) => (
//                     <AppRoute
//                       path={route.path}
//                       layout={Layout}
//                       component={route.component}
//                       key={idx}
//                       isAuthProtected={true}
//                       exact
//                       returnTranslation={this.returnTranslation}
//                     />
//                   ))}
//                 </Switch>
//               </Router>
//               {this.props.confirmPanel?.confirmCb && (
//                 <ConfirmPanel returnTranslation={this.returnTranslation} />
//               )}
//               <UiAlert returnTranslation={this.returnTranslation} />
//             </React.Fragment>
//           </Protect>
//         }
//       </>
//     )
//   }
// }

const mapStateToProps = state => {
  return {
    layout: state?.Layout,
    confirmPanel: {
      confirmCb: state?.confirmPanel?.confirmCb,
    },
    translations: state?.Translations?.translations,
    user: state?.Account?.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetTranslations: () => dispatch({ type: "GET_TRANSLATIONS" }),
    onGetTakeBacks: () => dispatch(getTakeBacks()),
    onGetRegions: () => dispatch(getRegions()),
    // onGetPaymentInfo: () => dispatch({ type: "GET_PAYMENT_INFO" }),
  }
}

App.propTypes = {
  layout: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
