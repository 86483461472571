import React from "react"
//@ts-ignore
import { Link } from "react-router-dom"
import moment from "moment-timezone"
import SwitchCustom from "./../../../../components/SwitchCustom/SwitchCustom"

interface CodesTableColumnsInterface {
    handleDelete: (id: string, name: string) => {},
    handleEdit: (id: string) => {},
    handleSetActive: (id: string, active: number) => {},
    returnTranslation: (name: string) => string
}

const WebsitesTableColumns = ({ handleDelete, handleEdit, returnTranslation }: CodesTableColumnsInterface) => {
    return (
        [
            {
                dataField: "i",
                isDummyField: true,
                text: "#",
                formatter: (cellContent: any, row: any, rowIndex: any) => <div>{rowIndex + 1}</div>,
            },
            {
                dataField: "url",
                text: returnTranslation("Feed name"),
            },
            {
                dataField: "type",
                text: returnTranslation("Feed type"),
            },
            {
                dataField: "country",
                text: returnTranslation("Country"),
            },
            {
                dataField: "Schedule",
                text: returnTranslation("Schedule frequency"),
                formatter: (cellContent: any, row: any, rowIndex: any) =>
                    <div className="d-flex flex-column">
                        {row?.schedule_frequency === "DAILY" ? (
                            <span>{`Daily  at `}<b>{moment.tz(row?.schedule_time, "hh:mm:ss", row?.schedule_tz).format('hh:mm a')}</b></span>
                        ) : row?.schedule_frequency === "WEEKLY" ? (
                            <span>{`Weekly on `}<b>{row?.schedule_day !== null ? moment.weekdaysShort(row?.schedule_day) : row?.schedule_day}</b>{" "}<b>{moment.tz(row?.schedule_time, "hh:mm:ss", row?.schedule_tz).format('hh:mm a')}</b></span>
                        ) : row?.schedule_frequency === "HOURLY" ? (
                            <b>{`Hourly`}</b>
                        ) : null}
                        {/* {row?.schedule_frequency !== "HOURLY" ? <span className="text-muted" style={{ fontSize: 10 }}>{row?.feed?.schedule_tz}</span> : null} */}
                    </div>
            },
            // {
            //     dataField: "schedule_time",
            //     text: returnTranslation("Schedule time"),
            // },
            {
                dataField: "action",
                isDummyField: true,
                text: "",
                formatter: (cellContent: any, row: any, rowIndex: any) => (
                    <div className="d-flex align-items-center gap-3">
                        <span
                            className="text-warning clickable"
                            onClick={() => handleEdit(row.id)}
                        >
                            {/* <i className="mdi mdi-pencil font-size-18" id="edittooltip" /> */}
                            Details
                        </span>
                        <span
                            className="text-danger clickable"
                            onClick={() => handleDelete(row.id, row.type)}
                        >
                            <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                        </span>
                    </div>
                ),
            },
        ]
    )
}

export default WebsitesTableColumns
