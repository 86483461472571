//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/billings/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"

//WEBSITES
export const GET_WEBSITES = (page) => `/sites?page=${page ? page : 1}` //get
export const GET_WEBSITE_DETAIL = "/sites" //get
export const UPDATE_WEBSITE = "/sites" //put
export const ADD_WEBSITE = "/sites" //post
export const DELETE_WEBSITE = "/sites" //delete

// export const API_BASE = "https://api.loopdigitalwardrobe.com/api/"
export const API_BASE = process.env.REACT_APP_API_URL;
export const LOGIN_PATH = `${API_BASE}login`

//GET_COUNTRIES
export const GET_COUNTRIES = "/countries"

//PROFILE
export const UPDATE_PROFILE = "/users/me"

//DICTIONARIES
export const GET_DICTIONARIES = "dictionaries?types=states,languages"
export const GET_REGIONS = "regions"

//CURRENCIES
export const GET_CURRENCIES = "currencies"

//CODES
export const GET_COUPONS = "retailers/coupons";
export const DELETE_CODE = "retailers/coupons";
export const GET_COUPON = "retailers/coupons";
export const ACTIVE_CODE = "retailers/coupons";

//CHARITY SHOP
// export const GET_CHARITY_SHOP_CONFIG = "charity_shop/config";
export const GET_VIRTUAL_SHOP_CONFIG = "virtual_shop/config";
export const GET_SHIPPING_ADDRESS = "users/profiles/shopping_details";
export const ENABLE_CHARITY_SHOP = "charity_shop/enabled";

//CHARITY SHOPS
export const GET_CHARITIES_SHOPS = "charities/points";
export const DELETE_CHARITY_SHOP = "charities/points";
export const GET_CHARITY_SHOP = "charities/points";
export const ACTIVE_CHARITY_SHOP = "charities/points";
export const CHARITIES_EXPORT = "charities/points/export";


//return warehouse
export const RETURN_ADDRESS = "charities/return_address";

//TRANSLATIONS
export const GET_TRANSLATIONS = "plugins/translations"

//RESET PASSWORD
export const RESET_PASSWORD = "dashboard/reset_password"
export const CHECK_EMAIL = "dashboard/reset_password_notification"

//BILLINGS
export const GET_PAYMENT_INFO = "billings/payment_info"
export const GET_BILLINGS = "billings"


export const UPLOAD_FILE = "files"

export const STRIPE = "stripe/account"
export const STRIPE_CONNECT = "stripe/account/connect"
export const STRIPE_CARDS = "stripe/cards"

export const PAYPAL = "paypal/login/status"
export const PAYPAL_CONNECT = "paypal/login"
export const PAYPAL_DISCONNECT = "paypal/logout"


//FEED ANALYTICS
export const RECOMMENDATIONS_STATS = "recommendations/stats"