// @ts-ignore
import { AvField, AvForm } from "availity-reactstrap-validation"
import _ from "lodash"
import moment from "moment"
import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
// @ts-ignore
import { useDispatch } from "react-redux"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container, FormGroup,
    Label, Row
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import * as url from "../../../helpers/url_helper"
import { changePreloader, openAlert } from "../../../store/actions"
import FormInput from "../../../components/Common/FormInput"
import { get, post, put } from "./../../../helpers/api_helper"
import FeederGrid from "./FeederGrid/FeederGrid"

interface AddFeedInterface {
    history: any,
    match: any,
    returnTranslation: (name: string) => string;
}

const AddFeed = ({ history, match: { params }, returnTranslation }: AddFeedInterface) => {
    const dispatch = useDispatch()
    const [retailersFeedsTypeId, setRetailersFeedsTypeId] = useState("1")
    // const [scheduleFrequency, setScheduleFrequency] = useState("DAILY")
    // const [scheduleTime, setScheduleTime] = useState("12:00")

    const [scheduleFrequency, setScheduleFrequency] = useState("DAILY")
    const [scheduleTime, setScheduleTime] = useState<any>(new Date(moment("12:00:00", "HH:mm:ss").format()))
    const [scheduleDay, setScheduleDay] = useState<number | any>(null)

    const [feedUrl, setFeedUrl] = useState("")
    const [feedUrlCustom, setFeedUrlCustom] = useState("")
    const [formErrors, setFormErrors] = useState([])

    const [feedTypes, setFeedTypes] = useState([])
    const [scheduleFrequencyTypes, setScheduleFrequencyTypes] = useState(["DAILY", "WEEKLY"])

    const [feedItemsCount, setFeedItemsCount] = useState(0);
    const [isEdit, setIsEdit] = useState(false);

    const [details, setDetails] = useState<any>({});
    

    const getFeedDetails = (id: string | number) => {
        return new Promise(async (resolve, reject) => {
            dispatch(changePreloader(true))

            get(`${url.API_BASE}retailers/feeds/${id}`)
                .then((response: any) => {
                    // console.log(["response1", response, response.url])

                    setRetailersFeedsTypeId(response.retailers_feeds_type ? response.retailers_feeds_type : "1")
                    setScheduleFrequency(response.schedule_frequency ? response.schedule_frequency : "")
                    setScheduleDay(parseInt(response?.schedule_day) - 1)
                    setScheduleTime(response?.schedule_time ? new Date(moment(response?.schedule_time, "HH:mm:ss").format()) : null)
                    setFeedUrl(response.url ? response.url : "")
                    setDetails(response)

                    dispatch(changePreloader(false))
                    return resolve(response)
                })
                .catch((error: any) => {
                    if (error?.response?.data) {
                        const { message, errors } = error?.response?.data;
                        // this.setState({ formErrors: errors });
                        dispatch(openAlert({ text: message, status: "danger" }))
                    }
                    dispatch(changePreloader(false))
                    return reject(error)
                })
        })
    }

    const getFeedTypes = () => {
        return new Promise(async (resolve, reject) => {
            dispatch(changePreloader(true))

            get(`${url.API_BASE}retailers/feeds/types`)
                .then((response: any) => {
                    setFeedTypes(response)

                    dispatch(changePreloader(false))
                    return resolve(response)
                })
                .catch((error: any) => {
                    if (error?.response?.data) {
                        const { message, errors } = error?.response?.data;
                        dispatch(openAlert({ text: message, status: "danger" }))
                    }
                    dispatch(changePreloader(false))
                    return reject(error)
                })
        })
    }

    useEffect(() => {
        if (params?.uuid) {
            setIsEdit(true)
            getFeedDetails(params?.uuid)
        }
    }, [params?.uuid])

    useEffect(() => {
        getFeedTypes()
        // dispatch(getCountries())
    }, [])

    const handleValidSubmit = async (event: any, values: any) => {
        event.preventDefault()

        return new Promise(async (resolve, reject) => {
            dispatch(changePreloader(true))

            //edycja
            if (params?.uuid) {
                put(`${url.API_BASE}retailers/feeds/${params?.uuid}`, {
                    retailers_feeds_type_id: Number(retailersFeedsTypeId),
                    schedule_frequency: scheduleFrequency,
                    schedule_time: scheduleTime ? moment(scheduleTime).format("HH:mm") : null,
                    schedule_day: scheduleDay + 1,
                    url: feedUrlCustom ? feedUrlCustom : feedUrl,
                    schedule_tz: "America/Bahia_Banderas",
                    username: "",
                    password: ""
                })
                    .then(response => {
                        if (response?.status) {
                            dispatch(openAlert({ text: response?.status, status: "success" }))
                            history.push("/feed")
                        }

                        dispatch(changePreloader(false))
                        return resolve(response)
                    })
                    .catch(error => {
                        if (error?.response?.data) {
                            const { message, errors } = error?.response?.data;
                            setFormErrors(errors)
                            dispatch(openAlert({ text: message, status: "danger" }))
                        }
                        dispatch(changePreloader(false))
                        return reject(error)
                    })
            } else {
                post(`${url.API_BASE}retailers/feeds`, {
                    retailers_feeds_type_id: Number(retailersFeedsTypeId),
                    schedule_frequency: scheduleFrequency,
                    schedule_time: scheduleTime ? moment(scheduleTime).format("HH:mm") : null,
                    schedule_day: scheduleDay + 1,
                    url: feedUrlCustom ? feedUrlCustom : feedUrl,
                    schedule_tz: "America/Bahia_Banderas",
                    username: "",
                    password: ""
                })
                    .then(response => {
                        if (response?.status) {
                            dispatch(openAlert({ text: response?.status, status: "success" }))
                            history.push("/feed")
                        }

                        dispatch(changePreloader(false))
                        return resolve(response)
                    })
                    .catch(error => {
                        if (error?.response?.data) {
                            const { message, errors } = error?.response?.data;
                            setFormErrors(errors)
                            dispatch(openAlert({ text: message, status: "danger" }))
                        }
                        dispatch(changePreloader(false))
                        return reject(error)
                    })
            }



        })
    }

    const getWeekdays = () => {
        return [1, 2, 3, 4, 5, 6, 0].map(i => moment.weekdays(i))
    }

    const handleSyncNow = async () => { 
        try{
            const response = await post(`${url.API_BASE}retailers/feeds/${params?.uuid}/import_now`)
        }catch(e){

        }
    }

    const handleDownloadFile = async () => {
        // try{
            // console.log(feedUrl)
        // get(feedUrl)
        //     .then(response => { 
        //         // console.log(response?.toBlob())
        //         const blob = new Blob([response?.data], {type: 'application/xml;charset=utf-8'});
        //         // console.log(['blob', blob]);
                const a = document.createElement("a");
                // a.href = URL.createObjectURL(blob);
                a.href = feedUrl;
                const dateTimeFormatted = new Date()?.toLocaleString()?.split(", ")?.join("-")?.split(".")?.join("_")?.split(":")?.join("_");
                //@ts-ignore
                // a.setAttribute("download", `${feedUrl?.split("/")?.pop()}_${dateTimeFormatted}`);
                a.target = "_blank";
                a.click();
        //     })
        // // console.log(response)
        // }catch(e){
        // console.log(e)
        // }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={returnTranslation("Ads Manager")} breadcrumbItem={returnTranslation("Add feed")} />

                    <Row>
                        <Col lg="6" sm="12">
                            {isEdit ? (
                                <div className="d-flex gap-3 align-items-center mb-2 justify-content-between">
                                    <div className="d-flex flex-column align-items-center">
                                        <h4 className="card-title font-size-20">Products</h4>
                                        <h1 className="text-success">{feedItemsCount}</h1>
                                    </div>

                                    <div className="">
                                        {details?.last_sync ? (
                                            // <div className="text-center"><div>Last processed:</div><div>{details?.last_sync}</div></div>
                                            <div className="">{returnTranslation("Last processed:")} {details?.last_sync}</div>
                                        ): (
                                            <div className="">{returnTranslation("File haven't been processed yet.")}</div>
                                        )}
                                        <div className="d-flex gap-2 align-items-center">
                                            <Button
                                                color="success"
                                                className=""
                                                type="button"
                                                onClick={handleSyncNow}
                                            >
                                                Process file now
                                            </Button>
                                            <button
                                                className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
                                                type="button"
                                                onClick={handleDownloadFile}
                                            >
                                                Show XML file
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            <Card>
                                <CardBody className="pt-0">
                                    <div className="p-2">
                                        <AvForm
                                            className="form-horizontal"
                                            onValidSubmit={handleValidSubmit}
                                        >
                                            <div className="form-group">
                                                <FormGroup className="mt-3 mb-3">
                                                    <Label>{returnTranslation("Choose the platform to import the feed from")}</Label>
                                                    <select
                                                        className="form-control select2-search-disable"
                                                        onChange={(e) => setRetailersFeedsTypeId(e?.target?.value)}
                                                        key={retailersFeedsTypeId}
                                                    >
                                                        {feedTypes?.map((feedType: any, i) => {
                                                            return (
                                                                <option key={'pluginFullscreenModalModeOff'} value={feedType.id} selected={retailersFeedsTypeId == feedType.id ? true : false}>
                                                                    {feedType.name}
                                                                </option>
                                                            )
                                                        })}
                                                    </select>
                                                </FormGroup>
                                            </div>

                                            <div className="mb-3">
                                                <AvField
                                                    name="url"
                                                    label="Add the link to your feed from the chosen platform"
                                                    className="form-control"
                                                    placeholder="Enter url"
                                                    type="text"
                                                    defaultValue={feedUrl}
                                                    key={feedUrl}
                                                    onChange={(e: any) => setFeedUrlCustom(e.target.value)}
                                                    required
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <AvField
                                                    name="country"
                                                    label="Country"
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={"UK"}
                                                    key={"country"}
                                                    disabled={true}
                                                />
                                            </div>

                                            <Row className="mb-1">
                                                <Col md={12}>
                                                    <FormInput
                                                        label={"Schedule fetch"}
                                                        type="select"
                                                        name="post_category"
                                                        className="form-control"
                                                        placeholder={returnTranslation('Select category')}
                                                        value={scheduleFrequency}
                                                        options={
                                                            scheduleFrequencyTypes.map((category: any) => {
                                                                return (
                                                                    <option
                                                                        key={category}
                                                                        value={category}
                                                                    >
                                                                        {_.capitalize(category.toLowerCase())}
                                                                    </option>
                                                                );
                                                            })
                                                        }
                                                        showErrors
                                                        formErrors={formErrors}
                                                        onChange={(e: any) => setScheduleFrequency(e?.target?.value)}
                                                    />
                                                </Col>
                                            </Row>

                                            {scheduleFrequency === "WEEKLY" ? (
                                                <Row className="mb-1">
                                                    <Col md={12}>
                                                        <FormInput
                                                            type="select"
                                                            name="post_category"
                                                            className="form-control"
                                                            placeholder={returnTranslation('Select a weekday')}
                                                            value={scheduleDay}
                                                            options={
                                                                getWeekdays().map((weekday: any, i: number) => {
                                                                    return (
                                                                        <option
                                                                            key={weekday}
                                                                            value={i}
                                                                        >
                                                                            {_.capitalize(weekday.toLowerCase())}
                                                                        </option>
                                                                    );
                                                                })}
                                                            showErrors
                                                            formErrors={formErrors}
                                                            onChange={(e: any) => setScheduleDay(parseInt(e?.target?.value))}
                                                        />
                                                    </Col>
                                                </Row>
                                            ) : null}

                                            {scheduleFrequency !== "HOURLY" ? (
                                                <Row className="mb-1">
                                                    <Col md={12}>
                                                        <DatePicker
                                                            selected={scheduleTime}
                                                            onChange={(date: any) => {
                                                                setScheduleTime(new Date(moment(date, "HH:mm:ss").format()))
                                                            }}
                                                            className="form-control"
                                                            showTimeSelect={true}
                                                            showTimeSelectOnly={true}
                                                            calendarClassName="react-datepicker-with-time-select"
                                                            dateFormat="HH:mm"
                                                            timeFormat="HH:mm"
                                                        />
                                                    </Col>
                                                </Row>
                                            ) : null}

                                            <div className="mt-4 d-grid">
                                                <button
                                                    className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
                                                    type="submit"
                                                >
                                                    {returnTranslation(isEdit ? "Save" :"Submit")}
                                                </button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {isEdit ? (
                                <Card>
                                    <CardBody>
                                        <FeederGrid setFeedItemsCount={setFeedItemsCount} feederId={params?.uuid} t={returnTranslation} />
                                    </CardBody>
                                </Card>
                            ) : null}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AddFeed