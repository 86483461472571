// @ts-ignore
import React, { useEffect } from "react";
// @ts-ignore
import MetaTags from 'react-meta-tags';
// @ts-ignore
import { connect, useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { RouteComponentProps } from "react-router";
// @ts-ignore
import { withRouter } from "react-router-dom";
import {
	Card,
	CardBody,
	Col,
	Container, Row
} from "reactstrap";
import Breadcrumb from "../../../../components/Common/Breadcrumb";
import { apiError, checkAccount } from "../../../../store/actions";
import { openAlert } from "../../../../store/alert/actions";
import SingleConfigurationOption from "../../../Dashboard/WelcomePanels/SingleConfigurationOption/SingleConfigurationOption";

export interface Props extends RouteComponentProps {
	dashbord_id: string,
	apiError: (err: string) => {},
	history: any,
	error: string,
	user: any,
	onOpenAlert: any,
	returnTranslation: any
}

const EnableCharityShops = ({ returnTranslation, onOpenAlert, history, user }: Props) => {
	const dispatch = useDispatch();
	const POINTS_ENTITLEMENT_NAME = `${user?.role}_SHOPS`

	const onCheckAccount = () => dispatch(checkAccount());
	const accountInfo = useSelector((state: any) => state.Account?.info);
	const pointsEntitlement = useSelector((state: any) => state.Account?.info?.configuration?.entitlements?.[POINTS_ENTITLEMENT_NAME]);
	const details = useSelector((state: any) => state.Account?.info?.configuration?.details)
	const hasShops = useSelector((state: any) => state.Account?.info?.points)

	useEffect(() => {
		onCheckAccount();
	}, [])

	return (
		<React.Fragment>
			<MetaTags>
				<title>
					{user?.role === 'CHARITY' ? returnTranslation("LOOP | Donations Setup - Charity Shops") : null}
					{user?.role === 'SCHOOL' ? returnTranslation("LOOP | LOOP FOR GOOD - School Points") : null}
				</title>
			</MetaTags>
			<div className="page-content">
				<Container fluid>
					
					{user?.role === 'CHARITY' ? <Breadcrumb title="Donations Setup" breadcrumbItem="Charity Shops" /> : null}
					{user?.role === 'SCHOOL' ? <Breadcrumb title="LOOP FOR GOOD" breadcrumbItem="School Points" /> : null}

					<Row>
						<Col sm={12} md={12} lg={12} xl={8}>
							<Card>
								<CardBody>
									<div className="welcome-panel__steps">
										<SingleConfigurationOption
											header={user?.role === 'CHARITY' ? "Charity Shops" : user?.role === 'SCHOOL' ? "School Points" : ""}
											description={"Get a stream of new products brought to your points by LOOP users. LOOP increases the footfall in your shop, incentivising Gen Z to start thrift shopping story with you."}
											descriptionClassName="m-0"
											filled={details && hasShops && pointsEntitlement?.status === "ACTIVE"}
											tasksOpened={true}
											containerClassName="p-0"
											tasksOptions={[
												{
													name: "Details",
													isActive: details,
													route: "/details"
												},
												{
													name: user?.role === 'CHARITY' ? "Add Shops" : user?.role === 'SCHOOL' ? "Add Points" : "",
													isActive: hasShops ?? false,
													route: "/charity-shops-list"
												}
											]}
											entitlement={pointsEntitlement}
											entitlementType={POINTS_ENTITLEMENT_NAME}
											verified={accountInfo?.verified}
											activateCondition={details && hasShops}
											returnTranslation={returnTranslation}
											customFilledIconDone={<img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donate-select-on-map.png" />}
											customFilledIconUndone={<img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donate-select-on-map.png" />}
										/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	)
}

const mapStateToProps = (state: any) => {
	const { error } = state.Login
	const { dashbord_id, user } = state.Account
	const { countries } = state.Countries
	return { error, dashbord_id, user, countries }
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		onApiError: () => apiError(),
		onOpenAlert: ({ text, status }: any) =>
			dispatch(openAlert({ text: text, status: status })),
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EnableCharityShops)
)
