import PropTypes from 'prop-types'
import React, { useState, useContext, useEffect } from "react"
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"
import { connect, useDispatch, useSelector } from "react-redux"
// @ts-ignore
import { Link, RouteComponentProps, withRouter } from "react-router-dom"
// @ts-ignore
import { AvField, AvForm } from "availity-reactstrap-validation"
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import { apiError, openAlert } from "../../store/actions"
import { resetPassword, checkEmail } from "./../../helpers/backend_helper"

interface ForgetPasswordInterface extends RouteComponentProps {
  history: any,
  returnTranslation: (name: string) => string,
  error: string
}

const ForgetPassword = ({ history, returnTranslation, error }: ForgetPasswordInterface) => {
  const [email, setEmail] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>('');
  const dispatch = useDispatch()

  const handleResetPassword = async (event: any, values: any) => {
    try {
      const response = await resetPassword({
        email: email,
        code: values.code,
        password: values.newPassword,
        password_confirmation: values.newPasswordConfirmation
      })
      const { status } = response;
      dispatch(openAlert({ text: "Password reset successful. You can now login to your account using new password.", status: "success" }))
      history?.push("/login", { email })
    } catch (error) {
      //@ts-ignore
      if (error?.response?.data) {
        //@ts-ignore
        const { message } = error?.response?.data
        dispatch(openAlert({ text: message, status: "danger" }))
      }
    }
  };

  const handleSendCode = async (e: any) => {
    e.preventDefault();

    if (!!email) {
      try {
        const response = await checkEmail({
          email
        });
        const { status } = response;
        dispatch(openAlert({ text: "Code sent.", status: "success" }))
      } catch (error) {
        //@ts-ignore
        if (error?.response?.data) {
          //@ts-ignore
          const { message } = error?.response?.data
          dispatch(openAlert({ text: message, status: "danger" }))
        }
      }
    }
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-2 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
             <Col md={8} lg={7} xl={6}>
              <Card className="overflow-hidden">
                <Row>
                  <Col className="col-12">
                    <div className="p-4">
                      <h1>Reset password</h1>
                    </div>
                  </Col>
                </Row>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={handleResetPassword}
                    >
                      {error ? (
                        <Alert color="danger">{error}</Alert>
                      ) : null}


                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          value={email}
                          onChange={(e: any) => setEmail(e.target.value)}
                          type="text"
                          required
                        />
                      </div>

                      <div className="mt-3 mb-3 d-grid">
                        <button
                          className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
                          onClick={(e) => handleSendCode(e)}
                        >
                          Send Code
                        </button>
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="code"
                          label="Code"
                          className="form-control"
                          placeholder="Enter code"
                          value={code}
                          type="text"
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="newPassword"
                          label="New Password"
                          className="form-control"
                          placeholder="Enter new password"
                          value={newPassword}
                          type="password"
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="newPasswordConfirmation"
                          label="New Password Confirmation"
                          className="form-control"
                          placeholder="Enter new password confirmation"
                          value={newPasswordConfirmation}
                          type="password"
                        />
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          Reset
                        </button>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Back to {" "}
                  <Link
                    to="login"
                    className="fw-medium text-primary"
                  >
                    Log in
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )

}

export default withRouter(ForgetPassword)