import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    Col,
    Container,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
} from "reactstrap"
import { withRouter } from "react-router-dom"
import { map } from "lodash"
import Breadcrumbs from "./../../../components/Common/Breadcrumb"
import "./Docs.scss"

class Docs extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        // const { page, totalPage, projects } = this.state
        const { returnTranslation } = this.props;

        return (
            <React.Fragment>
                <div className="page-content docs__container">
                    <Container fluid>
                        <Breadcrumbs title={returnTranslation("Documentation JS & API")} breadcrumbItem={returnTranslation("Documentation")} />

                        <Row>
                            <Col>
                                <div className="mt-4 mb-5">
                                    <h5 className="welcome-header">What is LOOP?</h5>
                                    <p className="mt-3">When integrated into your website LOOP allows your customers to add any purchased items to their own digital wardrobe. On LOOP any item from the wardrobe can be easily returned, resold, swapped, recycled or donated to charity in just a few clicks. All that’s required to start looping is to create an account by providing an email address and a verification code sent to that email address.</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="mb-5">
                                    <h5 className="welcome-header">What is web integration?</h5>
                                    <p className="mt-3">LOOP web integration process is quite simple and only requires to:</p>
                                    <p><b>2.1.</b> Define the configuration settings for your domain through the LOOP retailers dashboard. You'll be asked to provide the following data: domain address, container selector for which the plugin will be generated, its location, etc. In this step the configuration identifier will be generated (further in the document as  ‘SITE_UUID’)</p>
                                    <p><b>2.2.</b> Add a script tag to the head of the page with the specific URL and HTML div container where widget should be loaded. The script should only appear on the ‘Thank You’ page after finalizing the order:</p>
                                    <p>
                                        <pre>{`<script 
   id="loop_plugin_configuration_url" 
   loop-configuration-uuid="SITE_UUID" src="https://app.loopdigitalwardrobe.com/js/plugin/loop-widget.js"
></script>`}
                                        </pre>
                                    </p>
                                    <p><b>2.3.</b> Create a method that retrieves the details of the last order: order number, item details: brand, category, colour, currency, ean, sku, images, name, price, size, etc.</p>
                                    <p><b>2.4.</b> Assign the value of products from the last order to the variable on the page window object:</p>
                                    <p><pre>window.LoopCustomerItems = [];</pre></p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="mb-5">
                                    <h5 className="welcome-header">How to set-up script on the website?</h5>
                                    <p className="mt-3"><b>3.1.</b> Plugin initialization</p>
                                    <p>Paste the downloaded code from the LOOP retailers dashboard in the {"<head>"} section of HTML document</p>
                                    <p>
                                        <pre>{`<!DOCTYPE html>
<html lang="en">

<head>
    <title>Retailer site</title>
    
<script
id="loop_plugin_configuration_url" 
loop-configuration-uuid="uuid_from_dashboard" src="https://app.loopdigitalwardrobe.com/js/plugin/loop-widget.js"></script>

</head>
<body>
    <div id="loop-digital-wardrobe"></div>  
</body>
</html>`}
                                        </pre>
                                    </p>
                                    <p><b>3.2.</b> Provide the product details from the order</p>
                                    <p>
                                        <pre>{`window.LoopCustomerItems = [
{
    name: "Product Name", // string
    size: "Product Size", // string
    color: "Product Color", // string
    brand: "Product Brand Name", // string
    order_number: "Order Number", // string
    price: 19.99, // Product Price | decimal | format xx.xx
    final_price: 10.99, // Product Final Price after discount | decimal | format xx.xx
    currency: "GBP", // Currency Code | string(3) - ISO 4217
    sku: "Product SKU", // string
    ean: "Product EAN", // string
    buy_at: "2021-12-03T12:34:45.026Z", // date
    category: "Product Category Tree", // string
    gender: "Product Gender - Men/Women/Kids", // string
    type: "SIMPLE", // string
    images: [
    // e.g. https://example.com/path/image.png
    //full path with protocol
    "absolute path to image from store",
    "absolute path to image from store",
    "absolute path to image from store",
    ],
},
{...},
{...},
...
];`}</pre>
                                    </p>
                                    <p>Field description:</p>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td><b>Name / Required</b></td>
                                                <td><b>Type</b></td>
                                                <td><b>Description</b></td>
                                            </tr>
                                            <tr>
                                                <td>name<br /><span style={{ color: "red" }}>REQUIRED</span></td>
                                                <td>string<br />max. XX char.</td>
                                                <td>Product name</td>
                                            </tr>
                                            <tr>
                                                <td>size</td>
                                                <td>string</td>
                                                <td>Product size</td>
                                            </tr>
                                            <tr>
                                                <td>color</td>
                                                <td>string</td>
                                                <td>Product color</td>
                                            </tr>
                                            <tr>
                                                <td>brand</td>
                                                <td>string</td>
                                                <td>Product brand name</td>
                                            </tr>
                                            <tr>
                                                <td>order_number</td>
                                                <td>string</td>
                                                <td>Order Number</td>
                                            </tr>
                                            <tr>
                                                <td>price</td>
                                                <td>decimal</td>
                                                <td>Product price, format xx.xx</td>
                                            </tr>
                                            <tr>
                                                <td>final_price</td>
                                                <td>decimal</td>
                                                <td>Product final price after discount, format xx.xx</td>
                                            </tr>
                                            <tr>
                                                <td>currency</td>
                                                <td>string(3)</td>
                                                <td>Three character currency code according to standards<br />ISO 4217</td>
                                            </tr>
                                            <tr>
                                                <td>sku</td>
                                                <td>string</td>
                                                <td>Product SKU code</td>
                                            </tr>
                                            <tr>
                                                <td>ean</td>
                                                <td>string</td>
                                                <td>Product EAN code</td>
                                            </tr>
                                            <tr>
                                                <td>buy_at</td>
                                                <td>date</td>
                                                <td>Purchase date</td>
                                            </tr>
                                            <tr>
                                                <td>category</td>
                                                <td>string</td>
                                                <td>Product Category Tree</td>
                                            </tr>
                                            <tr>
                                                <td>gender</td>
                                                <td>string</td>
                                                <td>Product Gender - Men/Women/Kids</td>
                                            </tr>
                                            <tr>
                                                <td>type</td>
                                                <td>string</td>
                                                <td>‘SIMPLE’</td>
                                            </tr>
                                            <tr>
                                                <td>Images<br /><span style={{ color: "red" }}>REQUIRED</span></td>
                                                <td>array</td>
                                                <td>Array of urls addresses, full path with protocol</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p><b>3.3.</b> The plugin will be generated as an iframe on the partner's website. The products transfer to LOOP will be done automatically if the LoopCustomerItems variable is initialized to the window object.</p>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className="mb-5">
                                    <h5 className="welcome-header">What is mobile app integration?</h5>
                                    <p className="mt-3">LOOP mobile app integration process is quite simple and only requires to: </p>
                                    <p><b>4.1.</b> Define the configuration settings for your domain through the LOOP retailers dashboard. You'll be asked to provide the following data: domain address. In this step the configuration identifier will be generated (further in the document as  ‘SITE_UUID’)</p>
                                    <p><b>4.2.</b> After the ordering process order data is sent to LOOP API</p>
                                    <p>POST {"https://api.loopdigitalwardrobe.com/api/sites/{SITE_UUID}/products"}</p>
                                    <p>content-type: application/json</p>
                                    <p>
                                        <pre>{`{
    "order": {
       "number": "87070212", // string
       "buy_at": "2021-12-03T12:34:45.026Z", // date
       "currency": "GBP", // string ISO 4217
       "products": [
          {
             name: "Product Name", // string
             size: "Product Size", // string
             color: "Product Color", // string
             brand: "Product Brand Name", // string
             order_number: "Order Number", // string
             price: 19.99, // Product Price | decimal | format xx.xx
             final_price: 10.99, // Product Final Price after discount | decimal | format xx.xx
             currency: "GBP", // Currency Code | string(3) - ISO 4217
             sku: "Product SKU", // string
             ean: "Product EAN", // string
             buy_at: "2021-12-03T12:34:45.026Z", // date
             category: "Product Category Tree", // string
             gender: "Product Gender - Men/Women/Kids", // string
             type: "SIMPLE", // string
             images: [
                // e.g. https://example.com/path/image.png
                //full path with protocol
                "absolute path to image from store",
                "absolute path to image from store",
                 "absolute path to image from store",
              ],
           },
         {...},
         {...}
       }
 }`}
                                        </pre>
                                    </p>

                                    <p>Field description:</p>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td><b>Name / Required</b></td>
                                                <td><b>Type</b></td>
                                                <td><b>Description</b></td>
                                            </tr>
                                            <tr>
                                                <td>name<br /><span style={{ color: "red" }}>REQUIRED</span></td>
                                                <td>string<br />max. XX char.</td>
                                                <td>Product name</td>
                                            </tr>
                                            <tr>
                                                <td>size</td>
                                                <td>string</td>
                                                <td>Product size</td>
                                            </tr>
                                            <tr>
                                                <td>color</td>
                                                <td>string</td>
                                                <td>Product color</td>
                                            </tr>
                                            <tr>
                                                <td>brand</td>
                                                <td>string</td>
                                                <td>Product brand name</td>
                                            </tr>
                                            <tr>
                                                <td>order_number</td>
                                                <td>string</td>
                                                <td>Order Number</td>
                                            </tr>
                                            <tr>
                                                <td>price</td>
                                                <td>decimal</td>
                                                <td>Product price, format xx.xx</td>
                                            </tr>
                                            <tr>
                                                <td>final_price</td>
                                                <td>decimal</td>
                                                <td>Product final price after discount, format xx.xx</td>
                                            </tr>
                                            <tr>
                                                <td>currency</td>
                                                <td>string(3)</td>
                                                <td>Three character currency code according to standards<br />ISO 4217</td>
                                            </tr>
                                            <tr>
                                                <td>sku</td>
                                                <td>string</td>
                                                <td>Product SKU code</td>
                                            </tr>
                                            <tr>
                                                <td>ean</td>
                                                <td>string</td>
                                                <td>Product EAN code</td>
                                            </tr>
                                            <tr>
                                                <td>buy_at</td>
                                                <td>date</td>
                                                <td>Purchase date</td>
                                            </tr>
                                            <tr>
                                                <td>category</td>
                                                <td>string</td>
                                                <td>Product Category Tree</td>
                                            </tr>
                                            <tr>
                                                <td>gender</td>
                                                <td>string</td>
                                                <td>Product Gender - Men/Women/Kids</td>
                                            </tr>
                                            <tr>
                                                <td>type</td>
                                                <td>string</td>
                                                <td>‘SIMPLE’</td>
                                            </tr>
                                            <tr>
                                                <td>Images<br /><span style={{ color: "red" }}>REQUIRED</span></td>
                                                <td>array</td>
                                                <td>Array of urls addresses, full path with protocol</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p><b>4.3.</b> Opening the URL from the received response in the browser</p>
                                    <p><pre>{`{
    ‘url’: ‘https://app.loopdigitalwardrobe.com/initInWeb/g6k9Y1vvtk71_qFX’
}`}</pre></p>
                                    <p><b>4.4.</b> The products transfer to LOOP will be done automatically after the user logs in.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Docs))
