// @ts-ignore
import axios from "axios";
import React, { FormEvent, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
// @ts-ignore
import MetaTags from 'react-meta-tags';
// @ts-ignore
import { connect, useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { RouteComponentProps, useHistory, useParams } from "react-router";
// @ts-ignore
import { withRouter } from "react-router-dom";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container, Form, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Row, Spinner
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ImageCropper from "../../../components/ImageCropper/ImageCropper";
import { addTakeBack, getTakeBack, updateTakeBack } from "../../../helpers/backend_helper";
import * as url from "../../../helpers/url_helper";
import { apiError, getTakeBacks } from "../../../store/actions";
import { openAlert } from "../../../store/alert/actions";

const formErrorSvg = "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/%3e%3c/svg%3e" 

export interface Props extends RouteComponentProps {
	dashbord_id: string,
	apiError: (err: string) => {},
	error: string,
	user: any,
	onOpenAlert: any,
	returnTranslation: any
}

const TakeBackForm = ({ returnTranslation, onOpenAlert }: Props) => {
	const history = useHistory();
	const params: { takeback_id: string } = useParams();
	const dispatch = useDispatch();
	const dashboardID = useSelector((state: any) => state?.Account?.dashbord_id)
	const userToken = useSelector((state: any) => state?.Account?.user?.access_token)

	const [details, setDetails] = useState<any>(null)
	const [description, setDescription] = useState("")
	const [logo, setLogo] = useState<any>({})
	const [name, setName] = useState("")
	const [formErrors, setFormErrors] = useState<any>({})
	const [filesToResize, setFilesToResize] = useState<any>([]);
	const [isFormDisabled, setIsFormDisabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

	// const onCheckAccount = () => dispatch(checkAccount());
	// const details = useSelector((state: any) => state.Account?.info?.configuration?.details)
	// const entitlements = useSelector((state: any) => state.Account?.info?.configuration?.entitlements)

	// const VIRTUAL_CHARITY = "VIRTUAL_CHARITY"
	// const SEND_BY_POST = "SEND_BY_POST"
	// const CHARITY_SHOPS = "CHARITY_SHOPS"

	// useEffect(() => {
	// 	const charityShops = entitlements?.[CHARITY_SHOPS]
	// 	const sendByPost = entitlements?.[SEND_BY_POST]
	// 	const virtualCharity = entitlements?.[VIRTUAL_CHARITY]
	// 	const anyEntitlementWasActive = sendByPost?.status || virtualCharity?.status || charityShops?.status;
	// 	//if any entitlement was activated anytime && details filled -> dont allow to change details
	// 	setIsFormDisabled(!!(anyEntitlementWasActive && details))
	// }, [entitlements, details])

	useEffect(() => {
		if(params?.takeback_id){
			getDetails();
			setIsEdit(true)
		}
	}, [params?.takeback_id])


	const getDetails = async () => {
		try {
			setIsLoading(true)
			const response = await getTakeBack(params?.takeback_id);

			// setReason(entitlements[VIRTUAL_CHARITY]?.reason)
			// setStatus(entitlements[VIRTUAL_CHARITY]?.status)

			// setDonationReason(entitlements[SEND_BY_POST]?.reason)
			// setDonationStatus(entitlements[SEND_BY_POST]?.status)

			// setSwitchEnabled(entitlements[VIRTUAL_CHARITY]?.status === "ACTIVE" && verified)
			// setSwitchEnabledDonation(entitlements[SEND_BY_POST]?.status === "ACTIVE" && verified)
			setLogo(response?.logo)
			setName(response?.name)
			setDescription(response?.description)
			setDetails(response)
			return response
		} catch (e) {
			// console.log(e);
			onOpenAlert({ text: "Error. Try again later.", status: "danger" });
			return {}
		}finally{
			setIsLoading(false);
		}
	}

	const handleSubmit = async (event: FormEvent) => {
		// console.log(event)
		event?.preventDefault()
		setFormErrors({})
		try {
			if(isEdit){
				const response = await updateTakeBack(params?.takeback_id, {
					name,
					description,
					logo: logo.id,
					status: details?.status
				})
				onOpenAlert({ status: 'success', text: returnTranslation("Changes saved successfully.") })
			}else{
				const response = await addTakeBack({
					name,
					description,
					logo: logo.id,
					status: 0
				})
				onOpenAlert({ status: 'success', text: returnTranslation("Added successfully.") })
			}
			dispatch(getTakeBacks());
			// history?.push("/take-back")
			
				// onCheckAccount();
			// } else {
			// 	onOpenAlert({ status: 'danger', text: returnTranslation("Please fill required fields.") })
			// 	setFormErrors({
			// 		username: !name,
			// 		//if charity status is active, additionaly check other fields, bcs its required when virtual charity is active
			// 		...(charityStatusActive ? {
			// 			description: !description,
			// 			logo: !logo?.id,
			// 			website: !websiteUrl
			// 		}: {})
			// 	})
			// }
		} catch (e) {
			//@ts-ignore
			const { message, errors } = e?.response?.data;
			setFormErrors(errors)
			onOpenAlert({ status: 'danger', text: returnTranslation(message) })
			// console.log(e)
		}
	}

	const handleAcceptedFiles = (files: any, type: string) => {
		files.map(async (file: any) => {
			const reader = new FileReader()

			if (file.type.match("image.*")) {
				reader.onabort = () => console.log("file reading was aborted")
				reader.onerror = () => console.log("file reading has failed")
				reader.onload = async () => {
					//   const binaryStr = reader.result
					file = new Blob([file], { type: "image/png" })

					try {
						const response: any = await handleUploadPhoto(file)
						// console.log(['response', response]);
						Object.assign(file, {
							id: response?.id,
							url: URL.createObjectURL(file),
						})

						// setLogo(file)
						setFilesToResize([file]);
					} catch (err) {
						onOpenAlert({ text: 'error', status: "danger" })
					}
				}
				reader.readAsDataURL(file)
			} else {
				onOpenAlert({ text: returnTranslation("Import image type"), status: "danger" })
			}
		})
	}

	const handleUploadPhoto = async (photo: any) => {
		return new Promise((resolve, reject) => {
			let config = {
				headers: {
					'Content-Type': 'multipart/form-data',
					"Client-UUID": dashboardID,
					Authorization: `Bearer ${userToken}`,
				},
			}

			const formData = new FormData()

			formData.append("file", photo)

			axios
				.post(`${url.API_BASE}files`, formData, config)
				.then(response => {
					resolve(response?.data)
				})
				.catch(error => {
					if (error?.response?.data) {
						const { message, errors } = error?.response?.data

						if (message === "Unauthenticated.") {
							localStorage.setItem("authUser", "")
						}

						openAlert({ text: message, status: "danger" })
					}
					return reject(error)
				})
		})
	}

	const handleSaveCroppedImage = async (croppedImageBlob: any) => {
		try {
			const response: any = await handleUploadPhoto(croppedImageBlob);

			Object.assign(croppedImageBlob, {
				id: response?.id,
				path: URL.createObjectURL(croppedImageBlob),
			})

			let filesToResizeCopy = [...filesToResize];
			filesToResizeCopy.shift();
			setFilesToResize(filesToResizeCopy)

			setLogo(croppedImageBlob)
		} catch (e) {
			// console.log(e);
		}
	}

	// const getMissingInfoMessageText = () => {
	// 	if(!config) return false;
	// 	let text = returnTranslation("The following information is missing: ")
	// 	let missingArr = []
	// 	if(!config?.logo?.id){
	// 		missingArr.push("Logo")
	// 	}
	// 	if(!config?.username){
	// 		missingArr.push("Short name")
	// 	}
	// 	if(!config?.description){
	// 		missingArr.push("About us")
	// 	}
	// 	if(!config?.website){
	// 		missingArr.push("Website URL")
	// 	}

	// 	if(!missingArr?.length){
	// 		return false
	// 	}

	// 	return text + missingArr?.join(", ");
	// }

	return (
		<React.Fragment>
			<MetaTags>
				<title>Take Back Form</title>
			</MetaTags>
			<div className="page-content">
				<Container fluid>
					<Breadcrumb title="Take Back" breadcrumbItem={`Take Back Details ${details?.name ? `(${details?.name})` : ""}`} />

					<Row>
						<Col sm={12} md={12} lg={12} xl={7}>
							<Card>
								<CardBody>
									{isLoading ? (
										<div className="w-100 h-100 d-flex justify-content-center align-items-center">
											<Spinner className="ms-2" color="primary" />
										</div>
									): (
										<Form
											className="form-horizontal"
											onSubmit={handleSubmit}
										>
											<div className="mb-3">
												<FormGroup row>
													<div className="d-flex align-items-center">
														<Label className={`col-form-label pt-1 ${formErrors?.logo ? "text-danger" : ""}`}>
															<span>{returnTranslation("Logo")}</span>
															{formErrors?.logo ? 
																<span className="ms-1">
																	<img height="16" src={formErrorSvg} />
																</span>
															: null}
														</Label>
													</div>

													<div className="d-flex align-items-center">
														{logo?.path ? (
															<img src={logo?.path}
																style={{ width: 100, borderRadius: '100%' }}
															/>
														) : (
															<div style={{ height: 100, width: 100, borderRadius: '100%', backgroundColor: "#ebebeb" }} />
														)}
														<Dropzone
															onDrop={acceptedFiles =>
																handleAcceptedFiles(acceptedFiles, "background")
															}
															disabled={isFormDisabled}
														>
															{({ getRootProps, getInputProps }) => (
																<div {...getRootProps()}>
																	<input {...getInputProps()} />
																	<Button
																		type="button"
																		className="m-2 btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
																		disabled={isFormDisabled}
																	>
																		{returnTranslation(logo?.url ? "Edit" : "Upload")}
																	</Button>
																</div>
															)}
														</Dropzone>
														{/* {logo?.id ?
															<Button onClick={() => setLogo(null)} type="button" className="m-2 btn-no-shadow btn btn-danger btn-block waves-effect waves-light">
																X
															</Button>
														: null} */}
													</div>

													<Modal isOpen={filesToResize?.length}
														centered={true}
													>
														<ModalHeader>
															{returnTranslation("Crop image")}
														</ModalHeader>
														<ModalBody>
															<ImageCropper
																image={filesToResize[0]}
																onSave={handleSaveCroppedImage}
																cropRadius={true}
																returnTranslation={returnTranslation}
																setImage={() => setFilesToResize([])}
																showZoomSlider={true}
															/>
														</ModalBody>
													</Modal>
												</FormGroup>
											</div>

											<div className="form-group">
												<Label>{returnTranslation("Name")}</Label>
												<InputGroup>
													<Input
														name="name"
														label="Name"
														value={name}
														className="form-control"
														placeholder={returnTranslation("Enter short name")}
														type="text"
														onChange={(e: any) => setName(e.target.value)}
														invalid={formErrors?.name}
														disabled={isFormDisabled}
													/>
												</InputGroup>
											</div>

											<div className="form-group">
												<Label>{returnTranslation("Description")}</Label>
												<InputGroup>
													<Input
														name="description"
														label="Description"
														value={description}
														className="form-control"
														placeholder={returnTranslation("Enter text")}
														type="textarea"
														rows={12}
														resize={false}
														onChange={(e: any) => setDescription(e.target.value)}
														invalid={formErrors?.description}
														disabled={isFormDisabled}
													/>
												</InputGroup>
											</div>

											<div className="mb-3 d-flex gap-2">
												<Button
													color="success"
													type="submit"
													className="btn-no-shadow btn-block waves-effect waves-light"
													disabled={isFormDisabled}
												>
													{returnTranslation("Save")}
												</Button>
												{/* @ts-ignore */}
												{history?.location?.state?.from
													? (
														<Button
															onClick={() => history?.goBack()}
															type="button"
															className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
														>
															{returnTranslation("Back")}
														</Button>	
													) : null
												}
											</div>
											
										</Form>
									)}
									{/* <h4 className="card-title mb-4">{returnTranslation("Charity Details")}</h4> */}
									{/* <ContactUsAlert
										display={isFormDisabled}
										returnTranslation={returnTranslation}
										onOpenAlert={onOpenAlert}
									/>
									<ContactUsAlert
										//@ts-ignore
										customText={getMissingInfoMessageText()}
										display={getMissingInfoMessageText() ? true : false}
										returnTranslation={returnTranslation}
										onOpenAlert={onOpenAlert}
									/> */}
									
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	)
}

const mapStateToProps = (state: any) => {
	const { error } = state.Login
	const { dashbord_id, user } = state.Account
	const { countries } = state.Countries
	return { error, dashbord_id, user, countries }
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		onApiError: () => apiError(),
		onOpenAlert: ({ text, status }: any) =>
			dispatch(openAlert({ text: text, status: status })),
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(TakeBackForm)
)
