import { OPEN_ALERT, CLOSE_ALERT } from "./actionTypes"

const INIT_STATE = {
  text: null,
  status: null,
}

const alert = (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPEN_ALERT:
      const { text, status } = action ?.payload
      return {
        text,
        status,
      }
    case CLOSE_ALERT:
      return {
        text: null,
        status: null,
      }
    default:
      return state
  }
}

export default alert
