import { GET_CHARITY_SHOPS, GET_CHARITY_SHOPS_FAIL, GET_CHARITY_SHOPS_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  charityShops: [],
  info: {},
  isLoading: true
}

const CharityShops = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHARITY_SHOPS:
      return {
        ...state,
        isLoading: true
      }
    case GET_CHARITY_SHOPS_SUCCESS:
      return {
        ...state,
        charityShops: action?.payload?.items,
        info: action?.payload?.info,
        isLoading: false
      }

    case GET_CHARITY_SHOPS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      }
    default:
      return state
  }
}

export default CharityShops
