import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { CartesianGrid, ComposedChart, Legend, Line, Bar, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Area, LabelList } from 'recharts';
import LogoXAxisTick from './LogoXAxisTick/LogoXAxisTick';
import "./LoopReChart.scss"

interface Props{
    height: number;
    data: any[];
    xAxisDataKey?: string;
    showLoaderOnEmptyData: boolean;
    lines?: { type: "linear" | "monotone", dataKey: string, name: string, stroke: string, activeDotSize: number, label?: { dataKey: string } }[];
    bars?: { dataKey: string, name: string, fill: string, barSize: number, stackId?: string, label?: { dataKey: string } }[];
    areas?: { type: "linear" | "monotone", dataKey: string, name: string, stroke: string }[];
    displayLogo?: boolean;
    xAxisReversed?: boolean;
    customElement?: any;
    dataMaxKey?: string;
    isLoading?: boolean;
    layout?: 'horizontal' | 'vertical';
}

const LoopReChart = ({ height, data, showLoaderOnEmptyData, lines, bars, areas, xAxisDataKey, displayLogo, xAxisReversed, customElement, dataMaxKey, isLoading, layout = "horizontal" }: Props) => {
    const [max, setMax] = useState<number | null>(null);

    useEffect(() => {
        if(dataMaxKey){
            setMax(countTotalY());
        }
    }, [data])

    const countTotalY = () => {
        //@ts-ignore
        let dataMax = [...data]?.sort((a, b) => a?.[dataMaxKey] > b?.[dataMaxKey] ? -1 : 1)[0]?.[dataMaxKey];
        // console.log(["dataMax", dataMax])
        if (dataMax < 15) {
            return 20;
        } else if (dataMax < 30) {
            return 40;
        } else if (dataMax < 50) {
            return 60;
        } else if (dataMax < 85) {
            return 100;
        } else if (dataMax < 130) {
            // console.log(dataMax, "< 130")
            return Math.round(Math.round(dataMax * 120 / 100) / 10) * 10;
        } else if (dataMax < 1300) {
            // console.log(dataMax, "< 1300")
            return Math.round(Math.round(dataMax * 120 / 100) / 100) * 100;
        } else if (dataMax < 13000) {
            // console.log(dataMax, "< 13 000")
            return Math.round(Math.round(dataMax * 120 / 100) / 1000) * 1000;
        } else if (dataMax < 130000) {
            // console.log(dataMax, "< 130 000")
            return Math.round(Math.round(dataMax * 120 / 100) / 10000) * 10000;
        } else if (dataMax < 1300000) {
            // console.log(dataMax, "< 1 300 000")
            return Math.round(Math.round(dataMax * 120 / 100) / 100000) * 100000;
        }

        return null
    }

    return (
        <div className={layout === "vertical" ? `loop-line-chart--scroll-container` : ""}>
            <div className="w-100 loop-line-chart"
                style={{
                    height
                }}
            >

                {isLoading || (showLoaderOnEmptyData && (!data || !data?.length)) ? (
                    <div className="w-100 h-100 d-flex justify-content-center align-items-center chart-loader">
                        <Spinner className="ms-2" color="secondary" />
                    </div>
                ): null}
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        layout={layout ?? 'horizontal'}
                        // width={500}
                        // height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        barGap={0}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        {layout === "horizontal" ?(
                            <>
                                {displayLogo ? (
                                    <XAxis
                                        reversed={xAxisReversed}
                                        dataKey={xAxisDataKey}
                                        tick={(tick) => <LogoXAxisTick tick={tick} data={data} />}
                                        height={70}
                                        allowDataOverflow={true}
                                        spacing={0}
                                    />
                                ) : (
                                    <XAxis reversed={xAxisReversed} dataKey={xAxisDataKey} allowDataOverflow={true} />
                                )}
                                <YAxis domain={(dataMaxKey && max) ? [0, max] : undefined} />
                            </>
                        ) : (
                            <>
                                <XAxis type="number" />
                                <YAxis dataKey={xAxisDataKey} type="category" scale="band" />
                            </>
                        )}

                        <Tooltip />
                        {customElement && customElement}
                        <Legend />
                        {areas?.map(area => (
                            <Area key={area?.dataKey} type={area?.type} dataKey={area?.dataKey} name={area?.name} fill={area?.stroke} stroke={area?.stroke} />
                        ))}
                        {bars?.map(bar => (
                            <Bar key={bar?.dataKey} dataKey={bar?.dataKey} name={bar?.name} fill={bar?.fill} barSize={bar?.barSize} stackId={bar?.stackId}>
                                {bar?.label ? (
                                    <LabelList dataKey={bar?.label?.dataKey} position="top" />
                                ): null}
                            </Bar>
                        ))}
                        {lines?.map(line => (
                            <Line key={line?.dataKey} type={line?.type} dataKey={line?.dataKey} name={line?.name} stroke={line?.stroke} activeDot={{ r: line?.activeDotSize }}>
                                {line?.label ? (
                                    <LabelList dataKey={line?.label?.dataKey} position="top" />
                                ): null}
                            </Line>
                        ))}
                        {/* <Line type="linear" dataKey="total" name="Total" stroke="#e15554" activeDot={{ r: 8 }} />
                        <Line type="linear" dataKey="added" name="Added" stroke="#4d9de0" activeDot={{ r: 5 }} />
                        <Line type="linear" dataKey="updated" name="Updated" stroke="#75cd89" activeDot={{ r: 5 }} />
                        <Line type="linear" dataKey="total_aws" name="Total AWS" stroke="#e1bc29" activeDot={{ r: 8 }} />
                        <Line type="linear" dataKey="added_aws" name="Added AWS" stroke="black" activeDot={{ r: 5 }} />
                        <Line type="linear" dataKey="updated_aws" name="Updated AWS" stroke="#7768ae" activeDot={{ r: 5 }} /> */}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

export default LoopReChart




// import React, { useEffect, useState } from 'react';
// import { Spinner } from 'reactstrap';
// import { CartesianGrid, ComposedChart, Legend, Line, Bar, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Area, LabelList, PieChart, Pie } from 'recharts';
// import LogoXAxisTick from './LogoXAxisTick/LogoXAxisTick';
// import "./LoopReChart.scss"

// interface Props{
//     height: number;
//     data: any[];
//     xAxisDataKey?: string;
//     showLoaderOnEmptyData: boolean;
//     lines?: { type: "linear" | "monotone", dataKey: string, name: string, stroke: string, activeDotSize: number, label?: { dataKey: string } }[];
//     bars?: { dataKey: string, name: string, fill: string, barSize: number, stackId?: string, label?: { dataKey: string } }[];
//     areas?: { type: "linear" | "monotone", dataKey: string, name: string, stroke: string }[];
//     displayLogo?: boolean;
//     xAxisReversed?: boolean;
//     customElement?: any;
//     dataMaxKey?: string;
//     isLoading?: boolean;
//     layout?: 'horizontal' | 'vertical';
//     chartType?: 'composed' | 'pie';
//     pies?: { dataKey: string, data: any[], cx?: string, cy?: string, outerRadius?: number, innerRadius?: number, fill: string, showLabel?: boolean}[]
// }

// const LoopReChart = ({ height, data, showLoaderOnEmptyData, lines, bars, areas, xAxisDataKey, displayLogo, xAxisReversed, customElement, dataMaxKey, isLoading, layout = "horizontal", chartType = "composed", pies }: Props) => {
//     const [max, setMax] = useState<number | null>(null);

//     useEffect(() => {
//         if(dataMaxKey){
//             setMax(countTotalY());
//         }
//     }, [data])

//     const countTotalY = () => {
//         //@ts-ignore
//         let dataMax = [...data]?.sort((a, b) => a?.[dataMaxKey] > b?.[dataMaxKey] ? -1 : 1)[0]?.[dataMaxKey];
//         // console.log(["dataMax", dataMax])
//         if (dataMax < 15) {
//             return 20;
//         } else if (dataMax < 30) {
//             return 40;
//         } else if (dataMax < 50) {
//             return 60;
//         } else if (dataMax < 85) {
//             return 100;
//         } else if (dataMax < 130) {
//             // console.log(dataMax, "< 130")
//             return Math.round(Math.round(dataMax * 120 / 100) / 10) * 10;
//         } else if (dataMax < 1300) {
//             // console.log(dataMax, "< 1300")
//             return Math.round(Math.round(dataMax * 120 / 100) / 100) * 100;
//         } else if (dataMax < 13000) {
//             // console.log(dataMax, "< 13 000")
//             return Math.round(Math.round(dataMax * 120 / 100) / 1000) * 1000;
//         } else if (dataMax < 130000) {
//             // console.log(dataMax, "< 130 000")
//             return Math.round(Math.round(dataMax * 120 / 100) / 10000) * 10000;
//         } else if (dataMax < 1300000) {
//             // console.log(dataMax, "< 1 300 000")
//             return Math.round(Math.round(dataMax * 120 / 100) / 100000) * 100000;
//         }

//         return null
//     }

//     const data01 = [
//         { name: 'Group A', value: 400 },
//         { name: 'Group B', value: 300 },
//         { name: 'Group C', value: 300 },
//         { name: 'Group D', value: 200 },
//       ];
//       const data02 = [
//         { name: 'A1', value: 100 },
//         { name: 'A2', value: 300 },
//         { name: 'B1', value: 100 },
//         { name: 'B2', value: 80 },
//         { name: 'B3', value: 40 },
//         { name: 'B4', value: 30 },
//         { name: 'B5', value: 50 },
//         { name: 'C1', value: 100 },
//         { name: 'C2', value: 200 },
//         { name: 'D1', value: 150 },
//         { name: 'D2', value: 50 },
//       ];

//     return (
//         <div className={layout === "vertical" ? `loop-line-chart--scroll-container` : ""}>
//             <div className="w-100 loop-line-chart"
//                 style={{
//                     height
//                 }}
//             >

//                 {isLoading || (showLoaderOnEmptyData && (!data || !data?.length)) ? (
//                     <div className="w-100 h-100 d-flex justify-content-center align-items-center chart-loader">
//                         <Spinner className="ms-2" color="secondary" />
//                     </div>
//                 ): null}
                
//                 <ResponsiveContainer width="100%" height="100%">
//                     <>
//                         {chartType === "composed" ? (
//                             <ComposedChart
//                                 layout={layout ?? 'horizontal'}
//                                 // width={500}
//                                 // height={300}
//                                 data={data}
//                                 margin={{
//                                     top: 5,
//                                     right: 30,
//                                     left: 20,
//                                     bottom: 5,
//                                 }}
//                                 barGap={0}
//                             >
//                                 <CartesianGrid strokeDasharray="3 3" />
//                                 {layout === "horizontal" ?(
//                                     <>
//                                         {displayLogo ? (
//                                             <XAxis
//                                                 reversed={xAxisReversed}
//                                                 dataKey={xAxisDataKey}
//                                                 tick={(tick) => <LogoXAxisTick tick={tick} data={data} />}
//                                                 height={70}
//                                                 allowDataOverflow={true}
//                                                 spacing={0}
//                                             />
//                                         ) : (
//                                             <XAxis reversed={xAxisReversed} dataKey={xAxisDataKey} allowDataOverflow={true} />
//                                         )}
//                                         <YAxis domain={(dataMaxKey && max) ? [0, max] : undefined} />
//                                     </>
//                                 ) : (
//                                     <>
//                                         <XAxis type="number" />
//                                         <YAxis dataKey={xAxisDataKey} type="category" scale="band" />
//                                     </>
//                                 )}

//                                 <Tooltip />
//                                 {customElement && customElement}
//                                 <Legend />
//                                 {areas?.map(area => (
//                                     <Area key={area?.dataKey} type={area?.type} dataKey={area?.dataKey} name={area?.name} fill={area?.stroke} stroke={area?.stroke} />
//                                 ))}
//                                 {bars?.map(bar => (
//                                     <Bar key={bar?.dataKey} dataKey={bar?.dataKey} name={bar?.name} fill={bar?.fill} barSize={bar?.barSize} stackId={bar?.stackId}>
//                                         {bar?.label ? (
//                                             <LabelList dataKey={bar?.label?.dataKey} position="top" />
//                                         ): null}
//                                     </Bar>
//                                 ))}
//                                 {lines?.map(line => (
//                                     <Line key={line?.dataKey} type={line?.type} dataKey={line?.dataKey} name={line?.name} stroke={line?.stroke} activeDot={{ r: line?.activeDotSize }}>
//                                         {line?.label ? (
//                                             <LabelList dataKey={line?.label?.dataKey} position="top" />
//                                         ): null}
//                                     </Line>
//                                 ))}
//                                 {/* <Line type="linear" dataKey="total" name="Total" stroke="#e15554" activeDot={{ r: 8 }} />
//                                 <Line type="linear" dataKey="added" name="Added" stroke="#4d9de0" activeDot={{ r: 5 }} />
//                                 <Line type="linear" dataKey="updated" name="Updated" stroke="#75cd89" activeDot={{ r: 5 }} />
//                                 <Line type="linear" dataKey="total_aws" name="Total AWS" stroke="#e1bc29" activeDot={{ r: 8 }} />
//                                 <Line type="linear" dataKey="added_aws" name="Added AWS" stroke="black" activeDot={{ r: 5 }} />
//                                 <Line type="linear" dataKey="updated_aws" name="Updated AWS" stroke="#7768ae" activeDot={{ r: 5 }} /> */}
//                             </ComposedChart>
//                         ) : <></>}
                        
//                         {chartType === "pie" ? (
//                             <PieChart width={400} height={400}>
//                                 {/* {pies?.map(pie => (
//                                     <Pie key={pie?.dataKey}
//                                         dataKey={pie?.dataKey}
//                                         data={pie?.data}
//                                         cx={pie?.cx ? pie?.cx : "50%"}
//                                         cy={pie?.cy ? pie?.cy : "50%"}
//                                         outerRadius={pie?.outerRadius}
//                                         innerRadius={pie?.innerRadius}
//                                         fill={pie?.fill}
//                                         label={pie?.showLabel}
//                                     />
//                                 ))} */}
//                                 <Pie 
//                                     // data={data01} dataKey="value" cx="50%" cy="50%" outerRadius={60} fill="#8884d8"
//                                     dataKey={pies?.[0]?.dataKey ? pies?.[0]?.dataKey : 1}
//                                     data={pies?.[0]?.data}
//                                     cx={pies?.[0]?.cx ? pies?.[0]?.cx : "50%"}
//                                     cy={pies?.[0]?.cy ? pies?.[0]?.cy : "50%"}
//                                     outerRadius={pies?.[0]?.outerRadius}
//                                     fill={pies?.[0]?.fill}
//                                     // label={pies?.[0]?.showLabel}
//                                 />

//                                 <Pie 
//                                     // data={data01} dataKey="value" cx="50%" cy="50%" outerRadius={60} fill="#8884d8"
//                                     dataKey={pies?.[1]?.dataKey ? pies?.[1]?.dataKey : 2}
//                                     data={pies?.[1]?.data}
//                                     cx={pies?.[1]?.cx ? pies?.[1]?.cx : "50%"}
//                                     cy={pies?.[1]?.cy ? pies?.[1]?.cy : "50%"}
//                                     outerRadius={pies?.[1]?.outerRadius}
//                                     innerRadius={pies?.[1]?.innerRadius}
//                                     fill={pies?.[1]?.fill}
//                                     label={pies?.[1]?.showLabel}
//                                 />
//                                 {/* <Pie data={data02} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90} fill="#82ca9d" label /> */}
//                             </PieChart>
//                         ) : <></>}
//                     </>
//                 </ResponsiveContainer>
//             </div>
//         </div>
//     );
// }

// export default LoopReChart

