import React, { FormEvent, useEffect, useState } from "react"
// import { useForm } from "../../../../../../shop-front/hooks/form-hook"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
// import 'resources\js\commons\components\Authorization\auth\index.scss'
import { paypal, paypalDisonnect, paypalConnect } from "../../../helpers/backend_helper"
import { checkAccount, getPaymentInfo, openAlert, requestConfirmation } from "../../../store/actions"
import ConnectPaypal from "./ConnectPaypal/ConnectPaypal"
//@ts-ignore
import MetaTags from 'react-meta-tags';
import Breadcrumb from "../../../components/Common/Breadcrumb"

interface Props {
	returnTranslation: any
}

function PayoutsWithPaypal({ returnTranslation }: Props) {
	const paymentMethods = useSelector((state: any) => state?.paymentMethods?.items)
	const dispatch = useDispatch()

	// const [paypalInfo, setPaypalInfo] = useState<any>(false)
	const paypalAccount = useSelector((state: any) => state?.PaymentInfo?.info?.paypal_account);

	const onOpenAlert = (status: string, text: string) => {
		dispatch(openAlert({ text, status }))
	}

	// useEffect(() => {
	// 	getPaypalInfo()
	// }, [])

	const getUrl = async () => {
		try {
			const responseData = await paypalConnect();

			if (responseData?.url) {
				return responseData.url
			}
		} catch (e) {
			// console.log(e)
		}
	}

	const getPaypalInfo = async () => {
		dispatch(getPaymentInfo())
        dispatch(checkAccount())
	}

	const messageHandler = async (event: any) => {
		console.log(["messageHandler", event])
		if(!event?.data?.source?.includes("devtools")){
			if (event?.data?.error?.message) {
				onOpenAlert("error", event?.data?.error?.message)
				// //@ts-ignore
				// message.error({
				// 	content: event.data.error.message,
				// })
			}
	
			// if (event.data.status === "success") {
			// 	setLoaded(false)
			// 	getPaypalInfo()
			// 	onOpenAlert("success", returnTranslation("Payment method succesfuly connected"))
			// }
	
			getPaypalInfo()
			// dispatch(getPaymentInfo())
			// dispatch(checkAccount())
			// onOpenAlert("success", returnTranslation("Payment method succesfuly connected"))
		}
	}

	useEffect(() => {
		window.addEventListener("message", messageHandler)
		return () => {
			window.removeEventListener("message", messageHandler)
		}
	}, [])

	const handleConnectPaypal = async (type: string) => {
		const url = await getUrl()

		if (!url) {
			return
		}

		const newwindow = window.open("", type, "height=780,width=530")
		//@ts-ignore
		newwindow.location.href = url
		//@ts-ignore
		if (window.focus) {
			// newwindow.focus();
			//@ts-ignore
			newwindow.blur()
			//@ts-ignore
			setTimeout(newwindow.focus, 0)
		}
		return false
	}

	// const handleClickSocialModal = async (type: string, status: string) => {
	// 	setShowSocialConfirmModal(true)
	// 	setSocialConfirmModalType(type)

	// 	if (status === "CONNECT") {
	// 		setSocialConfirmModalMainText(translate(`"LOOP" wants to access to your "${type}.com" account.`))
	// 		setSocialConfirmModalSubText(
	// 			translate(`This will allow LOOP view and read information from your public profile`)
	// 		)
	// 	} else if (status === "DISCONNECT") {
	// 		setSocialConfirmModalMainText(translate(`Are you sure?`))
	// 		setSocialConfirmModalSubText("")
	// 	}
	// }


	const handleDisconnectPaypal = async () => {
		dispatch(
			requestConfirmation(`Disconnect Your PayPal Account`, () => handleDisconnect())
		)
	}

	const handleDisconnect = async () => {
		try {
			const responseData = await paypalDisonnect();

			if (responseData.status === "success") {
				onOpenAlert("success", returnTranslation("PayPal succesfuly disconnected"))
				getPaypalInfo()
			}
		} catch (e) {
			// console.log(e)
		}
	}

	return (
		<>
			{paypalAccount?.email ? (
				<Row>
					<Col sm={12} md={6} lg={6} xl={6}>
						<Card>
							<CardBody>
								<h4 className="card-body p-0">{returnTranslation("Paypal Account")}</h4>
								<span>
									<b className="me-2">{returnTranslation("Email")}</b>
									<span>{paypalAccount?.email}</span>
								</span>
							</CardBody>
						</Card>
					</Col>
				</Row>
			): null}
			<Row>
				<Col sm={12} md={6} lg={6} xl={6}>
					<Card>
						<CardBody>							
							<div className="loop__widget--settings__container--single-setting-panel">
								{!paypalAccount?.email ? (
									<ConnectPaypal
										paymentMethods={paymentMethods}
										onClick={handleConnectPaypal}
										returnTranslation={returnTranslation}
									/>
								) : (
									<>
										{/* <Button
											className={"mt-2 mb-2 loop__btn--center"}
											// type="white white-bordered"
											onClick={handleDisconnectPaypal}
											text={returnTranslation("Disconnect PayPal")}
										// size={"small"}
										/> */}

										<ConnectPaypal
											paymentMethods={paymentMethods}
											onClick={handleDisconnectPaypal}
											returnTranslation={returnTranslation}
											disconnect={true}
										/>
									</>
								)}
							</div>
							
						</CardBody>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default PayoutsWithPaypal
