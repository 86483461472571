import { GET_FEED_ANALYTICS, SET_FEED_ANALYTICS_QUERY_OBJECT, CLEAR_FEED_ANALYTICS, GET_FEED_ANALYTICS_FAIL, GET_FEED_ANALYTICS_SUCCESS } from "./actionTypes"

const INIT_QUERY_OBJECT = { sort: 'date', direction: 'desc', type: "daily" }
const INIT_STATE = {
  feedAnalytics: [],
  total: 0,
  isLoading: false,
  initialQueryObject: INIT_QUERY_OBJECT,
  queryObject: INIT_QUERY_OBJECT
}

const FeedAnalytics = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FEED_ANALYTICS:
      return {
        ...state,
        isLoading: true
    }

    case GET_FEED_ANALYTICS_SUCCESS:
      return {
        ...state,
        feedAnalytics: action ?.payload ?.items,
        total: action ?.payload ?.info ?.total_pages,
        page: action ?.payload ?.info ?.current_page,
        perPage: action ?.payload ?.info ?.page_count,
        totalItems: action ?.payload ?.info ?.total_items,
        isLoading: false
      }

    case GET_FEED_ANALYTICS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      }

    case CLEAR_FEED_ANALYTICS:
      return {
        ...INIT_STATE
      }
    
    case SET_FEED_ANALYTICS_QUERY_OBJECT:
      return {
        ...state,
        queryObject: action.payload.queryObject
      }

    default:
      return state
  }
}

export default FeedAnalytics
