import { takeEvery, put, call, all, select } from "redux-saga/effects"

import {
  getLocalStoresSuccess,
  getLocalStoresFail,
} from "./actions"

import { GET_LOCAL_STORES, DELETE_LOCAL_STORE } from "./actionTypes"

import {
  deleteTakeBackPoint,
  getTakeBackPoints,
} from "../../helpers/backend_helper"

function* getLocalStoresAsync({ payload }) {
  try {
    const params = new URLSearchParams(payload?.queryObject)
    const response = yield call(() => getTakeBackPoints(payload?.takebackId, payload?.page, params?.toString()))

    yield put(getLocalStoresSuccess(response))
  } catch (error) {
    yield put(getLocalStoresFail(error))
  }
}

function* deleteLocalStoreAsync({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(deleteTakeBackPoint, id)

    const { takebackId, current_page, queryObject } = yield select(state => state?.LocalStores)
    const responseLocalStores = yield call(() => getTakeBackPoints(takebackId, current_page, queryObject))
    yield put(getLocalStoresSuccess(responseLocalStores))
  } catch (error) {
    yield put(getLocalStoresFail(error))
  }
}

function* localStoresSaga() {
  yield takeEvery(GET_LOCAL_STORES, getLocalStoresAsync)
  yield takeEvery(DELETE_LOCAL_STORE, deleteLocalStoreAsync)
}

export default localStoresSaga
