import React from "react"
//@ts-ignore
import { Link } from "react-router-dom"

interface CodesTableColumnsInterface {
  handleDelete: (id: string, name: string) => {},
  handleEdit: (id: string) => {},
  handleSetActive: (id: string, active: number) => {},
  returnTranslation: (name: string) => string
}

const WebsitesTableColumns = ({ handleDelete, handleEdit, handleSetActive, returnTranslation }: CodesTableColumnsInterface) => {
  return (
    [
      {
        dataField: "i",
        isDummyField: true,
        text: "#",
        formatter: (cellContent: any, row: any, rowIndex: any) => <div>{rowIndex + 1}</div>,
      },
      {
        dataField: "name",
        text: returnTranslation("Name"),
      },
      {
        dataField: "street",
        text: returnTranslation("Street"),
      },
      {
        dataField: "city",
        text: returnTranslation("City"),
      },
      {
        dataField: "postcode",
        text: returnTranslation("Postcode"),
      },
      {
        dataField: "active",
        text: returnTranslation("Active"),
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "",
        formatter: (cellContent: any, row: any, rowIndex: any) => (
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-warning"
              onClick={() => handleEdit(row.id)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => handleDelete(row.id, row.name)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
            </Link>
            <Link
              to="#"
              className={row.active === 1 ? "text-danger" : "text-success"}
              onClick={() => handleSetActive(row.id, row.active === 1 ? 0 : 1)}
            >
              <p className="active-coupon-btn">{row.active === 1 ? returnTranslation("Set inactive") : returnTranslation("Set active")}</p>
            </Link>
          </div>
        ),
      },
    ]
  )
}

export default WebsitesTableColumns
