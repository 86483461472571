//Include Both Helper File with needed methods
import { getCoupons } from "../../helpers/backend_helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { getCouponsFail, getCouponsSuccess } from "./actions"
// Ecommerce Redux States
import { GET_COUPONS } from "./actionTypes"

function* getCouponsAsync({ payload }) {
  try {
    const response = yield call(getCoupons, payload)
    yield put(getCouponsSuccess(response))
  } catch (error) {
    yield put(getCouponsFail(error))
  }
}

function* couponsSaga() {
  yield takeEvery(GET_COUPONS, getCouponsAsync)
}

export default couponsSaga
