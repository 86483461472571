import axios from "axios"
import React, { useState } from "react"
import Dropzone from "react-dropzone"
import { readString } from "react-papaparse"
import { useDispatch, useSelector } from "react-redux"
import { RouteComponentProps, useHistory, useParams } from "react-router"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container, Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner
} from "reactstrap"
import plusGreenBg from "../../../../assets/images/plus-green-bg.svg"
import BasicTable from "../../../../components/BasicTable/BasicTable"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import ContactUsAlert from "../../../../components/Common/ContactUsAlert"
import LiVerticalTimeline from "../../../../components/LiVerticalTimeline/LiVerticalTimeline"
import * as url from "../../../../helpers/url_helper"
import { openAlert, requestConfirmation } from "../../../../store/actions"
import CsvPreviewTableColumns from "./CsvPreviewTableColumns"

interface Props extends RouteComponentProps {
  history: any,
  dashbord_id: string,
  userToken: string,
  onOpenAlert: any,
  returnTranslation: (name: string) => string,
  match: any;
}

const ImportCharityShops = ({ returnTranslation }: Props) => {
  const params: { takeback_id: string } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { dashbord_id, user: { access_token: userToken } } = useSelector((state: any) => state.Account)

  const [importMessages, setImportMessages] = useState<string[]>([]);
  const [isImporting, setIsImporting] = useState(false);

  const [csvPreviewData, setCsvPreviewData] = useState([]);
  const [isOpenPreviewCsvModal, setIsOpenPreviewCsvModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState({
    lastModified: 0,
    lastModifiedDate: "",
    path: "",
    size: 0,
    type: "",
    name: "",
    webkitRelativePath: ""
  });

  const [steps] = useState([
    {
      id: 1,
      stausTitle: returnTranslation("Download template"),
      iconClass: "bx-package",
      description: returnTranslation(`Download`),
    },
    {
      id: 2,
      stausTitle: returnTranslation("Fill in the form"),
      iconClass: "bx-copy-alt",
      description: returnTranslation("Add/edit information in the form"),
    },
    {
      id: 3,
      stausTitle: returnTranslation("Upload completed form"),
      iconClass: "bx-badge-check",
      description: returnTranslation("Use the below field to upload the form"),
    },
  ])

  const onOpenAlert = ({ text, status }: { text: string, status: string}) => dispatch(openAlert({ text, status }));

  const handleAcceptedFiles = (files: any) => {
    setImportMessages([]);

    // this.setState({ selectedFiles:  })
    setSelectedFiles(files[0]);

    const reader = new FileReader();
    reader.onload = function (e: any) {
      const csvString = e.target.result;
      //@ts-ignore
      const result: any = readString(csvString, { header: true });
      const expectedKeys = [
        "lat", "lng", "name", "street", "city", "country", "open_wednesday",
        "open_thursday", "open_friday", "open_saturday", "open_sunday",
        "open_monday", "open_tuesday", "postcode", "active", "description"
      ].sort((a ,b) => a < b ? -1 : 1)
      console.log(result?.data);
      setCsvPreviewData(
        result?.data?.filter((row: any, index: number) => {
          // console.log(["expectedKeys", expectedKeys?.join(",")])
          // console.log(["rowKeys", rowKeys?.join(",")])
          //sort the same and compare joined string to check if column headers are matching
          // if(expectedKeys?.join(",") === rowKeys?.join(",")){

          const rowKeys = Object.keys(row)?.map(key => key?.trim()?.toLowerCase())?.sort((a ,b) => a < b ? -1 : 1)
          const allExpectedKeysArePresent = expectedKeys?.every((expectedKey: string) => rowKeys?.includes(expectedKey))
          if(allExpectedKeysArePresent){
            return true
          } else {
            console.log(`row ${index + 1}`, "NOT MATCHING headers")
            return false
          }
        }
      ));
      setIsOpenPreviewCsvModal(true);
    };
    reader.readAsText(files[0]);
  }

  const requestSubmit = () => {
    dispatch(requestConfirmation(
      returnTranslation(
        "Importing a new file will replace the previous data. You can export the current data to a .csv file in the LOOP FOR GOOD->Take Back->Local Stores tab."),
        handleSubmit
      )
    )
  }

  const handleSubmit = () => {
    setIsImporting(true)
    return new Promise(async (resolve, reject) => {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "Client-UUID": dashbord_id,
          Authorization: `Bearer ${userToken}`,
          "Custom-User-Agent": window.navigator.userAgent
        },
      }

      const formData = new FormData()
      // @ts-ignore
      formData.append("points", selectedFiles)

      axios
        .post(`${url.API_BASE}dashboard/takes_backs/${params?.takeback_id}/points/import`, formData, config)
        .then(response => {
          onOpenAlert({ text: returnTranslation("Local Stores imported succesfully."), status: "success" })
          // history.push(`/take-back/${params?.takeback_id}/local-stores`)
          // setIsOpenPreviewCsvModal(false)
          //@ts-ignore
          const { no_changes, added, updated, deleted, errors, errors_lines, errors_lines_with_messages } = response?.data?.status;
          setImportMessages([
            `Imported ${added + updated + no_changes} rows`,
            errors ? `Found ${errors} errors (not imported)` : `No errors found.`,
          ])
          if(errors_lines?.length){
            setImportMessages(prev => [...prev, `Errors lines: [${errors_lines?.join(", ")}]`])
          }
          if(Object.entries(errors_lines_with_messages)?.length){
            //@ts-ignore
            setImportMessages(prev => [...prev, `${Object.entries(errors_lines_with_messages)?.map(([k,v]) => `${k}: ${Object.values(v)}`)}`])
          }

          return resolve(response)
        })
        .catch(error => {
          if (error?.response?.data) {
            const { message, errors } = error?.response?.data
            onOpenAlert({ text: message, status: "danger" })
          }
          return reject(error)
        }).finally(() => {
          setIsImporting(false)
        })
    })
  }

  const onRemoveFile = () => {
    setIsOpenPreviewCsvModal(false);
    setSelectedFiles({
      lastModified: 0,
      lastModifiedDate: "",
      path: "",
      size: 0,
      type: "",
      name: "",
      webkitRelativePath: ""
    });
  }

  return (
    <>
      <Modal
        className="import-preview"
        size="xl"
        centered={true}
        isOpen={isOpenPreviewCsvModal}
        toggle={() => setIsOpenPreviewCsvModal(!isOpenPreviewCsvModal)}
      >
        <ModalHeader>{returnTranslation("Preview of")} {selectedFiles?.name}</ModalHeader>
          <ModalBody>

            <ContactUsAlert
              CustomTextElement={importMessages?.map((message: string) => <div>
                <div>{message}</div>
              </div>)}
              display={importMessages?.length ? true : false}
              returnTranslation={returnTranslation}
              onOpenAlert={onOpenAlert}
              color="success"
            />

            <span className="text-muted">{returnTranslation("Preview of uploaded csv file.")}</span>
            <div className="modal-table">
              <BasicTable
                columns={CsvPreviewTableColumns({returnTranslation})}
                items={csvPreviewData}
                searchbar={false}
                totalSize={10}
                currentPage={1}
                sizePerPage={10}
                simple={true}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button style={{ width: 100 }} onClick={onRemoveFile} color="secondary">
              {returnTranslation("Close")}
            </Button> 
            <Button style={{ width: 100 }} onClick={requestSubmit} color="primary">
              {returnTranslation("Import")}
              {isImporting ? <Spinner className="ms-2" color="dark" /> : null}
            </Button> 
          </ModalFooter>
      </Modal>   
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={returnTranslation("Take Back")} breadcrumbItem={returnTranslation("Import Local Stores")} />

          <Row>
            <Col lg={12} xl={9}>
              <Card>
                <CardBody>
                  {steps?.map((status: any, key: number) => (
                    <LiVerticalTimeline
                      status={status}
                      key={"_status_" + key}
                      returnTranslation={returnTranslation}
                      fileUrl="https://dashboard.loopdigitalwardrobe.com/assets/take-back-local-stores.csv"
                    />
                  ))}

                  <Label className="col-form-label col-lg-2">
                    {returnTranslation("Attached Files")}
                  </Label>
                  <Dropzone
                    onDrop={acceptedFiles =>
                      handleAcceptedFiles(acceptedFiles)
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="dz-message needsclick">
                            {/* <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div> */}
                            <img src={plusGreenBg} />
                            <h4>{returnTranslation("Drop files here or click to upload.")}</h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div
                    className="dropzone-previews mt-3"
                    id="file-previews"
                  >
                    {selectedFiles?.name &&
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={`${selectedFiles?.name}`}
                      >
                        <div className="p-2">
                          <div className="align-items-center d-flex">
                            <a className="text-danger" href="#"><i className="mdi mdi-delete m-2" style={{ fontSize: 20 }} onClick={onRemoveFile} /></a>
                            <span>{selectedFiles?.name}</span>
                          </div>
                        </div>
                      </Card>
                    }
                  </div> 
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default(ImportCharityShops)
