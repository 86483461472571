import React, {Component} from 'react';
import * as url from './../../helpers/url_helper';
// @ts-ignore
import {Alert, Card, CardBody, Col, Container, Row} from 'reactstrap';
// @ts-ignore
import {connect} from 'react-redux';
// @ts-ignore
import {Link, withRouter} from 'react-router-dom';
// @ts-ignore
import {RouteComponentProps} from 'react-router';
// @ts-ignore
import {AvField, AvForm} from 'availity-reactstrap-validation';
// @ts-ignore
import MetaTags from 'react-meta-tags';
import {
  apiError,
  loginUser,
  socialLogin,
  loginSuccess,
  registerUserSuccessful,
  changePreloader,
} from '../../store/actions';
import {post} from './../../helpers/api_helper';
// import profile from "../../assets/images/profile-img.png"
// import logo from "../../assets/images/logo.svg"
// import lightlogo from "../../assets/images/logo-light.svg"
// import { truncate } from "fs"
import LoopLogo from '../../assets/images/loop/loop-logo-new.svg';

export interface LoginPropsInterface extends RouteComponentProps {
  dashbord_id: string;
  registerUserSuccessful: (userObj: any) => {};
  apiError: (err: string) => {};
  history: any;
  location: any;
  error: string;
  changePreloader: (value: boolean) => {};
}

export interface LoginStateInterface {
  err: string;
  testEmail: string;
  testPassword: string;
}

class Login extends Component<LoginPropsInterface, LoginStateInterface> {
  constructor(props: LoginPropsInterface) {
    super(props);
    this.state = {
      err: '',
      testEmail: '',
      testPassword: '',
    };
  }
  handleValidSubmit = async (event: any, values: any) => {
    await this.handleLoginUser(values.email, values.password);
  };

  handleLoginUser = async (email: string, password: string) => {
    return new Promise(async (resolve, reject) => {
      this.props.changePreloader(true);
      post(
        `${url.API_BASE}login`,
        {email, password},
        {
          headers: {
            Accept: 'application/vnd.loop.v4.5+json',
            'Content-Type': 'application/json',
            'Client-UUID': '9fde47f3-c8f8-46a8-8bed-a587032d9aa0',
            Authorization: `Bearer ${
              localStorage.authUser
                ? JSON.parse(localStorage.authUser).access_token
                : ''
            }`,
            'Custom-User-Agent': window.navigator.userAgent,
          },
        },
      )
        .then(response => {
          if (response?.user && response?.access_token) {
            let userObj = response?.user;
            userObj.access_token = response?.access_token;
            this.props.registerUserSuccessful(userObj);
            localStorage.setItem('authUser', JSON.stringify(userObj));
            this.props.history.push('/dashboard');
          }

          this.props.changePreloader(false);
          return resolve(response);
        })
        .catch(error => {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            this.props.apiError(error.response.data.message);
          }
          this.props.changePreloader(false);
          return reject(error);
        });
    });
  };

  componentDidMount() {
    this.props.apiError('');
  }

  render() {
    const {testEmail, testPassword} = this.state;
    return (
      <React.Fragment>
        <MetaTags>
          <title>LOOP | Login</title>
        </MetaTags>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-2 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={7} xl={6}>
                <Card className="overflow-hidden">
                  <div className="auth-logo__container">
                    <Row>
                      <img src={LoopLogo} alt="LOOP" title="LOOP" />
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    {/* <div className="mb-4 d-grid">
                      <button
                        className="btn-no-shadow btn btn-block waves-effect waves-light google-login__btn"
                      >
                        <img src="/assets/images/google-logo.png" alt="Log in with Google" title="Log in with Google" />Log in with Google
                      </button>
                    </div> */}

                    {/* <div className="mb-2 or-divider flex__justify-content--space-between">
                      <div className="or-divider__line"></div>
                      <div className="or-divider__text">or</div>
                      <div className="or-divider__line"></div>
                    </div> */}

                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}>
                        {this.props.error && this.props.error ? (
                          <Alert color="danger">{this.props.error}</Alert>
                        ) : null}

                        {this.state.err && this.state.err ? (
                          <Alert color="danger">{this.state.err}</Alert>
                        ) : null}

                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email address"
                            className="form-control"
                            placeholder="Enter email"
                            value={
                              this?.props?.location?.state?.email
                                ? this?.props?.location?.state?.email
                                : testEmail
                            }
                            type="email"
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="password"
                            label="Password"
                            type="password"
                            value={testPassword}
                            required
                            placeholder="Enter password"
                          />
                        </div>

                        <div className="flex__justify-content--space-between">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline">
                              Remember me
                            </label>
                          </div>

                          <div className="text-center forgot-password">
                            <Link to="/forgot-password" className="text-muted">
                              Forgot your password?
                            </Link>
                          </div>
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
                            type="submit">
                            Log In
                          </button>
                        </div>

                        <div className="mt-3 text-center">
                          <p>
                            <span className="auth-agreement-text">
                              {' '}
                              Don't have an account?
                            </span>{' '}
                            <Link
                              to="register-email"
                              className="fw-medium auth-agreement-link">
                              {' '}
                              Sign up
                            </Link>{' '}
                          </p>
                        </div>

                        <div className="mt-4 text-center">
                          <p className="mb-0 auth-agreement-text">
                            By continuing you agree to our{' '}
                            <a
                              href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/"
                              target="_blank"
                              className="text-primary">
                              Terms & Conditions
                            </a>
                            . LOOP services are subject to our{' '}
                            <a
                              href="https://loopdigitalwardrobe.com/privacy-policy/"
                              target="_blank"
                              className="text-primary">
                              Privacy Policy
                            </a>
                            .
                          </p>
                        </div>

                        <div className="mt-4 text-center">
                          <p className="mb-0 auth-agreement-text">
                            Need our help?{' '}
                            <a
                              href="mailto:help@loopdigitalwardrobe.com"
                              className="fw-medium contact-us-text">
                              Contact us.
                            </a>
                          </p>
                        </div>

                        <div className="mt-4 text-center our-integrations">
                          <p className="mb-0 auth-agreement-text">
                            <div className="fw-medium contact-us-text">
                              Our Integrations
                            </div>
                            <img
                              src="/assets/images/platforms.png"
                              width="220"
                            />
                          </p>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-5 text-center">
                  <p className="auth-agreement-text">
                    ©{new Date().getFullYear()} All Rights Reserved. LOOP
                    DIGITAL WARDROBE® is a registered trademark of LOOP DIGITAL
                    WARDROBE®.{' '}
                    <a
                      href="https://loopdigitalwardrobe.com/privacy-policy/"
                      target="_blank"
                      className="fw-medium text-primary">
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                      href="https://loopdigitalwardrobe.com/partners-terms-and-conditions/"
                      target="_blank"
                      className="fw-medium text-primary">
                      Terms & Conditions
                    </a>
                    .
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  const {error} = state.Login;
  const {dashbord_id} = state.Account;
  return {error, dashbord_id};
};

export default withRouter(
  connect(mapStateToProps, {
    loginUser,
    apiError,
    socialLogin,
    loginSuccess,
    registerUserSuccessful,
    changePreloader,
  })(Login),
);
