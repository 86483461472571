import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  CHECK_ACCOUNT_SUCCESSFUL,
  CHECK_ACCOUNT_FAILED,
  CHECK_ACCOUNT
} from "./actionTypes"

const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  dashbord_id: '9fde47f3-c8f8-46a8-8bed-a587032d9aa0',
  user: localStorage.getItem("authUser") && JSON.parse(localStorage.getItem("authUser")),
  info: {
    isLoading: true
  }
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      state = {
        ...state,
        user: null,
        loading: true,
        registrationError: null,
      }
      break
    case REGISTER_USER_SUCCESSFUL:
      // console.log("REGISTER_USER_SUCCESSFUL", action?.payload)
      localStorage.setItem("authUser", JSON.stringify(action?.payload))

      state = {
        ...state,
        loading: false,
        user: action.payload,
        registrationError: null,
      }
      break
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        registrationError: action.payload,
      }
      break
    case CHECK_ACCOUNT:
      state = {
        ...state,
        info: {
          ...state?.info,
          isLoading: true
        }
      }
      break
    case CHECK_ACCOUNT_SUCCESSFUL:
      state = {
        ...state,
        info: {
          ...action.payload,
          isLoading: false
        }
      }
      break
    case CHECK_ACCOUNT_FAILED:
        state = {
          ...state,
          info: {
            isLoading: false
          }
        }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default account
