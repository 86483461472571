// @ts-ignore
import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
// @ts-ignore
import { RouteComponentProps, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Row, Spinner } from "reactstrap"
import FormInput from "../../../components/Common/FormInput"
import { getCountries } from "../../../store/countries/actions"
import { put } from "./../../../helpers/api_helper"
import * as url from "./../../../helpers/url_helper"
import { changePreloader, checkAccount, getBillings, getInvoices, getPaymentInfo, openAlert } from "./../../../store/actions"
import { range } from "lodash";
import { createStripeCard, updateStripeCard } from "../../../helpers/backend_helper"

interface ChangePaymentInfoInterface extends RouteComponentProps {
    returnTranslation: (name: string) => string;
    handleClose?: () => void;
}

const ChangePaymentInfo = ({ history, returnTranslation, handleClose }: ChangePaymentInfoInterface) => {
    const dispatch = useDispatch()
    const user = useSelector((state: any) => state.Account.user)
    const billingsInfo = useSelector((state: any) => state.Billings?.info)
    const countries = useSelector((state: any) => state.Countries?.countries);
    const paymentInfo = useSelector((state: any) => state.PaymentInfo?.info);

    // const [defaultCharityNumber, setDefaultCharityNumber] = useState("")

    const [billAnnulally, setBillAnnulally] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState('')
    const [formErrors, setFormErrors] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        dispatch(getPaymentInfo())
        dispatch(getBillings())
        dispatch(getInvoices())
        dispatch(getCountries())
    }, [])

    useEffect(() => {
        setSelectedCountry(paymentInfo?.country?.code)
    }, [paymentInfo])

    // useEffect(() => {
    //     console.log(["billingsInfo?.charity_number", billingsInfo?.charity_number])

    //     if (billingsInfo?.charity_number) {
    //         setDefaultCharityNumber(billingsInfo?.charity_number)
    //     }
    // }, [billingsInfo?.charity_number])

    // const handleUpdateCity = async (postcode: string) => {
    //     return new Promise(async (resolve, reject) => {

    //         try {
    //             post(`${url.API_BASE}cities`, {
    //                 postcode,
    //                 country: selectedCountry,
    //             })
    //                 .then(response => {
    //                     // console.log(["response", response, response?.id])
    //                     if (response?.id) {
    //                         resolve(response?.id)
    //                     }
    //                 }).catch(error => {
    //                     if (error?.response?.data) {
    //                         const { message, errors } = error?.response?.data;
    //                         dispatch(openAlert({ text: message, status: "danger" }))
    //                         reject(error)
    //                     }
    //                 })
    //         } catch (e) {
    //             // console.log(e)
    //         }
    //     })
    // }

    const handleValidSubmit = async (event: any, values: any) => {
        event.preventDefault()

        return new Promise(async (resolve, reject) => {
            const {
                name,
                number,
                exp_month,
                exp_year,
                cvc,
                address,
                postcode,
                city_name,
                charity_number
            } = values;

            // let cityId = await handleUpdateCity(postcode);

            // dispatch(changePreloader(true))
            setIsLoading(true);

            const isEdit = !!paymentInfo?.card;
            if(isEdit){
                await handleEditCard(name, exp_month, exp_year);
            } else {
                await handleCreateCard(name, exp_month, exp_year, cvc, number);
            }
            dispatch(getPaymentInfo())
            dispatch(checkAccount())
        }).finally(() => setIsLoading(false));
    }

    const handleEditCard = async (name: string, exp_month: string, exp_year: string) => {
        try{
            const response = await updateStripeCard(paymentInfo?.card?.id, {
                name,
                exp_month,
                exp_year
            })
            dispatch(openAlert({ text: response?.message, status: "success" }))
            handleClose && handleClose();
        }catch(error){
            //@ts-ignore
            if (error?.response?.data) {
                //@ts-ignore
                const { message, errors } = error?.response?.data;
                setFormErrors(errors)
                dispatch(openAlert({ text: message, status: "danger" }))
            }
        }finally{
            setIsLoading(false)
        }
    }

    const handleCreateCard = async (name: string, exp_month: string, exp_year: string, cvc: string, number: string) => {
        try{
            const response = await createStripeCard(paymentInfo?.card?.id, {
                name,
                exp_month,
                exp_year,
                cvc,
                number
            })
            dispatch(openAlert({ text: response?.status, status: "success" }))
        }catch(error){
            //@ts-ignore
            if (error?.response?.data) {
                //@ts-ignore
                const { message, errors } = error?.response?.data;
                setFormErrors(errors)
                dispatch(openAlert({ text: message, status: "danger" }))
            }
        }finally{
            setIsLoading(false)
        }
    }

    return (
        <>
             {/* <div className="page-content">
                <MetaTags>
                    <title>LOOP | Edit Payment Details</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs
                        title={returnTranslation("Billings")}
                        breadcrumbItem={returnTranslation("Edit Payment Details")}
                    /> */}
                    <Row>
                        <Col sm={12} md={6} lg={6} xl={6}>
                            <Card className="overflow-hidden">
                                <CardBody className="p-3">
                                    <h4 className="card-title">Credit Card details</h4>
                                    <AvForm
                                        className="form-horizontal"
                                        onValidSubmit={handleValidSubmit}
                                    >
                                        {/* <div className="row">
                                            <div className="col-sm-6">
                                                <div className="mb-3">
                                                    <AvField
                                                        name="first_name"
                                                        label="First Name"
                                                        className="form-control"
                                                        placeholder="Enter first name"
                                                        type="text"
                                                        defaultValue={user?.first_name ? user?.first_name : ""}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="mb-3">
                                                    <AvField
                                                        name="last_name"
                                                        label="Last Name"
                                                        className="form-control"
                                                        placeholder="Enter last name"
                                                        type="text"
                                                        defaultValue={user?.last_name ? user?.last_name : ""}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <AvField
                                                name="address"
                                                label="Address"
                                                className="form-control"
                                                placeholder="Enter address"
                                                type="text"
                                                defaultValue={user?.address ? user?.address : ""}
                                                required
                                            />
                                        </div>

                                        <Row>
                                            <Col xl={3} lg={6} md={6}>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="postcode"
                                                        label="Postcode"
                                                        className="form-control"
                                                        placeholder="Enter postcode"
                                                        type="text"
                                                        defaultValue={user?.postcode ? user?.postcode : ""}
                                                        required
                                                    />
                                                </div>
                                            </Col>
                                            <Col xl={3} lg={6} md={6}>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="city_name"
                                                        label="City"
                                                        className="form-control"
                                                        placeholder="Enter city name"
                                                        type="text"
                                                        defaultValue={user?.city?.name ? user?.city?.name : ""}
                                                        required
                                                    />
                                                </div>
                                            </Col>
                                            <Col xl={6} md={12}>
                                                <div className="mb-3">
                                                    <FormInput
                                                        label={"Country"}
                                                        type="country"
                                                        name="country_code"
                                                        className="form-control"
                                                        placeholder={"Select country"}
                                                        showErrors
                                                        formErrors={formErrors}
                                                        onChange={code => setSelectedCountry(code)}
                                                        value={selectedCountry}
                                                        countries={countries?.map((country: any) => country?.code)}
                                                        // countries={[]}
                                                    />
                                                </div>
                                            </Col>
                                        </Row> */}
{/* 
                                        {user?.role.toLowerCase() === "charity" && billingsInfo?.charity_number && (
                                            <div className="mb-3">
                                                <AvField
                                                    name="charity_number"
                                                    label="Charity Number"
                                                    className="form-control"
                                                    placeholder="Enter charity number"
                                                    type="text"
                                                    defaultValue={billingsInfo?.charity_number ? billingsInfo?.charity_number : ""}
                                                    required
                                                />
                                            </div>
                                        )} */}

                                        <div className="mb-3">
                                            <AvField
                                                name="name"
                                                label="Card Owner Name"
                                                className="form-control"
                                                placeholder="Enter card owner name"
                                                type="text"
                                                defaultValue={paymentInfo?.card?.name ? paymentInfo?.card?.name : ""}
                                                required
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <AvField
                                                name="number"
                                                label="Card Number"
                                                className="form-control"
                                                placeholder="xxxxxxxxxxxxxxxx"
                                                type="text"
                                                defaultValue={paymentInfo?.card?.last4 ? "************" + paymentInfo?.card?.last4 : ""}
                                                required
                                                maxLength={16}
                                                disabled={!!paymentInfo?.card}
                                            />
                                        </div>

                                        <Row>
                                            <Col sm={4}>
                                                <div className="mb-3">
                                                    {/* <AvField
                                                        name="exp_month"
                                                        label="Exp Month"
                                                        className="form-control"
                                                        placeholder="mm"
                                                        type="text"
                                                        required
                                                        maxLength={2}
                                                    /> */}
                                                    <AvField
                                                        name="exp_month"
                                                        label="Exp Month"
                                                        className={`form-control ${paymentInfo?.card?.exp_month ? "has-value" : ""}`}
                                                        placeholder="mm"
                                                        type="select"
                                                        required
                                                        defaultValue={paymentInfo?.card?.exp_month
                                                            ? paymentInfo?.card?.exp_month?.toString()?.length === 1 ? `0${paymentInfo?.card?.exp_month?.toString()}` : paymentInfo?.card?.exp_month?.toString()
                                                            : undefined}
                                                    >
                                                        <option className="d-none text-muted">mm</option>
                                                        {range(1, 12)?.map((month: number) => (
                                                            <option key={month} value={month?.toString()?.length === 1 ? `0${month?.toString()}` : month?.toString()}>
                                                                {month?.toString()?.length === 1 ? `0${month?.toString()}` : month}
                                                            </option>
                                                        ))}
                                                    </AvField>
                                                </div>
                                            </Col>
                                            <Col sm={4}>
                                                <div className="mb-3">
                                                    {/* <AvField
                                                        name="exp_year"
                                                        label="Exp Year"
                                                        className="form-control"
                                                        placeholder="yyyy"
                                                        type="text"
                                                        required
                                                        maxLength={4}
                                                    /> */}
                                                    <AvField
                                                        name="exp_year"
                                                        label="Exp Year"
                                                        className={`form-control ${paymentInfo?.card?.exp_year ? "has-value" : ""}`}
                                                        placeholder="yyyy"
                                                        type="select"
                                                        required
                                                        defaultValue={paymentInfo?.card?.exp_year ? paymentInfo?.card?.exp_year : undefined}
                                                    >
                                                        <option className="d-none text-muted">yyyy</option>
                                                        {range(new Date().getFullYear(), new Date().getFullYear() + 20)?.map((year: number) => (
                                                            <option key={year} value={year}>{year}</option>
                                                        ))}
                                                    </AvField>
                                                </div>
                                            </Col>
                                            <Col sm={4}>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="cvc"
                                                        label="CVV"
                                                        className="form-control"
                                                        placeholder={"xxx / xxxx"} 
                                                        type="text"
                                                        minLength={3}
                                                        maxLength={4}
                                                        required={!paymentInfo?.card}
                                                        disabled={!!paymentInfo?.card}
                                                        defaultValue={!!paymentInfo?.card ? "***" : ""} 
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="d-flex gap-2">
                                            <div className="mt-4 d-grid">
                                                <button
                                                    className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
                                                    type="submit"
                                                >
                                                    {returnTranslation("Submit")}
                                                    {isLoading ? (
                                                        <Spinner className="ms-2" color="light" />
                                                    ): null}
                                                </button>
                                            </div>
                                            {handleClose ? (
                                                <div className="mt-4 d-grid">
                                                    <button
                                                        className="btn-no-shadow btn btn-secondary btn-block waves-effect waves-light"
                                                        type="button"
                                                        onClick={handleClose}
                                                    >
                                                        {returnTranslation("Cancel")}
                                                    </button>
                                                </div>
                                            ): null}
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                {/* </Container>
            </div> */}
        </>
    )
}

export default withRouter(ChangePaymentInfo)
