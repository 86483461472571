import {
  GET_COUPONS,
  GET_COUPONS_FAIL,
  GET_COUPONS_SUCCESS,
} from "./actionTypes"

export const getCodes = (page) => ({
  type: GET_COUPONS,
  payload: { page }
})

export const getCouponsSuccess = codes => ({
  type: GET_COUPONS_SUCCESS,
  payload: codes,
})

export const getCouponsFail = error => ({
  type: GET_COUPONS_FAIL,
  payload: error,
})
