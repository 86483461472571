import React, { useEffect, useState } from "react"
// @ts-ignore
import { useDispatch, useSelector } from "react-redux"
import { Col, Row } from "reactstrap"
//@ts-ignore
import SchoolDonate from "../../../assets/images/school_donate.svg"
//@ts-ignore
import SchoolShipByPost from "../../../assets/images/school_free_post_donation.svg"
//@ts-ignore
import SchoolResell from "../../../assets/images/school_resell_for_school.svg"
//@ts-ignore
import SchoolSwapIcon from "../../../assets/images/school_swap.svg"
//@ts-ignore
import PuzzleIcon from "../../../assets/images/puzzle.svg"
//@ts-ignore
import ListIcon from "../../../assets/images/list.svg"
//@ts-ignore
import CouponsIcon from "../../../assets/images/coupons.svg"
//@ts-ignore
import SchoolTakebackIcon from "../../../assets/images/school_takeback.svg"
import { getShippingAddress } from "../../../helpers/backend_helper"
import { changePreloader, checkAccount } from "../../../store/actions"
import SingleConfigurationOption from "./SingleConfigurationOption/SingleConfigurationOption"
import "./WelcomePanel.scss"

interface Props {
  user: any,
  history: any,
  returnTranslation: any
}

const WelcomePanel = ({ user, history, returnTranslation }: Props) => {
  const dispatch = useDispatch()
  const [shippingDetailsFilled, setShippingDetailsFilled] = useState(false)
  const [loaded, setLoaded] = useState(false)
  // const [entitlements, setEntitlements] = useState<any>({})

  const isLoading = useSelector((state: any) => state?.Account?.info?.isLoading) && !loaded;
  const accountInfo = useSelector((state: any) => state?.Account?.info)
  const takeback = useSelector((state: any) => state?.TakeBacks?.items?.[0]);
  const takebackTasks = useSelector((state: any) => state?.Account?.info?.take_back_tasks)

  // const isPaypalConnected = useSelector((state: any) => state?.PaymentInfo?.info?.paypal_connection);
  // const isCardConnected = useSelector((state: any) => state?.PaymentInfo?.info?.card) !== null;
  const onCheckAccount = () => dispatch(checkAccount());

  useEffect(() => {
    dispatch(changePreloader(isLoading))
    setLoaded(!isLoading)
  }, [isLoading])

  useEffect(() => {
    onCheckAccount();
    handleGetShippingAddress();
  }, [])

  const handleGetShippingAddress = async () => {
    try {
      const response: any = await getShippingAddress();
      // const { city_name, house_no, postcode, street, flat_no } = response;
      setShippingDetailsFilled(!!(response?.city_name && response?.house_no && response?.postcode && response?.street))
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Row>
        <Col>
          <div className="p-3">
            <h5 className="welcome-header">Welcome to LOOP Digital Wardrobe.</h5>
            <p>
              {user?.role === "CHARITY" ?
                `Acquire volunteers and supporters among the young crowd of digital ambassadors & influencers, and highly engaging community of fashionistas of the Gen Z.` :
                `Sustainability and Marketing platform that makes positive impact through digitalizing and connecting millions of wardrobes of fashionistas and their friends.`}
            </p>
          </div>
        </Col>

        <div className="welcome-panel__steps welcome-panel__steps--grid">
          {
            accountInfo && Object.entries(accountInfo).map(([key, value]: any) => {
              // console.log({key, value})
              // console.log(['key value', key, value])
              switch (key) {
                // case "general_information": {
                //   return (
                //     <SingleConfigurationOption
                //       key={key}
                //       returnTranslation={returnTranslation}
                //       header={"General Information"}
                //       description={`Complete your profile to ensure all features run at top performance and your ${user?.role === "RETAILER" ? 'brand' : user?.role?.toLowerCase()} is well represented.`}
                //       filled={Object.values(value).includes(false) ? false : true}
                //       tasksOptions={[
                //         // {
                //         //   name: "Mobile App Profile",
                //         //   isActive: !!value[""],
                //         //   route: "/details",
                //         //   display: !!Object.keys(value)?.find(key => key === "address")
                //         // },
                //         {
                //           name: "Add address",
                //           isActive: !!value["address"],
                //           route: "/profile",
                //           display: !!Object.keys(value)?.find(key => key === "address")
                //         },
                //         {
                //           name: "Add credit card",
                //           isActive: !!value["cards"],
                //           route: "/change-payment-info",
                //           display: !!Object.keys(value)?.find(key => key === "cards")
                //         },
                //         {
                //           name: "Choose the plan",
                //           isActive: !!value["plan"],
                //           route: "/change-plan",
                //           display: !!Object.keys(value)?.find(key => key === "plan")
                //         },
                //         {
                //           name: "Add VAT ID",
                //           isActive: !!value["vatin"],
                //           route: "/change-vat-id",
                //           display: !!Object.keys(value)?.find(key => key === "vatin")
                //         },
                //         {
                //           name: "Payment Method",
                //           isActive: !!value["payment_method"],
                //           route: "/payment-method",
                //           display: !!Object.keys(value)?.find(key => key === "payment_method")
                //         },

                //       ]}
                //       customFilledIconDone={<img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donates.png" />}
                //       customFilledIconUndone={<img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donates.png" />}
                //     />
                //   )
                //   break;
                // }

                case "sites": {
                  return (
                    <SingleConfigurationOption
                      key={key}
                      returnTranslation={returnTranslation}
                      header={"Add LOOP Plugin"}
                      description={user?.role === "CHARITY" ? "Invite your donors to join LOOP and support your charity in a new engaging way by adding LOOP plugin to your website." : "Boost sustainability credentials with one simple plugin. Integrated into your checkout, LOOP allows your customers to digitalize their purchases and apply circular afterlife scenario to all items in their wardrobes."}
                      filled={value}
                      tasksOptions={[
                        {
                          name: "Set up now",
                          isActive: value,
                          route: "/plugin/integrations"
                        }
                      ]}
                      customFilledIconDone={<img className="single-configuration-option--icon" src={PuzzleIcon} />}
                      customFilledIconUndone={<img className="single-configuration-option--icon" src={PuzzleIcon} />}
                    />
                  )
                  break;
                }

                case "coupons": {
                  return (
                    <SingleConfigurationOption
                      key={key}
                      returnTranslation={returnTranslation}
                      header={"Add Coupons"}
                      description={"Issue a discount coupon to reward clothing upcycling and charity donation"}
                      filled={value}
                      tasksOptions={[
                        {
                          name: "Add a coupon",
                          isActive: value,
                          route: "/add-coupon"
                        }
                      ]}
                      customFilledIconDone={<img className="single-configuration-option--icon" src={CouponsIcon} />}
                      customFilledIconUndone={<img className="single-configuration-option--icon" src={CouponsIcon} />}
                    />
                  )
                  break;
                }

                case "feeds": {
                  return user?.dev ? (
                    <SingleConfigurationOption
                      key={key}
                      returnTranslation={returnTranslation}
                      header={"Add Feed"}
                      description={"Configure the URL for your product feed. Attract, convert, and retain customers through social media style and performance-driven marketing on LOOP."}
                      filled={value}
                      tasksOptions={[
                        {
                          name: `Add feed`,
                          isActive: value,
                          route: "/add-feed"
                        }
                      ]}
                      customFilledIconDone={<img className="single-configuration-option--icon" src={ListIcon} />}
                      customFilledIconUndone={<img className="single-configuration-option--icon" src={ListIcon} />}
                    />
                  ) : null
                  break;
                }

                default:
                // code block
              }
            })
          }
          {(accountInfo?.configuration?.entitlements || accountInfo?.general_information?.entitlements) &&
            Object.keys(accountInfo?.configuration?.entitlements ? accountInfo?.configuration?.entitlements : accountInfo?.general_information?.entitlements)
              ?.map(key => {
                const config = accountInfo?.configuration ? accountInfo?.configuration : accountInfo?.general_information;
                const { paypal_connection, details, entitlements } = config;
                const entitlement = entitlements?.[key];

                switch (key) {
                  case `APP_PROFILE`: {
                    return (
                      <SingleConfigurationOption
                        key={key}
                        header={"Mobile App Profile"}
                        description={`Add details to display Your ${user?.role === "RETAILER" ? "brand" : user?.role?.toLowerCase()} profile in LOOP APP.`}
                        filled={entitlement?.status === "ACTIVE" && details}
                        tasksOptions={[
                          {
                            name: "Details",
                            isActive: details,
                            route: `/details`
                          }
                        ]}
                        entitlement={entitlement}
                        entitlementType={key}
                        verified={accountInfo?.verified}
                        activateCondition={details}
                        returnTranslation={returnTranslation}
                        customFilledIconDone={<img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donates.png" />}
                        customFilledIconUndone={<img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donates.png" />}
                      />
                    )
                    break;
                  }

                  case `VIRTUAL_${user?.role}`: {
                    return (
                      <SingleConfigurationOption
                        key={key}
                        header={user?.role === "CHARITY" ? "Virtual Charity Shop" : user?.role === "SCHOOL" ? "Resell for School" : ""}
                        description={`LOOP donors can now donate items from their digital wardrobes directly to your ${user?.role?.toLowerCase()}. Listed on LOOP marketplace, digital donations are resold between the LOOP donor and the LOOP buyer directly without getting your ${user?.role?.toLowerCase()} involved. When the product is sold, raised funds will go to your ${user?.role?.toLowerCase()}.`}
                        filled={entitlement?.status === "ACTIVE" && paypal_connection && details ? true : false}
                        tasksOptions={[
                          {
                            name: "Mobile App Profile",
                            isActive: details,
                            route: `/details`
                          },
                          // {
                          // 	name: "Payouts with Paypal"),
                          // 	isActive: value?.stripe_connection,
                          // 	route: "/virtual-charity-stripe"
                          // },
                          {
                            name: "Payouts with Paypal",
                            isActive: paypal_connection,
                            route: "/payouts-with-paypal"
                          }
                        ]}
                        entitlement={entitlement}
                        entitlementType={key}
                        verified={accountInfo?.verified}
                        activateCondition={details && paypal_connection}
                        returnTranslation={returnTranslation}
                        customFilledIconDone={user?.role === "CHARITY"
                          ? <img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donate-virtual-charity-shop.png" />
                          : <img className="single-configuration-option--icon" src={SchoolResell} />
                        }
                        customFilledIconUndone={user?.role === "CHARITY"
                          ? <img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donate-virtual-charity-shop.png" />
                          : <img className="single-configuration-option--icon" src={SchoolResell} />
                        }
                      />
                    )
                  }

                  case `SEND_BY_POST`: {
                    return (
                      <SingleConfigurationOption
                        key={key}
                        header={"Ship by Post"}
                        description={"Add the address to allow Loop users to ship their donations directly to your distribution centre."}
                        filled={entitlement?.status === "ACTIVE" && details && shippingDetailsFilled ? true : false}
                        tasksOptions={[
                          {
                            name: "Mobile App Profile",
                            isActive: details,
                            route: `/details`
                          },
                          {
                            name: "Shipping Details",
                            isActive: shippingDetailsFilled,
                            route: `/ship-by-post`
                          }
                        ]}
                        entitlement={entitlement}
                        entitlementType={key}
                        verified={accountInfo?.verified}
                        activateCondition={details && shippingDetailsFilled}
                        returnTranslation={returnTranslation}
                        customFilledIconDone={user?.role === "CHARITY"
                          ? <img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donate-free-shipping.png" />
                          : <img className="single-configuration-option--icon" src={SchoolShipByPost} />
                        }
                        customFilledIconUndone={user?.role === "CHARITY"
                          ? <img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donate-free-shipping.png" />
                          : <img className="single-configuration-option--icon" src={SchoolShipByPost} />
                        }
                      />
                    )
                    break;
                  }

                  case "SWAP": {
                    return (
                      <SingleConfigurationOption
                        key={key}
                        header={"Swap"}
                        description={"Enable Swap for items donated to your school’s virtual shop."}
                        filled={details && entitlement?.status === "ACTIVE"}
                        tasksOpened={true}
                        tasksOptions={[
                          {
                            name: "Mobile App Profile",
                            isActive: details,
                            route: `/details`
                          }
                        ]}
                        entitlement={entitlement}
                        entitlementType={key}
                        verified={accountInfo?.verified}
                        activateCondition={details}
                        returnTranslation={returnTranslation}
                        customFilledIconDone={<img className="single-configuration-option--icon" src={SchoolSwapIcon} />}
                        customFilledIconUndone={<img className="single-configuration-option--icon" src={SchoolSwapIcon} />}
                      />
                    )
                    break;
                  }

                  case "TAKE_BACKS": {
                    return (
                      <SingleConfigurationOption
                        key={key}
                        header={"Take Back Configuration"}
                        description={user?.role === "SCHOOL"
                          ? "Organize clothing collections. Easily resell or upcycle collected products and raise funds for your school or local communities or charity organizations you support"
                          : "Give it away, don’t throw it away! Just pack your items and find your closest drop off on the map."}
                        filled={takebackTasks?.details &&
                          // takebackTasks?.return_address &&
                          // takebackTasks?.accepted_items &&
                          ((takebackTasks?.carriers && takebackTasks?.return_address) || takebackTasks?.points)
                        }
                        tasksOptions={[
                          {
                            name: "Details",
                            isActive: takebackTasks?.details ?? false,
                            route: takeback?.id
                              ? `/take-back/${takeback?.id}/details`
                              : `/take-back/details/add`,
                          },
                          {
                            name: "Accepted Items",
                            isActive: takebackTasks?.accepted_items ?? false,
                            route: `/take-back/${takeback?.id}/accepted-items`,
                            isLocked: !takeback?.id,
                            optional: true
                          },
                          (user?.role === "RETAILER" || user?.role === "CHARITY"  ? {
                            name: "Redeem Coupon",
                            isActive: (takebackTasks?.coupon || takeback?.external_coupon_text) ?? false,
                            route: `/take-back/${takeback?.id}/redeem-coupon`,
                            isLocked: !takeback?.id,
                            optional: true
                          } : null),
                          {
                            name: "Local Stores",
                            isActive: takebackTasks?.points ?? false,
                            route: `/take-back/${takeback?.id}/local-stores`,
                            isLocked: !takeback?.id
                          },
                          {
                            name: "Delivery",
                            isActive: (takebackTasks?.carriers) ?? false,
                            // route: `/take-back/${takeback?.id}/carriers`,
                            isLocked: !takeback?.id,
                            subtasksOpened: true,
                            subtasks: [
                              {
                                name: "Return Address",
                                isActive: takebackTasks?.return_address ?? false,
                                route: `/take-back/${takeback?.id}/return-address`,
                                isLocked: !takeback?.id,
                                optional: true
                              },
                              {
                                name: "Carriers",
                                isActive: takebackTasks?.carriers ?? false,
                                route: `/take-back/${takeback?.id}/carriers`,
                                isLocked: !takeback?.id
                              }
                            ]
                          },
                        ]}
                        entitlement={entitlement}
                        entitlementType={key}
                        // verified={takeback?.confirmed}
                        verified={accountInfo?.verified}
                        activateCondition={
                          takebackTasks?.details &&
                          // takebackTasks?.return_address &&
                          // takebackTasks?.accepted_items &&
                          (takebackTasks?.carriers || takebackTasks?.points)
                        }
                        returnTranslation={returnTranslation}
                        customCheckAccount={onCheckAccount}
                        customFilledIconDone={<img className="single-configuration-option--icon" src={SchoolTakebackIcon} />}
                        customFilledIconUndone={<img className="single-configuration-option--icon" src={SchoolTakebackIcon} />}
                      />
                    )
                    break;
                  }

                  case "MICRO_DONATIONS": {
                    return (
                      <SingleConfigurationOption
                        key={key}
                        header={"Micro-Donations"}
                        description={"Connect to the world of digital fashion and acquire new donors and supporters among highly engaging community of shoppers and influencers on LOOP Digital Wardrobe. Simply add your bank details to start receiving micro-donations from LOOP users."}
                        filled={details && (paypal_connection || accountInfo?.micro_donations_url) && entitlement?.status === "ACTIVE"}
                        tasksOptions={[
                          {
                            name: "Mobile App Profile",
                            isActive: details,
                            route: `/details`
                          },
                          // {
                          // 	name: "Set up Virtual Charity"),
                          // 	isActive: entitlements[VIRTUAL_CHARITY].status === "ACTIVE",
                          // 	route: "/virtual-charity-shop"
                          // },
                          {
                            name: "Payouts with Paypal",
                            isActive: paypal_connection,
                            route: "/payouts-with-paypal"
                          },
                          {
                            name: "External Link",
                            isActive: !!accountInfo?.micro_donations_url,
                            route: "/micro-donations"
                          }
                        ]}
                        entitlement={entitlement}
                        entitlementType={key}
                        verified={accountInfo?.verified}
                        activateCondition={details && (paypal_connection || accountInfo?.micro_donations_url)}
                        returnTranslation={returnTranslation}
                        customFilledIconDone={user?.role === "CHARITY"
                          ? <img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donate-micro-donations.png" />
                          : <img className="single-configuration-option--icon" src={SchoolDonate} />
                        }
                        customFilledIconUndone={user?.role === "CHARITY"
                          ? <img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donate-micro-donations.png" />
                          : <img className="single-configuration-option--icon" src={SchoolDonate} />
                        }
                      />
                    )
                    break;
                  }

                  case `${user?.role}_SHOPS`: {
                    return (
                      <SingleConfigurationOption
                        key={key}
                        header={user?.role === "CHARITY" ? "Add Charity Shops" : "Add School Points"}
                        description={"Get a stream of new products brought to your points by LOOP users. LOOP increases the footfall in your shop, incentivising Gen Z to start thrift shopping story with you."}
                        filled={details && entitlement?.status === "ACTIVE" ? true : false}
                        tasksOptions={[
                          {
                            name: "Mobile App Profile",
                            isActive: details,
                            route: `/details`
                          },
                          {
                            name: "Add Shops",
                            isActive: accountInfo?.points ? true : false,
                            route: "/charity-shops-list"
                          }
                        ]}
                        entitlement={entitlement}
                        entitlementType={key}
                        verified={accountInfo?.verified}
                        activateCondition={details && accountInfo?.points}
                        returnTranslation={returnTranslation}
                        customFilledIconDone={<img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donate-select-on-map.png" />}
                        customFilledIconUndone={<img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donate-select-on-map.png" />}
                      />
                    )
                    break;
                  }
                  default:
                }
              })}
        </div>
      </Row>
    </>
  )
}

export default WelcomePanel
