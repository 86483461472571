import React from "react"
import { Card, CardBody, Col, Media, Spinner } from "reactstrap"

export interface SmallTileProps {
  title: string, 
  description: string, 
  bgClass: string,
  iconClass?: string,
  iconSrc?: any,
  imageSrc?: any,
  list: any,
  customLength: string,
  onRedirect: any,
  isLoading: boolean,
  col: number,
  alert?: number,
  showDetailsText?: string,
  showDetailsOnClick?: any
}

const SmallTile = ({
    title, 
    description,
    bgClass,
    iconClass,
    iconSrc,
    imageSrc,
    list,
    customLength,
    onRedirect,
    isLoading,
    col,
    alert,
    showDetailsText,
    showDetailsOnClick
  }: SmallTileProps) => {
  return (
    <Col md={col ? col : "4"}>
      <Card className={`mini-stats-wid ${onRedirect ? "clickable" : ""}`} onClick={onRedirect}>
        <CardBody style={{ minHeight: 112 }} className="d-flex flex-column justify-content-center">
          <div className="d-flex align-items-center justify-content-between" >
            <div>
              <div className="text-muted fw-medium">{title}</div>
              <h4 className="mb-0">
                {isLoading ? (
                  <Spinner
                    className=""
                    color="success"
                    style={{
                        width:' 1rem',
                        height: '1rem',
                        borderWidth: '0.1em'
                    }}
                  />
                ): list ? list.length : customLength ? customLength : "-"}
              </h4>
            </div>
            
              {imageSrc ? (
                <img src={imageSrc} className="avatar-sm rounded-circle bg-primary align-self-center"/> //mini-stat-icon
              ): (
                <div className="avatar-sm rounded-circle bg-primary align-self-center"> {/* mini-stat-icon */}
                  <span className={`avatar-title rounded-circle ${bgClass}`}>
                    {iconClass ? (
                      <i className={"bx " + iconClass + " font-size-24"}></i>
                    ): null} 
                    {iconSrc ? (
                      <img src={iconSrc} className="avatar-black-img"/>
                    ): null}
                  </span>
                </div>
              )}
          </div>
          {showDetailsText ? <div onClick={showDetailsOnClick} className="text-decoration-underline clickable">{showDetailsText}</div> : null}
          <div className="text-warning h-0">
            {alert ? <i className="fas fa-exclamation-triangle text-warning me-1" /> : null}
            {alert}
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

export default SmallTile
