import React, { Component } from "react"
// @ts-ignore
import { connect } from "react-redux"
// @ts-ignore
import { withRouter } from "react-router-dom"
// @ts-ignore
import { RouteComponentProps } from "react-router";
import * as url from "./../../helpers/url_helper"
import { logoutUser, registerUserSuccessful } from "../../store/actions"
import { post } from "./../../helpers/api_helper"

export interface LogoutPropsInterface extends RouteComponentProps {
  dashbord_id: string,
  registerUserSuccessful: (userObj: any) => {},
  apiError: (err: string) => {},
  history: any,
  error: string,
  logoutUser: any,
  access_token: string
}

export interface LogoutStateInterface {

}

class Logout extends Component<LogoutPropsInterface, LogoutStateInterface> {
  constructor(props: LogoutPropsInterface) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    this.props.logoutUser(this.props.history)
    this.props.registerUserSuccessful({})
    localStorage.setItem("authUser", "")
  }

  handleLogoutUser = async () => {
    return new Promise(async (resolve, reject) => {
      post(`${url.API_BASE}logout`, {})
        .then(response => {
          return resolve(response);
        })
        .catch(error => {
          if (error && error.response && error.response.data && error.response.data.message) {
            this.props.apiError(error.response.data.message)
          }

          return reject(error);
        });
    });
  }

  render() {
    return <React.Fragment></React.Fragment>
  }
}

const mapStateToProps = (state: any) => {
  const { dashbord_id, user: access_token } = state.Account
  return { dashbord_id, access_token }
}

export default withRouter(
  connect(mapStateToProps, {
    logoutUser,
    registerUserSuccessful
  })(Logout)
)