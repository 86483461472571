import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Col, Container, Row } from "reactstrap"
// import { getWebsites } from "./../../store/actions"
// import CodesStats from "./CodesStats/CodesStats"
// import SmallTile from "./SmallTile/SmallTile"
import WelcomePanel from "./WelcomePanels/WelcomePanel"
import MetaTags from 'react-meta-tags';

const Dashboard = ({ history, returnTranslation }) => {
  // const dispatch = useDispatch()

  const user = useSelector(store => store.Account.user)
  // const websites = useSelector(state => state.websites.websites)
  // const page = useSelector(state => state.websites.page)

  // const reports = [
    // {
    //   title: `${returnTranslation("Charity shops")} (${returnTranslation("total")})`,
    //   bgClass: "bg-info",
    //   iconClass: "bx bx-shopping-bag",
    //   description: "1160",
    //   link: "/charity-shops-list",
    // },
    // {
    //   title: `${returnTranslation("Charity shops")} (${returnTranslation("active")})`,
    //   bgClass: "bg-success",
    //   iconClass: "bx bx-check-circle",
    //   description: "740",
    // },
    // {
    //   title: `${returnTranslation("Charity shops")} (${returnTranslation("inactive")})`,
    //   bgClass: "bg-danger",
    //   iconClass: "bx bx-x-circle",
    //   description: "420",
    // },
  // ]

  // useEffect(() => {
  //   dispatch(getWebsites(1))
  // }, [])

  return (
    <div className="page-content">
      <MetaTags>
        <title>LOOP | Dashboard</title>
      </MetaTags>
      <Container fluid>
        {/* <h3>{returnTranslation("Dashboard")}</h3> */}
        <Row className="pb-4">
          <Col sm="12" md="12" lg="12">
            <WelcomePanel
              user={user}
              history={history}
              returnTranslation={returnTranslation}
            />
          </Col>


          {/* <Col sm="5">
            {user?.role === "RETAILER" ? (
              <WelcomePanel
                stat1={{
                  label: returnTranslation("Your websites"),
                  value: websites ? websites.length : 0,
                }}
                user={user}
              />
            ) : (
              <WelcomePanel user={user} />
            )}
          </Col>
          <Col sm="7">
            <Row>
              {user?.role === "RETAILER" && reports?.length
                ? null
                : reports.map(
                  ({ title, description, bgClass, iconClass }, index) => (
                    <SmallTile
                      key={"_col_" + index}
                      bgClass={bgClass}
                      title={title}
                      description={description}
                      iconClass={iconClass}
                    />
                  )
                )}
            </Row>
          </Col> */}
        </Row>

        {/* <Row>
          <Col sm="5">{user?.role === "RETAILER" ? <CodesStats returnTranslation={returnTranslation} /> : null}</Col>
          <Col sm="7"></Col>
        </Row> */}
      </Container>
    </div>
  )
}

export default Dashboard
