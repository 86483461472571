import React, { useEffect, useState } from "react"
// @ts-ignore
import { useDispatch } from "react-redux"
// @ts-ignore
import MetaTags from 'react-meta-tags';
import Breadcrumbs from "./../../../components/Common/Breadcrumb"
import { Container, Card, Row, CardBody, Col } from "reactstrap"
import { getWebsiteDetailApi } from "./../../../helpers/backend_helper"
import { openAlert } from "./../../../store/alert/actions"
import CompleteSetupDevelopers from "./../WebsitesFormSteps/CompleteSetupDevelopers"

interface WebsitesTableColumnsInterface {
    history: any,
    match: any,
    returnTranslation: (name: string) => string
}

const WebsitesTableColumns = ({ history, match: { params }, returnTranslation }: WebsitesTableColumnsInterface) => {
    const dispatch = useDispatch()
    const [headCode, setHeadCode] = useState("")

    useEffect(() => {
        if (params?.uuid) {
            handleGetWebsite()
        }
    }, [params?.uuid])

    const handleGetWebsite = async () => {
        try {
            const response = await getWebsiteDetailApi(params?.uuid)
            const { config: { plugin: { website_container_class } }, token } = response

            if (website_container_class) {
                //old
                // <script defer>
                //         window.addEventListener("load", function () {
                //             localStorage.setItem("loopPluginUUID", "${params?.uuid}");
                //             const _loopPlugin__isIE = window.navigator.userAgent.indexOf(".NET ");
                //             const _loopPlugin__node = document.querySelector('body');
                //             const _loopPlugin = document.createElement('script');
                //             const _loopPlugin__date = new Date();
                //             const _loopPlugin__fileName = 'https://loop-shop.yellow-octopus.com/js/plugin/index.bundle.js?date=' + _loopPlugin__date.getTime();
                //             _loopPlugin.type = _loopPlugin__isIE ? 'text/javascript' : 'module';
                //             _loopPlugin.charset = 'utf-8';
                //             _loopPlugin.async = true;
                //             _loopPlugin.src = _loopPlugin__fileName;
                //             _loopPlugin__node.appendChild(_loopPlugin);
                //             const _loopPlugin__containerNode = document.querySelector('${website_container_class}');
                //             const _loopPlugin__appNode = document.createElement('div');
                //             _loopPlugin__appNode.id = "loop-app-root";
                //             _loopPlugin__containerNode.appendChild(_loopPlugin__appNode);
                //         })
                //     </script>
                setHeadCode(`<script id="loop_plugin_configuration_url" loop-configuration-uuid="${token ? token : ""}" src="https://app.loopdigitalwardrobe.com/js/plugin/loop-widget.js?v=1"></script>`)
            } else {
                dispatch(openAlert({ text: returnTranslation("An error occured"), status: "danger" }))
            }

        } catch (e) {
            dispatch(openAlert({ text: returnTranslation("An error occured"), status: "danger" }))
        }
    }

    return (

        <div className="page-content">
            <MetaTags>
                <title>LOOP | Plugin configuration</title>
            </MetaTags>
            <Container fluid>
                <Breadcrumbs
                    title={returnTranslation("Pages")}
                    breadcrumbItems={[returnTranslation("Websites"), returnTranslation("Configure plugin")]}
                />

                <Row className="justify-content-center mt-lg-5">
                    <Col xl="12" sm="12">
                        <CompleteSetupDevelopers
                            returnTranslation={returnTranslation}
                            headCode={headCode}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default WebsitesTableColumns
