import {
  GET_BILLINGS,
  GET_BILLINGS_SUCCESS,
  GET_BILLINGS_FAIL
} from "./actionTypes"

export const getBillings = () => ({
  type: GET_BILLINGS,
})

export const getBillingsSuccess = info => ({
  type: GET_BILLINGS_SUCCESS,
  payload: info,
})

export const getBillingsFail = error => ({
  type: GET_BILLINGS_FAIL,
  payload: error,
})