// @ts-ignore
import React, { useEffect, useState } from "react";
// @ts-ignore
import MetaTags from 'react-meta-tags';
// @ts-ignore
import { connect, useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { RouteComponentProps, useParams } from "react-router";
// @ts-ignore
import { withRouter } from "react-router-dom";
import {
	Card,
	CardBody,
	Col,
	Container, Row
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { apiError, checkAccount } from "../../../store/actions";
import { openAlert } from "../../../store/alert/actions";
import SingleConfigurationOption from "../../Dashboard/WelcomePanels/SingleConfigurationOption/SingleConfigurationOption";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./EnableVirtualShop.scss";
import BasicTable from "../../../components/BasicTable/BasicTable";
import ReportsTableColumns from "./ReportsTableColumns"
import { getReports } from "../../../helpers/backend_helper";
//@ts-ignore
import SchoolResell from "../../../assets/images/school_resell_for_school.svg"

export interface Props extends RouteComponentProps {
	dashbord_id: string,
	apiError: (err: string) => {},
	history: any,
	error: string,
	user: any,
	onOpenAlert: any,
	returnTranslation: any
}

const EnableVirtualShop = ({ returnTranslation, onOpenAlert, history }: Props) => {
	const dispatch = useDispatch();

	const [initialDate] = useState(new Date());
	const [startAt, setStartAt] = useState<Date>(initialDate);	
	const [isLoadingReports, setIsLoadingReports] = useState(false);	
	const [reports, setReports] = useState<any>({
		info: {
			current_page: 1,
			page_count: 15,
			total_items: 0,
			total_pages: 1,
		},
		items: []
	});


	const onCheckAccount = () => dispatch(checkAccount());
	const accountInfo = useSelector((state: any) => state.Account?.info);
	const detailsFormFilled = useSelector((state: any) => state.Account?.info?.configuration?.details);
	const paypal_connection = useSelector((state: any) => state.Account?.info?.configuration?.paypal_connection);
	const user = useSelector((state: any) => state?.Account?.user);

	const VIRTUAL_SHOP_ENTITLEMENT_NAME = `VIRTUAL_${user?.role?.toUpperCase()}`
	const virtualShopEntitlement = useSelector((state: any) => state.Account?.info?.configuration?.entitlements?.[VIRTUAL_SHOP_ENTITLEMENT_NAME]);

	useEffect(() => {
		onCheckAccount();
	}, [])

	const formatMonthPayloadFromDateObject = (date: Date) => {
		const monthIndexString = (date.getMonth() + 1)?.toString();
		const formattedMonth = monthIndexString?.length === 1 ? `0${monthIndexString}` : monthIndexString
		return `${date?.getFullYear()}-${formattedMonth}`;
	}

	const handleChangeDate = async (date: any, type: string) => {
		handleGetReports(1, { date })
		setStartAt(date)
    }
	
	const handleGetReports = async (page: number, payload: { date: Date }) => {
		try{
			setIsLoadingReports(true);
			const formattedMonth = formatMonthPayloadFromDateObject(payload?.date)
			// console.log(formattedMonth);
			const response = await getReports(user?.role, page, `month=${formattedMonth}`)
			// const response = await getReports(page, `month=2023-01`)
			setReports(response);
			// console.log(["GET REPORTS", page, payload]);
		}catch(e){
			console.log(e);
		}finally{
			setIsLoadingReports(false);
		}
	}

	return (
		<>
			<MetaTags>
				{user?.role === 'CHARITY' ? returnTranslation("LOOP | Donations Setup - Virtual Charity Shop") : null}
				{user?.role === 'SCHOOL' ? returnTranslation("LOOP | LOOP FOR GOOD - Resell for School") : null}
			</MetaTags>
			<div className="page-content">
				<Container fluid>
					{user?.role === 'CHARITY' ? <Breadcrumb title="Donations Setup" breadcrumbItem="Virtual Charity Shop" /> : null}
					{user?.role === 'SCHOOL' ? <Breadcrumb title="LOOP FOR GOOD" breadcrumbItem="Resell for School" /> : null}
			

					<Row>
						<Col sm={12} md={12} lg={12} xl={8}>
							<Card>
								<CardBody>
									<div className="welcome-panel__steps">
										<SingleConfigurationOption
											header={user?.role === 'CHARITY' ? "Virtual Charity Shop" : user?.role === 'SCHOOL' ? "Resell for School" : ""}
											description={`LOOP donors can now donate items from their digital wardrobes directly to your ${user?.role?.toLowerCase()}. Listed on LOOP marketplace, digital donations are resold between the LOOP donor and the LOOP buyer directly without getting your ${user?.role?.toLowerCase()} involved. When the product is sold, raised funds will go to your ${user?.role?.toLowerCase()}.`}
											descriptionClassName="m-0"
											filled={detailsFormFilled && paypal_connection && virtualShopEntitlement?.status === "ACTIVE"}
											tasksOpened={true}
											containerClassName="p-0"
											tasksOptions={[
												{
													name: returnTranslation("Details"),
													isActive: detailsFormFilled,
													route: `/details`
												},
												// {
												// 	name: returnTranslation("Set up Virtual Charity"),
												// 	isActive: entitlements[VIRTUAL_CHARITY].status === "ACTIVE",
												// 	route: "/virtual-charity-shop"
												// },
												{
													name: returnTranslation("Payouts with Paypal"),
													isActive: paypal_connection,
													route: "/payouts-with-paypal"
												}
											]}
											entitlement={virtualShopEntitlement}
											entitlementType={VIRTUAL_SHOP_ENTITLEMENT_NAME}
											verified={accountInfo?.verified}
											activateCondition={detailsFormFilled && paypal_connection}
											returnTranslation={returnTranslation}
											customFilledIconDone={user?.role === "CHARITY"
												? <img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donate-virtual-charity-shop.png" />
												: <img className="single-configuration-option--icon" src={SchoolResell} />
											}
											customFilledIconUndone={user?.role === "CHARITY"
												? <img className="single-configuration-option--icon" src="https://loopdigitalwardrobe-static.s3.eu-west-2.amazonaws.com/icons/donate/donate-virtual-charity-shop.png" />
												: <img className="single-configuration-option--icon" src={SchoolResell} />
											}
										/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row className="donations-reports">
						<Col sm={12} md={12} lg={12} xl={8}>
							<Card>
								<CardBody>
									<span className="title">Donations Reports</span>
									<Row className="mb-3 mt-3">
										<Col md={3}>
											<label htmlFor="example-datetime-local-input">
												{returnTranslation("Reports Month")}
											</label>
											<DatePicker
												selected={
													startAt ? startAt : initialDate
												}
												onChange={(date: any) =>
													handleChangeDate(date, "start_at")
												}
												className="form-control"
												showTimeSelect={false}
												calendarClassName="react-datepicker"
												dateFormat="MMMM yyyy"
												showMonthYearPicker={true}
												minDate={new Date("Wed Mar 01 2023 00:00:00 GMT+0100 (czas środkowoeuropejski standardowy)")}
												maxDate={new Date()}
											/>
										</Col>
									</Row>
									<Row className="heading pt-3">
										<div className="card-title">LOOP DIGITAL WARDROBE</div>
										<div className="card-title">VIRTUAL {user?.role}</div>
										<div className="card-title">MONTHLY REPORT FOR: {user?.company_details?.company_name}</div>

										<div className="card-title mt-2">
											<span>{startAt.toLocaleString('en', { month: 'long' })?.toUpperCase()}</span>
											<span className="ms-1">{startAt?.getFullYear()}</span>
										</div>
									</Row>
									<Row>
										<Col>
											<BasicTable
												items={reports?.items}
												columns={ReportsTableColumns({
													offsetIndex: (reports?.info?.current_page - 1) * reports?.info?.page_count,
													returnTranslation })
												}
												//@ts-ignore
												// addItemBtnText={""}
												// addItemBtnAction={() => { }}
												searchbar={false}
												getFunction={(page: number) => handleGetReports(page, { date: startAt })}
												totalSize={reports?.info?.total_items}
												sizePerPage={15}
												isLoading={isLoadingReports}
												currentPage={reports?.info?.current_page}
											/>
										</Col>
									</Row>

								</CardBody>
							</Card>

						</Col>
					</Row>

				</Container>
			</div>
		</>
	)
}

const mapStateToProps = (state: any) => {
	const { error } = state.Login
	const { dashbord_id, user } = state.Account
	const { countries } = state.Countries
	return { error, dashbord_id, user, countries }
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		onApiError: () => apiError(),
		onOpenAlert: ({ text, status }: any) =>
			dispatch(openAlert({ text: text, status: status })),
	}
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EnableVirtualShop)
)
