import { GET_WEBSITES_FAIL, GET_WEBSITES_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  websites: [],
  total: 0,
}

const Websites = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WEBSITES_SUCCESS:
      return {
        ...state,
        websites: action?.payload?.items,
        total: action?.payload?.info?.total_pages,
        page: action?.payload?.info?.current_page,
        perPage: action?.payload?.info?.page_count,
        totalItems: action?.payload?.info?.total_items,
      }

    case GET_WEBSITES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Websites
