import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Col, Row, Card, CardBody, Button } from 'reactstrap'
import BasicTable from '../../../../components/BasicTable/BasicTable'
import { openAlert } from '../../../../store/actions'
import LoopPieChart from '../../LoopPieChart/LoopPieChart'
// import { changePriceAlertsQueryObject, changeTestsQueryObject, clearPriceAlerts, getPriceAlerts, getTests, openAlert } from '../../../../store/actions'
// import PriceAlertsTableColumns from '../../../Offers/PriceAlerts/PriceAlertsTableColumns'
import LoopReChart from '../../LoopReChart/LoopReChart'
import { ColorsPaletteLoop, ColorsPalette, ColorsPalette2, ColorsPaletteArray, ColorsPaletteArray3 } from '../../Dashboard'

interface Props {
    t: (text: string) => string
}

const WardrobesStatsTab = ({ t }: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    // const { items, totalItems, page, perPage, isLoading } = useSelector((state: any) => state.PriceAlerts);
    // const QUERY_OBJECT_INIT = useSelector((state: any) => state.PriceAlerts.initialQueryObject)
    // const queryObject = useSelector((state: any) => state.PriceAlerts.queryObject)
    // const setQueryObject = (queryObject: any) => dispatch(changePriceAlertsQueryObject(queryObject))
    // const onOpenAlert = (text: string, status: string) => dispatch(openAlert({ text, status }));
  
    // const handleSetQueryObject = (value: any, forceReload?: boolean) => {
    //   if ((JSON.stringify(queryObject) !== JSON.stringify(value)) || forceReload) {
    //      dispatch(getPriceAlerts({ page: 1, queryObject: { value, per_page: 10 } }))
    //   }
    //   setQueryObject(value)
    // }

    // useEffect(() => {
    //     if(items?.length !== 10){
    //         // dispatch(getPriceAlerts({ page: 1, queryObject: { ...queryObject, per_page: 10 } }))
    //     }
    //     console.log("RENDER ERRORS")
    // }, [])

    const takeBackTabViewsStats = [
        {date: '2023-01-01', views: 501 },
        {date: '2023-01-02', views: 203 },
        {date: '2023-01-03', views: 302 },
        {date: '2023-01-04', views: 215 },
        {date: '2023-01-05', views: 500 },
        {date: '2023-01-06', views: 605 },
        {date: '2023-01-07', views: 444 },
    ]

    const takebackGeneratedLabelsStats = [
        {date: '2023-01-01', generated_labels: 17 },
        {date: '2023-01-02', generated_labels: 5 },
        {date: '2023-01-03', generated_labels: 7 },
        {date: '2023-01-04', generated_labels: 6 },
        {date: '2023-01-05', generated_labels: 7 },
        {date: '2023-01-06', generated_labels: 12 },
        {date: '2023-01-07', generated_labels: 4 },
    ]
    //ROW 1
    const wardrobeProductsByAge = [
        // { name: '0-13 yrs', value: 50, color: ColorsPalette2.blue },
        { name: 'under 18 yrs', value: 50, color: ColorsPalette2.orange },
        { name: '18-24 yrs', value: 350, color: ColorsPalette2.redOrange },
        { name: '25-30 yrs', value: 300, color: ColorsPalette2.lightGreen },
        { name: '31-40 yrs', value: 150, color: ColorsPalette2.lavender },
        { name: '41-50 yrs', value: 100, color: ColorsPalette.darkBlue },
        { name: '60+ yrs', value: 50, color: ColorsPalette.bottleGreen },
    ]
    // ?.sort((a, b) => a?.value < b?.value ? 1 : -1)
    // ?.map((x, index) => { 
    //     x.color = ColorsPaletteArray[index]
    //     return x
    // })

    const wardrobeProductsByPrice = [
        { name: '£0-10', value: 400, color: ColorsPalette.orange },
        { name: '£11-20', value: 100, color: ColorsPalette.yellow },
        { name: '£21-30', value: 300, color: ColorsPalette.darkGreen },
        { name: '£30+', value: 200, color: ColorsPalette.appleGreen },
    ];

    const wardrobeProductsByGender = [
        { name: 'Men', value: 400, color: ColorsPaletteLoop.loopBlue },
        { name: 'Women', value: 600 , color: ColorsPaletteLoop.loopPink },
    ];

    // ROW 2
    const wardrobeProductsByCategory = [
        // { name: 'Clothes', value: 400, color: ColorsPalette.orange },
        { name: 'Tops', value: 550, color: ColorsPalette.appleGreen },
        { name: 'Bottoms', value: 400, color: ColorsPalette.blue },
        { name: 'Outerwear', value: 320, color: ColorsPalette.bottleGreen },
        { name: 'Shoes', value: 100, color: ColorsPalette.darkGreen },
        { name: 'Bags', value: 150, color: ColorsPalette.darkBlue },
        { name: 'Accessories', value: 200, color: ColorsPalette.blue },
        { name: 'Beauty', value: 200, color: ColorsPalette2.redOrange },
    ];

    const productsAddedByPlugin = [
        {date: '2023-01-01', added: 22 },
        {date: '2023-01-02', added: 15 },
        {date: '2023-01-03', added: 17 },
        {date: '2023-01-04', added: 16 },
        {date: '2023-01-05', added: 25 },
        {date: '2023-01-06', added: 24 },
        {date: '2023-01-07', added: 34 },
    ]
    
    // const mostFrequentlySelectedSecondLifeOptions = [
    //     { name: 'Resell', value: 900, color: palette2.lightGreen },
    //     { name: 'Swap', value: 500, color: palette2.blue },
    //     { name: 'Donate', value: 400 , color: palette2.orange },
    //     { name: 'Upcycle', value: 200 , color: palette2.redOrange },
    //     { name: 'Take Back', value: 1000 , color: palette2.lavender },
    // ];

    // const secondLifeOptions = [
    //     {date: '2023-01-01', resell: 20, swap: 5, donate: 10, upcycle: 5, take_back: 15 },
    //     {date: '2023-01-02', resell: 18, swap: 7, donate: 3, upcycle: 6, take_back: 25 },
    //     {date: '2023-01-03', resell: 22, swap: 9, donate: 5, upcycle: 4, take_back: 21 },
    //     {date: '2023-01-04', resell: 30, swap: 3, donate: 6, upcycle: 2, take_back: 16 },
    //     {date: '2023-01-05', resell: 21, swap: 14, donate: 4, upcycle: 7, take_back: 13 },
    //     {date: '2023-01-06', resell: 13, swap: 15, donate: 8, upcycle: 8, take_back: 20 },
    //     {date: '2023-01-07', resell: 7, swap: 17, donate: 11, upcycle: 1, take_back: 14 },
    // ]
    // const data02 = [
    // { name: 'A1', value: 100 },
    // { name: 'A2', value: 300 },
    // { name: 'B1', value: 100 },
    // { name: 'B2', value: 80 },
    // { name: 'B3', value: 40 },
    // { name: 'B4', value: 30 },
    // { name: 'B5', value: 50 },
    // { name: 'C1', value: 100 },
    // { name: 'C2', value: 200 },
    // { name: 'D1', value: 150 },
    // { name: 'D2', value: 50 },
    // ];

    // const feedsBrandsData = [
    //     {"brand": "Brand 4","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 578,"new": 600,"total": 3100},
    //     {"brand": "Brand 7","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Brand 8","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Brand 9","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Brand 10","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Brand 11","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Brand 12","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Brand 13","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Brand 14","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Brand 15","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Brand 16","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Brand 17","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Brand 18","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Brand 19","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Brand 20","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Brand 6","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 450,"new": 1000,"total": 4500},
    //     {"brand": "Brand 3","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg", "updated": 2000, "new": 1100, "total": 3800 },
    //     {"brand": "Brand 5","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg", "updated": 900, "new": 1300, "total": 4000 },
    //     {"brand": "Brand 2","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg", "updated": 750, "new": 1398, "total": 3800 },
    //     {"brand": "JD Sports","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg", "updated": 500, "new": 2400, "total": 4000 }
    // ];

    // const marketplacesData = [
    //     {"brand": "Vinted","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 578,"new": 600,"total": 3100},
    //     {"brand": "Depop","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Micolet","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Vestiaire Collective","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Asos","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Thrift+","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Zara","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    //     {"brand": "Hoola Kids","logoSrc": "https://loopdigitalwardrobe.s3.eu-west-2.amazonaws.com/files/aBc_qyrYBETQ7CmwuWA9L/original.jpg","updated": 240,"new": 670,"total": 4000},
    // ];

    return (
        <div className="add-to-stats-tab">
            {/* ROW 1 */}
            <Row>
                <Col sm={12} md={12} lg={12} xl={4}>
                    <Card className="w-100">
                        <CardBody className="p-3">
                            <h4 className="card-title" style={{marginTop: 15, marginBottom: 16}}>{t("Products separated into the age of the user")}</h4>
                            <LoopPieChart
                                data={wardrobeProductsByAge}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={12} md={12} lg={12} xl={4}>
                    <Card className="w-100">
                        <CardBody className="p-3">
                            <h4 className="card-title" style={{marginTop: 15, marginBottom: 16}}>{t("Products separated by average price")}</h4>
                            <LoopPieChart 
                                data={wardrobeProductsByPrice}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={12} md={12} lg={12} xl={4}>
                    <Card className="w-100">
                        <CardBody className="p-3">
                            <h4 className="card-title" style={{marginTop: 15, marginBottom: 16}}>Products by gender</h4>
                            <LoopPieChart 
                                data={wardrobeProductsByGender}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {/* ROW 2 */}
            <Row>
                <Col sm={12} md={12} lg={12} xl={4}>
                    <Card className="w-100">
                        <CardBody className="p-3">
                            <h4 className="card-title" style={{marginTop: 15, marginBottom: 16}}>{t("Products separated into categories")}</h4>
                            <LoopPieChart 
                                data={wardrobeProductsByCategory}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={8}>
                    <Card>
                        <CardBody className="p-3">
                            <h4 className="card-title" style={{marginTop: 15, marginBottom: 16}}>{t("Daily chart showing the number of products added to wardrobes through the use of the plug in")}</h4>
                            <LoopReChart
                                height={300}
                                data={productsAddedByPlugin}
                                showLoaderOnEmptyData={true}
                                xAxisDataKey="date"
                                lines={[
                                    // { type: "monotone", dataKey: "new", name: "New", stroke: "#4d9de0", activeDotSize: 5 },
                                    { type: "linear", dataKey: "added", name: "Added", stroke: ColorsPaletteLoop.green, activeDotSize: 5, label: { dataKey: "added" } },
                                    // { type: "monotone", dataKey: "total", name: "Total", stroke: "#e15554", activeDotSize: 8 },
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default WardrobesStatsTab
