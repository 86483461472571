// @ts-ignore
import { AvField, AvForm } from "availity-reactstrap-validation"
import React from "react"
import { withTranslation } from "react-i18next"
import 'react-phone-input-2/lib/high-res.css'
import Facebook from "../../../../assets/images/social-facebook.svg"
import Instagram from "../../../../assets/images/social-instagram.svg"
import Twitter from "../../../../assets/images/social-twitter.svg"
import { useLocation } from "react-router"

export interface RegisterSocialsPropsInterface {
    t: any;
    handleSubmit: (data: any) => void;
    stepData?: any;
    submitBtnText: string;
}

const RegisterSocialsForm = ({
    handleSubmit,
    stepData,
    submitBtnText,
}: RegisterSocialsPropsInterface) => {
    const location: any = useLocation();
    // const dispatch = useDispatch();
    // const { user, registrationError, loading, dashbord_id } = useSelector((state: any) => state?.Account)

    const handleValidSubmit = async (event: any, values: any) => {
        event.preventDefault()

        return new Promise(async (resolve, reject) => {

            const {
                facebook,
                twitter,
                instagram,
                www
            } = values;

            const data = {
                facebook,
                twitter,
                instagram,
                www,
                code: location?.state?.code,
            }

            handleSubmit(data)
        })
    }

    return (
        <>
            <AvForm
                className="form-horizontal"
                onValidSubmit={handleValidSubmit}
                // onSubmit={handleValidSubmit}
            >
                <div className="mb-3 d-flex av-field-wrapper--w-100">
                    <img src={Facebook} className="me-2" height={40} />
                    <AvField
                        name="facebook"
                        label=""
                        className="form-control"
                        placeholder="Enter facebook link"
                        type="text"
                        value={stepData?.facebook}
                        // validate={{
                        //     pattern: {
                        //         value: new RegExp(/https:\/\/.*/),
                        //         errorMessage: 'Link should start with "https://".'
                        //     },
                        // }}
                    />
                </div>
                <div className="mb-3 d-flex av-field-wrapper--w-100">
                    <img src={Twitter} className="me-2" height={40} />
                    <AvField
                        name="twitter"
                        label=""
                        className="form-control"
                        placeholder="Enter twitter link"
                        type="text"
                        value={stepData?.twitter}
                        // validate={{
                        //     pattern: {
                        //         value: new RegExp(/https:\/\/.*/),
                        //         errorMessage: 'Link should start with "https://".'
                        //     },
                        // }}
                    />
                </div>
                <div className="mb-3 d-flex av-field-wrapper--w-100">
                <img src={Instagram} className="me-2" height={40} />
                    <AvField
                        name="instagram"
                        label=""
                        className="form-control"
                        placeholder="Enter instagram link"
                        type="text"
                        value={stepData?.instagram}
                        // validate={{
                        //     pattern: {
                        //         value: new RegExp(/https:\/\/.*/),
                        //         errorMessage: 'Link should start with "https://".'
                        //     },
                        // }}
                    />
                </div>
                <div className="mb-3 d-flex av-field-wrapper--w-100">
                    <i className="mdi mdi-web me-2" style={{ fontSize: "40px", lineHeight: "40px" }} />
                    <AvField
                        name="www"
                        label=""
                        className="form-control"
                        placeholder="Enter website link"
                        type="text"
                        value={stepData?.www}
                        // validate={{
                        //     pattern: {
                        //         value: new RegExp(/https:\/\/.*/),
                        //         errorMessage: 'Link should start with "https://".'
                        //     },
                        // }}
                    />
                </div>

                <div className="mt-5 d-grid">
                    <button
                        className="btn-no-shadow btn btn-primary btn-block waves-effect waves-light"
                        type="submit"
                    >
                        {submitBtnText}
                    </button>
                </div>
            </AvForm>
        </>
    )
}

export default withTranslation()(RegisterSocialsForm)
