import { map } from "lodash"
import PropTypes from 'prop-types'
import React from "react"
import { Link } from "react-router-dom"
import {
    Button, Card,
    CardBody,
    Col,
    Media
} from "reactstrap"

interface Props {
    projects: any[],
    colSizes?: { xl?: number, lg?: number, md?: number, sm?: number }
}

const CardProject = ({ projects, colSizes }: Props) => {
    return (
        <div className="card-projects-grid gap-3">
            {map(projects, (project, dkey) => (
                // sm={colSizes?.sm ?? 6}
                // xl={colSizes?.xl ?? 4}
                // <Col xl={colSizes?.xl ?? undefined} lg={colSizes?.lg ?? undefined} md={colSizes?.md ?? undefined} sm={colSizes?.sm ?? undefined}  key={"__projectcd_" + dkey}>
                    <Card key={"__projectcd_" + dkey}>
                        <CardBody>
                            <div className="d-flex h-100">
                                <div className="avatar-md me-3">
                                    <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                                        <img src={project.img} alt="" height="60%" />
                                    </span>
                                </div>

                                <Media className="overflow-hidden d-flex flex-column justify-content-between" body>
                                    <div>
                                        <h5 className="text-truncate font-size-15">
                                            {/* <Link
                                                to={`/projects-overview/${project.id}`}
                                                className="text-dark"
                                            > */}
                                                {project.name}
                                            {/* </Link> */}
                                        </h5>
                                        <p className="text-muted mb-1">{project.description}</p>
                                    </div>

                                    <div className="d-flex gap-2">
                                        {project.configureLink ? (
                                            <Link to={project?.configureLink}>
                                                <Button color="success">
                                                    Configure
                                                </Button>
                                            </Link>
                                        ) : null}
                                    
                                        {project.downloadUrl ? (
                                            <a href={project?.downloadUrl} download={project.name}>
                                                <Button color="success">
                                                    Download
                                                </Button>
                                            </a>
                                        ): null}
                                        {project.customBtnText ? (
                                            // <a href={project?.downloadUrl} download={project.name}>
                                                <Button color="success" onClick={project.btnOnClick}>
                                                    {project.customBtnText}
                                                </Button>
                                            // </a>
                                        ): null}
                                    </div>
                                    
                                </Media>
                            </div>
                        </CardBody>
                    </Card>
                // </Col>
            ))}
        </div>
    )
}

export default CardProject
