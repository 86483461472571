import Box from "@material-ui/core/Box"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import TypographyMU from "@material-ui/core/Typography"
import React, { useEffect, useState } from "react"
import "./CustomTabs.scss"

const TabPanel = (props: any) => {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`a11y-tabpanel-${index}`}
			aria-labelledby={`a11y-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box style={{ padding: 0 }}>
					<TypographyMU component={"span"}>{children}</TypographyMU>
				</Box>
			)}
		</div>
	)
}

interface Props {
	labels: string[];
	onChange: (index: number) => void;
	currentTab: string;
	className: string;
	labelIcons?: any[];
	children: any;
	hideTabs?: boolean;
	minTabHeight?: number;
}

const CustomTabs = ({
	labels,
	onChange,
	currentTab,
	className,
	labelIcons,
	children,
	hideTabs = false,
	minTabHeight
}: Props) => {
	const [value, setValue] = useState(0)

	const handleChange = (event: any, newValue: number) => {
		setValue(newValue)
		onChange && onChange(newValue)
	}

	useEffect(() => {
		// console.log(['CustomTabs currentTab', currentTab])
		handleChange(
			null,
			labels?.findIndex((item: string) => item === currentTab)
		)
	}, [currentTab])

	return (
		<div
			className={`custom-tabs__container ${labels?.length === 1 ? "custom-tabs__container--single-tab" : ""} ${className ? className : ""}`}
		>
			{!hideTabs && (
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="ant example"
					variant="scrollable"
					//@ts-ignore
					scrollButtons="false"
				>
					{labels.map((label: any, i: number) => (
						<Tab key={label} label={label} icon={labelIcons?.[i]} />
					))}
				</Tabs>
			)}
			{Array.isArray(children) ? (
				children.map((children, key) => (
					<TabPanel
						key={key}
						index={key}
						value={value}
						style={{
							...(minTabHeight ? { minHeight: minTabHeight } : {})
						}}
					>
						{children}
					</TabPanel>
				))
			) : (
				<TabPanel value={value} index={0}>
					{children}
				</TabPanel>
			)}
		</div>
	)
}

export default CustomTabs
